import { useQuery } from "@apollo/client";
import { useState, useReducer, useEffect } from "react";
import { Header } from "components/Header";
import { Box, Container, Grid, Stack, Tab, Tabs } from "@mui/material";
import CountTask from "./CountTask";
import ListToday from "./ListToday";
import { FETCH_TASKS } from "graphql/queries/fetchTasks";
import { FETCH_TASKS_TODAY } from "graphql/queries/fetchTasksToday";
import { COUNT_TASKS } from "graphql/queries/countTasks";
import { FetchTasks } from "types/graphql/queries/fetchTasks";
import { FetchTasksToday } from "types/graphql/queries/fetchTasksToday";
import { countTasks } from "types/graphql/queries/countTasks";
import { CircularProgress } from "components/CircularProgress";
import { FETCH_DETAIL_COMPANY } from "graphql/queries/fetchDetailCompany";
import { FetchDetailCompany } from "types/graphql/queries/fetchDetailCompany";
import ListPending from "./ListPending";
import { Item } from "components/Paper";
import Button from "components/Button";
import { TasksFilter } from "./Modal/TasksFilters";
import { Filter } from "./types";
import { useLocation, useNavigate } from "react-router-dom";
import ListAll from "./ListAll";
import { FetchTasksLabelList } from "types/graphql/queries/fetchTasksLabelList";
import { FETCH_TASKS_LABEL_LIST } from "graphql/queries/fetchTasksLabelList";
import ListSuspended from "./ListSuspended";
import ListCanceled from "./ListCanceled";

function a11yProps(index: number) {
	return {
		id: `tarefa-tab-${index}`,
		"aria-controls": `tarefa-tabpanel-${index}`,
	};
}

function reducer(state: Filter, payload: any) {
	switch (payload.type) {
		case "startDate":
			return {
				...state,
				startDate: payload.value,
			};
		case "finishDate":
			return {
				...state,
				finishDate: payload.value,
			};
		case "keyword":
			return { ...state, keyword: payload.value };
		case "filters":
			return { ...state, filters: payload.value };
		case "upfmls":
			return { ...state, upfmls: payload.value };
		case "lateDays":
			return { ...state, lateDays: payload.value };
		case "street":
			return { ...state, street: payload.value };
		case "number":
			return { ...state, number: payload.value };
		case "lote":
			return { ...state, lote: payload.value };
		case "quadra":
			return { ...state, quadra: payload.value };
		case "cadastralIndex":
			return { ...state, cadastralIndex: payload.value };
		case "offendersName":
			return { ...state, offendersName: payload.value };
		default:
			throw new Error("Invalid action type");
	}
}

const initialState = {
	startDate: null,
	finishDate: null,
	keyword: "",
	filters: [
		{
			id: 0,
			title: "Situação do protocolo",
			options: [],
		},
		{
			id: 1,
			title: "Assunto",
			options: [],
		},
		{
			id: 2,
			title: "Status do protocolo",
			options: [],
		},
		{
			id: 3,
			title: "Bairro",
			options: [],
		},
		{
			id: 4,
			title: "Tarefa atual",
			options: [],
		},
	],
	lateDays: "",
	street: "",
	number: "",
	lote: "",
	quadra: "",
	cadastralIndex: "",
	offendersName: "",
	upfmls: null,
};

export function Tasks() {
	const pageFromStorage: {
		[key: string]: number;
	} = JSON.parse(localStorage.getItem("page")) || 1;
	const rowsPerPageFromStorage: {
		[key: string]: number;
	} = JSON.parse(localStorage.getItem("rowsPerPage")) || 15;

	const navigate = useNavigate();
	const { search } = useLocation();
	const subdomain = window.location.hostname.split(".")[0];

	const { data: dataTasks } = useQuery<FetchTasksLabelList>(
		FETCH_TASKS_LABEL_LIST
	);

	const dataTasksWithId = dataTasks?.fetchTasksLabelList?.labels?.map(
		(item, index) => ({
			id: index,
			name: item,
		})
	);

	const paramsFormatted = Object.fromEntries(new URLSearchParams(search));
	const situationParsed = JSON.parse(paramsFormatted.situation || "[]").map(
		(item, index) => {
			const isLastIndex =
				index + 1 === JSON.parse(paramsFormatted.situation || "[]").length;
			if (index === 0 || isLastIndex) {
				return item;
			}

			if (Array.isArray(item)) {
				return item.map((item) => item.id);
			}
			return item;
		}
	);

	const SITUATIONS_VALUE =
		situationParsed.length > 0 && situationParsed[0] !== ""
			? situationParsed[0]
			: null;

	const SUBJECTS_VALUE =
		situationParsed.length > 0 && situationParsed[1].length > 0
			? situationParsed[1]
			: null;

	const STATUS_VALUE =
		situationParsed.length > 0 && situationParsed[2].length > 0
			? situationParsed[2]
			: null;

	const DISTRICT_IDS =
		situationParsed.length > 0 && situationParsed[3].length > 0
			? situationParsed[3]
			: null;

	const TASKS_VALUE =
		situationParsed.length > 0 && situationParsed[4] !== ""
			? situationParsed[4]
			: null;

	const startDateParams = paramsFormatted
		? paramsFormatted.startDate === "null"
			? null
			: paramsFormatted.startDate
		: null;

	const finishDateParams = paramsFormatted
		? paramsFormatted.finishDate === "null"
			? null
			: paramsFormatted.finishDate
		: null;

	const upfmlsParams = paramsFormatted
		? paramsFormatted.upfmls === "null"
			? null
			: paramsFormatted.upfmls
		: null;

	const lateDaysParams = paramsFormatted
		? paramsFormatted.lateDays === "null"
			? null
			: paramsFormatted.lateDays
		: null;

	const streetParams = paramsFormatted ? paramsFormatted.street : "";

	const numberParams = paramsFormatted ? paramsFormatted.number : "";

	const keywordParams = paramsFormatted ? paramsFormatted.keyword : "";

	const loteParams = paramsFormatted ? paramsFormatted.lote : "";

	const quadraParams = paramsFormatted ? paramsFormatted.quadra : "";

	const cadastralIndexParams = paramsFormatted
		? paramsFormatted.cadastralIndex
		: "";

	const offendersNameParams = paramsFormatted
		? paramsFormatted.offendersName
		: "";

	const [state, dispatch] = useReducer(reducer, initialState);
	const [currentPageToday, setCurrentPageToday] = useState(
		pageFromStorage.tasksToday || 0
	);
	const [rowsPerPageToday, setRowsPerPageToday] = useState(
		rowsPerPageFromStorage.tasksToday || 5
	);
	const [currentPagePending, setCurrentPagePending] = useState(
		pageFromStorage.tasks || 0
	);
	const [rowsPerPagePending, setRowsPerPagePending] = useState(
		rowsPerPageFromStorage.tasksPending || 5
	);
	const [currentPageAll, setCurrentPageAll] = useState(
		pageFromStorage.tasks || 0
	);
	const [rowsPerPageAll, setRowsPerPageAll] = useState(
		rowsPerPageFromStorage.tasksPending || 15
	);
	const [orderByToday, setOrderByToday] = useState("tasks.expire_at");
	const [directionToday, setDirectionToday] = useState<"asc" | "desc">("asc");
	const [orderByPending, setOrderByPending] = useState("tasks.expire_at");
	const [orderByAll, setOrderByAll] = useState("tasks.expire_at");
	const [directionAll, setDirectionAll] = useState<"asc" | "desc">("asc");
	const [directionPending, setDirectionPending] = useState<"asc" | "desc">(
		"asc"
	);

	const [currentPageCanceled, setCurrentPageCanceled] = useState(pageFromStorage.tasks || 0);
	const [rowsPerPageCanceled, setRowsPerPageCanceled] = useState(rowsPerPageFromStorage.tasksPending || 15);

	const [currentPageSuspended, setCurrentPageSuspended] = useState(pageFromStorage.tasks || 0);
	const [rowsPerPageSuspended, setRowsPerPageSuspended] = useState(rowsPerPageFromStorage.tasksPending || 15);

	const [tab, setTab] = useState(0);
	const [isOpenModal, setIsOpenModal] = useState(false);

	const { data: dataCompany } = useQuery<FetchDetailCompany>(
		FETCH_DETAIL_COMPANY,
		{
			fetchPolicy: "network-only",
			variables: {
				domain:
					subdomain === "localhost"
						? process.env.REACT_DEFAULT_DOMAIN || "lagoasanta"
						: subdomain,
			},
		}
	);

	const upfmlsParamsValue =
		upfmlsParams && upfmlsParams !== "undefined"
			? upfmlsParams === "null"
				? null
				: typeof upfmlsParams === "string"
					? JSON.parse(upfmlsParams.toLowerCase())
					: upfmlsParams
			: null;

	const { data: dataToday, loading: loadingToday } = useQuery<FetchTasksToday>(
		FETCH_TASKS_TODAY,
		{
			fetchPolicy: "network-only",
			variables: {
				limit: rowsPerPageToday,
				page: currentPageToday + 1,
				order_by: orderByToday,
				direction: directionToday,
				keyword: keywordParams,
				start: startDateParams,
				finish: finishDateParams,
				subject_ids: SUBJECTS_VALUE,
				district_ids: DISTRICT_IDS,
				situations: SITUATIONS_VALUE,
				status: STATUS_VALUE,
				tasks: TASKS_VALUE,
				upfmls: upfmlsParamsValue,
				late_days: lateDaysParams,
				street: streetParams,
				number: numberParams,
				lote: loteParams,
				quadra: quadraParams,
				cadastral_index: cadastralIndexParams,
				offenders_name: offendersNameParams,
			},
		}
	);

	const { data: dataPending, loading: loadingPending } = useQuery<FetchTasks>(
		FETCH_TASKS,
		{
			fetchPolicy: "network-only",
			variables: {
				is_concluded_at: false,
				limit: rowsPerPagePending,
				page: currentPagePending + 1,
				order_by: orderByPending,
				direction: directionPending,
				keyword: keywordParams,
				start: startDateParams,
				finish: finishDateParams,
				subject_ids: SUBJECTS_VALUE,
				district_ids: DISTRICT_IDS,
				situations: SITUATIONS_VALUE,
				status: STATUS_VALUE,
				tasks: TASKS_VALUE,
				upfmls: upfmlsParamsValue,
				late_days: lateDaysParams,
				street: streetParams,
				number: numberParams,
				lote: loteParams,
				quadra: quadraParams,
				cadastral_index: cadastralIndexParams,
				offenders_name: offendersNameParams,
			},
		}
	);

	const { data: dataAll, loading: loadingAll } = useQuery<FetchTasks>(
		FETCH_TASKS,
		{
			fetchPolicy: "network-only",
			variables: {
				all: true,
				limit: rowsPerPageAll,
				page: currentPageAll + 1,
				order_by: orderByAll,
				direction: directionAll,
				keyword: keywordParams,
				start: startDateParams,
				finish: finishDateParams,
				subject_ids: SUBJECTS_VALUE,
				situations: SITUATIONS_VALUE,
				status: STATUS_VALUE,
				tasks: TASKS_VALUE,
				district_ids: DISTRICT_IDS,
				late_days: lateDaysParams,
				street: streetParams,
				number: numberParams,
				upfmls: upfmlsParamsValue,
				lote: loteParams,
				quadra: quadraParams,
				cadastral_index: cadastralIndexParams,
				offenders_name: offendersNameParams,
			},
		}
	);

	const { data: dataCount, loading: loadingCount } = useQuery<countTasks>(
		COUNT_TASKS,
		{
			fetchPolicy: "network-only",
			variables: {
				start: startDateParams,
				finish: finishDateParams,
				subject_ids: SUBJECTS_VALUE,
				district_ids: DISTRICT_IDS,
				late_days: lateDaysParams,
				street: streetParams,
				number: numberParams,
				lote: loteParams,
				quadra: quadraParams,
				cadastral_index: cadastralIndexParams,
				offenders_name: offendersNameParams,
				situations: SITUATIONS_VALUE,
				status: STATUS_VALUE,
				upfmls: upfmlsParamsValue,
				tasks: TASKS_VALUE,
				keyword: keywordParams,
			},
		}
	);

	const { data: dataSuspended, loading: loadingSuspended } = useQuery<FetchTasks>(
		FETCH_TASKS,
		{
			fetchPolicy: "network-only",
			variables: {
				all: true,
				limit: rowsPerPageSuspended,
				page: currentPageSuspended,
				order_by: orderByAll,
				keyword: keywordParams,
				start: startDateParams,
				finish: finishDateParams,
				subject_ids: SUBJECTS_VALUE,
				situations: ["Suspenso"],
				status: STATUS_VALUE,
				tasks: TASKS_VALUE,
				district_ids: DISTRICT_IDS,
				late_days: lateDaysParams,
				street: streetParams,
				number: numberParams,
				upfmls: upfmlsParamsValue,
				lote: loteParams,
				quadra: quadraParams,
				cadastral_index: cadastralIndexParams,
				offenders_name: offendersNameParams,
			},
		}
	);

	const { data: dataCanceled, loading: loadingCanceled } = useQuery<FetchTasks>(
		FETCH_TASKS,
		{
			fetchPolicy: "network-only",
			variables: {
				all: true,
				limit: rowsPerPageCanceled,
				page: currentPageCanceled,
				order_by: orderByAll,
				keyword: keywordParams,
				start: startDateParams,
				finish: finishDateParams,
				subject_ids: SUBJECTS_VALUE,
				situations: ["Cancelado"],
				status: STATUS_VALUE,
				tasks: TASKS_VALUE,
				district_ids: DISTRICT_IDS,
				late_days: lateDaysParams,
				street: streetParams,
				number: numberParams,
				upfmls: upfmlsParamsValue,
				lote: loteParams,
				quadra: quadraParams,
				cadastral_index: cadastralIndexParams,
				offenders_name: offendersNameParams,
			},
		}
	);


	const isLoading =
		loadingToday || loadingPending || loadingAll || loadingCount || loadingSuspended || loadingCanceled;

	const valuesSituation = [
		{
			id: 1,
			name: "Em andamento",
		},
		{
			id: 2,
			name: "Concluido",
		},
		{
			id: 3,
			name: "Cancelado",
		},
		{
			id: 4,
			name: "Suspenso",
		},
	];

	useEffect(() => {
		const storedTab = localStorage.getItem("tasksTab");
		if (storedTab) {
			setTab(parseInt(storedTab));
		}
	}, []);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
		localStorage.setItem("tasksTab", newValue.toString());
	};

	const handleOpenModal = () => {
		setIsOpenModal(true);
	};

	const handleCloseModal = () => {
		setIsOpenModal(false);
	};

	const handleChangePage = (
		event: React.ChangeEvent<unknown>,
		value: number
	) => {
		setCurrentPageToday(value);
		setCurrentPagePending(value);
		const pageFromStorage: {
			[key: string]: number;
		} = JSON.parse(localStorage.getItem("page")) || {};
		localStorage.setItem(
			"page",
			JSON.stringify({
				...pageFromStorage,
				tasks: value,
				tasksToday: value,
			})
		);
	};

	const handleFilter = (values) => {
		handleChangePage(null, 0);
		dispatch({ type: "keyword", value: values.keyword });
		dispatch({ type: "startDate", value: values.startDate });
		dispatch({ type: "finishDate", value: values.finishDate });
		dispatch({ type: "filters", value: values.situation });
		dispatch({ type: "lateDays", value: values.lateDays });
		dispatch({ type: "street", value: values.street });
		dispatch({ type: "number", value: values.number });
		dispatch({ type: "upfmls", value: values.upfmls });
		dispatch({ type: "lote", value: values.lote });
		dispatch({ type: "quadra", value: values.quadra });
		dispatch({ type: "cadastralIndex", value: values.cadastralIndex });
		dispatch({ type: "offendersName", value: values.offendersName });

		const data = {
			keyword: values.keyword,
			startDate: values.startDate ? values.startDate : null,
			finishDate: values.finishDate ? values.finishDate : null,
			upfmls: values.upfmls,
			lateDays: values.lateDays,
			street: values.street,
			number: values.number,
			lote: values.lote,
			quadra: values.quadra,
			cadastralIndex: values.cadastralIndex,
			offendersName: values.offendersName,
			situation: JSON.stringify(
				values.situation.map((situation) => {
					if (situation.options.length > 0) {
						if (situation.id === 0 || situation.id === 4) {
							const situationOptions = situation.options.map(
								(item) => item.name
							);
							return situationOptions;
						}
						return situation.options;
					}
					return "";
				})
			),
		};

		navigate(
			{
				pathname: "/tarefas",
				search: new URLSearchParams(data).toString(),
			},
			{ replace: true }
		);

		handleCloseModal();
	};

	useEffect(() => {
		dispatch({ type: "keyword", value: keywordParams ?? "" });
		dispatch({ type: "startDate", value: startDateParams ?? null });
		dispatch({ type: "finishDate", value: finishDateParams ?? null });
		dispatch({ type: "lateDays", value: lateDaysParams ?? null });
		dispatch({ type: "street", value: streetParams ?? "" });
		dispatch({
			type: "upfmls",
			value: upfmlsParamsValue,
		});
		dispatch({ type: "number", value: numberParams ?? "" });
		dispatch({ type: "lote", value: loteParams ?? "" });
		dispatch({ type: "quadra", value: quadraParams ?? "" });
		dispatch({ type: "cadastralIndex", value: cadastralIndexParams ?? "" });
		dispatch({ type: "offendersName", value: offendersNameParams ?? "" });

		if (
			paramsFormatted &&
			paramsFormatted.situation &&
			paramsFormatted.situation.length > 0
		) {
			const paramsParsed = JSON.parse(paramsFormatted.situation);

			const updatedFilters = state.filters.map((filter) => {
				const updatedFilter = { ...filter };

				if (filter.id === 0) {
					updatedFilter.options = valuesSituation.filter(
						(item) => SITUATIONS_VALUE && SITUATIONS_VALUE.includes(item.name)
					);
				} else if (filter.id === 1) {
					updatedFilter.options =
						paramsParsed[filter.id] === "" ? [] : paramsParsed[filter.id];
				} else if (filter.id === 2) {
					updatedFilter.options =
						paramsParsed[filter.id] === "" ? [] : paramsParsed[filter.id];
				} else if (filter.id === 3) {
					updatedFilter.options =
						paramsParsed[filter.id] === "" ? [] : paramsParsed[filter.id];
				} else if (filter.id === 4) {
					updatedFilter.options = dataTasksWithId?.filter(
						(item) => TASKS_VALUE && TASKS_VALUE.includes(item.name)
					);
				}

				return updatedFilter;
			});

			dispatch({ type: "filters", value: updatedFilters });
		} else {
			const updatedFilters = state.filters.map((filter) => ({
				...filter,
				options: [],
			}));

			dispatch({ type: "filters", value: updatedFilters });
		}
	}, [dataTasks]);

	return (
		<>
			<Box
				width="100%"
				display="flex"
				alignSelf="stretch"
				flexDirection="column"
				sx={{
					backgroundColor: "#f3f3f3",
				}}
			>
				<Header title="Tarefas" />

				<Container
					sx={{ display: "flex", flexDirection: "column" }}
					maxWidth="lg"
				>
					<Box
						sx={{
							backgroundImage: `url(${dataCompany && dataCompany.detailCompany.logo
								})`,
							backgroundSize: "contain",
							backgroundPosition: "center",
							backgroundRepeat: "no-repeat",
						}}
						minHeight="70px"
						marginTop={2}
					/>

					<Box>
						<Grid container spacing={2}>
							<Grid item xs={12} md={3} rowGap={2}>
								<Item>
									<Stack spacing={2} height="100%">
										{isLoading ? (
											<CircularProgress />
										) : (
											<CountTask
												{...{
													countTasks: dataCount,
												}}
											/>
										)}
										<Button
											variant="contained"
											fullWidth
											sx={{
												marginTop: 2,
											}}
											onClick={handleOpenModal}
										>
											Filtros
										</Button>
									</Stack>
								</Item>
							</Grid>

							<Grid item xs={12} md={9} marginBottom={2}>
								<Item
									sx={{
										marginBottom: 2,
									}}
								>
									<Tabs
										value={tab}
										onChange={handleChange}
										aria-label="tarefas"
									>
										<Tab label="Tarefas pendentes" {...a11yProps(0)} />
										<Tab label="Para hoje" {...a11yProps(1)} />
										<Tab label="Suspensas" {...a11yProps(2)} />
										<Tab label="Canceladas" {...a11yProps(3)} />
										<Tab label="Todas as tarefas" {...a11yProps(2)} />
									</Tabs>
								</Item>
								{isLoading ? (
									<CircularProgress />
								) : (
									<>
										{tab === 0 && (
											<ListPending
												{...{
													fetchTasksPending: dataPending.fetchTasks.data,
													currentPagePending,
													setCurrentPagePending,
													rowsPerPagePending,
													setRowsPerPagePending,
													setOrderByPending,
													setDirectionPending,
													orderByPending,
													directionPending,
													total: dataPending.fetchTasks.total,
												}}
											/>
										)}
										{tab === 1 && (
											<ListToday
												{...{
													fetchTasksToday: dataToday.fetchTasksToday.data,
													currentPageToday,
													setCurrentPageToday,
													rowsPerPageToday,
													setRowsPerPageToday,
													setOrderByToday,
													setDirectionToday,
													orderByToday,
													directionToday,
													total: dataToday.fetchTasksToday.total,
												}}
											/>
										)}
										{tab === 2 && (
											<ListSuspended
												{...{
													fetchTasksSuspended: dataSuspended.fetchTasks.data,
													currentPageSuspended,
													setCurrentPageSuspended,
													rowsPerPageSuspended,
													setRowsPerPageSuspended,
													setOrderByAll,
													setDirectionAll,
													orderByAll,
													directionAll,
													total: dataSuspended.fetchTasks.total,
												}}
											/>
										)}
										{tab === 3 && (
											<ListCanceled
												{...{
													fetchTasksListCanceled: dataCanceled.fetchTasks.data,
													currentPageCanceled,
													setCurrentPageCanceled,
													rowsPerPageCanceled,
													setRowsPerPageCanceled,
													setOrderByAll,
													setDirectionAll,
													orderByAll,
													directionAll,
													total: dataCanceled.fetchTasks.total,
												}}
											/>
										)}
										{tab === 4 && (
											<ListAll
												{...{
													fetchTasksAll: dataAll.fetchTasks.data,
													currentPageAll,
													setCurrentPageAll,
													rowsPerPageAll,
													setRowsPerPageAll,
													setOrderByAll,
													setDirectionAll,
													orderByAll,
													directionAll,
													total: dataAll.fetchTasks.total,
												}}
											/>
										)}
									</>
								)}
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Box>
			{isOpenModal && (
				<TasksFilter
					handleClose={handleCloseModal}
					open={isOpenModal}
					filters={state.filters}
					keyword={state.keyword}
					lateDays={state.lateDays}
					upfmls={state.upfmls}
					number={state.number}
					street={state.street}
					finishDate={state.finishDate}
					startDate={state.startDate}
					handleFilter={handleFilter}
					cadastralIndex={state.cadastralIndex}
					lote={state.lote}
					quadra={state.quadra}
					offendersName={state.offendersName}
					tasksLabel={dataTasksWithId}
				/>
			)}
		</>
	);
}

