import { Stack, Typography } from "@mui/material";
import { format, parse } from "date-fns";
import { Event } from "types/graphql/queries/fetchDocuments";

interface SignedForProps {
	event: Event;
}

export function SignedFor({ event }: SignedForProps) {
	const parsedDate = event.created_at
		? parse(event.created_at, "yyyy-MM-dd HH:mm:ss", new Date())
		: null;
	return (
		<Stack spacing={1}>
			<Stack>
				<Typography variant="caption">Assinado por:</Typography>
				<Typography variant="subtitle2" fontWeight={700}>
					{event.recipient.name}
				</Typography>
			</Stack>
			<Stack>
				<Typography variant="caption">Email:</Typography>
				<Typography variant="subtitle2">{event.recipient.email}</Typography>
			</Stack>
			<Stack>
				<Typography variant="caption">IP:</Typography>
				<Typography variant="subtitle2">{event.ip_address}</Typography>
			</Stack>
			<Stack>
				<Typography variant="caption">Em:</Typography>
				<Typography variant="subtitle2">
					{parsedDate ? format(parsedDate, "dd/MM/yyyy HH:mm:ss") : " - "}
				</Typography>
			</Stack>
		</Stack>
	);
}
