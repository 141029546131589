import {
	Card,
	CardContent,
	CardHeader,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useVerifyNoHasRestriction } from "utils/useVerifyNoHasRestriction";
import StarIcon from "@mui/icons-material/Star";
import ListIcon from "@mui/icons-material/List";
import TaskIcon from "@mui/icons-material/Task";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import AlarmIcon from "@mui/icons-material/Alarm";

export function DefaultReports() {
	const navigate = useNavigate();

	const noHasRestrictionReportsByMonth = useVerifyNoHasRestriction(
		"FECHAMENTO_PONTUACAO"
	);
	const noHasRestrictionDescontinuedProtocolsh = useVerifyNoHasRestriction(
		"PROTOCOLOS_DESCONTINUADOS"
	);
	const noHasRestrictionLateProtocols = useVerifyNoHasRestriction(
		"PROTOCOLOS_ATRASADOS"
	);
	const noHasRestrictionPontuation = useVerifyNoHasRestriction("PONTUACAO");
	return (
		<Card>
			<CardHeader title="Relatórios disponíveis" />
			<CardContent>
				<List component="nav">
					{noHasRestrictionPontuation && (
						<ListItemButton onClick={() => navigate("/relatorios/pontuacao")}>
							<ListItemIcon>
								<StarIcon />
							</ListItemIcon>
							<ListItemText primary="Pontuação" />
						</ListItemButton>
					)}

					{noHasRestrictionReportsByMonth && (
						<ListItemButton
							onClick={() => navigate("/relatorios/fechamento-pontuacao")}
						>
							<ListItemIcon>
								<ListIcon />
							</ListItemIcon>
							<ListItemText primary="Fechamento de pontuação" />
						</ListItemButton>
					)}

					<ListItemButton
						onClick={() => navigate("/relatorios/protocolos-concluidos")}
					>
						<ListItemIcon>
							<TaskIcon />
						</ListItemIcon>
						<ListItemText primary="Protocolos concluídos" />
					</ListItemButton>

					{noHasRestrictionDescontinuedProtocolsh && (
						<ListItemButton
							onClick={() => navigate("/relatorios/protocolos-descontinuados")}
						>
							<ListItemIcon>
								<DoDisturbIcon />
							</ListItemIcon>
							<ListItemText primary="Protocolos descontinuados" />
						</ListItemButton>
					)}

					{noHasRestrictionLateProtocols && (
						<ListItemButton
							onClick={() => navigate("/relatorios/protocolos-atrasados")}
						>
							<ListItemIcon>
								<AlarmIcon />
							</ListItemIcon>
							<ListItemText primary="Protocolos atrasados" />
						</ListItemButton>
					)}
					<ListItemButton
						onClick={() => navigate("/relatorios/protocolos-limpeza-de-lotes")}
					>
						<ListItemIcon>
							<AssessmentIcon />
						</ListItemIcon>
						<ListItemText primary="Relatório anual de limpeza de lote Lei (4523/2020)" />
					</ListItemButton>
				</List>
			</CardContent>
		</Card>
	);
}
