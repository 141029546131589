import Input from "components/Input";
import { Stack } from "@mui/material";
import { RadioButton } from "components/RadioButton";
import { CheckboxButtonGroup } from "components/CheckboxButton";
import { Select } from "components/Select";

import { FetchStatus } from "types/graphql/queries/fetchStatus";
import { FetchOccurrenceTypes } from "types/graphql/queries/fetchOccurrenceTypes";

import { FetchPerfis } from "types/graphql/queries/fetchPerfis";

interface DefaultFormProps {
	node?: {
		type?: string;
	};
	dataStatus?: FetchStatus;
	dataOccurrenceTypes?: FetchOccurrenceTypes;
	dataPerfis?: FetchPerfis;
}

export function DefaultForm({
	node,
	dataOccurrenceTypes,
	dataPerfis,
	dataStatus,
}: DefaultFormProps) {
	return (
		<Stack spacing={2} margin={2}>
			<Input name="label" placeholder="Nome da Tarefa" />
			<Input name="deadline" placeholder="Prazo para realizar tarefa (dias)" />
			<RadioButton
				label="Realizar tarefa"
				name="deadLineToDo"
				radios={[
					{
						label: "No último dia",
						value: "ON",
					},
					{
						label: "Até último dia",
						value: "UNTIL",
					},
				]}
			/>
			<CheckboxButtonGroup
				checks={[
					{
						name: "sendEmail",
						label: "Notificar autuado 1 dia antes",
					},
				]}
			/>
			{dataStatus && (
				<Select
					label="Status"
					name="status"
					options={dataStatus.fetchStatus.data.map((status) => ({
						title: status.name,
						value: status.name,
					}))}
				/>
			)}
			{dataOccurrenceTypes && node.type === "OCCURRENCE" && (
				<Select
					label="Tipo"
					name="occurrenceType"
					options={dataOccurrenceTypes.fetchOccurrenceTypes.data.map(
						(occurrence) => ({
							title: occurrence.name,
							value: occurrence.name,
						})
					)}
				/>
			)}
			{dataPerfis && node.type !== "START" && (
				<Select
					label="Perfil"
					name="toPerfil"
					options={dataPerfis.fetchPerfis.map((perfil) => ({
						title: perfil.name,
						value: perfil.name.toUpperCase(),
					}))}
				/>
			)}
			<CheckboxButtonGroup
				checks={[
					{
						name: "cancelInfraction",
						label: "Anular infração",
					},
					{
						name: "requiredAttached",
						label: "Anexo Obrigatório",
					},
					{
						name: "requiredDescription",
						label: "Parecer Obrigatório",
					},
					{
						name: "advanceNextTask",
						label: "Avançar para próxima tarefa automaticamente",
					},
				].filter(check => !(check.name === 'advanceNextTask' && node.type === 'CONDITION'))}
			/>
		</Stack>
	);
}
