import { Header } from "components/Header";
import {
	Box,
	CircularProgress,
	Container,
	Link,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { EmptyMessage } from "components/EmptyMessage";
import noUser from "assets/img/noUser.png";
import { FetchMyPoints } from "types/graphql/queries/fetchMyPoints";
import { FETCH_MY_POINTS } from "graphql/queries/fetchMyPoints";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import { FETCH_DETAIL_COMPANY } from "graphql/queries/fetchDetailCompany";
import { FetchDetailCompany } from "types/graphql/queries/fetchDetailCompany";
import { FETCH_POINTS_BY_USER } from "graphql/queries/fetchPointsByUser";
import LocalProvider from "components/LocalProvider";

export function Pontuation() {
	const [currentPage, setCurrentPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);

	const { data, loading } = useQuery<FetchMyPoints>(FETCH_MY_POINTS, {
		fetchPolicy: "network-only",
		variables: {
			limit: rowsPerPage,
			page: currentPage + 1,
		},
	});

	const { data: dataPoints, loading: loadingPoints } =
		useQuery(FETCH_POINTS_BY_USER);

	const subdomain = window.location.hostname.split(".")[0];

	const { data: dataCompany } = useQuery<FetchDetailCompany>(
		FETCH_DETAIL_COMPANY,
		{
			variables: {
				domain:
					subdomain === "localhost"
						? process.env.REACT_DEFAULT_DOMAIN || "lagoasanta"
						: subdomain,
			},
		}
	);

	const isLoading = loading || loadingPoints;

	return (
		<Box
			width="100%"
			display="flex"
			alignSelf="stretch"
			flexDirection="column"
			sx={{
				backgroundColor: "#f3f3f3",
			}}
			paddingBottom="1rem"
		>
			<Header title="Pontuação" />

			<Container maxWidth="md">
				<Box
					sx={{
						backgroundImage: `url(${
							dataCompany && dataCompany.detailCompany.logo
						})`,
						backgroundSize: "contain",
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
					}}
					minHeight="70px"
					marginTop={2}
					marginBottom={3}
				/>

				<Card>
					<CardContent>
						{isLoading && <CircularProgress />}
						<Stack direction="row" flex={1} spacing={2}>
							<Box>
								<Typography variant="caption">Pontos acumulados</Typography>
								<Typography variant="h4" component="div">
									{dataPoints?.pointsByUser.accumulated_month || "0"}
								</Typography>
								<Typography variant="overline">No mês</Typography>
							</Box>
							<Box>
								<Typography variant="caption">Pontos acumulados</Typography>
								<Typography variant="h4" component="div">
									{dataPoints?.pointsByUser.accumulated_today || 0}
								</Typography>
								<Typography variant="overline">Hoje</Typography>
							</Box>
						</Stack>

						<TableContainer style={{ marginTop: "10px" }}>
							{data?.fetchMyPoints?.total === 0 && (
								<>
									<EmptyMessage message="Nenhuma pontuação para listar" />
									<Box
										marginTop={2}
										sx={{
											backgroundImage: `url(${noUser})`,
											backgroundRepeat: "no-repeat",
											backgroundPosition: "center",
											backgroundSize: "contain",
											height: "200px",
										}}
									/>
								</>
							)}

							{data?.fetchMyPoints?.total !== 0 && (
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Protocolo</TableCell>
											<TableCell>Data</TableCell>
											<TableCell>Pontos acumulados</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data &&
											data?.fetchMyPoints?.data.map((row) => (
												<>
													<TableRow key={row.protocol}>
														<TableCell>
															<Link
																href={`/protocolo/${row.protocol}`}
																underline="hover"
																sx={{ fontWeight: 600, display: "block" }}
															>
																{row.protocol}
															</Link>
														</TableCell>
														<TableCell>{row.accumulated_at}</TableCell>
														<TableCell>{row.points}</TableCell>
													</TableRow>
												</>
											))}
									</TableBody>
								</Table>
							)}
						</TableContainer>

						{data?.fetchMyPoints?.total !== 0 && (
							<LocalProvider locale="ptBR">
								<TablePagination
									showFirstButton
									showLastButton
									rowsPerPageOptions={[5, 15, 30, 60, 100]}
									component="div"
									count={data?.fetchMyPoints?.total}
									rowsPerPage={rowsPerPage}
									page={currentPage}
									onPageChange={(event, newPage) => {
										setCurrentPage(newPage);
									}}
									onRowsPerPageChange={(event) => {
										setRowsPerPage(parseInt(event.target.value));
										setCurrentPage(0);
									}}
								/>
							</LocalProvider>
						)}
					</CardContent>
				</Card>
			</Container>
		</Box>
	);
}
