/* eslint-disable no-mixed-spaces-and-tabs */
import {
	Link,
} from "@mui/material";
import React, { Fragment } from "react";

type ShowFilesProps = {
	array_Files?: Array<{ id: number; name: string; url: string }>;
};

export function ShowFiles({
    array_Files
}: ShowFilesProps) {
    const verification = array_Files && array_Files.length > 0

	return (
		<>
			{verification &&
				array_Files.map((file) => (
					<Fragment key={file.id}>
						<span> │ </span>
						<Link
							variant="body2"
							fontSize="0.8rem"
							target="_blank"
							rel="norefferer"
							href={file.url}
						>
							{file.name}
						</Link>
					</Fragment>
				))}
		</>
	);
}
