	import { gql } from "@apollo/client";

export const DETAIL_PUBLIC_PROTOCOL = gql`
	query ($code: String!) {
		detailPublicProtocol(code: $code) {
			code
			responsible_user{
				id
				name
				email
				params{
					matricula
				}
			}
			updated_at
			created_at
		}
	}
`;
