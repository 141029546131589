/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import { Box, Backdrop } from "@mui/material";
import React, { useCallback, useState, useRef, useEffect, useContext } from "react";
import { Header } from "components/Header";
import ReactFlow, {
	Node,
	addEdge,
	Edge,
	Connection,
	useNodesState,
	useEdgesState,
	Controls,
	ReactFlowProvider,
	MarkerType,
	updateEdge,
	useKeyPress,
} from "reactflow";
import "reactflow/dist/style.css";
import { SaveFabButton } from "components/SaveFabButton";
import { v4 as uuidv4 } from "uuid";
import { useForm, FormProvider } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { nodeTypes } from "utils/nodeTypes";
import { edgeTypes } from "utils/edgeTypes";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BackgroundFlow from "components/Flow/BackgroundFlow";
import { ModalFlow } from "components/Flow/Modal";
import ConfigurationPanel from "components/Flow/ConfigurationPanel";
import ModalUpdateEdgeLabel from "components/Flow/Modal/Form/updateLabelEdge";
import { useQuery } from "@apollo/client";
import { DETAIL_FLOW } from "graphql/queries/detailFlow";
import { useParams } from "react-router-dom";
import FormPanel from "components/Flow/FormPanel";
import { CircularProgress } from "components/CircularProgress";
import { UPDATE_FLOW } from "graphql/mutations/updateFlow";
import { DetailFlowQuery } from "types/graphql/queries/detailFlow";
import CustomConnectionLine from "pages/Flow/CustomConnectionLine";
import { DataFlow } from "./types";
import HistoryPanel from "components/Flow/HistoryPanel";
import findEdgePosition from "utils/flow/findEdgePosition";
import { FlowProvider, useFlowContext } from "context/FlowContext";

const schema = yup.object().shape({
	name: yup.string().required("Nome é obrigatório"),
	description: yup.string(),
});

const connectionLineStyle = {
	strokeWidth: 3,
	stroke: "gray",
};

const defaultEdgeOptions = {
	style: { strokeWidth: 3, stroke: "gray" },
	type: "floating",
	markerEnd: {
		type: MarkerType.ArrowClosed,
		color: "gray",
	},
};

export function EditFlow() {
	return (
		<FlowProvider isFromCreate={false}>
			<Flow />
		</FlowProvider>
	)
}

function Flow() {

	const { nodes, edges, setNodes, setEdges, onNodesChange, onEdgesChange }: any = useFlowContext()

	const { id: idFlow } = useParams();
	const [versionHistory, setVersionHistory] = useState({
		version: null,
		open: false,
	});

	const { data: dataFlow, loading: loadingDetailFlow } =
		useQuery<DetailFlowQuery>(DETAIL_FLOW, {
			variables: {
				flow_id: Number(idFlow),
				version: versionHistory.open ? versionHistory.version : null,
			},
			fetchPolicy: "network-only",
		});

	const [defaultType, setDefaultType] = useState(false);
	const [isOpenModalFlow, setIsOpenModalFlow] = useState(false);
	const [isOpenModalUpdateEdgeLabel, setIsOpenModalUpdateEdgeLabel] = useState(false);
	const [isEdgeHover, setIsEdgeHover] = useState(null);
	const [reactFlowInstance, setReactFlowInstance] = useState(null);
	const [backGroundVariant, setBackgroundVariant] = useState<
		"lines" | "dots" | "cross"
	>("lines");

	const [nodeClicked, setNodeClicked] = useState(null);
	const [edgeClicked, setEdgeClicked] = useState<Edge>(null);

	const edgeUpdateSuccessful = useRef(true);
	const reactFlowWrapper = useRef(null);
	const deletePressed = useKeyPress("Delete");
	const onEdgeUpdateStart = useCallback(() => {
		edgeUpdateSuccessful.current = false;
	}, []);

	const [updateFlow, { loading }] = useMutation(UPDATE_FLOW);
	const methods = useForm({
		resolver: yupResolver(schema),
	});
	const { handleSubmit, setValue } = methods;

	useEffect(() => {
		if (dataFlow) {
			setVersionHistory((prevState) => ({
				...prevState,
				version: versionHistory.open
					? versionHistory.version
					: dataFlow.detailFlow.version,
			}));
			setValue("name", dataFlow.detailFlow.name);
			setValue("description", dataFlow.detailFlow.description || "");
			setValue(
				"refuseComplaint",
				dataFlow.detailFlow.auto_finish_on_complaint_refused === true
					? "true"
					: "false"
			);
			setValue("type", dataFlow.detailFlow.spec_start_official_protocol || "");
			setValue(
				"status",
				dataFlow.detailFlow.status === "Ativo" ? "true" : "false"
			);

			const initialNodes: Node[] = dataFlow.detailFlow.spec
				.filter((item) => item.group === "nodes" && item.data.name)
				.map((item) => {
					return {
						id: item.data._id,
						type: item.data.type,
						data: {
							label: item.data.name,
							faveColor: item.data.faveColor,
							pointDescription: item.data.pointDescription || "",
							point: item.data.point || 0,
							deadline: item.data.deadline || 0,
							deadLineToDo: item.data.deadlineTodo || false,
							status: item.data.status
								? {
									name: item.data.status.label,
									color: item.data.status.color,
								}
								: null,
							occurrenceType: item.data.occurrenceType
								? {
									id: item.data.occurrenceType._id[0],
									name: item.data.occurrenceType.name,
									form: item.data.occurrenceType.form,
								}
								: null,
							cancelInfraction: item.data.cancelInfraction || false,
							sendEmail: item.data.sendEmail || false,
							requiredAttached: item.data.requiredAttached || false,
							requiredDescription: item.data.requiredDescription || false,
							advanceNextTask: item.data.advanceNextTask || false,
							toPerfil: item.data.toPerfil
								? {
									id: item.data.toPerfil._id,
									hasChildren: item.data.toPerfil.hasChildren,
									height: item.data.toPerfil.height,
									width: item.data.toPerfil.width,
									name: item.data.toPerfil.name,
									perfilFeatures: item.data.toPerfil.perfilFeatures
										? item.data.toPerfil.perfilFeatures.map((perfil) => ({
											id: perfil.id,
											name: perfil.name,
											authority: perfil.authority,
										}))
										: null,
								}
								: null,
						},
						group: "nodes",
						locked: item.locked,
						removed: item.removed,
						selectable: item.selectable,
						selected: item.selected,
						position: {
							x: Number(item.position.x),
							y: Number(item.position.y),
						},
					};
				});
			const initialEdges: Edge[] = dataFlow.detailFlow.spec
				.filter((item) => item.group === "edges")
				.map((item) => {
					return {
						id: item.data._id,
						source: item.data.source,
						target: item.data.target,
						label: item.data.name,
						data: {
							requiredAttached: item.data.requiredAttached ? item.data.requiredAttached : false
						},
					};
				});
			setNodes(initialNodes);
			setEdges(initialEdges);
			setDefaultType(true);
		}
	}, [dataFlow]);

	const { enqueueSnackbar } = useSnackbar();

	const onEdgeUpdate = useCallback(
		(oldEdge: Edge, newConnection: Connection) => {
			edgeUpdateSuccessful.current = true;
			setEdges((els) => updateEdge(oldEdge, newConnection, els));
		},
		[]
	);

	const onEdgeUpdateEnd = useCallback((_, edge: Edge) => {
		if (!edgeUpdateSuccessful.current) {
			setEdges((eds) => eds.filter((e) => e.id !== edge.id));
		}

		edgeUpdateSuccessful.current = true;
	}, []);

	const handleOpenModalUpdateEdgeLabel = useCallback((edge: Edge) => {
		setIsOpenModalUpdateEdgeLabel(true);
		setEdgeClicked(edge);
	}, []);

	const handleCloseModalUpdateEdgeLabel = useCallback(() => {
		setIsOpenModalUpdateEdgeLabel(false);
		setEdgeClicked(null);
	}, []);

	const handleUpdateEdgeLabel = useCallback((edge) => {
		setEdges((els) =>
			updateEdge(
				edge,
				{
					...edge,
					label: edge.label,
					requiredAttached: edge.data.requiredAttached,
				},
				els
			)
		);
		handleCloseModalUpdateEdgeLabel();
	}, []);

	function onRequestCloseModal() {
		setIsOpenModalFlow(false);
		setNodeClicked(null);
	}

	const onNodeUpdate = useCallback((node: Node) => {
		setNodes((nodes) => {
			const index = nodes.findIndex((n) => n.id === node.id);
			const newNodes = [...nodes];
			newNodes[index] = node;
			return newNodes;
		});
		onRequestCloseModal();
	}, []);

	const onNodeSelect = (node: Node) => {
		setNodeClicked(node);
		setIsOpenModalFlow(true);
	};

	const onNodeSelectToRemove = (node: Node) => {
		setNodeClicked(node);
	};

	useEffect(() => {
		if (deletePressed && nodeClicked) {
			const idNodeStart = nodes.filter((node) => node.type === "START")[0].id;
			const findEdgeStart = edges.filter((edge) => edge.source === idNodeStart);

			const targetFromEdgeStartIsNodeTypeOccurrence = findEdgeStart.filter(
				(edge) => {
					const nodeTarget = nodes.filter((node) => node.id === edge.target)[0];
					return nodeTarget.type === "OCCURRENCE";
				}
			);

			const nodeFromTarget = nodes.filter((node) => {
				return targetFromEdgeStartIsNodeTypeOccurrence.some(
					(edge) => edge.target === node.id
				);
			});

			if (nodeFromTarget[0]?.id === nodeClicked.id) {
				enqueueSnackbar("Você não pode remover esse nó ligado ao início", {
					variant: "error",
				});
				return;
			}

			if (nodeClicked.type === "START") {
				enqueueSnackbar("Não é possível excluir o nó inicial", {
					variant: "error",
				});
				return;
			}

			setNodes((nodes) => nodes.filter((node) => node.id !== nodeClicked.id));
			setEdges((edges) =>
				edges.filter(
					(edge) =>
						edge.source !== nodeClicked.id && edge.target !== nodeClicked.id
				)
			);
			setNodeClicked(null);
		}
	}, [deletePressed]);

	const onConnect = useCallback(
		(params: Edge | Connection) =>
			setEdges((els) =>
				addEdge(
					{
						...params,
						id: uuidv4(),
					},
					els
				)
			),
		[setEdges]
	);

	const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = "move";
	}, []);

	const onDrop = useCallback(
		(event: React.DragEvent<HTMLDivElement>) => {
			event.preventDefault();

			const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
			const type = event.dataTransfer.getData("application/reactflow");
			const label = event.dataTransfer.getData("application/reactflow-label");
			const isExistingNodeTypeEnd =
				nodes.filter((node) => node.type === "END").length > 0;

			// check if the dropped element is valid
			if (typeof type === "undefined" || !type) {
				return;
			}

			if (type === "START") {
				enqueueSnackbar("Não é possível adicionar mais de um nó inicial", {
					variant: "warning",
				});
				return;
			}

			if (isExistingNodeTypeEnd && type === "END") {
				enqueueSnackbar("Não é possível adicionar mais de um nó final", {
					variant: "warning",
				});
				return;
			}

			const position = reactFlowInstance.project({
				x: event.clientX - reactFlowBounds.left,
				y: event.clientY - reactFlowBounds.top,
			});
			const newNode = {
				id: uuidv4(),
				type,
				position,
				data: { label },
				group: "nodes",
			};

			setNodes((nds) => nds.concat(newNode));
		},
		[reactFlowInstance, nodes]
	);

	const onSubmit = async (data: {
		name: string;
		description: string;
		type: string;
		refuseComplaint: string;
		status: string;
	}) => {
		const nodeOccurrenceType = nodes.filter(
			(item) => item.type === "OCCURRENCE"
		);
		const isExistsNodeOccurrenceTypeWithoutOccurrenceType =
			nodeOccurrenceType.some((item) => item.data.occurrenceType === null);

		if (isExistsNodeOccurrenceTypeWithoutOccurrenceType) {
			return enqueueSnackbar(
				"Você precisa selecionar um tipo de ocorrência para todos os nós de ocorrência",
				{
					variant: "warning",
				}
			);
		}

		const isNotExistisNodeEnd =
			nodes.filter((node) => node.type === "END").length === 0;

		const idNodeStart = nodes.filter((node) => node.type === "START")[0].id;
		const findEdgeStart = edges.filter((edge) => edge.source === idNodeStart);
		const nodesConectedsInTheStart = nodes.filter((node) => {
			return findEdgeStart.some((edge) => edge.target === node.id);
		});

		const isSomeNodeIsNotTypeOccurrence = nodesConectedsInTheStart.some(
			(node) => node.type !== "OCCURRENCE"
		);

		const targetFromEdgeStartIsNodeTypeOccurrence =
			findEdgeStart.filter((edge) => {
				const nodeTarget = nodes.filter((node) => node.id === edge.target)[0];
				return nodeTarget.type === "OCCURRENCE";
			}).length === 0;

		if (
			targetFromEdgeStartIsNodeTypeOccurrence ||
			isSomeNodeIsNotTypeOccurrence
		) {
			enqueueSnackbar("O nó inicial deve ser seguido de um nó de ocorrência", {
				variant: "error",
			});
			return;
		}

		if (isNotExistisNodeEnd) {
			enqueueSnackbar("É necessário adicionar um nó final", {
				variant: "warning",
			});
			return;
		}

		const nodesFormated = nodes.map((node) => {
			return {
				data: {
					_id: node.id,
					name: node.data.label,
					type: node.type,
					faveColor: node.data.faveColor || "",
					deadline: Number(node.data.deadline) || null,
					cancelInfraction: node.data.cancelInfraction || false,
					deadlineTodo: node.data.deadLineToDo || "",
					occurrenceType: node.data.occurrenceType
						? {
							_id: [node.data.occurrenceType.id.toString()],
							name: node.data.occurrenceType.name,
							form: node.data.occurrenceType.dynamic_form
								? {
									_id: [node.data.occurrenceType.dynamic_form.code],
								}
								: node.data.occurrenceType.form
									? {
										_id: node.data.occurrenceType.form._id,
									}
									: null,
						}
						: null,
					requiredAttached: node.data.requiredAttached || false,
					requiredDescription: node.data.requiredDescription || null,
					advanceNextTask: node.data.advanceNextTask || false,
					sendEmail: node.data.sendEmail || null,
					status: node.data.status
						? {
							label: node.data.status.name,
							color: node.data.status.theme,
						}
						: null,
					toPerfil: node.data.toPerfil
						? {
							_id: String(node.data.toPerfil.id),
							hasChildren: node.data.toPerfil.hasChildren,
							height: node.data.toPerfil.height,
							width: node.data.toPerfil.width,
							name: node.data.toPerfil.name,
							perfilFeatures: node.data.toPerfil.perfilFeatures
								? node.data.toPerfil.perfilFeatures.map((perfil) => ({
									id: perfil.id,
									name: perfil.name,
									authority: perfil.authority,
								}))
								: null,
						}
						: null,
					point: Number(node.data.point) || null,
					pointDescription: node.data.pointDescription || null,
				},
				graspable: node.data.graspable || false,
				group: "nodes",
				locked: node.data.locked || false,
				position: {
					x: node.position.x,
					y: node.position.y,
				},
				removed: node.data.removed || false,
				selectable: node.data.selectable || false,
				selected: node.data.selected || false,
			};
		});

		const edgesFormated = edges.map((edge) => {
			return {
				data: {
					_id: edge.id,
					source: edge.source,
					target: edge.target,
					type: edge.type,
					name: edge.label as string,
					requiredAttached: edge?.data?.requiredAttached,
				},
			};
		});

		const dataFormatedFlow: DataFlow = {
			id: Number(idFlow),
			name: data.name,
			status: data.status === "true" ? "Ativo" : "Inativo",
			description: data.description,
			spec_start_official_protocol: data.type,
			auto_finish_on_complaint_refused:
				data.refuseComplaint === "true" ? true : false,
			spec: [
				...nodesFormated,
				...edgesFormated.map((edge) => ({
					...edge,
					group: "edges",
				})),
			],
		};
		try {
			await updateFlow({
				variables: dataFormatedFlow,
			});
			enqueueSnackbar("Fluxo atualizado com sucesso", { variant: "success" });
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
	};

	const onSwitchBackground = useCallback(
		(variant: "lines" | "dots" | "cross") => {
			setBackgroundVariant(variant);
		},
		[]
	);

	return (
		<>
			{loadingDetailFlow ? (
				<Backdrop
					sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={true}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			) : (
				<>
					{dataFlow && (
						<FormProvider {...methods}>
							<Box
								width="100%"
								display="flex"
								alignSelf="stretch"
								flexDirection="column"
								sx={{
									backgroundColor: "#f3f3f3",
								}}
								paddingBottom="1rem"
							>
								<Header title="Fluxo" isReturn />

								<ReactFlowProvider>
									<Box
										ref={reactFlowWrapper}
										flexGrow={1}
										height="100%"
										sx={{ position: "relative" }}
									>
										<ReactFlow
											nodes={nodes}
											edges={edges}
											nodeTypes={nodeTypes}
											edgeTypes={edgeTypes}
											onNodeDoubleClick={(event, node) => onNodeSelect(node)}
											onNodeClick={(event, node) => onNodeSelectToRemove(node)}
											onEdgeDoubleClick={(event, edge) =>
												handleOpenModalUpdateEdgeLabel(edge)
											}
											onNodesChange={onNodesChange}
											onEdgesChange={onEdgesChange}
											onConnect={onConnect}
											onEdgeUpdate={onEdgeUpdate}
											onEdgeUpdateStart={onEdgeUpdateStart}
											onEdgeUpdateEnd={onEdgeUpdateEnd}
											onInit={setReactFlowInstance}
											onDrop={onDrop}
											onDragOver={onDragOver}
											defaultEdgeOptions={defaultEdgeOptions}
											connectionLineComponent={CustomConnectionLine}
											connectionLineStyle={connectionLineStyle}
											fitView
										>
											<BackgroundFlow
												variants={backGroundVariant}
												color="#ccc"
											/>
											<Controls
												position="top-right"
												style={{
													display: "flex",
												}}
											>
												<ConfigurationPanel
													handleSwitchBackgroundVariant={onSwitchBackground}
												/>
											</Controls>

											{versionHistory.open && (
												<HistoryPanel
													versions={dataFlow && dataFlow.detailFlow.versions}
													currentVersion={
														dataFlow && dataFlow.detailFlow.version
													}
													{...{ versionHistory, setVersionHistory }}
												/>
											)}

											{!versionHistory.open && (
												<FormPanel
													{...{ setValue, setVersionHistory }}
													statusInput={dataFlow?.detailFlow?.status === "Ativo"}
													nodesOccurrencyType={nodes
														.filter((item) => item.type === "OCCURRENCE")
														.map((item) => ({
															_id: item.id as string,
															name: item.data.label as string,
														}))}
													defaultType={defaultType}
												/>
											)}
										</ReactFlow>
									</Box>
								</ReactFlowProvider>
							</Box>

							{nodeClicked && (
								<ModalFlow
									open={isOpenModalFlow}
									handleClose={() => onRequestCloseModal()}
									title="Editar Nó"
									typeForm={
										nodeClicked?.type === "PONTUATE" ? "pontuate" : "default"
									}
									node={nodeClicked}
									onNodeUpdate={onNodeUpdate}
								/>
							)}

							{edgeClicked && (
								<ModalUpdateEdgeLabel
									open={isOpenModalUpdateEdgeLabel}
									title="Atualizar Label"
									handleClose={handleCloseModalUpdateEdgeLabel}
									edge={edgeClicked}
									handleUpdateEdge={(edge) => handleUpdateEdgeLabel(edge)}
								/>
							)}

							{!versionHistory.open && (
								<SaveFabButton
									onClick={handleSubmit(onSubmit)}
									isLoading={loading}
								/>
							)}
						</FormProvider>
					)}
				</>
			)}
		</>
	);
}
