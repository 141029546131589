import { Stack, Typography } from "@mui/material";

interface DetailPontuationProps {
	title: string;
	concluded_at: string;
	concluded_by: string;
	points: number;
	matricula: string;
	undo_at?: string;
}

export function DetailPontuation({
	concluded_at,
	concluded_by,
	points,
	matricula,
	undo_at,
}: DetailPontuationProps) {
	return (
		<Stack
			minHeight={100}
			sx={{
				opacity: undo_at ? 0.7 : 1,
			}}
		>
			<Typography variant="subtitle2" fontWeight={500} fontSize="0.9rem">
				Pontuação
			</Typography>
			<Typography variant="caption" fontSize="0.8rem">
				{points} pontos
			</Typography>
			<Typography variant="caption" fontSize="0.8rem">
				Acumulado em {concluded_at || "-"}
			</Typography>
			{concluded_by && (
				<>
					<Typography variant="caption" fontSize="0.8rem">
						por {concluded_by}
					</Typography>
					{matricula && (
						<Typography variant="caption" fontSize="0.8rem">
							Matrícula {matricula}
						</Typography>
					)}
				</>
			)}
		</Stack>
	);
}
