import { ModalLayout } from "components/ModalLayout";
import { ModalLayoutProps } from "types/ModalLayout";
import { Stack, Typography } from "@mui/material";
import Input from "components/Input";
import { GreenSwitch } from "components/GreenSwitch";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "components/IconButton";
import { v4 as uuidv4 } from "uuid";
import { Component } from "..";

type DataForm = {
	componentName: string;
	isRequired: boolean;
	options: Array<{
		id: string;
		label: string;
		value: string;
	}>;
};

type ModalEditFieldProps = ModalLayoutProps & {
	component: Component;
	handleEditComponent?: (component: Component) => void;
};

export function ModalEditField({
	open,
	handleClose,
	title,
	component,
	handleEditComponent,
}: ModalEditFieldProps) {
	const methods = useForm({
		defaultValues: {
			componentName: component.name,
			isRequired: component.isRequired,
			options: component.options,
		},
	});

	const { handleSubmit, control } = methods;

	const { fields, append, remove } = useFieldArray({
		control,
		name: "options",
	});

	const handleAddOption = () => {
		append({
			id: uuidv4(),
			label: `Opção ${fields.length + 1}`,
			value: `Opção ${fields.length + 1}`,
		});
	};

	const onSubmit = (data: DataForm) => {
		handleEditComponent({
			...component,
			name: data.componentName,
			isRequired: data.isRequired,
			options: data.options,
		});
	};

	return (
		<FormProvider {...methods}>
			<ModalLayout
				open={open}
				handleClose={handleClose}
				title={title}
				isNotShowButtonClose
				sx={{
					"& .MuiDialog-paper": {
						width: "100%",
					},
				}}
				handleSubmit={handleSubmit(onSubmit)}
			>
				<Stack spacing={2} flex={1}>
					<Stack spacing={1} marginTop={2}>
						<Input name="componentName" placeholder="Título do campo" />
						<GreenSwitch label="Obrigatório" name="isRequired" />
					</Stack>
					{component.isOption && (
						<Stack spacing={3}>
							<Typography variant="h6">Opções</Typography>

							{fields.map((item, index) => {
								const isLastIndex = index === fields.length - 1;
								return (
									<Stack key={item.id} spacing={2}>
										<Stack spacing={1} direction="row">
											<Input
												name={`options.${index}.label`}
												placeholder={`Opção ${index + 1}`}
											/>
											{isLastIndex ? (
												<IconButton onClick={() => handleAddOption()}>
													<AddIcon fontSize="large" />
												</IconButton>
											) : (
												<IconButton onClick={() => remove(index)}>
													<DeleteIcon fontSize="large" />
												</IconButton>
											)}
										</Stack>
									</Stack>
								);
							})}
						</Stack>
					)}
				</Stack>
			</ModalLayout>
		</FormProvider>
	);
}
