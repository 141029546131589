import { useMutation } from "@apollo/client"
import { TextField, Grid } from "@mui/material"
import { ModalLayout } from "components/ModalLayout"
import { UPDATE_ADDRESS_PROTOCOL } from "graphql/mutations/updateAddressProtocol"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { DetailProtocol } from "types/graphql/queries/detailProtocol"
import { useSnackbar } from "notistack"

import { maskCEP } from "utils/maskOutputs"
import { useEffect, useState } from "react"

interface ModalEditAddressProps {
    isOpen: boolean
    onClose: () => void
    code: string
    refetch: () => void
    protocol: DetailProtocol["detailProtocol"];
}

const schema = yup.object().shape({
    justification: yup.string().required('Justificativa é obrigatória'),
    zipcode: yup.string(),
    street: yup.string(),
    number: yup.string(),
    complement: yup.string(),
    district_name: yup.string(),
});

export function ModalEditAddress({
    isOpen,
    onClose,
    code,
    refetch,
    protocol,
}: ModalEditAddressProps) {
    const [cepLocal, setCepLocal] = useState(protocol.zipcode || '');

    const [editAddressMutation, { loading }] = useMutation(UPDATE_ADDRESS_PROTOCOL)
    const { handleSubmit, control, setValue } = useForm({
        defaultValues: {
            justification: protocol.justification || '',
            zipcode: protocol.zipcode || '',
            street: protocol.street || '',
            number: protocol.number || '',
            complement: protocol.complement || '',
            district_name: protocol.district && protocol.district.name ? protocol.district.name : '',
        },
        resolver: yupResolver(schema),
    });

    const { enqueueSnackbar } = useSnackbar();


    const handleCEP = async (cep: string) => {
        if (cep.length === 8) {
            try {
                const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
                const data = await response.json();
                const { logradouro: street, bairro: district_name } = data;
                setValue('street', street);
                setValue('district_name', district_name);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const onSubmit = async (data) => {
        try {
            await editAddressMutation({
                variables: {
                    code: code,
                    ...data
                },
            });

            enqueueSnackbar("Endereço editado com sucesso", {
                variant: "success",
            });

            refetch();
            onClose();
        } catch (error) {
            enqueueSnackbar("Erro ao editar o endereço", {
                variant: "error",
            });
            console.error(error);
        }
    }

    useEffect(() => {
        handleCEP(cepLocal);
    }, [cepLocal]);

    return (
        <ModalLayout
            open={isOpen}
            handleClose={onClose}
            title="Editar endereço"
            handleSubmit={handleSubmit(onSubmit)}
            buttonTextClose="Cancelar"
            buttonText="Salvar"
            isDisabled={loading}
            isLoading={loading}
        >
            <Grid container spacing={2} mt={0.1}>
                <Grid item xs={6}>
                    <Controller
                        name="zipcode"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="CEP"
                                fullWidth
                                onChange={(e) => {
                                    let cep = e.target.value;
                                    cep = cep.replace('-', '');
                                    setValue('zipcode', cep);
                                    setCepLocal(cep);
                                }}
                                value={maskCEP(field.value)}
                                inputProps={{ maxLength: 9 }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="street"
                        control={control}
                        render={({ field }) => <TextField {...field} label="Rua" fullWidth />}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="number"
                        control={control}
                        render={({ field }) => <TextField {...field} label="Número" fullWidth />}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="district_name"
                        control={control}
                        render={({ field }) =>
                            <TextField {...field} label="Bairro" fullWidth />
                        }

                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="complement"
                        control={control}
                        render={({ field }) => <TextField {...field} label="Complemento" fullWidth />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="justification"
                        control={control}
                        render={({ field, fieldState: { error } }) =>
                            <TextField
                                {...field}
                                label="Justificativa*"
                                multiline
                                rows={4}
                                fullWidth
                                error={!!error}
                                helperText={error ? error.message : null}
                            />}
                    />
                </Grid>
            </Grid>
        </ModalLayout>
    )
}