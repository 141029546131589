/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import { ModalVisualization } from "components/DynamicForm/Modal/VisualizationForm";
import { SaveFabButton } from "components/SaveFabButton";
import { VisualizationFabButton } from "components/VisualizationFabButton";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { Box, Container, Grid, Stack, Backdrop } from "@mui/material";
import { Header } from "components/Header";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Item } from "components/Paper";
import { Title } from "components/Title";
import ComponentsDynamicForm from "components/DynamicForm/ComponentsDynamicForm";
import GroupsDynamicForm from "components/DynamicForm/GroupsDynamicForm";
import { useCallback, useState, useEffect } from "react";
import Button from "components/Button";
import { Component } from "pages/DynamicForm";
import componentsDinamycForm from "utils/fieldsDynamicForm";
import Input from "components/Input";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { FETCH_DETAIL_DYNAMIC_FORM } from "graphql/queries/fetchDetailDynamicForm";
import { CircularProgress } from "components/CircularProgress";
import { FetchDetailDynamicForm } from "types/graphql/queries/fetchDetailDynamicForm";
import { UPDATE_DYNAMIC_FORM } from "graphql/mutations/updateDynamicForm";

const schema = yup.object().shape({
	formName: yup.string().required("Nome do formulário é obrigatório"),
});

type DataFormated = {
	groups?: Array<{
		title: string;
		inputs: Array<{
			name: string;
			size: number;
			type: string;
			label: string;
			valor: number;
			required: boolean;
			placeholder: string;
			nomeComponente: string;
			tipoComponenteDinamico: string;
			options: Array<{
				id: number;
				name: string;
			}>;
		}>;
	}>;
};

export function EditDynamicForm() {
	const { id: idDynamicForm } = useParams();
	const { data: dataDynamicForm, loading: loadingDetail } =
		useQuery<FetchDetailDynamicForm>(FETCH_DETAIL_DYNAMIC_FORM, {
			fetchPolicy: "network-only",
			variables: { dynamic_form_id: Number(idDynamicForm) },
		});
	const [updateDynamicForm, { loading: loadingUpdateDynamicForm }] =
		useMutation(UPDATE_DYNAMIC_FORM);
	const methods = useForm({
		defaultValues: {
			groups: [],
			formName: "",
		},
		resolver: yupResolver(schema),
	});
	const { handleSubmit, control, setValue, getValues } = methods;
	const { fields, append, remove, update } = useFieldArray({
		control,
		name: "groups",
	});
	const [isOpenModalVisualization, setIsOpenModalVisualization] =
		useState(false);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (dataDynamicForm) {
			setValue("formName", dataDynamicForm.detailDynamicForm.name);
			const groups = dataDynamicForm.detailDynamicForm.groups
				? dataDynamicForm.detailDynamicForm.groups.map((group) => ({
						id: uuidv4(),
						name: group.title,
						components: group.inputs.map((input) => ({
							id: uuidv4(),
							name: input.label,
							type: input.type,
							isEditable:
								componentsDinamycForm.find((component) => {
									return component.type === input.type;
								})?.isEditable || false,
							isRequired: input.required,
							isOption: input.options ? true : false,
							component: componentsDinamycForm.find((component) => {
								return component.type === input.type;
							})?.component,
							options:
								input.options && input.options.length > 0
									? input.options.map((option) => ({
											id: option.id,
											label: option.name,
											value: option.name,
									  }))
									: null,
						})),
				  }))
				: [];
			setValue("groups", groups);
		}
	}, [dataDynamicForm]);

	async function onSubmit(data: any) {
		const dataFormated: DataFormated = data.groups.map((group) => ({
			title: group.name,
			inputs: group.components.map((component, index) => {
				const uuid = uuidv4();
				return {
					name: component.name ? component.name.toLowerCase() + index : uuid,
					type: component.type,
					label: component.name,
					placeholder: component.name,
					required: component.isRequired,
					options:
						(component.options &&
							component.options.map((option) => ({
								id: option.label,
								name: option.label,
							}))) ||
						null,
				};
			}),
		}));

		try {
			await updateDynamicForm({
				variables: {
					id: Number(idDynamicForm),
					name: data.formName,
					groups: dataFormated,
				},
			});
			enqueueSnackbar("Formulário registrado com sucesso", {
				variant: "success",
			});
		} catch (err) {
			enqueueSnackbar(err.message, {
				variant: "error",
			});
		}
	}

	function handleAddGroup() {
		append({
			id: uuidv4(),
			name: `Grupo ${fields.length + 1}`,
			components: [],
		});
	}

	const handleRemoveGroup = useCallback((index: number) => {
		remove(index);
	}, []);

	const onDragEnd = (result: DropResult) => {
		const { destination, source } = result;

		const findIndexGroup = fields.findIndex((field) => {
			return field.id === destination.droppableId;
		});

		const findComponent = componentsDinamycForm.find((component) => {
			return component.label === source.droppableId;
		});

		// If user tries to drop in an unknown destination
		if (!destination) return;

		// if the user drags and drops back in the same position
		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		) {
			return;
		}
		const values = getValues("groups");

		const concatComponents = fields.map((field) => {
			if (field.id === destination.droppableId) {
				return {
					...field,
					name: values[findIndexGroup].name,
					components: [
						...field.components,
						{
							id: uuidv4(),
							name: findComponent?.label,
							type: findComponent?.type,
							component: findComponent?.component,
							isEditable: findComponent?.isEditable,
							isRequired: findComponent?.isRequired,
							isOption: findComponent?.isOption,
							options: findComponent?.options,
						},
					],
				};
			}
			return field;
		});

		setValue("groups", concatComponents);
	};

	const handleRemoveComponent = useCallback(
		(id: string) => {
			const values = getValues("groups");

			const newComponents = values.map((value) => {
				return {
					...value,
					components: value.components.filter((component) => {
						return component.id !== id;
					}),
				};
			});
			setValue("groups", newComponents);
		},
		[fields]
	);

	const handleEditComponent = useCallback(
		(
			indexGroup: number,
			indexComponent: number,
			componentEdited: Component
		) => {
			const findIndexField = fields.findIndex(
				(field) => field.id === fields[indexGroup].id
			);
			const findIndexComponent = fields[indexGroup].components.findIndex(
				(component) =>
					component.id === fields[indexGroup].components[indexComponent].id
			);
			const values = getValues("groups");

			update(findIndexField, {
				...fields[findIndexField],
				name: values[findIndexField].name,
				components: fields[indexGroup].components.map((component, index) => {
					if (index === findIndexComponent) {
						return componentEdited;
					}
					return component;
				}),
			});
		},
		[fields]
	);

	function onRequestOpenModalVisualization() {
		setIsOpenModalVisualization(true);
	}

	function onRequestCloseModalVisualization() {
		setIsOpenModalVisualization(false);
	}

	return (
		<>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={loadingDetail}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<FormProvider {...methods}>
				<Box
					width="100%"
					display="flex"
					alignSelf="stretch"
					flexDirection="column"
					sx={{
						backgroundColor: "#f3f3f3",
					}}
				>
					<Header title="Formulário" isReturn />

					<Container
						sx={{ display: "flex", flexDirection: "column" }}
						maxWidth="lg"
					>
						<Box sx={{ flexGrow: 1 }} marginY="1rem">
							<DragDropContext onDragEnd={onDragEnd}>
								<Grid container spacing={2}>
									<Grid item xs={12} md={4} rowGap={2}>
										<Item>
											<Stack spacing={4}>
												<Box>
													<Title
														title="Dados do formulário"
														marginBottom={2}
														fontSize="1rem"
													/>
													<Input
														name="formName"
														placeholder="Nome do formulário"
														autoFocus
														autoComplete="off"
													/>
												</Box>
												<ComponentsDynamicForm />
											</Stack>
										</Item>
									</Grid>

									<Grid item xs={12} md={8}>
										<Stack spacing={2}>
											{fields.map((field, index) => (
												<Item key={field.id}>
													<GroupsDynamicForm
														index={index}
														handleRemoveGroup={(index) =>
															handleRemoveGroup(index)
														}
														group={field}
														handleRemoveComponent={(id) =>
															handleRemoveComponent(id)
														}
														handleEditComponent={(
															indexGroup,
															indexComponent,
															component
														) =>
															handleEditComponent(
																indexGroup,
																indexComponent,
																component
															)
														}
													/>
												</Item>
											))}
											<Box alignSelf="flex-end">
												<Button
													variant="contained"
													onClick={() => handleAddGroup()}
													type="button"
												>
													Adicionar grupo
												</Button>
											</Box>
										</Stack>
									</Grid>
								</Grid>
							</DragDropContext>
						</Box>
						<VisualizationFabButton
							onClick={() => onRequestOpenModalVisualization()}
						/>
						<SaveFabButton
							onClick={handleSubmit(onSubmit)}
							isLoading={loadingUpdateDynamicForm}
						/>
					</Container>
				</Box>
				<ModalVisualization
					open={isOpenModalVisualization}
					handleClose={() => onRequestCloseModalVisualization()}
					buttonText="Fechar"
				/>
			</FormProvider>
		</>
	);
}
