export const zendeskWidget = new Promise<void>((resolve, reject) => {

	const createZWidget = (pathname: string) => {

		const regex = /\/(webdenuncia|verificar)/
		const containsRegex = regex.test(pathname);

		if (containsRegex) return

		const script = document.createElement("script");
		script.src = `https://static.zdassets.com/ekr/snippet.js?key=7bcd27b2-8428-4d15-86c1-931c9b11e096`;
		script.setAttribute("id", "ze-snippet");
		script.async = true;
		script.addEventListener("load", function () {
			resolve();
		});
		script.addEventListener("error", function (e) {
			reject(e);
		});
		document.body.appendChild(script);
	}

	let currentPath = window.location.pathname
	createZWidget(currentPath)

	window.addEventListener('popstate', () => {
		currentPath = window.location.pathname
		createZWidget(currentPath)
	});


});
