import { gql } from "@apollo/client";

export const FETCH_HOLIDAYS = gql`
	query ($keyword: String, $order_by: String, $direction: String, $page: Int, $limit: Int) {
		fetchHolidays(keyword: $keyword, order_by: $order_by, direction: $direction, page: $page, limit: $limit) {
			total
			current_page
			per_page
			last_page
			data {
				id
				company_id
				day
				month
				year
				name
				created_at
				updated_at
			}
		}
	}
`;
