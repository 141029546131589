import { ModalLayout } from "components/ModalLayout";
import { ModalLayoutProps } from "types/ModalLayout";
import { Typography } from "@mui/material";

type ModalConfirmationDelectionProps = ModalLayoutProps & {
	message: string;
	buttonSubmitText?: string;
	centerButtons?: boolean;
};

export function ModalConfirmationDelection({
	open,
	handleClose,
	title,
	message,
	handleSubmit,
	isLoading,
	buttonSubmitText,
	centerButtons,
	...rest
}: ModalConfirmationDelectionProps) {
	return (
		<ModalLayout
			open={open}
			title={title}
			handleClose={handleClose}
			buttonText={buttonSubmitText ?? "Excluir"}
			buttonTextClose="Cancelar"
			handleSubmit={handleSubmit}
			isLoading={isLoading}
			centerButtons={centerButtons}
			{...rest}
		>
			<Typography variant="body2" textAlign="center">
				{message}
			</Typography>
		</ModalLayout>
	);
}
