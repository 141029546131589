/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Route, Routes } from "react-router-dom";
import { Layout } from "components/Layout";
import { Unauthorized } from "pages/Unauthorized";
import { Missing } from "pages/Missing";
import { Login } from "pages/Login";
import RequireAuth from "components/RequireAuth";
import { useLocation, Navigate } from "react-router-dom";
import { ForgotPassword } from "pages/ForgotPassword";
import { useAuthContext } from "context/AuthProvider";
import { Dashboard } from "pages/Dashboard";
import { Protocol } from "pages/Protocols";
import { ProtocolDetail } from "pages/ProtocolDetail";
import { Settings } from "pages/Settings";
import { useSidebarContext } from "context/SidebarProvider";
import { useEffect } from "react";
import { Tasks } from "pages/Tasks";
import { Complaints } from "pages/Fiscalization/Complaints";
import { RegulatoryAct } from "pages/Fiscalization/RegulatoryAct";
import { TaxGroups } from "pages/Fiscalization/TaxGroups";
import { Matters } from "pages/Fiscalization/Matters";
import { Flow } from "pages/Flow";
import { EditFlow } from "pages/EditFlow";
import { FormList } from "pages/FormList";
import { DynamicForm } from "pages/DynamicForm";
import { TaskState } from "pages/TaskState";
import { OccurrenceTypes } from "pages/OccurrenceTypes";
import { EditDynamicForm } from "pages/EditDynamicForm";
import { Groups } from "pages/Groups";
import { RegulatoryActs } from "pages/RegulatoryActs";
import { Subjects } from "pages/Subjects";
import { Pontuation } from "pages/Pontuation";
import { Reports } from "pages/Reports";
import { WebReport } from "pages/WebReport";
import { WebReportDetail } from "pages/WebReportDetail";
import { ValidateProtocol } from "pages/ValidateProtocol";
import { ReportPoints } from "pages/Reports/DefaultReports/ReportPoints";
import { ReportPointsByMonth } from "pages/Reports/DefaultReports/ReportPointsByMonth";
import { Perfis } from "pages/Perfis";
import { OS } from "pages/OS";
import { ReportFinishedProtocols } from "pages/Reports/DefaultReports/ReportFinishedProtocols";
import { ReportDescontinuedProtocols } from "pages/Reports/DefaultReports/ReportDescontinuedProtocols";
import { ReportLateProtocols } from "pages/Reports/DefaultReports/ReportLateProtocols";
import { UserMobiles } from "pages/UserMobiles";
import { Users } from "pages/Users";
import { Flows } from "pages/Flows";
import { Holidays } from "pages/Holidays";
import { Districts } from "pages/Districts";
import { useVerifyNoHasRestriction } from "utils/useVerifyNoHasRestriction";
import { zendeskWidget } from "utils/loadScriptWidget";
import { ReportBatchCleaningAnnual } from "pages/Reports/DefaultReports/ReportBatchCleaningAnnual";
import { Inbox } from "pages/DocumentManager/Inbox";
import { Dump } from "pages/DocumentManager/Dump";
import { Sent } from "pages/DocumentManager/Sent";
import { ValidateDocument } from "pages/ValidateDocument";
import { DetailAnalyticalReport } from "../pages/Reports/CustomReports/DetailAnalyticalReport";
import { DetailSinteticReport } from "pages/Reports/CustomReports/DetailSinteticReport";

export function RoutesComponent() {
	const { TOKEN_USER } = useAuthContext();
	const location = useLocation();
	const { closeSidebar } = useSidebarContext();

	useEffect(() => {
		setTimeout(() => {
			if (TOKEN_USER) {
				zendeskWidget.then(() => {
					// @ts-ignore
					if (zE && zE.hide) {
						// @ts-ignore
						zE.show();
					}
				});
				return;
			} else {
				zendeskWidget.then(() => {
					// @ts-ignore
					if (zE && zE.hide) {
						// @ts-ignore
						zE.hide();
					}
				});
				return;
			}
		}, 1000);
	});

	const switchedPathname = location.pathname;

	useEffect(() => {
		closeSidebar();
	}, [switchedPathname]);

	if (location.pathname === "/") {
		return <Navigate to="/login" replace />;
	} else if (TOKEN_USER && location.pathname === "/login") {
		return <Navigate to="/dashboard" replace />;
	}

	const noHasRestricitonFlow = useVerifyNoHasRestriction("FLUXO");
	const noHasRestricitonUser = useVerifyNoHasRestriction("USUARIO");
	const noHasRestricitonStatus = useVerifyNoHasRestriction("STATUS");
	const noHasRestricitonProfile = useVerifyNoHasRestriction("PERFIL");
	const noHasRestricitonCreateDynamicForm = useVerifyNoHasRestriction(
		"FORMULARIO_DINAMICO_CRIAR"
	);
	const noHasRestricitonRegulatoryActs =
		useVerifyNoHasRestriction("ATO_REGULATORIO");
	const noHasRestricitonTaxGroups = useVerifyNoHasRestriction("GRUPO_FISCAIS");
	const noHasRestricitonOccurrenceTypes =
		useVerifyNoHasRestriction("TIPO_OCORRENCIA");
	const noHasRestrictionReportsByMonth = useVerifyNoHasRestriction(
		"FECHAMENTO_PONTUACAO"
	);
	const noHasRestrictionDescontinuedProtocols = useVerifyNoHasRestriction(
		"PROTOCOLOS_DESCONTINUADOS"
	);
	const noHasRestrictionLateProtocols = useVerifyNoHasRestriction(
		"PROTOCOLOS_ATRASADOS"
	);

	const noHasRestrictionPontuation = useVerifyNoHasRestriction("PONTUACAO");
	const noHasRestrictionComplaint =
		useVerifyNoHasRestriction("DENUNCIA_AVALIAR");

	return (
		<>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route path="login" element={<Login />} />
					{/* <Route path="esqueci-minha-senha" element={<ForgotPassword />} /> */}
					<Route path="unauthorized" element={<Unauthorized />} />
					<Route path="/webdenuncia" element={<WebReport />} />
					<Route
						path="/webdenuncia/acompanhar-denuncia/:protocolo"
						element={<WebReportDetail />}
					/>
					<Route path="/verificar/:protocolo" element={<ValidateProtocol />} />
					<Route
						path="/verificar-documento/:id"
						element={<ValidateDocument />}
					/>

					<Route element={<RequireAuth />}>
						<Route path="/dashboard" element={<Dashboard />} />
						<Route path="/protocolos" element={<Protocol />} />
						<Route path="/protocolo/:code" element={<ProtocolDetail />} />
						<Route path="/ordem-de-serviço" element={<OS />} />
						<Route path="/pontuacao" element={<Pontuation />} />
						<Route path="/relatorios" element={<Reports />} />
						<Route
							path="/relatorios/pontuacao"
							element={
								noHasRestrictionPontuation ? <ReportPoints /> : <Unauthorized />
							}
						/>
						<Route
							path="/relatorios/fechamento-pontuacao"
							element={
								noHasRestrictionReportsByMonth ? (
									<ReportPointsByMonth />
								) : (
									<Unauthorized />
								)
							}
						/>
						<Route
							path="/relatorios/protocolos-concluidos"
							element={<ReportFinishedProtocols />}
						/>
						<Route
							path="/relatorios/protocolos-descontinuados"
							element={
								noHasRestrictionDescontinuedProtocols ? (
									<ReportDescontinuedProtocols />
								) : (
									<Unauthorized />
								)
							}
						/>
						<Route
							path="/relatorios/protocolos-atrasados"
							element={
								noHasRestrictionLateProtocols ? (
									<ReportLateProtocols />
								) : (
									<Unauthorized />
								)
							}
						/>
						<Route
							path="/relatorios/protocolos-limpeza-de-lotes"
							element={<ReportBatchCleaningAnnual />}
						/>
						<Route
							path="/relatorios/relatorio-analitico/:id"
							element={<DetailAnalyticalReport />}
						/>
						<Route
							path="/relatorios/relatorio-sintetico/:id"
							element={<DetailSinteticReport />}
						/>
						<Route path="/configuracoes" element={<Settings />} />
						<Route path="/tarefas" element={<Tasks />} />
						<Route
							path="/fiscalizacao/denuncias"
							element={
								noHasRestrictionComplaint ? <Complaints /> : <Unauthorized />
							}
						/>
						<Route
							path="/atos-regulatorios"
							element={
								noHasRestricitonRegulatoryActs ? (
									<RegulatoryAct />
								) : (
									<Unauthorized />
								)
							}
						/>
						<Route path="/grupos-fiscais" element={<TaxGroups />} />
						<Route path="/assuntos" element={<Matters />} />
						<Route
							path="/criar-fluxo"
							element={noHasRestricitonFlow ? <Flow /> : <Unauthorized />}
						/>
						<Route
							path="/editar-fluxo/:id"
							element={noHasRestricitonFlow ? <EditFlow /> : <Unauthorized />}
						/>
						<Route
							path="/configuracoes/criar-formulario"
							element={
								noHasRestricitonCreateDynamicForm ? (
									<DynamicForm />
								) : (
									<Unauthorized />
								)
							}
						/>

						<Route path="/configuracoes/formulario" element={<FormList />} />

						<Route
							path="/configuracoes/editar-formulario/:id"
							element={
								noHasRestricitonCreateDynamicForm ? (
									<EditDynamicForm />
								) : (
									<Unauthorized />
								)
							}
						/>

						<Route
							path="/configuracoes/usuarios"
							element={noHasRestricitonUser ? <Users /> : <Unauthorized />}
						/>

						<Route
							path="/configuracoes/fluxos"
							element={noHasRestricitonFlow ? <Flows /> : <Unauthorized />}
						/>

						<Route
							path="/configuracoes/status-da-tarefa"
							element={
								noHasRestricitonStatus ? <TaskState /> : <Unauthorized />
							}
						/>

						<Route
							path="/configuracoes/perfil"
							element={noHasRestricitonProfile ? <Perfis /> : <Unauthorized />}
						/>
						<Route
							path="/configuracoes/tipo-de-ocorrencia"
							element={
								noHasRestricitonOccurrenceTypes ? (
									<OccurrenceTypes />
								) : (
									<Unauthorized />
								)
							}
						/>

						<Route
							path="/configuracoes/grupo-de-fiscais"
							element={
								noHasRestricitonTaxGroups ? <Groups /> : <Unauthorized />
							}
						/>
						<Route
							path="/configuracoes/atos-regulatorios"
							element={
								noHasRestricitonRegulatoryActs ? (
									<RegulatoryActs />
								) : (
									<Unauthorized />
								)
							}
						/>
						<Route path="/configuracoes/assuntos" element={<Subjects />} />
						<Route
							path="/configuracoes/gerenciar-dispositivos"
							element={<UserMobiles />}
						/>
						<Route path="/configuracoes/feriados" element={<Holidays />} />
						<Route path="/configuracoes/bairros" element={<Districts />} />
						<Route path="/documentos/caixa-de-entrada" element={<Inbox />} />
						<Route path="/documentos/lixeira" element={<Dump />} />
						<Route path="/documentos/enviados" element={<Sent />} />
					</Route>

					<Route path="*" element={<Missing />} />
				</Route>
			</Routes>
		</>
	);
}
