import { gql } from "@apollo/client";

export const UPDATE_RESPONSIBLE_PROTOCOL = gql`
	mutation ($code: String!, $responsible_user_id: Int!, $update_all_tasks: Boolean) {
		updateResponsibleProtocol(
			code: $code
			responsible_user_id: $responsible_user_id
			update_all_tasks: $update_all_tasks
		)
	}
`;
