import React, { useState } from "react";

type HoverLinkProps = {
	href: string;
	children: React.ReactNode;
};

const HoverLink: React.FC<HoverLinkProps> = ({ href, children }) => {
	const [isHovered, setIsHovered] = useState(false);

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	return (
		<a
			href={href}
			rel="noopener noreferrer"
			style={{
				fontWeight: 600,
				display: "block",
				cursor: "pointer",
				color: "rgb(25, 118, 210)",
				textDecoration: isHovered ? "underline" : "none",
				transition: "text-decoration 0.3s",
			}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{children}
		</a>
	);
};

export default HoverLink;
