import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LayersIcon from "@mui/icons-material/Layers";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import SubjectIcon from "@mui/icons-material/Subject";
import CampaignIcon from "@mui/icons-material/Campaign";
import ListAltIcon from "@mui/icons-material/ListAlt";
import InboxIcon from "@mui/icons-material/Inbox";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";

export const sidebar = [
	{
		path: "/dashboard",
		icon: ViewComfyIcon,
		name: "Dashboard",
	},
	{
		path: "/tarefas",
		icon: NotificationsIcon,
		name: "Tarefas",
	},
	{
		path: "/protocolos",
		icon: LayersIcon,
		name: "Protocolos",
	},
	{
		path: "/ordem-de-serviço",
		icon: ListAltIcon,
		name: "OS",
	},
	{
		path: "/pontuacao",
		icon: StarBorderIcon,
		name: "Pontuação",
	},
	{
		path: "/relatorios",
		icon: SubjectIcon,
		name: "Relatórios",
	},
	{
		path: "/configuracoes",
		icon: SettingsIcon,
		name: "Configurações",
	},
];

export const fiscalizationRoutes = [
	{
		path: "/fiscalizacao/denuncias",
		icon: CampaignIcon,
		name: "Denúncias",
	},
];

export const documentManagerRoutes = [
	{
		path: "/documentos/caixa-de-entrada",
		icon: InboxIcon,
		name: "Caixa de Entrada",
	},
	{
		path: "/documentos/enviados",
		icon: SendIcon,
		name: "Enviados",
	},
	{
		path: "/documentos/lixeira",
		icon: DeleteIcon,
		name: "Lixeira",
	},
];
