import {
	Typography,
	Card,
	CardContent,
	CardHeader,
	Box,
	CardActions,
	Button,
	Link
} from "@mui/material";
import { useState } from "react";
import { ModalRefuseComplaint } from "./Modal/ModalRefuseComplaint";
import { DetailProtocol, Task } from "types/graphql/queries/detailProtocol";
import { ModalAcceptComplaint } from "./Modal/ModalAcceptComplaint";
import ImgList from "components/ImgList";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { maskCEP } from "utils/maskOutputs";

interface ComplaintProps {
	complaint: DetailProtocol["detailProtocol"]["complaint"];
	tasks: Task[];
	refetch: () => void;
}

export function Complaint({ complaint, tasks, refetch }: ComplaintProps) {
	const [isOpenRefuseComplaint, setIsOpenRefuseComplaint] = useState(false);
	const [isOpenAcceptComplaint, setIsOpenAcceptComplaint] = useState(false);
	if (!complaint) return null;

	function handleOpenRefuseComplaint() {
		setIsOpenRefuseComplaint(true);
	}

	function handleOpenAcceptComplaint() {
		setIsOpenAcceptComplaint(true);
	}

	return (
		<>
			<Card style={{ marginTop: 16 }}>
				<CardHeader title="Denúncia" />
				<CardContent>
					<Box display="flex" width="100%">
						<Box>
							<Typography variant="caption">Denunciante</Typography>
							<Typography variant="body2">{complaint.name || "-"}</Typography>
						</Box>
						<Box ml={12}>
							<Typography variant="caption">Email</Typography>
							<Typography variant="body2">{complaint.email || "-"}</Typography>
						</Box>
					</Box>
					<Box display="flex" width="100%">
						<Box>
							<Typography variant="caption">Cep</Typography>
							<Typography variant="body2">
								{maskCEP(complaint.zipcode) || "-"}
							</Typography>
						</Box>
						<Box ml={12}>
							<Typography variant="caption">Endereço</Typography>
							<Typography variant="body2">
								{complaint.address || "-"}
							</Typography>
						</Box>
						<Box ml={12}>
							<Typography variant="caption">Número</Typography>
							<Typography variant="body2">{complaint.number || "-"}</Typography>
						</Box>
						<Box ml={12}>
							<Typography variant="caption">Complemento</Typography>
							<Typography variant="body2">
								{complaint.complement || "-"}
							</Typography>
						</Box>
					</Box>
					<Box display="flex" width="100%">
						<Box>
							<Typography variant="caption">Bairro</Typography>
							<Typography variant="body2">
								{complaint.district || "-"}
							</Typography>
						</Box>
						<Box ml={12}>
							<Typography variant="caption">Ponto de referencia</Typography>
							<Typography variant="body2">
								{complaint.reference || "-"}
							</Typography>
						</Box>
					</Box>
					<Box display="flex" width="100%">
						<Box>
							<Typography variant="caption">Descrição</Typography>
							<Typography variant="body2">
								{complaint.description || "-"}
							</Typography>
						</Box>
					</Box>
					<Box sx={{ marginTop: 4 }}>
						{complaint.attachments.length > 0 && (
							<>
								{
									complaint.attachments.map((attch, index) => {
										const split = attch.name.split(".")[1];
										if (split === "pdf") {
											return (
												<Box key={index}>
													<AttachFileIcon />
													<Link
														variant="h6"
														fontSize="1rem"
														href={attch.url}
														target="_blank"
														rel="norefferer"
													>
														{attch.name}
													</Link>
												</Box>
											)
										}
									})
								}
								<ImgList itemsData={complaint.attachments} />
							</>
						)}
					</Box>
				</CardContent>
				{tasks.length === 0 && (
					<CardActions>
						<Button
							size="small"
							variant="contained"
							onClick={handleOpenAcceptComplaint}
						>
							Aceitar denúncia
						</Button>
						<Button
							size="small"
							variant="outlined"
							color="error"
							onClick={handleOpenRefuseComplaint}
						>
							Recusar
						</Button>
					</CardActions>
				)}
			</Card>
			<ModalRefuseComplaint
				open={isOpenRefuseComplaint}
				handleClose={() => setIsOpenRefuseComplaint(false)}
				refetch={refetch}
				code={complaint.code}
			/>
			<ModalAcceptComplaint
				open={isOpenAcceptComplaint}
				handleClose={() => setIsOpenAcceptComplaint(false)}
				refetch={refetch}
				code={complaint.code}
				complaint={complaint}
			/>
		</>
	);
}
