import {
	FormControl,
	Grid,
	InputLabel,
	OutlinedInput,
	Typography,
} from "@mui/material";
import { useFormContext } from "react-hook-form";

interface FieldWitnessProps {
	name?: string;
	index?: number;
	readonly?: boolean;
}

function FieldWitness({ name, index, readonly }: FieldWitnessProps) {
	const { register } = useFormContext();
	return (
		<>
			<Grid item xs={12}>
				<Typography variant="h6" fontSize="1rem" marginBottom={1} marginTop={2}>
					Testemunhas
				</Typography>
				<FormControl variant="outlined" fullWidth>
					<InputLabel htmlFor="witness1">Testemunha 1</InputLabel>
					<OutlinedInput
						readOnly={readonly}
						type={"text"}
						label="Testemunha 1"
						fullWidth
						{...register(`groups.${index}.fields.witnesses.witness1`)}
					/>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<FormControl variant="outlined" fullWidth>
					<InputLabel htmlFor="witness1">Identidade ou CPF</InputLabel>
					<OutlinedInput
						readOnly={readonly}
						id="documentWitness1"
						type={"text"}
						label="Identidade ou CPF"
						fullWidth
						{...register(`groups.${index}.fields.witnesses.documentWitness1`)}
					/>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<FormControl variant="outlined" fullWidth>
					<InputLabel htmlFor="witness1">Testemunha 2</InputLabel>
					<OutlinedInput
						readOnly={readonly}
						id="witness2"
						type={"text"}
						label="Testemunha 2"
						fullWidth
						{...register(`groups.${index}.fields.witnesses.witness2`)}
					/>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<FormControl variant="outlined" fullWidth>
					<InputLabel htmlFor="witness1">Identidade ou CPF</InputLabel>
					<OutlinedInput
						readOnly={readonly}
						id="documentWitness2"
						type={"text"}
						label="Identidade ou CPF"
						fullWidth
						{...register(`groups.${index}.fields.witnesses.documentWitness2`)}
					/>
				</FormControl>
			</Grid>
		</>
	);
}

export default FieldWitness;
