import { Backdrop, Link } from "@mui/material";
import { Stack } from "@mui/material";
import { Box } from "@mui/material";
import Input from "components/Input";
import LoadingButton from "components/LoadingButton";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { AUTH } from "graphql/mutations/auth";
import { useSnackbar } from "notistack";
import { useAuthContext } from "context/AuthProvider";
import { FETCH_DETAIL_COMPANY } from "graphql/queries/fetchDetailCompany";
import { CircularProgress } from "components/CircularProgress";
import { FetchDetailCompany } from "types/graphql/queries/fetchDetailCompany";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
	email: yup.string().required("Nome do usuário obrigatório"),
	password: yup.string().required("Senha obrigatória"),
});

export function Login() {
	const navigate = useNavigate();
	const subdomain = window.location.hostname.split(".")[0];
	const { data, loading: loadingDetailCompany } = useQuery<FetchDetailCompany>(
		FETCH_DETAIL_COMPANY,
		{
			variables: {
				domain:
					subdomain === "localhost"
						? process.env.REACT_DEFAULT_DOMAIN || "lagoasanta"
						: subdomain,
			},
		}
	);
	const { handleLogin } = useAuthContext();
	const { enqueueSnackbar } = useSnackbar();
	const [auth, { loading }] = useMutation(AUTH);
	const methods = useForm({
		resolver: yupResolver(schema),
	});
	const { handleSubmit } = methods;

	async function onSubmit(values: { email: string; password: string }) {
		try {
			const { data } = await auth({
				variables: {
					email: values.email,
					password: values.password,
					domain:
						subdomain === "localhost"
							? process.env.REACT_DEFAULT_DOMAIN || "lagoasanta"
							: subdomain,
				},
			});
			enqueueSnackbar("Login realizado com sucesso", {
				variant: "success",
			});

			handleLogin(data.auth);
		} catch (err) {
			enqueueSnackbar(err.message, {
				variant: "error",
			});
		}
	}
	return (
		<>
			{loadingDetailCompany && (
				<Backdrop
					sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={true}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			)}
			{data && (
				<>
					<Box display={["none", "flex"]} height="100vh" flex={1}>
						<Box
							sx={{
								backgroundImage: `url(${data.detailCompany.cover})`,
								backgroundSize: "cover",
								backgroundPosition: "center",

								width: "100%",
							}}
						/>
					</Box>
					<Stack padding="1rem" flex={1} spacing={2}>
						<Box
							textAlign="center"
							sx={{
								backgroundImage: `url(${data.detailCompany.logo})`,
								backgroundSize: "contain",
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
							}}
							height="100px"
						/>
						<FormProvider {...methods}>
							<Stack
								component="form"
								spacing={2}
								onSubmit={handleSubmit(onSubmit)}
							>
								<Input
									placeholder="E-mail/Nome do usuário"
									type="text"
									name="email"
								/>
								<Input
									placeholder="Senha"
									type="text"
									name="password"
									isPassword
									autoComplete="off"
								/>
								<LoadingButton
									variant="contained"
									type="submit"
									loading={loading}
								>
									Entrar
								</LoadingButton>
								{/* <Box>
									<Link
										underline="hover"
										onClick={() => navigate("/esqueci-minha-senha")}
										sx={{
											cursor: "pointer",
										}}
									>
										Esqueci minha senha
									</Link>
								</Box> */}
							</Stack>
						</FormProvider>
					</Stack>
				</>
			)}
		</>
	);
}
