import {
	Checkbox as MaterialCheckbox,
	FormControlLabel,
	CheckboxProps as MaterialCheckboxProps,
} from "@mui/material";

type CheckboxProps = MaterialCheckboxProps & {
	label: string;
};

export function Checkbox({ label, ...rest }: CheckboxProps) {
	return (
		<FormControlLabel control={<MaterialCheckbox {...rest} />} label={label} />
	);
}
