import React from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	InputAdornment,
	Stack,
} from "@mui/material";
import { Chips } from "components/Chips";
import { useState, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchInput from "../SeachInput";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DocumentNode, useQuery } from "@apollo/client";
import { CircularProgress } from "components/CircularProgress";
import { EmptyMessage } from "components/EmptyMessage";
import useDebounce from "hooks/useDebounce";

interface SelectFilterModalProps {
	onChange: (values: Array<any>) => void;
	fullWidth: boolean;
	queryName: DocumentNode;
	dataName: string;
	title: string;
	valuesSelecteds: Array<{
		id: number;
		name: string;
	}>;
	error?: boolean;
	moreVariables?: {
		[key: string]: number | string | boolean | null;
	};
	order_by: string;
}

export default function SelectFilterModal({
	onChange,
	title,
	fullWidth,
	queryName,
	dataName,
	valuesSelecteds,
	error,
	moreVariables,
	order_by,
}: SelectFilterModalProps) {
	const [newValue, setNewValue] = useState(valuesSelecteds);
	const [showDialog, setShowDialog] = useState(false);
	const [keyword, setKeyword] = useState("");
	const debouncedValue = useDebounce(keyword, 500);

	const { data, loading } = useQuery(queryName, {
		fetchPolicy: "network-only",
		variables: {
			keyword: debouncedValue,
			limit: 500,
			order_by: order_by,
			direction: "asc",
			...moreVariables,
		},
	});

	useEffect(() => {
		setNewValue(valuesSelecteds);
	}, [valuesSelecteds]);

	const values =
		data &&
		data[dataName].data.map((element) => ({
			id: element.id,
			name: element.name,
		}));

	const handleChangeCheckbox = (e, situation: { id: number; name: string }) => {
		const value = e.target.value;
		const index = newValue.findIndex((i) => i.name === value);

		if (index >= 0 && e.target.checked === false) deleteItem(situation.id);
		else {
			setNewValue((prevState) => [...prevState, situation]);
		}
	};

	const deleteItem = (id: number) => {
		setNewValue((prevState) => prevState.filter((prev) => prev.id !== id));
	};

	const handleSelect = () => {
		onChange([...newValue, { title: title }]);
		setShowDialog(false);
	};

	const handleClearFilter = () => {
		setNewValue([]);
		onChange([{ title: title }]);
		setKeyword("");
		setShowDialog(false);
	};

	return (
		<>
			<TextField
				error={error}
				InputProps={{
					readOnly: true,
					endAdornment: (
						<InputAdornment position="end">
							<ArrowDropDownIcon
								style={{ color: "#787878", marginRight: -6 }}
							/>
						</InputAdornment>
					),
				}}
				InputLabelProps={{
					shrink: newValue.length > 0 ? true : false,
				}}
				onClick={() => {
					setShowDialog(true);
				}}
				variant="outlined"
				fullWidth={fullWidth}
				value={newValue.map((element) => element.name).join(", ")}
				label={title}
			/>

			<Dialog
				open={showDialog}
				onClose={() => setShowDialog(false)}
				maxWidth="md"
			>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<SearchInput
						typeSearch="inDialog"
						onChange={(value) => {
							setKeyword(value);
						}}
					/>

					<Stack
						spacing={2}
						flex={1}
						direction="row"
						marginTop={2}
						flexWrap="wrap"
						rowGap={2}
					>
						{newValue.map((element) => {
							return (
								<Chips
									key={element.id}
									label={element.name}
									handleDelete={() => deleteItem(element.id)}
									isDelete
									color="primary"
								/>
							);
						})}
					</Stack>

					<div
						style={{
							marginTop: 20,
							height: 300,
							overflow: "auto",
							display: "flex",
							flexDirection: "column",
						}}
					>
						{loading ? (
							<CircularProgress />
						) : (
							<>
								{data && (
									<FormGroup>
										<Stack flex={1}>
											{values && values.length > 0 ? (
												values.map((situation) => (
													<FormControlLabel
														key={situation.id}
														control={
															<Checkbox
																checked={newValue.some(
																	(i) => i.id === situation.id
																)}
																value={situation.name}
																onChange={(e) => {
																	handleChangeCheckbox(e, situation);
																}}
															/>
														}
														label={situation.name}
													/>
												))
											) : (
												<EmptyMessage message="Nenhum item encontrado" />
											)}
										</Stack>
									</FormGroup>
								)}
							</>
						)}
					</div>
				</DialogContent>

				<DialogActions>
					<Button onClick={() => handleClearFilter()} color="primary">
						Limpar
					</Button>
					<Button
						onClick={handleSelect}
						variant="contained"
						color="primary"
						disabled={loading}
					>
						Selecionar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
