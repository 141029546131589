import { useMutation } from "@apollo/client";

import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";

import { DataUser } from "types/graphql/queries/fetchUsers";
import { RESTORE_USER } from "graphql/mutations/restoreUser";

type RestoreDialogProps = {
    open: boolean;
    handleClose: () => void;
    onRestore: () => void;
    data: DataUser;
}

export default function RestoreDialog({ open, handleClose, onRestore, data }: RestoreDialogProps) {

    const [restoreUser, { loading, error }] = useMutation(RESTORE_USER);

    const handleRestore = async () => {
        try {
            await restoreUser({
                variables: {
                    id: data.id
                }
            });
            onRestore();
            handleClose();
        } catch (error) {
            console.error("Erro ao restaurar o usuário: ", error);
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle id="scroll-dialog-title">Restaurar Usuário</DialogTitle>

                <DialogContent>

                    <p>
                        Deseja realmente restaurar o usuário <strong>{data?.name}</strong>?
                    </p>

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleRestore} variant="contained" color="primary" disabled={loading}>
                        {loading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                        Restaurar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}