import { gql } from "@apollo/client";

export const FETCH_COMPLAINTS = gql`
	query ($keyword: String, $order_by: String, $direction: String, $page: Int, $limit: Int, $evaluated: Boolean) {
		fetchComplaints(keyword: $keyword, order_by: $order_by, direction: $direction, page: $page, limit: $limit, evaluated: $evaluated) {
			total
			current_page
			per_page
			last_page
			data {
				id
				company_id
				code
				name
				email
				zipcode
				address
				number
				complement
				reference
				district_id
				created_at
				updated_at
				protocol {
					id
					code
					situation
					subject {
						id
						name
					}
					responsible_user {
						id
						name
					}
				}
			}
		}
	}
`;
