import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@mui/material";
import IconButton from "components/IconButton";
import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller, useFormContext, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { UPDATE_FLOW_PROTOCOLS } from "graphql/mutations/updateFlowProtocols";
import { format, parse } from "date-fns";
import { useSnackbar } from "notistack";

interface propsModalUpdateFlow {
	open: boolean;
	handleClose: () => void;
}
export function ModalUpdateFlow({ open, handleClose }: propsModalUpdateFlow) {
	const { enqueueSnackbar } = useSnackbar();
	const { control } = useFormContext();
	const [selectedValue, setSelectedValue] = useState("allProtocols");
	const [date, setDate] = useState(null);

	const { id: idFlow } = useParams();

	const [updateFlowProtocols, loading] = useMutation(UPDATE_FLOW_PROTOCOLS);
	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		updateFlowProtocols({
			variables: {
				flow_id: Number(idFlow),
				from_date: format(new Date(date), "yyyy-MM-dd"),
			},
		}).then(({ data }) => {
			if (data.updateFlowProtocols) {
				enqueueSnackbar("Protocolos atualizados", {
					variant: "success",
					preventDuplicate: true,
				});
				handleClose()
			} else {
				enqueueSnackbar("Nenhum Protocolo encontradoa a partir dessa data", {
					variant: "warning",
					preventDuplicate: true,
				});
			}
		});

		setDate(null);
	};

	const handleRadioChange = (event) => {
		setSelectedValue(event.target.value);
	};
	return (
		<Dialog aria-labelledby="customized-dialog-title" open={open}>
			<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
				<strong>Atualização em massa</strong>
			</DialogTitle>
			<form onSubmit={handleSubmit}>
				<DialogContent dividers>
					<Typography gutterBottom>
						Qual opção gostaria para atualizar o fluxo dos protocolos abertos?
					</Typography>
					<FormControl disabled={loading.loading}>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							defaultValue="allProtocols"
							name="radio-buttons-group"
							value={selectedValue}
							onChange={handleRadioChange}
						>
							<FormControlLabel
								value="data"
								control={<Radio />}
								label="Apartir de uma data"
							/>
							{selectedValue === "data" && !loading.loading && (
								<Controller
									control={control}
									name="date"
									defaultValue={null}
									render={(inputProps) => (
										<LocalizationProvider
											dateAdapter={AdapterDayjs}
											adapterLocale="pt-br"
										>
											<DatePicker
												inputFormat="DD/MM/YYYY"
												label="Data"
												value={date}
												onChange={(newValue) => {
													setDate(newValue);
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														sx={{ width: "100%" }}
														name={inputProps.field.name}
														error={!!inputProps.fieldState.error}
													/>
												)}
											/>
										</LocalizationProvider>
									)}
								/>
							)}
							<FormControlLabel
								value="allProtocols"
								control={<Radio />}
								label="Todos os protocolos abertos"
								onChange={() => setDate(null)}
							/>
						</RadioGroup>
					</FormControl>
				</DialogContent>

				<DialogActions>
					<Button
						type="button"
						disabled={loading.loading}
						autoFocus
						onClick={handleClose}
					>
						Fechar
					</Button>
					<Button type="submit" disabled={loading.loading} variant="contained">
						{loading.loading ? 
						(
							<>
								<CircularProgress size={20} sx={{marginRight: "8px"}}/>
								Atualizando 
							</>
						) : 
						"Salvar"}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}
