import { Item } from "components/Paper";
import { Title } from "components/Title";
import { Box, Grid } from "@mui/material";
import { WrapperInfo } from "components/WrapperInfo";
import { ChartComponent } from "components/Chart";
import { useQuery } from "@apollo/client";
import { CircularProgress } from "components/CircularProgress";
import { COUNT_PROTOCOL_SITUATIONS } from "graphql/queries/countProtocolsSituation";
import { CountProtocolSituations } from "types/graphql/queries/countProtocolSituations";

interface CardSituationProtocolsProps {
	startDate: Date | null;
	finishDate: Date | null;
	groupIds: number[];
	responsibleIds: number[];
	situations: string[];
	subject_ids: number[];
	status_ids: number[];
	district_ids: number[];
	upfmls: string | null;
	lateDays: string | null;
	street: string | null;
	number: string | null;
	keyword: string | null;
	lote: string | null;
	quadra: string | null;
	cadastralIndex: string | null;
	offendersName: string | null;
}

export function CardSituationProtocols({
	finishDate,
	groupIds,
	responsibleIds,
	startDate,
	situations,
	subject_ids,
	status_ids,
	district_ids,
	upfmls,
	lateDays,
	street,
	number,
	keyword,
	cadastralIndex,
	offendersName,
	lote,
	quadra,
}: CardSituationProtocolsProps) {
	const { data, loading } = useQuery<CountProtocolSituations>(
		COUNT_PROTOCOL_SITUATIONS,
		{
			fetchPolicy: "network-only",
			variables: {
				situations: situations.length > 0 ? situations : null,
				subject_ids: subject_ids.length > 0 ? subject_ids : null,
				status_ids: status_ids.length > 0 ? status_ids : null,
				street: street,
				number: number,
				district_ids: district_ids.length > 0 ? district_ids : null,
				upfmls: upfmls,
				late_days: lateDays,
				keyword: keyword,
				start: startDate,
				finish: finishDate,
				group_ids: groupIds.length > 0 ? groupIds : null,
				responsible_ids: responsibleIds.length > 0 ? responsibleIds : null,
				cadastral_index: cadastralIndex,
				offenders_name: offendersName,
				lote: lote,
				quadra: quadra,
			},
		}
	);

	const colors = ["#ff9800", "green", "#d32f2f", "#c92fd3"];

	const stacks = [
		{
			series: ["Em andamento", "Concluído", "Cancelados", "Suspensos"],
		},
	];

	const dataChart =
		data &&
		data.countProtocolSituations.groups.map((item) => {
			return {
				argument: item.name.substring(0, 20),
				valuesFields: [
					{
						in_progress: item.in_progress,
						finished: item.finished,
						canceled: item.canceled,
						suspended: item.suspended,
					},
				],
			};
		});

	return (
		<Item
			sx={{
				height: "100%",
			}}
		>
			<Box>
				<Box display="flex" alignItems="center" justifyContent="space-between">
					<Title title="Situação dos protocolos" />
				</Box>
				{loading ? (
					<CircularProgress />
				) : (
					<>
						{data && (
							<>
								<Grid container spacing={2}>
									<Grid item xs={4} md={2}>
										<WrapperInfo
											title="Total"
											value={data.countProtocolSituations.total}
										/>
									</Grid>
									<Grid item xs={4} md={2}>
										<WrapperInfo
											title="Em andamento"
											value={data.countProtocolSituations.in_progress}
											color="#ff9800"
										/>
									</Grid>
									<Grid item xs={4} md={2}>
										<WrapperInfo
											title="Concluído"
											value={data.countProtocolSituations.finished}
											color="green"
										/>
									</Grid>
									<Grid item xs={4} md={2} display="flex" columnGap={2}>
										<WrapperInfo
											title="Cancelados"
											value={data.countProtocolSituations.canceled}
											color="#d32f2f"
										/>
									</Grid>
									<Grid item xs={4} md={2}>
										<WrapperInfo
											title="Suspensos"
											value={data.countProtocolSituations.suspended}
											color="#c92fd3"
										/>
									</Grid>
								</Grid>
								<ChartComponent
									colors={colors}
									stacks={stacks}
									data={dataChart}
									rotated={true}
								/>
							</>
						)}
					</>
				)}
			</Box>
		</Item>
	);
}
