import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ThemeSuspense from "components/ThemeSuspense";
import { AuthProvider } from "context/AuthProvider";
import { SnackbarProvider } from "notistack";
import { Suspense } from "react";
import {
	BrowserRouter,
	Routes as RoutesRouterDom,
	Route,
} from "react-router-dom";
import { client } from "service/api";
import { CssBaseline } from "@mui/material";
import { RoutesComponent } from "routes";
import { SidebarProvider } from "context/SidebarProvider";
import { ApolloProvider } from "@apollo/client";
import "swiper/css";
import "swiper/css/pagination";

export default function App() {
	return (
		<SnackbarProvider
			maxSnack={2}
			autoHideDuration={3000}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
		>
			<ApolloProvider client={client}>
				<Suspense fallback={<ThemeSuspense />}>
					<SidebarProvider>
						<CssBaseline />
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<BrowserRouter>
								<AuthProvider>
									<RoutesRouterDom>
										<Route path="/*" element={<RoutesComponent />} />
									</RoutesRouterDom>
								</AuthProvider>
							</BrowserRouter>
						</LocalizationProvider>
					</SidebarProvider>
				</Suspense>
			</ApolloProvider>
		</SnackbarProvider>
	);
}
