import { gql } from "@apollo/client";

export const COUNT_DOCUMENTS = gql`
	query (
		$sender_id: Int
		$deleted: Boolean
		$concluded: Boolean
		$necessary_action: Boolean
		$waiting_others: Boolean
		$keyword: String
	) {
		countDocuments(
			sender_id: $sender_id
			deleted: $deleted
			concluded: $concluded
			necessary_action: $necessary_action
			waiting_others: $waiting_others
			keyword: $keyword
		) {
			qtd_documents
			qtd_necessary_action
			qtd_waiting_others
			qtd_concluded
		}
	}
`;
