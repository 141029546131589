/* eslint-disable no-mixed-spaces-and-tabs */
import { Stack, Typography, Link, Box } from "@mui/material";
import Button from "components/Button";
import { useState } from "react";
import { ModalTaskResolve } from "../Modal";
import { ModalDetailOccurrence } from "../Modal/ModalDetailOccurrence";
import { useSnackbar } from "notistack";
import { useAuthContext } from "context/AuthProvider";
import { DetailProtocol, Task } from "types/graphql/queries/detailProtocol";
import { format, parse, isFuture } from "date-fns";

interface DetailOccurrenceProps {
	task: Task;
	formResponseOccurrence?: DetailProtocol["detailProtocol"]["tasks"][0]["detail"]["form_response"];
	subject: DetailProtocol["detailProtocol"]["subject"];
	isNotConcluded: boolean;
	refetch: () => void;
	status: string;
}
export function DetailOccurrence({
	task,
	isNotConcluded,
	refetch,
	status,
	subject,
	formResponseOccurrence,
}: DetailOccurrenceProps) {
	const { enqueueSnackbar } = useSnackbar();
	const [open, setOpen] = useState(false);
	const [isOpenModalTaskResolve, setIsOpenModalTaskResolve] = useState(false);
	const { state } = useAuthContext();

	function handleOpenModalTaskResolve() {
		if (status === "Suspenso" || status === "Cancelado") {
			enqueueSnackbar(
				"Não é possível resolver uma tarefa de um protocolo cancelado ou suspenso",
				{ variant: "error" }
			);
			return;
		}
		setIsOpenModalTaskResolve(true);
	}

	const isSameUser = state.userInfo.user.id === task.user?.id;
	const isSamePerfilIdWithUserNull =
		state.userInfo.user.perfil_id === task.perfil?.id && task.user === null;

	const isSameUserOrPerfil = isSameUser || isSamePerfilIdWithUserNull;

	const parsedDate = task.expire_at
		? parse(task.expire_at, "yyyy-MM-dd HH:mm:ss", new Date())
		: null;

	const isFutureDate = parsedDate ? isFuture(parsedDate) : false;
	const disabledResolveTask =
		task.resolve_task === "Ao expirar" && isFutureDate === false;

	return (
		<>
			<Stack
				minHeight={100}
				sx={{
					opacity: task.undo_at ? 0.7 : 1,
				}}
			>
				<Typography variant="subtitle2" fontWeight={500} fontSize="0.9rem">
					{task.name}
				</Typography>
				{!isNotConcluded && (
					<Typography
						variant="caption"
						fontSize="0.8rem"
						onClick={() => setOpen(true)}
						onKeyDown={() => setOpen(true)}
						role="button"
						tabIndex={0}
						sx={{
							cursor: "pointer",
						}}
					>
						<Link>Ver detalhes</Link>
					</Typography>
				)}
				<Typography variant="caption" fontSize="0.8rem">
					{task.resolve_task === "Ao expirar"
						? `Tarefa só pode ser concluída a partir do dia ${
								parsedDate ? format(parsedDate, "dd/MM/yyyy HH:mm:ss") : " - "
						  }`
						: isNotConcluded
						? task.expire_at
							? `Prazo para conclusão: ${task.expire_at}`
							: "Sem prazo para conclusão"
						: `Concluída em ${task.concluded_at || "-"}`}
				</Typography>
				{!isNotConcluded && task.user && (
					<>
						<Typography variant="caption" fontSize="0.8rem">
							por {task.user.name}
						</Typography>
						{task.user.params?.matricula && (
							<Typography variant="caption" fontSize="0.8rem">
								Matrícula {task.user.params?.matricula}
							</Typography>
						)}
					</>
				)}
				{isNotConcluded && status !== "Suspenso" && status !== "Cancelado" && (
					<Box>
						<Button
							variant="outlined"
							onClick={() => handleOpenModalTaskResolve()}
							disabled={!isSameUserOrPerfil || disabledResolveTask === true}
						>
							Resolver tarefa
						</Button>
					</Box>
				)}
			</Stack>
			{open && (
				<ModalDetailOccurrence
					open={open}
					handleClose={() => setOpen(false)}
					task={task}
					refetch={refetch}
					formResponseOccurrence={formResponseOccurrence}
				/>
			)}
			{isOpenModalTaskResolve && (
				<ModalTaskResolve
					refetch={refetch}
					open={isOpenModalTaskResolve}
					handleClose={() => setIsOpenModalTaskResolve(false)}
					tasks={task}
					subject={subject}
					formResponseOccurrence={formResponseOccurrence}
				/>
			)}
		</>
	);
}
