import { useAuthContext } from "context/AuthProvider";
import { useLocation, Navigate, Outlet } from "react-router-dom";

const RequireAuth = () => {
	const { TOKEN_USER } = useAuthContext();
	const location = useLocation();

	if (!TOKEN_USER) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	} else {
		return <Outlet />;
	}
};

export default RequireAuth;
