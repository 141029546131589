/* eslint-disable no-mixed-spaces-and-tabs */
import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import Button from "components/Button";
import { Controller, useFormContext } from "react-hook-form";
import { ContentState, convertFromHTML, EditorState } from "draft-js";

interface StepRegulatoryActsProps {
	goToNextStep: () => void;
	goToPrevStep: () => void;
}

export function StepRegulatoryActs({
	goToNextStep,
	goToPrevStep,
}: StepRegulatoryActsProps) {
	const { control, getValues, setValue } = useFormContext();
	const { regulatoryActs, dynamicForm, autofill_regulatory_acts } = getValues();

	let regulatoryActDescription =
		regulatoryActs && autofill_regulatory_acts
			? regulatoryActs.length > 0 &&
			  regulatoryActs.filter((i) => i.checked).map((i) => i.description)
			: [];

	if (regulatoryActDescription.length > 0) {
		regulatoryActDescription = regulatoryActDescription.join("\n\n\n");
	}

	const findInputTextAreaGroup = dynamicForm
		? dynamicForm.groups.findIndex((group) => {
				return group.inputs.find((input) => input.type === "textarea");
		  })
		: null;

	const findIndexTextAreaInput =
		findInputTextAreaGroup >= 0 && dynamicForm
			? dynamicForm.groups[findInputTextAreaGroup]?.inputs.findIndex(
					(input) => {
						return input.type === "textarea";
					}
			  )
			: null;

	const findInputTextArea =
		findInputTextAreaGroup >= 0 && dynamicForm
			? dynamicForm.groups[findInputTextAreaGroup].inputs.find((input) => {
					return input.type === "textarea";
			  })
			: null;

	if (
		findInputTextAreaGroup !== null &&
		findIndexTextAreaInput !== null &&
		findInputTextArea &&
		autofill_regulatory_acts &&
		dynamicForm
	) {
		const blocksFromHTML = convertFromHTML(
			regulatoryActDescription.length > 0 ? regulatoryActDescription : ""
		);
		const state = ContentState.createFromBlockArray(
			blocksFromHTML.contentBlocks,
			blocksFromHTML.entityMap
		);
		setValue(
			`groups.${findInputTextAreaGroup}.fields.${findInputTextArea.name}`,
			EditorState.createWithContent(state)
		);
	}

	return (
		<>
			<Stack spacing={1} flex={1} maxHeight="500px" overflow="scroll">
				{regulatoryActs &&
					regulatoryActs.map((regulatoryAct, index) => (
						<Controller
							key={regulatoryAct.id}
							control={control}
							name={`regulatoryActs.${index}.checked`}
							render={({
								field: { onChange, onBlur, value = false, name, ref },
								fieldState: { invalid, isTouched, isDirty, error },
								formState,
								...rest
							}) => (
								<FormControlLabel
									control={<Checkbox value {...rest} />}
									label={regulatoryAct.name}
									onChange={onChange}
									checked={value}
									sx={{
										mx: 0,
									}}
								/>
							)}
						/>
					))}
			</Stack>
			<Box sx={{ mb: 2 }}>
				<Box>
					<Button
						variant="contained"
						onClick={goToNextStep}
						sx={{ mt: 1, mr: 1 }}
					>
						Próximo
					</Button>
					<Button onClick={goToPrevStep} sx={{ mt: 1, mr: 1 }}>
						Voltar
					</Button>
				</Box>
			</Box>
		</>
	);
}
