import Input from "components/Input";
import { Stack } from "@mui/material";

export function FormPontuate() {
	return (
		<Stack spacing={2} margin={2}>
			<Input
				name="point"
				type="text"
				placeholder="Pontuação"
				autoComplete="off"
			/>
			<Input
				name="pointDescription"
				type="text"
				placeholder="Descrição"
				autoComplete="off"
			/>
		</Stack>
	);
}
