import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
	Box,
	Fab,
	Container,
	TablePagination,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	TableBody,
	Table,
	IconButton,
	Stack,
	TableSortLabel,
	TextField,
} from "@mui/material";
import { Item } from "components/Paper";
import { Header } from "components/Header";
import { EmptyMessage } from "components/EmptyMessage";
import noUser from "assets/img/noUser.png";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteDialog from "./DeleteDialog";
import { FETCH_FLOWS } from "graphql/queries/fetchFlows";
import { DataFlow, FetchFlows } from "types/graphql/queries/fetchFlows";
import { useNavigate } from "react-router-dom";
import useDebounce from "hooks/useDebounce";
import LocalProvider from "components/LocalProvider";

export function Flows() {
	const pageFromStorage: {
		[key: string]: number;
	} = JSON.parse(localStorage.getItem("page")) || 0;
	const rowsPerPageFromStorage: {
		[key: string]: number;
	} = JSON.parse(localStorage.getItem("rowsPerPage")) || 15;

	const { enqueueSnackbar } = useSnackbar();
	const [currentPage, setCurrentPage] = useState(pageFromStorage.flows || 0);
	const [rowsPerPage, setRowsPerPage] = useState(
		rowsPerPageFromStorage.flows || 15
	);
	const [showDelete, setShowDelete] = useState(false);
	const [flowToDeleted, setFlowToDelete] = useState<DataFlow>(null);
	const [orderBy, setOrderBy] = useState("name");
	const [direction, setDirection] = useState<"asc" | "desc">("asc");
	const [keyword, setKeyword] = useState("");

	const debouncedValue = useDebounce(keyword, 1000);

	const { data, refetch } = useQuery<FetchFlows>(FETCH_FLOWS, {
		fetchPolicy: "network-only",
		variables: {
			limit: rowsPerPage,
			page: currentPage + 1,
			order_by: orderBy,
			direction: direction,
			keyword: debouncedValue,
		},
	});

	const navigate = useNavigate();

	function handleDeleteFlow(flow) {
		setFlowToDelete(flow);
		setShowDelete(true);
	}

	function handleSort(name) {
		const isAsc = orderBy === name && direction === "asc";
		setDirection(isAsc ? "desc" : "asc");
		setOrderBy(name);
	}

	return (
		<Box
			width="100%"
			display="flex"
			alignSelf="stretch"
			flexDirection="column"
			sx={{
				backgroundColor: "#f3f3f3",
			}}
			paddingBottom="1rem"
		>
			<Header title="Fluxos" isReturn to="/configuracoes" />

			<Container maxWidth="lg" sx={{ marginTop: "1rem" }}>
				<Item
					sx={{
						paddingX: 0,
						paddingY: "1px",
					}}
				>
					<Box sx={{ padding: 2 }}>
						<TextField
							fullWidth
							variant="standard"
							name="keyword"
							type="search"
							label="Pesquisar..."
							value={keyword}
							onChange={(event) => {
								setCurrentPage(0);
								setKeyword(event.target.value);
							}}
						/>
					</Box>

					<TableContainer style={{ marginTop: "10px" }}>
						{data?.fetchFlows?.total === 0 && (
							<>
								<EmptyMessage message="Nenhum fluxo encontrado 😥" />
								<Box
									marginTop={2}
									sx={{
										backgroundImage: `url(${noUser})`,
										backgroundRepeat: "no-repeat",
										backgroundPosition: "center",
										backgroundSize: "contain",
										height: "200px",
									}}
								/>
							</>
						)}
						{data?.fetchFlows?.total !== 0 && (
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>
											<TableSortLabel
												active={orderBy === "name"}
												direction={orderBy === "name" ? direction : "asc"}
												onClick={() => handleSort("name")}
											>
												Nome
											</TableSortLabel>
										</TableCell>
										<TableCell>
											<TableSortLabel
												active={orderBy === "version"}
												direction={orderBy === "version" ? direction : "asc"}
												onClick={() => handleSort("version")}
											>
												Versão
											</TableSortLabel>
										</TableCell>
										<TableCell>
											<TableSortLabel
												active={orderBy === "status"}
												direction={orderBy === "status" ? direction : "asc"}
												onClick={() => handleSort("status")}
											>
												Status
											</TableSortLabel>
										</TableCell>
										<TableCell
											sx={{ paddingTop: 0, paddingBottom: 2 }}
										></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data?.fetchFlows?.data.map((row) => (
											<TableRow key={row.id}>
												<TableCell>{row.name}</TableCell>
												<TableCell>{row.version}</TableCell>
												<TableCell>{row.status}</TableCell>
												<TableCell>
													<Stack
														spacing={2}
														direction="row"
														flex={1}
														justifyContent="flex-end"
													>
														<IconButton
															aria-label="edit"
															onClick={() =>
																navigate(`/editar-fluxo/${row.id}`)
															}
														>
															<EditIcon />
														</IconButton>
														<IconButton
															aria-label="delete"
															onClick={() => handleDeleteFlow(row)}
														>
															<DeleteIcon />
														</IconButton>
													</Stack>
												</TableCell>
											</TableRow>
									))}
								</TableBody>
							</Table>
						)}
					</TableContainer>

					<DeleteDialog
						open={showDelete}
						handleClose={() => setShowDelete(null)}
						data={flowToDeleted}
						onDelete={() => {
							enqueueSnackbar("Fluxo excluído com sucesso!", {
								variant: "success",
							});
							refetch();
							setShowDelete(false);
						}}
					/>

					<Fab
						color="primary"
						onClick={() => navigate("/criar-fluxo")}
						style={{ position: "fixed", bottom: 48, right: 48 }}
					>
						<AddIcon />
					</Fab>

					{data?.fetchFlows?.total !== 0 && (
						<LocalProvider locale="ptBR">
							<TablePagination
								showLastButton
								showFirstButton
								rowsPerPageOptions={[5, 15, 30, 60, 100]}
								component="div"
								count={data?.fetchFlows?.total}
								rowsPerPage={rowsPerPage}
								page={currentPage}
								onPageChange={(event, newPage) => {
									setCurrentPage(newPage);
									const pageFromStorage: {
										[key: string]: number;
									} = JSON.parse(localStorage.getItem("page")) || 0;

									localStorage.setItem(
										"page",
										JSON.stringify({
											...pageFromStorage,
											flows: newPage,
										})
									);
								}}
								onRowsPerPageChange={(event) => {
									setRowsPerPage(parseInt(event.target.value));
									setCurrentPage(0);
									const rowsPerPageFromStorage: {
										[key: string]: number;
									} = JSON.parse(localStorage.getItem("rowsPerPage")) || 15;
									localStorage.setItem(
										"rowsPerPage",
										JSON.stringify({
											...rowsPerPageFromStorage,
											flows: parseInt(event.target.value),
										})
									);

									const pageFromStorage: {
										[key: string]: number;
									} = JSON.parse(localStorage.getItem("page")) || 0;
									localStorage.setItem(
										"page",
										JSON.stringify({
											...pageFromStorage,
											flows: 0,
										})
									);
								}}
							/>
						</LocalProvider>
					)}
				</Item>
			</Container>
		</Box>
	);
}
