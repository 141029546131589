import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
	Box,
	Fab,
	Container,
	TableRow,
	TableHead,
	TableContainer,
	TableSortLabel,
	TableCell,
	TableBody,
	Table,
	IconButton,
	TextField,
} from "@mui/material";
import { Item } from "components/Paper";
import { Header } from "components/Header";
import { EmptyMessage } from "components/EmptyMessage";
import noUser from "assets/img/noUser.png";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useSnackbar } from "notistack";
import { FETCH_GROUPS } from "graphql/queries/fetchGroups";
import { FetchGroups } from "types/graphql/queries/fetchGroups";
import AddIcon from "@mui/icons-material/Add";
import CreateGroupsDialog from "./CreateGroupsDialog";
import EditDialog from "./EditDialog";
import DeleteDialog from "./DeleteDialog";
import useDebounce from "hooks/useDebounce";
import PaginationStorage from "components/PaginationStorage";
import { CircularProgress } from "components/CircularProgress";

export function Groups() {
	const [showDelete, setShowDelete] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [groupsToDeleted, setGroupsToDelete] = useState(null);
	const [showCreate, setShowCreate] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [groupSelected, setGroupSelected] = useState(null);
	const [orderBy, setOrderBy] = useState("name");
	const [direction, setDirection] = useState<"asc" | "desc">("asc");
	const [keyword, setKeyword] = useState("");
	const [showPagination, setShowPagination] = useState(false);

	const debouncedValue = useDebounce(keyword, 1000);

	const { data, loading, refetch } = useQuery<FetchGroups>(FETCH_GROUPS, {
		variables: {
			limit: rowsPerPage,
			page: currentPage + 1,
			order_by: orderBy,
			direction: direction,
			keyword: debouncedValue,
		},
	});

	function handleDeleteGroups(item) {
		setGroupsToDelete(item);
		setShowDelete(true);
	}

	const handleOpenEditModal = (Group) => {
		setGroupSelected(Group);
		setOpenEdit(true);
	};

	function handleSort(name) {
		const isAsc = orderBy === name && direction === "asc";
		setDirection(isAsc ? "desc" : "asc");
		setOrderBy(name);
	}

	return (
		<Box
			width="100%"
			display="flex"
			alignSelf="stretch"
			flexDirection="column"
			sx={{
				backgroundColor: "#f3f3f3",
			}}
			paddingBottom="1rem"
		>
			<Header title="Grupos fiscais" isReturn />

			{loading ? (
				<CircularProgress />
			) : (
				<Container maxWidth="md" sx={{ marginTop: "1rem" }}>
					<div
						style={{ position: "relative", marginBottom: "60px" }}
						onMouseEnter={() => setShowPagination(true)}
						onMouseLeave={() => setShowPagination(false)}
					>
						<Item
							sx={{
								paddingX: 0,
								paddingY: "1px",
							}}
						>
							<Box sx={{ padding: 2 }}>
								<TextField
									fullWidth
									variant="standard"
									name="keyword"
									type="search"
									label="Pesquisar..."
									value={keyword}
									onChange={(event) => {
										setKeyword(event.target.value);
									}}
								/>
							</Box>

							<TableContainer style={{ marginTop: "10px" }}>
								{data?.fetchGroups?.total === 0 && (
									<>
										<EmptyMessage message="Nenhum grupo encontrado 😥" />
										<Box
											marginTop={2}
											sx={{
												backgroundImage: `url(${noUser})`,
												backgroundRepeat: "no-repeat",
												backgroundPosition: "center",
												backgroundSize: "contain",
												height: "200px",
											}}
										/>
									</>
								)}
								{data?.fetchGroups?.total !== 0 && (
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>
													<TableSortLabel
														active={orderBy === "name"}
														direction={orderBy === "name" ? direction : "asc"}
														onClick={() => handleSort("name")}
													>
														Nome
													</TableSortLabel>
												</TableCell>
												<TableCell>
													<TableSortLabel
														active={orderBy === "limit_points_day"}
														direction={
															orderBy === "limit_points_day" ? direction : "asc"
														}
														onClick={() => handleSort("limit_points_day")}
													>
														Limite pontos diários
													</TableSortLabel>
												</TableCell>
												<TableCell>
													<TableSortLabel
														active={orderBy === "status"}
														direction={orderBy === "status" ? direction : "asc"}
														onClick={() => handleSort("status")}
													>
														Status
													</TableSortLabel>
												</TableCell>
												<TableCell>&nbsp;</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{data?.fetchGroups?.data.map((row) => (
												<>
													<TableRow key={row.id}>
														<TableCell>{row.name}</TableCell>
														<TableCell>{row.limit_points_day ?? "-"}</TableCell>
														<TableCell>{row.status}</TableCell>
														<TableCell style={{ textAlign: "right" }}>
															<IconButton
																style={{ padding: 0, marginRight: 24 }}
																aria-label="edit"
																onClick={() => handleOpenEditModal(row)}
															>
																<EditIcon />
															</IconButton>
															<IconButton
																style={{ padding: 0 }}
																aria-label="delete"
																onClick={() => handleDeleteGroups(row)}
															>
																<DeleteIcon />
															</IconButton>
														</TableCell>
													</TableRow>
												</>
											))}
										</TableBody>
									</Table>
								)}
							</TableContainer>

							<DeleteDialog
								open={showDelete}
								handleClose={() => setShowDelete(null)}
								data={groupsToDeleted}
								onDelete={() => {
									enqueueSnackbar("Grupo fiscal excluído com sucesso!", {
										variant: "success",
									});
									refetch();
									setShowDelete(false);
								}}
							/>

							{groupSelected && (
								<EditDialog
									open={openEdit}
									handleClose={() => {
										setOpenEdit(false);
										setGroupSelected(null);
									}}
									data={groupSelected}
									onEdit={() => {
										enqueueSnackbar("Grupo fiscal editado com sucesso!", {
											variant: "success",
										});
										refetch();
										setOpenEdit(false);
										setGroupSelected(null);
									}}
								/>
							)}

							<CreateGroupsDialog
								open={showCreate}
								handleClose={() => setShowCreate(false)}
								onCreate={() => {
									enqueueSnackbar("Grupo fiscal criado com sucesso!", {
										variant: "success",
									});
									setShowCreate(false);
									refetch();
								}}
							/>

							<Fab
								color="primary"
								onClick={() => setShowCreate(true)}
								style={{ position: "fixed", bottom: 48, right: 48 }}
							>
								<AddIcon />
							</Fab>
							{data?.fetchGroups?.total !== 0 && (
								<Box
									sx={{
										position: "fixed",
										bottom: "30px",
										left: "50%",
										transform: "translateX(-50%)",
										padding: "0px",
										backgroundColor: "rgba(25, 118, 210, 0.98)",
										boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
										opacity: showPagination ? 0.95 : 0,
										zIndex: 2,
										borderRadius: "10px",
										transition: "opacity 0.3s ease-in-out",
									}}
								>
									<PaginationStorage
										count={data?.fetchGroups?.total}
										rowsPerPage={rowsPerPage}
										page={currentPage}
										onPageChange={(event, newPage) => {
											setCurrentPage(newPage);
										}}
										onRowsPerPageChange={(event) => {
											setRowsPerPage(parseInt(event.target.value));
											setCurrentPage(0);
										}}
									/>
								</Box>
							)}
						</Item>
					</div>
				</Container>
			)}
		</Box>
	);
}
