import { gql } from "@apollo/client";

export const ACCEPT_COMPLAINT = gql`
	mutation (
		$code: String!
		$subject_id: Int!
		$responsible_user_id: Int!
		$expire_at: String!
	) {
		acceptComplaint(
			code: $code
			subject_id: $subject_id
			responsible_user_id: $responsible_user_id
			expire_at: $expire_at
		)
	}
`;
