import { useEffect, useState } from "react";
import { InputBase, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useDebounce from "hooks/useDebounce";

interface SeachInputProps {
	onChange: (value: string) => void;
	typeSearch: string;
}

export default function SearchInput({ onChange, typeSearch }: SeachInputProps) {
	const [value, setValue] = useState(null);
	const debouncedSearchTerm = useDebounce(value, 500);

	useEffect(() => {
		if (debouncedSearchTerm === "") {
			setValue(null);
		}

		onChange(value);
	}, [debouncedSearchTerm]);

	return typeSearch === "inDialog" ? (
		<TextField
			fullWidth={true}
			label="Pesquisar"
			onChange={(e) => setValue(e.target.value)}
			value={value}
			sx={{
				mt: 1,
			}}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<SearchIcon></SearchIcon>
					</InputAdornment>
				),
			}}
		/>
	) : (
		<InputBase
			style={{ width: "100%" }}
			autoFocus={true}
			placeholder="Palavra chave"
			inputProps={{ "aria-label": "Palavra chave" }}
			onChange={(e) => setValue(e.target.value)}
		/>
	);
}
