import { gql } from "@apollo/client";

export const FETCH_DISTRICTS = gql`
	query (
		$domain: String!
		$keyword: String
		$order_by: String
		$direction: String
		$page: Int
		$limit: Int
	) {
		fetchDistricts(
			domain: $domain
			keyword: $keyword
			order_by: $order_by
			direction: $direction
			page: $page
			limit: $limit
		) {
			total
			current_page
			per_page
			last_page
			data {
				id
				city {
					id
					name
					state
				}
				name
				latitude
				longitude
				status
				created_at
			}
		}
	}
`;
