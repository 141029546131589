import { useState } from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ModalDetailProtocols } from "./Modal";
import { DataProtocol } from "types/graphql/queries/fetchProtocols";

interface DescriptionAlertProps {
	numberProtocols: number;
	subjectName: string;
	zipcode: boolean;
	info: string;
	protocols: DataProtocol[];
}

export function DescriptionAlert({
	numberProtocols,
	subjectName,
	zipcode,
	info,
	protocols,
}: DescriptionAlertProps) {
	const [isOpen, setIsOpen] = useState(false);

	function onRequestClose() {
		setIsOpen(false);
	}

	function handleOpenModal() {
		setIsOpen(true);
	}

	return (
		<>
			<Stack sx={{ width: "100%", marginTop: 2 }} spacing={2}>
				<Alert severity="warning">
					<Typography variant="body2">
						Já existe(m) <strong>{numberProtocols} protocolo(s)</strong> com o
						assunto
						<strong>{" " + subjectName + " "}</strong>
						{zipcode ? "no cep " : "no indice cadastral "}
						<strong>{info}</strong>.{" "}
						<Typography
							variant="body2"
							component="span"
							sx={{
								color: "primary.main",
								cursor: "pointer",
								textDecoration: "underline",
							}}
							onClick={handleOpenModal}
						>
							Ver detalhes
						</Typography>
					</Typography>
				</Alert>
			</Stack>
			<ModalDetailProtocols
				isOpen={isOpen}
				onClose={onRequestClose}
				protocols={protocols}
			/>
		</>
	);
}
