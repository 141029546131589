import { gql } from "@apollo/client";

export const FETCH_TASKS = gql`
	query (
		$all: Boolean
		$keyword: String
		$order_by: String
		$direction: String
		$page: Int
		$limit: Int
		$is_concluded_at: Boolean
		$start: String
		$finish: String
		$subject_ids: [Int]
		$street: String
		$number: String
		$district_ids: [Int]
		$late_days: String
		$lote: String
		$quadra: String
		$cadastral_index: String
		$offenders_name: String
		$situations: [String]
		$status_ids: [Int]
		$upfmls: Boolean
		$tasks: [String]
	) {
		fetchTasks(
			all: $all
			keyword: $keyword
			order_by: $order_by
			direction: $direction
			page: $page
			limit: $limit
			is_concluded_at: $is_concluded_at
			start: $start
			finish: $finish
			subject_ids: $subject_ids
			street: $street
			number: $number
			district_ids: $district_ids
			late_days: $late_days
			lote: $lote
			quadra: $quadra
			cadastral_index: $cadastral_index
			offenders_name: $offenders_name
			situations: $situations
			status_ids: $status_ids
			upfmls: $upfmls
			tasks: $tasks
		) {
			total
			current_page
			per_page
			last_page
			data {
				id
				company_id
				code
				name
				answer
				protocol {
					code
					subject {
						name
					}
					situation
				}
				concluded_at
				expire_at
				info
				theme
				created_at
				street
				number
				zipcode
				complement
				district
				user {
					id
					name
				}
				perfil {
					id
					name
				}
			}
		}
	}
`;
