import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

interface OptionButtonProps {
	label: string;
	name: string;
	options?: Array<{
		label: string;
		value: string;
	}>;
	index?: number;
	readonly?: boolean;
}

function FieldOptionButton({
	label,
	name,
	options,
	index,
	readonly,
}: OptionButtonProps) {
	const { control } = useFormContext();
	return (
		<Grid item xs={12} marginTop={2}>
			<FormControl>
				<FormLabel id={label}>{label}</FormLabel>
				<RadioGroup row aria-labelledby={label} name={name}>
					{options.map((item) => (
						<Controller
							key={item.value}
							name={`groups.${index}.fields.${name}`}
							control={control}
							render={(inputProps) => (
								<FormControlLabel
									value={item.value}
									control={
										<Radio
											value={item.value}
											readOnly={readonly}
											onChange={() => inputProps.field.onChange(item.value)}
										/>
									}
									label={item.label}
								/>
							)}
						/>
					))}
				</RadioGroup>
			</FormControl>
		</Grid>
	);
}

export default FieldOptionButton;
