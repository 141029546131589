import { useMutation } from "@apollo/client";
import { DELETE_SUBJECT } from "graphql/mutations/deleteSubject";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";

type DeleteDialogProps = {
	open: boolean;
	handleClose: () => void;
	onDelete: () => void;
	data: any;
}

export default function DeleteDialog({ open, handleClose, onDelete, data }: DeleteDialogProps) {

	// Setting mutation
	const [deleteSubject, { loading: loadingDelete }] = useMutation(
		DELETE_SUBJECT
	);

	// Chamando a mutation de remoção
	const handleDelete = () => {
		deleteSubject({
			variables: {
				id: data.id,
			},
		}).then(() => {
			onDelete();
		});
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="paper"
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogTitle id="scroll-dialog-title">Excluir Assunto</DialogTitle>

				<DialogContent>

					<p>Deseja realmente excluir o assunto?</p>

				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose} color="primary">
            Cancelar
					</Button>
					<Button onClick={handleDelete} variant="contained" color="primary" disabled={loadingDelete}>
						{loadingDelete && <CircularProgress size={14} style={{ marginRight: 12 }} />}
            Excluir
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}
