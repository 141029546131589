import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
	Box,
	Fab,
	Container,
	TablePagination,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	TableBody,
	Table,
	IconButton,
	Stack,
	TableSortLabel,
	TextField,
} from "@mui/material";
import { Item } from "components/Paper";
import { Header } from "components/Header";
import { EmptyMessage } from "components/EmptyMessage";
import noUser from "assets/img/noUser.png";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useSnackbar } from "notistack";
import { FETCH_DYNAMIC_FORMS } from "graphql/queries/fetchDynamicForms";
import { FetchDynamicForms } from "types/graphql/queries/fetchDynamicForms";
import AddIcon from "@mui/icons-material/Add";
import DeleteDialog from "./DeleteDialog";
import { useNavigate } from "react-router-dom";
import useDebounce from "hooks/useDebounce";
import { useVerifyNoHasRestriction } from "utils/useVerifyNoHasRestriction";
import LocalProvider from "components/LocalProvider";

export function FormList() {
	const pageFromStorage: {
		[key: string]: number;
	} = JSON.parse(localStorage.getItem("page")) || 0;
	const rowsPerPageFromStorage: {
		[key: string]: number;
	} = JSON.parse(localStorage.getItem("rowsPerPage")) || 15;

	const navigate = useNavigate();
	const [showDelete, setShowDelete] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [formsToDeleted, setFormsToDelete] = useState(null);
	const [currentPage, setCurrentPage] = useState(pageFromStorage.form || 0);
	const [rowsPerPage, setRowsPerPage] = useState(
		rowsPerPageFromStorage.form || 15
	);
	const [orderBy, setOrderBy] = useState("name");
	const [direction, setDirection] = useState<"asc" | "desc">("asc");
	const [keyword, setKeyword] = useState("");

	const debouncedValue = useDebounce(keyword, 1000);

	const { data, refetch } = useQuery<FetchDynamicForms>(FETCH_DYNAMIC_FORMS, {
		fetchPolicy: "network-only",
		variables: {
			limit: rowsPerPage,
			page: currentPage + 1,
			order_by: orderBy,
			direction: direction,
			keyword: debouncedValue,
		},
	});

	function handleDeleteForms(item) {
		setFormsToDelete(item);
		setShowDelete(true);
	}

	function handleClick() {
		navigate("/configuracoes/criar-formulario");
	}

	function handleEditForms(item) {
		navigate(`/configuracoes/editar-formulario/${item.id}`);
	}

	function handleSort(name) {
		const isAsc = orderBy === name && direction === "asc";
		setDirection(isAsc ? "desc" : "asc");
		setOrderBy(name);
	}

	const noHasRestricitonCreateDynamicForm = useVerifyNoHasRestriction(
		"FORMULARIO_DINAMICO_CRIAR"
	);

	return (
		<Box
			width="100%"
			display="flex"
			alignSelf="stretch"
			flexDirection="column"
			sx={{
				backgroundColor: "#f3f3f3",
			}}
			paddingBottom="1rem"
		>
			<Header title="Formulários" isReturn />

			<Container maxWidth="md" sx={{ marginTop: "1rem" }}>
				<Item
					sx={{
						paddingX: 0,
						paddingY: "1px",
					}}
				>
					<Box sx={{ padding: 2 }}>
						<TextField
							fullWidth
							variant="standard"
							name="keyword"
							type="search"
							label="Pesquisar..."
							value={keyword}
							onChange={(event) => {
								setKeyword(event.target.value);
							}}
						/>
					</Box>

					<TableContainer style={{ marginTop: "10px" }}>
						{data?.fetchDynamicForms?.total === 0 && (
							<>
								<EmptyMessage message="Nenhum formulário encontrado 😥" />
								<Box
									marginTop={2}
									sx={{
										backgroundImage: `url(${noUser})`,
										backgroundRepeat: "no-repeat",
										backgroundPosition: "center",
										backgroundSize: "contain",
										height: "200px",
									}}
								/>
							</>
						)}
						{data?.fetchDynamicForms?.total !== 0 && (
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>
											<TableSortLabel
												active={orderBy === "name"}
												direction={orderBy === "name" ? direction : "asc"}
												onClick={() => handleSort("name")}
											>
												Nome
											</TableSortLabel>
										</TableCell>
										<TableCell>
											<TableSortLabel
												active={orderBy === "version"}
												direction={orderBy === "version" ? direction : "asc"}
												onClick={() => handleSort("version")}
											>
												Versão
											</TableSortLabel>
										</TableCell>
										<TableCell>&nbsp;</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data?.fetchDynamicForms?.data.map((row) => (
										<TableRow key={row.id}>
											<TableCell>{row.name}</TableCell>
											<TableCell>{row.version}</TableCell>
											<TableCell>
												<Stack
													spacing={2}
													direction="row"
													flex={1}
													justifyContent="end"
												>
													<IconButton
														aria-label="edit"
														onClick={() => handleEditForms(row)}
													>
														<EditIcon />
													</IconButton>
													<IconButton
														aria-label="delete"
														onClick={() => handleDeleteForms(row)}
													>
														<DeleteIcon />
													</IconButton>
												</Stack>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						)}
					</TableContainer>

					<DeleteDialog
						open={showDelete}
						handleClose={() => setShowDelete(null)}
						data={formsToDeleted}
						onDelete={() => {
							enqueueSnackbar("Formulário excluído com sucesso!", {
								variant: "success",
							});
							refetch();
							setShowDelete(false);
						}}
					/>

					{noHasRestricitonCreateDynamicForm && (
						<Fab
							color="primary"
							onClick={handleClick}
							style={{ position: "fixed", bottom: 48, right: 48 }}
						>
							<AddIcon />
						</Fab>
					)}
					{data?.fetchDynamicForms?.total !== 0 && (
						<LocalProvider locale="ptBR">
							<TablePagination
								showLastButton
								showFirstButton
								rowsPerPageOptions={[5, 15, 30, 60, 100]}
								component="div"
								count={data?.fetchDynamicForms?.total}
								rowsPerPage={rowsPerPage}
								page={currentPage}
								onPageChange={(_event, newPage) => {
									setCurrentPage(newPage);
									const pageFromStorage: {
										[key: string]: number;
									} = JSON.parse(localStorage.getItem("page")) || 1;

									localStorage.setItem(
										"page",
										JSON.stringify({
											...pageFromStorage,
											form: newPage,
										})
									);
								}}
								onRowsPerPageChange={(event) => {
									setRowsPerPage(parseInt(event.target.value));
									setCurrentPage(0);

									const rowsPerPageFromStorage: {
										[key: string]: number;
									} = JSON.parse(localStorage.getItem("rowsPerPage")) || 15;
									localStorage.setItem(
										"rowsPerPage",
										JSON.stringify({
											...rowsPerPageFromStorage,
											form: parseInt(event.target.value),
										})
									);

									const pageFromStorage: {
										[key: string]: number;
									} = JSON.parse(localStorage.getItem("page")) || 0;
									localStorage.setItem(
										"page",
										JSON.stringify({
											...pageFromStorage,
											form: 0,
										})
									);
								}}
							/>
						</LocalProvider>
					)}
				</Item>
			</Container>
		</Box>
	);
}
