import { useCallback } from "react";
import { useStore, getStraightPath, EdgeProps, EdgeLabelRenderer, BaseEdge, updateEdge } from "reactflow";
import { Box, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

import { getEdgeParams } from "utils/createEdgesAndNodes";
import { useFlowContext } from "context/FlowContext";

type FloatingEdgeProps = EdgeProps;

const onEdgeClick = (evt, id) => {
	evt.stopPropagation();
	alert(`remove ${id}`);
};

function FloatingEdge({
	id,
	source,
	target,
	label,
	markerEnd,
	style,
	selected
}: FloatingEdgeProps) {

	const { setEdges }: any = useFlowContext()

	const handleDeleteEdge = useCallback(() => {
		setEdges((prevEdges) =>
			prevEdges.filter((edge) => edge.id !== id)
		);
	}, []);

	const sourceNode = useStore(
		useCallback((store) => store.nodeInternals.get(source), [source])
	);
	const targetNode = useStore(
		useCallback((store) => store.nodeInternals.get(target), [target])
	);

	if (!sourceNode || !targetNode) {
		return null;
	}
	const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);

	const [edgePath] = getStraightPath({
		sourceX: sx,
		sourceY: sy,
		targetX: tx,
		targetY: ty,
	});

	const labelPosition = {
		x: (sx + tx) / 2,
		y: (sy + ty) / 2,
	};

	return (
		<>
			<BaseEdge
				path={edgePath}
				markerEnd={markerEnd}
				style={style}
			/>
			<EdgeLabelRenderer>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "flex-end",
						position: 'absolute',
						transform: `translate(-50%, -50%) translate(${labelPosition.x}px,${labelPosition.y}px)`,
						fontSize: 12,
						pointerEvents: 'all',
					}}
					className="nodrag nopan"
				>
					{label && (
						<text
							x={labelPosition.x}
							y={labelPosition.y}
							dx={-10}
							style={{
								fontSize: 12,
								fontWeight: 500,
								margin: 8,
								backgroundColor: "rgba(204,204,204, 0.8)",
								padding: 4,
								borderRadius: 5,
								boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)'
							}}
						>
							{label}
						</text>
					)}
					{
						selected &&
						<button
							style={{
								width: 18,
								height: 18,
								borderRadius: "50%",
								outline: 0,
								backgroundColor: "red",
								border: "1px solid white",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
							}}
							onClick={handleDeleteEdge}>
							<DeleteIcon sx={{ fontSize: 12, color: "white" }} />
						</button>
					}
				</div>
			</EdgeLabelRenderer>
		</>
	);
}

export default FloatingEdge;
