import { ModalLayout } from "components/ModalLayout";
import { ModalLayoutProps } from "types/ModalLayout";
import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useState } from "react";
import { useVerifyNoHasRestriction } from "utils/useVerifyNoHasRestriction";
import SelectFilterModal from "components/selectFilterModal";
import { FETCH_GROUPS } from "graphql/queries/fetchGroups";
import { FETCH_USERS } from "graphql/queries/fetchUsers";
import { format } from "date-fns";
import GenericInput from "components/Input/GenericInput";
import SearchIcon from "@mui/icons-material/Search";
import SelectFilterNotUseQuery from "components/selectFilterModal/SelectFilterNotUseQuery";
import { FETCH_SUBJECTS } from "graphql/queries/fetchSubjects";
import { FETCH_STATUS } from "graphql/queries/fetchStatus";
import { FETCH_DISTRICTS } from "graphql/queries/fetchDistricts";

type ModalFilterProps = ModalLayoutProps & {
	handleFilter: (values) => void;
};

const valuesSituation = [
	{
		id: 1,
		name: "Em andamento",
	},
	{
		id: 2,
		name: "Concluido",
	},
	{
		id: 3,
		name: "Cancelado",
	},
	{
		id: 4,
		name: "Suspenso",
	},
];

export function ModalFilter({
	open,
	handleClose,
	handleFilter,
}: ModalFilterProps) {
	const subdomain = window.location.hostname.split(".")[0];
	const [search, setSearch] = useState("");
	const [lateDaysFilter, setLateDaysFilter] = useState("");
	const [streetDialog, setStreetDialog] = useState("");
	const [upfmlsFilter, setUpfmlsFilter] = useState("");
	const [numberDialog, setNumberDialog] = useState("");
	const [startDateDialog, setStartDateDialog] = useState<Date | null>(null);
	const [finishDateDialog, setFinishDateDialog] = useState<Date | null>(null);
	const [loteDialog, setLoteDialog] = useState("");
	const [quadraDialog, setQuadraDialog] = useState("");
	const [cadastralIndexDialog, setCadastralIndexDialog] = useState("");
	const [offendersNameDialog, setOffendersNameDialog] = useState("");
	const [situation, setSituation] = useState([
		{
			id: 1,
			title: "Grupo responsável",
			options: [],
		},
		{
			id: 2,
			title: "Responsável",
			options: [],
		},
		{
			id: 3,
			title: "Situação",
			options: [],
		},
		{
			id: 4,
			title: "Assunto",
			options: [],
		},
		{
			id: 5,
			title: "Status",
			options: [],
		},
		{
			id: 6,
			title: "Bairro",
			options: [],
		},
	]);

	const upfmlsOptions = [
		{
			id: 1,
			name: "SIM",
			value: true,
		},
		{
			id: 2,
			name: "NÃO",
			value: false,
		},
	];

	const lateDaysOptions = [
		{
			id: 1,
			name: "atrasa hoje",
			value: "today",
		},
		{
			id: 2,
			name: "em até 7 dias",
			value: "7",
		},
		{
			id: 3,
			name: "em até 15 dias",
			value: "15",
		},
		{
			id: 4,
			name: "em até 1 mês",
			value: "1m",
		},
		{
			id: 5,
			name: "em mais de 1 mês",
			value: "+1m",
		},
		{
			id: 6,
			name: "Atrasados",
			value: "delayed",
		},
	];

	const noHasRestrictionUsers = useVerifyNoHasRestriction("USUARIO");
	const noHasRestrictionTaxGroups = useVerifyNoHasRestriction("GRUPO_FISCAIS");

	const handleAddedSituation = (values) => {
		const title = values.find((item) => item.title).title;
		const index = situation.findIndex((item) => item.title === title);
		const newSituations = [...situation];
		newSituations[index].options = values.filter((item) => !item.title);
		setSituation(newSituations);
	};

	const handleClear = () => {
		setStartDateDialog(null);
		setFinishDateDialog(null);
		setStreetDialog("");
		setNumberDialog("");
		setUpfmlsFilter("");
		setLateDaysFilter("");
		setSearch("");
		setLoteDialog("");
		setQuadraDialog("");
		setCadastralIndexDialog("");
		setOffendersNameDialog("");
		setSituation([
			{
				id: 1,
				title: "Grupo responsável",
				options: [],
			},
			{
				id: 2,
				title: "Responsável",
				options: [],
			},
			{
				id: 3,
				title: "Situação",
				options: [],
			},
			{
				id: 4,
				title: "Assunto",
				options: [],
			},
			{
				id: 5,
				title: "Status",
				options: [],
			},
			{
				id: 6,
				title: "Bairro",
				options: [],
			},
		]);
	};

	const onRequestFilter = () => {
		const filter = {
			startDate: startDateDialog
				? format(new Date(startDateDialog), "yyyy-MM-dd")
				: null,
			finishDate: finishDateDialog
				? format(new Date(finishDateDialog), "yyyy-MM-dd")
				: null,
			search: search,
			upfmls: upfmlsFilter,
			lateDays: lateDaysFilter,
			street: streetDialog,
			number: numberDialog,
			lote: loteDialog,
			quadra: quadraDialog,
			cadastralIndex: cadastralIndexDialog,
			offendersName: offendersNameDialog,
			group_ids: situation[0].options.map((item) => item.id),
			responsible_ids: situation[1].options.map((item) => item.id),
			situations: situation[2].options.map((item) => item.name),
			subject_ids: situation[3].options.map((item) => item.id),
			status_ids: situation[4].options.map((item) => item.id),
			district_ids: situation[5].options.map((item) => item.id),
		};

		handleFilter(filter);
	};

	return (
		<ModalLayout
			open={open}
			handleClose={handleClose}
			additionalFuntion={handleClear}
			title="Filtrar protocolos"
			buttonTextClose="Limpar filtros"
			buttonText="Filtrar"
			handleSubmit={onRequestFilter}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} marginTop={2}>
					<GenericInput
						placeholder="Pesquisar"
						endAdornment={<SearchIcon color="disabled" />}
						onChange={(e) => setSearch(e.target.value)}
						value={search}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} sm={6} marginTop={2}>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale="pt-br"
					>
						<DatePicker
							disableFuture
							inputFormat="DD/MM/YYYY"
							label="Início"
							value={startDateDialog}
							onChange={(newValue) => {
								setStartDateDialog(newValue);
							}}
							renderInput={(params) => (
								<TextField {...params} sx={{ width: "100%" }} />
							)}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid item xs={12} sm={6} marginTop={2}>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale="pt-br"
					>
						<DatePicker
							disableFuture
							inputFormat="DD/MM/YYYY"
							label="Fim"
							value={finishDateDialog}
							onChange={(newValue) => {
								setFinishDateDialog(newValue);
							}}
							renderInput={(params) => (
								<TextField {...params} sx={{ width: "100%" }} />
							)}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid item xs={12}>
					<FormControl fullWidth>
						<InputLabel htmlFor={"dialog-prazo de resolução"}>
							Prazo de resolução
						</InputLabel>
						<Select
							input={
								<OutlinedInput
									label={"Prazo de resolução"}
									id={"dialog-prazo de resolução"}
								/>
							}
							value={lateDaysFilter}
							onChange={(event) => {
								setLateDaysFilter(event.target.value);
							}}
						>
							<MenuItem value={null}>
								<em>Nenhum</em>
							</MenuItem>
							{lateDaysOptions.map((option) => (
								<MenuItem key={option.id} value={String(option.value)}>
									{option.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<SelectFilterNotUseQuery
						title="Situação"
						fullWidth
						onChange={(values) => {
							handleAddedSituation(values);
						}}
						loading={false}
						selectValues={valuesSituation}
						valuesSelecteds={situation[2].options.map((item) => ({
							id: item.id,
							name: item.name,
						}))}
					/>
				</Grid>

				<Grid item xs={12} sm={6}>
					<SelectFilterModal
						title="Assunto"
						fullWidth
						onChange={(values) => {
							handleAddedSituation(values);
						}}
						queryName={FETCH_SUBJECTS}
						order_by="subjects.name"
						dataName="fetchSubjects"
						valuesSelecteds={situation[3].options.map((item) => ({
							id: item.id,
							name: item.name,
						}))}
					/>
				</Grid>

				{noHasRestrictionTaxGroups && (
					<Grid item xs={12} sm={6}>
						<SelectFilterModal
							title="Grupo responsável"
							fullWidth
							onChange={(values) => {
								handleAddedSituation(values);
							}}
							queryName={FETCH_GROUPS}
							order_by="name"
							dataName="fetchGroups"
							valuesSelecteds={situation[0].options.map((item) => ({
								id: item.id,
								name: item.name,
							}))}
						/>
					</Grid>
				)}
				<Grid item xs={12} sm={6}>
					<SelectFilterModal
						title="Status"
						fullWidth
						onChange={(values) => {
							handleAddedSituation(values);
						}}
						queryName={FETCH_STATUS}
						order_by="status.name"
						dataName="fetchStatus"
						valuesSelecteds={situation[4].options.map((item) => ({
							id: item.id,
							name: item.name,
						}))}
					/>
				</Grid>
				{noHasRestrictionUsers && (
					<Grid item xs={12} sm={6}>
						<SelectFilterModal
							title="Responsável"
							fullWidth
							onChange={(values) => {
								handleAddedSituation(values);
							}}
							queryName={FETCH_USERS}
							order_by="users.name"
							dataName="fetchUsers"
							valuesSelecteds={situation[1].options.map((item) => ({
								id: item.id,
								name: item.name,
							}))}
						/>
					</Grid>
				)}
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth>
						<InputLabel htmlFor={"dialog-GerouUPFMLS"}>Gerou UPFMLS</InputLabel>
						<Select
							input={
								<OutlinedInput
									label={"Gerou UPFMLS"}
									id={"dialog-GerouUPFMLS"}
								/>
							}
							value={upfmlsFilter}
							onChange={(event) => {
								setUpfmlsFilter(
									JSON.parse(String(event.target.value).toLowerCase())
								);
							}}
						>
							<MenuItem value={null}>
								<em>Nenhum</em>
							</MenuItem>
							{upfmlsOptions.map((option) => (
								<MenuItem key={option.id} value={String(option.value)}>
									{option.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<TextField
						label="Rua"
						fullWidth
						value={streetDialog}
						onChange={(e) => setStreetDialog(e.target.value)}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField
						label="Número"
						fullWidth
						value={numberDialog}
						onChange={(e) => setNumberDialog(e.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<SelectFilterModal
						title="Bairro"
						fullWidth
						onChange={(values) => {
							handleAddedSituation(values);
						}}
						queryName={FETCH_DISTRICTS}
						order_by="name"
						moreVariables={{
							domain:
								subdomain === "localhost"
									? process.env.REACT_DEFAULT_DOMAIN || "lagoasanta"
									: subdomain,
						}}
						dataName="fetchDistricts"
						valuesSelecteds={situation[5].options.map((item) => ({
							id: item.id,
							name: item.name,
						}))}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						label="Lote"
						fullWidth
						value={loteDialog}
						onChange={(e) => setLoteDialog(e.target.value)}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						label="Quadra"
						fullWidth
						value={quadraDialog}
						onChange={(e) => setQuadraDialog(e.target.value)}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						label="Inscrição cadastral"
						fullWidth
						value={cadastralIndexDialog}
						onChange={(e) => setCadastralIndexDialog(e.target.value)}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						label="Nome do autuado"
						fullWidth
						value={offendersNameDialog}
						onChange={(e) => setOffendersNameDialog(e.target.value)}
					/>
				</Grid>
			</Grid>
		</ModalLayout>
	);
}
