import { Stack, Animation } from "@devexpress/dx-react-chart";
import {
	Chart,
	ArgumentAxis,
	ValueAxis,
	BarSeries,
	Title,
	Legend,
	Tooltip,
} from "@devexpress/dx-react-chart-material-ui";
import { Box, useMediaQuery } from "@mui/material";
import Button from "components/Button";
import { EmptyMessage } from "components/EmptyMessage";

interface ChartProps {
	data: Array<{
		argument: string;
		valuesFields: Array<{
			[key: string]: number;
		}>;
	}>;
	stacks: Array<{
		series: string[];
	}>;
	colors: string[];
	rotated?: boolean;
	isShowTitle?: boolean;
	title?: string;
}

export function ChartComponent({
	data,
	stacks,
	colors,
	rotated,
	title,
}: ChartProps) {
	const isSmallScreen = useMediaQuery("(max-width: 600px)");
	if (data.length === 0) {
		return (
			<Box mt={12}>
				<EmptyMessage message="Nenhum protocolo cadastrado" />
			</Box>
		);
	}

	const dataChart = data.map((item) => {
		return {
			argument: item.argument,
			...item.valuesFields[0],
		};
	});

	const valueField = Object.keys(dataChart[0]).filter(
		(item) => item !== "argument"
	);

	const Root = (props) => (
		<Legend.Root
			{...props}
			sx={{
				display: "flex",
				margin: "auto",
				flexDirection: "row",
				flexWrap: isSmallScreen ? "wrap" : "nowrap",
			}}
		/>
	);
	const Label = (props) => (
		<Legend.Label {...props} sx={{ whiteSpace: "nowrap" }} />
	);

	return (
		<Chart data={dataChart} rotated={rotated} height={400}>
			<ArgumentAxis />
			<ValueAxis />

			{Array.from({ length: stacks[0].series.length }, (_, index) => (
				<BarSeries
					key={index}
					name={stacks.map((item) => item.series[index])[0]}
					argumentField="argument"
					valueField={valueField[index]}
					color={colors[index]}
				/>
			))}

			<Animation />
			<Legend position="bottom" rootComponent={Root} labelComponent={Label} />
			{title && <Title text={title} />}
			<Stack stacks={stacks} />
		</Chart>
	);
}
