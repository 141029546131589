import { gql } from "@apollo/client";

export const FETCH_MY_POINTS = gql`
	query ($page: Int, $limit: Int) {
		fetchMyPoints(page: $page, limit: $limit) {
			total
			current_page
			per_page
			last_page
			data {
				protocol
				points
				accumulated_at
			}
		}
	}
`;
