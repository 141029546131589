import { ModalLayout } from "components/ModalLayout";
import { ModalLayoutProps } from "types/ModalLayout";
import { DetailDynamicReport } from "types/graphql/queries/detailDynamicReport";
import { FormProvider, useForm } from "react-hook-form";
import { FormCreateAnalyticalReport } from "../../Form/AnalyticalReport";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@apollo/client";
import { UPDATE_DYNAMIC_REPORT } from "graphql/mutations/editDynamicReport";
import { useSnackbar } from "notistack";

type ModalEditAnalyticalReportProps = ModalLayoutProps & {
	report: DetailDynamicReport;
	refetch: () => void;
};

type FormData = {
	author: string;
	data_source: string;
	columns_name: string[];
	title: string;
	group_by: string;
	order_by: string;
	order_direction: string;
	visualization_rule: string;
	spec: Array<string>;
	profile_ids: Array<{
		id: number;
		name: string;
	}>;
};

const schema = yup.object().shape({
	author: yup.string(),
	data_source: yup.string().required("Campo obrigatório"),
	columns_name: yup.array().of(yup.string()).required("Campo obrigatório"),
	title: yup.string().required("Campo obrigatário"),
	group_by: yup.string().nullable(),
	order_by: yup.string().nullable(),
	order_direction: yup.string().nullable(),
	visualization_rule: yup.string().required("Campo obrigatório").nullable(),
	spec: yup.array().of(yup.string()),
	profile_ids: yup.array().of(
		yup.object().shape({
			id: yup.number(),
			name: yup.string(),
		})
	),
});

export function ModalEditAnalyticalReport({
	open,
	report,
	handleClose,
	refetch,
}: ModalEditAnalyticalReportProps) {
	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			author: report.created_by.name,
			data_source: report.data_source,
			columns_name: report.columns,
			title: report.title,
			group_by: report.group_by,
			order_by: report.order_by,
			order_direction: report.order_direction,
			visualization_rule: report.visualization_rule,
			spec: [],
			profile_ids: report.profiles,
		},
	});

	const [updateDynamicReport, { loading }] = useMutation(UPDATE_DYNAMIC_REPORT);
	const { enqueueSnackbar } = useSnackbar();

	const { handleSubmit } = methods;

	const onSubmit = async (data: FormData) => {
		const dataFormatted = {
			id: report.id,
			type: "Analítico",
			data_source: data.data_source,
			title: data.title,
			group_by:
				data.data_source === "Protocolos" && data.group_by === "user"
					? "responsibleUser"
					: data.group_by || "",
			order_by: data.order_by,
			order_direction: data.order_direction,
			visualization_rule: data.visualization_rule,
			columns:
				data.data_source === "Protocolos"
					? data.columns_name.filter((item) => item !== "userName")
					: data.columns_name.filter((item) => item !== "responsibleName"),
			spec: JSON.stringify(data.spec),
			profile_ids:
				data.visualization_rule === "Perfis"
					? data.profile_ids.map((item) => item.id)
					: [],
		};
		try {
			await updateDynamicReport({
				variables: dataFormatted,
			});
			enqueueSnackbar("Relatório atualizado com sucesso", {
				variant: "success",
			});
			refetch();
			handleClose();
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
	};

	return (
		<FormProvider {...methods}>
			<ModalLayout
				open={open}
				handleClose={handleClose}
				title="Editar relatório"
				maxWidth="lg"
				handleSubmit={handleSubmit(onSubmit)}
				isLoading={loading}
			>
				<FormCreateAnalyticalReport />
			</ModalLayout>
		</FormProvider>
	);
}
