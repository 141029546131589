import { useMutation } from "@apollo/client";
import { ModalLayout } from "components/ModalLayout";
import { UPDATE_DYNAMIC_REPORT } from "graphql/mutations/editDynamicReport";
import { useSnackbar } from "notistack";
import { FormProvider, useForm } from "react-hook-form";
import { ModalLayoutProps } from "types/ModalLayout";
import { DetailDynamicReport } from "types/graphql/queries/detailDynamicReport";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormCreateSinteticReport } from "../../Form/SinteticReport";
import { Graphic } from "../../ModalCreateReport";

type ModalEditSinteticReportProps = ModalLayoutProps & {
	report: DetailDynamicReport;
	refetch: () => void;
};

type FormData = {
	author: string;
	data_source: string;
	columns_name: string[];
	title: string;
	titles: string[];
	group_by: string;
	order_by: string;
	order_direction: string;
	visualization_rule: string;
	spec: Array<string>;
	profile_ids: Array<{
		id: number;
		name: string;
	}>;
	graphics: Array<Graphic>;
	subject_id: number;
};

const schema = yup.object().shape({
	author: yup.string(),
	data_source: yup.string().required("Campo obrigatório"),
	title: yup.string().required("Campo obrigatário"),
	group_by: yup.string().nullable(),
	order_by: yup.string().nullable(),
	order_direction: yup.string().nullable(),
	visualization_rule: yup.string().required("Campo obrigatório").nullable(),
	spec: yup.array().of(yup.string()),
	profile_ids: yup.array().of(
		yup.object().shape({
			id: yup.number(),
			name: yup.string(),
		})
	),
	subject_id: yup.number()
});

export function ModalEditSinteticReport({
	open,
	handleClose,
	report,
	refetch,
}: ModalEditSinteticReportProps) {
	const titles = JSON.parse(report.spec)[0].items;
	const graphics = JSON.parse(report.spec)[1].items;
	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			author: report.created_by.name,
			data_source: report.data_source,
			title: report.title,
			group_by: report.group_by,
			order_by: report.order_by,
			order_direction: report.order_direction,
			visualization_rule: report.visualization_rule,
			spec: [],
			profile_ids: report.profiles,
			titles: titles,
			graphics: graphics,
			subject_id: report.subject_id
		},
	});

	const [updateDynamicReport, { loading }] = useMutation(UPDATE_DYNAMIC_REPORT);
	const { enqueueSnackbar } = useSnackbar();

	const { handleSubmit } = methods;

	const onSubmit = async (data: FormData) => {
		const specSynthetic = [
			{
				type: "wrapper",
				col: 12,
				style: {},
				items: data.titles,
			},
			{
				type: "wrapper",
				col: 12,
				style: {},
				items: data.graphics.map((item) => ({
					...item,
					data: item.data,
				})),
			},
		];

		const dataFormatted = {
			id: report.id,
			type: "Sintético",
			data_source: data.data_source,
			title: data.title,
			titles: data.titles,
			visualization_rule: data.visualization_rule,
			profile_ids:
				data.visualization_rule === "Perfis"
					? data.profile_ids.map((item) => item.id)
					: [],
			spec: JSON.stringify([...specSynthetic]),
			subject_id: data.subject_id
		};
		try {
			await updateDynamicReport({
				variables: dataFormatted,
			});
			enqueueSnackbar("Relatório atualizado com sucesso", {
				variant: "success",
			});
			refetch();
			handleClose();
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
	};
	return (
		<FormProvider {...methods}>
			<ModalLayout
				open={open}
				handleClose={handleClose}
				title="Editar relatório"
				maxWidth="lg"
				handleSubmit={handleSubmit(onSubmit)}
				isLoading={loading}
			>
				<FormCreateSinteticReport />
			</ModalLayout>
		</FormProvider>
	);
}
