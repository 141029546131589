import { useState } from "react";
import { Typography, Stack, IconButton, Link, Grid, Box } from "@mui/material";
import Input from "components/Input";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { FileUploader } from "react-drag-drop-files";
import { useFormContext} from "react-hook-form";
import { useSnackbar } from "notistack";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export function StepDescription() {
	const {watch, setValue } = useFormContext();
	const { enqueueSnackbar } = useSnackbar();

	const files = watch("files", []);

	const fileTypes = ["SVG", "JPG", "PNG", "GIF", "PDF"];

	const handleAddFiles = (e: File) => {
        const newFiles = e;
        const filesArray = [newFiles];

        if (
            files &&
            filesArray.filter((fileArray) =>
                files.find((file) => file.name === fileArray.name)
            ).length > 0
        ) {
            enqueueSnackbar("Arquivo já adicionado", { variant: "error" });
            return;
        }

        const concatedFiles = files ? [...files, ...filesArray] : filesArray;

				if (concatedFiles.length > 5) {
						enqueueSnackbar("Você atingiu o limite máximo de 5 arquivos", { variant: "error" });
						return;
				}

        setValue("files", concatedFiles);
    };

		const filePreview = (file: File) => {
        const fileURL = URL.createObjectURL(file);
        return (
            <Box>
                <Link
                    variant="h6"
                    fontSize="1rem"
                    target="_blank"
                    rel="norefferer"
                    href={fileURL}
                >
                    {file.name}
                </Link>
            </Box>
        );
    };

		const handleDeleteFile = (file: File) => {
        const newFiles = files.filter((f) => f.name !== file.name);

        setValue("files", newFiles);
    };

		const handleSizeError = (file) => {
			enqueueSnackbar("O arquivo supera o limite máximo de 5MB", { variant: "error" });
		}

	return (
		<>
			<Stack spacing={1} flex={1} maxHeight="500px" overflow="auto" sx={{ paddingTop: "10px"}}>
				<Input
					name="description"
					multiline
					rows={3}
					placeholder="Descreva os dados da irregularidade com o máximo
					de detalhes possíveis para conferência.*"
					type="text"
				/>
			</Stack>

			<Typography variant="subtitle2" sx={{ fontSize: "0.9rem", fontWeight: 500, marginTop: "30px" }}>Anexos</Typography>

				<FileUploader  handleChange={(e) => handleAddFiles(e)} name="file" types={fileTypes} onSizeError={(e) => handleSizeError(e)} maxSize={5}>
					<Stack sx={{ cursor: "pointer", border: "1px dashed rgba(0, 0, 0, 0.12)", borderRadius: "4px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
						<IconButton sx={{ backgroundColor: "#cfeffd", width: "40px", color: "#2979ff", marginTop: "30px", marginBottom: "10px" }}>
							<UploadFileIcon />
						</IconButton>

						<Typography variant="subtitle1" fontSize="1rem">
							<Link>Click para upload</Link> ou arraste e solte
						</Typography>
						<Typography variant="body2" fontSize="0.9rem" sx={{color: "rgba(0, 0, 0, 0.54)", marginBottom: "30px" }}>SVG, PNG, JPG, GIF ou PDF (max. 5MB)</Typography>
					</Stack>
				</FileUploader>

			<Grid item xs={12}>
					<Stack spacing={2} marginTop={2}>
							{files && files.length > 0 ? (
									files.map((file: File) => (
											<Box
													display="flex"
													columnGap={2}
													alignItems="center"
													key={file.name}
											>
													<Box>
															<AttachFileIcon />
													</Box>
													{filePreview(file)}
													<Box>
															<IconButton onClick={() => handleDeleteFile(file)}>
																	<DeleteOutlineIcon color="error" />
															</IconButton>
													</Box>
											</Box>
									))
							) : (
									<Typography variant="subtitle2">
											Nenhum arquivo anexado
									</Typography>
							)}
					</Stack>
			</Grid>
		</>
	);
}
