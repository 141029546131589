import { gql } from "@apollo/client";

export const FETCH_USERS = gql`
	query (
		$keyword: String
		$order_by: String
		$direction: String
		$perfil_id: Int
		$page: Int
		$limit: Int
		$subject_id: Int
		$user_id: Int
		$status: String
	) {
		fetchUsers(
			keyword: $keyword
			order_by: $order_by
			direction: $direction
			perfil_id: $perfil_id
			page: $page
			limit: $limit
			subject_id: $subject_id
			user_id: $user_id
			status: $status
		) {
			total
			current_page
			per_page
			last_page
			data {
				id
				company_id
				perfil {
					id
					company_id
					perfil_id
					code
					name
					created_at
				}
				name
				email
				params {
					matricula
				}
				groups {
					id
					code
					name
					description
					status
				}
				status
				is_point_accumulator
				is_mobile_user
				created_at
				deleted_at
			}
		}
	}
`;

