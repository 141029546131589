import { useSnackbar } from "notistack";

export const closeModalExportCsv = (
  percentageDataExport: number,
  handleClose: () => void,
): void => {

	const { enqueueSnackbar } = useSnackbar();

  if (percentageDataExport === 100) {
    enqueueSnackbar('Exportação concluída com sucesso!', {
      variant: 'success',
      preventDuplicate: true,
    });
    setTimeout(() => {
      handleClose();
    }, 2000);
  }
};
