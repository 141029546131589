import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function Unauthorized() {
	const navigate = useNavigate();

	const goBack = () => navigate(-1);

	return (
		<Box component="section">
			<Typography variant="h1">Não Autorizado</Typography>
			<Typography variant="body1">
				Você não tem autorização para acessar essa página.
			</Typography>
			<div className="flexGrow">
				<button onClick={goBack}>Voltar</button>
			</div>
		</Box>
	);
}
