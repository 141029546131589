import { ModalLayout } from "components/ModalLayout";
import { ModalLayoutProps } from "types/ModalLayout";
import { type } from "../consts";
import { Select } from "components/Select";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import {
	Box,
	Stack,
	TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useQuery } from "@apollo/client";
import { FetchTasksLabelList } from "types/graphql/queries/fetchTasksLabelList";
import { FETCH_TASKS_LABEL_LIST } from "graphql/queries/fetchTasksLabelList";
import Input from "components/Input";

type TitleProps = {
	type: string;
	customized: boolean;
	titles: string[];
	subtitle: string;
};

type ModalAddTypeOfDataProps = ModalLayoutProps & {
	handleAddTypeOfData: (data: TitleProps) => void;
	title: string;
	customized: boolean;
	titles: string[];
	subtitle: string;
};

export function ModalAddTypeOfData({
	open,
	handleClose,
	handleAddTypeOfData,
	title = "",
	customized = false,
	titles = [],
	subtitle = ""
}: ModalAddTypeOfDataProps) {
	const methods = useForm({
		defaultValues: {
			type: title,
			customized: customized,
			titles: titles,
			subtitle: subtitle
		},
	});

	const {getValues, setValue} = methods;

	const { type: typeValue, customized: customizedValue } = getValues();
	
	const { data: dataTasks } = useQuery<FetchTasksLabelList>(
		FETCH_TASKS_LABEL_LIST
	);

	const allTasks = dataTasks?.fetchTasksLabelList?.labels;

	const onSubmit = (data: TitleProps) => {
		handleAddTypeOfData(
			{ 
				type: data.type, 
				customized: data.type === "Por Tarefa", 
				titles: data.titles,
				subtitle: data.subtitle
			}
		);
	};
	return (
		<FormProvider {...methods}>
			<ModalLayout
				open={open}
				handleClose={handleClose}
				title="Editar tipo de dados"
				buttonText="Editar"
				handleSubmit={methods.handleSubmit(onSubmit)}
			>
				<Stack spacing={2} mt={2}>
					<Box>
						<Select
							name="type"
							label="Selecione um item dos dados"
							options={type}
							defaultValue={customized ? "Por Tarefa" : ""}
							fullWidth
							marginSelect={0}
						/>

						{(typeValue === "Por Tarefa" || customizedValue) && (
							<>
								<Box mt={2}>
									<Autocomplete
										options={allTasks}
										fullWidth
										renderInput={(params) => (
											<TextField {...params} label="Selecione uma tarefa" />
										)}
										defaultValue={titles[0]}
										disableClearable
										getOptionLabel={(option) => option}
										onChange={(event, newValue) => {
											setValue("titles", [newValue]);
										}}
									/>
								</Box>
								<Box mt={2}>
									<Input name="subtitle" placeholder="Título" fullWidth />
								</Box>
							</>
						)}
					</Box>
				</Stack>
			</ModalLayout>
		</FormProvider>
	);
}
