import React, { forwardRef, ForwardRefRenderFunction } from "react";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";

const loadingButton: ForwardRefRenderFunction<
	HTMLButtonElement,
	LoadingButtonProps
> = (
	{
		onClick,
		children,
		type = "button",
		variant,
		color,
		loading,
		disabled,
		...rest
	},
	ref
) => {
	return (
		<LoadingButton
			loading={loading}
			onClick={onClick}
			variant={variant}
			type={type}
			{...rest}
			ref={ref}
			color={color}
			disabled={disabled}
		>
			{children}
		</LoadingButton>
	);
};

export default forwardRef(loadingButton);
