import { Grid, Typography, Box } from "@mui/material";

interface FieldReceiverProps {
	values?: Array<{
		[key: string]: string | number | boolean | Array<string | number | boolean>;
	}>;
}

function FieldReceiver({ values }: FieldReceiverProps) {
	return (
		<>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">Nome Completo</Typography>
					<Typography variant="subtitle2">
						{values && values[0].name ? values[0].name : "-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6} alignSelf="self-end">
				<Box>
					<Typography variant="caption">Identidade ou CPF</Typography>
					<Typography variant="subtitle2">
						{values && values[0].document ? values[0].document : "-"}
					</Typography>
				</Box>
			</Grid>
		</>
	);
}

export default FieldReceiver;
