import { Animation, Stack } from "@devexpress/dx-react-chart";
import {
	ArgumentAxis,
	BarSeries,
	Chart,
	Legend,
	ValueAxis,
} from "@devexpress/dx-react-chart-material-ui";
import { Grid, styled, Typography } from "@mui/material";

interface VariantStackedBarProps {
	chartData: Array<{
		[key: string]: number;
	}>;
	series: Array<{
		name: string;
		valueField: string;
		argumentField: string;
	}>;
	title: string;
	label: string;
	rotate?: boolean;
	medias?: { [key: string]: boolean };
}

export function VariantStackedBar({
	chartData,
	series,
	title,
	label,
	rotate,
	medias
}: VariantStackedBarProps) {
	const Root = (props: Legend.RootProps) => <Grid container {...props} />;
	const Label = (props: Legend.LabelProps) => (
		<Legend.Label {...props} sx={{ whiteSpace: "nowrap" }} />
	);

	const Item = (props: Legend.ItemProps) => (
		<Grid item xs>
			<Legend.Item {...props} sx={{ p: 0 }} />
		</Grid>
	);

	const StyledChart = styled(Chart)(() => ({}));

	return (
		<>
			<Typography variant="h6">{title}</Typography>
			<Typography variant="caption">{label}</Typography>
			<StyledChart data={chartData} rotated={rotate} height={300}>
				<ArgumentAxis />
				<ValueAxis />

				{series.map((serie) => {
					return (
						<BarSeries
							key={serie.name}
							valueField={serie.valueField}
							argumentField={serie.argumentField}
							name={serie.name}
						/>
					);
				})}
				<Animation />
				<Legend
					position="bottom"
					rootComponent={Root}
					labelComponent={Label}
					itemComponent={Item}
				/>
				<Stack
					stacks={[
						{
							series: series.map((item) => item.name),
						},
					]}
				/>
			</StyledChart>
		</>
	);
}
