import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Stack,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Button from "components/Button";
import { ModalLayoutProps } from "types/ModalLayout";
import LoadingButton from "components/LoadingButton";
import IconButton from "components/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { ConfirmDialog } from "components/ConfirmDialog";

export function ModalLayout({
	handleClose,
	open,
	title,
	children,
	handleSubmit,
	buttonText = "Salvar",
	isLoading,
	isDisabled,
	isNotShowButtonSubmit = false,
	isNotShowButtonClose = false,
	variantButtonSubmit = "contained",
	variantButtonClose = "text",
	buttonTextClose = "Fechar",
	isShowAdditionalButton,
	icon,
	onClickAdditionalButton,
	additionalButtonIsComponent,
	componentAdditionalButton,
	additionalFuntion,
	isFullScreen,
	centerButtons,
	modalConfirm = false,
	...rest
}: ModalLayoutProps) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const [openConfirm, setOpenConfirm] = useState(false);

	const handleConfirmClose = () => {
		setOpenConfirm(false);
		handleClose();
	};

	return (
		<Dialog
			fullScreen={fullScreen}
			open={open}
			onClose={
				modalConfirm
					? () => setOpenConfirm(true)
					: handleClose
			}
			aria-labelledby="responsive-dialog-title"
			{...rest}
			sx={{
				"& .MuiDialog-paper": {
					width: "100%",
				},
			}}
		>
			{!isFullScreen && (
				<DialogTitle id="responsive-dialog-title">
					<Stack
						direction="row"
						flex={1}
						justifyContent="space-between"
						alignItems="center"
					>
						{title}
						{isShowAdditionalButton && (
							<>
								{additionalButtonIsComponent ? (
									<>{componentAdditionalButton}</>
								) : (
									<IconButton onClick={onClickAdditionalButton}>
										{icon ?? <DeleteIcon color="error" />}
									</IconButton>
								)}
							</>
						)}
					</Stack>
				</DialogTitle>
			)}
			<DialogContent
				sx={{
					padding: isFullScreen ? 0 : "20px 24px",
					display: isFullScreen ? "flex" : "block",
					flexDirection: isFullScreen ? "column" : "row",
				}}
			>
				{children}
			</DialogContent>
			<DialogActions
				sx={{
					justifyContent: centerButtons ? "center" : "flex-end",
				}}
			>
				{!isNotShowButtonClose && (
					<Button
						autoFocus
						onClick={() => {
							if (additionalFuntion) {
								additionalFuntion();
							} else {
								modalConfirm ? setOpenConfirm(true) : handleClose();
							}
						}}
						disabled={isLoading}
						variant={variantButtonClose}
					>
						{buttonTextClose}
					</Button>
				)}
				{!isNotShowButtonSubmit && (
					<LoadingButton
						onClick={handleSubmit}
						autoFocus
						variant={variantButtonSubmit}
						loading={isLoading}
						disabled={isDisabled}
					>
						{buttonText}
					</LoadingButton>
				)}
			</DialogActions>

			{modalConfirm && (
				<ConfirmDialog
					open={openConfirm}
					onClose={() => setOpenConfirm(false)}
					onConfirm={handleConfirmClose}
					title="Deseja realmente sair?"
					description="Ao sair você perderá todas as informações preenchidas."
				/>
			)}
		</Dialog>

	);
}
