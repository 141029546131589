import React from "react";
import { Typography, Stack, Box } from "@mui/material";

export default function SidebarFlow() {
	const onDragStart = (
		event: React.DragEvent<HTMLDivElement>,
		nodeType: string,
		label: string
	) => {
		event.dataTransfer.setData("application/reactflow", nodeType);
		event.dataTransfer.setData("application/reactflow-label", label);
		event.dataTransfer.effectAllowed = "move";
	};

	return (
		<Stack spacing={1} marginTop={2} paddingX={1}>
			<Typography variant="body1">Adicionar componentes</Typography>
			<Box
				onDragStart={(event) => onDragStart(event, "START", "Início")}
				draggable
				sx={{
					cursor: "move",
				}}
				display="flex"
				flexDirection="column"
				alignItems="center"
			>
				<svg height="50" width="50">
					<circle
						fill="#025aae"
						stroke="#000"
						r="15"
						cx="30"
						cy="30"
						strokeWidth="2"
					/>
				</svg>
				<Typography variant="body2">Início</Typography>
			</Box>
			<Box
				onDragStart={(event) => onDragStart(event, "TASK", "Tarefas")}
				draggable
				sx={{
					cursor: "move",
				}}
				display="flex"
				flexDirection="column"
				alignItems="center"
			>
				<svg width="80" height="40">
					<rect
						width="70"
						height="30"
						fill="#fff"
						stroke="#000"
						strokeWidth="2"
						x="4"
						y="2"
					/>
				</svg>
				<Typography variant="body2">Tarefas</Typography>
			</Box>
			<Box
				onDragStart={(event) => onDragStart(event, "EMAIL", "E-mail")}
				draggable
				sx={{
					cursor: "move",
				}}
				display="flex"
				flexDirection="column"
				alignItems="center"
			>
				<svg width="40" height="40">
					<rect
						width="30"
						height="30"
						fill="#3e65a9"
						stroke="#000"
						strokeWidth="2"
						x="7"
						y="7"
					/>
				</svg>
				<Typography variant="body2">E-mail</Typography>
			</Box>
			<Box
				onDragStart={(event) => onDragStart(event, "OCCURRENCE", "Ocorrências")}
				draggable
				sx={{
					cursor: "move",
				}}
				display="flex"
				flexDirection="column"
				alignItems="center"
			>
				<svg width="50" height="50">
					<rect
						width="40"
						height="40"
						fill="#fff"
						stroke="#000"
						strokeWidth="2"
						x="7"
						y="7"
					/>
				</svg>
				<Typography variant="body2">Ocorrências</Typography>
			</Box>
			<Box
				onDragStart={(event) => onDragStart(event, "CONDITION", "Condicional")}
				draggable
				sx={{
					cursor: "move",
				}}
				display="flex"
				flexDirection="column"
				alignItems="center"
			>
				<svg height="90" width="90">
					<polygon
						points="10,45, 45,10, 80,45, 45,80"
						fill="#ddd"
						stroke="#000"
						strokeWidth="2"
					/>
				</svg>
				<Typography variant="body2">Condicional</Typography>
			</Box>
			<Box
				onDragStart={(event) => onDragStart(event, "PONTUATE", "Pontuação")}
				draggable
				sx={{
					cursor: "move",
				}}
				display="flex"
				flexDirection="column"
				alignItems="center"
			>
				<svg height="70" width="70">
					<circle
						fill="#fff9d5"
						stroke="#000"
						r="25"
						cx="30"
						cy="30"
						strokeWidth="2"
					/>
				</svg>
				<Typography variant="body2">Pontuação</Typography>
			</Box>
			<Box
				onDragStart={(event) => onDragStart(event, "END", "Fim")}
				draggable
				sx={{
					cursor: "move",
				}}
				display="flex"
				flexDirection="column"
				alignItems="center"
			>
				<svg height="50" width="50">
					<circle
						fill="#55ab63"
						stroke="#000"
						r="15"
						cx="25"
						cy="25"
						strokeWidth="2"
					/>
				</svg>
				<Typography variant="body2">Fim</Typography>
			</Box>
		</Stack>
	);
}
