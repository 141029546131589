import { gql } from "@apollo/client";

export const UPDATE_PERFIL = gql`
	mutation (
		$id: Int!
		$perfil_id: Int
		$name: String!
		$restriction_ids: [Int]
	) {
		updatePerfil(
			id: $id
			perfil_id: $perfil_id
			name: $name
			restriction_ids: $restriction_ids
		)
	}
`;
