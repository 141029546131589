import { Stack } from "@mui/material";
import Button from "components/Button";
import Input from "components/Input";
import AddIcon from "@mui/icons-material/Add";
import { useFormContext, useForm, FormProvider } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { FETCH_USERS } from "graphql/queries/fetchUsers";
import { RadioFilter } from "components/RadioFilterModal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Recipient } from "..";

interface AddRecipientsProps {
	goToNextStep: () => void;
	goToPrevStep: () => void;
}

const schema = yup.object().shape({
	recipients: yup.array().of(
		yup.object().shape({
			name: yup.string().required("Nome é obrigatório"),
			email: yup
				.string()
				.email("Email inválido")
				.required("Email é obrigatório"),
		})
	),
});

type FormData = {
	recipients: Recipient[];
};

export function AddRecipients({
	goToNextStep,
	goToPrevStep,
}: AddRecipientsProps) {
	const { setValue: setValueContext, watch: watchContext } = useFormContext();
	const allValuesForm = watchContext();

	const methods = useForm({
		defaultValues: {
			recipients: allValuesForm.recipients,
		},
		resolver: yupResolver(schema),
	});
	const {
		setValue,
		watch,
		handleSubmit,
		formState: { errors },
	} = methods;

	const recipients = watch("recipients");

	const handleAddRecipient = () => {
		setValue("recipients", [
			...recipients,
			{ id: uuidv4(), name: "", email: "", idUser: null },
		]);
	};

	const handleRemoveRecipient = (id: string) => {
		const findIndex = recipients.findIndex((recipient) => recipient.id === id);
		const newState = [...recipients];
		newState.splice(findIndex, 1);
		setValue("recipients", newState);
	};

	const disabledNextButton = () => {
		return (
			recipients.some(
				(recipient) => recipient.name === "" || recipient.email === ""
			) || Object.keys(errors).length > 0
		);
	};

	const handleSelectUser = (
		user: { id: number; name: string; email?: string },
		index: number
	) => {
		setValue(`recipients.${index}.name`, user.name);
		setValue(`recipients.${index}.email`, user.email);
		setValue(`recipients.${index}.idUser`, user.id);
	};

	const onSubmit = (values: FormData) => {
		setValueContext("recipients", values.recipients);
		goToNextStep();
	};

	const handleClickPrevStep = () => {
		setValueContext("recipients", recipients);
		goToPrevStep();
	};

	return (
		<FormProvider {...methods}>
			<Stack spacing={4} mt={4}>
				<Stack spacing={2}>
					{recipients.map((recipient, index) => (
						<Stack
							direction={"row"}
							spacing={2}
							key={recipient.id}
							alignItems="center"
							mr={recipients.length > 1 && index === 0 ? 10 : 0}
						>
							<RadioFilter
								title="Selecionar usuário"
								placeholder="Usuário*"
								queryName={FETCH_USERS}
								dataName="fetchUsers"
								handleSelectAll={(value) => handleSelectUser(value, index)}
								defaultValue={recipient}
								isActiveUsers
							/>
							<Input
								name={`recipients.${index}.email`}
								placeholder="Email"
								readOnly
							/>
							{index >= 1 && (
								<Button
									sx={{
										maxWidth: "136px",
										maxHeight: "40px",
										fontSize: "12px",
									}}
									color="error"
									variant="contained"
									onClick={() => handleRemoveRecipient(recipient.id)}
								>
									Excluir
								</Button>
							)}
						</Stack>
					))}
					<Button
						startIcon={<AddIcon />}
						sx={{
							maxWidth: "136px",
						}}
						variant="outlined"
						onClick={handleAddRecipient}
					>
						Adicionar
					</Button>
				</Stack>
				<Stack direction="row" spacing={2}>
					<Button
						variant="contained"
						sx={{
							maxWidth: "100px",
						}}
						onClick={handleSubmit(onSubmit)}
						disabled={disabledNextButton()}
					>
						Próximo
					</Button>
					<Button
						variant="outlined"
						sx={{
							maxWidth: "100px",
						}}
						onClick={handleClickPrevStep}
					>
						Voltar
					</Button>
				</Stack>
			</Stack>
		</FormProvider>
	);
}
