import React, { useEffect, useState } from "react";
import { Stack, Grid, InputAdornment, Link } from "@mui/material";
import Input from "components/Input";
import { CircularProgress } from "components/CircularProgress";
import { maskCEP, validateNumberInput } from "utils/maskOutputs";
import { fetchCepData } from "utils/fetchCepData";
import { Control, Controller } from "react-hook-form";

export function StepPlaceComplaint({
	control,
	setIsZipcodeValid,
	setValue,
}: {
	control: Control;
	setIsZipcodeValid: (valid: boolean) => void;
	setValue: (name: string, value: string) => void;
}) {
	const [isAddressFilled, setIsAddressFilled] = useState(false);
	const [isDistrictFilled, setIsDistrictFilled] = useState(false);
	const [loadingCheckCep, setLoadingCheckCep] = useState(false);
	const [cep, setCep] = useState('');

	const handleCepChange = async (e) => {
		const cep = e.target.value;
		setCep(cep);

		setLoadingCheckCep(true);

		try {
			const data = await fetchCepData(cep);
			if (data && data.erro) {
				setIsZipcodeValid(false);
			} else if (data) {
				const { logradouro: street, bairro: district_name } = data;
				setValue('zipcode', cep)
				setValue('address', street);
				setValue('district_name', district_name);
				setIsAddressFilled(!!street);
				setIsDistrictFilled(true);
				setIsZipcodeValid(true);
			} else {
				setIsZipcodeValid(false);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoadingCheckCep(false);
		}

		return cep;
	}

	useEffect(() => {
		if (cep.length === 8) {
			handleCepChange({ target: { value: cep } });
		} else {
			setIsAddressFilled(false);
			setIsDistrictFilled(false);
		}
	}, [cep])
	return (
		<>
			<Stack
				flex={1}
				maxHeight="500px"
				overflow="auto"
				sx={{ paddingY: "10px" }}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<Controller
							name="zipcode"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<Input
									{...field}
									placeholder="CEP*"
									type="text"
									onChange={(e) => {
										let cep = e.target.value;
										cep = cep.replace('-', '');
										setValue('zipcode', cep);
										setCep(cep);
									}}
									value={maskCEP(field.value)}
									inputProps={{ maxLength: 9 }}
									endAdornment={
										loadingCheckCep && (
											<InputAdornment position="end">
												<CircularProgress size={20} />
											</InputAdornment>
										)
									}
								/>
							)}
						/>
						<Link
							href="https://buscacepinter.correios.com.br/app/endereco/index.php"
							target="_blank"
							variant="body2"
						>
							Não sei o CEP
						</Link>
					</Grid>


					<Grid item xs={12} sm={6}>
						<Input name="address" placeholder="Rua*" type="text" disabled={isAddressFilled} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<Input
							name="number"
							placeholder="Número"
							type="text"
							inputProps={{ maxLength: 5 }}
							onChange={(e) => {
								if (validateNumberInput(e.target.value)) {
									setValue('number', e.target.value);
								} else {
									setValue('number', '');
								}
							}} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<Input name="district_name" placeholder="Bairro*" type="text" disabled={isDistrictFilled} />
					</Grid>
					<Grid item xs={12} sm={12}>
						<Input
							name="reference"
							placeholder="Ponto de referência*"
							type="text"
						/>
					</Grid>
				</Grid>
			</Stack>
		</>
	);
}
