/* eslint-disable no-mixed-spaces-and-tabs */
import { useQuery } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import { ModalLayout } from "components/ModalLayout";
import { DETAIL_FLOW } from "graphql/queries/detailFlow";
import {
	Edge,
	MarkerType,
	Node,
	ReactFlow,
	useEdgesState,
	useNodesState,
} from "reactflow";
import { ModalLayoutProps } from "types/ModalLayout";
import { useEffect, useRef } from "react";
import { nodeTypes } from "utils/nodeTypes";
import { edgeTypes } from "utils/edgeTypes";
import BackgroundFlow from "components/Flow/BackgroundFlow";
import { FormProvider, useForm } from "react-hook-form";

type ModalDetailFlowProps = ModalLayoutProps & {
	flow_id: number;
	title: string;
	description: string;
};

export function ModalDetailFlow({
	open,
	handleClose,
	flow_id,
	title,
	description,
}: ModalDetailFlowProps) {
	const methods = useForm();
	const { data: dataFlow } = useQuery(DETAIL_FLOW, {
		variables: {
			flow_id: flow_id,
		},
	});

	const [nodes, setNodes] = useNodesState([]);
	const [edges, setEdges] = useEdgesState([]);
	const reactFlowWrapper = useRef(null);

	useEffect(() => {
		if (dataFlow) {
			const initialNodes: Node[] = dataFlow.detailFlow.spec
				.filter((item) => item.group === "nodes" && item.data.name)
				.map((item) => {
					return {
						id: item.data._id,
						type: item.data.type,
						data: {
							label: item.data.name,
							faveColor: item.data.faveColor,
							description: item.data.pointDescription || "",
							point: item.data.point || 0,
							deadline: item.data.deadline || 0,
							deadLineToDo: item.data.deadlineTodo || false,
							status: item.data.status
								? {
										name: item.data.status.label,
										color: item.data.status.color,
								  }
								: null,
							occurrenceType: item.data.occurrenceType
								? {
										id: item.data.occurrenceType._id[0],
										name: item.data.occurrenceType.name,
								  }
								: null,
							cancelInfraction: item.data.cancelInfraction || false,
							sendEmail: item.data.sendEmail || false,
							requiredAttached: item.data.requiredAttached || false,
							requiredDescription: item.data.requiredDescription || false,
							toPerfil: item.data.toPerfil
								? {
										id: item.data.toPerfil._id,
										hasChildren: item.data.toPerfil.hasChildren,
										height: item.data.toPerfil.height,
										width: item.data.toPerfil.width,
										name: item.data.toPerfil.name,
										perfilFeatures: item.data.toPerfil.perfilFeatures
											? item.data.toPerfil.perfilFeatures.map((perfil) => ({
													id: perfil.id,
													name: perfil.name,
													authority: perfil.authority,
											  }))
											: null,
								  }
								: null,
						},
						group: "nodes",
						locked: item.locked,
						removed: item.removed,
						selectable: item.selectable,
						selected: item.selected,
						position: {
							x: Number(item.position.x),
							y: Number(item.position.y),
						},
					};
				});
			const initialEdges: Edge[] = dataFlow.detailFlow.spec
				.filter((item) => item.group === "edges")
				.map((item) => {
					return {
						id: item.data._id,
						source: item.data.source,
						target: item.data.target,
						label: item.data.name,
						type: "straight",
						markerEnd: {
							type: MarkerType.ArrowClosed,
							height: 20,
							width: 20,
						},
					};
				});
			setNodes(initialNodes);
			setEdges(initialEdges);
		}
	}, [dataFlow]);

	return (
		<FormProvider{...methods}>
			<ModalLayout
				open={open}
				handleClose={handleClose}
				title={title}
				isNotShowButtonSubmit
			>
				<Typography>Fluxo associado: {description}</Typography>
				<Box ref={reactFlowWrapper} height="500px" flex={1}>
					<ReactFlow
						nodes={nodes}
						edges={edges}
						nodeTypes={nodeTypes}
						edgeTypes={edgeTypes}
						fitView
					>
						<BackgroundFlow variants={"cross"} color="#ccc" />
					</ReactFlow>
				</Box>
			</ModalLayout>
		</FormProvider>
	);
}
