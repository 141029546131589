import { gql } from "@apollo/client";

export const UPDATE_FLOW = gql`
	mutation (
		$id: Int!
		$name: String!
		$description: String
		$status: String!
		$spec: [UpdateSpecInput]!
		$spec_start_official_protocol: String
		$auto_finish_on_complaint_refused: Boolean
	) {
		updateFlow(
			id: $id
			name: $name
			description: $description
			spec: $spec
			status: $status
			spec_start_official_protocol: $spec_start_official_protocol
			auto_finish_on_complaint_refused: $auto_finish_on_complaint_refused
		)
	}
`;
