import * as React from "react";
import { useFormContext } from "react-hook-form";
import {
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	FormLabel,
} from "@mui/material";

type RadioButtonProps = {
	label: string;
	name: string;
	radios: Array<{
		label: string;
		value: string;
	}>;
	row?: boolean;
	onClick?: (event: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void;
};

export function RadioButton({
	label,
	name,
	radios,
	row = true,
	onClick,
}: RadioButtonProps) {
	const { register, watch } = useFormContext();
	const watchAllFields = watch();

	return (
		<FormControl>
			<FormLabel id={label}>{label}</FormLabel>
			<RadioGroup row={row} aria-labelledby={label} name={label}>
				{radios.map((item) => (
					<FormControlLabel
						value={item.value}
						id={item.value}
						checked={watchAllFields[name] === item.value}
						control={<Radio />}
						label={item.label}
						key={item.value}
						{...register(name)}
						onClick={(event) => onClick && onClick(event)}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
}
