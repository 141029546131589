/* eslint-disable no-mixed-spaces-and-tabs */
import { Box, Stack, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import componentsDinamycForm from "utils/fieldsDynamicForm";
import React, { Fragment } from "react";
import { formData } from ".";
import Button from "components/Button";

interface StepDynamicFormProps {
	goToNextStep: () => void;
	goToPrevStep: () => void;
}

export function StepDynamicForm({
	goToNextStep,
	goToPrevStep,
}: StepDynamicFormProps) {
	const {
		getValues,
		handleSubmit,
		formState: { errors },
	} = useFormContext();
	const dynamicForm: formData["dynamicForm"] = getValues("dynamicForm");
	const onSubmit = async () => {
		await handleSubmit(goToNextStep)();
		if (errors.files && !errors.groups) {
			return goToNextStep();
		}
	};
	return (
		<>
			<Stack spacing={3} flex={1} marginTop={2}>
				{!dynamicForm && (
					<Typography variant="h6" fontSize="1rem">
						Não há formulário dinâmico para este assunto
					</Typography>
				)}
				{dynamicForm &&
					dynamicForm.groups.map((group, index) => (
						<Fragment key={group.title}>
							<Typography variant="h6" fontSize="1rem">
								{group.title}
							</Typography>
							{group.inputs.map((input, key) => {
								const component = componentsDinamycForm.find(
									(value) => value.type === input.type
								);
								return (
									<Fragment key={key}>
										{component && (
											<Fragment>
												{
													<component.component
														required={input.required}
														label={input.label}
														placeholder={input.label}
														name={input.name}
														options={
															input.options &&
															input.options.map((option) => ({
																label: option.name,
																value: option.name,
															}))
														}
														index={index}
														groupTitle={group.title}
													/>
												}
											</Fragment>
										)}
									</Fragment>
								);
							})}
						</Fragment>
					))}
			</Stack>
			<Box sx={{ my: 2 }}>
				<Box>
					<Button
						variant="contained"
						onClick={onSubmit}
						sx={{ mt: 1, mr: 1 }}
						disabled={errors.groups && true}
					>
						Próximo
					</Button>
					<Button onClick={goToPrevStep} sx={{ mt: 1, mr: 1 }}>
						Voltar
					</Button>
				</Box>
			</Box>
		</>
	);
}
