import { Header } from "components/Header";
import { useQuery } from "@apollo/client";
import { Box, Container } from "@mui/material";

import { FETCH_DETAIL_COMPANY } from "graphql/queries/fetchDetailCompany";
import { FetchDetailCompany } from "types/graphql/queries/fetchDetailCompany";
import { DefaultReports } from "./DefaultReports";
import { CustomReports } from "./CustomReports";

export function Reports() {
	const subdomain = window.location.hostname.split(".")[0];

	const { data: dataCompany } = useQuery<FetchDetailCompany>(
		FETCH_DETAIL_COMPANY,
		{
			variables: {
				domain:
					subdomain === "localhost"
						? process.env.REACT_DEFAULT_DOMAIN || "lagoasanta"
						: subdomain,
			},
		}
	);

	const hasModuleDynamicReport = dataCompany?.detailCompany.modules.find(
		(item) => item.code === "DYNAMIC_REPORT"
	);

	return (
		<>
			<Box
				width="100%"
				display="flex"
				alignSelf="stretch"
				flexDirection="column"
				sx={{
					backgroundColor: "#f3f3f3",
				}}
				paddingBottom="1rem"
			>
				<Header title="Relatórios" />

				<Container maxWidth="md">
					<Box
						sx={{
							backgroundImage: `url(${dataCompany && dataCompany.detailCompany.logo
								})`,
							backgroundSize: "contain",
							backgroundPosition: "center",
							backgroundRepeat: "no-repeat",
						}}
						minHeight="70px"
						marginTop={2}
						marginBottom={3}
					/>
					{hasModuleDynamicReport ? <CustomReports /> : <DefaultReports />}
				</Container>
			</Box>
		</>
	);
}
