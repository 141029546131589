import { useState } from "react";
import { useMutation } from "@apollo/client";
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Stack,
	TextField,
} from "@mui/material";
import { UPDATE_HOLIDAY } from "graphql/mutations/updateHoliday";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs//locale/pt-br";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useForm, FormProvider } from "react-hook-form";
import { useSnackbar } from "notistack";
import Input from "components/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

type EditDialogProps = {
	open: boolean;
	handleClose: () => void;
	data: any;
	onEdit: () => void;
};

const schema = yup.object().shape({
	name: yup.string().required("Campo obrigatório"),
});

export default function EditDialog({
	open,
	handleClose,
	data,
	onEdit,
}: EditDialogProps) {
	const methods = useForm({
		defaultValues: {
			name: data.name,
		},
		resolver: yupResolver(schema),
	});

	const [dateYear, setDateYear] = useState(null);
	const [dateDay, setDateDay] = useState(null);

	const { enqueueSnackbar } = useSnackbar();

	// Setting mutations
	const [updateHoliday, { loading }] = useMutation(UPDATE_HOLIDAY);

	const { reset, handleSubmit } = methods;

	const onSubmit = async (values: any) => {
		try {
			await updateHoliday({
				variables: {
					id: data.id,
					name: values.name,
					day: dateDay.get("date"),
					month: dateDay.get("month") + 1,
					year: dateYear?.get("year") || null,
				},
			});
			onEdit();
			reset();
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="paper"
				maxWidth="sm"
				fullWidth={true}
			>
				<FormProvider {...methods}>
					<DialogTitle id="scroll-dialog-title">Editar Feriado</DialogTitle>

					<DialogContent>
						<Stack spacing={2} marginTop={2}>
							<Input name="name" autoFocus placeholder="Nome*" type="text" />
							<LocalizationProvider
								dateAdapter={AdapterDayjs}
								adapterLocale="pt-br"
							>
								<DatePicker
									inputFormat="DD / MM"
									label="Dia/Mês*"
									value={dateDay}
									onChange={(newValue) => {
										setDateDay(newValue);
									}}
									renderInput={(params) => (
										<TextField {...params} sx={{ width: "100%" }} />
									)}
								/>
								<DatePicker
									views={["year"]}
									label="Ano"
									value={dateYear}
									onChange={(newValue) => {
										setDateYear(newValue);
									}}
									renderInput={(params) => (
										<TextField {...params} sx={{ width: "100%" }} />
									)}
								/>
							</LocalizationProvider>
						</Stack>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Cancelar
						</Button>
						<Button
							onClick={handleSubmit(onSubmit)}
							variant="contained"
							color="primary"
							disabled={loading}
						>
							{loading && (
								<CircularProgress size={14} style={{ marginRight: 12 }} />
							)}
							Editar
						</Button>
					</DialogActions>
				</FormProvider>
			</Dialog>
		</>
	);
}
