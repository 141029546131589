import { gql } from "@apollo/client";

export const FETCH_STATUS = gql`
	query ($keyword: String, $order_by: String, $direction: String, $page: Int, $limit: Int) {
		fetchStatus(keyword: $keyword, order_by: $order_by, direction: $direction, page: $page, limit: $limit) {
			total
			current_page
			per_page
			last_page
			data {
				id
				company_id
				name
				theme
				created_at
			}
		}
	}
`;
