import { useMutation } from "@apollo/client";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Stack,
	Collapse,
	OutlinedInput,
} from "@mui/material";
import { CREATE_STATUS } from "graphql/mutations/createStatus";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import Input from "components/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import IconButton from "components/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ColorPicker } from "components/ColorPicker";
import LoadingButton from "components/LoadingButton";

type CreateStatusDialogProps = {
	open: boolean;
	handleClose: () => void;
	onCreate: () => void;
};

const schema = yup.object().shape({
	name: yup.string().required("Campo obrigatório"),
	theme: yup.string().required("Campo obrigatório"),
});

export default function CreateStatusDialog({
	open,
	handleClose,
	onCreate,
}: CreateStatusDialogProps) {
	const [expanded, setExpanded] = useState(false);

	const methods = useForm({
		defaultValues: {
			name: "",
			theme: "",
		},
		resolver: yupResolver(schema),
	});
	const { enqueueSnackbar } = useSnackbar();

	// Setting mutation
	const [createStatus, { loading }] = useMutation(CREATE_STATUS);

	const { reset, handleSubmit, control, setValue, watch } = methods;

	const onSubmit = async (values: any) => {
		try {
			await createStatus({
				variables: {
					name: values.name,
					theme: theme.toUpperCase(),
				},
			});
			onCreate();
			reset({
				name: "",
				theme: "",
			});
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
	};

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};
	const theme = watch("theme");
	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="paper"
				maxWidth="sm"
				fullWidth={true}
			>
				<FormProvider {...methods}>
					<DialogTitle id="scroll-dialog-title">Criar status</DialogTitle>

					<DialogContent>
						<Stack spacing={2} marginTop={2}>
							<Input name="name" autoFocus placeholder="Nome*" type="name" />
							<Controller
								name="theme"
								control={control}
								rules={{ required: true }}
								render={() => (
									<OutlinedInput
										placeholder="Cor*"
										type="text"
										value={theme}
										readOnly
										sx={{
											color: theme,
										}}
										endAdornment={
											<IconButton onClick={() => handleExpandClick()}>
												{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
											</IconButton>
										}
									/>
								)}
							/>
							<Collapse in={expanded} timeout="auto" unmountOnExit>
								<ColorPicker
									handleColor={(color) => setValue("theme", color)}
								/>
							</Collapse>
						</Stack>
					</DialogContent>

					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Cancelar
						</Button>
						<LoadingButton
							variant="contained"
							loading={loading}
							onClick={handleSubmit(onSubmit)}
						>
							Criar status
						</LoadingButton>
					</DialogActions>
				</FormProvider>
			</Dialog>
		</>
	);
}
