import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
	Box,
	Container,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	TableBody,
	Table,
	TableSortLabel,
	TextField,
} from "@mui/material";
import { Item } from "components/Paper";
import { Header } from "components/Header";
import { EmptyMessage } from "components/EmptyMessage";
import noUser from "assets/img/noUser.png";
import { FETCH_POINTS_BY_MONTH } from "graphql/queries/fetchPointsByMonth";
import { FetchPointsByMonth } from "types/graphql/queries/fetchPointsByMonth";
import useDebounce from "hooks/useDebounce";
import { CircularProgress } from "components/CircularProgress";
import { ExportCSV } from "./ExportCSV";
import { Filter } from "./Filters";

export function ReportPointsByMonth() {
	const [orderBy, setOrderBy] = useState("users.name");
	const [direction, setDirection] = useState<"asc" | "desc">("asc");
	const [keyword, setKeyword] = useState("");
	const [month, setMonth] = useState(new Date().getMonth() + 1);
	const [year, setYear] = useState(new Date().getFullYear());
	const [responsibles, setResponsibles] = useState(null);

	const debouncedValue = useDebounce(keyword, 1000);

	const { data, loading, refetch } = useQuery<FetchPointsByMonth>(
		FETCH_POINTS_BY_MONTH,
		{
			variables: {
				year: year,
				month: month,
				order_by: orderBy,
				direction: direction,
				keyword: keyword,
				responsibles:
					responsibles && responsibles.length > 0
						? responsibles.map((item) => item.id)
						: null,
			},
		}
	);

	function handleSort(name) {
		const isAsc = orderBy === name && direction === "asc";
		setDirection(isAsc ? "desc" : "asc");
		setOrderBy(name);
	}

	function handleFilter(values) {
		setMonth(values.month ? Number(values.month) : new Date().getMonth() + 1);
		setYear(values.year ? Number(values.year) : new Date().getFullYear());
		setResponsibles(values.responsibles);
		setKeyword(values.keyword);
	}
	return (
		<Box
			width="100%"
			display="flex"
			alignSelf="stretch"
			flexDirection="column"
			sx={{
				backgroundColor: "#f3f3f3",
			}}
			paddingBottom="1rem"
		>
			<Header title="Fechamento de pontuação" isReturn />

			<Container maxWidth="lg" sx={{ marginTop: "1rem" }}>
				<Item
					sx={{
						paddingX: 0,
						paddingY: "1px",
					}}
				>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						paddingX="1rem"
					>
						<Filter
							handleFilter={(values) => handleFilter(values)}
							keyword={keyword}
						/>
						<ExportCSV
							deboucedValue={debouncedValue}
							order_by={orderBy}
							direction={direction}
							month={month}
							year={year}
							responsibles={responsibles}
						/>
					</Box>
					<>
						{loading ? (
							<Box paddingY={4}>
								<CircularProgress />
							</Box>
						) : (
							<>
								{data && data.fetchPointsByMonth ? (
									<TableContainer style={{ marginTop: "10px" }}>
										{data.fetchPointsByMonth.length < 1 && (
											<>
												<EmptyMessage message="Nenhum item para listar" />
												<Box
													marginTop={2}
													sx={{
														backgroundImage: `url(${noUser})`,
														backgroundRepeat: "no-repeat",
														backgroundPosition: "center",
														backgroundSize: "contain",
														height: "200px",
													}}
												/>
											</>
										)}

										{data.fetchPointsByMonth.length > 0 && (
											<Table>
												<TableHead>
													<TableRow>
														<TableCell>Matrícula</TableCell>
														<TableCell>
															<TableSortLabel
																active={orderBy === "users.name"}
																direction={
																	orderBy === "users.name" ? direction : "asc"
																}
																onClick={() => handleSort("users.name")}
															>
																Colaborador
															</TableSortLabel>
														</TableCell>
														<TableCell>Mês</TableCell>
														<TableCell>Ano</TableCell>
														<TableCell>
															<TableSortLabel
																active={orderBy === "points"}
																direction={
																	orderBy === "points" ? direction : "asc"
																}
																onClick={() => handleSort("points")}
															>
																Total de pontos
															</TableSortLabel>
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{data.fetchPointsByMonth.map((row, index) => (
														<>
															<TableRow key={index}>
																<TableCell>
																	{row.user.params
																		? JSON.parse(row.user.params)?.matricula
																		: "-"}
																</TableCell>
																<TableCell>{row.user.name}</TableCell>
																<TableCell>
																	{row.year_month
																		? row.year_month.split("-")[1]
																		: " - "}
																</TableCell>
																<TableCell>
																	{row.year_month
																		? row.year_month.split("-")[0]
																		: " - "}
																</TableCell>
																<TableCell>{row.accumulated}</TableCell>
															</TableRow>
														</>
													))}
												</TableBody>
											</Table>
										)}
									</TableContainer>
								) : (
									<Box marginTop={2}>
										<EmptyMessage message="Nenhum item para listar" />
									</Box>
								)}
							</>
						)}
					</>
				</Item>
			</Container>
		</Box>
	);
}
