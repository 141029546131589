import { styled } from "@mui/material/styles";
import { Paper as PaperMaterial, PaperProps } from "@mui/material";
import { memo } from "react";

interface ItemProps extends PaperProps {
	children: React.ReactNode;
}

const Paper = styled(PaperMaterial)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(2),
	textAlign: "left",
	color: theme.palette.text.secondary,
}));

function ItemBase({ children, ...rest }: ItemProps) {
	return <Paper {...rest}>{children}</Paper>;
}

export const Item = memo(ItemBase);
