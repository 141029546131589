import Condition from "components/Flow/CustomNodes/Condition";
import Email from "components/Flow/CustomNodes/Email";
import End from "components/Flow/CustomNodes/End";
import Occurrence from "components/Flow/CustomNodes/Occurrence";
import Pontuation from "components/Flow/CustomNodes/Pontuation";
import Start from "components/Flow/CustomNodes/Start";
import Tasks from "components/Flow/CustomNodes/Tasks";

export const nodeTypes = {
	START: Start,
	TASK: Tasks,
	EMAIL: Email,
	OCCURRENCE: Occurrence,
	CONDITION: Condition,
	PONTUATE: Pontuation,
	END: End,
};
