import { Box, BoxProps, Typography } from "@mui/material";

type WrapperInfo = BoxProps & {
	title: string;
	value: string | number;
	color?: string;
};
export function WrapperInfo({
	title,
	value,
	color = "#000000",
	...rest
}: WrapperInfo) {
	return (
		<Box {...rest} mr={[2, 0]}>
			<Typography variant="caption" color={color}>
				{title}
			</Typography>
			<Typography variant="h5" color={color}>
				{value}
			</Typography>
		</Box>
	);
}
