import { gql } from "@apollo/client";

export const CREATE_FLOW = gql`
	mutation (
		$name: String!
		$description: String
		$spec: [CreateSpecInput]!
		$spec_start_official_protocol: String
		$auto_finish_on_complaint_refused: Boolean
	) {
		createFlow(
			name: $name
			description: $description
			spec: $spec
			spec_start_official_protocol: $spec_start_official_protocol
			auto_finish_on_complaint_refused: $auto_finish_on_complaint_refused
		)
	}
`;
