import { gql } from "@apollo/client";

export const FETCH_DETAIL_COMPANY = gql`
	query ($domain: String) {
		detailCompany(domain: $domain) {
			logo
			cover
			modules {
				id
				name
				code
			}
		}
	}
`;
