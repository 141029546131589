import {
	Box,
	FormControl,
	FormHelperText,
	Grid,
	TextField,
} from "@mui/material";
import { Checkbox } from "components/Checkbox";
import IconButton from "components/IconButton";
import { useFormContext, Controller } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { ModalSearchRealty } from "../Modal/ModalSearchRealty";
import { useLazyQuery, useQuery } from "@apollo/client";
import { FetchProtocols } from "types/graphql/queries/fetchProtocols";
import { FetchSubjects } from "types/graphql/queries/fetchSubjects";
import { FETCH_PROTOCOLS } from "graphql/queries/fetchProtocols";
import { FETCH_SUBJECTS } from "graphql/queries/fetchSubjects";
import { format, sub } from "date-fns";
import { DescriptionAlert } from "components/Warning";
import { useParams } from "react-router-dom";
import { DetailProtocol } from "types/graphql/queries/detailProtocol";
import { DETAIL_PROTOCOL } from "graphql/queries/detailProtocol";

interface FieldPropertyDetailsProps {
	name: string;
	index?: number;
	readonly?: boolean;
	groupTitle?: string;
}

function FieldLocation({ name, index, readonly }: FieldPropertyDetailsProps) {
	const [isOpenModalSearch, setIsOpenModalSearch] = useState(false);
	const { control, setValue, getValues } = useFormContext();
	const [dataProtocol, setDataProtocol] = useState<any>(null);

	const [location, setLocation] = useState<any>({});

	const { code } = useParams<{ code: string }>();
	const { data } = useQuery<DetailProtocol>(DETAIL_PROTOCOL, {
		fetchPolicy: "network-only",
		variables: {
			code: code,
		},
	});

	const subjectId = data?.detailProtocol?.subject.id;

	const [fetchProtocols] = useLazyQuery<FetchProtocols>(FETCH_PROTOCOLS);

	const { data: dataSubjects } = useQuery<FetchSubjects>(FETCH_SUBJECTS, {
		variables: {
			limit: 1000,
		},
	});

	const handleSelectRealty = async (realty: string) => {
		const parsedRealty = JSON.parse(realty);
		const name = getValues("groups.0.fields.owner.name");
		const document = getValues("groups.0.fields.owner.document");
		setLocation(parsedRealty);

		if (parsedRealty) {
			const {
				INDICECADASTRAL,
				LOTEAMLOTE,
				LOTEAMQUADRA,
				LOGRADOURO,
				NUMERO,
				NOMEBAIRRO,
				COMPLEMENTO,
				AREATOTAL,
				CODIGO,
				NOME,
				CGCCPF,
				TIPOLOGRAD,
				AREATERRENO,
			} = parsedRealty;
			setValue(
				`groups.${index}.fields.location.municipalRegistration`,
				INDICECADASTRAL
			);
			setValue(`groups.${index}.fields.location.lote`, LOTEAMLOTE);
			setValue(`groups.${index}.fields.location.quadra`, LOTEAMQUADRA);
			setValue(
				`groups.${index}.fields.location.street`,
				LOGRADOURO ? `${TIPOLOGRAD ?? " "} ${LOGRADOURO}` : ""
			);
			setValue(`groups.${index}.fields.location.number`, NUMERO);
			setValue(`groups.${index}.fields.location.neighborhood`, NOMEBAIRRO);
			setValue(`groups.${index}.fields.location.complement`, COMPLEMENTO);
			setValue(`groups.${index}.fields.location.areatotal`, AREATOTAL);
			setValue(`groups.${index}.fields.location.areaterreno`, AREATERRENO);
			setValue(`groups.${index}.fields.location.codigo`, CODIGO);
			if (!name && !document) {
				setValue("groups.0.fields.owner.name", NOME ?? "");
				setValue("groups.0.fields.owner.document", CGCCPF ?? "");
			}
			const actualDate: Date = new Date();
			const startDate = sub(actualDate, { months: 6 });

			const { data } = await fetchProtocols({
				fetchPolicy: "network-only",
				variables: {
					start: format(startDate, "yyyy-MM-dd"),
					finish: format(actualDate, "yyyy-MM-dd"),
					subject_ids: [subjectId],
					municipal_registration: parsedRealty.INDICECADASTRAL,
				},
			});

			setDataProtocol(data.fetchProtocols);
		}
	};

	useEffect(() => {
		const formLocation = getValues("location");
		if (formLocation) {
			setLocation(formLocation);
		}
	}, []);

	return (
		<>
			{dataProtocol && dataProtocol.total > 0 && dataSubjects && (
				<DescriptionAlert
					zipcode={false}
					info={location.INDICECADASTRAL}
					protocols={dataProtocol.data}
					numberProtocols={dataProtocol.total}
					subjectName={
						dataSubjects.fetchSubjects.data.find(
							(item) => item.id === subjectId
						)?.name || null
					}
				/>
			)}
			<Grid item xs={12}>
				<Controller
					name={`groups.${index}.fields.location.municipalRegistration`}
					control={control}
					render={(inputProps) => (
						<FormControl variant="outlined" fullWidth>
							<TextField
								id={name}
								type={"text"}
								label="Indíce cadastral"
								fullWidth
								value={inputProps.field.value ?? ""}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
								InputProps={{
									endAdornment: (
										<IconButton onClick={() => setIsOpenModalSearch(true)}>
											<SearchIcon />
										</IconButton>
									),
								}}
							/>
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Controller
					name={`groups.${index}.fields.location.lote`}
					control={control}
					render={(inputProps) => (
						<FormControl variant="outlined" fullWidth>
							<TextField
								id={name}
								type={"text"}
								label="Lote"
								fullWidth
								value={inputProps.field.value ?? ""}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Controller
					name={`groups.${index}.fields.location.quadra`}
					control={control}
					render={(inputProps) => (
						<FormControl variant="outlined" fullWidth>
							<TextField
								id={name}
								type={"text"}
								label="Quadra"
								fullWidth
								value={inputProps.field.value ?? ""}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Controller
					control={control}
					name={`groups.${index}.fields.location.isSameAddress`}
					render={(inputProps) => (
						<Checkbox
							readOnly={readonly}
							label="Mesmo endereço do infrator/requerente"
							onChange={(e) => inputProps.field.onChange(e.target.checked)}
							checked={inputProps.field.value}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Controller
					name={`groups.${index}.fields.location.street`}
					control={control}
					render={(inputProps) => (
						<FormControl variant="outlined" fullWidth>
							<TextField
								id={name}
								type={"text"}
								label="Rua"
								fullWidth
								value={`${inputProps.field.value ?? ""}`}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Controller
					name={`groups.${index}.fields.location.number`}
					control={control}
					rules={{
						pattern: {
							value: /^[0-9]+$/,
							message: "Digite apenas números",
						},
					}}
					render={(inputProps) => (
						<FormControl
							variant="outlined"
							fullWidth
							error={inputProps.fieldState.error && true}
						>
							<TextField
								id={name}
								type={"text"}
								label="Número"
								fullWidth
								value={inputProps.field.value ?? ""}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>

							{inputProps.fieldState.error && (
								<FormHelperText error={inputProps.fieldState.error && true}>
									{inputProps.fieldState.error.message + ""}
								</FormHelperText>
							)}
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Controller
					name={`groups.${index}.fields.location.neighborhood`}
					control={control}
					render={(inputProps) => (
						<FormControl variant="outlined" fullWidth>
							<TextField
								id={name}
								type={"text"}
								label="Bairro"
								fullWidth
								value={inputProps.field.value ?? ""}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Controller
					name={`groups.${index}.fields.location.complement`}
					control={control}
					render={(inputProps) => (
						<FormControl variant="outlined" fullWidth>
							<TextField
								id={name}
								type={"text"}
								label="Complemento"
								fullWidth
								value={inputProps.field.value ?? ""}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Controller
					name={`groups.${index}.fields.location.areatotal`}
					control={control}
					render={(inputProps) => (
						<FormControl variant="outlined" fullWidth>
							<TextField
								id={name}
								type={"text"}
								label="Área total"
								fullWidth
								value={inputProps.field.value ?? ""}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Controller
					name={`groups.${index}.fields.location.areaterreno`}
					control={control}
					render={(inputProps) => (
						<FormControl variant="outlined" fullWidth>
							<TextField
								id={name}
								type={"text"}
								label="Área terreno"
								fullWidth
								value={inputProps.field.value ?? ""}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={12} marginBottom={2}>
				<Controller
					name={`groups.${index}.fields.location.codigo`}
					control={control}
					render={(inputProps) => (
						<FormControl variant="outlined" fullWidth>
							<TextField
								id={name}
								type={"text"}
								label="Ponto de referência"
								fullWidth
								value={inputProps.field.value ?? ""}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>
						</FormControl>
					)}
				/>
			</Grid>
			{isOpenModalSearch && (
				<ModalSearchRealty
					open={isOpenModalSearch}
					handleClose={() => setIsOpenModalSearch(false)}
					handleSelect={(value) => handleSelectRealty(value)}
				/>
			)}
		</>
	);
}

export default FieldLocation;
