import {
	Box,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	Typography,
} from "@mui/material";
import Button from "components/Button";

interface StepsProps {
	steps: Array<{
		label: string;
		description?: string;
		component: ({
			goToNextStep,
			goToPrevStep,
			handleClose,
			resetSteps,
			refetch,
		}: {
			goToNextStep: () => void;
			goToPrevStep: () => void;
			handleClose?: () => void;
			resetSteps?: () => void;
			refetch?: () => void;
		}) => JSX.Element;
	}>;
	currentStep: number;
	goToNextStep: () => void;
	goToPrevStep: () => void;
	handleClose?: () => void;
	resetSteps?: () => void;
	refetch?: () => void;
	isCreateProtocol?: boolean;
	disabledButtons?: boolean;
	disabledNextButton?: boolean;
}

export function Steps({
	steps,
	currentStep,
	goToNextStep,
	goToPrevStep,
	handleClose,
	resetSteps,
	refetch,
	isCreateProtocol,
	disabledButtons,
	disabledNextButton,
}: StepsProps) {
	const isDisabledButtons = isCreateProtocol || disabledButtons;
	return (
		<Stepper activeStep={currentStep} orientation="vertical">
			{steps.map((step, index) => (
				<Step key={step.label}>
					<StepLabel
						sx={{
							"& .MuiStepLabel-label": {
								display: "flex",
								flexDirection: "column",
							},
						}}
					>
						{step.label}
						<Typography variant="caption">{step.description}</Typography>
					</StepLabel>

					<StepContent>
						<Box>
							<step.component
								goToNextStep={goToNextStep}
								goToPrevStep={goToPrevStep}
								handleClose={handleClose}
								resetSteps={resetSteps}
								refetch={refetch}
							/>
						</Box>
						{!isDisabledButtons && (
							<Box sx={{ mb: 2 }}>
								<Box>
									{index < steps.length - 1 && (
										<Button
											variant="contained"
											onClick={goToNextStep}
											sx={{ mt: 1, mr: 1 }}
											disabled={disabledNextButton}
										>
											Próximo
										</Button>
									)}
									<Button
										disabled={index === 0}
										onClick={goToPrevStep}
										sx={{ mt: 1, mr: 1 }}
									>
										Voltar
									</Button>
								</Box>
							</Box>
						)}
					</StepContent>
				</Step>
			))}
		</Stepper>
	);
}
