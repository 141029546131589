/* eslint-disable no-mixed-spaces-and-tabs */
import { useMutation, useQuery } from "@apollo/client";
import {
	Box,
	Container,
	FormControl,
	FormControlLabel,
	Grid,
	Paper,
	Radio,
	RadioGroup,
	Stack,
	TextField,
	Typography,
	useMediaQuery
} from "@mui/material";
import { CircularProgress } from "components/CircularProgress";
import { Header } from "components/Header";
import { DETAIL_DYNAMIC_REPORT } from "graphql/queries/detailDynamicReport";
import {
	DetailDynamicReport,
	FetchDetailDynamicReport,
} from "types/graphql/queries/detailDynamicReport";
import { useParams } from "react-router-dom";
import { visualizationRules } from "../Form/AnalyticalReport/consts";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import { Fragment, useState } from "react";
import SpeedDialComponent from "components/SpeedDial";
import { ModalSharePerfis } from "../ModalSharePerfis";
import { UPDATE_DYNAMIC_REPORT_PROFILES } from "types/graphql/queries/updateDynamicReportProfiles";
import { useSnackbar } from "notistack";
import { Item } from "components/Paper";
import { graphicsComponents } from "../consts";
import { ModalEditSinteticReport } from "./Modal/ModalEditSinteticReport";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useDebounce from "hooks/useDebounce";

export function DetailSinteticReport() {
	const [year, setYear] = useState(new Date().getFullYear());
	const isSmallScreen = useMediaQuery("(max-width: 1024px)");
	const media768 = useMediaQuery("(max-width:768px)");
	const media600 = useMediaQuery("(max-width:600px)");
	const media425 = useMediaQuery("(max-width:425px)");
	const { enqueueSnackbar } = useSnackbar();
	const { id } = useParams();
	const yearDebounce = useDebounce(String(year), 800);

	const { data, loading, refetch } = useQuery<FetchDetailDynamicReport>(
		DETAIL_DYNAMIC_REPORT,
		{
			fetchPolicy: "network-only",
			variables: {
				id: parseInt(id),
				year: yearDebounce,
			},
		}
	);
	

	const handleChangeYear = (newValue: Date) => {
		setYear(new Date(newValue).getFullYear());
	};

	const [updateDynamicReportProfiles, { loading: loadingUpdate }] = useMutation(
		UPDATE_DYNAMIC_REPORT_PROFILES
	);
	const [isOpenModalSharePerfis, setIsOpenModalSharePerfis] = useState(false);
	const [dynamicReportToEdit, setDynamicReportToEdit] =
		useState<DetailDynamicReport | null>(null);
	const [isOpenModalEditDynamicReport, setIsOpenModalEditDynamicReport] =
		useState(false);
	const handleClickToEditDynamicReport = (item: DetailDynamicReport) => {
		setDynamicReportToEdit(item);
		setIsOpenModalEditDynamicReport(true);
	};

	const handleCloseModalEditDynamicReport = () => {
		setDynamicReportToEdit(null);
		setIsOpenModalEditDynamicReport(false);
	};

	const handleUpdatePerfis = async (
		perfis: Array<{ id: number; name: string }>
	) => {
		try {
			await updateDynamicReportProfiles({
				variables: {
					id: parseInt(id),
					profile_ids: perfis.map((item) => item.id),
				},
			});
			enqueueSnackbar("Perfis atualizados com sucesso", { variant: "success" });
			refetch();
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
		setIsOpenModalSharePerfis(false);
	};

	const parsedSpec = data && JSON.parse(data?.detailDynamicReport.spec);

	const visualizationRule =
		data &&
		visualizationRules.find(
			(item) => item.value === data.detailDynamicReport.visualization_rule
		);

	const actionsButton =
		visualizationRule?.value === "Perfis"
			? [
					visualizationRule?.value === "Perfis" && {
						icon: <ShareIcon />,
						name: "Compartilhar",
						onClick: () => setIsOpenModalSharePerfis(true),
					},
					{
						icon: <EditIcon />,
						name: "Editar",
						onClick: () =>
							handleClickToEditDynamicReport(data?.detailDynamicReport),
					},
			]
			: [
					{
						icon: <EditIcon />,
						name: "Editar",
						onClick: () =>
							handleClickToEditDynamicReport(data?.detailDynamicReport),
					},
			];

	return (
		<>
			<Box
				width="100%"
				display="flex"
				alignSelf="stretch"
				flexDirection="column"
				sx={{
					backgroundColor: "#f3f3f3",
				}}
				paddingBottom="1rem"
			>
				<Header title="Relatório sintético" isReturn />
				{loading ? (
					<CircularProgress />
				) : (
					<>
						{data && parsedSpec && (
							<Container maxWidth="lg">
								<Item>
									<Box
										display="flex"
										alignItems={"center"}
										justifyContent={"center"}
									>
										<Typography
											variant="h6"
											fontWeight={500}
											component="h2"
											gutterBottom
											color="#000"
										>
											{data.detailDynamicReport.title}
										</Typography>
										<LocalizationProvider
											dateAdapter={AdapterDayjs}
											adapterLocale="pt-br"
										>
											<DatePicker
												disableFuture
												inputFormat="YYYY"
												openTo="year"
												views={["year"]}
												label="Ano"
												value={new Date(year, 0, 1)}
												onChange={(newValue) => {
													handleChangeYear(newValue);
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														sx={{
															width: "100px",
															ml: "1rem",
														}}
													/>
												)}
											/>
										</LocalizationProvider>
									</Box>
									<Grid container spacing={2}>
										{parsedSpec[0].items.length === 0 && (
											<Grid item xs={12}>
												<Typography textAlign={"center"}>
													Nenhum título selecionado
												</Typography>
											</Grid>
										)}
										{parsedSpec.map((item, index) => {
											return (
												<Grid
													container
													item
													key={index}
													columnGap={4}
													rowGap={2}
													columns={{ md: 12 }}
												>
													{item.items.map((value) => {
														if (value.type === "dataDisplay") {
															return (
																<Box
																	key={value.id}
																	sx={{
																		backgroundColor: "transparent",
																		display: "flex",
																	}}
																>
																	<Stack
																		sx={{
																			p: "12px",
																		}}
																		flexDirection={
																			value.reverse
																				? "column-reverse"
																				: "column"
																		}
																	>
																		<>
																			<Typography
																				textAlign={value.style.textAlign}
																				variant="subtitle1"
																				fontWeight={600}
																				width={"100%"}
																				color="#000"
																			>
																				{value.title === "UPFS"
																					? new Intl.NumberFormat("pt-BR", {
																							maximumSignificantDigits: 3,
																						}).format(value.data)
																					: value.data || 0}
																			</Typography>
																			<Typography
																				variant="caption"
																				textAlign={value.style.textAlign}
																				width={"100%"}
																			>
																				{value.customized ? value.subtitle :value.title}
																			</Typography>
																		</>
																	</Stack>
																</Box>
															);
														}
														if (value.type === "graphic" && value.variant !== "ssidChart") {
															if (value.data) {
																
																return (
																	<Grid
																		item
																		container
																		display={"grid"}
																		width={"100%"}
																		xs={
																			isSmallScreen
																				? 12
																				: 5.8
																		}
																		key={value.id}
																	>
																		{graphicsComponents[value.variant]({
																			chartData: value.data,
																			series: value.series,
																			title: value.title,
																			rotate: value.rotated,
																			label: value.label,
																		})}
																	</Grid>
																);
															} else {
																return (
																	<Typography
																		key={value.id}
																		textAlign={"center"}
																		flex={1}
																		py={10}
																	>
																		Não há dados para exibir
																	</Typography>
																);
															}
														}
													})}
												</Grid>
											);
										})}

										{parsedSpec[1].items.length === 0 && (
											<Grid item xs={12}>
												<Typography textAlign={"center"}>
													Nenhum gráfico selecionado
												</Typography>
											</Grid>
										)}
									</Grid>
									<Stack direction="row" justifyContent="space-between">
										<Box>
											<Box mt={2}>
												<Typography
													fontSize="16px"
													fontWeight={400}
													lineHeight={"24px"}
													letterSpacing={0.5}
												>
													Criado por{" "}
													<strong>
														{data.detailDynamicReport.created_by.name}
													</strong>{" "}
													em{" "}
													<strong>{data.detailDynamicReport.created_at}</strong>
												</Typography>
											</Box>
											{loadingUpdate ? (
												<CircularProgress />
											) : (
												<>
													{visualizationRule && (
														<Stack>
															<FormControl
																sx={{
																	mt: "6px",
																}}
															>
																<RadioGroup
																	row={true}
																	aria-labelledby={visualizationRule.label}
																	name={visualizationRule.label}
																>
																	<FormControlLabel
																		value={visualizationRule.value}
																		id={visualizationRule.value}
																		checked={true}
																		control={<Radio color="default" />}
																		label={visualizationRule.label}
																	/>
																</RadioGroup>
															</FormControl>
															{data.detailDynamicReport.profiles.length > 0 &&
																visualizationRule.value === "Perfis" && (
																	<>
																		<Typography variant="caption">
																			Perfis Selecionados:
																		</Typography>
																		<Typography>
																			{data.detailDynamicReport.profiles.map(
																				(item, index) => {
																					const isLastIndex =
																						index <
																						data.detailDynamicReport.profiles
																							.length -
																							1;
																					return (
																						<Fragment key={item.id}>
																							{item.name} {isLastIndex && ", "}
																						</Fragment>
																					);
																				}
																			)}
																		</Typography>
																	</>
																)}
														</Stack>
													)}
												</>
											)}
										</Box>
									</Stack>
								</Item>
								<Box mt={4} sx={{bgcolor: "transparent"}}>
									{parsedSpec.map((item, index) => {
										return (
											<Grid
												container
												item
												key={index}
												columnGap={0}
												rowGap={0}
												width="100%"
												columns={{ md: 12 }}
											>
												{item.items.map((value) => {
												
													if (value.type === "graphic" && value.variant === "ssidChart") {
														if (value.data) {
															return (
																<Grid
																	item
																	container
																	display={"grid"}
																	width={"100%"}
																	xs={12}
																	key={value.id}
																	sx={{
																		bgcolor: "#f3f3f3",
																	}}
																>
																	{graphicsComponents[value.variant]({
																		chartData: value.data,
																		series: value.series,
																		title: value.title,
																		rotate: value.rotated,
																		label: value.label,
																		medias: {
																			media768,
																			media600,
																			media425
																		},
																	})}
																</Grid>
															);
														} else {
															return (
																<Typography
																	key={value.id}
																	textAlign={"center"}
																	flex={1}
																	py={10}
																>
																	Não há dados para exibir
																</Typography>
															);
														}
													}
												})}
											</Grid>
										);
									})}
								</Box>
							</Container>
						)}
					</>
				)}
			</Box>
			{isOpenModalSharePerfis && (
				<ModalSharePerfis
					open={isOpenModalSharePerfis}
					handleClose={() => setIsOpenModalSharePerfis(false)}
					handleAddPerfis={(values) => handleUpdatePerfis(values)}
					defaultPerfis={data.detailDynamicReport.profiles.map((item) => ({
						id: item.id,
						name: item.name,
					}))}
				/>
			)}
			{dynamicReportToEdit && (
				<ModalEditSinteticReport
					handleClose={handleCloseModalEditDynamicReport}
					open={isOpenModalEditDynamicReport}
					report={dynamicReportToEdit}
					refetch={refetch}
				/>
			)}
			<SpeedDialComponent
				ariaLabel="Botão de edição e gerenciar compartilhamento"
				actions={actionsButton}
				hidden={!visualizationRule && visualizationRule?.value !== "Perfis"}
			/>
		</>
	);
}
