
import { Backdrop, Box, Container, Grid } from "@mui/material";
import { InfoResume } from "./InfoResume";
import { Header } from "components/Header";
import { Tasks } from "./Tasks";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { DETAIL_PROTOCOL } from "graphql/queries/detailProtocol";
import { CircularProgress } from "components/CircularProgress";
import { DetailProtocol } from "types/graphql/queries/detailProtocol";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Complaint } from "./Complaint";
import Button from "components/Button";
import { useEffect, useState } from "react";
import ChangeSubjectDialog from "components/ChangeSubjectDialog";
import { useSnackbar } from "notistack";
import { useAuthContext } from "context/AuthProvider";
import { useNavigate, useLocation } from "react-router-dom";
import { ServiceOrder } from "./ServiceOrder";
import { useVerifyNoHasRestriction } from "utils/useVerifyNoHasRestriction";

export function ProtocolDetail() {
	const { state } = useAuthContext();
	const { enqueueSnackbar } = useSnackbar();
	const [openEdit, setOpenEdit] = useState(false);
	const [showUndoTasks, setShowUndoTasks] = useState(false);
	const navigate = useNavigate();
	const { search } = useLocation();

	const { code } = useParams<{ code: string }>();
	const { data, loading, refetch } = useQuery<DetailProtocol>(DETAIL_PROTOCOL, {
		fetchPolicy: "network-only",
		variables: {
			code: code
		},
	});

	const handleEditModal = () => {
		setOpenEdit(!openEdit);
	};
	const existProtocolRestriction = state.userInfo.user.restrictions.some(
		(item) => item.name === "PROTOCOLO_TROCAR_ASSUNTO"
	);
	const filterConcluido = data?.detailProtocol?.tasks.filter(
		(task) => task.concluded_at !== null && task.type !== "Desfeita"
	);
	const multipleTaskRestriction = data && filterConcluido.length > 1;

	function handleGoBack() {
		switch (search) {
			case "?from=task":
				navigate(-1);
				break;
			case "?from=denuncias":
				navigate("/fiscalizacao/denuncias");
				break;
			case "?from=protocolos":
				navigate(-1);
				break;
			case "?from=os":
				navigate("/ordem-de-serviço");
				break;
			default:
				navigate("/protocolos");
		}
	}

	const noHasRestrictionDetail = useVerifyNoHasRestriction("PROTOCOLO_DETALHAR");

	useEffect(() => {
		if (data) {
			const tasks = data.detailProtocol?.tasks;
			const userInTasks = tasks.some(task => task.user && task.user.id === state.userInfo.user.id);
			const userIsNullAndPerfilIdMatches = tasks.some(task => !task.user && task.perfil && task.perfil.id === state.userInfo.user.perfil_id);

			if (!noHasRestrictionDetail && !userInTasks && !userIsNullAndPerfilIdMatches) {
				navigate("/protocolos");
			}
		}
	}, [data, navigate, state.userInfo.user.id, noHasRestrictionDetail]);


	return (
		<>
			<Box
				width="100%"
				display="flex"
				alignSelf="stretch"
				flexDirection="column"
				sx={{
					backgroundColor: "#f3f3f3",
				}}
			>
				<Header title="Detalhe do protocolo" isReturn onClick={handleGoBack} />

				<Container maxWidth="lg">
					{loading && (
						<Backdrop
							open={true}
							sx={{
								color: "#fff",
								zIndex: (theme) => theme.zIndex.drawer + 1,
							}}
						>
							<CircularProgress color="inherit" />
						</Backdrop>
					)}
					{data && (
						<Box sx={{ flexGrow: 1 }}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={4.3} rowGap={2}>
									<InfoResume
										protocol={data.detailProtocol}
										refetch={refetch}

									/>
								</Grid>

								<Grid item xs={12} md={7.7}>
									<Card>
										<CardContent>
											<Typography gutterBottom variant="h5" component="div">
												Assunto
											</Typography>
											<Typography gutterBottom variant="body1" component="div">
												{data.detailProtocol.subject?.name || "-"}
											</Typography>
											{data &&
												data.detailProtocol.subject &&
												!existProtocolRestriction && (
													<Button
														variant="contained"
														size="small"
														onClick={handleEditModal}
														disabled={multipleTaskRestriction}
													>
														Trocar assunto
													</Button>
												)}
										</CardContent>
									</Card>

									<Complaint
										complaint={data.detailProtocol.complaint}
										tasks={data.detailProtocol.tasks}
										refetch={refetch}
									/>
									<ServiceOrder
										serviceOrder={{
											description: data.detailProtocol.params,
											attachments: data.detailProtocol.attachments,
										}}
									/>

									<Tasks
										tasks={data.detailProtocol.tasks}
										status={data.detailProtocol.situation}
										subject={data.detailProtocol.subject}
										refetch={refetch}
										auto_finish_on_complaint_refused={
											data.detailProtocol.flow?.auto_finish_on_complaint_refused
										}
										showUndoTasks={showUndoTasks}
										setShowUndoTasks={(value) => setShowUndoTasks(value)}
									/>
								</Grid>
							</Grid>

							{openEdit && (
								<ChangeSubjectDialog
									open={openEdit}
									handleClose={handleEditModal}
									data={data.detailProtocol}
									onEdit={() => {
										enqueueSnackbar("Assunto editado com sucesso!", {
											variant: "success",
										});
										refetch();
										setOpenEdit(!openEdit);
									}}
								/>
							)}
						</Box>
					)}
				</Container>
			</Box>
		</>
	);
}
