import { Backdrop } from "@mui/material";
import { Stack } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { FETCH_DETAIL_COMPANY } from "graphql/queries/fetchDetailCompany";
import { CircularProgress } from "components/CircularProgress";
import { FetchDetailCompany } from "types/graphql/queries/fetchDetailCompany";
import { useParams } from "react-router-dom";
import { DetailPublicDocument } from "types/graphql/queries/detailPublicDocument";
import { DETAIL_PUBLIC_DOCUMENT } from "graphql/queries/detailPublicDocument";

export function ValidateDocument() {
	const subdomain = window.location.hostname.split(".")[0];
	const { data, loading: loadingDetailCompany } = useQuery<FetchDetailCompany>(
		FETCH_DETAIL_COMPANY,
		{
			variables: {
				domain:
					subdomain === "localhost"
						? process.env.REACT_DEFAULT_DOMAIN || "lagoasanta"
						: subdomain,
			},
		}
	);

	const params = useParams();
	const id = params.id;

	const { data: dataDocument, loading } = useQuery<DetailPublicDocument>(
		DETAIL_PUBLIC_DOCUMENT,
		{
			variables: {
				document_id: parseInt(id),
			},
		}
	);

	return (
		<>
			{loadingDetailCompany && (
				<Backdrop
					sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={true}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			)}
			{data && (
				<>
					<Box display={["none", "none", "block"]} height="100vh" flex={1}>
						<Box
							sx={{
								backgroundImage: `url(${data.detailCompany.cover})`,
								backgroundSize: "cover",
								backgroundPosition: "center",
								width: "100%",
								height: "100%",
							}}
						/>
					</Box>

					{loading ? (
						<Backdrop
							open={true}
							sx={{
								color: "#fff",
								zIndex: (theme) => theme.zIndex.drawer + 1,
							}}
						>
							<CircularProgress color="inherit" />
						</Backdrop>
					) : (
						<Stack
							sx={{
								display: "flex",
								flexDirection: "column",
								flex: "1",
								alignItems: "center",
								textAlign: "center",
							}}
						>
							<Stack padding="1rem" flex={1} spacing={2}>
								<Box
									textAlign="center"
									sx={{
										backgroundImage: `url(${data.detailCompany.logo})`,
										backgroundSize: "contain",
										backgroundPosition: "center",
										backgroundRepeat: "no-repeat",
										width: ["250px", "350px"],
									}}
									height="100px"
								/>
							</Stack>
							<Stack
								padding="1rem"
								sx={{ marginTop: "10px", alignItems: "center" }}
								flex={1}
								spacing={2}
								width="100%"
							>
								<Typography fontWeight={700} fontSize="1.6rem">
									Validação de documento
								</Typography>
							</Stack>

							<Stack width="100%" paddingX={4}>
								<Box
									sx={{
										marginY: "3rem",
										textAlign: "start",
										display: "flex",
										flexDirection: "column",
										justifyContent: "space-between",
									}}
								>
									{dataDocument && (
										<Typography
											variant="subtitle2"
											fontWeight={400}
											fontSize="1.4rem"
										>
											O documento{" "}
											<strong>{dataDocument.detailPublicDocument.name}</strong>{" "}
											é VÁLIDO e foi cadastrado no dia{" "}
											{dataDocument.detailPublicDocument.created_at}.
										</Typography>
									)}

									{!dataDocument && (
										<Typography
											variant="subtitle2"
											fontWeight={400}
											fontSize="1.4rem"
										>
											O documento é <strong>INVÁLIDO.</strong>
										</Typography>
									)}
								</Box>
							</Stack>
						</Stack>
					)}
				</>
			)}
		</>
	);
}
