import { useSidebarContext } from "context/SidebarProvider";
import { SidebarContent } from "./SidebarContent";
import { Drawer } from "@mui/material";

export function Sidebar() {
	const { isSidebarOpen, closeSidebar } = useSidebarContext();
	return (
		<Drawer open={isSidebarOpen} onClose={closeSidebar}>
			<SidebarContent />
		</Drawer>
	);
}
