import { useAuthContext } from "context/AuthProvider";

export function useVerifyNoHasRestriction(restriction: string) {
	const { state } = useAuthContext();
	const noHasRestriction =
		state.userInfo &&
		state.userInfo.user.restrictions.findIndex((item) => {
			return item.code === restriction;
		}) === -1;
	return noHasRestriction;
}
