import { FormControl, Grid, InputLabel, OutlinedInput } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

interface FieldDecimalInputProps {
	name: string;
	placeholder?: string;
	required?: boolean;
	index?: number;
	readonly?: boolean;
}

function FieldDecimalInput({
	name,
	placeholder,
	required,
	index,
	readonly,
}: FieldDecimalInputProps) {
	const { control } = useFormContext();
	return (
		<Grid item xs={12}>
			<Controller
				name={`groups.${index}.fields.${name}`}
				control={control}
				render={(inputProps) => (
					<FormControl
						variant="outlined"
						fullWidth
						error={inputProps.fieldState.error && true}
					>
						<InputLabel
							htmlFor={name}
							error={inputProps.fieldState.error && true}
						>
							{`${placeholder}${required ? "*" : ""}`}
						</InputLabel>
						<OutlinedInput
							readOnly={readonly}
							id={name}
							type="text"
							value={inputProps.field.value}
							onChange={(e) => inputProps.field.onChange(e.target.value)}
							label={`${placeholder}${required ? "*" : ""}`}
							fullWidth
							error={inputProps.fieldState.error && true}
						/>
					</FormControl>
				)}
				rules={{
					required: required ? "Campo obrigatório" : false,
					onChange(event) {
						const { value } = event.target;
						const regex = /[^0-9.,]/g;
						if (regex.test(value)) {
							event.target.value = value.replace(regex, "");
						}
					},
				}}
			/>
		</Grid>
	);
}

export default FieldDecimalInput;
