import { gql } from "@apollo/client";

export const COMPLETE_TASK = gql`
	mutation (
		$id: Int!
		$dynamic_form_response: String
		$answer: String
		$files: [CompleteTaskFilesResponse]
		$regulatory_act_ids: [Int]
		$description: String
		$old_task: Boolean
	) {
		completeTask(
			id: $id
			dynamic_form_response: $dynamic_form_response
			answer: $answer
			files: $files
			regulatory_act_ids: $regulatory_act_ids
			description: $description
			old_task: $old_task
		)
	}
`;

