import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
	Box,
	Fab,
	Container,
	TablePagination,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	TableBody,
	Table,
	IconButton,
	CircularProgress,
	Stack,
	Typography,
	Tooltip,
	TableSortLabel,
	TextField,
	useMediaQuery,
} from "@mui/material";
import { Item } from "components/Paper";
import { Header } from "components/Header";
import { EmptyMessage } from "components/EmptyMessage";
import noUser from "assets/img/noUser.png";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useSnackbar } from "notistack";
import { FETCH_SUBJECTS } from "graphql/queries/fetchSubjects";
import { FetchSubjects } from "types/graphql/queries/fetchSubjects";
import AddIcon from "@mui/icons-material/Add";
import DeleteDialog from "./DeleteDialog";
import EditDialog from "./EditDialog";
import CreateSubjectDialog from "./CreateSubjectDialog";
import useDebounce from "hooks/useDebounce";
import { useVerifyNoHasRestriction } from "utils/useVerifyNoHasRestriction";
import LocalProvider from "components/LocalProvider";
import PaginationStorage from "components/PaginationStorage";

export function Subjects() {
	const [showDelete, setShowDelete] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [subjectToDeleted, setSubjectToDelete] = useState(null);
	const [showCreate, setShowCreate] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [subjectSelected, setSubjectSelected] = useState(null);
	const [orderBy, setOrderBy] = useState("subjects.name");
	const [direction, setDirection] = useState<"asc" | "desc">("asc");
	const [keyword, setKeyword] = useState("");
	const [showPagination, setShowPagination] = useState(false);

	const debouncedValue = useDebounce(keyword, 1000);

	const { data, refetch, loading } = useQuery<FetchSubjects>(FETCH_SUBJECTS, {
		variables: {
			limit: rowsPerPage,
			page: currentPage + 1,
			order_by: orderBy,
			direction: direction,
			keyword: debouncedValue,
		},
	});

	function handleDeleteSubject(item) {
		setSubjectToDelete(item);
		setShowDelete(true);
	}

	const handleOpenEditModal = (Subject) => {
		setSubjectSelected(Subject);
		setOpenEdit(true);
	};

	const handleCloseEditModal = () => {
		setOpenEdit(false);
		setSubjectSelected(null);
	};

	function handleSort(name) {
		const isAsc = orderBy === name && direction === "asc";
		setDirection(isAsc ? "desc" : "asc");
		setOrderBy(name);
	}

	const noHasRestrictionCreateSubject =
		useVerifyNoHasRestriction("ASSUNTO_CRIAR");

	const media500 = useMediaQuery("(max-width:500px)");
	
	return (
		<Box
			width="100%"
			display="flex"
			alignSelf="stretch"
			flexDirection="column"
			sx={{
				backgroundColor: "#f3f3f3",
			}}
			paddingBottom="1rem"
		>
			<Header title="Assuntos" isReturn />

			{loading ? (
				<CircularProgress />
			) : (
				<Container maxWidth="lg" sx={{ marginTop: "1rem" }}>
					<div
						style={{ position: "relative", marginBottom: "60px" }}
						onMouseEnter={() => setShowPagination(true)}
						onMouseLeave={() => setShowPagination(false)}
					>
						<Item
							sx={{
								paddingX: 0,
								paddingY: "1px",
							}}
						>
							<Box sx={{ padding: 2 }}>
								<TextField
									fullWidth
									variant="standard"
									name="keyword"
									type="search"
									label="Pesquisar..."
									value={keyword}
									onChange={(event) => {
										setKeyword(event.target.value);
									}}
								/>
							</Box>

							<TableContainer style={{ marginTop: "10px" }}>
								{data?.fetchSubjects?.total === 0 && (
									<>
										<EmptyMessage message="Nenhum assunto encontrado 😥" />
										<Box
											marginTop={2}
											sx={{
												backgroundImage: `url(${noUser})`,
												backgroundRepeat: "no-repeat",
												backgroundPosition: "center",
												backgroundSize: "contain",
												height: "200px",
											}}
										/>
									</>
								)}
								{data?.fetchSubjects?.total !== 0 && (
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>
													<TableSortLabel
														active={orderBy === "name"}
														direction={orderBy === "name" ? direction : "asc"}
														onClick={() => handleSort("name")}
													>
														Nome
													</TableSortLabel>
												</TableCell>
												<TableCell>
													<TableSortLabel
														active={orderBy === "groups.name"}
														direction={
															orderBy === "groups.name" ? direction : "asc"
														}
														onClick={() => handleSort("groups.name")}
													>
														Grupo Fiscal
													</TableSortLabel>
												</TableCell>
												<TableCell>
													<TableSortLabel
														active={orderBy === "flows.name"}
														direction={
															orderBy === "flows.name" ? direction : "asc"
														}
														onClick={() => handleSort("flows.name")}
													>
														Fluxo
													</TableSortLabel>
												</TableCell>
												<TableCell>
													<TableSortLabel
														active={orderBy === "subjects.status"}
														direction={
															orderBy === "subjects.status" ? direction : "asc"
														}
														onClick={() => handleSort("subjects.status")}
													>
														Status
													</TableSortLabel>
												</TableCell>
												<TableCell>&nbsp;</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{data?.fetchSubjects?.data.map((row) => (
												<TableRow key={row.id}>
													<TableCell>{row.name}</TableCell>
													<TableCell>
														<Typography variant="body2">
															{row.groups.length > 0 ? (
																<>
																	{row.groups.length === 1 ? (
																		row.groups[0].name
																	) : row.groups.length > 1 ? (
																		<Tooltip
																			title={row.groups
																				.map((group) => group.name)
																				.join(", ")}
																		>
																			<span
																				style={{ cursor: "pointer" }}
																			>{`${row.groups.length} grupos`}</span>
																		</Tooltip>
																	) : null}
																</>
															) : (
																"-"
															)}
														</Typography>
													</TableCell>
													<TableCell>{row.flow.name}</TableCell>
													<TableCell>{row.status}</TableCell>
													<TableCell>
														<Stack
															spacing={1}
															direction="row"
															flex={1}
															justifyContent="flex-end"
														>
															<IconButton
																style={{ padding: 0, marginRight: 24 }}
																aria-label="edit"
																onClick={() => handleOpenEditModal(row)}
															>
																<EditIcon />
															</IconButton>
															<IconButton
																style={{ padding: 0 }}
																aria-label="delete"
																onClick={() => handleDeleteSubject(row)}
															>
																<DeleteIcon />
															</IconButton>
														</Stack>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								)}
							</TableContainer>

							<DeleteDialog
								open={showDelete}
								handleClose={() => setShowDelete(null)}
								data={subjectToDeleted}
								onDelete={() => {
									enqueueSnackbar("Assunto excluído com sucesso!", {
										variant: "success",
									});
									refetch();
									setShowDelete(false);
								}}
							/>

							{subjectSelected && (
								<EditDialog
									open={openEdit}
									handleClose={() => handleCloseEditModal()}
									data={subjectSelected}
									onEdit={() => {
										enqueueSnackbar("Assunto editado com sucesso!", {
											variant: "success",
										});
										refetch();
										setOpenEdit(false);
										setSubjectSelected(null);
									}}
								/>
							)}

							<CreateSubjectDialog
								open={showCreate}
								handleClose={() => setShowCreate(false)}
								onCreate={() => {
									enqueueSnackbar("Assunto criado com sucesso!", {
										variant: "success",
									});
									setShowCreate(false);
									refetch();
								}}
							/>

							{noHasRestrictionCreateSubject && (
								<Fab
									color="primary"
									onClick={() => setShowCreate(true)}
									style={{ position: "fixed", bottom: 48, right: 48 }}
								>
									<AddIcon />
								</Fab>
							)}
							{data?.fetchSubjects?.total !== 0 &&
								(media500 ? (
									<TablePagination
										sx={{
											background: "#fff",
										}}
										component="div"
										labelRowsPerPage="Linhas por página:"
										rowsPerPageOptions={[5, 15, 30, 60, 100]}
										count={data?.fetchSubjects?.total}
										page={currentPage}
										onPageChange={(event, newPage) => {
											setCurrentPage(newPage);
										}}
										rowsPerPage={rowsPerPage}
										onRowsPerPageChange={(event) => {
											setRowsPerPage(parseInt(event.target.value));
											setCurrentPage(0);
										}}
									/>
								) : (
									<Box
										sx={{
											position: "fixed",
											bottom: "30px",
											left: "50%",
											transform: "translateX(-50%)",
											padding: "0px",
											backgroundColor: "rgba(25, 118, 210, 0.98)",
											boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
											opacity: showPagination ? 0.95 : 0,
											zIndex: 2,
											borderRadius: "10px",
											transition: "opacity 0.3s ease-in-out",
										}}
									>
										<PaginationStorage
											count={data?.fetchSubjects?.total}
											rowsPerPage={rowsPerPage}
											page={currentPage}
											onPageChange={(event, newPage) => {
												setCurrentPage(newPage);
											}}
											onRowsPerPageChange={(event) => {
												setRowsPerPage(parseInt(event.target.value));
												setCurrentPage(0);
											}}
										/>
									</Box>
								))}
						</Item>
					</div>
				</Container>
			)}
		</Box>
	);
}
