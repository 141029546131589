import { ModalLayout } from "components/ModalLayout";
import { FetchPerfis, Profile } from "types/graphql/queries/fetchPerfis";
import { ModalLayoutProps } from "types/ModalLayout";
import { FormProvider, useForm, Controller } from "react-hook-form";
import Input from "components/Input";
import { Box, Stack, Typography } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { DELETE_PERFIL } from "graphql/mutations/deletePerfil";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { ModalConfirmationDelection } from "components/Modal/ModalConfirmationDelection";
import { FETCH_RESTRICTION } from "graphql/queries/fetchRestriction";
import {
	FetchRestrictions,
	Restriction,
} from "types/graphql/queries/fetchRestrictions";
import { CircularProgress } from "components/CircularProgress";
import { alpha, styled } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import { useEffect, useMemo } from "react";
import { UPDATE_PERFIL } from "graphql/mutations/updatePerfil";

type ModalEditRestrictionsProps = ModalLayoutProps & {
	perfil: Profile;
	refetch: () => void;
	compareGroups: {
		perfil_id: string | number;
		value: FetchPerfis["fetchPerfis"];
	}[];
};

export function ModalEditRestrictions({
	open,
	handleClose,
	perfil,
	refetch,
	compareGroups,
}: ModalEditRestrictionsProps) {
	const { enqueueSnackbar } = useSnackbar();
	const [isOpenDelete, setIsOpenDelete] = useState(false);

	const { data, loading } = useQuery<FetchRestrictions>(FETCH_RESTRICTION);
	const [updatePerfil, { loading: loadingPerfil }] = useMutation(UPDATE_PERFIL);
	const [deletePerfil, { loading: loadingDelete }] = useMutation(DELETE_PERFIL);
	const methods = useForm({
		defaultValues: {
			name: perfil.name,
			restrictions: [],
			groupByRestrictionsTitle: [],
		},
	});
	const { handleSubmit, control, setValue, getValues } = methods;

	const onSubmit = async (data) => {
		try {
			const dataFormated = {
				id: perfil.id,
				perfil_id: perfil.perfil_id,
				name: data.name,
				restriction_ids: Object.entries(data.restrictions)
					.filter(([key, value]) => {
						return value === true;
					})
					.map(([key, value]) => {
						return parseInt(key);
					}),
			};
			await updatePerfil({
				variables: dataFormated,
			});
			enqueueSnackbar("Perfil editado com sucesso", {
				variant: "success",
			});
			refetch();
			handleClose();
		} catch (err) {
			enqueueSnackbar("Ocorreu um erro ao editar o perfil", {
				variant: "error",
			});
		}
	};

	const handleDelete = () => {
		const filterSamePerfilId = compareGroups.filter(
			(item) => Number(item.perfil_id) === perfil.id
		);

		if (filterSamePerfilId.length > 0) {
			enqueueSnackbar(
				"Não é possível excluir este perfil pois existem outros perfis vinculados a ele.",
				{
					variant: "warning",
				}
			);
			return;
		}
		deletePerfil({
			variables: {
				id: perfil.id,
			},
		})
			.then(() => {
				enqueueSnackbar("Perfil deletado com sucesso", {
					variant: "success",
				});
				handleClose();
				refetch();
			})
			.catch((err) => {
				enqueueSnackbar(err.message, {
					variant: "error",
				});
			});
	};

	const handleOpenModalDelete = () => {
		setIsOpenDelete(true);
	};

	const groupByRestrictions = useMemo(() => {
		if (data) {
			return data.fetchRestrictions.reduce((acc, item) => {
				const key = item.code.split("_")[0];
				if (!acc[key]) {
					acc[key] = [];
				}
				acc[key].push(item);
				return acc;
			}, {});
		}
	}, [data]);

	const groupByRestrictionsTitle =
		groupByRestrictions &&
		Object.entries(groupByRestrictions).map(([key, value]) => {
			return {
				title: key.slice(0, 1).toUpperCase() + key.slice(1).toLowerCase(),
				restrictions: value as FetchRestrictions["fetchRestrictions"],
			};
		});

	const GreenSwitch = styled(Switch)(({ theme }) => ({
		"& .MuiSwitch-switchBase.Mui-checked": {
			color: green[600],
			"&:hover": {
				backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
			},
		},
		"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
			backgroundColor: green[600],
		},
	}));

	useEffect(() => {
		if (groupByRestrictionsTitle) {
			groupByRestrictionsTitle.forEach((item) => {
				item.restrictions.forEach((restriction) => {
					setValue(
						`restrictions.${restriction.id}`,
						perfil.restrictions.find((i) => i.id === restriction.id)
							? true
							: false
					);
				});
			});
		}
	}, [groupByRestrictionsTitle]);

	useEffect(() => {
		const restrictionValues = getValues("restrictions");
		if (groupByRestrictionsTitle && restrictionValues) {
			groupByRestrictionsTitle.forEach((item, index) => {
				const idRestrictions = item.restrictions.map((item) => item.id);

				const allValuesChecked = idRestrictions.every(
					(id) => restrictionValues[id] === true
				);
				if (allValuesChecked) {
					setValue(`groupByRestrictionsTitle.${index}`, true);
				}
			});
		}
	}, [groupByRestrictionsTitle]);

	const handleCheckedAllValuesFromGroup = (value: boolean, index: number) => {
		const findIndexGroup = groupByRestrictionsTitle[index];

		findIndexGroup.restrictions.forEach((restriction) => {
			setValue(`restrictions.${restriction.id}`, value);
		});
		setValue(`groupByRestrictionsTitle.${index}`, value);
	};

	return (
		<>
			<FormProvider {...methods}>
				<ModalLayout
					open={open}
					handleClose={handleClose}
					title="Editar perfil"
					isShowAdditionalButton
					handleSubmit={handleSubmit(onSubmit)}
					onClickAdditionalButton={handleOpenModalDelete}
					buttonTextClose="Cancelar"
					isLoading={loadingPerfil}
				>
					<Stack marginTop={2}>
						<Input name="name" placeholder="Nome*" />

						{loading && <CircularProgress />}
						<Stack spacing={2} marginTop={2}>
							<Typography variant="h6">Restrições</Typography>
							{groupByRestrictionsTitle &&
								groupByRestrictionsTitle.map((item, indexGroup) => (
									<Stack spacing={1} key={item.title}>
										<Box
											display="flex"
											justifyContent="space-between"
											alignItems="center"
										>
											<Controller
												control={control}
												name={`groupByRestrictionsTitle.${indexGroup}`}
												render={(inputProps) => (
													<>
														<Typography variant="h6" fontWeight={400}>
															{item.title}
														</Typography>
														<GreenSwitch
															checked={inputProps.field.value}
															value={inputProps.field.value}
															onChange={(event) => {
																inputProps.field.onChange(event.target.checked);
																handleCheckedAllValuesFromGroup(
																	event.target.checked,
																	indexGroup
																);
															}}
														/>
													</>
												)}
											/>
										</Box>
										{item.restrictions.map((restriction: Restriction) => (
											<Controller
												control={control}
												name={`restrictions.${restriction.id}`}
												key={restriction.id}
												render={(inputProps) => (
													<Box
														key={restriction.id}
														display="flex"
														alignItems="center"
														justifyContent="space-between"
														borderBottom="1px solid #E5E5E5"
													>
														<Typography variant="subtitle1" fontWeight={400}>
															{restriction.name}
														</Typography>
														<GreenSwitch
															onChange={(event) =>
																inputProps.field.onChange(event.target.checked)
															}
															value={inputProps.field.value}
															checked={inputProps.field.value}
															size="small"
														/>
													</Box>
												)}
											/>
										))}
									</Stack>
								))}
						</Stack>
					</Stack>
				</ModalLayout>
			</FormProvider>
			<ModalConfirmationDelection
				handleClose={() => setIsOpenDelete(false)}
				open={isOpenDelete}
				message="Deseja realmente excluir este perfil?"
				handleSubmit={handleDelete}
				isLoading={loadingDelete}
			/>
		</>
	);
}
