import { useState } from "react";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuthContext } from "context/AuthProvider";
import { Avatar, Box, Typography } from "@mui/material";
import Button from "components/Button";
import { useSnackbar } from "notistack";

export default function InfoResume() {
	const { handleLogout, state } = useAuthContext();
	const { enqueueSnackbar } = useSnackbar();

	const [open, setOpen] = useState(false);

	return (
		<>
			<Box display="flex" flexDirection="column" alignItems="center">
				<Avatar sx={{ width: 100, height: 100 }} />
				<Typography
					style={{ fontSize: "18px", marginTop: "20px", textAlign: "center" }}
					fontWeight={700}
				>
					{state.userInfo.user.name}
				</Typography>
				<Typography style={{ fontSize: "13px", marginTop: "10px" }}>
					{state.userInfo.user.perfil || "admin"}
				</Typography>
			</Box>

			{/* <Button
				variant="outlined"
				fullWidth
				onClick={() => setOpen(true)}
				style={{
					border: "none",
					marginTop: 10,
					paddingBottom: 0,
					fontSize: "12px",
					height: "40px",
				}}
			>
				<LockIcon style={{ marginRight: 6, fontSize: 16 }} />
				ALTERAR SENHA
			</Button> */}

			<Button
				variant="outlined"
				fullWidth
				onClick={() => handleLogout()}
				style={{
					border: "none",
					paddingTop: 0,
					fontSize: "12px",
					height: "40px",
				}}
			>
				<LogoutIcon style={{ marginRight: 6, fontSize: 16 }} />
				<p>LOGOUT</p>
			</Button>
		</>
	);
}
