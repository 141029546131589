/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/prop-types */
import { useQuery } from "@apollo/client";
import {
	Animation,
	ScaleObject,
	EventTracker,
	BarSeries,
} from "@devexpress/dx-react-chart";
import {
	Chart,
	LineSeries,
	AreaSeries,
	ValueAxis,
	ArgumentAxis,
	ScatterSeries,
	Tooltip,
} from "@devexpress/dx-react-chart-material-ui";
import {
	Box,
	Container,
	Grid,
	Paper,
	Stack,
	TextField,
	Typography,
	Backdrop,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CircularProgress } from "components/CircularProgress";
import { Header } from "components/Header";
import { FETCH_BATCH_CLEANING_REPORT } from "graphql/queries/fetchBatchCleaningReport";
import React, { useState } from "react";
import { FetchBatchCleaningReport } from "types/graphql/queries/fetchBatchCleaningReport";
import { months } from "utils/listOfMonths";

const format = () => (tick: string) => tick;
const formatForFullstack = (scale: ScaleObject) => scale.tickFormat(null, "s");

const pointOptions = { point: { size: 7 } };

const Point = (props) => {
	const { value } = props;
	if (value) {
		return <ScatterSeries.Point {...props} {...pointOptions} />;
	}
	return null;
};

const LineWithPoint = (props: LineSeries.SeriesProps) => (
	<React.Fragment>
		<LineSeries.Path {...props} />
		<ScatterSeries.Path
			{...props}
			pointComponent={Point}
			style={{
				backgroundColor: "red",
			}}
		/>
	</React.Fragment>
);

const formatterNumber = new Intl.NumberFormat("pt-BR", {
	style: "decimal",
});

export function ReportBatchCleaningAnnual() {
	const [year, setYear] = useState(new Date().getFullYear());
	const { data: dataReport, loading } = useQuery<FetchBatchCleaningReport>(
		FETCH_BATCH_CLEANING_REPORT,
		{
			fetchPolicy: "network-only",
			variables: {
				year: year,
			},
		}
	);

	const handleChangeYear = (newValue: Date) => {
		setYear(new Date(newValue).getFullYear());
	};

	const data = dataReport?.fetchBatchCleaningReport?.per_month.map((item) => ({
		month_name: months[item.month_index],
		count_protocols: item.count_protocols,
		inspected_batches: item.inspected_batches,
		fined_lots: item.fined_lots,
		total_fines: item.total_fines,
		total_assessment: item.total_assessment,
		forwarded_urban_cleaning: item.forwarded_urban_cleaning,
		forwarded_miscellaneous_income: item.forwarded_miscellaneous_income,
		counts: item.counts,
	}));

	const colors = ["green", "#ff9800", "#d32f2f", "#c92fd3"];

	const stacks = [
		{
			series: ["Concluído", "Em andamento", "Cancelados", "Suspensos"],
		},
	];

	const dataChartPerMonth = data?.map((item) =>
		Object.keys(item.counts)
			.map((key, index) => ({
				situation: stacks[0].series[index - 1],
				[key]: item.counts[key],
			}))
			.splice(1)
	);

	const dataChartBars = [
		{
			situation: "Concluído",
			concluded: dataReport?.fetchBatchCleaningReport.counts.concluded,
		},
		{
			situation: "Em progresso",
			in_progress: dataReport?.fetchBatchCleaningReport.counts.in_progress,
		},
		{
			situation: "Suspenso",
			suspended: dataReport?.fetchBatchCleaningReport.counts.suspended,
		},
		{
			situation: "Cancelado",
			canceled: dataReport?.fetchBatchCleaningReport.counts.canceled,
		},
	];

	const valueFields = ["concluded", "in_progress", "suspended", "canceled"];

	return (
		<Box
			width="100%"
			display="flex"
			alignSelf="stretch"
			flexDirection="column"
			sx={{
				backgroundColor: "#f3f3f3",
			}}
			paddingBottom="1rem"
		>
			<Header
				title="Relatório anual de limpeza de lote Lei (4523/2020)"
				isReturn
			/>

			{loading ? (
				<Backdrop open={loading}>
					<CircularProgress
						sx={{
							color: "#fff",
						}}
					/>
				</Backdrop>
			) : (
				<Container maxWidth="lg" sx={{ marginTop: "1rem" }}>
					<Paper
						sx={{
							paddingY: "1rem",
							paddingX: "2rem",
						}}
					>
						<Box display="flex" alignItems={"center"} justifyContent={"center"}>
							<Typography
								variant="h6"
								fontWeight={500}
								component="h2"
								gutterBottom
							>
								Limpeza de Lote - Lei (4523/2020)
							</Typography>
							<LocalizationProvider
								dateAdapter={AdapterDayjs}
								adapterLocale="pt-br"
							>
								<DatePicker
									disableFuture
									inputFormat="YYYY"
									openTo="year"
									views={["year"]}
									label="Ano"
									value={new Date(year, 0, 1)}
									onChange={(newValue) => {
										handleChangeYear(newValue);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											sx={{
												width: "100px",
												ml: "1rem",
											}}
										/>
									)}
								/>
							</LocalizationProvider>
						</Box>
						<Stack
							direction={"row"}
							justifyContent={"space-between"}
							alignItems={"center"}
							flexWrap={"wrap"}
							mt={2}
						>
							<Box>
								<Typography variant="caption">Protocolos criados</Typography>
								<Typography variant="subtitle1" fontWeight={600}>
									{dataReport?.fetchBatchCleaningReport?.count_protocols ||
										" - "}
								</Typography>
							</Box>
							<Box>
								<Typography variant="caption">Lotes fiscalizados</Typography>
								<Typography variant="subtitle1" fontWeight={600}>
									{dataReport?.fetchBatchCleaningReport?.inspected_batches ||
										" - "}
								</Typography>
							</Box>
							<Box>
								<Typography variant="caption">Lotes autuados</Typography>
								<Typography variant="subtitle1" fontWeight={600}>
									{dataReport?.fetchBatchCleaningReport?.fined_lots || " - "}
								</Typography>
							</Box>
							<Box>
								<Typography variant="caption">
									Total autuações (UPFMLS)
								</Typography>
								<Typography variant="subtitle1" fontWeight={600}>
									{dataReport?.fetchBatchCleaningReport?.total_assessment
										? formatterNumber.format(
												dataReport?.fetchBatchCleaningReport?.total_assessment
										  )
										: " - "}
								</Typography>
							</Box>
							<Box>
								<Typography variant="caption">Enc. Limpeza Urbana</Typography>
								<Typography variant="subtitle1" fontWeight={600}>
									{
										dataReport?.fetchBatchCleaningReport
											?.forwarded_urban_cleaning
									}
								</Typography>
							</Box>
							<Box>
								<Typography variant="caption">Enc. Rendas diversas</Typography>
								<Typography variant="subtitle1" fontWeight={600}>
									{
										dataReport?.fetchBatchCleaningReport
											?.forwarded_miscellaneous_income
									}
								</Typography>
							</Box>
							<Box>
								<Typography variant="caption">
									Total multado (UPFMLS)
								</Typography>
								<Typography variant="subtitle1" fontWeight={600}>
									{dataReport?.fetchBatchCleaningReport?.total_fines
										? formatterNumber.format(
												dataReport?.fetchBatchCleaningReport?.total_fines
										  )
										: " - "}
								</Typography>
							</Box>
						</Stack>
						<Grid container mt={4}>
							<Grid item xs={12} md={4}>
								<Typography variant="h6">Protocolos criados</Typography>
								<Typography variant="caption">
									Situação dos protocolos
								</Typography>
								{dataReport && (
									<Chart data={dataChartBars} height={300}>
										<ArgumentAxis />
										<ValueAxis />

										{Array.from(
											{ length: stacks[0].series.length },
											(_, index) => (
												<BarSeries
													key={index}
													name={stacks.map((item) => item.series[index])[0]}
													argumentField="situation"
													valueField={valueFields[index]}
													color={colors[index]}
												/>
											)
										)}

										<Animation />
										<EventTracker />
										<Tooltip />
									</Chart>
								)}
							</Grid>
							{dataReport && (
								<Grid item xs={12} md={8}>
									<Typography variant="h6">Lotes fiscalizados</Typography>
									<Typography variant="caption">Por mês</Typography>
									<Chart
										data={dataReport?.fetchBatchCleaningReport?.per_month.map(
											(item) => ({
												month: months[item.month_index],
												val: item.fined_lots,
											})
										)}
										height={300}
									>
										<ArgumentAxis tickFormat={format} showGrid />
										<ValueAxis tickFormat={formatForFullstack} />
										<AreaSeries
											name="month"
											valueField="val"
											argumentField="month"
											color="rgba(66, 165, 245, 0.5)"
										/>
										<LineSeries
											name="month"
											valueField="val"
											argumentField="month"
											color="#00aaff"
											seriesComponent={LineWithPoint}
										/>
										<Animation />
									</Chart>
								</Grid>
							)}
						</Grid>
					</Paper>
					{dataReport && (
						<Grid container mt={8} gap={3} justifyContent={"space-between"}>
							{data.length === 0 ? (
								<Grid item xs={12}>
									<Paper>
										<Box p={2}>
											<Typography variant="h6" fontWeight={500} component="h2">
												Não há dados para o ano selecionado
											</Typography>
										</Box>
									</Paper>
								</Grid>
							) : (
								data.map((item, index) => {
									if (item.count_protocols === 0) return null;
									return (
										<Grid item xs={12} md={2.5} key={item.month_name + index}>
											<Paper>
												<Box p={2}>
													<Typography
														variant="h6"
														fontWeight={500}
														component="h2"
													>
														{item.month_name}
													</Typography>
													<Chart data={dataChartPerMonth[index]} height={130}>
														<ValueAxis />

														{Array.from(
															{ length: stacks[0].series.length },
															(_, index) => (
																<BarSeries
																	key={index}
																	name={
																		stacks.map((item) => item.series[index])[0]
																	}
																	argumentField="situation"
																	valueField={valueFields[index]}
																	color={colors[index]}
																/>
															)
														)}

														<Animation />
														<EventTracker />
														<Tooltip />
													</Chart>
													<Stack mt={4}>
														<Box>
															<Typography variant="caption">
																Protocolos criados
															</Typography>
															<Typography variant="subtitle2">
																{item.count_protocols}
															</Typography>
														</Box>
														<Box>
															<Typography variant="caption">
																Lotes fiscalizados
															</Typography>
															<Typography variant="subtitle2">
																{item.inspected_batches}
															</Typography>
														</Box>
														<Box>
															<Typography variant="caption">
																Lotes autuados
															</Typography>
															<Typography variant="subtitle2">
																{item.fined_lots}
															</Typography>
														</Box>
														<Box>
															<Typography variant="caption">
																Total autuações (UPFMLS)
															</Typography>
															<Typography variant="subtitle2">
																{item.total_assessment
																	? formatterNumber.format(
																			item.total_assessment
																	  )
																	: " - "}
															</Typography>
														</Box>
														<Box>
															<Typography variant="caption">
																Enc. Limpeza Urbana
															</Typography>
															<Typography variant="subtitle2">
																{item.forwarded_urban_cleaning}
															</Typography>
														</Box>
														<Box>
															<Typography variant="caption">
																Enc. Rendas Diversas
															</Typography>
															<Typography variant="subtitle2">
																{item.forwarded_miscellaneous_income}
															</Typography>
														</Box>
														<Box>
															<Typography variant="caption">
																Total multado (UPFMLS)
															</Typography>
															<Typography variant="subtitle2">
																{item.total_fines
																	? formatterNumber.format(item.total_fines)
																	: " - "}
															</Typography>
														</Box>
													</Stack>
												</Box>
											</Paper>
										</Grid>
									);
								})
							)}
						</Grid>
					)}
				</Container>
			)}
		</Box>
	);
}
