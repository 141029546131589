import {
	Link,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
} from "@mui/material";
import { EmptyMessage } from "components/EmptyMessage";
import LocalProvider from "components/LocalProvider";
import { format } from "date-fns";
import { FetchDynamicReports } from "types/graphql/queries/fetchDynamicReport";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { DELETE_DYNAMIC_REPORT } from "graphql/mutations/deleteDynamicReport";
import { Fragment, useRef, useState } from "react";
import { ModalConfirmationDelection } from "components/Modal/ModalConfirmationDelection";
import IconButton from "components/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

interface AnalyticalReportProps {
	dynamicReports: FetchDynamicReports["fetchDynamicReports"];
	handleSwitchPage: (page: number) => void;
	handleSwitchPerPage: (rowsPerPage: number) => void;
	handleSort: (orderBy: string) => void;
	refetch: () => void;
	currentPage: number;
	rowsPerPage: number;
	orderBy: string;
	direction: "asc" | "desc";
}

export function AnalyticalReports({
	dynamicReports,
	handleSwitchPage,
	handleSwitchPerPage,
	handleSort,
	refetch,
	currentPage,
	rowsPerPage,
	orderBy,
	direction,
}: AnalyticalReportProps) {
	const { enqueueSnackbar } = useSnackbar();
	const [deleteDynamicReport, { loading: loadingDelete }] = useMutation(
		DELETE_DYNAMIC_REPORT
	);
	const [
		isOpenModalConfirmationDelection,
		setIsOpenModalConfirmationDelection,
	] = useState(false);
	const navigate = useNavigate();
	const idToDelete = useRef<number | null>(null);

	const handleNavigate = (id: number) => {
		navigate(`relatorio-analitico/${id}`);
	};

	const handleDelete = (id: number) => {
		idToDelete.current = id;
		setIsOpenModalConfirmationDelection(true);
	};

	const handleDeleteReport = async () => {
		try {
			await deleteDynamicReport({
				variables: {
					id: idToDelete.current,
				},
			});
			enqueueSnackbar("Relatário analítico excluído com sucesso", {
				variant: "success",
			});
			refetch();
			setIsOpenModalConfirmationDelection(false);
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
	};
	return (
		<>
			<TableContainer style={{ marginTop: "20px" }}>
				{dynamicReports.data.length === 0 && (
					<>
						<EmptyMessage message="Nenhum relatório analítico encontrado " />
					</>
				)}

				{dynamicReports.data.length !== 0 && (
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									<TableSortLabel
										active={orderBy === "title"}
										direction={orderBy === "title" ? direction : "asc"}
										onClick={() => handleSort("title")}
									>
										Título
									</TableSortLabel>
								</TableCell>
								<TableCell>Base de dados</TableCell>
								<TableCell>Versão</TableCell>
								<TableCell>Criado por</TableCell>
								<TableCell>
									<TableSortLabel
										active={orderBy === "created_at"}
										direction={orderBy === "created_at" ? direction : "asc"}
										onClick={() => handleSort("created_at")}
									>
										Criado em
									</TableSortLabel>
								</TableCell>
								<TableCell>Atualizado em</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{dynamicReports.data.map((row) => (
								<TableRow key={row.id}>
									<TableCell>
										<Link
											onClick={() => handleNavigate(row.id)}
											underline="hover"
											sx={{
												fontWeight: 600,
												display: "block",
												cursor: "pointer",
											}}
										>
											{row.title}
										</Link>
									</TableCell>
									<TableCell>{row.data_source}</TableCell>
									<TableCell>{row.version}</TableCell>
									<TableCell>{row.created_by.name}</TableCell>
									<TableCell>
										{format(new Date(row.created_at), "dd/MM/yyyy")}
									</TableCell>
									<TableCell>
										{format(new Date(row.updated_at), "dd/MM/yyyy")}
									</TableCell>
									<TableCell>
										<IconButton onClick={() => handleDelete(row.id)}>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				)}
			</TableContainer>

			{dynamicReports.data.length !== 0 && (
				<LocalProvider locale="ptBR">
					<TablePagination
						showLastButton
						showFirstButton
						rowsPerPageOptions={[5, 15, 30, 60, 100]}
						component="div"
						count={dynamicReports.total}
						rowsPerPage={rowsPerPage}
						page={currentPage}
						onPageChange={(event, newPage) => {
							handleSwitchPage(newPage);
						}}
						onRowsPerPageChange={(event) => {
							handleSwitchPerPage(parseInt(event.target.value));
							handleSwitchPage(0);
						}}
					/>
				</LocalProvider>
			)}
			{isOpenModalConfirmationDelection && (
				<ModalConfirmationDelection
					message="Deseja realmente excluir este relatório?"
					handleClose={() => setIsOpenModalConfirmationDelection(false)}
					open={isOpenModalConfirmationDelection}
					handleSubmit={handleDeleteReport}
					isLoading={loadingDelete}
				/>
			)}
		</>
	);
}
