import { Animation, EventTracker } from "@devexpress/dx-react-chart";
import {
	BarSeries,
	Chart,
	ValueAxis,
	Tooltip,
} from "@devexpress/dx-react-chart-material-ui";
import { Box, Typography, Grid, Paper, Stack} from "@mui/material";

interface VariantBarProps {
	chartData: Array<any>;
	title: string;
	label: string;
	rotate?: boolean;
	medias?: {[key: string]: boolean};
}

export function VariantSsidChart({
	chartData = [],
	title = "",
	rotate = false,
	label = "",
	medias
}: VariantBarProps) {
	const {media768, media600, media425} = medias;
	return (
		<Grid container rowGap={3} columnSpacing={{ xs: 12, sm: 4, md: 4 }}>
			{chartData.map((mes, index) => {
				const listOfTitles = Object.entries(mes)
					.filter(([key]) => key !== "month")
					.map(([key, value]) => ({
						key: key.toLowerCase(),
						value:value,
					}));

				return (
					<Grid key={index} item xs={
						media768 ? 
							media600 ? 
								media425 ? 12 
								:6
							:4
						:3}>
						<Paper sx={{ p: 2 }}>
							<Typography variant="h6" fontWeight={500} component="h2">
								{mes.month}
							</Typography>
							<Typography variant="caption">{label}</Typography>
							<Chart data={listOfTitles} rotated={rotate} height={300}>
								<ValueAxis />

								{listOfTitles.map((titles, key) => (
									<BarSeries
										key={key}
										valueField="value"
										argumentField="key"
										name={titles.key}
										color="#1976d2"
									/>
								))}
								<EventTracker />
								<Tooltip />
								<Animation />
							</Chart>
							<Stack mt={4}>
								{listOfTitles.map((title) => (
									<Box key={title.key}>
										<Typography
											sx={{
												color: "#555",
												"&:first-letter": {
													textTransform: "uppercase",
												},
											}}
											variant="caption"
										>
											{title.key}
										</Typography>
										<Typography variant="subtitle2" fontWeight={500}>
											{String(title.value)}
										</Typography>
									</Box>
								))}
							</Stack>
						</Paper>
					</Grid>
				);
			})}
		</Grid>
	);
}
