import { Stack, Typography } from "@mui/material";
import { ModalLayout } from "components/ModalLayout";
import { SelectFilterPerfisModal } from "components/SelectFilterPerfisModal";
import { useAuthContext } from "context/AuthProvider";
import { ModalLayoutProps } from "types/ModalLayout";
import { useState } from "react";

type ModalSharePerfisProps = ModalLayoutProps & {
	handleAddPerfis: (perfis: Array<{ id: number; name: string }>) => void;
	defaultPerfis?: Array<{ id: number; name: string }>;
};

export function ModalSharePerfis({
	open,
	handleClose,
	handleAddPerfis,
	defaultPerfis,
}: ModalSharePerfisProps) {
	const [perfisSelecteds, setPerfisSelecteds] = useState<
		Array<{ id: number; name: string }>
	>(defaultPerfis || []);
	const { state } = useAuthContext();

	const handleCloseModal = () => {
		if (perfisSelecteds.length === 0) {
			handleAddPerfis([
				{
					id: state.userInfo.user.perfil_id || 1,
					name: state.userInfo.user.perfil || "Administrador",
				},
			]);
		} else {
			handleClose();
		}
	};

	const onSubmit = () => {
		if (defaultPerfis) {
			handleAddPerfis([...perfisSelecteds]);
		} else {
			handleAddPerfis([
				{
					id: state.userInfo.user.perfil_id || 1,
					name: state.userInfo.user.perfil || "Administrador",
				},
				...perfisSelecteds,
			]);
		}
	};

	const onChangePerfis = (perfis: Array<{ id: number; name: string }>) => {
		if (defaultPerfis) {
			setPerfisSelecteds(perfis);
		} else {
			setPerfisSelecteds(
				perfis.filter((p) => p.id !== (state.userInfo.user.perfil_id || 1))
			);
		}
	};

	return (
		<ModalLayout
			open={open}
			handleClose={handleCloseModal}
			title="Compartilhar"
			buttonText="Adicionar"
			handleSubmit={() => onSubmit()}
		>
			<SelectFilterPerfisModal
				onChange={(perfis) => onChangePerfis(perfis)}
				defaultPerfis={
					defaultPerfis ?? [
						{
							id: state.userInfo.user.perfil_id || 1,
							name: state.userInfo.user.perfil || "Administrador",
						},
						...perfisSelecteds,
					]
				}
			/>
			<Stack spacing={2}>
				<Typography
					fontSize="20px"
					letterSpacing={"0.15px"}
					lineHeight={"24px"}
					mt="37px"
				>
					Perfis com acesso
				</Typography>
				<Stack spacing={1}>
					{!defaultPerfis && (
						<Stack direction="row" justifyContent="space-between">
							<Typography variant="subtitle1">
								{state.userInfo.user.perfil || "Administrador"}
							</Typography>
							<Typography variant="caption">Proprietário</Typography>
						</Stack>
					)}
					{perfisSelecteds.map((perfil, index) => (
						<Stack
							direction="row"
							justifyContent="space-between"
							key={perfil.id}
						>
							<Typography variant="subtitle1">{perfil.name}</Typography>
							<Stack direction="row" justifyContent="space-between">
								{defaultPerfis && index === 0 && (
									<Typography variant="caption">Proprietário</Typography>
								)}
							</Stack>
						</Stack>
					))}
				</Stack>
			</Stack>
		</ModalLayout>
	);
}
