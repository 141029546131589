import { useMutation } from "@apollo/client";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Stack,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { useSnackbar } from "notistack";
import Input from "components/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { UPDATE_PASSWORD } from "graphql/mutations/updatePassword";
import LoadingButton from "components/LoadingButton";

type EditPasswordProps = {
	open: boolean;
	handleClose: () => void;
	onEdit: () => void;
	userId: number;
};

const schema = yup.object().shape({
	password: yup.string().required("Campo obrigatório"),
	passwordConfirmation: yup
		.string()
		.required("Campo obrigatório")
		.oneOf([yup.ref("password"), null], "Senhas não conferem"),
});

export default function EditPassword({
	open,
	handleClose,
	onEdit,
	userId,
}: EditPasswordProps) {
	const methods = useForm({
		defaultValues: {
			password: "",
			passwordConfirmation: "",
		},
		resolver: yupResolver(schema),
	});
	const { enqueueSnackbar } = useSnackbar();
	// Setting mutations
	const [updatePassword, { loading }] = useMutation(UPDATE_PASSWORD);

	const { reset, handleSubmit } = methods;

	const onSubmit = async (values: { password: string }) => {
		try {
			await updatePassword({
				variables: {
					user_id: userId,
					password: values.password,
				},
			});
			onEdit();
			reset({
				password: "",
				passwordConfirmation: "",
			});
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="paper"
				maxWidth="sm"
				fullWidth={true}
			>
				<FormProvider {...methods}>
					<DialogTitle id="scroll-dialog-title">Alterar senha</DialogTitle>

					<DialogContent>
						<Stack spacing={2} marginTop={2}>
							<Input
								name="password"
								placeholder="Senha*"
								type="password"
								isPassword
							/>
							<Input
								name="passwordConfirmation"
								placeholder="Confirmação de senha*"
								type="password"
								isPassword
							/>
						</Stack>
					</DialogContent>

					<DialogActions>
						<Button onClick={handleClose} color="primary" disabled={loading}>
							Cancelar
						</Button>
						<LoadingButton
							onClick={handleSubmit(onSubmit)}
							loading={loading}
							variant="contained"
						>
							Alterar senha
						</LoadingButton>
					</DialogActions>
				</FormProvider>
			</Dialog>
		</>
	);
}
