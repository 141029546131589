import { gql } from "@apollo/client";

export const CREATE_DISTRICT = gql`
	mutation (
		$name: String!
		$city_id: Int!
		$latitude: String
		$longitude: String
	) {
		createDistrict(
			name: $name
			city_id: $city_id
			latitude: $latitude
			longitude: $longitude
		)
	}
`;
