import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { CircularProgress } from "components/CircularProgress";
import { CSVDownloader } from "components/ExportCSV/CSVDownloader";
import { LinearProgressWithLabel } from "components/LinearProgress";
import { ModalLayout } from "components/ModalLayout";
import { FETCH_POINTS } from "graphql/queries/fetchPoints";
import { useEffect, useState } from "react";
import { FetchPoints } from "types/graphql/queries/fetchPoints";
import { ModalLayoutProps } from "types/ModalLayout";
import { closeModalExportCsv } from "utils/closeModalExportCsv";

type ModalExportCSVProps = ModalLayoutProps & {
	orderBy: string;
	direction: string;
	debouncedValue: string;
	description: string;
	responsibles: any;
	startDate: string;
	finishDate: string;
};

export function ModalExportCSV({
	open,
	handleClose,
	orderBy,
	direction,
	debouncedValue,
	description,
	responsibles,
	startDate,
	finishDate,
}: ModalExportCSVProps) {
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [dataExport, setDataExport] = useState<
		FetchPoints["fetchPoints"]["data"]
	>([]);

	const { data, loading } = useQuery<FetchPoints>(FETCH_POINTS, {
		fetchPolicy: "network-only",
		variables: {
			order_by: orderBy,
			direction: direction,
			keyword: debouncedValue,
			page: page,
			limit: 500,
			description: description,
			responsibles:
				responsibles && responsibles.length > 0
					? responsibles.map((item) => item.id)
					: null,
			start: startDate,
			finish: finishDate,
		},
	});

	const rowsCsv = dataExport.map((points) => [
		points.protocol ?? " - ",
		points.user ?? " - ",
		points.created_at ?? " - ",
		points.points ? String(points.points) : " - ",
		points.description ?? " - ",
	]);

	const headersCsv = [
		"Protocolo",
		"Fiscal",
		"Data/Hora",
		"Pontuação",
		"Descrição",
	];

	useEffect(() => {
		if (data) {
			setTotal(data.fetchPoints.total);
			setDataExport((prev) => [...prev, ...data.fetchPoints.data]);
			if (data.fetchPoints.total > dataExport.length) {
				setPage((prev) => prev + 1);
			}
		}
	}, [data]);

	const percentageDataExport =
		(dataExport.length * 100) / data?.fetchPoints?.total;

	if(percentageDataExport === 100) {
		closeModalExportCsv(
			100,
			handleClose,
		);
	}

	return (
		<ModalLayout
			open={open}
			handleClose={handleClose}
			title="Exportar CSV"
			isNotShowButtonSubmit
			buttonTextClose="Cancelar"
		>
			<>
				{data?.fetchPoints?.total === dataExport.length && (
					<CSVDownloader rowsCsv={rowsCsv} headersCsv={headersCsv} />
				)}
				{loading && page === 1 ? (
					<CircularProgress />
				) : (
					<Box sx={{ width: "100%" }}>
						<LinearProgressWithLabel
							value={percentageDataExport}
							current={dataExport.length}
							total={total}
						/>
					</Box>
				)}
			</>
		</ModalLayout>
	);
}
