import { Box, Typography } from "@mui/material";

interface WrapperInfoProps {
	title: string;
	value: number | string;
	color?: string;
	fontSize?: number | string;
}

export function WrapperFilterInfo({
	title,
	value,
	color,
	fontSize,
}: WrapperInfoProps) {
	return (
		<Box display="flex" alignItems="baseline">
			<Typography variant="caption" color={color} fontSize={fontSize}>
				{title}
			</Typography>
			<Box
				flex={1}
				borderBottom={`1px dotted ${color || "#000000"}`}
				marginX={1}
			/>
			<Typography variant="caption" color={color} fontSize={fontSize}>
				{value}
			</Typography>
		</Box>
	);
}
