import Button from "components/Button";
import { Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { CountDocumentsType } from "types/graphql/queries/countDocuments";

interface SliderProps {
	handleSelectFilter: (key: string) => void;
	filter: string;
	dataCount: CountDocumentsType;
	counters: {
		key: string;
		name: string;
	}[];
}

export function Slider({
	handleSelectFilter,
	filter,
	dataCount,
	counters,
}: SliderProps) {
	return (
		<Swiper slidesPerView={"auto"} spaceBetween={20} className="mySwiper">
			{dataCount &&
				counters.map((counter) => {
					const dataCountKey = Object.keys(dataCount?.countDocuments).map(
						(key) => {
							return key;
						}
					);
					return (
						<SwiperSlide
							style={{
								height: "200px",
								width: "350px",
							}}
							key={counter.name}
						>
							<Button
								sx={{
									color: filter === counter.key ? "#fff" : "#000",
									width: "100%",
									height: "100%",
									borderRadius: "20px",
									backgroundColor: filter === counter.key ? "#2660F7" : "#fff",
									flexDirection: "column",
									alignItems: "flex-start",
									paddingX: "48px",
								}}
								onClick={() => handleSelectFilter(counter.key)}
							>
								<Typography
									variant="h6"
									fontWeight={500}
									fontSize="64px"
									lineHeight={"56px"}
								>
									{dataCountKey.includes(counter.key)
										? dataCount?.countDocuments[counter.key]
										: ""}
								</Typography>
								<Typography variant="subtitle2">{counter.name}</Typography>
							</Button>
						</SwiperSlide>
					);
				})}
		</Swiper>
	);
}
