import {
	Typography,
	Card,
	CardContent,
	CardHeader,
	Box,
	Link,
} from "@mui/material";
import { DetailProtocol } from "types/graphql/queries/detailProtocol";
import ImgList from "components/ImgList";
import AttachFileIcon from "@mui/icons-material/AttachFile";

interface ComplaintProps {
	serviceOrder: {
		description: DetailProtocol["detailProtocol"]["params"];
		attachments: DetailProtocol["detailProtocol"]["attachments"];
	};
}

export function ServiceOrder({ serviceOrder }: ComplaintProps) {
	if (!serviceOrder) return null;
	if (serviceOrder.description === null) return null;
	if (serviceOrder.description === "null") return null;
	if (serviceOrder.attachments === null) return null;

	const description = JSON.parse(serviceOrder.description);
	return (
		<>
			<Card style={{ marginTop: 16 }}>
				<CardHeader title="Ordem de serviço" />
				<CardContent>
					<Box display="flex" width="100%">
						<Box>
							<Typography variant="caption">Descrição:</Typography>
							<Typography variant="body2">
								{description.description || " - "}
							</Typography>
						</Box>
					</Box>

					<Box sx={{ marginTop: 4 }}>
						{serviceOrder.attachments.length > 0 && (
							<>
								{serviceOrder.attachments.map((attch, index) => {
									const isPdf = attch.url.split(".").pop();
									if (isPdf === "pdf") {
										return (
											<Box key={index}>
												<AttachFileIcon />
												<Link
													variant="h6"
													fontSize="1rem"
													href={attch.url}
													target="_blank"
													rel="norefferer"
												>
													{attch.name}
												</Link>
											</Box>
										);
									}
								})}
								<ImgList itemsData={serviceOrder.attachments} />
							</>
						)}
					</Box>
				</CardContent>
			</Card>
		</>
	);
}
