import { gql } from "@apollo/client";

export const FETCH_POINTS = gql`
	query (
		$keyword: String
		$order_by: String
		$direction: String
		$page: Int
		$limit: Int
		$start: String
		$finish: String
		$description: String
		$responsibles: [Int]
	) {
		fetchPoints(
			keyword: $keyword
			order_by: $order_by
			direction: $direction
			page: $page
			limit: $limit
			start: $start
			finish: $finish
			description: $description
			responsibles: $responsibles
		) {
			total
			current_page
			per_page
			last_page
			data {
				protocol
				user
				created_at
				points
				description
			}
		}
	}
`;
