import { useMutation } from "@apollo/client";
import { ModalLayout } from "components/ModalLayout";
import useStep from "hooks/useStep";
import { Steps } from "components/Steps";
import { StepSubject } from "./StepSubject";
import { StepAdress } from "./StepAdress";
import { StepDescription } from "./StepDescription";
import { useForm, FormProvider } from "react-hook-form";
import { useSnackbar } from "notistack";
import { CREATE_SERVICE_ORDER } from "graphql/mutations/createServiceOrder";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

type ModalReportProps = {
	open: boolean;
	handleClose: () => void;
	refetch: () => void;
};

const schema = yup.object().shape({
	subject_id: yup.number().required("Campo obrigatório"),
	type: yup.string().required("Campo obrigatório"),
	date: yup.string().required("Campo obrigatório"),
	responsible_user_id: yup.number().required("Campo obrigatório"),
	zipcode: yup.string(),
	street: yup.string().required("Campo obrigatório"),
	number: yup.string(),
	district_id: yup.number(),
	complement: yup.string(),
	description: yup.string().required("Campo obrigatório"),
});

export function ModalCreateOS({
	open,
	handleClose,
	refetch,
}: ModalReportProps) {
	const navigate = useNavigate();
	const methods = useForm({
		resolver: yupResolver(schema),
	});

	const { enqueueSnackbar } = useSnackbar();

	// Setting mutation
	const [createServiceOrder, { loading }] = useMutation(CREATE_SERVICE_ORDER);

	const { reset, handleSubmit } = methods;
	const steps = [
		{
			label: "Selecione o assunto",
			component: StepSubject,
		},
		{
			label: "Preencha o endereço",
			component: StepAdress,
		},
		{
			label: "Preencha a descrição",
			component: StepDescription,
		},
	];

	const [currentStep, helpers] = useStep(steps.length);
	const { goToNextStep, goToPrevStep, reset: resetStep } = helpers;

	const onSubmit = async (values: any) => {
		try {
			const { data } = await createServiceOrder({
				variables: {
					subject_id: values.subject_id,
					type: values.type,
					responsible_user_id: values.responsible_user_id,
					expire_at: format(new Date(values.date), "yyyy-MM-dd"),
					zipcode: values.zipcode,
					street: values.street,
					number: values.number,
					district_id: values.district_id,
					complement: values.complement,
					description: values.description,
					files: values.files,
				},
			});
			enqueueSnackbar("Sua OS foi registrada com sucesso!", {
				variant: "success",
			});
			handleClose();
			refetch();
			navigate(`/protocolo/${data.createServiceOrder}`);
			reset({
				subject_id: "",
				type: "",
				date: null,
				responsible_user_id: "",
				expire_at: null,
				zipcode: "",
				street: "",
				number: "",
				district_id: "",
				complement: "",
				description: "",
				files: [],
			});
			resetStep();
		} catch (err) {
			enqueueSnackbar(err.message, {
				variant: "error",
			});
		}
	};

	return (
		<>
			<FormProvider {...methods}>
				<ModalLayout
					open={open}
					handleClose={handleClose}
					handleSubmit={handleSubmit(onSubmit)}
					title="Criar OS"
					buttonText="Criar OS"
					isLoading={loading}
				>
					<Steps
						steps={steps}
						currentStep={currentStep}
						goToNextStep={goToNextStep}
						goToPrevStep={goToPrevStep}
					/>
				</ModalLayout>
			</FormProvider>
		</>
	);
}
