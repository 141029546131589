import { gql } from "@apollo/client";

export const FETCH_SERVICE_ORDERS = gql`
	query (
		$start: String
		$finish: String
		$situations: [String]
		$subject_ids: [Int]
		$responsible_ids: [Int]
		$group_ids: [Int]
		$status_ids: [Int]
		$keyword: String
		$order_by: String
		$direction: String
		$page: Int
		$limit: Int
	) {
		fetchServiceOrders(
			start: $start
			finish: $finish
			situations: $situations
			subject_ids: $subject_ids
			responsible_ids: $responsible_ids
			group_ids: $group_ids
			status_ids: $status_ids
			keyword: $keyword
			order_by: $order_by
			direction: $direction
			page: $page
			limit: $limit
		) {
			total
			current_page
			per_page
			last_page
			data {
				id
				code
				situation
				expire_at
				created_at
				updated_at
				responsible{
					id name
				}
				subject {
					id name
				}
				status  {
					id name theme
				}
				street
				number
				complement
				zipcode
				district{
					id
					name
				}
			}
		}
	}
`;
