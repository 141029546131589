import {
	FormControl,
	InputLabel,
	Select as SelectMaterial,
	OutlinedInput,
	SelectProps as SelectPropsMaterial,
	MenuItem,
	Grid,
} from "@mui/material";
import { useFormContext } from "react-hook-form";

interface SelectBoxProps extends SelectPropsMaterial {
	label: string;
	name: string;
	options?: Array<{
		label?: string;
		title?: string;
		name?: string;
		value?: string | number;
		id?: string;
	}>;
	index?: number;
	readonly?: boolean;
}

function FieldSelectBox({
	label,
	name,
	options,
	fullWidth,
	index,
	readonly,
	...rest
}: SelectBoxProps) {
	const { register } = useFormContext();
	return (
		<Grid item xs={12}>
			<FormControl sx={{ minWidth: 120 }} fullWidth>
				<InputLabel htmlFor={`dialog-${label}`}>{label}</InputLabel>
				<SelectMaterial
					readOnly={readonly}
					{...rest}
					fullWidth
					input={<OutlinedInput label={label} id={`dialog-${label}`} />}
					defaultValue={label}
					{...register(`groups.${index}.fields.${name}`)}
				>
					<MenuItem value="">
						<em>Nenhum</em>
					</MenuItem>
					{options.map((option) => (
						<MenuItem
							key={option.value ?? option.id}
							value={option.value ?? option.id}
						>
							{option.title ?? option.label}
						</MenuItem>
					))}
				</SelectMaterial>
			</FormControl>
		</Grid>
	);
}

export default FieldSelectBox;
