import { gql } from "@apollo/client";

export const SEARCH_CUSTOM_DATA = gql`
	query (
		$type: String
		$keyword: String
		$limit: Int
		$page: Int
		$filter: [FilterSearchCustomData]
	) {
		searchCustomData(
			type: $type
			keyword: $keyword
			limit: $limit
			page: $page
			filter: $filter
		) {
			total
			current_page
			per_page
			last_page
			data {
				id
				type
				value
			}
		}
	}
`;
