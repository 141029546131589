import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { ModalLayout } from "components/ModalLayout";
import { ModalLayoutProps } from "types/ModalLayout";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@apollo/client";
import { Select } from "components/Select";
import { FETCH_USERS } from "graphql/queries/fetchUsers";
import { FetchUsers } from "types/graphql/queries/fetchUsers";
import { CircularProgress } from "components/CircularProgress";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UPDATE_RESPONSIBLE_PROTOCOL } from "graphql/mutations/updateResponsibleProtocol";

type ModalDesignateResponsibleProps = ModalLayoutProps & {
	refetch: () => void;
	protocolCode: string;
};

const schema = yup.object().shape({
	responsible: yup.number().required("Campo obrigatório"),
	update_all_tasks: yup.boolean()
});

export function ModalDesignateResponsible({
	open,
	handleClose,
	refetch,
	protocolCode,
}: ModalDesignateResponsibleProps) {

	const { data, loading } = useQuery<FetchUsers>(FETCH_USERS, {
		variables: {
			limit: 1000,
			status: "Ativo",
			order_by: "users.name",
			direction: "asc",
		},
	});
	const [updateResponsibleProtocol, { loading: loadingUpdate }] = useMutation(
		UPDATE_RESPONSIBLE_PROTOCOL
	);
	const { enqueueSnackbar } = useSnackbar();
	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			responsible: "",
			update_all_tasks: false
		},
	});
	const { handleSubmit, reset } = methods;

	const onSubmit = async (data: any) => {
		try {
			await updateResponsibleProtocol({
				variables: {
					code: protocolCode,
					responsible_user_id: data.responsible,
					update_all_tasks: data.update_all_tasks
				},
			});
			enqueueSnackbar("Responsável atualizado com sucesso!", {
				variant: "success",
			});
			refetch();
			handleClose();
			reset({
				responsible: "",
				update_all_tasks: false
			});
		} catch (err) {
			enqueueSnackbar(err.message, {
				variant: "error",
			});
		}
	};
	return (
		<FormProvider {...methods}>
			<ModalLayout
				open={open}
				handleClose={handleClose}
				title="Atualizar responsável"
				handleSubmit={handleSubmit(onSubmit)}
				isLoading={loadingUpdate}
			>
				<Stack spacing={2} marginTop={2}>
					{loading && <CircularProgress />}
					{data && (
						<>
							<Select
								label="Selecionar responsável*"
								options={data.fetchUsers.data.map((item) => ({
									label: item.name,
									value: item.id,
								}))}
								name="responsible"
							/>
							<FormControlLabel
								control={<Checkbox />}
								{...methods.register("update_all_tasks")}
								defaultChecked={false}
								label="Atualizar em todas tarefas"
								
							/>
						</>
					)}
				</Stack>
			</ModalLayout>
		</FormProvider>
	);
}
