import { useMutation, useQuery } from "@apollo/client";
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Stack,
	Divider,
	Box,
} from "@mui/material";
import { UPDATE_USER } from "graphql/mutations/updateUser";
import { DataUser } from "types/graphql/queries/fetchUsers";
import { useForm, FormProvider } from "react-hook-form";
import { useSnackbar } from "notistack";
import Input from "components/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Select } from "components/Select";
import { FETCH_PERFIS } from "graphql/queries/fetchPerfis";
import { FetchPerfis } from "types/graphql/queries/fetchPerfis";
import { FETCH_GROUPS } from "graphql/queries/fetchGroups";
import { FetchGroups } from "types/graphql/queries/fetchGroups";
import SelectFilterModal from "components/selectFilterModal";
import { GreenSwitch } from "components/GreenSwitch";

type EditDialogProps = {
	open: boolean;
	handleClose: () => void;
	data: DataUser;
	onEdit: () => void;
};

const schema = yup.object().shape({
	name: yup.string().required("Campo obrigatório"),
	email: yup.string().required("Campo obrigatório").email("Email inválido"),
	status: yup.string().required("Campo obrigatório"),
	group_ids: yup.array(yup.number()),
	perfil_id: yup.number(),
	ismobile: yup.boolean(),
	isAccumulatePoints: yup.boolean(),
});

export default function EditDialog({
	open,
	handleClose,
	data,
	onEdit,
}: EditDialogProps) {
	const methods = useForm({
		defaultValues: {
			name: data.name,
			email: data.email,
			status: data.status,
			matricula: data.params ? data.params.matricula : "",
			group_ids: data.groups.map((group) => group.id),
			perfil_id: data.perfil ? data.perfil.id : "",
			ismobile: data.is_mobile_user,
			isAccumulatePoints: data?.is_point_accumulator,
		},
		resolver: yupResolver(schema),
	});

	const { enqueueSnackbar } = useSnackbar();

	// Setting mutations
	const [updateUser, { loading }] = useMutation(UPDATE_USER);

	const { data: dataGroups } = useQuery<FetchGroups>(FETCH_GROUPS, {
		variables: {
			limit: 1000,
		},
	});

	const { data: dataPerfis } = useQuery<FetchPerfis>(FETCH_PERFIS, {
		variables: {
			limit: 1000,
			order_by: "name",
			direction: "asc"
		},
	});

	const { reset, handleSubmit, watch, setValue } = methods;

	const groupIds = watch("group_ids");

	const onSubmit = async (values: any) => {
		try {
			await updateUser({
				variables: {
					id: data.id,
					perfil_id: values.perfil_id,
					name: values.name,
					email: values.email,
					params: {
						matricula: values.matricula
					},
					status: values.status,
					group_ids: values.group_ids,
					is_point_accumulator: values.isAccumulatePoints,
					is_mobile_user: values.ismobile,
				},
			});
			onEdit();
			reset();
		} catch (err) {
			enqueueSnackbar("Erro ao editar usuário", { variant: "error" });
		}
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="paper"
				maxWidth="sm"
				fullWidth={true}
			>
				<FormProvider {...methods}>
					<DialogTitle id="scroll-dialog-title">Editar Usuário</DialogTitle>

					<DialogContent>
						<Stack spacing={2} marginTop={2}>
							<Input name="name" autoFocus placeholder="Nome*" type="text" />
							<Input name="email" placeholder="E-mail*" type="email" />
							<Input
								name="matricula"
								placeholder="Matrícula"
								type="text"
							/>
						</Stack>
						<Divider sx={{ marginY: 3 }} />
						<Stack spacing={2}>
							<Select
								marginSelect={0}
								style={{ margin: 0 }}
								label="Status*"
								name="status"
								options={[
									{ label: "Ativo", value: "Ativo" },
									{ label: "Inativo", value: "Inativo" },
									{ label: "Em Férias", value: "Em Férias" }
								]}
							/>
							{dataPerfis && (
								<Select
									label="Perfil*"
									name="perfil_id"
									options={dataPerfis.fetchPerfis.map((item) => ({
										label: item.name,
										value: item.id,
									}))}
								/>
							)}
							{dataGroups && (
								<SelectFilterModal
									title="Selecione um grupo fiscal"
									fullWidth
									onChange={(value) => {
										const idGroups = value
											.filter((item) => !item.title)
											.map((item) => item.id);
										setValue("group_ids", idGroups);
									}}
									queryName={FETCH_GROUPS}
									order_by="name"
									dataName="fetchGroups"
									valuesSelecteds={dataGroups.fetchGroups.data.filter(
										(group) => {
											return groupIds.includes(group.id);
										}
									)}
								/>
							)}
							<Box>
								<GreenSwitch name="ismobile" label="Permitir acesso mobile?" />
								<GreenSwitch
									name="isAccumulatePoints"
									label="Acumula pontuação"
								/>
							</Box>
						</Stack>
					</DialogContent>

					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Cancelar
						</Button>
						<Button
							onClick={handleSubmit(onSubmit)}
							variant="contained"
							color="primary"
							disabled={loading}
						>
							{loading && (
								<CircularProgress size={14} style={{ marginRight: 12 }} />
							)}
							Editar Usuário
						</Button>
					</DialogActions>
				</FormProvider>
			</Dialog>
		</>
	);
}
