import GenericInput from "components/Input/GenericInput";
import { ModalLayout } from "components/ModalLayout";
import { memo, useState } from "react";
import { ModalLayoutProps } from "types/ModalLayout";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { Edge } from "reactflow";


type ModalUpdateLabelEdgeProps = ModalLayoutProps & {
	edge?: Edge;
	handleUpdateEdge?: (edge: Edge) => void;
};

function ModalUpdateEdgeLabel({
	open,
	title,
	handleClose,
	edge,
	handleUpdateEdge,
}: ModalUpdateLabelEdgeProps) {
	const [label, setLabel] = useState(edge.label);
	const [checkBox, setCheckbox] = useState(edge?.data?.requiredAttached ? edge?.data?.requiredAttached : false);

	const setLabelValue = (value: string) => {
		setLabel(value);
	};

	const setCheckboxValue = () => {
		setCheckbox(state => !state);
	};

	const handleSubmitForm = () => {
		handleUpdateEdge({
			...edge,
			label,
			data: {
				requiredAttached: checkBox
			},
		});
	};
	return (
		<ModalLayout
			open={open}
			title={title}
			handleClose={handleClose}
			handleSubmit={() => handleSubmitForm()}
		>
			<Box marginY={2}>
				<GenericInput
					value={label}
					onChange={(e) => setLabelValue(e.target.value)}
					placeholder="Label"
				/>
			</Box>
			<Box mt={2}>
				<FormControlLabel
					control={
						<Checkbox 
							checked={checkBox}
							onChange={setCheckboxValue}
						/>
					}
					label="Anexo Obrigatório"
					/>
			</Box>
		</ModalLayout>
	);
}

export default memo(ModalUpdateEdgeLabel);
