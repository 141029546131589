import { Box } from "@mui/material";
import { Header } from "components/Header";

export function TaxGroups() {
	return (
		<Box
			width="100%"
			display="flex"
			alignSelf="stretch"
			flexDirection="column"
			sx={{
				backgroundColor: "#f3f3f3",
			}}
		>
			<Header title="Grupos Fiscais" />
		</Box>
	);
}
