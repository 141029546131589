import {
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	FormGroup,
	InputAdornment,
	Stack,
	TextField,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchInput from "components/SeachInput";
import { Chips } from "components/Chips";
import { EmptyMessage } from "components/EmptyMessage";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import useDebounce from "hooks/useDebounce";
import { FETCH_PERFIS } from "graphql/queries/fetchPerfis";
import { FetchPerfis } from "types/graphql/queries/fetchPerfis";
import Button from "components/Button";
import { useAuthContext } from "context/AuthProvider";
import { useSnackbar } from "notistack";

interface SelectFilterPerfisModalProps {
	onChange: (
		perfis: Array<{
			id: number;
			name: string;
		}>
	) => void;
	defaultPerfis: Array<{
		id: number;
		name: string;
	}>;
}

export function SelectFilterPerfisModal({
	onChange,
	defaultPerfis,
}: SelectFilterPerfisModalProps) {
	const { enqueueSnackbar } = useSnackbar();
	const { state } = useAuthContext();
	const [newValue, setNewValue] = useState(defaultPerfis);
	const [showDialog, setShowDialog] = useState(false);
	const [keyword, setKeyword] = useState("");
	const debouncedValue = useDebounce(keyword, 500);
	const { data, loading } = useQuery<FetchPerfis>(FETCH_PERFIS, {
		fetchPolicy: "network-only",
		variables: {
			keyword: debouncedValue,
			order_by: "name",
			direction: "asc",
		},
	});

	const handleClearFilter = () => {
		setNewValue([]);
		onChange([]);
		setKeyword("");
		setShowDialog(false);
	};

	const deleteItem = (id: number) => {
		if ((state.userInfo.user.perfil_id || 1) === id) {
			enqueueSnackbar(
				"Não é possível remover o perfil que está criando o relatório",
				{ variant: "warning" }
			);
			return;
		}
		setNewValue((prevState) => prevState.filter((prev) => prev.id !== id));
	};

	const handleChangeCheckbox = (e, perfil: { id: number; name: string }) => {
		const value = e.target.value;
		const index = newValue.findIndex((i) => i.name === value);

		if (index >= 0 && e.target.checked === false) {
			if ((state.userInfo.user.perfil_id || 1) === perfil.id) {
				enqueueSnackbar(
					"Não é possível remover o perfil que está criando o relatório",
					{ variant: "warning" }
				);
				return;
			}
			deleteItem(perfil.id);
		} else {
			setNewValue((prevState) => [...prevState, perfil]);
		}
	};

	const handleSelect = () => {
		onChange([...newValue]);
		setShowDialog(false);
	};

	const values =
		data &&
		data.fetchPerfis.map((element) => ({
			id: element.id,
			name: element.name,
		}));

	return (
		<>
			<TextField
				sx={{
					mt: 2,
				}}
				InputProps={{
					readOnly: true,
					endAdornment: (
						<InputAdornment position="end">
							<ArrowDropDownIcon
								style={{ color: "#787878", marginRight: -6 }}
							/>
						</InputAdornment>
					),
				}}
				InputLabelProps={{
					shrink: false,
				}}
				onClick={() => {
					setShowDialog(true);
				}}
				variant="outlined"
				fullWidth
				value={""}
				label={"Adicionar Perfis"}
			/>
			<Dialog
				open={showDialog}
				onClose={() => setShowDialog(false)}
				maxWidth="md"
			>
				<DialogTitle>{"Adicionar Perfis"}</DialogTitle>
				<DialogContent>
					<SearchInput
						typeSearch="inDialog"
						onChange={(value) => {
							setKeyword(value);
						}}
					/>

					<Stack
						spacing={2}
						flex={1}
						direction="row"
						marginTop={2}
						flexWrap="wrap"
						rowGap={2}
					>
						{newValue.map((element) => {
							return (
								<Chips
									key={element.id}
									label={element.name}
									handleDelete={() => deleteItem(element.id)}
									isDelete
									color="primary"
								/>
							);
						})}
					</Stack>

					<div
						style={{
							marginTop: 20,
							height: 300,
							overflow: "auto",
							display: "flex",
							flexDirection: "column",
						}}
					>
						{loading ? (
							<CircularProgress />
						) : (
							<>
								{data && (
									<FormGroup>
										<Stack flex={1}>
											{values && values.length > 0 ? (
												values.map((situation) => (
													<FormControlLabel
														key={situation.id}
														control={
															<Checkbox
																checked={newValue.some(
																	(i) => i.id === situation.id
																)}
																value={situation.name}
																onChange={(e) => {
																	handleChangeCheckbox(e, situation);
																}}
															/>
														}
														label={situation.name}
													/>
												))
											) : (
												<EmptyMessage message="Nenhum item encontrado" />
											)}
										</Stack>
									</FormGroup>
								)}
							</>
						)}
					</div>
				</DialogContent>

				<DialogActions>
					<Button onClick={() => handleClearFilter()} color="primary">
						Limpar
					</Button>
					<Button
						onClick={handleSelect}
						variant="contained"
						color="primary"
						disabled={loading}
					>
						Selecionar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
