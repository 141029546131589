/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable indent */
import {
	createContext,
	useContext,
	useReducer,
	useMemo,
	useEffect,
} from "react";
import { Buffer } from "buffer";
import { AuthContextType } from "types/AuthContext";
import { zendeskWidget } from "utils/loadScriptWidget";

interface IAuthProviderProps {
	children: React.ReactNode;
}

const AuthContext = createContext({} as AuthContextType);

function parseToken(token: string) {
	const content = token.split(".")[1];
	return JSON.parse(Buffer.from(content, "base64").toString());
}

function reducer(
	state: AuthContextType["state"],
	{ type, payload }: { type: string; payload: string }
) {
	switch (type) {
		case "USER_LOGIN":
			return { ...state, userInfo: parseToken(payload) };

		case "USER_LOGOUT":
			return {
				...state,
				userInfo: null,
			};
		case "UPDATE_TOKEN":
			return { ...state, userInfo: parseToken(payload) };

		default:
			throw new Error("Nenhum tipo de ação encontrado");
	}
}

const subdomain = window.location.hostname.split(".")[0];

const initialState: AuthContextType["state"] = {
	userInfo: localStorage.getItem(`@${subdomain}:token`)
		? parseToken(localStorage.getItem(`@${subdomain}:token`))
		: null,
};

const hours = 8;
const now = new Date().getTime();

export const AuthProvider = ({ children }: IAuthProviderProps) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const TOKEN_USER = localStorage.getItem(`@${subdomain}:token`);

	function handleLogout() {
		localStorage.removeItem(`@${subdomain}:token`);
		localStorage.removeItem("setupTime");
		dispatch({ type: "USER_LOGOUT", payload: "" });
		zendeskWidget.then(() => {
			// @ts-ignore
			if (zE && zE.hide) {
				// @ts-ignore
				zE.hide();
			}
		});
	}

	function handleLogin(token: string) {
		localStorage.setItem("setupTime", now + "");
		localStorage.setItem(`@${subdomain}:token`, token);
		dispatch({ type: "USER_LOGIN", payload: token });

		zendeskWidget.then(() => {
			// @ts-ignore
			if (zE && zE.show) {
				// @ts-ignore
				zE.show();
			}
		});
	}

	useEffect(() => {
		const setupTime = localStorage.getItem("setupTime");

		if (setupTime === null) {
			localStorage.setItem("setupTime", now + "");
		} else {
			if (Number(now) - Number(setupTime) > hours * 60 * 60 * 1000) {
				localStorage.removeItem("setupTime");
				localStorage.removeItem(`@${subdomain}:token`);
				window.location.reload();
			}
		}
	}, [state]);

	const value = useMemo(
		() => ({
			state,
			dispatch,
			TOKEN_USER,
			handleLogout,
			handleLogin,
		}),
		[state, TOKEN_USER, dispatch]
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
