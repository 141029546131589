import { useQuery } from "@apollo/client";
import { FETCH_DISTRICTS } from "graphql/queries/fetchDistricts";
import { FetchDistricts } from "types/graphql/queries/fetchDistricts";
import { Select } from "components/Select";
import { Stack, Grid } from "@mui/material";
import Input from "components/Input";

export function StepAdress() {

	const subdomain = window.location.hostname.split(".")[0];

	const { data } = useQuery<FetchDistricts>(FETCH_DISTRICTS, {
		variables: {
			limit: 1000,
			domain: subdomain === "localhost" ? (process.env.REACT_DEFAULT_DOMAIN || "lagoasanta") : subdomain,
		},
	});

	return (
		<>
			<Stack
				flex={1}
				maxHeight="500px"
				overflow="auto"
				sx={{ paddingY: "10px" }}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<Input name="zipcode" placeholder="CEP" type="text" />
					</Grid>
					<Grid item xs={12} sm={6}>
						<Input name="street" placeholder="Rua*" type="text" />
					</Grid>
					<Grid item xs={12} sm={6}>
						<Input name="number" placeholder="Número" type="text" />
					</Grid>
					{data && (
						<Grid item xs={12} sm={6}>
							<Select
								marginSelect={0}
								fullWidth
								label="Bairro"
								name="district_id"
								options={data.fetchDistricts.data.map((district) => ({
									title: district.name,
									value: district.id,
								}))}
							/>
						</Grid>
					)}
					<Grid item xs={12} sm={12}>
						<Input
							name="complement"
							placeholder="Ponto de referência"
							type="text"
						/>
					</Grid>
				</Grid>
			</Stack>
		</>
	);
}
