import { Box, Fab, FabProps } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CircularProgress } from "components/CircularProgress";

type FabButtonProps = FabProps & {
	isLoading?: boolean;
};

export function VisualizationFabButton({ isLoading, ...rest }: FabButtonProps) {
	return (
		<Box position="fixed" bottom={100} right={50}>
			<Fab color="inherit" aria-label="add" {...rest} disabled={isLoading}>
				{isLoading ? <CircularProgress /> : <VisibilityIcon color="action" />}
			</Fab>
		</Box>
	);
}
