import { gql } from "@apollo/client";

export const FETCH_FLOWS = gql`
	query ($keyword: String, $order_by: String, $direction: String, $name: String, $page: Int, $limit: Int, $status: String) {
		fetchFlows(keyword: $keyword, order_by: $order_by, direction: $direction, name: $name, page: $page, limit: $limit, status: $status) {
			total
			current_page
			per_page
			last_page
			data {
				id
				name
				description
				version
				status
				created_at
			}
		}
	}
`;
