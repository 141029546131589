import { useState } from "react";
import { ModalLayout } from "components/ModalLayout";
import { useNavigate } from "react-router-dom";
import {
	Stack,
	Typography,
} from "@mui/material";
import Input from "components/Input";
import { useForm, FormProvider } from "react-hook-form";

type ModalFollowUpProps = {
	open: boolean;
	handleClose: () => void;
};

export function ModalFollowUp({
	open,
	handleClose,
}: ModalFollowUpProps) {
	const methods = useForm({
		defaultValues: {
			protocols: "",
		},
	});

	const [value, setValue] = useState("");

	const { reset, handleSubmit } = methods;

	const navigate = useNavigate();

	const onSubmit = (value: any) => {
		handleClose
		navigate("/webdenuncia/acompanhar-denuncia/" + value.protocols);
	}

	return (
		<>
			<FormProvider {...methods}>
				<ModalLayout
					open={open}
					handleClose={handleClose}
					handleSubmit={handleSubmit(onSubmit)}
					title="Acompanhar denúncia"
					buttonText="Acompanhar"
				>
					<Stack sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "30px"}}>
						<Stack spacing={3} sx={{  maxWidth: "450px"}}>
							<Typography variant="body1" sx={{ fontSize: "1.1rem", textAlign: "center", color: "#000" }}>
								Insira abaixo o número de protocolo da denúncia para acompanhar o seu status
							</Typography>
							<Stack sx={{ alignSelf: "center" }}>
								<Input sx={{ maxWidth: "300px"}} name="protocols" autoFocus placeholder="N° de protocolo" type="text" />
							</Stack>
						</Stack>
					</Stack>
				</ModalLayout>
			</FormProvider>
		</>
	);
}
