import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Button from 'components/Button';

interface ConfirmDialogProps {
    open: boolean;
    title: string;
    description: string;
    onClose: () => void;
    onConfirm: () => void;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    open,
    title,
    description,
    onClose,
    onConfirm
}: ConfirmDialogProps) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="confirm-dialog-title"
        >
            <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {description}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant='text'>
                    Cancelar
                </Button>
                <Button onClick={onConfirm} autoFocus variant='contained'>
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
};