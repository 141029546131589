import { useMutation } from "@apollo/client";
import { DELETE_STATUS } from "graphql/mutations/deleteStatus";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";
import { FetchStatus } from "types/graphql/queries/fetchStatus";

type DeleteDialogProps = {
	open: boolean;
	handleClose: () => void;
	onDelete: () => void;
	data: any;
}

export default function DeleteDialog({ open, handleClose, onDelete, data }: DeleteDialogProps) {

	// Setting mutation
	const [deleteStatus, { loading: loadingDelete }] = useMutation(
		DELETE_STATUS
	);

	// Chamando a mutation de remoção
	const handleDelete = () => {
		deleteStatus({
			variables: {
				id: data.id,
			},
		}).then(() => {
			onDelete();
		});
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="paper"
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogTitle id="scroll-dialog-title">Excluir status</DialogTitle>

				<DialogContent>

					<p>Deseja realmente excluir o status?</p>

				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancelar
					</Button>
					<Button onClick={handleDelete} variant="contained" color="primary" disabled={loadingDelete}>
						{loadingDelete && <CircularProgress size={14} style={{ marginRight: 12 }} />}
						Excluir
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}
