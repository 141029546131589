export const dataSource = [
	{
		label: "Protocolos",
		value: "Protocolos",
	},
	{
		label: "Tarefas",
		value: "Tarefas",
	},
];

export const headersProtocols = {
	code: "Código",
	created_at: "Data de criação",
	subjectName: "Assunto",
	street: "Endereço",
	responsibleName: "Responsável",
	expire_at: "Data de expiração",
	situation: "Situação",
	groupName: "Grupo",
	concluded_at: "Concluído em",
	upf: "UPFMLS",
	taskPoints: "Pontuação",
	responsibleRegistration: "Matricula",
	updated_at: "Data de descontinuação"
};

export const dataColumnsProtocols = [
	{
		code: "001",
		created_at: "2023-06-01",
		subjectName: "Assunto 1",
		street: "Endereço 1",
		responsibleName: "Usuário 1",
		expire_at: "2023-06-15",
		situation: "Situação 1",
		groupName: "Grupo 1",
		concluded_at: null,
		upf: 5.2,
		taskPoints: Math.floor(Math.random() * 1000),
		responsibleRegistration: Math.floor(Math.random() * 100000),
		updated_at: "2023-06-02"
	},
	{
		code: "002",
		created_at: "2023-06-02",
		subjectName: "Assunto 2",
		street: "Endereço 2",
		responsibleName: "Usuário 2",
		expire_at: "2023-06-20",
		situation: "Situação 2",
		groupName: "Grupo 2",
		concluded_at: "2023-06-18",
		upf: 7.8,
		taskPoints: Math.floor(Math.random() * 1000),
		responsibleRegistration: Math.floor(Math.random() * 100000),
		updated_at: "2023-06-05"
	},
	{
		code: "003",
		created_at: "2023-06-03",
		subjectName: "Assunto 3",
		street: "Endereço 3",
		responsibleName: "Usuário 3",
		expire_at: "2023-06-25",
		situation: "Situação 3",
		groupName: "Grupo 1",
		concluded_at: null,
		upf: 4.5,
		taskPoints: Math.floor(Math.random() * 1000),
		responsibleRegistration: Math.floor(Math.random() * 100000),
		updated_at: "2013-03-04"
	},
];

export const columnsProtocol = [
	{
		label: "Código",
		value: "code",
	},
	{
		label: "Data de criação",
		value: "created_at",
	},
	{
		label: "Assunto",
		value: "subjectName",
	},
	{
		label: "Endereço",
		value: "street",
	},
	{
		label: "Responsável",
		value: "responsibleName",
	},
	{
		label: "Data de expiração",
		value: "expire_at",
	},
	{
		label: "Situação",
		value: "situation",
	},
	{
		label: "Grupo",
		value: "groupName",
	},
	{
		label: "Concluído em",
		value: "concluded_at",
	},
	{
		label: "UPFMLS",
		value: "upf",
	},
	{
		label: "Pontuação",
		value: "taskPoints"
	},
	{
		label: "Matrícula",
		value: "responsibleRegistration"
	},
	{
		label: "Data de descontinuação",
		value: "updated_at"
	}
];

export const headersTasks = {
	userName: "Responsável",
	perfilName: "Perfil",
	statusName: "Status",
	name: "Nome",
	points: "Pontos",
	created_at: "Data de criação",
	concluded_at: "Data de conclusão",
	expire_at: "Data de expiração",
};

export const dataColumnsTasks = [
	{
		userName: "Responsável 1",
		perfilName: "Perfil 1",
		statusName: "Status 1",
		name: "Nome 1",
		points: 10,
		created_at: "2023-06-01",
		concluded_at: "2023-06-10",
		expire_at: "2023-06-30",
	},
	{
		userName: "Responsável 2",
		perfilName: "Perfil 2",
		statusName: "Status 2",
		name: "Nome 2",
		points: 5,
		created_at: "2023-06-01",
		concluded_at: null,
		expire_at: "2023-07-15",
	},
	{
		userName: "Responsável 3",
		perfilName: "Perfil 1",
		statusName: "Status 3",
		name: "Nome 3",
		points: 8,
		created_at: "2023-06-01",
		concluded_at: "2023-06-20",
		expire_at: "2023-06-25",
	},
];

export const columnsTasks = [
	{
		label: "Responsável",
		value: "userName",
	},
	{
		label: "Perfil",
		value: "perfilName",
	},
	{
		label: "Status",
		value: "statusName",
	},
	{
		label: "Nome",
		value: "name",
	},
	{
		label: "Pontos",
		value: "points",
	},
	{
		label: "Data de criação",
		value: "created_at",
	},
	{
		label: "Data de conclusão",
		value: "concluded_at",
	},
	{
		label: "Data de expiração",
		value: "expire_at",
	},
];

export const groupByProtocols = [
	{
		label: "Data de criação",
		value: "created_at",
	},
	{
		label: "Assunto",
		value: "subject",
	},
	{
		label: "Situação",
		value: "situation",
	},
	{
		label: "Responsável",
		value: "user",
	},
	{
		label: "Data de expiração",
		value: "expire_at",
	},
	{
		label: "Grupo",
		value: "group_id",
	},
	{
		label: "concluido em",
		value: "concluded_at",
	},
];
export const groupByTasks = [
	{
		label: "Responsável",
		value: "user",
	},
	{
		label: "Perfil",
		value: "perfil",
	},
	{
		label: "Status",
		value: "status",
	},
	{
		label: "Nome",
		value: "name",
	},
	{
		label: "concluido em",
		value: "concluded_at",
	},
	{
		label: "Data de expiração",
		value: "expire_at",
	},
];

export const orderBy = [
	{
		label: "Ordem alfabética",
		value: "name",
	},
	{
		label: "Ordem criação",
		value: "created_at",
	},
];

export const orderDirection = [
	{
		label: "Crescente",
		value: "asc",
	},
	{
		label: "Decrescente",
		value: "desc",
	},
];

export const visualizationRules = [
	{
		label: "Somente eu visualizo",
		value: "Eu",
	},
	{
		label: "Todos visualizam",
		value: "Todos",
	},
	{
		label: "Compartilhar com um perfil específico",
		value: "Perfis",
	},
];
