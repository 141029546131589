import React, { useState } from "react";
import {
	TablePagination,
	IconButton,
	TextField,
	Box,
	Divider,
	useMediaQuery,
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LocalProvider from "components/LocalProvider";

type PaginationProps = {
	count: number;
	rowsPerPage: number;
	page: number;
	onPageChange: (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => void;
	onRowsPerPageChange: React.ChangeEventHandler<
		HTMLTextAreaElement | HTMLInputElement
	>;
};

const ActionsComponent: React.FC<any> = ({
	count,
	page,
	rowsPerPage,
	onPageChange,
	showFirstButton,
	showLastButton,
}) => {
	const totalPages = Math.ceil(count / rowsPerPage);
	const [pageNum, setPageNum] = useState(page + 1);
	const [isInvalidPage, setIsInvalidPage] = useState(false);
	const media889 = useMediaQuery("(max-width:889px)");

	const handleChange = (value: string) => {
		setPageNum(value);
		setIsInvalidPage(false);
	};

	const handleTextFieldKeyDown = (
		event: React.KeyboardEvent<HTMLInputElement>
	) => {
		if (event.key === "Enter") {
			event.preventDefault();
			const newPage = Number(pageNum);

			if (!Number.isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
				onPageChange(null, newPage - 1);
			} else {
				setIsInvalidPage(true);
			}
		}
	};

	return (
		<Box
			style={{
				display: "flex",
				alignItems: "center",
				marginLeft: "10px",
				justifyContent: "center",
				color: "white",
			}}
		>
			{!media889 && (
				<Divider
					orientation="vertical"
					sx={{ height: 50, mx: 1, bgcolor: "primary.main", width: "2px" }}
				/>
			)}

			{showFirstButton && (
				<IconButton
					onClick={() => onPageChange(null, 0)}
					disabled={page === 0}
					aria-label="Primeira página"
					sx={{
						color: "inherit",
						"&:hover": {
							color: "white",
						},
					}}
				>
					<FirstPageIcon />
				</IconButton>
			)}

			<IconButton
				onClick={() => onPageChange(null, page - 1)}
				disabled={page === 0}
				aria-label="Página anterior"
				sx={{
					color: "inherit",
					"&:hover": {
						color: "white",
					},
				}}
			>
				<KeyboardArrowLeft />
			</IconButton>

			<TextField
				value={pageNum}
				onChange={(evt) => handleChange(evt.target.value)}
				onKeyDown={handleTextFieldKeyDown}
				type="number"
				variant="outlined"
				sx={{
					justifyContent: "center",
					height: "40px",
					"& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input, & .css-1x5jdmq, & input[type=number], & .MuiInputBase-input":
						{
							padding: "5px 5px",
							width: "40px",
							fontSize: "14px",
							textAlign: "center",
							color: "#FFF",
							border: "none",
							backgroundColor: isInvalidPage ? "#ff4040" : "#025ebb",
						},
					"& .MuiTablePagination-root, & .css-m8e8i3": {
						overflow: "unset",
					},
					"& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
						{
							display: "none",
						},
				}}
			/>

			<span style={{ marginLeft: "8px", width: "65px" }}>
				de <span style={{ marginLeft: "5px" }}>{totalPages}</span>
			</span>

			<IconButton
				onClick={() => onPageChange(null, page + 1)}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="Próxima página"
				sx={{
					color: "inherit",
					"&:hover": {
						color: "white",
					},
				}}
			>
				<KeyboardArrowRight />
			</IconButton>

			{showLastButton && (
				<IconButton
					onClick={() => onPageChange(null, Math.ceil(count / rowsPerPage) - 1)}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="Última página"
					sx={{
						color: "inherit",
						"&:hover": {
							color: "white",
						},
						mr: "6px",
					}}
				>
					<LastPageIcon />
				</IconButton>
			)}
		</Box>
	);
};

const PaginationStorage: React.FC<PaginationProps> = ({
	count,
	rowsPerPage,
	page,
	onPageChange,
	onRowsPerPageChange,
}) => {
	const handleRowsPerPageChange = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		const newRowsPerPage = parseInt(event.target.value);
		onRowsPerPageChange(event);

		const rowsPerPageFromStorage =
			JSON.parse(localStorage.getItem("rowsPerPage")) || {};
		localStorage.setItem(
			"rowsPerPage",
			JSON.stringify({
				...rowsPerPageFromStorage,
				tasksToday: newRowsPerPage,
			})
		);

		const pageFromStorage = JSON.parse(localStorage.getItem("page")) || {};
		localStorage.setItem(
			"page",
			JSON.stringify({
				...pageFromStorage,
				tasksToday: 0,
			})
		);
	};
	const media889 = useMediaQuery("(max-width:889px)");
	return (
		<LocalProvider locale="ptBR">
			<Box
				sx={{
					width: "max-content",
				}}
			>
				<TablePagination
					showLastButton
					showFirstButton
					labelRowsPerPage={!media889 && "Linhas por página:"}
					rowsPerPageOptions={[5, 15, 30, 60, 100]}
					component="div"
					count={count}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={onPageChange}
					onRowsPerPageChange={handleRowsPerPageChange}
					SelectProps={{
						style: {
							display: media889 && "none",
							padding: 0,
							marginLeft: 0,
							marginRight: 0,
						},
					}}
					ActionsComponent={ActionsComponent}
					sx={{
						"& .MuiTablePagination-caption": {
							color: "white",
						},
						"& .MuiTablePagination-toolbar": {
							color: "white",
						},
						"& .MuiTablePagination-displayedRows": {
							display: "none",
						},
					}}
				/>
			</Box>
		</LocalProvider>
	);
};

export default PaginationStorage;
