import {
    Box,
    TableRow,
    TableHead,
    TableContainer,
    TablePagination,
    TableCell,
    TableBody,
    Table,
    TableSortLabel,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { Title } from "components/Title";
import { Item } from "components/Paper";
import { EmptyMessage } from "components/EmptyMessage";
import noTaskToday from "assets/img/noTaskToday.png";

import { format } from "date-fns";
import { Chips } from "components/Chips";
import HoverLink from "components/HoverLink";
import { getTheme } from "utils/protocolStatus";
import invertColor from "utils/invertColor";
import PaginationStorage from "components/PaginationStorage";
import { useState } from "react";
import { FetchTasks } from "types/graphql/queries/fetchTasks";

type ListSuspendedProps = {
    fetchTasksSuspended: FetchTasks["fetchTasks"]["data"];
    currentPageSuspended: number;
    setCurrentPageSuspended: React.Dispatch<React.SetStateAction<number>>;
    rowsPerPageSuspended: number;
    setRowsPerPageSuspended: React.Dispatch<React.SetStateAction<number>>;
    setOrderByAll: React.Dispatch<React.SetStateAction<string>>;
    setDirectionAll: React.Dispatch<React.SetStateAction<"desc" | "asc">>;
    orderByAll: string;
    directionAll: "desc" | "asc";
    total: number;
};

export default function ListSuspended({
    currentPageSuspended,
    setCurrentPageSuspended,
    rowsPerPageSuspended,
    setRowsPerPageSuspended,
    fetchTasksSuspended,
    total,
    orderByAll,
    setOrderByAll,
    directionAll,
    setDirectionAll,
}: ListSuspendedProps) {
    const [showPagination, setShowPagination] = useState(false);

    const media889 = useMediaQuery("(max-width:889px)");
    const media500 = useMediaQuery("(max-width:500px)");

    const handleSort = (name) => {
        const isAsc = orderByAll === name && directionAll === "asc";
        setDirectionAll(isAsc ? "desc" : "asc");
        setOrderByAll(name);
    };

    return (
        <div
            style={{ position: "relative", marginBottom: "60px" }}
            onMouseEnter={() => setShowPagination(true)}
            onMouseLeave={() => setShowPagination(false)}
        >
            <Item
                sx={{
                    paddingX: 0,
                    position: "relative",
                    marginBottom: "20px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingX: "16px",
                    }}
                >
                    <Title title="Tarefas suspensas" />
                </Box>
                <TableContainer style={{ marginTop: "20px" }}>
                    {fetchTasksSuspended.length === 0 && (
                        <>
                            <EmptyMessage message="Nenhuma tarefa suspensa" />
                            <Box
                                marginTop={2}
                                sx={{
                                    backgroundImage: `url(${noTaskToday})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    backgroundSize: "contain",
                                    height: "100px",
                                }}
                            />
                        </>
                    )}

                    {fetchTasksSuspended.length !== 0 && (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderByAll === "tasks.expire_at"}
                                            direction={
                                                orderByAll === "tasks.expire_at" ? directionAll : "desc"
                                            }
                                            onClick={() => handleSort("tasks.expire_at")}
                                        >
                                            Nº Protocolo
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Tarefa atual</TableCell>
                                    <TableCell>Assunto</TableCell>
                                    <TableCell>Endereço</TableCell>
                                    <TableCell>Situação do protocolo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fetchTasksSuspended.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            <HoverLink
                                                href={`/protocolo/${row.protocol.code}?from=task`}
                                            >
                                                {row.protocol.code}
                                            </HoverLink>
                                            <Typography
                                                variant="caption"
                                                sx={{ display: "flex", flexDirection: "column" }}
                                            >
                                                <Stack
                                                    spacing={2}
                                                    direction="row"
                                                    flex={1}
                                                    alignItems="center"
                                                >
                                                    Prazo resolução:{" "}
                                                    {!row.expire_at && <Typography>-</Typography>}
                                                    {row.expire_at &&
                                                        format(new Date(row.expire_at), "dd/MM/yyyy")}
                                                </Stack>
                                                {row.info && (
                                                    <Chips label={row.info} theme={row.theme} />
                                                )}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <b>{row.name + "\n"}</b>
                                            <br />
                                            <Typography variant="caption">
                                                Responsável da tarefa:{" "}
                                                {row.user ? row.user.name : row.perfil.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {row.protocol?.subject
                                                ? row.protocol?.subject.name
                                                : "-"}
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle2" fontWeight={400}>
                                                {row.street ? row.street : "-"}{" "}
                                                {row.number
                                                    ? (row.street ? ", " : "") + "nº" + row.number
                                                    : null}{" "}
                                                {row.district ? row.district : null}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Chips
                                                label={row.protocol.situation}
                                                style={{
                                                    backgroundColor: getTheme(row.protocol.situation),
                                                    color: invertColor(
                                                        getTheme(row.protocol.situation)
                                                    ),
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </TableContainer>
                {fetchTasksSuspended.length !== 0 &&
                    (media500 ? (
                        <TablePagination
                            sx={{
                                background: "#fff",
                            }}
                            component="div"
                            labelRowsPerPage="Linhas por página:"
                            rowsPerPageOptions={[5, 15, 30, 60, 100]}
                            count={total}
                            page={currentPageSuspended}
                            onPageChange={(event, newPage) => {
                                setCurrentPageSuspended(newPage);
                            }}
                            rowsPerPage={rowsPerPageSuspended}
                            onRowsPerPageChange={(event) => {
                                setRowsPerPageSuspended(parseInt(event.target.value));
                                setCurrentPageSuspended(0);
                            }}
                        />
                    ) : (
                        <Box
                            sx={{
                                position: "fixed",
                                bottom: "30px",
                                left: media889 ? "50%" : "60%",
                                transform: "translateX(-50%)",
                                padding: "0px",
                                backgroundColor: "rgba(25, 118, 210, 0.98)",
                                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                                opacity: showPagination ? 0.95 : 0,
                                zIndex: 2,
                                borderRadius: "10px",
                                transition: "opacity 0.3s ease-in-out",
                            }}
                        >
                            <PaginationStorage
                                count={total}
                                rowsPerPage={rowsPerPageSuspended}
                                page={currentPageSuspended}
                                onPageChange={(event, newPage) => {
                                    setCurrentPageSuspended(newPage);
                                }}
                                onRowsPerPageChange={(event) => {
                                    setRowsPerPageSuspended(parseInt(event.target.value));
                                    setCurrentPageSuspended(0);
                                }}
                            />
                        </Box>
                    ))}
            </Item>
        </div>
    );
}
