import { gql } from "@apollo/client";

export const FETCH_PERFIS = gql`
	query ($keyword: String, $order_by: String, $direction: String) {
		fetchPerfis(keyword: $keyword, order_by: $order_by, direction: $direction) {
			id
			company_id
			perfil_id
			name
			restrictions {
				id
				name
				code
			}
			created_at
		}
	}
`;
