import { gql } from "@apollo/client";

export const ADD_RELATED_PROTOCOL = gql`
    mutation (
        $protocol_code: String!
        $related_protocol_codes: [String]!
    ) {
        addRelatedProtocol(
            protocol_code: $protocol_code
            related_protocol_codes: $related_protocol_codes
        )
    }
`