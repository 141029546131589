import { Box, Typography } from "@mui/material";

interface EmptyMessageProps {
	title?: string;
	message: string;
}

export function EmptyMessage({ message, title }: EmptyMessageProps) {
	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			height="100%"
			width="100%"
			flexDirection="column"
		>
			<Typography variant="body1">{title}</Typography>
			<Typography variant="body1">{message}</Typography>
		</Box>
	);
}
