import { Animation } from "@devexpress/dx-react-chart";
import {
	ArgumentAxis,
	Chart,
	Legend,
	LineSeries,
	ValueAxis,
} from "@devexpress/dx-react-chart-material-ui";
import { Grid, styled, Typography } from "@mui/material";

const format = () => (tick: string) => tick;

const Root = (props: Legend.RootProps) => <Grid container {...props} />;
const Label = (props: Legend.LabelProps) => (
	<Legend.Label sx={{ pt: 1, whiteSpace: "nowrap" }} {...props} />
);
const Item = (props: Legend.ItemProps) => (
	<Grid item xs>
		<Legend.Item {...props} sx={{ p: 0 }} />
	</Grid>
);

const ValueLabel = (props: ValueAxis.LabelProps) => {
	const { text } = props;
	return <ValueAxis.Label {...props} text={`${text}`} />;
};

interface VariantSimpleLineProps {
	title: string;
	label: string;
	chartData: Array<{
		[key: string]: number;
	}>;
	series: Array<{
		name: string;
		valueField: string;
		argumentField: string;
	}>;
	medias?: { [key: string]: boolean };
}

export function VariantSimpleLine({
	title = "",
	label = "",
	chartData = [],
	series = [],
	medias
}: VariantSimpleLineProps) {
	const StyledChart = styled(Chart)(() => ({
		paddingRight: "20px",
	}));
	return (
		<>
			<Typography variant="h6">{title}</Typography>
			<Typography variant="caption">{label}</Typography>
			<StyledChart data={chartData} height={300}>
				<ArgumentAxis tickFormat={format} />
				<ValueAxis labelComponent={ValueLabel} />

				{series.map((serie) => (
					<LineSeries
						name={serie.name}
						valueField={serie.valueField}
						argumentField={serie.argumentField}
						key={serie.valueField}
					/>
				))}

				<Legend
					position="bottom"
					rootComponent={Root}
					itemComponent={Item}
					labelComponent={Label}
				/>
				<Animation />
			</StyledChart>
		</>
	);
}
