import { gql } from "graphql-tag";

export const NEXT_USER_COMPLAINT = gql`
	query ($group_id: Int!, $subject_id: Int!, $status: String) {
		nextUserComplaint(
			group_id: $group_id
			subject_id: $subject_id
			status: $status
		)
	}
`;
