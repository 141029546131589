import { useState } from "react";
import { useMutation } from "@apollo/client";
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Stack,
} from "@mui/material";
import { CREATE_DISTRICT } from "graphql/mutations/createDistrict";
import { useForm, FormProvider } from "react-hook-form";
import { useSnackbar } from "notistack";
import Input from "components/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

type CreateDistrictDialogProps = {
	open: boolean;
	data: any;
	handleClose: () => void;
	onCreate: () => void;
};

const schema = yup.object().shape({
	name: yup.string().required("Campo obrigatório"),
});

export default function CreateDistrictDialog({
	open,
	data,
	handleClose,
	onCreate,
}: CreateDistrictDialogProps) {
	const methods = useForm({
		defaultValues: {
			name: "",
			latitude: "",
			longitude: "",
		},
		resolver: yupResolver(schema),
	});

	const { enqueueSnackbar } = useSnackbar();

	// Setting mutation
	const [createDistrict, { loading }] = useMutation(CREATE_DISTRICT);

	const { reset, handleSubmit } = methods;

	const onSubmit = async (values: any) => {
		try {
			await createDistrict({
				variables: {
					name: values.name,
					latitude: values.latitude,
					longitude: values.longitude,
					type: values.type,
					city_id: data.fetchDistricts.data[0].city.id,
				},
			});
			onCreate();
			reset({
				name: "",
			});
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="paper"
				maxWidth="sm"
				fullWidth={true}
			>
				<FormProvider {...methods}>
					<DialogTitle id="scroll-dialog-title">Criar Bairro</DialogTitle>

					<DialogContent>
						<Stack spacing={2} marginTop={2}>
							<Input name="name" autoFocus placeholder="Nome*" type="text" />
							<Input name="latitude" placeholder="Latitude" type="text" />
							<Input name="longitude" placeholder="Longitude" type="text" />
						</Stack>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Cancelar
						</Button>
						<Button
							onClick={handleSubmit(onSubmit)}
							variant="contained"
							color="primary"
							disabled={loading}
						>
							{loading && (
								<CircularProgress size={14} style={{ marginRight: 12 }} />
							)}
							Criar
						</Button>
					</DialogActions>
				</FormProvider>
			</Dialog>
		</>
	);
}
