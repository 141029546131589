/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import {
	Box,
	Container,
	Grid,
	Stack,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
	useMediaQuery,
	Pagination,
	TablePagination,
} from "@mui/material";
import { Counter } from "./Counter";
import { Header } from "components/Header";
import { ListProtocols } from "./ListProtocols";
import { ExportCSV } from "components/ExportCSV";
import { AddFabButton } from "components/AddFabButton";
import { useQuery } from "@apollo/client";
import { FETCH_PROTOCOLS } from "graphql/queries/fetchProtocols";
import { FetchProtocols } from "types/graphql/queries/fetchProtocols";
import { CircularProgress } from "components/CircularProgress";
import { useState, useEffect } from "react";
import { ModalCreateProtocol } from "./Modal";
import { DialogFilter } from "components/DialogFilter";
import { Item } from "components/Paper";
import Button from "components/Button";
import { Title } from "components/Title";
import { FETCH_DETAIL_COMPANY } from "graphql/queries/fetchDetailCompany";
import { FetchDetailCompany } from "types/graphql/queries/fetchDetailCompany";
import { useLocation, useNavigate } from "react-router-dom";
import { useVerifyNoHasRestriction } from "utils/useVerifyNoHasRestriction";
import { FetchTasksLabelList } from "types/graphql/queries/fetchTasksLabelList";
import { FETCH_TASKS_LABEL_LIST } from "graphql/queries/fetchTasksLabelList";
import PaginationStorage from "components/PaginationStorage";

export function Protocol() {
	const pageFromStorage: {
		[key: string]: number;
	} = JSON.parse(localStorage.getItem("page")) || 0;
	const rowsPerPageFromStorage: {
		[key: string]: number;
	} = JSON.parse(localStorage.getItem("rowsPerPage")) || 15;

	const [rowsPerPage, setRowsPerPage] = useState(
		rowsPerPageFromStorage.protocol || 15
	);
	const [showPagination, setShowPagination] = useState(false);

	const subdomain = window.location.hostname.split(".")[0];
	const noHasRestrictionCreateProtocol =
		useVerifyNoHasRestriction("PROTOCOLO_CRIAR");

	const navigate = useNavigate();
	const { data: dataTasks } = useQuery<FetchTasksLabelList>(
		FETCH_TASKS_LABEL_LIST
	);

	const dataTasksWithId = dataTasks?.fetchTasksLabelList.labels.map(
		(item, index) => ({
			id: index,
			name: item,
		})
	);

	const [page, setPage] = useState(pageFromStorage.protocolo || 0);
	const [street, setStreet] = useState("");
	const [number, setNumber] = useState("");
	const [upfmls, setUpfmls] = useState(null);
	const [lateDays, setLateDays] = useState(null);
	const [lote, setLote] = useState("");
	const [quadra, setQuadra] = useState("");
	const [cadastralIndex, setCadastralIndex] = useState("");
	const [offendersName, setOffendersName] = useState("");
	const [filters, setFilters] = useState([
		{
			id: 1,
			title: "Situação",
			options: [],
		},
		{
			id: 2,
			title: "Assunto",
			options: [],
		},
		{
			id: 4,
			title: "Grupo responsável",
			options: [],
		},
		{
			id: 5,
			title: "Status",
			options: [],
		},
		{
			id: 6,
			title: "Responsável",
			options: [],
		},
		{
			id: 7,
			title: "Bairro",
			options: [],
		},
		{
			id: 8,
			title: "Tarefa atual",
			options: [],
		},
	]);
	const [startDate, setStartDate] = useState(null);
	const [finishDate, setFinishDate] = useState(null);
	const [orderBy] = useState("protocols.created_at");
	const [direction, setDirection] = useState("desc");
	const [keyword, setKeyword] = useState("");

	const { data: dataCompany } = useQuery<FetchDetailCompany>(
		FETCH_DETAIL_COMPANY,
		{
			variables: {
				domain:
					subdomain === "localhost"
						? process.env.REACT_DEFAULT_DOMAIN || "lagoasanta"
						: subdomain,
			},
		}
	);
	const { search } = useLocation();
	const paramsFormatted = Object.fromEntries(new URLSearchParams(search));
	const situationParsed = JSON.parse(paramsFormatted.situation || "[]").map(
		(item, index) => {
			const isLastIndex =
				index + 1 === JSON.parse(paramsFormatted.situation || "[]").length;
			if (index === 0 || isLastIndex) {
				return item;
			}

			if (Array.isArray(item)) {
				return item.map((item) => item.id);
			}
			return item;
		}
	);

	const SITUATIONS_VALUE =
		situationParsed.length > 0 && situationParsed[0] !== ""
			? situationParsed[0]
			: null;

	const SUBJECTS_VALUE =
		situationParsed.length > 0 && situationParsed[1].length > 0
			? situationParsed[1]
			: null;
	const GROUP_IDS =
		situationParsed.length > 0 && situationParsed[2].length > 0
			? situationParsed[2]
			: null;

	const STATUS_IDS =
		situationParsed.length > 0 && situationParsed[3].length > 0
			? situationParsed[3]
			: null;

	const RESPONSIBLES_IDS =
		situationParsed.length > 0 && situationParsed[4].length > 0
			? situationParsed[4]
			: null;

	const DISTRICT_IDS =
		situationParsed.length > 0 && situationParsed[5].length > 0
			? situationParsed[5]
			: null;

	const TASKS_VALUE =
		situationParsed.length > 0 && situationParsed[6] !== ""
			? situationParsed[6]
			: null;

	const startDateParams = paramsFormatted
		? paramsFormatted.startDate === "null"
			? null
			: paramsFormatted.startDate
		: null;

	const finishDateParams = paramsFormatted
		? paramsFormatted.finishDate === "null"
			? null
			: paramsFormatted.finishDate
		: null;

	const upfmlsParams = paramsFormatted
		? paramsFormatted.upfmls === "null"
			? null
			: paramsFormatted.upfmls
		: null;

	const lateDaysParams = paramsFormatted
		? paramsFormatted.lateDays === "null"
			? null
			: paramsFormatted.lateDays
		: null;

	const streetParams = paramsFormatted ? paramsFormatted.street : "";

	const numberParams = paramsFormatted ? paramsFormatted.number : "";

	const keywordParams = paramsFormatted ? paramsFormatted.keyword : "";

	const loteParams = paramsFormatted ? paramsFormatted.lote : "";

	const quadraParams = paramsFormatted ? paramsFormatted.quadra : "";

	const cadastralIndexParams = paramsFormatted
		? paramsFormatted.cadastralIndex
		: "";

	const offendersNameParams = paramsFormatted
		? paramsFormatted.offendersName
		: "";

	const { data, loading, refetch } = useQuery<FetchProtocols>(FETCH_PROTOCOLS, {
		fetchPolicy: "network-only",
		variables: {
			page: page,
			limit: rowsPerPage,
			start: startDateParams,
			finish: finishDateParams,
			order_by: orderBy,
			direction: direction,
			keyword: keywordParams,
			situations: SITUATIONS_VALUE,
			tasks: TASKS_VALUE,
			subject_ids: SUBJECTS_VALUE,
			group_ids: GROUP_IDS,
			status_ids: STATUS_IDS,
			responsible_ids: RESPONSIBLES_IDS,
			district_ids: DISTRICT_IDS,
			upfmls:
				typeof upfmlsParams === "string"
					? JSON.parse(upfmlsParams.toLowerCase())
					: upfmlsParams,
			late_days: lateDays,
			street: streetParams,
			number: numberParams,
			lote: loteParams,
			quadra: quadraParams,
			cadastral_index: cadastralIndexParams,
			offenders_name: offendersNameParams,
		},
	});

	const [isOpenModalCreateProtocol, setIsOpenModalCreateProtocol] =
		useState(false);
	const [isOpenModalFilter, setIsOpenModalFilter] = useState(false);

	const handleOpenModalCreateProtocols = () => {
		setIsOpenModalCreateProtocol(true);
	};

	const handleCloseModalCreateProtocols = () => {
		setIsOpenModalCreateProtocol(false);
	};

	const handleOpenModalFilter = () => {
		setIsOpenModalFilter(true);
	};

	const handleClose = () => {
		setIsOpenModalFilter(false);
	};

	const handleChangePage = (
		event: React.ChangeEvent<unknown>,
		value: number
	) => {
		setPage(value);
		const pageFromStorage: {
			[key: string]: number;
		} = JSON.parse(localStorage.getItem("page")) || 0;
		localStorage.setItem(
			"page",
			JSON.stringify({
				...pageFromStorage,
				protocolo: value,
			})
		);
	};

	const handleFilter = (values) => {
		handleChangePage(null, 1);
		setKeyword(values.keyword);
		setFilters(values.situation);
		setStartDate(values.startDate);
		setFinishDate(values.finishDate);
		setUpfmls(values.upfmls);
		setLateDays(values.lateDays);
		setStreet(values.street);
		setNumber(values.number);
		setLote(values.lote);
		setQuadra(values.quadra);
		setCadastralIndex(values.cadastralIndex);
		setOffendersName(values.offendersName);
		const data = {
			keyword: values.keyword,
			startDate: values.startDate ? values.startDate : null,
			finishDate: values.finishDate ? values.finishDate : null,
			upfmls: values.upfmls,
			lateDays: values.lateDays,
			street: values.street,
			number: values.number,
			lote: values.lote,
			quadra: values.quadra,
			cadastralIndex: values.cadastralIndex,
			offendersName: values.offendersName,
			situation: JSON.stringify(
				values.situation.map((situation) => {
					if (situation.options.length > 0) {
						if (situation.id === 1 || situation.id === 8) {
							const situationOptions = situation.options.map(
								(item) => item.name
							);
							return situationOptions;
						}
						return situation.options;
					}
					return "";
				})
			),
		};
		navigate(
			{
				pathname: "/protocolos",
				search: new URLSearchParams(data).toString(),
			},
			{ replace: true }
		);
		setIsOpenModalFilter(false);
	};

	const valuesSituation = [
		{
			id: 1,
			name: "Em andamento",
		},
		{
			id: 2,
			name: "Concluido",
		},
		{
			id: 3,
			name: "Cancelado",
		},
		{
			id: 4,
			name: "Suspenso",
		},
	];

	useEffect(() => {
		setKeyword(keywordParams ? keywordParams : "");
		setStartDate(startDateParams ? startDateParams : null);
		setFinishDate(finishDateParams ? finishDateParams : null);
		setUpfmls(
			typeof upfmlsParams === "string"
				? JSON.parse(upfmlsParams.toLowerCase())
				: upfmls
		);
		setLateDays(lateDaysParams ?? null);
		setStreet(streetParams ?? "");
		setNumber(numberParams ?? "");
		setLote(loteParams ?? "");
		setQuadra(quadraParams ?? "");
		setCadastralIndex(cadastralIndexParams ?? "");
		setOffendersName(offendersNameParams ?? "");
		if (
			paramsFormatted &&
			paramsFormatted.situation &&
			paramsFormatted.situation.length > 0 &&
			dataTasks
		) {
			const paramsParsed = JSON.parse(paramsFormatted.situation);
			setFilters((prev) => {
				const newSituation = [...prev];
				const indexSituation = newSituation.findIndex((item) => item.id === 1);
				const indexSubjects = newSituation.findIndex((item) => item.id === 2);
				const indexGroups = newSituation.findIndex((item) => item.id === 4);
				const indexStatus = newSituation.findIndex((item) => item.id === 5);
				const indexResponsible = newSituation.findIndex(
					(item) => item.id === 6
				);
				const indexDistrict = newSituation.findIndex((item) => item.id === 7);
				const indexTasks = newSituation.findIndex((item) => item.id === 8);

				newSituation[indexSituation].options = valuesSituation.filter(
					(item) => SITUATIONS_VALUE && SITUATIONS_VALUE.includes(item.name)
				);

				newSituation[indexStatus].options =
					paramsParsed[indexStatus] === "" ? [] : paramsParsed[indexStatus];

				newSituation[indexSubjects].options =
					paramsParsed[indexSubjects] === "" ? [] : paramsParsed[indexSubjects];

				newSituation[indexGroups].options =
					paramsParsed[indexGroups] === "" ? [] : paramsParsed[indexGroups];

				newSituation[indexResponsible].options =
					paramsParsed[indexResponsible] === ""
						? []
						: paramsParsed[indexResponsible];

				newSituation[indexDistrict].options =
					paramsParsed[indexDistrict] === "" ? [] : paramsParsed[indexDistrict];

				newSituation[indexTasks].options = dataTasksWithId.filter(
					(item) => TASKS_VALUE && TASKS_VALUE.includes(item.name)
				);

				return newSituation;
			});
		}
	}, [dataTasks]);

	const isLoading = loading;

	const media889 = useMediaQuery("(max-width:889px)");
	const media500 = useMediaQuery("(max-width:500px)");
	return (
		<>
			<Box
				width="100%"
				display="flex"
				alignSelf="stretch"
				flexDirection="column"
				sx={{
					backgroundColor: "#f3f3f3",
				}}
			>
				<Header title="Protocolos" />
				<Container maxWidth="lg">
					<Box
						sx={{
							backgroundImage: `url(${dataCompany && dataCompany.detailCompany.logo
								})`,
							backgroundSize: "contain",
							backgroundPosition: "center",
							backgroundRepeat: "no-repeat",
						}}
						minHeight="70px"
						marginTop={2}
						marginBottom={3}
					/>

					<Box sx={{ flexGrow: 1 }}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={3} rowGap={2}>
								<Item>
									<Stack spacing={2} height="100%">
										<Counter
											filters={filters}
											isLoading={isLoading}
											startDate={startDate}
											finishDate={finishDate}
											upfmls={upfmls}
											street={street}
											number={number}
											lateDays={lateDays}
											keyword={keyword}
											lote={lote}
											quadra={quadra}
											cadastralIndex={cadastralIndex}
											offendersName={offendersName}
										/>
										<Button
											variant="contained"
											fullWidth
											onClick={handleOpenModalFilter}
										>
											Filtros
										</Button>
										{isOpenModalFilter && dataTasks && (
											<DialogFilter
												handleClose={handleClose}
												title="Filtrar protocolos"
												open={isOpenModalFilter}
												handleFilter={(values) => handleFilter(values)}
												filters={filters}
												finishDate={finishDate}
												startDate={startDate}
												lateDays={lateDays}
												upfmls={upfmls}
												street={street}
												number={number}
												keyword={keyword}
												tasksLabel={dataTasksWithId}
												lote={lote}
												quadra={quadra}
												cadastralIndex={cadastralIndex}
												offendersName={offendersName}
											/>
										)}
									</Stack>
								</Item>
								<ExportCSV
									filters={filters}
									finishDate={finishDate}
									startDate={startDate}
									lateDays={lateDays}
									upfmls={upfmls}
									street={street}
									number={number}
									keyword={keyword}
									cadastralIndex={cadastralIndex}
									lote={lote}
									offendersName={offendersName}
									quadra={quadra}
								/>
							</Grid>

							<Grid item xs={12} md={9} marginBottom={2}>
								<div
									style={{ position: "relative", marginBottom: "60px" }}
									onMouseEnter={() => setShowPagination(true)}
									onMouseLeave={() => setShowPagination(false)}
								>
									<Item
										sx={{
											height: "100%",
											paddingX: 0,
											minHeight: "327px",
										}}
									>
										<Box
											paddingX={2}
											display="flex"
											alignItems="center"
											justifyContent="space-between"
											flexDirection={["column", "column", "row"]}
										>
											<Title title="Protocolos" marginBottom={[2, 2, 0]} />
											<Stack
												display="flex"
												alignItems={["flex-start", "center", "center"]}
												justifyContent={["flex-start", "center", "center"]}
												spacing={[2, 2, 3]}
												direction={["column", "row", "row"]}
												width={["100%", "100%", "auto"]}
											>
												<FormControl
													sx={{
														minWidth: 150,
													}}
												>
													<InputLabel>Ordenar por</InputLabel>
													<Select
														label="Ordenar por"
														onChange={(e) =>
															setDirection(e.target.value as string)
														}
														value={direction}
													>
														<MenuItem value={"desc"}>Mais recentes</MenuItem>
														<MenuItem value={"asc"}>Mais antigos</MenuItem>
													</Select>
												</FormControl>
											</Stack>
										</Box>
										{isLoading ? (
											<CircularProgress />
										) : (
											data && (
												<>
													<ListProtocols
														fetchProtocols={data.fetchProtocols}
													/>
													{data.fetchProtocols.data.length > 0 &&
														(media500 ? (
															<TablePagination
																sx={{
																	background: "#fff"
																}}
																component="div"
																labelRowsPerPage="Linhas por página:"
																rowsPerPageOptions={[5, 15, 30, 60, 100]}
																count={data?.fetchProtocols?.total}
																page={page}
																onPageChange={handleChangePage}
																rowsPerPage={rowsPerPage}
																onRowsPerPageChange={(event) => {
																	setRowsPerPage(
																		parseInt(event.target.value)
																	);
																	setPage(0);
																}}
															/>
														) : (
															<Box
																sx={{
																	position: "fixed",
																	bottom: "30px",
																	left: media889 ? "50%" : "60%",
																	transform: "translateX(-50%)",
																	padding: "0px",
																	backgroundColor: "rgba(25, 118, 210, 0.98)",
																	boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
																	opacity: showPagination ? 0.95 : 0,
																	zIndex: 2,
																	borderRadius: "10px",
																	transition: "opacity 0.3s ease-in-out",
																}}
															>
																<PaginationStorage
																	count={data?.fetchProtocols?.total}
																	rowsPerPage={rowsPerPage}
																	page={page}
																	onPageChange={handleChangePage}
																	onRowsPerPageChange={(event) => {
																		setRowsPerPage(
																			parseInt(event.target.value)
																		);
																		setPage(0)
																	}}
																/>
															</Box>
														))}
												</>
											)
										)}
									</Item>
								</div>
							</Grid>
						</Grid>
					</Box>
					{noHasRestrictionCreateProtocol && (
						<AddFabButton onClick={() => handleOpenModalCreateProtocols()} />
					)}
				</Container>
			</Box>
			{isOpenModalCreateProtocol && (
				<ModalCreateProtocol
					refetch={refetch}
					open={isOpenModalCreateProtocol}
					handleClose={() => handleCloseModalCreateProtocols()}
				/>
			)}
		</>
	);
}