import { gql } from "@apollo/client";

export const FETCH_LATE_PROTOCOLS = gql`
	query (
		$keyword: String
		$order_by: String
		$direction: String
		$page: Int
		$limit: Int
	) {
		fetchLateProtocols(
			keyword: $keyword
			order_by: $order_by
			direction: $direction
			page: $page
			limit: $limit
		) {
			total
			current_page
			last_page
			per_page
			data {
				id
				code
				responsible {
					id
					name
				}
				subject {
					id
					name
				}
				status {
					id
					name
					theme
				}
				situation
				expire_at
				created_at
				updated_at
				info
			}
		}
	}
`;
