import IconButton from "components/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ModalFilter } from "../Modal/ModalFilter";
import { useState } from "react";

interface handleFilterProps {
	handleFilter: (values) => void;
	keyword: string;
}

export function Filter({ handleFilter, keyword }: handleFilterProps) {
	const [isOpenModal, setIsOpenModal] = useState(false);

	function onRequestFilter(values) {
		handleFilter(values);
		setIsOpenModal(false);
	}
	return (
		<>
			<IconButton onClick={() => setIsOpenModal(true)}>
				<FilterListIcon color="action" />
			</IconButton>
			<ModalFilter
				open={isOpenModal}
				handleClose={() => setIsOpenModal(false)}
				handleFilter={(values) => onRequestFilter(values)}
				keyword={keyword}
			/>
		</>
	);
}
