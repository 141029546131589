import { Box } from "@mui/material";
import Button from "components/Button";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";
import { ModalExportCSV } from "./Modal";

interface ExportCSVProps {
	filters: any;
	startDate: string | null;
	finishDate: string | null;
	upfmls: boolean;
	lateDays: string | null;
	street?: string | null;
	number?: string | null;
	keyword: string;
	lote: string | null;
	quadra: string | null;
	cadastralIndex: string | null;
	offendersName: string | null;
}

export function ExportCSV({
	filters,
	finishDate,
	startDate,
	upfmls,
	lateDays,
	street,
	number,
	keyword,
	cadastralIndex,
	lote,
	offendersName,
	quadra,
}: ExportCSVProps) {
	const [isOpen, setOpen] = useState(false);

	return (
		<>
			<Box marginTop={2} display="flex" justifyContent="center">
				<Button
					color="inherit"
					startIcon={<DownloadIcon />}
					onClick={() => setOpen(true)}
				>
					Exportar para Excel
				</Button>
			</Box>
			{isOpen && (
				<ModalExportCSV
					open={isOpen}
					handleClose={() => setOpen(false)}
					filters={filters}
					startDate={startDate}
					finishDate={finishDate}
					upfmls={upfmls}
					lateDays={lateDays}
					street={street}
					number={number}
					keyword={keyword}
					cadastralIndex={cadastralIndex}
					lote={lote}
					offendersName={offendersName}
					quadra={quadra}
				/>
			)}
		</>
	);
}
