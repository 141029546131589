import { useMutation } from "@apollo/client";
import { useState } from "react";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Stack,
	Collapse,
} from "@mui/material";
import { UPDATE_STATUS } from "graphql/mutations/updateStatus";
import { useForm, FormProvider } from "react-hook-form";
import { useSnackbar } from "notistack";
import Input from "components/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ColorPicker } from "components/ColorPicker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import IconButton from "components/IconButton";
import GenericInput from "components/Input/GenericInput";
import LoadingButton from "components/LoadingButton";

type EditDialogProps = {
	open: boolean;
	handleClose: () => void;
	data: any;
	onEdit: () => void;
};

const schema = yup.object().shape({
	name: yup.string().required("Campo obrigatório"),
});

export default function EditDialog({
	open,
	handleClose,
	data,
	onEdit,
}: EditDialogProps) {
	const [expanded, setExpanded] = useState(false);
	const [theme, setTheme] = useState(data.theme);

	const methods = useForm({
		defaultValues: {
			name: data.name,
		},
		resolver: yupResolver(schema),
	});
	const { enqueueSnackbar } = useSnackbar();

	// Setting mutations
	const [updateStatus, { loading }] = useMutation(UPDATE_STATUS);

	const { reset, handleSubmit } = methods;

	const onSubmit = async (values: any) => {
		try {
			await updateStatus({
				variables: {
					id: data.id,
					name: values.name,
					theme: theme.toUpperCase(),
				},
			});
			onEdit();
			reset();
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
	};

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};
	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="paper"
				maxWidth="sm"
				fullWidth={true}
			>
				<FormProvider {...methods}>
					<DialogTitle id="scroll-dialog-title">Editar Status</DialogTitle>

					<DialogContent>
						<Stack spacing={2} marginTop={2}>
							<Input name="name" autoFocus placeholder="Nome*" type="name" />
							<GenericInput
								readOnly
								name="theme"
								placeholder="Cor"
								value={theme}
								type="text"
								endAdornment={
									<IconButton onClick={() => handleExpandClick()}>
										{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
									</IconButton>
								}
								sx={{
									color: theme,
								}}
							/>
							<Collapse in={expanded} timeout="auto" unmountOnExit>
								<ColorPicker handleColor={(color) => setTheme(color)} />
							</Collapse>
						</Stack>
					</DialogContent>

					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Cancelar
						</Button>
						<LoadingButton
							loading={loading}
							variant="contained"
							onClick={handleSubmit(onSubmit)}
						>
							Editar Status
						</LoadingButton>
					</DialogActions>
				</FormProvider>
			</Dialog>
		</>
	);
}
