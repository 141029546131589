import { ModalLayout } from "components/ModalLayout";
import { ModalLayoutProps } from "types/ModalLayout";
import { FileUpload } from "./Steps/FileUpload";
import { AddRecipients } from "./Steps/AddRecipients";
import { ToSend } from "./Steps/ToSend";
import { Steps } from "components/Steps";
import useStep from "hooks/useStep";
import { FormProvider, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

type ModalCreateDocumentProps = ModalLayoutProps & {
	refetch: () => void;
};

const steps = [
	{
		label: "Upload de arquivo",
		description: "Escolha um arquivo em formato PDF para fazer o upload.",
		component: FileUpload,
	},
	{
		label: "Adicionar destinatários",
		description:
			"Adicione destinatários para assinar o documento informando um nome e email.",
		component: AddRecipients,
	},
	{
		label: "Enviar",
		description:
			"Envie seu documento para ser visualizado e assinado pelos destinatários.",
		component: ToSend,
	},
];

export type Recipient = {
	id: string;
	name: string;
	email: string;
	idUser: number | null;
};

export type FormData = {
	name: string;
	file: File;
	fileBase64: string;
	recipients: Array<Recipient>;
};
export function ModalCreateDocument({
	open,
	handleClose,
	refetch,
}: ModalCreateDocumentProps) {
	const methods = useForm({
		defaultValues: {
			name: "",
			file: null,
			fileBase64: "",
			recipients: [
				{
					id: uuidv4(),
					name: "",
					email: "",
					idUser: null,
				},
			],
		},
	});
	const [currentStep, helpers] = useStep(steps.length);
	const { goToNextStep, goToPrevStep, reset } = helpers;
	return (
		<FormProvider {...methods}>
			<ModalLayout
				open={open}
				handleClose={handleClose}
				title="Criar um novo documento"
				isNotShowButtonClose
				isNotShowButtonSubmit
			>
				<Steps
					steps={steps}
					goToNextStep={goToNextStep}
					goToPrevStep={goToPrevStep}
					resetSteps={reset}
					handleClose={handleClose}
					currentStep={currentStep}
					disabledButtons
					refetch={refetch}
				/>
			</ModalLayout>
		</FormProvider>
	);
}
