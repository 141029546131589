/* eslint-disable no-mixed-spaces-and-tabs */
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FormHelperText, Grid } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

interface FieldTextEditorProps {
	name?: string;
	index?: number;
	defaultValue?: string;
	readonly?: boolean;
	placeholder?: string;
	required?: boolean;
}

function FieldTextEditor({
	name,
	index,
	readonly,
	required,
	placeholder,
}: FieldTextEditorProps) {
	const { control } = useFormContext();
	const toolsOption = {
		options: [
			"inline",
			"blockType",
			"fontSize",
			"fontFamily",
			"list",
			"textAlign",
			"colorPicker",
			"link",
			"remove",
			"history",
		],
	};

	return (
		<Grid item xs={12}>
			<Controller
				defaultValue={EditorState.createEmpty()}
				control={control}
				name={`groups.${index}.fields.${name}`}
				rules={{
					required: required ? true : false,
				}}
				render={(inputProps) => (
					<>
						<Editor
							toolbar={toolsOption}
							handlePastedText={() => false}
							readOnly={readonly}
							editorState={inputProps.field.value}
							onEditorStateChange={(editorState) =>
								inputProps.field.onChange(editorState)
							}
							placeholder={`${placeholder}${required ? "*" : ""}`}
							editorStyle={{
								border: "1px solid #ccc",
								minHeight: "6rem",
							}}
						/>
						{required && (
							<FormHelperText error={true}>Campo obrigatório *</FormHelperText>
						)}
					</>
				)}
			/>
		</Grid>
	);
}

export default FieldTextEditor;
