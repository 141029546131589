import { ModalLayout } from "components/ModalLayout";
import { FormProvider, useForm } from "react-hook-form";
import { Stack } from "@mui/material";
import Input from "components/Input";
import { ModalLayoutProps } from "types/ModalLayout";
import { useMutation } from "@apollo/client";
import { REFUSE_COMPLAINT } from "graphql/mutations/refuseComplaint";
import { useSnackbar } from "notistack";

type ModalRefuseComplaintProps = ModalLayoutProps & {
	code: string;
	refetch: () => void;
};

export function ModalRefuseComplaint({
	open,
	handleClose,
	refetch,
	code,
}: ModalRefuseComplaintProps) {
	const [refuseComplaint, { loading }] = useMutation(REFUSE_COMPLAINT);
	const methods = useForm({
		defaultValues: {
			justification: "",
		},
	});
	const { enqueueSnackbar } = useSnackbar();

	const { handleSubmit, reset } = methods;

	async function onSubmit(data: { justification: string }) {
		try {
			await refuseComplaint({
				variables: {
					code: code,
					justification: data.justification,
				},
			});
			enqueueSnackbar("Reclamação recusada com sucesso", {
				variant: "success",
			});
			handleClose();
			setTimeout(() => {
        window.location.href = '/fiscalizacao/denuncias';
      }, 500);
			reset({
				justification: "",
			});
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
	}

	return (
		<FormProvider {...methods}>
			<ModalLayout
				open={open}
				handleClose={handleClose}
				title="Justificativa"
				handleSubmit={handleSubmit(onSubmit)}
				isLoading={loading}
			>
				<Stack spacing={2} marginTop={2}>
					<Input
						name="justification"
						placeholder="Escreva a sua justificativa"
						multiline
						rows={4}
						isRequired={true}
					/>
				</Stack>
			</ModalLayout>
		</FormProvider>
	);
}
