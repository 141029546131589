/* eslint-disable indent */
/* eslint-disable no-mixed-spaces-and-tabs */
import { ModalLayout } from "components/ModalLayout";
import { ModalLayoutProps } from "types/ModalLayout";
import { FormPontuate } from "./Form/FormPontuate";
import { DefaultForm } from "./Form/DefaultForm";
import { useForm, FormProvider } from "react-hook-form";
import { FETCH_OCCURRENCE_TYPES } from "graphql/queries/fetchOccurrenceTypes";
import { FETCH_PERFIS } from "graphql/queries/fetchPerfis";
import { useQuery } from "@apollo/client";
import { FETCH_STATUS } from "graphql/queries/fetchStatus";
import { FetchStatus } from "types/graphql/queries/fetchStatus";
import { FetchOccurrenceTypes } from "types/graphql/queries/fetchOccurrenceTypes";
import { FetchPerfis } from "types/graphql/queries/fetchPerfis";
import { CircularProgress } from "components/CircularProgress";

type Data = {
	deadLineToDo?: "UNTIL" | "ON" | "";
	deadline?: number;
	pointDescription?: string;
	label: string;
	occurrenceType?: {
		id?: string;
		name?: string;
	};
	point?: number;
	cancelInfraction?: boolean;
	requiredAttached?: boolean;
	requiredDescription?: boolean;
	advanceNextTask?: boolean;
	sendEmail?: boolean;
	status?: {
		name?: string;
		color?: string;
	};
	toPerfil?: {
		_id?: string;
		name?: string;
	};
};

type NodeSelected = {
	id?: string;
	type?: string;
	data: Data;
	selected?: boolean;
	position: {
		x: number;
		y: number;
	};
	positionAbsolute: {
		x: number;
		y: number;
	};
	dragging?: boolean;
	height?: number;
	width?: number;
};

type ModalFlow = ModalLayoutProps & {
	typeForm?: string;
	node?: NodeSelected;
	onNodeUpdate?: (node: any) => void;
};

export function ModalFlow({
	open,
	title,
	handleClose,
	typeForm,
	node,
	onNodeUpdate,
}: ModalFlow) {
	const { data: dataStatus } = useQuery<FetchStatus>(FETCH_STATUS, {
		variables: {
			limit: 1000,
		},
	});
	const { data: dataOccurrenceTypes } = useQuery<FetchOccurrenceTypes>(
		FETCH_OCCURRENCE_TYPES,
		{
			variables: {
				limit: 1000,
			},
		}
	);
	const { data: dataPerfis, loading } = useQuery<FetchPerfis>(FETCH_PERFIS, {
		variables: {
			limit: 1000,
		},
	});
	const typeFormOptions = {
		pontuate: <FormPontuate />,
		default: (
			<>
				{loading && <CircularProgress />}
				{dataOccurrenceTypes && dataStatus && dataPerfis && (
					<DefaultForm
						node={{
							type: node?.type,
						}}
						dataStatus={dataStatus}
						dataOccurrenceTypes={dataOccurrenceTypes}
						dataPerfis={dataPerfis}
					/>
				)}
			</>
		),
	};
	const methods = useForm({
		defaultValues:
			typeForm === "pontuate"
				? {
					point: node.data.point || 0,
					pointDescription: node.data.pointDescription || "",
				}
				: {
					label: node.data.label || "",
					pointDescription: node.data.pointDescription || "",
					deadline: node.data.deadline || 0,
					deadLineToDo: node.data.deadLineToDo || "",
					sendEmail: node.data.sendEmail || false,
					cancelInfraction: node.data.cancelInfraction || false,
					requiredAttached: node.data.requiredAttached || false,
					requiredDescription: node.data.requiredDescription || false,
					advanceNextTask: node.data.advanceNextTask || false,
					occurrenceType: node.data.occurrenceType
						? node.data.occurrenceType.name
						: null,
					toPerfil: node.data.toPerfil
						? node.data.toPerfil.name.toUpperCase()
						: null,
					status: node.data.status ? node.data.status.name : null,
					point: node.data.point || 0,
				},
	});
	const { handleSubmit } = methods;
	const onSubmit = (data: any) => {
		const dataFormated = {
			...node,
			data:
				typeForm === "pontuate"
					? {
						...node.data,
						point: data.point,
						pointDescription: data.pointDescription,
					}
					: {
						id: node.id,
						label: data.label,
						faveColor: data.status
							? dataStatus.fetchStatus.data.find((status) => {
								return status.name === data.status;
							}).theme
							: null,
						deadline: data.deadline,
						cancelInfraction: data.cancelInfraction,
						deadLineToDo: data.deadLineToDo,
						occurrenceType:
							dataOccurrenceTypes.fetchOccurrenceTypes.data.find(
								(occurrence) => {
									return occurrence.name === data.occurrenceType;
								}
							) || null,
						requiredAttached: data.requiredAttached,
						requiredDescription: data.requiredDescription,
						advanceNextTask: data.advanceNextTask,
						sendEmail: data.sendEmail,
						status:
							dataStatus.fetchStatus.data.find((status) => {
								return status.name === data.status;
							}) || null,
						toPerfil:
							dataPerfis.fetchPerfis.find((perfil) => {
								return perfil?.name.toString().toUpperCase() ===
									data?.toPerfil
									? data?.toPerfil.toString().toUpperCase()
									: null;
							}) || null,
						point: data.point || 0,
					},
		};
		onNodeUpdate(dataFormated);
	};
	return (
		<FormProvider {...methods}>
			<ModalLayout
				title={title}
				open={open}
				handleClose={handleClose}
				handleSubmit={handleSubmit(onSubmit)}
			>
				{typeFormOptions[typeForm]}
			</ModalLayout>
		</FormProvider>
	);
}
