import { gql } from "@apollo/client";

export const FETCH_PROTOCOLS = gql`
	query (
		$start: String
		$finish: String
		$situations: [String]
		$subject_ids: [Int]
		$responsible_ids: [Int]
		$group_ids: [Int]
		$status_ids: [Int]
		$keyword: String
		$order_by: String
		$direction: String
		$page: Int
		$limit: Int
		$upfmls: Boolean
		$late_days: String
		$street: String
		$number: String
		$district_ids: [Int]
		$zipcode: String
		$municipal_registration: String
		$tasks: [String]
		$lote: String
		$quadra: String
		$cadastral_index: String
		$offenders_name: String
	) {
		fetchProtocols(
			start: $start
			finish: $finish
			situations: $situations
			subject_ids: $subject_ids
			responsible_ids: $responsible_ids
			group_ids: $group_ids
			status_ids: $status_ids
			keyword: $keyword
			order_by: $order_by
			direction: $direction
			page: $page
			limit: $limit
			upfmls: $upfmls
			late_days: $late_days
			street: $street
			number: $number
			district_ids: $district_ids
			zipcode: $zipcode
			municipal_registration: $municipal_registration
			tasks: $tasks
			lote: $lote
			quadra: $quadra
			cadastral_index: $cadastral_index
			offenders_name: $offenders_name
		) {
			total
			current_page
			per_page
			last_page
			data {
				id
				code
				situation
				expire_at
				created_at
				updated_at
				concluded_at
				upf
				municipal_registration
				address_extras
				responsible {
					id
					name
				}
				subject {
					id
					name
				}
				status {
					id
					name
					theme
				}
				street
				number
				complement
				district {
					id
					name
					city {
						id
						name
						state
					}
				}
				actually_task
				task_user_ids
				latitude
				longitude
			}
		}
	}
`;
