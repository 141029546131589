import Input from "components/Input";
import {
	Stack,
	Typography,
} from "@mui/material";

export function StepContact() {

	return (
		<>
			<Typography sx={{ fontSize: "0.9rem", color: "rgba(0, 0, 0, 0.5)", marginTop: "-10px", marginBottom: "10px" }}>
				Deixe em branco caso queira fazer uma denúncia anônima
			</Typography>
			<Stack spacing={2} sx={{ paddingY: "10px"}}>
				<Input name="name" autoFocus placeholder="Nome" type="text" />
				<Input name="email" placeholder="Email" type="email" />
			</Stack>
		</>
	);
}
