import {
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	OutlinedInput,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

interface FieldNumericInputProps {
	name: string;
	placeholder?: string;
	required?: boolean;
	index?: number;
	readonly?: boolean;
}

function FieldNumericInput({
	name,
	placeholder,
	required,
	index,
	readonly,
}: FieldNumericInputProps) {
	const { control } = useFormContext();
	return (
		<Grid item xs={12}>
			<Controller
				control={control}
				name={`groups.${index}.fields.${name}`}
				rules={{
					required: required ? "Campo obrigatório" : false,
					pattern: {
						value: /^[0-9]+$/,
						message: "Este campo aceita somente números",
					},
				}}
				render={(inputProps) => (
					<FormControl
						variant="outlined"
						fullWidth
						error={inputProps.fieldState.error && true}
					>
						<InputLabel
							htmlFor={name}
							error={inputProps.fieldState.error && true}
						>
							{`${placeholder}${required ? "*" : ""}`}
						</InputLabel>
						<OutlinedInput
							readOnly={readonly}
							id={name}
							type="text"
							value={inputProps.field.value}
							onChange={(e) => inputProps.field.onChange(e.target.value)}
							label={`${placeholder}${required ? "*" : ""}`}
							fullWidth
							error={inputProps.fieldState.error && true}
						/>
						{inputProps.fieldState.error && (
							<FormHelperText error={inputProps.fieldState.error && true}>
								{inputProps.fieldState.error.message + ""}
							</FormHelperText>
						)}
					</FormControl>
				)}
			/>
		</Grid>
	);
}

export default FieldNumericInput;
