import PrintIcon from "@mui/icons-material/Print";
import { PRINT_PROTOCOL } from "graphql/queries/printProtocol";
import { PRINT_TASK } from "graphql/queries/printTask";
import { useLazyQuery, useQuery } from "@apollo/client";
import { FetchDetailCompany } from "types/graphql/queries/fetchDetailCompany";
import { FETCH_DETAIL_COMPANY } from "graphql/queries/fetchDetailCompany";
import { DetailProtocol, Task } from "types/graphql/queries/detailProtocol";
import IconButton from "components/IconButton";
import { CircularProgress } from "components/CircularProgress";

interface PrintButtonProps {
	code: string | number;
	type?: "protocol" | "task";
	task?: Task;
	protocol?: DetailProtocol["detailProtocol"];
}

export function PrintButton({
	code,
	type = "protocol",
	task,
	protocol,
}: PrintButtonProps) {
	const subdomain = window.location.hostname.split(".")[0];

	const { data: dataCompany } = useQuery<FetchDetailCompany>(
		FETCH_DETAIL_COMPANY,
		{
			variables: {
				domain:
					subdomain === "localhost"
						? process.env.REACT_DEFAULT_DOMAIN || "lagoasanta"
						: subdomain,
			},
		}
	);

	const [printProtocol, { loading }] = useLazyQuery(PRINT_PROTOCOL);
	const [printTask, { loading: loadingTasks }] = useLazyQuery(PRINT_TASK);

	const handlePrint = () => {
		if (type === "protocol") {
			printProtocol({
				variables: { code: code },
			}).then(({ data }) => {
				const print = window.open("about:blank");
				print.document.write(data.printProtocol);
			});
		} else if (type === "task") {
			printTask({
				variables: { id: code },
			}).then(({ data }) => {
				const print = window.open("about:blank");
				print.document.write(data.printTask);
			});
		}
	};

	return (
		<IconButton
			aria-label="print"
			onClick={handlePrint}
			disabled={loading || loadingTasks}
		>
			{loading || loadingTasks ? <CircularProgress /> : <PrintIcon />}
		</IconButton>
	);
}
