import { gql } from "@apollo/client";

export const FETCH_POINTS_BY_USER = gql`
	query {
		pointsByUser {
			accumulated_month
			accumulated_today
		}
	}
`;
