/* eslint-disable no-mixed-spaces-and-tabs */
import { useMutation, useQuery } from "@apollo/client";
import {
	Box,
	Container,
	Typography,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	TablePagination,
	Stack,
} from "@mui/material";
import { CircularProgress } from "components/CircularProgress";
import { Header } from "components/Header";
import { DETAIL_DYNAMIC_REPORT } from "graphql/queries/detailDynamicReport";
import {
	DetailDynamicReport,
	FetchDetailDynamicReport,
} from "types/graphql/queries/detailDynamicReport";
import { useParams } from "react-router-dom";
import { Item } from "components/Paper";
import {
	headersProtocols,
	headersTasks,
	visualizationRules,
} from "../Form/AnalyticalReport/consts";
import { ModalSharePerfis } from "../ModalSharePerfis";
import { Fragment, useState } from "react";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import { UPDATE_DYNAMIC_REPORT_PROFILES } from "types/graphql/queries/updateDynamicReportProfiles";
import { useSnackbar } from "notistack";
import LocalProvider from "components/LocalProvider";
import SpeedDialComponent from "components/SpeedDial";
import { ModalEditAnalyticalReport } from "./Modal/ModalEditAnalyticalReport";

export function DetailAnalyticalReport() {
	const { enqueueSnackbar } = useSnackbar();
	const [isOpenModalSharePerfis, setIsOpenModalSharePerfis] = useState(false);
	const [dynamicReportToEdit, setDynamicReportToEdit] =
		useState<DetailDynamicReport | null>(null);
	const [isOpenModalEditDynamicReport, setIsOpenModalEditDynamicReport] =
		useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const { id } = useParams();
	const { data, loading, refetch } = useQuery<FetchDetailDynamicReport>(
		DETAIL_DYNAMIC_REPORT,
		{
			fetchPolicy: "network-only",
			variables: {
				id: parseInt(id),
				page: page + 1,
				limit: rowsPerPage,
			},
		}
	);
	const [updateDynamicReportProfiles, { loading: loadingUpdate }] = useMutation(
		UPDATE_DYNAMIC_REPORT_PROFILES
	);

	const handleUpdatePerfis = async (
		perfis: Array<{ id: number; name: string }>
	) => {
		try {
			await updateDynamicReportProfiles({
				variables: {
					id: parseInt(id),
					profile_ids: perfis.map((item) => item.id),
				},
			});
			enqueueSnackbar("Perfis atualizados com sucesso", { variant: "success" });
			refetch();
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
		setIsOpenModalSharePerfis(false);
	};

	const handleClickToEditDynamicReport = (item: DetailDynamicReport) => {
		setDynamicReportToEdit(item);
		setIsOpenModalEditDynamicReport(true);
	};

	const handleCloseModalEditDynamicReport = () => {
		setDynamicReportToEdit(null);
		setIsOpenModalEditDynamicReport(false);
	};

	const parsedSpec = data && JSON.parse(data?.detailDynamicReport.spec);
	const headers =
		data?.detailDynamicReport.data_source === "Protocolos"
			? headersProtocols
			: headersTasks;

	const visualizationRule =
		data &&
		visualizationRules.find(
			(item) => item.value === data.detailDynamicReport.visualization_rule
		);

	const actionsButton =
		visualizationRule?.value === "Perfis"
			? [
					visualizationRule?.value === "Perfis" && {
						icon: <ShareIcon />,
						name: "Compartilhar",
						onClick: () => setIsOpenModalSharePerfis(true),
					},
					{
						icon: <EditIcon />,
						name: "Editar",
						onClick: () =>
							handleClickToEditDynamicReport(data?.detailDynamicReport),
					},
			  ]
			: [
					{
						icon: <EditIcon />,
						name: "Editar",
						onClick: () =>
							handleClickToEditDynamicReport(data?.detailDynamicReport),
					},
			  ];

	return (
		<>
			<Box
				width="100%"
				display="flex"
				alignSelf="stretch"
				flexDirection="column"
				sx={{
					backgroundColor: "#f3f3f3",
				}}
				paddingBottom="1rem"
			>
				<Header title="Relatório analítico" isReturn />
				{loading ? (
					<CircularProgress />
				) : (
					<>
						{data && parsedSpec && (
							<Container maxWidth="lg">
								<Item>
									<Typography
										fontSize="32px"
										fontWeight={700}
										component="h2"
										gutterBottom
										textAlign={"center"}
									>
										{data.detailDynamicReport.title}
									</Typography>
									<TableContainer style={{ marginTop: "20px" }}>
										<Table>
											<TableHead>
												<TableRow>
													{data.detailDynamicReport.columns.map((column, index) => (
														<TableCell key={index}>
															{headers[column]}
														</TableCell>
													))}
												</TableRow>
											</TableHead>
											<TableBody>
												{parsedSpec.rows.items.map((row, index) => {
													const nameObject = "col" + index;
													const tableCells = Object.keys(row);
													return (
														<TableRow key={index}>
															{tableCells.map((cell) => {
																return (
																	<TableCell key={cell}>
																		{row[cell] || " - "}
																	</TableCell>
																);
															})}
														</TableRow>
													);
												})}
											</TableBody>
										</Table>
									</TableContainer>
									<Stack direction="row" justifyContent="space-between">
										<Box>
											<Box mt={2}>
												<Typography
													fontSize="16px"
													fontWeight={400}
													lineHeight={"24px"}
													letterSpacing={0.5}
												>
													Criado por{" "}
													<strong>
														{data.detailDynamicReport.created_by.name}
													</strong>{" "}
													em{" "}
													<strong>{data.detailDynamicReport.created_at}</strong>
												</Typography>
											</Box>
											{loadingUpdate ? (
												<CircularProgress />
											) : (
												<>
													{visualizationRule && (
														<Stack>
															<FormControl
																sx={{
																	mt: "6px",
																}}
															>
																<RadioGroup
																	row={true}
																	aria-labelledby={visualizationRule.label}
																	name={visualizationRule.label}
																>
																	<FormControlLabel
																		value={visualizationRule.value}
																		id={visualizationRule.value}
																		checked={true}
																		control={<Radio color="default" />}
																		label={visualizationRule.label}
																	/>
																</RadioGroup>
															</FormControl>
															{data.detailDynamicReport.profiles.length > 0 &&
																visualizationRule.value === "Perfis" && (
																	<>
																		<Typography variant="caption">
																			Perfis Selecionados:
																		</Typography>
																		<Typography>
																			{data.detailDynamicReport.profiles.map(
																				(item, index) => {
																					const isLastIndex =
																						index <
																						data.detailDynamicReport.profiles
																							.length -
																							1;
																					return (
																						<Fragment key={item.id}>
																							{item.name} {isLastIndex && ", "}
																						</Fragment>
																					);
																				}
																			)}
																		</Typography>
																	</>
																)}
														</Stack>
													)}
												</>
											)}
										</Box>
										{parsedSpec.rows.items.length !== 0 && (
											<LocalProvider locale="ptBR">
												<TablePagination
													showLastButton
													showFirstButton
													rowsPerPageOptions={[5, 15, 30, 60, 100]}
													component="div"
													count={parsedSpec.rows.total}
													rowsPerPage={rowsPerPage}
													page={page}
													onPageChange={(event, newPage) => {
														setPage(newPage);
													}}
													onRowsPerPageChange={(event) => {
														setRowsPerPage(parseInt(event.target.value));
														setPage(0);
													}}
												/>
											</LocalProvider>
										)}
									</Stack>
								</Item>
							</Container>
						)}
					</>
				)}
			</Box>
			{isOpenModalSharePerfis && (
				<ModalSharePerfis
					open={isOpenModalSharePerfis}
					handleClose={() => setIsOpenModalSharePerfis(false)}
					handleAddPerfis={(values) => handleUpdatePerfis(values)}
					defaultPerfis={data.detailDynamicReport.profiles.map((item) => ({
						id: item.id,
						name: item.name,
					}))}
				/>
			)}
			{dynamicReportToEdit && (
				<ModalEditAnalyticalReport
					handleClose={handleCloseModalEditDynamicReport}
					open={isOpenModalEditDynamicReport}
					report={dynamicReportToEdit}
					refetch={refetch}
				/>
			)}
			<SpeedDialComponent
				ariaLabel="Botão de edição e gerenciar compartilhamento"
				actions={actionsButton}
				hidden={!visualizationRule && visualizationRule?.value !== "Perfis"}
			/>
		</>
	);
}
