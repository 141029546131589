import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { EmptyMessage } from "components/EmptyMessage";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import { FetchComplaintsToEvalute } from "types/graphql/queries/fetchComplaintsToEvalute";
import { format } from "date-fns";

interface ListComplaintsToEvaluateProps {
	complaintsToEvaluate: FetchComplaintsToEvalute["fetchComplaintsToEvaluate"]["data"];
}

export function ListComplaintsToEvalute({
	complaintsToEvaluate,
}: ListComplaintsToEvaluateProps) {
	const navigate = useNavigate();

	return (
		<TableContainer style={{ marginTop: "10px", maxBlockSize: "400px" }}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Protocolo</TableCell>
						<TableCell>Data</TableCell>
						<TableCell>Denunciante</TableCell>
						<TableCell>&nbsp;</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{complaintsToEvaluate.length > 0 ? (
						complaintsToEvaluate.map((complaint) => (
							<TableRow key={complaint.id}>
								<TableCell>{complaint.code}</TableCell>
								<TableCell>
									{format(new Date(complaint.created_at), "dd/MM/yyyy HH:mm")}
								</TableCell>
								<TableCell>{complaint.name || "Anônimo"}</TableCell>
								<TableCell>
									<Button
										size="small"
										variant="contained"
										onClick={() =>
											navigate({
												pathname: `/protocolo/${complaint.protocol.code}`,
												search: "from=denuncias",
											})
										}
									>
										Avaliar
									</Button>
								</TableCell>
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell colSpan={4}>
								<EmptyMessage message="Nenhum protocolo para avaliar" />
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
