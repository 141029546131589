import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

const subdomain = window.location.hostname.split(".")[0];

const link = createUploadLink({
	uri: process.env.REACT_APP_GRAPHQL,
	headers: {
		"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Credentials": "true",
	},
});

const authLink = setContext(async (_, { headers }) => {
	const accessToken = localStorage.getItem(`@${subdomain}:token`);
	return {
		headers: {
			...headers,
			authorization: accessToken ? `Bearer ${accessToken}` : "",
		},
	};
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (
		networkError &&
		(networkError.cause === 500 || networkError.cause === 404)
	) {
		localStorage.removeItem(`@${subdomain}:token`);
		window.location.href = "/login";
	}

	if (graphQLErrors) {
		if (graphQLErrors[0].message === "Signature verification failed") {
			localStorage.removeItem(`@${subdomain}:token`);
			window.location.href = "/login";
		}

		graphQLErrors.map(({ message, locations, path }) => {
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
			);
		});
	}

	if (networkError) {
		console.log(`[Network error]: ${networkError}`);
	}
});

export const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: from([errorLink, authLink, link]),
});
