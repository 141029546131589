import { gql } from "@apollo/client";

export const CREATE_HOLIDAY = gql`
	mutation (
		$name: String!
		$day: Int!
		$month: Int!
		$year: Int
	) {
		createHoliday(
			name: $name
			day: $day
			month: $month
			year: $year
		)
	}
`;
