import { Grid, Box, Typography } from "@mui/material";

interface FieldPropertyDetailsProps {
	values?: Array<{
		[key: string]: string | number | boolean | Array<string | number | boolean>;
	}>;
}
function FieldLocation({ values }: FieldPropertyDetailsProps) {
	return (
		<>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">Índice Cadastral</Typography>
					<Typography variant="subtitle2">
						{(values &&
							values.length > 0 &&
							values[0].municipalRegistration &&
							values[0].municipalRegistration) ||
							"-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">Lote</Typography>
					<Typography variant="subtitle2">
						{(values &&
							values.length > 0 &&
							values[0].lote &&
							values[0].lote) ||
							"-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">Quadra</Typography>
					<Typography variant="subtitle2">
						{(values &&
							values.length > 0 &&
							values[0].quadra &&
							values[0].quadra) ||
							"-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">
						Mesmo endereço do infrator/requerente
					</Typography>
					{values && values.length > 0 && (
						<Typography variant="subtitle2">
							{values[0].isSameAddress === true ? "Sim" : "Não"}
						</Typography>
					)}
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">Rua</Typography>
					<Typography variant="subtitle2">
						{(values &&
							values.length > 0 &&
							values[0].street &&
							values[0].street) ||
							"-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">Número</Typography>
					<Typography variant="subtitle2">
						{(values &&
							values.length > 0 &&
							values[0].number &&
							values[0].number) ||
							"-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">Bairro</Typography>
					<Typography variant="subtitle2">
						{(values &&
							values.length > 0 &&
							values[0].neighborhood &&
							values[0].neighborhood) ||
							"-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">Complemento</Typography>
					<Typography variant="subtitle2">
						{(values &&
							values.length > 0 &&
							values[0].complement &&
							values[0].complement) ||
							"-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">Área total</Typography>
					<Typography variant="subtitle2">
						{(values &&
							values.length > 0 &&
							values[0].areatotal &&
							values[0].areatotal) ||
							"-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">Área terreno</Typography>
					<Typography variant="subtitle2">
						{(values &&
							values.length > 0 &&
							values[0].areaterreno &&
							values[0].areaterreno) ||
							"-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">Ponto de referência</Typography>
					<Typography variant="subtitle2">
						{(values &&
							values.length > 0 &&
							values[0].codigo &&
							values[0].codigo) ||
							"-"}
					</Typography>
				</Box>
			</Grid>
		</>
	);
}

export default FieldLocation;
