import { Box } from "@mui/material";
import { memo } from "react";
import { ControlButton } from "reactflow";

interface ConfigurationPanelProps {
	handleSwitchBackgroundVariant: (variant: "lines" | "dots" | "cross") => void;
}

function ConfigurationPanel({
	handleSwitchBackgroundVariant,
}: ConfigurationPanelProps) {
	const onSwitchBackgroundVariant = (variant: "lines" | "dots" | "cross") => {
		handleSwitchBackgroundVariant(variant);
	};

	return (
		<>
			<ControlButton
				onClick={() => onSwitchBackgroundVariant("lines")}
				style={{
					width: "auto",
				}}
			>
				<Box>Linhas</Box>
			</ControlButton>
			<ControlButton
				onClick={() => onSwitchBackgroundVariant("dots")}
				style={{
					width: "auto",
				}}
			>
				<Box>Pontos</Box>
			</ControlButton>
			<ControlButton
				onClick={() => onSwitchBackgroundVariant("cross")}
				style={{
					width: "auto",
				}}
			>
				<Box>Cruz</Box>
			</ControlButton>
		</>
	);
}

export default memo(ConfigurationPanel);
