import { Button, ButtonProps } from "@mui/material";
import React, { forwardRef, ForwardRefRenderFunction } from "react";

type ButtonComponentProps = ButtonProps & {
	endIcon?: React.ReactNode;
	startIcon?: React.ReactNode;
};

const buttonBase: ForwardRefRenderFunction<
	HTMLButtonElement,
	ButtonComponentProps
> = (
	{
		onClick,
		children,
		type = "button",
		variant,
		color,
		disabled,
		endIcon,
		startIcon,
		...rest
	},
	ref
) => {
	return (
		<Button
			endIcon={endIcon}
			startIcon={startIcon}
			onClick={onClick}
			ref={ref}
			type={type}
			variant={variant}
			color={color}
			disabled={disabled}
			{...rest}
		>
			{children}
		</Button>
	);
};

export default forwardRef(buttonBase);
