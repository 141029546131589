import { gql } from "@apollo/client";

export const FETCH_DYNAMIC_REPORTS = gql`
	query (
		$keyword: String
		$type: String
		$data_source: String
		$order_by: String
		$order_direction: String
		$page: Int
		$limit: Int
	) {
		fetchDynamicReports(
			keyword: $keyword
			type: $type
			data_source: $data_source
			order_by: $order_by
			order_direction: $order_direction
			page: $page
			limit: $limit
		) {
			total
			current_page
			per_page
			last_page
			data {
				id
				created_by {
					id
					name
					email
					params {
						matricula
					}
				}
				type
				data_source
				title
				version
				created_at
				updated_at
			}
		}
	}
`;
