import { DocumentNode } from "@apollo/client";
import { useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { RadioFilterModal } from "./Modal";

interface RadioFilterModalProps {
	defKeyword?: string;
	title: string;
	placeholder?: string;
	queryName: DocumentNode;
	dataName: string;
	moreVariables?: {
		[key: string]: number | string | boolean | null;
	};
	handleSelect?: (id: number) => void;
	handleSelectAll?: (values: {
		id: number;
		name: string;
		email?: string;
	}) => void;
	isRequired?: boolean;
	messageEmpty?: string;
	messageEmptyTitle?: string;
	isActiveUsers?: boolean;
	defaultValue?: {
		id: number;
		name: string;
		email?: string;
	};
	officialProtocol?: {
		[key: string]: number | string | boolean | null;
	};
}

export function RadioFilter({
	defKeyword,
	title,
	placeholder,
	queryName,
	dataName,
	moreVariables,
	handleSelect,
	handleSelectAll,
	isRequired,
	messageEmpty = "Nenhum item encontrado",
	messageEmptyTitle,
	isActiveUsers,
	defaultValue,
	officialProtocol,
}: RadioFilterModalProps) {
	const [showDialog, setShowDialog] = useState(false);

	const [selected, setSelected] = useState<{
		id: number;
		name: string;
		email?: string;
	} | null>(defaultValue || null);

	const handleClose = () => {
		setShowDialog(false);
	};

	const onSelected = (values: { id: number; name: string; email?: string }) => {
		setSelected(values);
		if (handleSelectAll) {
			handleSelectAll(values);
		} else {
			handleSelect(values.id);
		}
		setShowDialog(false);
	};

	return (
		<>
			<TextField
				error={selected === null && isRequired ? true : false}
				InputProps={{
					readOnly: true,
					endAdornment: (
						<InputAdornment position="end">
							<ArrowDropDownIcon
								style={{ color: "#787878", marginRight: -6 }}
							/>
						</InputAdornment>
					),
				}}
				InputLabelProps={{
					shrink: selected ? true : false,
				}}
				onClick={() => {
					setShowDialog(true);
				}}
				variant="outlined"
				fullWidth
				value={selected?.name || ""}
				label={placeholder}
			/>
			<RadioFilterModal
				dataName={dataName}
				queryName={queryName}
				title={title}
				isActiveUsers={isActiveUsers}
				messageEmpty={messageEmpty}
				messageEmptyTitle={messageEmptyTitle}
				moreVariables={moreVariables}
				defKeyword={defKeyword}
				handleClose={handleClose}
				open={showDialog}
				handleSelectItem={(values) => onSelected(values)}
				defaultValue={selected}
				officialProtocol={officialProtocol}
			/>
		</>
	);
}
