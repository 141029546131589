import { Handle, NodeProps, Position, useStore } from "reactflow";
import { Typography, Box } from "@mui/material";

const connectionNodeIdSelector = (state) => state.connectionNodeId;

function Condition({
	data,
	isConnectable,
	id,
	targetPosition = Position.Top,
	sourcePosition = Position.Bottom,
}: NodeProps) {
	const connectionNodeId = useStore(connectionNodeIdSelector);
	const isTarget = connectionNodeId && connectionNodeId !== id;

	return (
		<Box
			height="60px"
			width="60px"
			sx={{
				backgroundColor: data.faveColor ? data.faveColor : "#ddd",
				transform: "rotate(45deg)",
				"&:hover:before": {
					content: "' '",
					position: "absolute",
					top: "-5px",
					left: "0",
					height: "10px",
					width: "10px",
					transform: "translate(-50%, 0)",
					background: "#ff0000",
					zIndex: 1000,
					lineHeight: 1,
					borderRadius: "50%",
					color: "#fff",
					fontSize: "9px",
					border: "1px solid #222138",
				},
			}}
			alignItems="center"
			justifyContent="center"
			display="flex"
			border="1px solid #000"
		>
			<Handle
				type="target"
				position={targetPosition}
				isConnectable={isConnectable}
				style={{
					width: "100%",
					height: "100%",
					background: "blue",
					position: "absolute" as const,
					top: 0,
					left: 0,
					borderRadius: 0,
					transform: "none",
					border: "none",
					opacity: 0,
					zIndex: isTarget ? 3 : 1,
				}}
			/>
			<Typography
				variant="body2"
				fontSize={8}
				alignSelf="center"
				sx={{
					transform: "rotate(-45deg)",
				}}
				textAlign="center"
			>
				{data?.label}
			</Typography>
			<Handle
				type="source"
				id="b"
				position={sourcePosition}
				isConnectable={isConnectable}
				style={{
					zIndex: 2,
					width: "100%",
					height: "100%",
					background: "blue",
					position: "absolute" as const,
					top: 0,
					left: 0,
					borderRadius: 0,
					transform: "none",
					border: "none",
					opacity: 0,
				}}
			/>
		</Box>
	);
}

export default Condition;
