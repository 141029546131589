import { gql } from "@apollo/client";

export const CREATE_PUBLIC_COMPLAINT = gql`
	mutation createPublicComplaint(
		$domain: String!
		$name: String
		$email: String
		$zipcode: String!
		$address: String!
		$number: String
		$district_name: String!
		$reference: String!
		$description: String!
		$files: [CreatePublicComplaintFiles]
	) {
		createPublicComplaint(
			domain: $domain
			name: $name
			email: $email
			zipcode: $zipcode
			address: $address
			number: $number
			district_name: $district_name
			reference: $reference
			description: $description
			files: $files
		)
	}
`;
