import { gql } from "@apollo/client";

export const FETCH_DYNAMIC_FORMS = gql`
	query ($keyword: String, $order_by: String, $direction: String, $page: Int, $limit: Int) {
		fetchDynamicForms(keyword: $keyword, order_by: $order_by, direction: $direction, page: $page, limit: $limit) {
			total
			current_page
			per_page
			last_page
			data {
				id
				name
				version
				created_at
			}
		}
	}
`;
