import { gql } from "@apollo/client";

export const DETAIL_FLOW = gql`
	query ($flow_id: Int!, $version: Int) {
		detailFlow(flow_id: $flow_id, version: $version) {
			name
			description
			version
			spec_start_official_protocol
			auto_finish_on_complaint_refused
			status
			spec {
				data {
					_id
					name
					type
					faveColor
					deadline
					source
					target
					cancelInfraction
					deadlineTodo
					occurrenceType {
						_id
						name
						form {
							_id
						}
					}
					requiredAttached
					requiredDescription
					advanceNextTask
					sendEmail
					status {
						label
						color
					}
					toPerfil {
						_id
						hasChildren
						height
						width
						name
						perfilFeatures {
							_id
							name
							authority
						}
					}
					point
					pointDescription
				}
				graspable
				group
				locked
				panoramic
				position {
					x
					y
				}
				removed
				selectable
				selected
			}
			versions {
				version
				updated_at
				user
			}
		}
	}
`;

