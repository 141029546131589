/* eslint-disable no-mixed-spaces-and-tabs */
import { LinearProgressWithLabel } from "components/LinearProgress";
import { ModalLayout } from "components/ModalLayout";
import { ModalLayoutProps } from "types/ModalLayout";
import { Box } from "@mui/material";
import { useQuery } from "@apollo/client";
import { FETCH_PROTOCOLS } from "graphql/queries/fetchProtocols";
import { FetchProtocols } from "types/graphql/queries/fetchProtocols";
import { useEffect, useState } from "react";
import { CSVDownloader } from "../CSVDownloader";
import { format } from "date-fns";
import { CircularProgress } from "components/CircularProgress";
import { closeModalExportCsv } from "utils/closeModalExportCsv";

type ModalExportCSVProps = ModalLayoutProps & {
	filters: any;
	startDate: string | null;
	finishDate: string | null;
	upfmls: boolean;
	lateDays: string | null;
	street?: string | null;
	number?: string | null;
	keyword: string;
	lote: string | null;
	quadra: string | null;
	cadastralIndex: string | null;
	offendersName: string | null;
};

export function ModalExportCSV({
	open,
	handleClose,
	filters,
	startDate,
	finishDate,
	upfmls,
	lateDays,
	street,
	number,
	keyword,
	cadastralIndex,
	lote,
	offendersName,
	quadra,
}: ModalExportCSVProps) {
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [dataExport, setDataExport] = useState<
		FetchProtocols["fetchProtocols"]["data"]
	>([]);

	const { data, loading } = useQuery<FetchProtocols>(FETCH_PROTOCOLS, {
		fetchPolicy: "no-cache",
		variables: {
			page: page,
			limit: 100,
			keyword: keyword,
			start: startDate,
			finish: finishDate,
			situations: filters
				? (filters[0].options.length > 0 &&
					filters[0].options.map((item) => item.name)) ||
				null
				: null,
			subject_ids: filters
				? (filters[1].options.length > 0 &&
					filters[1].options.map((item) => item.id)) ||
				null
				: null,
			group_ids: filters
				? (filters[2].options.length > 0 &&
					filters[2].options.map((item) => item.id)) ||
				null
				: null,
			status_ids: filters
				? (filters[3].options.length > 0 &&
					filters[3].options.map((item) => item.id)) ||
				null
				: null,
			responsible_ids: filters
				? (filters[4].options.length > 0 &&
					filters[4].options.map((item) => item.id)) ||
				null
				: null,
			district_ids: filters
				? (filters[5].options.length > 0 &&
					filters[5].options.map((item) => item.id)) ||
				null
				: null,
			tasks: filters
				? (filters[6].options.length > 0 &&
					filters[6].options.map((item) => item.name)) ||
				null
				: null,
			upfmls: upfmls,
			late_days: lateDays,
			street: street,
			number: number,
			lote: lote,
			quadra: quadra,
			cadastral_index: cadastralIndex,
			offenders_name: offendersName,
		},
	});

	const headersCsv = [
		"Protocolo",
		"Criado em",
		"Expira em",
		"Atualizado em",
		"UPFMLS",
		"Endereço",
		"Bairro",
		"Índice cadastral",
		"Responsável",
		"Situação",
		"Status",
		"Assunto",
		"Lote",
		"Quadra",
		"Latitude",
		"Longitude",
		"Tarefa atual",
	];

	const rowsCsv = dataExport.map((protocol) => {
		const address = `${protocol.street ? protocol.street : "-"}${" "}${protocol.number
			? (protocol.street ? ", " : "") + "nº" + protocol.number
			: null
			}${" "}${protocol.district ? protocol.district.name : null}${protocol.district?.city
				? " - " +
				protocol.district.city.name +
				"/" +
				protocol.district.city.state
				: null
			}`;

		const district = protocol.district ? protocol.district.name : null;

		const addressExtras = JSON.parse(protocol.address_extras);
		return [
			`#${protocol.code}`,
			protocol.created_at
				? format(new Date(protocol.created_at), "dd/MM/yyyy HH:mm")
				: "-",
			protocol.expire_at
				? format(new Date(protocol.expire_at), "dd/MM/yyyy HH:mm")
				: "-",
			protocol.updated_at
				? format(new Date(protocol.updated_at), "dd/MM/yyyy HH:mm")
				: "-",
			protocol.upf ? String(protocol.upf) : "-",
			address ? address.trim() : "-",
			district,
			protocol.municipal_registration,
			protocol.responsible ? protocol.responsible.name : "-",
			protocol.situation,
			protocol.status ? protocol.status.name : "-",
			protocol.subject ? protocol.subject.name : "-",
			addressExtras?.lote ?? "-",
			addressExtras?.quadra ?? "-",
			protocol.latitude ?? "-",
			protocol.longitude ?? "-",
			protocol.actually_task ?? "-",
		];
	});
	useEffect(() => {
		if (data) {
			setTotal(data.fetchProtocols.total);
			setDataExport([...dataExport, ...data.fetchProtocols.data]);
			if (
				data.fetchProtocols.total > dataExport.length &&
				page < data.fetchProtocols.last_page
			) {
				setPage((prev) => prev + 1);
			}
		}
	}, [data]);

	const percentageDataExport = (dataExport.length * 100) / total;

	if (percentageDataExport === 100) {
		closeModalExportCsv(100, handleClose);
	}

	return (
		<ModalLayout
			open={open}
			handleClose={handleClose}
			title="Exportar CSV"
			isNotShowButtonSubmit
			buttonTextClose="Cancelar"
		>
			<>
				{data && data.fetchProtocols.total <= dataExport.length && (
					<CSVDownloader rowsCsv={rowsCsv} headersCsv={headersCsv} />
				)}
				<Box sx={{ width: "100%" }}>
					{loading && page === 1 ? (
						<CircularProgress />
					) : (
						<LinearProgressWithLabel
							value={percentageDataExport}
							current={dataExport.length}
							total={total}
						/>
					)}
				</Box>
			</>
		</ModalLayout>
		// }
	);
}
