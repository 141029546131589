import {
	FormControl,
	Grid,
	OutlinedInputProps,
	FormHelperText,
	TextField,
} from "@mui/material";
import IconButton from "components/IconButton";
import { useFormContext, Controller } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import { maskCNPJ, maskCPF } from "utils/maskOutputs";
import { useState } from "react";
import { ModalSearchContributor } from "../Modal/ModalSearchContributor";

interface FieldCpfOrCnpjProps extends OutlinedInputProps {
	label: string;
	name: string;
	options?: Array<{
		label?: string;
		title?: string;
		value: string | number;
	}>;
	fullWidth?: boolean;
	required?: boolean;
	index?: number;
	readonly?: boolean;
	groupTitle?: string;
}

function FieldCpfOrCnpj({
	name,
	required,
	index,
	groupTitle,
}: FieldCpfOrCnpjProps) {
	const [isOpenModalSearch, setIsOpenModalSearch] = useState(false);
	const { control, setValue } = useFormContext();

	function handleSelectContributor(value: string) {
		const parsedValue = JSON.parse(value);
		if (parsedValue) {
			const {
				BAIRRO,
				CEP,
				CGCCPF,
				CIDADE,
				CODIGO,
				LOGRADOURO,
				NOME,
				NUM_RESID,
				TIPO,
				UF,
				EMAIL,
				COMPLEMENTO,
				NOMEBAIRRO,
				TIPOLOGRAD,
			} = parsedValue;
			setValue(`groups.${index}.fields.${name}`, CGCCPF ?? "");
			setValue(`groups.${index}.fields.owner.name`, NOME ?? "");

			// PROPRIETÁRIO/POSSUIDOR E ENDEREÇO PARA COBRANÇA
			setValue(`groups.${index}.fields.nome / razão social1`, NOME ?? "");
			setValue(`groups.${index}.fields.e-mail3`, EMAIL ?? "");
			setValue(`groups.${index}.fields.cep4`, CEP ?? "");
			setValue(`groups.${index}.fields.rua5`, LOGRADOURO ?? "");
			setValue(`groups.${index}.fields.número6`, NUM_RESID ?? "");
			setValue(`groups.${index}.fields.bairro7`, BAIRRO ?? "");
			setValue(`groups.${index}.fields.complemento8`, COMPLEMENTO ?? "");
			setValue(`groups.${index}.fields.cidade10`, CIDADE ?? "");

			setValue(`groups.${index}.fields.nome / razão social1`, NOME ?? "");
			setValue(`groups.${index}.fields.cpf/cnpj2`, CGCCPF ?? "");
			setValue(`groups.${index}.fields.cep3`, CEP ?? "");
			setValue(`groups.${index}.fields.rua4`, LOGRADOURO ?? "");
			setValue(`groups.${index}.fields.número5`, NUM_RESID ?? "");
			setValue(`groups.${index}.fields.bairro6`, BAIRRO ?? "");
			setValue(`groups.${index}.fields.complemento7`, COMPLEMENTO ?? "");
			setValue(`groups.${index}.fields.cidade8`, CIDADE ?? "");

			if (groupTitle === "Dados do requerente") {
				setValue(`groups.${index}.fields.owner.name`, NOME ?? "");
				setValue(`groups.${index}.fields.owner.document`, CGCCPF ?? "");
				setValue(`groups.${index}.fields.owner.address.zipCode`, CEP ?? "");
				setValue(
					`groups.${index}.fields.owner.address.street`,
					LOGRADOURO ? `${TIPOLOGRAD ?? " "} ${LOGRADOURO}` : ""
				);
				setValue(
					`groups.${index}.fields.owner.address.number`,
					NUM_RESID ?? ""
				);
				setValue(
					`groups.${index}.fields.owner.address.neighborhood`,
					BAIRRO ?? NOMEBAIRRO ?? ""
				);
				setValue(
					`groups.${index}.fields.owner.address.complement`,
					COMPLEMENTO ?? ""
				);
			}
			if (groupTitle === "Dados do infrator") {
				setValue(`groups.${index}.fields.owner.name`, NOME ?? "");
				setValue(`groups.${index}.fields.owner.identity`, CGCCPF ?? "");
				setValue(`groups.${index}.fields.owner.email`, EMAIL ?? "");
				setValue(`groups.${index}.fields.owner.address.zipCode`, CEP ?? "");
				setValue(`groups.${index}.fields.cepdoinfrator`, CEP ?? "");
				setValue(
					`groups.${index}.fields.owner.address.street`,
					LOGRADOURO ? `${TIPOLOGRAD ?? " "} ${LOGRADOURO}` : ""
				);
				setValue(
					`groups.${index}.fields.owner.address.number`,
					NUM_RESID ?? ""
				);
				setValue(
					`groups.${index}.fields.owner.address.neighborhood`,
					BAIRRO ?? NOMEBAIRRO ?? ""
				);
				setValue(
					`groups.${index}.fields.owner.address.complement`,
					COMPLEMENTO ?? ""
				);
			}
		}
	}

	const unMaskCpfOrCnpj = (value: string) => {
		const valueReplace = value ? value.replace(/\D/g, "") : "";
		return valueReplace;
	};

	return (
		<>
			<Grid item xs={12} marginTop={2}>
				<Controller
					control={control}
					name={`groups.${index}.fields.${name}`}
					rules={{
						required: required ? "Campo obrigatório" : false,
						validate: (value) => {
							const valueRegex = value ? value.replace(/\D/g, "") : "";
							if (valueRegex.length < 11 && valueRegex.length > 0) {
								return "CPF inválido";
							}
							if (
								valueRegex.length > 11 &&
								valueRegex.length < 14 &&
								valueRegex.length > 0
							) {
								return "CNPJ inválido";
							}
							if (valueRegex.length > 14 && valueRegex.length > 0) {
								return "CNPJ ou CPF inválido";
							}
						},
					}}
					render={(inputProps) => (
						<>
							<FormControl
								fullWidth
								error={inputProps.fieldState.error && true}
							>
								<TextField
									variant="outlined"
									type="text"
									InputLabelProps={{
										shrink: inputProps.field.value ? true : false,
									}}
									InputProps={{
										endAdornment: (
											<IconButton onClick={() => setIsOpenModalSearch(true)}>
												<SearchIcon />
											</IconButton>
										),
									}}
									label={`CPF ou CNPJ${required ? "*" : ""}`}
									fullWidth
									value={inputProps.field.value}
									onChange={(e) => {
										const { value } = e.target;
										const valueReplace = value ? value.replace(/\D/g, "") : "";
										inputProps.field.onChange(valueReplace);
									}}
									error={inputProps.fieldState.error && true}
									onBlur={(e) => {
										const { value } = e.target;
										const valueReplace = value.replace(/\D/g, "");
										if (valueReplace.length >= 14) {
											const text = valueReplace;
											const cnpj = maskCNPJ(text);

											e.target.value = cnpj;
											inputProps.field.onChange(e.target.value);
											return;
										}
										if (valueReplace.length >= 11) {
											const text = valueReplace;
											const cpf = maskCPF(text);

											e.target.value = cpf;
											inputProps.field.onChange(e.target.value);
											return;
										}
									}}
								/>
								{inputProps.fieldState.error && (
									<FormHelperText error={inputProps.fieldState.error && true}>
										{inputProps.fieldState.error.message + ""}
									</FormHelperText>
								)}
							</FormControl>
							{isOpenModalSearch && (
								<ModalSearchContributor
									defaultKeyword={unMaskCpfOrCnpj(inputProps.field.value)}
									open={isOpenModalSearch}
									handleClose={() => setIsOpenModalSearch(false)}
									handleSelect={(values) => handleSelectContributor(values)}
								/>
							)}
						</>
					)}
				/>
			</Grid>
		</>
	);
}

export default FieldCpfOrCnpj;
