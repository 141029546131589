import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
	Box,
	Fab,
	Container,
	TablePagination,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	TableBody,
	Table,
	Link,
	Typography,
	TableSortLabel,
	TextField,
} from "@mui/material";
import { Item } from "components/Paper";
import { Header } from "components/Header";
import { EmptyMessage } from "components/EmptyMessage";
import noUser from "assets/img/noUser.png";
import { FETCH_SERVICE_ORDERS } from "graphql/queries/fetchServiceOrders";
import { FetchServiceOrders } from "types/graphql/queries/fetchServiceOrders";
import AddIcon from "@mui/icons-material/Add";
import { ModalCreateOS } from "./Modal";
import { format } from "date-fns";
import { FETCH_DETAIL_COMPANY } from "graphql/queries/fetchDetailCompany";
import { FetchDetailCompany } from "types/graphql/queries/fetchDetailCompany";
import useDebounce from "hooks/useDebounce";
import { useVerifyNoHasRestriction } from "utils/useVerifyNoHasRestriction";
import LocalProvider from "components/LocalProvider";
import { useNavigate } from "react-router-dom";

export function OS() {
	const pageFromStorage: {
		[key: string]: number;
	} = JSON.parse(localStorage.getItem("page")) || 0;
	const rowsPerPageFromStorage: {
		[key: string]: number;
	} = JSON.parse(localStorage.getItem("rowsPerPage")) || 15;
	const [showCreate, setShowCreate] = useState(false);
	const [currentPage, setCurrentPage] = useState(pageFromStorage.os || 0);
	const [rowsPerPage, setRowsPerPage] = useState(
		rowsPerPageFromStorage.os || 15
	);
	const [orderBy, setOrderBy] = useState("protocols.created_at");
	const [direction, setDirection] = useState<"asc" | "desc">("desc");
	const [keyword, setKeyword] = useState("");

	const debouncedValue = useDebounce(keyword, 1000);
	const navigate = useNavigate();

	const { data, refetch } = useQuery<FetchServiceOrders>(FETCH_SERVICE_ORDERS, {
		variables: {
			limit: rowsPerPage,
			page: currentPage + 1,
			order_by: orderBy,
			direction: direction,
			keyword: debouncedValue,
		},
	});

	const subdomain = window.location.hostname.split(".")[0];

	const { data: dataCompany } = useQuery<FetchDetailCompany>(
		FETCH_DETAIL_COMPANY,
		{
			variables: {
				domain:
					subdomain === "localhost"
						? process.env.REACT_DEFAULT_DOMAIN || "lagoasanta"
						: subdomain,
			},
		}
	);

	function handleSort(name) {
		const isAsc = orderBy === name && direction === "asc";
		setDirection(isAsc ? "desc" : "asc");
		setOrderBy(name);
	}

	const noHasRestrictionCreateOS = useVerifyNoHasRestriction("OS_CRIAR");

	const handleNavigate = (code) => {
		navigate({
			pathname: "/protocolo/" + code,
			search: "from=os",
		});
	};

	return (
		<Box
			width="100%"
			display="flex"
			alignSelf="stretch"
			flexDirection="column"
			sx={{
				backgroundColor: "#f3f3f3",
			}}
			paddingBottom="1rem"
		>
			<Header title="Ordens de serviço" />

			<Container maxWidth="lg">
				<Box
					sx={{
						backgroundImage: `url(${dataCompany && dataCompany.detailCompany.logo
							})`,
						backgroundSize: "contain",
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
					}}
					minHeight="70px"
					marginTop={2}
					marginBottom={3}
				/>

				<Item
					sx={{
						paddingX: 0,
						paddingY: "1px",
					}}
				>
					<Box sx={{ padding: 2 }}>
						<TextField
							fullWidth
							variant="standard"
							name="keyword"
							type="search"
							label="Pesquisar..."
							value={keyword}
							onChange={(event) => {
								setKeyword(event.target.value);
							}}
						/>
					</Box>

					<TableContainer style={{ marginTop: "10px" }}>
						{data?.fetchServiceOrders?.data.length === 0 && (
							<>
								<EmptyMessage message="Nenhuma ordem de serviço encontrado 😥" />
								<Box
									marginTop={2}
									sx={{
										backgroundImage: `url(${noUser})`,
										backgroundRepeat: "no-repeat",
										backgroundPosition: "center",
										backgroundSize: "contain",
										height: "200px",
									}}
								/>
							</>
						)}
						{data?.fetchServiceOrders?.data.length !== 0 && (
							<>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>
												<TableSortLabel
													active={orderBy === "protocols.created_at"}
													direction={
														orderBy === "protocols.created_at"
															? direction
															: "asc"
													}
													onClick={() => handleSort("protocols.created_at")}
												>
													N° Protocolo
												</TableSortLabel>
											</TableCell>
											<TableCell>Assunto</TableCell>
											<TableCell>Fiscal</TableCell>
											<TableCell>Endereço</TableCell>
											<TableCell>Status</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data?.fetchServiceOrders?.data.map((item) => (
											<>
												<TableRow key={item.id}>
													<TableCell>
														<Link
															onClick={() => handleNavigate(item.code)}
															underline="hover"
															sx={{
																fontWeight: 600,
																display: "block",
																cursor: "pointer",
															}}
														>
															{item.code}
														</Link>
														<Typography variant="caption">
															Criado em{" "}
															{format(
																new Date(item.created_at),
																"dd/MM/yyyy HH:mm"
															)}
														</Typography>
													</TableCell>
													<TableCell>{item.subject?.name || "-"}</TableCell>
													<TableCell>{item.responsible?.name || "-"}</TableCell>
													<TableCell>
														{item.street}
														{", "}
														{item.number}
														{", "}
														{<br />}
														{item.district?.name}
														{", "}
														{item.complement}
														{" - "}
														{item.zipcode}
													</TableCell>
													<TableCell>{item.situation || "-"}</TableCell>
												</TableRow>
											</>
										))}
									</TableBody>
								</Table>
							</>
						)}
					</TableContainer>

					<ModalCreateOS
						refetch={refetch}
						open={showCreate}
						handleClose={() => setShowCreate(false)}
					/>

					{noHasRestrictionCreateOS && (
						<Fab
							color="primary"
							onClick={() => setShowCreate(true)}
							style={{ position: "fixed", bottom: 48, right: 48 }}
						>
							<AddIcon />
						</Fab>
					)}

					{data?.fetchServiceOrders?.total !== 0 && (
						<LocalProvider locale="ptBR">
							<TablePagination
								showLastButton
								showFirstButton
								rowsPerPageOptions={[5, 15, 30, 60, 100]}
								component="div"
								count={data?.fetchServiceOrders?.total}
								rowsPerPage={rowsPerPage}
								page={currentPage}
								onPageChange={(event, newPage) => {
									setCurrentPage(newPage);
									const pageFromStorage: {
										[key: string]: number;
									} = JSON.parse(localStorage.getItem("page")) || 1;

									localStorage.setItem(
										"page",
										JSON.stringify({
											...pageFromStorage,
											os: newPage,
										})
									);
								}}
								onRowsPerPageChange={(event) => {
									setRowsPerPage(parseInt(event.target.value));
									setCurrentPage(0);
									const rowsPerPageFromStorage: {
										[key: string]: number;
									} = JSON.parse(localStorage.getItem("rowsPerPage")) || 15;
									localStorage.setItem(
										"rowsPerPage",
										JSON.stringify({
											...rowsPerPageFromStorage,
											os: parseInt(event.target.value),
										})
									);

									const pageFromStorage: {
										[key: string]: number;
									} = JSON.parse(localStorage.getItem("page")) || 0;
									localStorage.setItem(
										"page",
										JSON.stringify({
											...pageFromStorage,
											os: 0,
										})
									);
								}}
							/>
						</LocalProvider>
					)}
				</Item>
			</Container>
		</Box>
	);
}
