export function getTheme(status: string) {
	if (status === "Concluido") {
		return "#72b77c";
	}
	if (status === "Em andamento") {
		return "#ffc107";
	}
	if (status === "Cancelado") {
		return "#c24040";
	}
	if (status === "Suspenso") {
		return "#000";
	}
	return "#CCC";
}
