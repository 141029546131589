import {
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	OutlinedInput,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

interface FieldTotalAmountFineProps {
	name: string;
	placeholder?: string;
	required?: boolean;
	index?: number;
	readonly?: boolean;
}

function FieldTotalAmountFine({
	name,
	required,
	index,
	readonly,
}: FieldTotalAmountFineProps) {
	const { control } = useFormContext();
	return (
		<Grid item xs={12}>
			<Controller
				name={`groups.${index}.fields.${name}`}
				control={control}
				rules={{
					required: required ? "Campo obrigatório" : false,
					onChange(event) {
						const { value } = event.target;
						const regex = /[^0-9.,]/g;
						if (regex.test(value)) {
							event.target.value = value.replace(regex, "");
						}
					},
				}}
				render={(inputProps) => (
					<FormControl
						variant="outlined"
						fullWidth
						error={inputProps.fieldState.error && true}
					>
						<InputLabel
							htmlFor="totalAmountFine"
							error={inputProps.fieldState.error && true}
						>
							Valor total da multa (UPFMLS)
						</InputLabel>
						<OutlinedInput
							readOnly={readonly}
							error={inputProps.fieldState.error && true}
							id="totalAmountFine"
							value={inputProps.field.value}
							onChange={(e) => inputProps.field.onChange(e.target.value)}
							type={"text"}
							label={`Valor total da multa (UPFMLS)${required ? "*" : ""}`}
							fullWidth
						/>
						{inputProps.fieldState.error && (
							<FormHelperText error={inputProps.fieldState.error && true}>
								{inputProps.fieldState.error.message + ""}
							</FormHelperText>
						)}
					</FormControl>
				)}
			/>
		</Grid>
	);
}

export default FieldTotalAmountFine;
