import { gql } from "@apollo/client";

export const UPDATE_HOLIDAY = gql`
	mutation updateHoliday(
		$id: Int!
		$name: String!
		$day: Int!
		$month: Int!
		$year: Int
	) {
		updateHoliday(
			id: $id
			name: $name
			day: $day
			month: $month
			year: $year
		)
	}
`;
