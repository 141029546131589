import { gql } from "@apollo/client";

export const COUNT_PERFIS = gql`
	query {
		countPerfis {
			qtd_perfis
			qtd_users
			qtd_restrictions
		}
	}
`;
