import { useMutation } from "@apollo/client";
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Stack,
} from "@mui/material";
import { UPDATE_DISTRICT } from "graphql/mutations/updateDistrict";
import { Select } from "components/Select";
import { useForm, FormProvider } from "react-hook-form";
import { useSnackbar } from "notistack";
import Input from "components/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

type EditDialogProps = {
	open: boolean;
	handleClose: () => void;
	data: any;
	onEdit: () => void;
};

const schema = yup.object().shape({
	name: yup.string().required("Campo obrigatório"),
});

export default function EditDialog({
	open,
	handleClose,
	data,
	onEdit,
}: EditDialogProps) {
	const methods = useForm({
		defaultValues: {
			name: data.name,
			latitude: data.latitude || null,
			longitude: data.longitude || null,
			status: data.status
		},
		resolver: yupResolver(schema),
	});

	const { enqueueSnackbar } = useSnackbar();

	// Setting mutations
	const [updateDistrict, { loading }] = useMutation(UPDATE_DISTRICT);

	const {
		reset,
		handleSubmit,
	} = methods;

	const onSubmit = async (values: any) => {
		try {
			await updateDistrict({
				variables: {
					id: data.id,
					name: values.name,
					latitude: values.latitude,
					longitude: values.longitude,
					city_id: data.city.id,
					status: values.status,
				},
			});
			onEdit();
			reset();
		} catch (err) {
			enqueueSnackbar("Erro ao editar bairro", { variant: "error" });
		}
	};

	const status = [
		{
			label: "Ativo",
			title: "Ativo",
			value: "Ativo",
		},
		{
			label: "Inativo",
			title: "Inativo",
			value: "Inativo",
		},
	];

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="paper"
				maxWidth="sm"
				fullWidth={true}
			>
				<FormProvider {...methods}>
					<DialogTitle id="scroll-dialog-title">Editar bairro</DialogTitle>

					<DialogContent>
						<Stack spacing={2} marginTop={2}>
							<Input name="name" autoFocus placeholder="Nome*" type="text" />
							<Select
								label="Selecione um status*"
								name="status"
								options={status}
							/>
							<Input name="latitude" placeholder="Latitude" type="text" />
							<Input name="longitude" placeholder="Longitude" type="text" />
						</Stack>
					</DialogContent>

					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Cancelar
						</Button>
						<Button
							onClick={handleSubmit(onSubmit)}
							variant="contained"
							color="primary"
							disabled={loading}
						>
							{loading && (
								<CircularProgress size={14} style={{ marginRight: 12 }} />
							)}
							Editar
						</Button>
					</DialogActions>
				</FormProvider>
			</Dialog>
		</>
	);
}
