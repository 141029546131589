import {
	CircularProgress as CircularProgressMaterial,
	CircularProgressProps,
	Box,
} from "@mui/material";

export function CircularProgress({ ...rest }: CircularProgressProps) {
	return (
		<Box padding={2} textAlign="center">
			<CircularProgressMaterial {...rest} />
		</Box>
	);
}
