import { gql } from "@apollo/client";

export const TOTAL_UPF = gql`
	query {
		totalUpf {
			total
			amount
		}
	}
`;
