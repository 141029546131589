import { Grid, Box, Typography } from "@mui/material";

interface FieldTextProps {
	label?: string;
	value?: string;
}

function FieldSelect({ label, value }: FieldTextProps) {
	return (
		<Grid item xs={12}>
			<Box>
				<Typography variant="caption">{label}</Typography>
				<Typography variant="subtitle2">{value}</Typography>
			</Box>
		</Grid>
	);
}

export default FieldSelect;
