import { useLazyQuery, useQuery } from "@apollo/client";
import { Alert, Box, Stack, Typography } from "@mui/material";
import Button from "components/Button";
import { ModalSearchRealty } from "components/DynamicForm/Modal/ModalSearchRealty";
import { DescriptionAlert } from "components/Warning";
import { format, sub } from "date-fns";
import { FETCH_PROTOCOLS } from "graphql/queries/fetchProtocols";
import { FETCH_SUBJECTS } from "graphql/queries/fetchSubjects";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FetchProtocols } from "types/graphql/queries/fetchProtocols";
import { FetchSubjects } from "types/graphql/queries/fetchSubjects";

interface StepLocationProps {
	goToNextStep: () => void;
	goToPrevStep: () => void;
}

export default function StepLocation({
	goToNextStep,
	goToPrevStep,
}: StepLocationProps) {
	const [open, setOpen] = useState(false);
	const [location, setLocation] = useState<any>({});
	const [dataProtocol, setDataProtocol] = useState<any>(null);
	const { setValue: setValueContext, getValues } = useFormContext();
	const subjectId = getValues("subject");

	const [fetchProtocols] = useLazyQuery<FetchProtocols>(FETCH_PROTOCOLS);

	const { data: dataSubjects } = useQuery<FetchSubjects>(FETCH_SUBJECTS, {
		variables: {
			limit: 1000,
		},
	});

	const handleSelectRealty = async (realty: string) => {
		const parsedRealty = JSON.parse(realty);
		setLocation(parsedRealty);
		if (parsedRealty) {
			const {
				INDICECADASTRAL,
				LOGRADOURO,
				NUMERO,
				NOMEBAIRRO,
				COMPLEMENTO,
				NOME,
				LOTEAMLOTE,
				LOTEAMQUADRA,
				CGCCPF,
				CODIGO,
				AREATOTAL,
				TIPOLOGRAD,
				AREATERRENO,
				CIDADE,
				UF,
				CEP,
			} = parsedRealty;
			// Input de dados do infrator no index 0
			setValueContext(
				"groups.0.fields.owner.address.street",
				LOGRADOURO ? `${TIPOLOGRAD ?? " "} ${LOGRADOURO}` : ""
			);
			setValueContext("groups.0.fields.owner.address.number", NUMERO);
			setValueContext("groups.0.fields.owner.address.neighborhood", NOMEBAIRRO);
			setValueContext("groups.0.fields.owner.address.complement", COMPLEMENTO);
			setValueContext("groups.0.fields.owner.address.city", CIDADE ?? "");
			setValueContext("groups.0.fields.owner.address.uf", UF ?? "");
			setValueContext("groups.0.fields.owner.name", NOME ?? "");
			setValueContext("groups.0.fields.owner.document", CGCCPF ?? "");

			setValueContext(`groups.0.fields.nome / razão social1`, NOME ?? "");
			setValueContext(`groups.0.fields.cpf/cnpj2`, CGCCPF ?? "");
			setValueContext(`groups.0.fields.cep3`, CEP ?? "");
			setValueContext(`groups.0.fields.rua4`, LOGRADOURO ?? "");
			setValueContext(`groups.0.fields.número5`, NUMERO ?? "");
			setValueContext(`groups.0.fields.bairro6`, NOMEBAIRRO ?? "");
			setValueContext(`groups.0.fields.complemento7`, COMPLEMENTO ?? "");
			setValueContext(`groups.0.fields.cidade8`, CIDADE ?? "");

			// Input de location no index 0
			setValueContext(
				"groups.0.fields.location.municipalRegistration",
				INDICECADASTRAL ?? ""
			);
			setValueContext("groups.0.fields.location.lote", LOTEAMLOTE ?? "");
			setValueContext(
				"groups.0.fields.location.street",
				LOGRADOURO ? `${TIPOLOGRAD ?? " "} ${LOGRADOURO}` : ""
			);
			setValueContext("groups.0.fields.location.quadra", LOTEAMQUADRA ?? "");
			setValueContext(
				"groups.0.fields.location.neighborhood",
				NOMEBAIRRO ?? ""
			);
			setValueContext("groups.0.fields.location.number", NUMERO ?? "");
			setValueContext("groups.0.fields.location.codigo", CODIGO ?? "");
			setValueContext("groups.0.fields.location.complement", COMPLEMENTO ?? "");
			setValueContext("groups.0.fields.location.areatotal", AREATOTAL ?? "");
			setValueContext(
				"groups.0.fields.location.areaterreno",
				AREATERRENO ?? ""
			);

			// Input de location no index 1
			setValueContext(
				"groups.1.fields.location.municipalRegistration",
				INDICECADASTRAL ?? ""
			);
			setValueContext("groups.1.fields.location.lote", LOTEAMLOTE ?? "");
			setValueContext(
				"groups.1.fields.location.street",
				LOGRADOURO ? `${TIPOLOGRAD ?? " "} ${LOGRADOURO}` : ""
			);
			setValueContext("groups.1.fields.location.quadra", LOTEAMQUADRA ?? "");
			setValueContext(
				"groups.1.fields.location.neighborhood",
				NOMEBAIRRO ?? ""
			);
			setValueContext("groups.1.fields.location.number", NUMERO ?? "");
			setValueContext("groups.1.fields.location.codigo", CODIGO ?? "");
			setValueContext("groups.1.fields.location.complement", COMPLEMENTO ?? "");
			setValueContext("groups.1.fields.location.areatotal", AREATOTAL ?? "");
			setValueContext(
				"groups.1.fields.location.areaterreno",
				AREATERRENO ?? ""
			);
			
			setValueContext(`groups.1.fields.nome / razão social1`, NOME ?? "");
			setValueContext(`groups.1.fields.cpf/cnpj2`, CGCCPF ?? "");
			setValueContext(`groups.1.fields.cep3`, CEP ?? "");
			setValueContext(`groups.1.fields.rua4`, LOGRADOURO ?? "");
			setValueContext(`groups.1.fields.número5`, NUMERO ?? "");
			setValueContext(`groups.1.fields.bairro6`, NOMEBAIRRO ?? "");
			setValueContext(`groups.1.fields.complemento7`, COMPLEMENTO ?? "");
			setValueContext(`groups.1.fields.cidade8`, CIDADE ?? "");

			setValueContext("location", parsedRealty);

			const actualDate: Date = new Date();
			const startDate = sub(actualDate, { months: 6 });

			const { data } = await fetchProtocols({
				fetchPolicy: "network-only",
				variables: {
					start: format(startDate, "yyyy-MM-dd"),
					finish: format(actualDate, "yyyy-MM-dd"),
					subject_ids: [subjectId],
					municipal_registration: parsedRealty.INDICECADASTRAL,
				},
			});

			setDataProtocol(data.fetchProtocols);
		}
	};

	useEffect(() => {
		const formLocation = getValues("location");
		if (formLocation) {
			setLocation(formLocation);
		}
	}, []);

	return (
		<>
			<Alert
				severity="warning"
				sx={{
					marginBottom: 2,
				}}
			>
				O preenchimento dessa sessão de localização do formulário não é
				obrigatório
			</Alert>
			
			<Stack>
				<Button
					variant="contained"
					color="info"
					style={{ maxWidth: 200 }}
					onClick={() => {
						setOpen(!open);
					}}
				>
					Buscar localização
				</Button>

				{dataProtocol && dataProtocol.total > 0 && dataSubjects && (
					<DescriptionAlert
						zipcode={false}
						info={location.INDICECADASTRAL}
						protocols={dataProtocol.data}
						numberProtocols={dataProtocol.total}
						subjectName={
							dataSubjects.fetchSubjects.data.find(
								(item) => item.id === subjectId
							)?.name || null
						}
					/>
				)}

				{!(Object.keys(location).length === 0) && (
					<Stack
						style={{
							marginTop: 24,
							marginRight: 16,
							flexDirection: "row",
							justifyContent: "space-between",
						}}
						spacing={16}
					>
						<Box
							style={{
								flexDirection: "column",
							}}
						>
							<Box
								style={{
									marginRight: 16,
								}}
							>
								<Typography variant="caption">Indíce cadastral:</Typography>
								<Typography variant="subtitle2">
									{location.INDICECADASTRAL}
								</Typography>
							</Box>
							<Box>
								<Typography variant="caption">Endereço:</Typography>
								<Typography variant="subtitle2">
									{`${location.TIPOLOGRAD} ${location.LOGRADOURO}`}
									{location.NUMERO && ", nº " + location.NUMERO}{" "}
									{location.COMPLEMENTO && ` - ${location.COMPLEMENTO}`}
									{location.NOMEBAIRRO && " - " + location.NOMEBAIRRO}
								</Typography>
							</Box>
							<Box>
								<Typography variant="caption">
									Lote/Quadra/Área Total/Área Terreno:
								</Typography>
								<Typography variant="subtitle2">
									{location.LOTEAMLOTE}{" "}
									{location.LOTEAMQUADRA && " / " + location.LOTEAMQUADRA}{" "}
									{location.AREATOTAL && " / " + location.AREATOTAL + "m2"}
									{location.AREATERRENO && " / " + location.AREATERRENO + "m2"}
								</Typography>
							</Box>
							<Box>
								<Typography variant="caption">Nome:</Typography>
								<Typography variant="subtitle2">{location.NOME}</Typography>
							</Box>
							<Box>
								<Typography variant="caption">Documento:</Typography>
								<Typography variant="subtitle2">{location.CGCCPF}</Typography>
							</Box>
						</Box>
					</Stack>
				)}

				{open && (
					<ModalSearchRealty
						open={open}
						handleClose={() => setOpen(!open)}
						handleSelect={(value) => handleSelectRealty(value)}
					/>
				)}
			</Stack>
			<Box sx={{ my: 2 }}>
				<Box>
					<Button
						variant="contained"
						onClick={goToNextStep}
						sx={{ mt: 1, mr: 1 }}
					>
						Próximo
					</Button>
					<Button onClick={goToPrevStep} sx={{ mt: 1, mr: 1 }}>
						Voltar
					</Button>
				</Box>
			</Box>
		</>
	);
}
