export const titles = [
	{
		id: 1,
		reverse: false,
		textAlign: "left" as const,
	},
	{
		id: 2,
		reverse: true,
		textAlign: "left" as const,
	},
	{
		id: 3,
		reverse: false,
		textAlign: "center" as const,
	},
	{
		id: 4,
		reverse: true,
		textAlign: "center" as const,
	},
];

export const dynamicGraphics = [
	{
		id: 1,
		variant: "bar",
		rotated: false,
	},
	{
		id: 2,
		variant: "barRotated",
		originalVariant: "bar",
		rotated: true,
	},
	{
		id: 3,
		variant: "simpleLine",
	},
	{
		id: 4,
		variant: "stackedBar",
		rotated: false,
	},
	{
		id: 5,
		variant: "stackedBarRotaded",
		originalVariant: "stackedBar",
		rotated: true,
	},
	{
		id: 6,
		variant: "doughnut",
	},
	{
		id: 7,
		variant: "pie",
	},
	{
		id: 8,
		variant: "ssidChart"
	},
	{
		id: 9,
		variant: "taskChart",
	}
];

export const visualizationRules = [
	{
		label: "Somente eu visualizo",
		value: "Eu",
	},
	{
		label: "Todos visualizam",
		value: "Todos",
	},
	{
		label: "Compartilhar com um perfil específico",
		value: "Perfis",
	},
];

export const type = [
	{
		label: "Concluídos",
		value: "Concluídos",
	},
	{
		label: "Em andamento",
		value: "Em andamento",
	},
	{
		label: "Cancelados",
		value: "Cancelados",
	},
	{
		label: "Suspensos",
		value: "Suspensos",
	},
	{
		label: "Atrasados",
		value: "Atrasados",
	},
	{
		label: "UPFS",
		value: "UPFS",
	},
	{
		label: "Denúncias",
		value: "Denúncias",
	},
	{
		label: "Ordens de Serviço",
		value: "Ordens de Serviço",
	},
	{
		label: "Por Tarefa",
		value: "Por Tarefa"
	}
];

export const dataSource = [
	{
		label: "Protocolos",
		value: "Protocolos",
	},
	{
		label: "Tarefas",
		value: "Tarefas",
	},
];

export const defaultData = {
	bar: [
		{
			key: "Concluídos",
			value: Math.floor(Math.random() * 100),
		},
	],
	barRotated: [
		{
			key: "Concluídos",
			value: Math.floor(Math.random() * 100),
		},
	],
	simpleLine: [
		{
			year: "2022",
			Concluídos: Math.floor(Math.random() * 100),
		},
		{
			year: "2023",
			Concluídos: Math.floor(Math.random() * 100),
		},
	],
	stackedBar: [
		{
			year: "2022",
			Concluídos: Math.floor(Math.random() * 100),
		},
		{
			year: "2023",
			Concluídos: Math.floor(Math.random() * 100),
		},
	],
	stackedBarRotaded: [
		{
			year: "2022",
			Concluídos: Math.floor(Math.random() * 100),
		},
		{
			year: "2023",
			Concluídos: Math.floor(Math.random() * 100),
		},
	],
	doughnut: [
		{
			key: "Concluídos",
			value: Math.floor(Math.random() * 100),
		},
	],
	pie: [
		{
			key: "Concluídos",
			value: Math.floor(Math.random() * 100),
		},
	],
	ssidChart: [
		{
			key: "Concluídos",
			value: Math.floor(Math.random() * 100),
		},
	],
	taskChart: [
		
	],
};

export const defaultSeries = {
	bar: [],
	barRotated: [],
	simpleLine: [
		{
			argumentField: "year",
			valueField: "Concluídos",
			name: "Concluídos",
		},
	],
	stackedBar: [
		{
			argumentField: "year",
			valueField: "Concluídos",
			name: "Concluídos",
		},
	],
	stackedBarRotaded: [
		{
			argumentField: "year",
			valueField: "Concluídos",
			name: "Concluídos",
		},
	],
	doughnut: [],
	pie: [],
	ssidChart: [],
	taskChart: [],
};
