import { useMutation } from "@apollo/client";
import { UNDO_LAST_TASK } from "graphql/mutations/undoLastTask";
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
} from "@mui/material";
import { DataUser } from "types/graphql/queries/fetchUsers";
import { useParams } from "react-router-dom";
import LoadingButton from "components/LoadingButton";

type UndoDialogProps = {
	open: boolean;
	handleClose: () => void;
	onDelete: () => void;
	data: DataUser;
	refetch: () => void;
};

export default function UndoDialog({
	open,
	handleClose,
	onDelete,
	data,
	refetch,
}: UndoDialogProps) {
	// Setting mutation
	const [undoLastTask, { loading }] = useMutation(UNDO_LAST_TASK);

	const { code } = useParams<{ code: string }>();

	// Chamando a mutation de remoção
	const handleDelete = () => {
		undoLastTask({
			variables: {
				code_protocol: code,
			},
		}).then(() => {
			refetch();
			onDelete();
		});
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="paper"
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogTitle id="scroll-dialog-title">Desfazer tarefa</DialogTitle>

				<DialogContent>
					<p>
						Você deseja realmente desfazer essa tarefa? Todas as informações
						serão perdidas.
					</p>
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancelar
					</Button>
					<LoadingButton
						onClick={handleDelete}
						variant="contained"
						color="primary"
						loading={loading}
					>
						Desfazer
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
}
