import { Header } from "components/Header";
import {
	Box,
	Container,
	MenuItem,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import { DocumentOfList } from "../Inbox/DocumentOfList";
import { CircularProgress } from "components/CircularProgress";
import GenericInput from "components/Input/GenericInput";
import SearchIcon from "@mui/icons-material/Search";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { FetchDocuments } from "types/graphql/queries/fetchDocuments";
import useDebounce from "hooks/useDebounce";
import { FETCH_DOCUMENTS } from "graphql/queries/fetchDocuments";
import { useAuthContext } from "context/AuthProvider";
import { Pagination } from "components/Pagination";

export function Sent() {
	const [search, setSearch] = useState("");
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const { state } = useAuthContext();
	const { data, loading, refetch } = useQuery<FetchDocuments>(FETCH_DOCUMENTS, {
		fetchPolicy: "network-only",
		variables: {
			sender_id: state.userInfo.user.id,
			keyword: useDebounce(search, 500),
			page: page,
			limit: rowsPerPage,
		},
	});
	const [documentToSign, setDocumentToSign] = useState(null);

	const isSender = data?.fetchDocuments.data.some((document) => {
		return document.sender.id === state.userInfo.user.id;
	});

	useEffect(() => {
		if (data && documentToSign) {
			const newDocument = data.fetchDocuments.data.find((document) => {
				return document.id === documentToSign.id;
			});
			setDocumentToSign(newDocument);
		}
	}, [data]);

	const handleChangePage = (
		event: React.ChangeEvent<unknown>,
		value: number
	) => {
		setPage(value);
	};

	function handleRowsPerPageChange(event) {
		const value = parseInt(event.target.value);
		if (!isNaN(value)) {
			setPage(1);
			setRowsPerPage(value);
		}
	}

	const isLoading = loading;

	return (
		<>
			<Box
				width="100%"
				display="flex"
				alignSelf="stretch"
				flexDirection="column"
				sx={{
					backgroundColor: "#f3f3f3",
				}}
				paddingBottom="1rem"
			>
				<Header title="Enviados" />
				<Container maxWidth="lg" sx={{ mt: "40px" }}>
					<GenericInput
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						placeholder="Pesquisar por documento"
						endAdornment={<SearchIcon />}
					/>
					<Stack direction="row" mt={"70px"} spacing={2}>
						<Box flex={2}>Documentos</Box>
						<Box flex={1}>Situação</Box>
						<Box flex={1}>Última atualização</Box>
						{isSender && <Box flex={0.2}>{""}</Box>}
					</Stack>
					{data?.fetchDocuments.data.length === 0 && (
						<Stack
							direction="row"
							mt="10px"
							alignItems={"center"}
							justifyContent={"center"}
							sx={{
								backgroundColor: "#fff",
							}}
							spacing={2}
							padding="10px"
						>
							<Typography variant="subtitle2" textAlign={"center"}>
								Nenhum documento encontrado.
							</Typography>
						</Stack>
					)}
					{isLoading ? (
						<CircularProgress />
					) : (
						<>
							{data.fetchDocuments.data.map((document) => {
								const recipients = document.recipients
									.map((recipient) => recipient.user.name)
									.join(", ");

								const isSender = document.sender.id === state.userInfo.user.id;
								const findRecipient = document.recipients.find((recipient) => {
									return recipient.user.id === state.userInfo.user.id;
								});

								const senderHasNotSigned = findRecipient?.is_signed === false;
								const allRecipientsHasSigned = document.recipients.every(
									(recipient) => {
										return recipient.is_signed === true;
									}
								);
								const someRecipientsHasNotSigned = document.recipients.some(
									(recipient) => {
										return recipient.is_signed === false;
									}
								);

								const numberOfRecipientsHasSigned = document.recipients.filter(
									(recipient) => {
										return recipient.is_signed === true;
									}
								).length;

								const percentageRecipientsHasSigned = Math.round(
									(numberOfRecipientsHasSigned / document.recipients.length) *
										100
								);

								return (
									<DocumentOfList
										key={document.id}
										allRecipientsHasSigned={allRecipientsHasSigned}
										someRecipientsHasNotSigned={someRecipientsHasNotSigned}
										percentageRecipientsHasSigned={
											percentageRecipientsHasSigned
										}
										document={document}
										isSender={isSender}
										senderHasNotSigned={senderHasNotSigned}
										findRecipient={findRecipient}
										refetch={refetch}
										numberOfRecipientsHasSigned={numberOfRecipientsHasSigned}
										recipients={recipients}
										documentToSign={documentToSign}
										setDocumentToSign={(document) =>
											setDocumentToSign(document)
										}
									/>
								);
							})}
							<Stack
								display="flex"
								flexDirection={["row"]}
								justifyContent="flex-end"
								alignItems={"center"}
							>
								<p style={{ fontSize: "0.9rem" }}>Linhas por página:</p>
								<Select
									variant="standard"
									disableUnderline
									style={{
										fontSize: "0.9rem",
										marginRight: "10px",
										marginLeft: "10px",
									}}
									sx={{ marginX: "10px" }}
									value={rowsPerPage}
									onChange={handleRowsPerPageChange}
								>
									<MenuItem value={5}>5</MenuItem>
									<MenuItem value={15}>15</MenuItem>
									<MenuItem value={30}>30</MenuItem>
									<MenuItem value={60}>60</MenuItem>
									<MenuItem value={100}>100</MenuItem>
								</Select>

								<Pagination
									showFirstButton
									showLastButton
									count={data.fetchDocuments.last_page}
									page={page}
									onChange={handleChangePage}
									boundaryCount={2}
									sx={{
										"& .MuiPagination-ul": {
											justifyContent: "flex-end",
										},
									}}
								/>
							</Stack>
						</>
					)}
				</Container>
			</Box>
		</>
	);
}
