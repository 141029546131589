/* eslint-disable no-mixed-spaces-and-tabs */
import { Box, Link, Stack, Typography, Grid, Button } from "@mui/material";
import { ModalLayout } from "components/ModalLayout";
import { Fragment, useState } from "react";
import { ModalLayoutProps } from "types/ModalLayout";
import { useForm, FormProvider } from "react-hook-form";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { PrintButton } from "../PrintButton";
import { Interweave } from "interweave";
import FieldLocation from "components/ProtocolDetail/FieldsDetailOccurrence/FieldLocation";
import FieldOffenderData from "components/ProtocolDetail/FieldsDetailOccurrence/FieldOffender";
import FieldApplicant from "components/ProtocolDetail/FieldsDetailOccurrence/FieldApplication";
import FieldWitness from "components/ProtocolDetail/FieldsDetailOccurrence/FieldWitness";
import FieldReceiver from "components/ProtocolDetail/FieldsDetailOccurrence/FieldReceiver";
import FieldProperty from "components/ProtocolDetail/FieldsDetailOccurrence/FieldProperty";
import FieldCheckbox from "components/ProtocolDetail/FieldsDetailOccurrence/FieldCheckbox";
import FieldDate from "components/ProtocolDetail/FieldsDetailOccurrence/FieldDate";
import { format, set } from "date-fns";
import FieldSelect from "components/ProtocolDetail/FieldsDetailOccurrence/FieldSelect";
import { Task } from "types/graphql/queries/detailProtocol";
import ModalDetailDescription from "./ModalDetailDescription";
import { DetailProtocol } from "types/graphql/queries/detailProtocol";

type ModalDetailOccurrenceProps = ModalLayoutProps & {
	refetch: () => void;
	task: Task;
	formResponseOccurrence?: DetailProtocol["detailProtocol"]["tasks"][0]["detail"]["form_response"];
};

export function ModalDetailOccurrence({
	open,
	handleClose,
	task,
	refetch,
	formResponseOccurrence
}: ModalDetailOccurrenceProps) {
	const [files, setFiles] = useState([]);
	const [isOpenModalDescription, setIsOpenModalDescription] = useState(false);
	const methods = useForm();

	const formGroups = JSON.parse(task?.detail?.form_groups);
	const formResponse = JSON.parse(
		formResponseOccurrence.length <= 0 && task.concluded_at !== null
			? formResponseOccurrence
			: task?.detail?.form_response
	);

	const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const dataFiles = Array.from(e.target.files);
		setFiles(dataFiles);
		setIsOpenModalDescription((state) => !state);
	};

	const handleCloseModal = () => {
		setIsOpenModalDescription((state) => !state);
		handleClose();
	};

	const regexIfIsHtml = /(<([^>]+)>)/gi;

	const formResponseIsArray = Array.isArray(formResponse);

	let location = null;

	//PALEATIVO - PENSAR EM UMA SOLUÇÃO MELHOR
	if (formResponseIsArray) {
		formResponse.forEach((item) => {
			if (item?.location) {
				const isEmptyArray = item.location.length === 0;
				if (isEmptyArray) {
					return (location = null);
				}
				location = item.location;
			}
		});
	}

	return (
		<FormProvider {...methods}>
			<ModalLayout
				open={open}
				handleClose={handleClose}
				isNotShowButtonSubmit
				isShowAdditionalButton
				variantButtonClose="contained"
				title="Detalhes"
				componentAdditionalButton={
					<PrintButton type="task" code={task.id} task={task} />
				}
				additionalButtonIsComponent
			>
				<Stack spacing={2} flex={1}>
					<Box
						display="flex"
						justifyContent="space-between"
						columnGap={2}
						flexWrap="wrap"
					>
						<Box display="flex" flex={1} columnGap={10} flexWrap="wrap">
							<Box>
								<Typography variant="caption">Tipo</Typography>
								<Typography variant="subtitle2">{task.type}</Typography>
							</Box>
							<Box>
								<Typography variant="caption">Nome da tarefa</Typography>
								<Typography variant="subtitle2">{task.name}</Typography>
							</Box>
							<Box flex={1}>
								<Typography variant="caption">
									Designado para o perfil
								</Typography>
								<Typography variant="subtitle2">
									{task.perfil ? task.perfil.name : " - "}
								</Typography>
							</Box>
						</Box>
						<Box>
							<Typography variant="caption">Prazo</Typography>
							<Typography variant="subtitle2" textAlign="center">
								{task.expire_at || "-"}
							</Typography>
						</Box>
					</Box>
					<Grid container spacing={0} marginTop={0}>
						{formGroups &&
							formGroups.map((group, indexGroup) => (
								<Fragment key={indexGroup}>
									{group.title && (
										<Grid item xs={12}>
											<Typography
												key={group.title}
												variant="h6"
												fontSize="1rem"
											>
												{group.title}
											</Typography>
										</Grid>
									)}
									{group.inputs.map((input) => {
										const keys = input.name.split(".");
										let values = null;
										if (formResponseIsArray) {
											values = formResponse[indexGroup];
											if (!values) return null;
											const hasKeysInTheFormResponse = keys.some((key) => {
												return values[key] ?? false;
											});
											if (hasKeysInTheFormResponse) {
												values = keys.reduce((acc, key) => {
													return acc ? acc[key] : " - ";
												}, values);
											} else {
												values = " - ";
											}
										} else {
											const hasKeysInTheFormResponse = keys.some((key) => {
												return formResponse ? formResponse[key] : false;
											});
											
											if (hasKeysInTheFormResponse) {
												values = keys.reduce((acc, key) => {
													return acc ? acc[key] : " - ";
												}, formResponse);
											} else {
												values = " - ";
											}
										}

										if (input.type === "signbutton") {
											return (
												<Fragment key={input.name}>
													{values && values.length > 3 && (
														<Grid item xs={12}>
															<Box>
																<Typography variant="caption">
																	Assinatura:
																</Typography>
															</Box>
															<img
																src={values}
																alt={`Assinatura ${input.label}`}
																width={200}
																height={200}
															/>
														</Grid>
													)}
												</Fragment>
											);
										}

										if (values && regexIfIsHtml.test(values.toString())) {
											return (
												<Grid item xs={12} key={input.name}>
													<Typography variant="caption">
														{input.label}
													</Typography>
													<Interweave content={values} />
												</Grid>
											);
										}

										if (location && input.type === "location") {
											return (
												<FieldLocation
													values={location ? [location] : location}
													key={input.name}
												/>
											);
										}

										if (input.type === "location" && !location) {
											if (formResponseIsArray) {
												const values = formResponse[indexGroup];
												return (
													<FieldLocation
														values={
															values.location ? [values.location] : values
														}
														key={input.name}
													/>
												);
											}
											return (
												<FieldLocation values={[values]} key={input.name} />
											);
										}

										if (input.type === "owner") {
											const values = formResponse[indexGroup];
											return (
												<FieldOffenderData
													values={values.owner ? [values.owner] : values}
													key={input.name}
												/>
											);
										}

										if (input.type === "applicant") {
											const values = formResponse[indexGroup];
											return (
												<FieldApplicant
													values={values.owner ? [values.owner] : values}
													key={input.name}
												/>
											);
										}

										if (input.type === "imovelInput") {
											const values = formResponse[indexGroup];
											return (
												<FieldProperty
													values={values.location ? [values.location] : values}
													key={input.name}
												/>
											);
										}

										if (input.type === "witness") {
											const values = formResponse[indexGroup];
											return (
												<FieldWitness
													values={
														values.witnesses ? [values.witnesses] : values
													}
													key={input.name}
												/>
											);
										}

										if (input.type === "receiver") {
											const values = formResponse[indexGroup];
											return (
												<FieldReceiver
													values={values.receiver ? [values.receiver] : values}
													key={input.name}
												/>
											);
										}

										if (input.type === "checkbox") {
											if (formResponseIsArray) {
												values = formResponse[indexGroup][input.name];

												return (
													<FieldCheckbox
														label={input.label}
														value={values}
														key={input.name}
													/>
												);
											} else {
												values = formResponse[input.name];
												return (
													<FieldCheckbox
														label={input.label}
														value={values ?? false}
														key={input.name}
													/>
												);
											}
										}

										if (input.type === "date") {
											values = formResponse[indexGroup][input.name];
											return (
												<FieldDate
													label={input.label}
													value={
														values &&
														format(new Date(values), "dd/MM/yyyy HH:mm")
													}
													key={input.name}
												/>
											);
										}

										if (input.type === "combo" && !formResponseIsArray) {
											values = formResponse[input.name];
											if (typeof values === "object") {
												values = Object.values(values);
												if (values.length > 0) {
													values = values[1];
												} else {
													values = values[0];
												}
											}

											return (
												<FieldSelect
													label={input.label}
													value={values ? values : " - "}
													key={input.name}
												/>
											);
										}

										return (
											<Fragment key={input.name}>
												{values && (
													<Grid item xs={6}>
														<Box
															display="flex"
															justifyContent="space-between"
															alignItems="center"
															columnGap={2}
														>
															<Box>
																<Typography variant="caption">
																	{input.label}
																</Typography>
																<Typography variant="subtitle2">
																	{typeof values === "string"
																		? values
																		: JSON.stringify(values)}
																</Typography>
															</Box>
														</Box>
													</Grid>
												)}
											</Fragment>
										);
									})}
								</Fragment>
							))}
					</Grid>
					<Stack spacing={3} flex={1} marginTop={2}>
						<Box>
							{task.detail.attachments.map((item) => {
								const isImageFile =
									item.name.split(".").pop().toLowerCase() === "jpg" ||
									item.name.split(".").pop().toLowerCase() === "png" ||
									item.name.split(".").pop().toLowerCase() === "jpeg";

								return (
									<Box
										key={item.id}
										display="flex"
										columnGap={2}
										alignItems="center"
										mb={2}
									>
										<Box>
											<AttachFileIcon />
										</Box>
										<Box>
											{isImageFile ? (
												<Box display="flex" flexDirection={"column"}>
													<img
														src={item.url}
														style={{
															maxHeight: "200px",
															maxWidth: "200px",
														}}
													/>
													<Link
														variant="h6"
														fontSize="1rem"
														href={item.url}
														target="_blank"
														rel="norefferer"
													>
														{item.name}
													</Link>
												</Box>
											) : (
												<Link
													variant="h6"
													fontSize="1rem"
													href={item.url}
													target="_blank"
													rel="norefferer"
												>
													{item.name}
												</Link>
											)}
											<Box>
												<Typography variant="caption">
													{`criado em ${item.created_at}`}
												</Typography>
											</Box>
											{item.description !== "" && (
												<Box mt={1}>
													<Typography variant="body2" fontSize={".8rem"}>
														{item.description}
													</Typography>
												</Box>
											)}
										</Box>
									</Box>
								);
							})}
						</Box>
						<Box>
							<Button
								color="primary"
								aria-label="upload picture"
								component="label"
								endIcon={
									<UploadFileIcon
										sx={{ color: "rgba(0, 0, 0, 0.54)", height: "20px" }}
									/>
								}
								variant="outlined"
							>
								ANEXAR ARQUIVOS
								<input
									hidden
									accept="image/*, application/pdf"
									multiple
									type="file"
									name="Logo"
									id="logo"
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										handleUploadFile(e)
									}
								/>
							</Button>
						</Box>
					</Stack>
				</Stack>
			</ModalLayout>
			{isOpenModalDescription && (
				<ModalDetailDescription
					open={isOpenModalDescription}
					handleClose={handleCloseModal}
					files={files}
					refetch={refetch}
					task_id={task.id}
				/>
			)}
		</FormProvider>
	);
}
