import {
	AppBar,
	Box,
	Toolbar,
	Typography,
	Badge,
	Stack,
	Popover,
	List,
	ListItem,
	ListItemText,
	ListItemButton,
	Divider,
	Alert,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "components/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useSidebarContext } from "context/SidebarProvider";
import { Sidebar } from "components/Sidebar";
import { useNavigate } from "react-router-dom";
import { COUNT_TASKS } from "graphql/queries/countTasks";
import { useQuery } from "@apollo/client";
import { countTasks } from "types/graphql/queries/countTasks";
import { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import { useAuthContext } from "context/AuthProvider";

interface HeaderProps {
	title?: string;
	isReturn?: boolean;
	to?: string;
	onClick?: () => void;
}

export function Header({ title, isReturn, to, onClick }: HeaderProps) {
	const url = new URL(window.location.href);
	const lastDomain = url.hostname.split(".");
	const digitalDomain = lastDomain[lastDomain.length - 1];
	const { data } = useQuery<countTasks>(COUNT_TASKS);
	const { toggleSidebar } = useSidebarContext();
	const { state, handleLogout } = useAuthContext();
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	return (
		<>
			<AppBar
				position="sticky"
				sx={{
					"@media print": {
						display: "none",
					},
				}}
			>
				<Toolbar
					sx={{
						justifyContent: "space-between",
					}}
				>
					<Box display="flex" columnGap={2} alignItems="center">
						{isReturn ? (
							<IconButton
								onClick={
									onClick ? onClick : () => (to ? navigate(to) : navigate(-1))
								}
							>
								<ArrowBackIcon
									sx={{
										color: "primary.contrastText",
									}}
								/>
							</IconButton>
						) : (
							<IconButton onClick={() => toggleSidebar()}>
								<MenuIcon
									sx={{
										color: "primary.contrastText",
									}}
								/>
							</IconButton>
						)}
						<Typography variant="body1" color="white">
							{title}
						</Typography>
					</Box>
					<Stack
						direction="row"
						spacing={2}
						alignItems="center"
						justifyContent="center"
					>
						<IconButton aria-describedby={id} onClick={handleClick}>
							<PersonIcon
								sx={{
									color: "#fff",
								}}
							/>
						</IconButton>
						<Popover
							id={id}
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
						>
							<List>
								<ListItem>
									<ListItemText>{state.userInfo.user.name}</ListItemText>
								</ListItem>
								<Divider />
								<ListItem onClick={() => handleLogout()}>
									<ListItemButton>Logout</ListItemButton>
								</ListItem>
							</List>
						</Popover>
						<Box
							display="flex"
							columnGap={2}
							onClick={() => navigate("/tarefas")}
						>
							<Badge
								color="error"
								badgeContent={data?.countTasks?.late}
								sx={{
									cursor: "pointer",
								}}
							>
								<NotificationsIcon />
							</Badge>
						</Box>
					</Stack>
				</Toolbar>
			</AppBar>
			{digitalDomain === "digital" && (
				<Alert
					severity="warning"
					icon={false}
					sx={{
						position: "sticky",
						justifyContent: "center",
						py: 0,
						fontWeight: 600,
						color: "#fdd835",
						backgroundColor: "#fff59d",
						"@media print": {
							display: "none",
						},
					}}
				>
					Você está no ambiente de homologação
				</Alert>
			)}
			<Toolbar
				sx={{
					"@media (min-width: 0px) and (orientation: landscape)": {
						minHeight: 24,
					},
					"@media print": {
						display: "none",
					},
				}}
			/>
			<Sidebar />
		</>
	);
}
