import { Box, Fab, FabProps } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

type FabButtonProps = FabProps;

export function AddFabButton({ ...rest }: FabButtonProps) {
	return (
		<Box position="fixed" bottom={50} right={16}>
			<Fab color="primary" aria-label="add" {...rest}>
				<AddIcon />
			</Fab>
		</Box>
	);
}
