import { useLazyQuery, useQuery } from "@apollo/client";
import { ModalLayout } from "components/ModalLayout";
import { SEARCH_CUSTOM_DATA } from "graphql/queries/searchCustomData";
import { ModalLayoutProps } from "types/ModalLayout";
import { useSnackbar } from "notistack";
import GenericInput from "components/Input/GenericInput";
import { useState, useEffect } from "react";
import IconButton from "components/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {
	Stack,
	Box,
	FormControl,
	RadioGroup,
	Radio,
	Grid,
	getListItemSecondaryActionClassesUtilityClass,
} from "@mui/material";
import { SearchCustomData } from "types/graphql/queries/searchCustomData";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "components/CircularProgress";
import { Pagination } from "components/Pagination";
import { EmptyMessage } from "components/EmptyMessage";
import ModalSelectFilter from "./ModalSelectFilter";
import { Chips } from "components/Chips";
import { removeSpecialCharacters } from "utils/removeSpecialCharacters";

type ModalVisualizationProps = ModalLayoutProps & {
	handleSelect: (value: string) => void;
};

export function ModalSearchRealty({
	open,
	handleClose,
	handleSelect,
}: ModalVisualizationProps) {
	const [isOpenModalFilter, setIsOpenModalFilter] = useState(false);
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState("");
	const [filters, setFilters] = useState<
		{
			field: {
				title: string;
				value: string;
			};
			keyword: string;
		}[]
	>([]);
	const [selected, setSelected] = useState<string | null>(null);

	const { enqueueSnackbar } = useSnackbar();
	const { data: searchData, loading: loadingSearch } =
		useQuery<SearchCustomData>(SEARCH_CUSTOM_DATA, {
			fetchPolicy: "network-only",
			variables: {
				type: "Imovel",
				keyword: search,
				page: page,
				filter: filters.map((item) => ({
					field: item.field.value,
					keyword: removeSpecialCharacters(item.keyword.toUpperCase()),
				})),
			},
		});

	function handleFilter(values: {
		field: {
			title: string;
			value: string;
		};
		keyword: string;
	}) {
		const isExistFilter = filters.find(
			(item) =>
				item.field.title === values.field.title &&
				item.keyword === values.keyword
		);
		if (isExistFilter) {
			return enqueueSnackbar("Filtro já adicionado", {
				variant: "warning",
			});
		}
		setPage(1);
		setFilters([...filters, values]);
	}

	function handleRemoveFilter(index: number) {
		const newFilters = filters.filter((item, i) => i !== index);
		setPage(1);
		setFilters(newFilters);
	}

	function handleSelectRealty() {
		handleSelect(selected);
		handleClose();
	}
	return (
		<>
			<ModalLayout
				title="Buscar imóvel"
				open={open}
				handleClose={handleClose}
				buttonText="Selecionar"
				isDisabled={!selected ? true : false}
				handleSubmit={() => handleSelectRealty()}
			>
				<Box marginTop={2}>
					<Stack
						flex={1}
						spacing={2}
						justifyContent="space-between"
						alignItems="center"
						border={1}
						borderColor="grey.300"
						borderRadius={1}
						direction="row"
						padding={2}
					>
						{filters.length === 0 && (
							<Typography
								variant="body2"
								flex={1}
								onClick={() => setIsOpenModalFilter(true)}
								sx={{
									cursor: "pointer",
								}}
								height={"100%"}
							>
								Adicionar filtro...
							</Typography>
						)}
						{filters.length > 0 && (
							<Stack
								columnGap={1}
								direction="row"
								flex={1}
								flexWrap="wrap"
								rowGap={1}
							>
								{filters.map((item, index) => (
									<Chips
										variant="filled"
										label={`${item.field.title}: "${item.keyword}"`}
										textColor="#000"
										key={`${item.field.title}: ${item.keyword}`}
										isDelete
										handleDelete={() => {
											handleRemoveFilter(index);
										}}
									/>
								))}
							</Stack>
						)}
						<Box>
							<IconButton onClick={() => setIsOpenModalFilter(true)}>
								<SearchIcon />
							</IconButton>
						</Box>
					</Stack>
					<Grid container spacing={2} marginTop={2}>
						{loadingSearch && (
							<Box flex={1} alignItems="center" justifyContent="center">
								<CircularProgress />
							</Box>
						)}
						{searchData && searchData.searchCustomData.data.length === 0 && (
							<EmptyMessage message="Nenhum imóvel encontrado" />
						)}
						{searchData && (
							<FormControl>
								<RadioGroup row aria-labelledby={"realty"} name={"realty"}>
									{searchData.searchCustomData.data.map((item) => {
										const parsedValue = JSON.parse(item.value);
										return (
											<Grid
												key={item.id}
												display="flex"
												flex={1}
												item
												xs={12}
												marginTop={3}
												alignItems="center"
											>
												<Radio
													onChange={() => setSelected(item.value)}
													value={selected}
													checked={selected === item.value}
													sx={{
														height: 40,
													}}
												/>
												<Stack
													marginRight={2}
													flex={1}
													spacing={2}
													direction="row"
													justifyContent="space-between"
												>
													<Box display="flex" columnGap={2} flex={1}>
														<Box>
															<Box>
																<Typography variant="caption">
																	Indíce cadastral:{" "}
																	<Typography variant="subtitle2">
																		{parsedValue.INDICECADASTRAL}
																	</Typography>
																</Typography>
															</Box>
															<Box>
																<Typography variant="caption">
																	Nome:{" "}
																	<Typography variant="subtitle2">
																		{parsedValue.NOME}
																	</Typography>
																</Typography>
															</Box>
															<Box>
																<Typography variant="caption">
																	Documento:{" "}
																	<Typography variant="subtitle2">
																		{parsedValue.CGCCPF}
																	</Typography>
																</Typography>
															</Box>
														</Box>
														<Box flex={1} textAlign={"right"}>
															<Typography variant="caption">
																Logradouro:{" "}
																<Typography variant="subtitle2">
																	{`${parsedValue.TIPOLOGRAD} ${parsedValue.LOGRADOURO}`}
																</Typography>
															</Typography>
															<Box>
																<Typography variant="caption">
																	Bairro:{" "}
																	<Typography variant="subtitle2">
																		{parsedValue.NOMEBAIRRO}
																	</Typography>
																</Typography>
															</Box>
														</Box>
													</Box>
													<Box display="flex" columnGap={2} flexWrap="wrap">
														<Box flex={1} textAlign="left">
															<Typography variant="caption">
																Número:
																<Typography variant="subtitle2">
																	{" "}
																	{parsedValue.NUMERO}
																	{parsedValue.COMPLEMENTO &&
																		` - ${parsedValue.COMPLEMENTO}`}
																</Typography>
															</Typography>
														</Box>
														<Box>
															<Typography variant="caption">
																Quadra:{" "}
																<Typography variant="subtitle2">
																	{parsedValue.LOTEAMQUADRA}
																</Typography>
															</Typography>
														</Box>
														<Box>
															<Typography variant="caption">
																Lote:{" "}
																<Typography variant="subtitle2">
																	{parsedValue.LOTEAMLOTE}
																</Typography>
															</Typography>
														</Box>
													</Box>
												</Stack>
											</Grid>
										);
									})}
								</RadioGroup>
							</FormControl>
						)}
						<Grid item xs={12}>
							{searchData && searchData.searchCustomData.total > 15 && (
								<Pagination
									count={searchData.searchCustomData.last_page}
									onChange={(event, value) => {
										setPage(value);
									}}
									page={page}
								/>
							)}
						</Grid>
					</Grid>
				</Box>
			</ModalLayout>
			{isOpenModalFilter && (
				<ModalSelectFilter
					open={isOpenModalFilter}
					handleClose={() => setIsOpenModalFilter(false)}
					handleFilter={(values) => handleFilter(values)}
				/>
			)}
		</>
	);
}
