import { Header } from "components/Header";
import {
	Box,
	CircularProgress,
	Container,
	TablePagination,
	TextField,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import { ListComplaintsToEvalute } from "./ListComplaintsToEvaluate";
import { FETCH_COMPLAINTS_TO_EVALUTE } from "graphql/queries/fetchComplaintsToEvalute";
import { FetchComplaintsToEvalute } from "types/graphql/queries/fetchComplaintsToEvalute";
import { FETCH_COMPLAINTS } from "graphql/queries/fetchComplaints";
import { FetchComplaints } from "types/graphql/queries/fetchComplaints";
import { ListComplaints } from "./ListComplaints";
import { FETCH_DETAIL_COMPANY } from "graphql/queries/fetchDetailCompany";
import { FetchDetailCompany } from "types/graphql/queries/fetchDetailCompany";
import useDebounce from "hooks/useDebounce";
import { useVerifyNoHasRestriction } from "utils/useVerifyNoHasRestriction";
import LocalProvider from "components/LocalProvider";

export function Complaints() {
	const [page, setPage] = useState(0);
	const [pageToEvaluate, setPageToEvaluate] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [orderBy, setOrderBy] = useState("complaints.created_at");
	const [direction, setDirection] = useState<"asc" | "desc">("desc");
	const [keyword, setKeyword] = useState("");
	const [orderByEvalute, setOrderByEvalute] = useState("complaints.created_at");
	const [directionEvalute, setDirectionEvalute] = useState<"asc" | "desc">(
		"desc"
	);
	const [keywordEvalute, setKeywordEvalute] = useState("");

	const debouncedValue = useDebounce(keyword, 1000);
	const debouncedValueEvalute = useDebounce(keywordEvalute, 1000);

	const { data: complaintsToEvaluate, loading: loadingComplaintToEvaluate } =
		useQuery<FetchComplaintsToEvalute>(FETCH_COMPLAINTS_TO_EVALUTE, {
			fetchPolicy: "network-only",
			variables: {
				order_by: orderByEvalute,
				direction: directionEvalute,
				keyword: debouncedValueEvalute,
				limit: 5,
				page: pageToEvaluate + 1,
			},
		});

	const { data: complaints, loading: loadingComplaints } =
		useQuery<FetchComplaints>(FETCH_COMPLAINTS, {
			fetchPolicy: "network-only",
			variables: {
				evaluated: true,
				page: page + 1,
				limit: rowsPerPage,
				order_by: orderBy,
				direction: direction,
				keyword: debouncedValue,
			},
		});

	const subdomain = window.location.hostname.split(".")[0];

	const { data: dataCompany } = useQuery<FetchDetailCompany>(
		FETCH_DETAIL_COMPANY,
		{
			variables: {
				domain:
					subdomain === "localhost"
						? process.env.REACT_DEFAULT_DOMAIN || "lagoasanta"
						: subdomain,
			},
		}
	);

	const noHasRestrictionEvaluateComplaint =
		useVerifyNoHasRestriction("DENUNCIA_AVALIAR");

	return (
		<Box
			width="100%"
			display="flex"
			alignSelf="stretch"
			flexDirection="column"
			sx={{
				backgroundColor: "#f3f3f3",
			}}
			paddingBottom="1rem"
		>
			<Header title="Denúncias" />

			<Container maxWidth="md">
				<Box
					sx={{
						backgroundImage: `url(${
							dataCompany && dataCompany.detailCompany.logo
						})`,
						backgroundSize: "contain",
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
					}}
					minHeight="70px"
					marginTop={2}
					marginBottom={3}
				/>

				{noHasRestrictionEvaluateComplaint && (
					<Card>
						<CardContent>
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
							>
								<Box>
									<Typography variant="caption">Denúncias</Typography>
									<Typography variant="h4" component="div">
										{loadingComplaintToEvaluate ? (
											<CircularProgress />
										) : (
											complaintsToEvaluate?.fetchComplaintsToEvaluate?.total ||
											0
										)}
									</Typography>
								</Box>
								<TextField
									name="keyword"
									type="text"
									label="Pesquisar..."
									value={keywordEvalute}
									onChange={(event) => {
										setPageToEvaluate(0);
										setKeywordEvalute(event.target.value);
									}}
									sx={{ width: "300px" }}
								/>
							</Box>
							<Typography variant="overline">aguardando avaliação</Typography>
							{loadingComplaintToEvaluate ? (
								<Box alignItems="center" justifyContent="center" display="flex">
									<CircularProgress />
								</Box>
							) : (
								<>
									{complaintsToEvaluate && (
										<ListComplaintsToEvalute
											complaintsToEvaluate={
												complaintsToEvaluate.fetchComplaintsToEvaluate.data
											}
										/>
									)}
									{complaintsToEvaluate.fetchComplaintsToEvaluate.data.length >
										0 && (
										<LocalProvider locale="ptBR">
											<TablePagination
												showLastButton
												showFirstButton
												component="div"
												count={
													complaintsToEvaluate.fetchComplaintsToEvaluate.total
												}
												page={pageToEvaluate}
												rowsPerPage={5}
												rowsPerPageOptions={[5]}
												onPageChange={(event, newPage) => {
													setPageToEvaluate(newPage);
												}}
											/>
										</LocalProvider>
									)}
								</>
							)}
						</CardContent>
					</Card>
				)}

				<Card style={{ marginTop: 16 }}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							paddingX: "16px",
							marginTop: "30px",
						}}
					>
						<CardHeader title="Denúncias avaliadas" />
						<TextField
							name="keyword"
							type="search"
							label="Pesquisar..."
							value={keyword}
							onChange={(event) => {
								setKeyword(event.target.value);
							}}
							sx={{ width: "300px" }}
						/>
					</Box>
					<CardContent>
						{loadingComplaints ? (
							<Box alignItems="center" justifyContent="center" display="flex">
								<CircularProgress />
							</Box>
						) : (
							<>
								{complaints && (
									<>
										<ListComplaints
											{...{
												complaints: complaints.fetchComplaints.data,
												orderBy,
												setOrderBy,
												direction,
												setDirection,
											}}
										/>
										{complaints.fetchComplaints.data.length > 0 && (
											<LocalProvider locale="ptBR">
												<TablePagination
													showLastButton
													showFirstButton
													component="div"
													count={complaints.fetchComplaints.total}
													page={page}
													rowsPerPage={rowsPerPage}
													rowsPerPageOptions={[5, 15, 30, 60, 100]}
													onRowsPerPageChange={(event) => {
														setPage(0);
														setRowsPerPage(parseInt(event.target.value));
													}}
													onPageChange={(event, newPage) => {
														setPage(newPage);
													}}
												/>
											</LocalProvider>
										)}
									</>
								)}
							</>
						)}
					</CardContent>
				</Card>
			</Container>
		</Box>
	);
}
