import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
} from "@mui/material";
import { ModalLayout } from "components/ModalLayout";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { ModalLayoutProps } from "types/ModalLayout";

type ModalSelectFilterProps = ModalLayoutProps & {
	handleFilter: (value: {
		field: {
			title: string;
			value: string;
		};
		keyword: string;
	}) => void;
};

const options = [
	{
		label: "Bairro",
		value: {
			title: "Bairro",
			value: "NOMEBAIRRO",
		},
	},
	{
		label: "Cep",
		value: {
			title: "Cep",
			value: "CEP",
		},
	},
	{
		label: "Cidade",
		value: {
			title: "Cidade",
			value: "CIDADE",
		},
	},
	{
		label: "Complemento",
		value: {
			title: "Complemento",
			value: "COMPLEMENTO",
		},
	},
	{
		label: "CPF/CNPJ",
		value: {
			title: "CPF/CNPJ",
			value: "CGCCPF",
		},
	},
	{
		label: "Indíce Cadastral",
		value: {
			title: "Ind. Cadastral",
			value: "INDICECADASTRAL",
		},
	},
	{
		label: "Logradouro",
		value: {
			title: "Logradouro",
			value: "LOGRADOURO",
		},
	},
	{
		label: "Lote",
		value: {
			title: "Lote",
			value: "LOTEAMLOTE",
		},
	},
	{
		label: "Nome",
		value: {
			title: "Nome",
			value: "NOME",
		},
	},
	{
		label: "Número",
		value: {
			title: "Nº",
			value: "NUMERO",
		},
	},
	{
		label: "Quadra",
		value: {
			title: "Quadra",
			value: "LOTEAMQUADRA",
		},
	},
	{
		label: "Tipo",
		value: {
			title: "Tipo",
			value: "TIPOLOGRAD",
		},
	},
	{
		label: "UF",
		value: {
			title: "UF",
			value: "UF",
		},
	},
];

export default function ModalSelectFilter({
	open,
	handleClose,
	handleFilter,
}: ModalSelectFilterProps) {
	const [value, setValue] = useState(null);
	const [keyword, setKeyword] = useState("");

	const { enqueueSnackbar } = useSnackbar();

	const onFilter = () => {
		if (!value) {
			return enqueueSnackbar("Selecione um campo", { variant: "warning" });
		}
		handleFilter({
			field: {
				title: options.find((option) => option.value.value === value).value
					.title,
				value: value,
			},
			keyword: keyword,
		});
		handleClose();
		setKeyword("");
		setValue(null);
	};

	return (
		<ModalLayout
			open={open}
			handleClose={handleClose}
			title="Adicionar filtro"
			handleSubmit={onFilter}
		>
			<Stack spacing={2}>
				<FormControl sx={{ minWidth: 120, mt: 1 }}>
					<InputLabel htmlFor="grouped-native-select">Campo</InputLabel>
					<Select
						value={value}
						label="Campo"
						placeholder="Campo"
						onChange={(e) => setValue(e.target.value)}
					>
						{options.map((option) => (
							<MenuItem key={option.label} value={option.value.value}>
								{option.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<TextField
					label="Palavra-chave"
					value={keyword}
					onChange={(e) => setKeyword(e.target.value)}
				/>
			</Stack>
		</ModalLayout>
	);
}
