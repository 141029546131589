import { useQuery } from "@apollo/client";
import { Box, Container, Grid, Stack } from "@mui/material";
import { Header } from "components/Header";
import { CardLateProtocols } from "./CardLateProtocols";
import { CardSituationProtocols } from "./CardSituationProtocols";
import { FETCH_DETAIL_COMPANY } from "graphql/queries/fetchDetailCompany";
import { FetchDetailCompany } from "types/graphql/queries/fetchDetailCompany";
import { CardDevicesLocation } from "./CardDevicesLocation";
import { CardUPFMLS } from "./CardUPFMLS";
import { useVerifyNoHasRestriction } from "utils/useVerifyNoHasRestriction";
import Button from "components/Button";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import IconButton from "components/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useReducer, useState } from "react";
import { ModalFilter } from "./Modal";
import { Filter } from "./types";

function reducer(state: Filter, action) {
	switch (action.type) {
		case "setStartDate":
			return { ...state, startDate: action.payload };
		case "setFinishDate":
			return { ...state, finishDate: action.payload };
		case "setGroupIds":
			return { ...state, group_ids: action.payload };
		case "setResponsibleIds":
			return { ...state, responsible_ids: action.payload };
		case "setSituations":
			return { ...state, situations: action.payload };
		case "setSubjectIds":
			return { ...state, subject_ids: action.payload };
		case "setStatusIds":
			return { ...state, status_ids: action.payload };
		case "setDistrictIds":
			return { ...state, district_ids: action.payload };
		case "setUpfmls":
			return { ...state, upfmls: action.payload };
		case "setLateDays":
			return { ...state, lateDays: action.payload };
		case "setStreet":
			return { ...state, street: action.payload };
		case "setNumber":
			return { ...state, number: action.payload };
		case "setSearch":
			return { ...state, search: action.payload };
		case "setQuadra":
			return { ...state, quadra: action.payload };
		case "setLote":
			return { ...state, lote: action.payload };
		case "setCadastralIndex":
			return { ...state, cadastralIndex: action.payload };
		case "setOffendersName":
			return { ...state, offendersName: action.payload };

		default:
			throw new Error("Invalid action type");
	}
}

const initialState = {
	startDate: null as Date | null,
	finishDate: null as Date | null,
	group_ids: [] as number[],
	responsible_ids: [] as number[],
	situations: [] as string[],
	subject_ids: [] as number[],
	status_ids: [] as number[],
	district_ids: [] as number[],
	upfmls: "" as string | null,
	lateDays: "" as string | null,
	street: "" as string | null,
	number: "" as string | null,
	search: "" as string | null,
	lote: "" as string | null,
	quadra: "" as string | null,
	cadastralIndex: "" as string | null,
	offendersName: "" as string | null,
};

export function Dashboard() {
	const [state, dispatch] = useReducer(
		reducer,
		initialState,
		() => initialState
	);
	const subdomain = window.location.hostname.split(".")[0];

	const [isOpenModal, setIsOpenModal] = useState(false);
	const { data } = useQuery<FetchDetailCompany>(FETCH_DETAIL_COMPANY, {
		variables: {
			domain:
				subdomain === "localhost"
					? process.env.REACT_DEFAULT_DOMAIN || "lagoasanta"
					: subdomain,
		},
	});

	const noHasRestrictionDevicesLocation =
		useVerifyNoHasRestriction("TABLET_LOCALIZAR");

	function handlePrint() {
		window.print();
	}

	function handleFilter(values) {
		dispatch({ type: "setStartDate", payload: values.startDate });
		dispatch({ type: "setFinishDate", payload: values.finishDate });
		dispatch({ type: "setGroupIds", payload: values.group_ids });
		dispatch({ type: "setResponsibleIds", payload: values.responsible_ids });
		dispatch({ type: "setSituations", payload: values.situations });
		dispatch({ type: "setSubjectIds", payload: values.subject_ids });
		dispatch({ type: "setStatusIds", payload: values.status_ids });
		dispatch({ type: "setDistrictIds", payload: values.district_ids });
		dispatch({ type: "setUpfmls", payload: values.upfmls });
		dispatch({ type: "setLateDays", payload: values.lateDays });
		dispatch({ type: "setStreet", payload: values.street });
		dispatch({ type: "setNumber", payload: values.number });
		dispatch({ type: "setSearch", payload: values.search });
		dispatch({ type: "setLote", payload: values.lote });
		dispatch({ type: "setQuadra", payload: values.quadra });
		dispatch({ type: "setCadastralIndex", payload: values.cadastralIndex });
		dispatch({ type: "setOffendersName", payload: values.offendersName });

		setIsOpenModal(false);
	}
	return (
		<>
			<Box
				width="100%"
				display="flex"
				alignSelf="stretch"
				flexDirection="column"
				sx={{
					backgroundColor: "#f3f3f3",
				}}
				paddingBottom="1rem"
			>
				<Header title="Dashboard" />
				<Container maxWidth="lg">
					<Box
						sx={{
							backgroundImage: `url(${data && data.detailCompany.logo})`,
							backgroundSize: "contain",
							backgroundPosition: "center",
							backgroundRepeat: "no-repeat",
							"@media print": {
								display: "block",
							},
						}}
						minHeight="70px"
						marginTop={2}
					/>
					<Box sx={{ flexGrow: 1 }} marginTop="2rem">
						<Grid container spacing={2}>
							<Grid item xs={12} md={8} rowGap={2}>
								<CardSituationProtocols
									finishDate={state.finishDate}
									startDate={state.startDate}
									groupIds={state.group_ids}
									responsibleIds={state.responsible_ids}
									district_ids={state.district_ids}
									status_ids={state.status_ids}
									subject_ids={state.subject_ids}
									situations={state.situations}
									upfmls={state.upfmls}
									lateDays={state.lateDays}
									street={state.street}
									number={state.number}
									keyword={state.search}
									cadastralIndex={state.cadastralIndex}
									lote={state.lote}
									offendersName={state.offendersName}
									quadra={state.quadra}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									marginBottom={2}
									sx={{
										"@media print": {
											display: "none",
										},
									}}
								>
									<Button
										variant="outlined"
										color="inherit"
										onClick={() => handlePrint()}
										endIcon={<PictureAsPdfIcon />}
									>
										exportar pdf
									</Button>
									<IconButton onClick={() => setIsOpenModal(true)}>
										<FilterListIcon color="action" />
									</IconButton>
								</Stack>
								<CardUPFMLS />
								<CardLateProtocols />
							</Grid>
							{noHasRestrictionDevicesLocation && (
								<Grid
									item
									xs={12}
									sx={{
										"@media print": {
											display: "none",
										},
									}}
								>
									<CardDevicesLocation />
								</Grid>
							)}
						</Grid>
					</Box>
				</Container>
			</Box>
			<ModalFilter
				open={isOpenModal}
				handleClose={() => setIsOpenModal(false)}
				handleFilter={handleFilter}
			/>
		</>
	);
}
