import { Typography, TypographyProps } from "@mui/material";

interface TitleProps extends TypographyProps {
	title: string;
}
export function Title({ title, ...rest }: TitleProps) {
	return (
		<Typography variant="h6" {...rest}>
			{title}
		</Typography>
	);
}
