import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useForm, FormProvider } from "react-hook-form";
import { useSnackbar } from "notistack";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { StepPlaceComplaint } from "./StepPlaceComplaint";
import { ModalLayout } from "components/ModalLayout";
import { StepDescription } from "./StepDescription";
import { Steps } from "components/Steps";
import { StepContact } from "./StepContact";

import useStep from "hooks/useStep";

import { CREATE_PUBLIC_COMPLAINT } from "graphql/mutations/createPublicComplaint";

type ModalReportProps = {
	open: boolean;
	handleClose: () => void;
};

const stepSchemas = [
	yup.object().shape({
		name: yup.string(),
		email: yup.string().email("E-mail inválido"),
	}),
	yup.object().shape({
		zipcode: yup.string()
			.required("CEP é obrigatório")
			.test('valid-cep', 'CEP inválido', (value) => {
				if (!value) return false;
				const cep = value.replace('-', '');
				return !(cep === "33400000" || cep.length !== 8);
			}),
		address: yup.string().required("Rua é obrigatória"),
		number: yup.string(),
		district_name: yup.string().required("Bairro é obrigatório"),
		reference: yup.string().required("Ponto de referência é obrigatório"),
	}),
	yup.object().shape({
		description: yup.string().required("Campo obrigatório"),
	}),
];

export function ModalReport({ open, handleClose }: ModalReportProps) {
	const steps = [
		{
			label: "Dados do denunciante",
			component: StepContact,
		},
		{
			label: "Informe o local da denúncia",
			component: () => (
				<StepPlaceComplaint
					control={methods.control}
					setIsZipcodeValid={setIsZipcodeValid}
					setValue={setValue}
				/>
			),
		},
		{
			label: "Descrição da denúncia",
			component: StepDescription,
		},
	];

	const [currentStep, helpers] = useStep(steps.length);
	const { goToNextStep, goToPrevStep } = helpers;

	const [currentSchema, setCurrentSchema] = useState(stepSchemas[currentStep]);

	const methods = useForm({ resolver: yupResolver(currentSchema) });
	const { reset, handleSubmit, setValue, trigger } = methods;

	const [isZipcodeValid, setIsZipcodeValid] = useState(true);

	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const [createComplaint, { loading }] = useMutation(CREATE_PUBLIC_COMPLAINT);

	const subdomain = window.location.hostname.split(".")[0];

	const handleNextStep = async () => {
		const isValid = await trigger();
		if (isValid) { goToNextStep() }
	};


	const onSubmit = async (values: any) => {
		try {
			const response = await createComplaint({
				variables: {
					domain:
						subdomain === "localhost"
							? process.env.REACT_DEFAULT_DOMAIN || "lagoasanta"
							: subdomain,
					name: values.name,
					email: values.email,
					zipcode: values.zipcode,
					address: values.address,
					number: values.number,
					district_name: values.district_name,
					reference: values.reference,
					description: values.description,
					files: values.files,
				},
			});
			const protocolo = response.data.createPublicComplaint;

			enqueueSnackbar("Sua denúcia foi registrada com sucesso!", {
				variant: "success",
			});
			reset({
				name: "",
				email: "",
				zipcode: "",
				address: "",
				number: "",
				district_name: "",
				reference: "",
				description: "",
				file: [],
			});
			helpers.reset();
			handleClose();
			navigate("/webdenuncia/acompanhar-denuncia/" + protocolo);
		} catch (err: any) {
			enqueueSnackbar(err.message, {
				variant: "error",
			});
		}
	};

	useEffect(() => {
		setCurrentSchema(stepSchemas[currentStep]);
	}, [currentStep]);


	return (
		<>
			<FormProvider {...methods}>
				<ModalLayout
					open={open}
					handleClose={handleClose}
					handleSubmit={handleSubmit(onSubmit)}
					title="Criar Denúncia"
					buttonText="Criar Denúncia"
					isLoading={loading}
					isNotShowButtonSubmit={
						currentStep !== 2
					}
				>
					<Steps
						steps={steps}
						currentStep={currentStep}
						goToNextStep={
							currentStep === 1 && !isZipcodeValid
								? () => enqueueSnackbar("CEP inválido. Por favor, insira o CEP correto da rua.", { variant: "error" })
								: handleNextStep
						}
						goToPrevStep={goToPrevStep}
						disabledButtons={loading}
					/>
				</ModalLayout>
			</FormProvider>
		</>
	);
}
