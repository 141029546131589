import { gql } from "@apollo/client";

export const FETCH_RELATED_PROTOCOLS = gql`
    query ($code: String!) {
        fetchRelatedProtocols(code: $code) {
            id
            code
            subject {
                id
                name
            }
            responsible {
                id
                name
            }
            address {
                zipcode
                street
                number
                complement
                district {
                    id
                    name
                }
            }
            created_at
        }
    }
`