import { FormControlLabel, Stack, Switch, Typography } from "@mui/material";

import Input from "components/Input";
import { Item } from "components/Paper";
import SidebarFlow from "../SidebarFlow";
import { memo } from "react";
import { Select } from "components/Select";
import Button from "components/Button";

import React, { useState } from "react";
import { ModalUpdateFlow } from "./ModalUpdateFlow";
import { useFormContext } from "react-hook-form";

interface Props {
	nodesOccurrencyType: {
		_id: string;
		name: string;
	}[];
	statusInput?: boolean;
	defaultType?: boolean;
	setValue?: any;
	setVersionHistory?: any;
}

const valueRefuseComplaint = [
	{ value: "true", label: "Sim" },
	{ value: "false", label: "Não" },
];

const valueStatusFlow = [
	{ value: "true", label: "Ativo" },
	{ value: "false", label: "Inativo" },
];

function Form({
	nodesOccurrencyType,
	defaultType = true,
	statusInput = false,
	setValue,
	setVersionHistory,
}: Props) {
	const [open, setOpen] = useState<boolean>(false);
	const { control } = useFormContext();

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	return (
		<Item
			sx={{
				maxWidth: "300px",
				maxHeight: "600px",
				position: "relative",
				left: "10px",
				top: "10px",
				zIndex: 999,
				overflow: "auto",
			}}
		>
			<Stack spacing={2} paddingTop={2} paddingX={1}>
				<Input name="name" placeholder="Nome" autoComplete="off" />
				<Input name="description" placeholder="Descrição" autoComplete="off" />
				<Button
					variant="outlined"
					onClick={() => {
						setVersionHistory((prevState) => ({
							...prevState,
							open: true,
						}));
					}}
				>
					Ver historico de versões
				</Button>
				<Typography variant="subtitle2" fontWeight={500}>
					Editar status do fluxo
				</Typography>

				{defaultType && (
					<Select 
						name="status" 
						label="Selecionar" 
						options={valueStatusFlow} />
				)}

				<Button variant="contained" onClick={handleClickOpen}>
					ATUALIZAÇÃO EM MASSA
				</Button>

				{open && <ModalUpdateFlow open={open} handleClose={handleClose} />}

				<Typography variant="subtitle2" fontWeight={500}>
					Ocorrência de início para protocolos de Ofício
				</Typography>

				{defaultType && (
					<Select
						name="type"
						label="Selecionar"
						options={nodesOccurrencyType.map((item) => ({
							value: item._id,
							label: item.name,
						}))}
					/>
				)}

				<Typography variant="subtitle2" fontWeight={500}>
					Ao recusar a denuncia, esse fluxo deve ser finalizado automaticamente?
				</Typography>
				{defaultType && (
					<Select
						name="refuseComplaint"
						label="Selecionar"
						options={valueRefuseComplaint}
					/>
				)}
			</Stack>
			<SidebarFlow />
		</Item>
	);
}

export default memo(Form);
