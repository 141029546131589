import { Backdrop } from "@mui/material";
import { CircularProgress } from "components/CircularProgress";

export default function ThemeSuspense() {
	return (
		<Backdrop
			sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={true}
		>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
}
