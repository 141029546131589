/* eslint-disable no-mixed-spaces-and-tabs */
import { Link, Stack, Typography, Box } from "@mui/material";
import Button from "components/Button";
import { useAuthContext } from "context/AuthProvider";
import { format, parse, isFuture } from "date-fns";
import { useState } from "react";
import { Task } from "types/graphql/queries/detailProtocol";
import { ModalTaskResolve } from "../Modal";
import { ModalDetailConditional } from "../Modal/ModalDetailConditional";

interface DetailConditionalProps {
	task: Task;
	isNotConcluded: boolean;
	refetch: () => void;
	status: string;
	auto_finish_on_complaint_refused?: boolean;
}

export function DetailConditional({
	task,
	isNotConcluded,
	refetch,
	status,
	auto_finish_on_complaint_refused,
}: DetailConditionalProps) {
	const { state } = useAuthContext();
	const [isOpen, setOpen] = useState(false);
	const [isOpenModalTaskResolve, setIsOpenModalTaskResolve] = useState(false);

	const isSameUser = state.userInfo.user.id === task.user?.id;
	const isSamePerfilIdWithUserNull =
		state.userInfo.user.perfil_id === task.perfil?.id && task.user === null;

	const isSameUserOrPerfil = isSameUser || isSamePerfilIdWithUserNull;

	const parsedDate = task.expire_at
		? parse(task.expire_at, "yyyy-MM-dd HH:mm:ss", new Date())
		: null;

	const isFutureDate = parsedDate ? isFuture(parsedDate) : false;
	const disabledResolveTask =
		task.resolve_task === "Ao expirar" && isFutureDate === false;
	return (
		<>
			<Stack
				minHeight={100}
				sx={{
					opacity: task.undo_at ? 0.7 : 1,
				}}
			>
				<Typography variant="subtitle2" fontWeight={500} fontSize="0.9rem">
					{task.name}
				</Typography>
				{!isNotConcluded && (
					<Typography variant="caption" fontSize="0.8rem" fontWeight="bold">
						R: {task.answer}
					</Typography>
				)}
				{task.detail.attachments.length > 0 && (
					<Typography
						variant="caption"
						fontSize="0.8rem"
						sx={{
							cursor: "pointer",
						}}
						onClick={() => setOpen(true)}
					>
						<Link>Ver anexos</Link>
					</Typography>
				)}
				<Typography variant="caption" fontSize="0.8rem">
					{task.resolve_task === "Ao expirar"
						? `Tarefa só pode ser concluída a partir do dia ${
								parsedDate ? format(parsedDate, "dd/MM/yyyy HH:mm:ss") : " - "
						  }`
						: isNotConcluded
						? task.expire_at
							? `Prazo para conclusão: ${task.expire_at}`
							: "Sem prazo para conclusão"
						: `Respondida em ${task.concluded_at || "-"}`}
				</Typography>
				{!isNotConcluded && task.user && (
					<>
						<Typography variant="caption" fontSize="0.8rem">
							por {task.user.name}
						</Typography>
						{task.user.params?.matricula && (
							<Typography variant="caption" fontSize="0.8rem">
								Matrícula {task.user.params?.matricula}
							</Typography>
						)}
					</>
				)}
				{isNotConcluded && status !== "Suspenso" && status !== "Cancelado" && (
					<Box>
						<Button
							variant="outlined"
							onClick={() => setIsOpenModalTaskResolve(true)}
							disabled={!isSameUserOrPerfil || disabledResolveTask === true}
						>
							Resolver tarefa
						</Button>
					</Box>
				)}
			</Stack>
			<ModalDetailConditional
				task={task}
				open={isOpen}
				handleClose={() => setOpen(false)}
				attachments={task.detail.attachments}
				refetch={refetch}
			/>
			<ModalTaskResolve
				refetch={refetch}
				open={isOpenModalTaskResolve}
				handleClose={() => setIsOpenModalTaskResolve(false)}
				tasks={task}
				auto_finish_on_complaint_refused={auto_finish_on_complaint_refused}
			/>
		</>
	);
}
