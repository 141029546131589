import { gql } from "@apollo/client";

export const FETCH_BATCH_CLEANING_REPORT = gql`
	query ($year: Int) {
		fetchBatchCleaningReport(year: $year) {
			count_protocols
			inspected_batches
			fined_lots
			total_fines
			total_assessment
			forwarded_urban_cleaning
			forwarded_miscellaneous_income
			counts {
				total
				concluded
				in_progress
				suspended
				canceled
			}
			per_month {
				month_index
				count_protocols
				inspected_batches
				fined_lots
				total_fines
				total_assessment
				forwarded_urban_cleaning
				forwarded_miscellaneous_income
				counts {
					concluded
					in_progress
					suspended
					canceled
				}
			}
		}
	}
`;
