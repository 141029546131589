import {
	IconButton,
	Popper,
	Fade,
	Paper,
	List,
	ListItem,
	ListItemButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import { useVerifyNoHasRestriction } from "utils/useVerifyNoHasRestriction";

interface SwitchSituationProps {
	onOpenModalSwitchSituation: (type: string) => void;
	onOpenModalUndoTask: () => void;
	situation: string;
	tasks: number;
}

export function SwitchSituation({
	onOpenModalSwitchSituation,
	onOpenModalUndoTask,
	situation,
	tasks,
}: SwitchSituationProps) {
	const isSuspendOrCancel =
		situation === "Suspenso" || situation === "Cancelado";

	const noHasRestrictionSuspend = useVerifyNoHasRestriction("SUSPENDER");

	const noHasRestrictionCanceled =
		useVerifyNoHasRestriction("PROTOCOLO_CANCELAR");

	const noHasRestrictionReundoTask = useVerifyNoHasRestriction(
		"PROTOCOLO_DEFAZER_TAREFA"
	);

	const hasPermission = noHasRestrictionSuspend || noHasRestrictionCanceled || noHasRestrictionReundoTask;

	return (
		<PopupState variant="popper" popupId="demo-popup-popper">
			{(popupState) => (
				<div>
					{hasPermission && (
						<IconButton type="button" {...bindToggle(popupState)}>
							<MoreVertIcon />
						</IconButton>
					)}
					<Popper {...bindPopper(popupState)} transition>
						{({ TransitionProps }) => (
							<Fade {...TransitionProps} timeout={350}>
								<Paper>
									<List>
										{noHasRestrictionSuspend && !isSuspendOrCancel && (
											<ListItem
												sx={{
													paddingX: 0,
												}}
											>
												<ListItemButton
													onClick={() => {
														onOpenModalSwitchSituation("suspend");
														popupState.close();
													}}
													sx={{
														paddingX: "16px",
														justifyContent: "center",
													}}
												>
													Suspender
												</ListItemButton>
											</ListItem>
										)}
										{noHasRestrictionCanceled && !isSuspendOrCancel && (
											<ListItem
												sx={{
													paddingX: 0,
												}}
											>
												<ListItemButton
													onClick={() => {
														onOpenModalSwitchSituation("cancel");
														popupState.close();
													}}
													sx={{
														paddingX: "16px",
														justifyContent: "center",
													}}
												>
													Cancelar
												</ListItemButton>
											</ListItem>
										)}
										{isSuspendOrCancel && (
											<ListItem
												sx={{
													paddingX: 0,
												}}
											>
												<ListItemButton
													onClick={() => {
														onOpenModalSwitchSituation("reopen");
														popupState.close();
													}}
													sx={{
														paddingX: "16px",
														justifyContent: "center",
													}}
												>
													Reabrir
												</ListItemButton>
											</ListItem>
										)}

										{tasks > 1 && noHasRestrictionReundoTask && (
											<ListItem
												sx={{
													paddingX: 0,
												}}
											>
												<ListItemButton
													onClick={() => {
														onOpenModalUndoTask();
														popupState.close();
													}}
													sx={{
														paddingX: "16px",
														justifyContent: "center",
													}}
												>
													Desfazer última tarefa
												</ListItemButton>
											</ListItem>
										)}
									</List>
								</Paper>
							</Fade>
						)}
					</Popper>
				</div>
			)}
		</PopupState>
	);
}
