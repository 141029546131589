import { ModalLayout } from "components/ModalLayout";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { Stack, Typography } from "@mui/material";
import { ModalLayoutProps } from "types/ModalLayout";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { ACCEPT_COMPLAINT } from "graphql/mutations/acceptComplaint";
import { FETCH_SUBJECTS } from "graphql/queries/fetchSubjects";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs//locale/pt-br";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import { format } from "date-fns";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FETCH_USERS } from "graphql/queries/fetchUsers";
import { RadioFilter } from "components/RadioFilterModal";
import { useEffect, useState } from "react";
import { FETCH_PROTOCOLS } from "graphql/queries/fetchProtocols";
import { FetchProtocols } from "types/graphql/queries/fetchProtocols";
import { DetailProtocol } from "types/graphql/queries/detailProtocol";
import { DescriptionAlert } from "components/Warning";
import { FetchSubjects } from "types/graphql/queries/fetchSubjects";
import { FETCH_GROUPS } from "graphql/queries/fetchGroups";
import { NEXT_USER_COMPLAINT } from "graphql/queries/nextUserComplaint";

type ModalRefuseComplaintProps = ModalLayoutProps & {
	refetch: () => void;
	code: string;
	complaint: DetailProtocol["detailProtocol"]["complaint"];
};

const schema = yup.object().shape({
	expired_at: yup.date().required("Data de vencimento é obrigatória"),
});

export function ModalAcceptComplaint({
	open,
	handleClose,
	code,
	complaint,
}: ModalRefuseComplaintProps) {
	const [acceptComplaint, { loading }] = useMutation(ACCEPT_COMPLAINT);

	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			subject_id: null as number,
			group_id: null as number,
			responsible_user_id: null as string | number,
			expired_at: null,
		},
	});
	const [selectedResponsible, setSelectedResponsible] = useState(null);
	const [isSubjectRequired, setIsSubjectRequired] = useState(false);
	const [isResponsibleRequired, setIsResponsibleRequired] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const { handleSubmit, reset, control, setValue, watch } = methods;
	const subjectId = watch("subject_id");
	const groupId = watch("group_id");

	const { data: dataProtocols } = useQuery<FetchProtocols>(FETCH_PROTOCOLS, {
		fetchPolicy: "network-only",
		variables: {
			subject_ids: [subjectId],
			zipcode: complaint.zipcode ?? null,
		},
	});

	const { data: dataSubjects } = useQuery<FetchSubjects>(FETCH_SUBJECTS, {
		variables: {
			limit: 1000,
		},
	});

	const [nextUserComplaint] = useLazyQuery(NEXT_USER_COMPLAINT);
	async function onSubmit(data: {
		subject_id: number;
		group_id: number;
		responsible_user_id: 1;
		expired_at: Date | null;
	}) {
		if (!data.subject_id) {
			setIsSubjectRequired(true);
			return;
		}
		if (!data.responsible_user_id) {
			setIsResponsibleRequired(true);
			return;
		}

		try {
			await acceptComplaint({
				variables: {
					code: code,
					subject_id: data.subject_id,
					responsible_user_id: data.responsible_user_id,
					expire_at: format(data.expired_at, "yyyy-MM-dd"),
				},
			});
			enqueueSnackbar("Reclamação aceita com sucesso", {
				variant: "success",
			});
			handleClose();
			setTimeout(() => {
				window.location.href = "/fiscalizacao/denuncias";
			}, 500);
			reset({
				subject_id: null,
				responsible_user_id: null,
				expired_at: null,
			});
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
	}

	const onRequestClose = () => {
		reset({
			subject_id: null,
			responsible_user_id: null,
			expired_at: null,
		});
		setIsResponsibleRequired(false);
		setIsSubjectRequired(false);
		handleClose();
	};

	const searchComplaint = async () => {
		try {
			if (groupId) {
				const data = await nextUserComplaint({
					variables: {
						group_id: groupId,
						subject_id: subjectId,
						status: "Ativo",
					},
				});
				setSelectedResponsible(data?.data?.nextUserComplaint);
			}
		} catch (e) {
			enqueueSnackbar(e.message, { variant: "error" });
		}
	};

	useEffect(() => {
		searchComplaint();
	}, [groupId]);
	return (
		<FormProvider {...methods}>
			<ModalLayout
				open={open}
				handleClose={() => onRequestClose()}
				title="Aceitar denúncia"
				handleSubmit={handleSubmit(onSubmit)}
				isLoading={loading}
			>
				<Typography>
					{complaint.address}, {complaint.number} - {complaint.district}, {complaint.zipcode}
				</Typography>
				<Stack spacing={2} marginTop={2}>
					<RadioFilter
						title="Selecionar assunto"
						placeholder="Assunto*"
						queryName={FETCH_SUBJECTS}
						dataName="fetchSubjects"
						handleSelect={(value) => setValue("subject_id", value)}
						isRequired={isSubjectRequired}
					/>
					{subjectId &&
						dataProtocols &&
						dataProtocols.fetchProtocols.total > 0 &&
						complaint.zipcode && (
							<DescriptionAlert
								zipcode={true}
								protocols={dataProtocols.fetchProtocols.data}
								numberProtocols={dataProtocols.fetchProtocols.total}
								info={complaint.zipcode}
								subjectName={
									dataSubjects.fetchSubjects.data.find(
										(item) => item.id === subjectId
									)?.name || null
								}
							/>
						)}
					{subjectId && (
						<RadioFilter
							title="Selecione um grupo fiscal*"
							placeholder="Grupo Fiscal*"
							queryName={FETCH_GROUPS}
							dataName="fetchGroups"
							handleSelect={(value) => setValue("group_id", value)}
							moreVariables={{
								subject_id: subjectId,
							}}
							isRequired={isResponsibleRequired}
							messageEmptyTitle="Não existe nenhum grupo fiscal vinculado ao assunto."
							messageEmpty="Crie um grupo fiscal para o assunto selecionado."
						/>
					)}
					{subjectId && groupId && (
						<RadioFilter
							title="Selecionar fiscal"
							placeholder="Fiscal*"
							queryName={FETCH_USERS}
							dataName="fetchUsers"
							moreVariables={{
								user_id: selectedResponsible
									? Number(selectedResponsible)
									: null,
							}}
							handleSelect={(value) => setValue("responsible_user_id", value)}
							defKeyword={selectedResponsible}
							isActiveUsers
							isRequired={isResponsibleRequired}
							messageEmptyTitle="Não existe nenhum fiscal vinculado ao assunto."
							messageEmpty="Crie um fiscal para o assunto selecionado."
						/>
					)}

					<Controller
						control={control}
						name="expired_at"
						render={(inputProps) => (
							<LocalizationProvider
								dateAdapter={AdapterDayjs}
								adapterLocale="pt-br"
							>
								<DatePicker
									value={inputProps.field.value}
									onChange={(newValue) => inputProps.field.onChange(newValue)}
									inputFormat="DD/MM/YYYY"
									label="Data para resolução*"
									renderInput={(params) => (
										<TextField
											{...params}
											sx={{ width: "100%" }}
											error={inputProps.fieldState.error && true}
										/>
									)}
									disablePast
								/>
							</LocalizationProvider>
						)}
					/>
				</Stack>
			</ModalLayout>
		</FormProvider>
	);
}
