import { Box, Fab, FabProps } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { CircularProgress } from "components/CircularProgress";

type FabButtonProps = FabProps & {
	isLoading?: boolean;
};

export function SaveFabButton({ isLoading, ...rest }: FabButtonProps) {
	return (
		<Box position="fixed" bottom={32} right={50}>
			<Fab color="primary" aria-label="add" {...rest} disabled={isLoading}>
				{isLoading ? <CircularProgress color="inherit" /> : <SaveIcon />}
			</Fab>
		</Box>
	);
}
