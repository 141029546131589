import { Animation } from "@devexpress/dx-react-chart";
import {
	ArgumentAxis,
	BarSeries,
	Chart,
	ValueAxis,
} from "@devexpress/dx-react-chart-material-ui";
import { Typography } from "@mui/material";

interface VariantBarProps {
	chartData: Array<{
		key: string;
		value: number;
	}>;
	title: string;
	label: string;
	rotate?: boolean;
	medias?: { [key: string]: boolean };
}

export function VariantBar({
	chartData = [],
	title = "",
	rotate = false,
	label = "",
	medias
}: VariantBarProps) {
	return (
		<>
			<Typography variant="h6">{title}</Typography>
			<Typography variant="caption">{label}</Typography>
			<Chart data={chartData} rotated={rotate} height={300}>
				<ArgumentAxis />
				<ValueAxis />

				<BarSeries valueField="value" argumentField="key" color="#8B64FE" />

				<Animation />
			</Chart>
		</>
	);
}
