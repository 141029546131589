/* eslint-disable prefer-const */
import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";

interface ColorPickerProps {
	handleColor: (color: string) => void;
}

export function ColorPicker({ handleColor }: ColorPickerProps) {
	const [color, setColor] = useState("#fff");
	const { enqueueSnackbar } = useSnackbar();

	function checkLuminosity(hex: string) {
		let r: number, g: number, b: number, long: boolean;
		hex = hex.replace("#", "");
		long = hex.length > 3;

		r = long
			? parseInt(hex.substr(0, 2), 16)
			: parseInt(hex.substr(0, 1), 16) * 17;
		g = long
			? parseInt(hex.substr(2, 2), 16)
			: parseInt(hex.substr(1, 1), 16) * 17;
		b = long
			? parseInt(hex.substr(4, 2), 16)
			: parseInt(hex.substr(2, 1), 16) * 17;

		return (r * 299 + g * 587 + b * 114) / 1000;
	}

	const handleChangeColor = (value: string) => {
		const luminosity = checkLuminosity(value);
		if (luminosity > 128) {
			enqueueSnackbar("Atenção! Não é possível utilizar cores muito claras", {
				variant: "warning",
				preventDuplicate: true,
				autoHideDuration: 6000,
			});
			return;
		}
		setColor(value);
		handleColor(value);
	};

	return (
		<Box>
			<SketchPicker
				color={color}
				onChange={(e) => handleChangeColor(e.hex)}
				presetColors={[
					"#D0021B",
					"#8B572A",
					"#417505",
					"#BD10E0",
					"#9013FE",
					"#000000",
					"#4A4A4A",
				]}
			/>
		</Box>
	);
}
