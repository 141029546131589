import {
	Grid,
	Typography,
	Stack,
	Box,
	Divider,
	Collapse,
	styled,
	FormHelperText,
} from "@mui/material";
import { Checkbox } from "components/Checkbox";
import IconButton from "components/IconButton";
import { Select } from "components/Select";
import { Fragment, useEffect, useState, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RadioButton } from "components/RadioButton";
import Input from "components/Input";
import {
	columnsProtocol,
	columnsTasks,
	dataColumnsProtocols,
	dataColumnsTasks,
	dataSource,
	groupByProtocols,
	groupByTasks,
	headersProtocols,
	headersTasks,
	orderBy,
	orderDirection,
	visualizationRules,
} from "./consts";
import { useSnackbar } from "notistack";
import { grey } from "@mui/material/colors";
import { ModalSharePerfis } from "pages/Reports/CustomReports/ModalSharePerfis";

const Root = styled("div")(
	() => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${grey[500]};
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: ${"#fff"};
  }
  `
);

export function FormCreateAnalyticalReport() {
	const [isOpenModalSharePerfis, setIsOpenModalSharePerfis] = useState(false);
	const {
		watch,
		control,
		setValue,
		resetField,
		formState: { errors },
	} = useFormContext();
	const { enqueueSnackbar } = useSnackbar();
	const dataSourceSelected = watch("data_source");
	const columnsSelected = watch("columns_name");
	const visualizationSelected = watch("visualization_rule");
	const profileIds = watch("profile_ids");
	const isFirstRender = useRef(true);

	const handleOpenModalSharePerfis = () => {
		setIsOpenModalSharePerfis(true);
	};

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}
		resetField("columns_name");
		setValue("columns_name", ["responsibleName", "userName"]);
	}, [dataSourceSelected]);

	const handleAddPerfis = (perfis: Array<{ id: number; name: string }>) => {
		setValue("profile_ids", perfis);
		setIsOpenModalSharePerfis(false);
	};

	const [isShowColumns, setIsShowColumns] = useState(false);

	const columns =
		dataSourceSelected === "Protocolos" ? columnsProtocol : columnsTasks;

	const groupBy =
		dataSourceSelected === "Protocolos" ? groupByProtocols : groupByTasks;

	const headers =
		dataSourceSelected === "Protocolos" ? headersProtocols : headersTasks;

	const rows =
		dataSourceSelected === "Protocolos"
			? dataColumnsProtocols
			: dataColumnsTasks;
	return (
		<>
			<Grid container height={"100%"}>
				<Grid item xs pr={4}>
					<Box mb="20px">
						<Typography color="#2660F6" fontWeight={400} fontSize="24px">
							Criar Relatório
						</Typography>
					</Box>
					<Box>
						<Select
							name="data_source"
							label="Fonte de dados"
							options={dataSource}
							fullWidth
							marginSelect={0}
						/>
					</Box>
					<Stack mt="20px" spacing={0.5} direction="row" alignItems="center">
						<Typography variant="body2">Colunas em exibição</Typography>
						<IconButton onClick={() => setIsShowColumns((prev) => !prev)}>
							{isShowColumns ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</IconButton>
					</Stack>
					<Collapse in={isShowColumns}>
						<Stack>
							<Controller
								name={"columns_name"}
								control={control}
								shouldUnregister={true}
								render={(inputProps) => (
									<>
										{columns.map((column) => {
											const isChecked = columnsSelected.includes(column.value);
											return (
												<Checkbox
													key={column.value}
													label={column.label}
													name={column.value}
													checked={isChecked}
													value={inputProps.field.value}
													onChange={(e) => {
														const prev = [...columnsSelected];
														const findColumn = prev.findIndex((column) => {
															return column === e.currentTarget.name;
														});
														if (findColumn >= 0) {
															if (prev.length === 1) {
																enqueueSnackbar(
																	"É necessário selecionar pelo menos uma coluna",
																	{
																		variant: "warning",
																	}
																);
																return;
															}
															prev.splice(findColumn, 1);
															inputProps.field.onChange(prev);
															return;
														}
														prev.push(e.currentTarget.name);
														inputProps.field.onChange(prev);
													}}
												/>
											);
										})}
									</>
								)}
							/>
						</Stack>
					</Collapse>
					<Stack spacing={1} mt="30px">
						<Typography variant="body2">Agrupamento:</Typography>
						<Select
							name="group_by"
							label="Agrupar por"
							fullWidth
							marginSelect={0}
							options={groupBy}
							isShowEmptyOption
						/>
					</Stack>
					<Stack spacing={1} mt="30px">
						<Typography variant="body2">Ordenação:</Typography>
						<Select
							name="order_by"
							label="Ordenar por"
							fullWidth
							marginSelect={0}
							options={orderBy}
						/>
					</Stack>
					<Box mt="6px">
						<RadioButton
							name="order_direction"
							label=""
							radios={orderDirection}
						/>
					</Box>
				</Grid>
				<Divider orientation="vertical" flexItem />
				<Grid item xs pl={4}>
					<Box mb="20px">
						<Typography fontWeight={400} fontSize="24px">
							Visualização
						</Typography>
					</Box>
					<Box>
						<Input name="title" placeholder="Título" fullWidth />
					</Box>
					<Box my="40px">
						<Root>
							<table>
								<thead>
									<tr>
										{Object.keys(headers).map((key) => {
											const headersToRender = columnsSelected.find(
												(item) => item === key
											);
											if (headersToRender)
												return <th key={key}>{headers[headersToRender]}</th>;
										})}
									</tr>
								</thead>
								<tbody>
									{rows.map((row, index) => {
										const keyValue = Object.keys(row);
										const valueToRender = columnsSelected.filter((item) => {
											return keyValue.includes(item);
										});
										
										return (
											<tr key={index}>
												{valueToRender.map((key) => {
													return <td key={key}>{row[key] || " - "}</td>;
												})}
											</tr>
										);
									})}
								</tbody>
							</table>
						</Root>
					</Box>
					<Box>
						<Input
							name="author"
							placeholder="Autor"
							fullWidth
							readOnly
							disabled
						/>
					</Box>
					<Box mt="6px">
						<RadioButton
							name="visualization_rule"
							label=""
							radios={visualizationRules}
							row={false}
							onClick={(e) => {
								if (e.currentTarget.id === "Perfis") {
									handleOpenModalSharePerfis();
								}
							}}
						/>
						{errors.visualization_rule && (
							<FormHelperText error>
								{errors.visualization_rule.message + ""}
							</FormHelperText>
						)}
						{profileIds.length > 0 && visualizationSelected === "Perfis" && (
							<Box>
								<Typography variant="caption">Perfis Selecionados:</Typography>
								<Typography>
									{profileIds.map((item, index) => {
										const isLastIndex = index < profileIds.length - 1;
										return (
											<Fragment key={index}>
												{item.name} {isLastIndex && ", "}
											</Fragment>
										);
									})}
								</Typography>
							</Box>
						)}
					</Box>
				</Grid>
			</Grid>
			{isOpenModalSharePerfis && (
				<ModalSharePerfis
					open={isOpenModalSharePerfis}
					handleClose={() => setIsOpenModalSharePerfis(false)}
					handleAddPerfis={(perfis) => handleAddPerfis(perfis)}
				/>
			)}
		</>
	);
}
