import {
	Box,
	CircularProgress,
	FormControl,
	Grid,
	Pagination,
	Radio,
	RadioGroup,
	Stack,
	Typography,
} from "@mui/material";
import { EmptyMessage } from "components/EmptyMessage";
import IconButton from "components/IconButton";
import GenericInput from "components/Input/GenericInput";
import { ModalLayout } from "components/ModalLayout";
import { format } from "date-fns";
import SearchIcon from "@mui/icons-material/Search";
import { DocumentNode, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import useDebounce from "hooks/useDebounce";

interface RadioFilterModalProps {
	title: string;
	open: boolean;
	handleClose: () => void;
	handleSelectItem: (values: {
		id: number;
		name: string;
		email?: string;
	}) => void;
	queryName: DocumentNode;
	dataName: string;
	moreVariables?: {
		[key: string]: number | string | boolean | null;
	};
	isActiveUsers?: boolean;
	messageEmpty?: string;
	messageEmptyTitle?: string;
	valueSelected?: number;
	defaultValue?: {
		id: number;
		name: string;
		email?: string;
	};
	officialProtocol?: {
		[key: string]: number | string | boolean | null;
	};
	defKeyword?: string;
}

export function RadioFilterModal({
	title,
	open,
	handleClose,
	handleSelectItem,
	queryName,
	moreVariables,
	isActiveUsers,
	dataName,
	messageEmpty = "Nenhum item encontrado",
	messageEmptyTitle,
	defaultValue,
	defKeyword,
	officialProtocol,
}: RadioFilterModalProps) {
	const [page, setPage] = useState(1);
	const [keyword, setKeyword] = useState("");
	const [disableVariables, setDisabledVariables] = useState(false);
	const [selected, setSelected] = useState<{
		id: number;
		name: string;
		email?: string;
	} | null>(defaultValue || null);

	const isMoreVariables = !disableVariables ? { ...moreVariables } : {};

	const debouncedValue = useDebounce(keyword, 500);
	const { data, loading } = useQuery(queryName, {
		fetchPolicy: "network-only",
		variables: {
			keyword: debouncedValue,
			page: page,
			...isMoreVariables,
			...officialProtocol,
			...(isActiveUsers && { status: "Ativo" }),
		},
	});
	const onChangeText = (keyword: string) => {
		if (keyword.length > 0) setDisabledVariables(true);
		else setDisabledVariables(false);
		setPage(1);
		setKeyword(keyword);
	};

	useEffect(() => {
		if (data && data?.fetchUsers?.data.length == 1 && defKeyword) {
			if (data.fetchUsers.data[0].id !== Number(defKeyword)) {
				return;
			}
			const selectedData = {
				id: data.fetchUsers.data[0].id,
				name: data.fetchUsers.data[0].name,
				email: data.fetchUsers.data[0].email || undefined,
			};
			setSelected(selectedData);
			handleSelectItem(selectedData);
			setKeyword("");
		}
	}, [data]);

	const onSelectItem = () => {
		handleSelectItem(selected);
	};

	return (
		<ModalLayout
			title={title}
			open={open}
			handleClose={handleClose}
			buttonText="Selecionar"
			handleSubmit={() => onSelectItem()}
		>
			<Box marginTop={2}>
				<GenericInput
					endAdornment={
						<IconButton>
							<SearchIcon />
						</IconButton>
					}
					placeholder="Pesquisar"
					value={keyword}
					onChange={(e) => onChangeText(e.target.value)}
				/>
				<Grid container spacing={2} marginTop={2}>
					{loading && (
						<Box flex={1} alignItems="center" justifyContent="center">
							<CircularProgress />
						</Box>
					)}
					{data && data[dataName].data.length === 0 && (
						<EmptyMessage message={messageEmpty} title={messageEmptyTitle} />
					)}
					{data && (
						<FormControl fullWidth>
							<RadioGroup row aria-labelledby={title} name={title}>
								{data[dataName].data.map((item) => {
									return (
										<Grid
											key={item.id}
											display="flex"
											flex={1}
											item
											xs={12}
											marginTop={3}
											alignItems="center"
										>
											<Radio
												onChange={() =>
													setSelected({
														id: item.id,
														name: item.name,
														email: item.email || undefined,
													})
												}
												value={selected}
												checked={selected?.id === item.id}
												sx={{
													height: 40,
												}}
											/>
											<Stack
												marginRight={2}
												flex={1}
												spacing={2}
												direction="row"
												justifyContent="space-between"
												width="100%"
											>
												<Box display="flex" columnGap={2} flex={1}>
													<Box flex={1}>
														<Box>
															<Typography variant="caption">
																Nome:{" "}
																<Typography variant="subtitle2">
																	{item.name}
																</Typography>
															</Typography>
														</Box>
														{item.email && (
															<Box>
																<Typography variant="caption">
																	Email:{" "}
																	<Typography variant="subtitle2">
																		{item.email}
																	</Typography>
																</Typography>
															</Box>
														)}
													</Box>
													{item.created_at && (
														<Box>
															<Typography variant="caption">
																Criado em:{" "}
																<Typography variant="subtitle2">
																	{format(
																		new Date(item.created_at),
																		"dd/MM/yyyy HH:mm"
																	)}
																</Typography>
															</Typography>
														</Box>
													)}
												</Box>
											</Stack>
										</Grid>
									);
								})}
							</RadioGroup>
						</FormControl>
					)}
					<Grid item xs={12}>
						{data && data[dataName].total > 15 && (
							<Pagination
								count={data[dataName].last_page}
								onChange={(event, value) => {
									setPage(value);
								}}
								page={page}
							/>
						)}
					</Grid>
				</Grid>
			</Box>
		</ModalLayout>
	);
}
