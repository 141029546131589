export function getLastMonths(monthsToIterate: number) {
	const currentDate = new Date();
	const months = [];

	for (let i = 0; i < monthsToIterate; i++) {
		const month = currentDate.getMonth();
		const year = currentDate.getFullYear();
		const formattedDate = new Intl.DateTimeFormat("pt-BR", {
			month: "short",
		}).format(currentDate);

		months.push(formattedDate);

		currentDate.setMonth(month - 1);
		if (month === 0) {
			currentDate.setFullYear(year - 1);
		}
	}

	return months;
}

export function getLastYears(yearsToIterate: number) {
	const currentDate = new Date();
	const years = [];

	for (let i = 0; i < yearsToIterate; i++) {
		const year = currentDate.getFullYear();
		years.push(year);

		currentDate.setFullYear(year - 1);
	}

	return years;
}

export function getLastDays(daysToIterate: number) {
	const currentDate = new Date();
	const days = [];

	for (let i = 0; i < daysToIterate; i++) {
		const day = currentDate.getDate();
		days.push(day);

		currentDate.setDate(day - 1);
	}

	return days;
}
