import { Handle, NodeProps, Position, useStore } from "reactflow";
import { Typography, Box } from "@mui/material";

const connectionNodeIdSelector = (state) => state.connectionNodeId;

function End({
	data,
	id,
	isConnectable,
	targetPosition = Position.Top,
}: NodeProps) {
	const connectionNodeId = useStore(connectionNodeIdSelector);
	const isTarget = connectionNodeId && connectionNodeId !== id;

	return (
		<>
			<Typography
				variant="body2"
				fontSize={8}
				alignSelf="center"
				color="inherit"
				textAlign="center"
			>
				{data?.label}
			</Typography>
			<Box
				height="30px"
				width="30px"
				sx={{
					backgroundColor: data.faveColor ? data.faveColor : "#55ab63",
					"&:hover:before": {
						content: "' '",
						position: "absolute",
						top: "7px",
						left: "50%",
						height: "10px",
						width: "10px",
						transform: "translate(-50%, 0)",
						background: "#ff0000",
						zIndex: 1000,
						lineHeight: 1,
						borderRadius: "50%",
						color: "#fff",
						fontSize: "9px",
						border: "1px solid #222138",
					},
				}}
				borderRadius="50%"
				alignItems="center"
				justifyContent="center"
				display="flex"
				padding={2}
				border="1px solid #000"
			>
				<Handle
					type="target"
					position={targetPosition}
					isConnectable={isConnectable}
					style={{
						width: "100%",
						height: "100%",
						background: "blue",
						position: "absolute" as const,
						top: 0,
						left: 0,
						borderRadius: 0,
						transform: "none",
						border: "none",
						opacity: 0,
						zIndex: isTarget ? 3 : 1,
					}}
				/>
			</Box>
		</>
	);
}

export default End;
