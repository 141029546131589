/* eslint-disable react/prop-types */
import { Animation, ScaleObject } from "@devexpress/dx-react-chart";
import {
	ArgumentAxis,
	Chart,
	Legend,
	LineSeries,
	AreaSeries,
	ValueAxis,
	ScatterSeries,
	Tooltip,
} from "@devexpress/dx-react-chart-material-ui";
import { Box, Grid, styled, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { EventTracker } from "@devexpress/dx-react-chart";

const format = () => (tick: string) => tick;

const Root = (props: Legend.RootProps) => <Grid container sx={{
	fontSize: "1rem"
}} {...props} />;
const Label = (props: Legend.LabelProps) => (
	<Legend.Label
		sx={{ pt: 0, whiteSpace: "nowrap" }}
		{...props}
	/>
);
const Item = (props: Legend.ItemProps) => (
	<Grid item xs>
		<Legend.Item {...props} sx={{ p: 0 }} />
	</Grid>
);
const formatForFullstack = (scale: ScaleObject) => scale.tickFormat(null, "s");

const ValueLabel = (props: ValueAxis.LabelProps) => {
	const { text } = props;
	return <ValueAxis.Label {...props} text={`${text}`} />;
};

interface VariantTaskChartProps {
	title: string;
	label: string;
	chartData: Array<{
		[key: string]: number;
	}>;
	series: Array<{
		name: string;
		valueField: string;
		argumentField: string;
	}>;
	medias?: { [key: string]: boolean };
}

export function VariantTaskChart({
	title = "",
	label = "",
	chartData = [],
	series = [],
	medias
}: VariantTaskChartProps) {
	const StyledChart = styled(Chart)(() => ({
		paddingRight: "0px",
	}));

	return (
		<>
			<Typography variant="h6">{title}</Typography>
			<Typography variant="caption">{label}</Typography>
			<StyledChart data={chartData.reverse()} height={300}>
				<ArgumentAxis tickFormat={format} showGrid />
				<ValueAxis labelComponent={ValueLabel} />

				{series.reverse().map((serie) => (
					<Fragment key={serie.valueField}>
						<LineSeries
							name={serie.name}
							valueField={serie.valueField}
							argumentField={serie.argumentField}
						/>
					</Fragment>
				))}
				<EventTracker />
				<Tooltip />
				{/* <Legend
					position="top"
					rootComponent={Root}
					itemComponent={Item}
					labelComponent={Label}
				/> */}
				<Animation />
			</StyledChart>
		</>
	);
}
