/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import { Box } from "@mui/material";
import React, { useCallback, useState, useRef, useEffect } from "react";
import { Header } from "components/Header";
import ReactFlow, {
	Node,
	addEdge,
	Edge,
	Connection,
	useNodesState,
	useEdgesState,
	Panel,
	Controls,
	ReactFlowProvider,
	MarkerType,
	updateEdge,
	useKeyPress,
} from "reactflow";
import "reactflow/dist/style.css";
import BackgroundFlow from "../../components/Flow/BackgroundFlow";
import { ModalFlow } from "../../components/Flow/Modal";
import { SaveFabButton } from "components/SaveFabButton";
import { v4 as uuidv4 } from "uuid";
import { useForm, FormProvider } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { CREATE_FLOW } from "graphql/mutations/createFlow";
import { useSnackbar } from "notistack";
import Form from "../../components/Flow/FormPanel";
import { nodeTypes } from "utils/nodeTypes";
import { edgeTypes } from "utils/edgeTypes";
import ConfigurationPanel from "../../components/Flow/ConfigurationPanel";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import ModalUpdateEdgeLabel from "../../components/Flow/Modal/Form/updateLabelEdge";
import CustomConnectionLine from "./CustomConnectionLine";
import FormPanel from "../../components/Flow/FormPanel";
import { FlowProvider, useFlowContext } from "context/FlowContext";

const connectionLineStyle = {
	strokeWidth: 3,
	stroke: "gray",
};

const defaultEdgeOptions = {
	style: { strokeWidth: 3, stroke: "gray" },
	type: "floating",
	markerEnd: {
		type: MarkerType.ArrowClosed,
		color: "gray",
	},
};

const schema = yup.object().shape({
	name: yup.string().required("Nome é obrigatório"),
	description: yup.string(),
	type: yup.string(),
	refuseComplaint: yup.string(),
});

export function Flow() {
	return (
		<FlowProvider isFromCreate={true}>
			<CreateFlow />
		</FlowProvider>
	)
}

function CreateFlow() {

	const { nodes, edges, setNodes, setEdges, onNodesChange, onEdgesChange }: any = useFlowContext()

	const [versionHistory, setVersionHistory] = useState(false);

	const [isOpenModalFlow, setIsOpenModalFlow] = useState(false);
	const [isOpenModalUpdateEdgeLabel, setIsOpenModalUpdateEdgeLabel] =
		useState(false);
	const [reactFlowInstance, setReactFlowInstance] = useState(null);
	const [backGroundVariant, setBackgroundVariant] = useState<
		"lines" | "dots" | "cross"
	>("lines");

	const [nodeClicked, setNodeClicked] = useState(null);
	const [edgeClicked, setEdgeClicked] = useState<Edge>(null);

	const edgeUpdateSuccessful = useRef(true);
	const reactFlowWrapper = useRef(null);
	const deletePressed = useKeyPress("Delete");
	const navigate = useNavigate();

	const onEdgeUpdateStart = useCallback(() => {
		edgeUpdateSuccessful.current = false;
	}, []);

	const [createFlow, { loading }] = useMutation(CREATE_FLOW);
	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			name: "",
			description: "",
			type: "",
			refuseComplaint: "true",
		},
	});
	const { enqueueSnackbar } = useSnackbar();

	const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
		edgeUpdateSuccessful.current = true;
		setEdges((els) => updateEdge(oldEdge, newConnection, els));
	}, []);

	const onEdgeUpdateEnd = useCallback((_, edge) => {
		if (!edgeUpdateSuccessful.current) {
			setEdges((eds) => eds.filter((e) => e.id !== edge.id));
		}

		edgeUpdateSuccessful.current = true;
	}, []);

	const handleOpenModalUpdateEdgeLabel = useCallback((edge) => {
		setIsOpenModalUpdateEdgeLabel(true);
		setEdgeClicked(edge);
	}, []);

	const handleCloseModalUpdateEdgeLabel = useCallback(() => {
		setIsOpenModalUpdateEdgeLabel(false);
		setEdgeClicked(null);
	}, []);

	const handleUpdateEdgeLabel = useCallback((edge) => {
		setEdges((els) => updateEdge(edge, { ...edge, label: edge.label }, els));
		handleCloseModalUpdateEdgeLabel();
	}, []);

	function onRequestCloseModal() {
		setIsOpenModalFlow(false);
		setNodeClicked(null);
	}

	const onNodeUpdate = useCallback((node: Node) => {
		setNodes((nodes) => {
			const index = nodes.findIndex((n) => n.id === node.id);
			const newNodes = [...nodes];
			newNodes[index] = node;
			return newNodes;
		});
		onRequestCloseModal();
	}, []);

	const onNodeSelect = (node) => {
		setNodeClicked(node);
		setIsOpenModalFlow(true);
	};

	const onNodeSelectToRemove = (node) => {
		setNodeClicked(node);
	};

	useEffect(() => {
		if (deletePressed && nodeClicked) {
			const idNodeStart = nodes.filter((node) => node.type === "START")[0].id;
			const findEdgeStart = edges.filter((edge) => edge.source === idNodeStart);

			const targetFromEdgeStartIsNodeTypeOccurrence = findEdgeStart.filter(
				(edge) => {
					const nodeTarget = nodes.filter((node) => node.id === edge.target)[0];
					return nodeTarget.type === "OCCURRENCE";
				}
			);

			const nodeFromTarget = nodes.filter((node) => {
				return targetFromEdgeStartIsNodeTypeOccurrence.some(
					(edge) => edge.target === node.id
				);
			});

			if (nodeFromTarget[0].id === nodeClicked.id) {
				enqueueSnackbar("Você não pode remover esse nó ligado ao início", {
					variant: "error",
				});
				return;
			}

			if (nodeClicked.type === "START") {
				enqueueSnackbar("Não é possível excluir o nó inicial", {
					variant: "error",
				});
				return;
			}

			setNodes((nodes) => nodes.filter((node) => node.id !== nodeClicked.id));
			setEdges((edges) =>
				edges.filter(
					(edge) =>
						edge.source !== nodeClicked.id && edge.target !== nodeClicked.id
				)
			);
			setNodeClicked(null);
		}
	}, [deletePressed]);

	const onConnect = useCallback(
		(params: Edge | Connection) =>
			setEdges((els) =>
				addEdge(
					{
						...params,
						id: uuidv4(),
					},
					els
				)
			),
		[setEdges]
	);

	const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = "move";
	}, []);

	const onDrop = useCallback(
		(event: React.DragEvent<HTMLDivElement>) => {
			event.preventDefault();

			const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
			const type = event.dataTransfer.getData("application/reactflow");
			const label = event.dataTransfer.getData("application/reactflow-label");
			const isExistingNodeTypeEnd =
				nodes.filter((node) => node.type === "END").length > 0;

			// check if the dropped element is valid
			if (typeof type === "undefined" || !type) {
				return;
			}

			if (type === "START") {
				enqueueSnackbar("Não é possível adicionar mais de um nó inicial", {
					variant: "warning",
				});
				return;
			}

			if (isExistingNodeTypeEnd && type === "END") {
				enqueueSnackbar("Não é possível adicionar mais de um nó final", {
					variant: "warning",
				});
				return;
			}

			const position = reactFlowInstance.project({
				x: event.clientX - reactFlowBounds.left,
				y: event.clientY - reactFlowBounds.top,
			});
			const newNode = {
				id: uuidv4(),
				type,
				position,
				data: { label },
				group: "nodes",
			};

			setNodes((nds) => nds.concat(newNode));
		},
		[reactFlowInstance, nodes]
	);

	const { handleSubmit, setValue, getValues } = methods;

	const onSubmit = async (data: {
		name: string;
		description: string;
		type: string;
		refuseComplaint: string;
		status: boolean;
	}) => {
		const nodeOccurrenceType = nodes.filter(
			(item) => item.type === "OCCURRENCE"
		);
		const isExistsNodeOccurrenceTypeWithoutOccurrenceType =
			nodeOccurrenceType.some(
				(item) => item.data.occurrenceType === null || !item.data.occurrenceType
			);

		if (isExistsNodeOccurrenceTypeWithoutOccurrenceType) {
			return enqueueSnackbar(
				"Você precisa selecionar um tipo de ocorrência para todos os nós de ocorrência",
				{
					variant: "warning",
				}
			);
		}

		const isNotExistisNodeEnd =
			nodes.filter((node) => node.type === "END").length === 0;

		const idNodeStart = nodes.filter((node) => node.type === "START")[0].id;
		const findEdgeStart = edges.filter((edge) => edge.source === idNodeStart);
		const nodesConectedsInTheStart = nodes.filter((node) => {
			return findEdgeStart.some((edge) => edge.target === node.id);
		});

		const isSomeNodeIsNotTypeOccurrence = nodesConectedsInTheStart.some(
			(node) => node.type !== "OCCURRENCE"
		);

		const targetFromEdgeStartIsNodeTypeOccurrence =
			findEdgeStart.filter((edge) => {
				const nodeTarget = nodes.filter((node) => node.id === edge.target)[0];
				return nodeTarget.type === "OCCURRENCE";
			}).length === 0;

		if (
			targetFromEdgeStartIsNodeTypeOccurrence ||
			isSomeNodeIsNotTypeOccurrence
		) {
			enqueueSnackbar("O nó inicial deve ser seguido de um nó de ocorrência", {
				variant: "error",
			});
			return;
		}

		if (isNotExistisNodeEnd) {
			enqueueSnackbar("É necessário adicionar um nó final", {
				variant: "warning",
			});
			return;
		}

		const nodesFormated = nodes.map((node) => {
			return {
				data: {
					_id: node.id,
					name: node.data.label,
					type: node.type,
					faveColor: node.data.faveColor || "",
					deadline: Number(node.data.deadline) || null,
					cancelInfraction: node.data.cancelInfraction || false,
					deadlineTodo: node.data.deadLineToDo || "",
					occurrenceType: node.data.occurrenceType
						? {
							_id: [node.data.occurrenceType.id.toString()],
							name: node.data.occurrenceType.name,
							form: node.data.occurrenceType.dynamic_form
								? {
									_id: [node.data.occurrenceType.dynamic_form.code],
								}
								: null,
						}
						: null,
					requiredAttached: node.data.requiredAttached || null,
					requiredDescription: node.data.requiredDescription || null,
					sendEmail: node.data.sendEmail || null,
					status: node.data.status
						? {
							label: node.data.status.name,
							color: node.data.status.theme,
						}
						: null,
					toPerfil: node.data.toPerfil
						? {
							_id: String(node.data.toPerfil.id),
							hasChildren: node.data.toPerfil.hasChildren || false,
							height: node.data.toPerfil.height || null,
							width: node.data.toPerfil.width || null,
							name: node.data.toPerfil.name || null,
							perfilFeatures: node.data.toPerfil.perfilFeatures || null,
						}
						: null,
					point: Number(node.data.point) || null,
					pointDescription: node.data.pointDescription || null,
				},
				graspable: node.data.graspable || false,
				group: "nodes",
				locked: node.data.locked || false,
				position: {
					x: node.position.x,
					y: node.position.y,
				},
				removed: node.data.removed || false,
				selectable: node.data.selectable || false,
				selected: node.data.selected || false,
			};
		});

		const edgesFormated = edges.map((edge) => {
			return {
				data: {
					_id: edge.id,
					source: edge.source,
					target: edge.target,
					type: edge.type,
					name: edge.label || "",
				},
			};
		});

		const dataFlow = {
			name: data.name,
			description: data.description,
			spec_start_official_protocol: data.type,
			status: data.status ? "Ativo" : "Inativo",
			auto_finish_on_complaint_refused:
				data.refuseComplaint === "true" ? true : false,
			spec: [
				...nodesFormated,
				...edgesFormated.map((edge) => ({
					...edge,
					group: "edges",
				})),
			],
		};

		try {
			await createFlow({
				variables: dataFlow,
			});
			enqueueSnackbar("Fluxo criado com sucesso", { variant: "success" });
			navigate("/configuracoes/fluxos");
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
	};

	const onSwitchBackground = useCallback(
		(variant: "lines" | "dots" | "cross") => {
			setBackgroundVariant(variant);
		},
		[]
	);

	return (
		<>
			<FormProvider {...methods}>
				<Box
					width="100%"
					display="flex"
					alignSelf="stretch"
					flexDirection="column"
					sx={{
						backgroundColor: "#f3f3f3",
					}}
					paddingBottom="1rem"
				>
					<Header title="Fluxo" isReturn />

					<ReactFlowProvider>
						<Box ref={reactFlowWrapper} flexGrow={1} height="100%">
							<ReactFlow
								nodes={nodes}
								edges={edges}
								nodeTypes={nodeTypes}
								edgeTypes={edgeTypes}
								onNodeDoubleClick={(event, node) => onNodeSelect(node)}
								onNodeClick={(event, node) => onNodeSelectToRemove(node)}
								onEdgeDoubleClick={(event, edge) =>
									handleOpenModalUpdateEdgeLabel(edge)
								}
								onNodesChange={onNodesChange}
								onEdgesChange={onEdgesChange}
								onConnect={onConnect}
								onEdgeUpdate={onEdgeUpdate}
								onEdgeUpdateStart={onEdgeUpdateStart}
								onEdgeUpdateEnd={onEdgeUpdateEnd}
								onInit={setReactFlowInstance}
								onDrop={onDrop}
								onDragOver={onDragOver}
								defaultEdgeOptions={defaultEdgeOptions}
								connectionLineComponent={CustomConnectionLine}
								connectionLineStyle={connectionLineStyle}
								fitView
							>
								<BackgroundFlow variants={backGroundVariant} color="#ccc" />
								<Controls
									position="top-right"
									style={{
										display: "flex",
									}}
								>
									<ConfigurationPanel
										handleSwitchBackgroundVariant={onSwitchBackground}
									/>
								</Controls>

								<FormPanel
									nodesOccurrencyType={nodes
										.filter((item) => item.type === "OCCURRENCE")
										.map((item) => ({
											_id: item.id as string,
											name: item.data.label as string,
										}))}
								/>
							</ReactFlow>
						</Box>
					</ReactFlowProvider>
				</Box>
				{nodeClicked && (
					<ModalFlow
						open={isOpenModalFlow}
						handleClose={() => onRequestCloseModal()}
						title="Editar Nó"
						typeForm={nodeClicked?.type === "PONTUATE" ? "pontuate" : "default"}
						node={nodeClicked}
						onNodeUpdate={onNodeUpdate}
					/>
				)}
				{edgeClicked && (
					<ModalUpdateEdgeLabel
						open={isOpenModalUpdateEdgeLabel}
						title="Atualizar Label"
						handleClose={handleCloseModalUpdateEdgeLabel}
						edge={edgeClicked}
						handleUpdateEdge={(edge) => handleUpdateEdgeLabel(edge)}
					/>
				)}
				<SaveFabButton onClick={handleSubmit(onSubmit)} isLoading={loading} />
			</FormProvider>
		</>
	);
}
