import { gql } from "@apollo/client";

export const UPDATE_ADDRESS_PROTOCOL = gql`
	mutation UpdateAddressProtocol(
		$code: String!
		$justification: String!
		$zipcode: String
		$street: String
		$number: String
		$complement: String
		$district_name: String
	) {
		updateAddressProtocol(
			code: $code
			justification: $justification
			zipcode: $zipcode
			street: $street
			number: $number
			complement: $complement
			district_name: $district_name
		)
	}
`;

