import { useMutation, useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogTitle, DialogActions } from "@mui/material";
import { CircularProgress } from "components/CircularProgress";
import { Select } from "../Select"
import { FETCH_SUBJECTS } from "graphql/queries/fetchSubjects";
import { useForm, FormProvider } from "react-hook-form";
import { FetchSubjects } from "types/graphql/queries/fetchSubjects";
import * as yup from "yup";
import { Backdrop } from "@mui/material";
import Button from "components/Button";
import { CHANGE_PROTOCOL_SUBJECT } from "graphql/mutations/changeProtocolSubject";
import { useSnackbar } from "notistack";
import { ChangeProtocolSubject } from "types/graphql/mutations/changeProtocolSubject";
import { FetchProtocols } from "types/graphql/queries/fetchProtocols";
import { FETCH_PROTOCOLS } from "graphql/queries/fetchProtocols";
import { DescriptionAlert } from "components/Warning";
import LoadingButton from "components/LoadingButton";

type Props = {
    open: boolean;
    handleClose: () => void;
    data: any;
    onEdit: () => void;
};

export default function ChangeSubjectDialog({ open, handleClose, data, onEdit }: Props) {

    const { enqueueSnackbar } = useSnackbar();


    const schema = yup.object().shape({
        subject_id: yup.number().required("Campo obrigatório")
    });
    const methods = useForm({
        defaultValues: {
            code: data.code,
            subject_id: data.subject.id
        },
        resolver: yupResolver(schema),
    });

    const {
        getValues,
        handleSubmit,
        watch,
        formState: { errors },
    } = methods;
    const subjectId = watch("subject_id");

    const { data: dataSubjects } = useQuery<FetchSubjects>(FETCH_SUBJECTS, {
        variables: {
            order_by: "subjects.name",
            direction: "asc",
            limit: 1000
        },
    });

    const { data: dataProtocols } = useQuery<FetchProtocols>(FETCH_PROTOCOLS, {
        fetchPolicy: "network-only",
        variables: {
            subject_ids: [getValues("subject_id")],
            zipcode: data.zipcode
        }
    })

    const [changeProtocolSubject, { loading: loadingMutation }] = useMutation<ChangeProtocolSubject>(CHANGE_PROTOCOL_SUBJECT)

    const onSubmit = async (values: any) => {
        try {
            await changeProtocolSubject({
                variables: {
                    code: values.code,
                    subject_id: values.subject_id
                }
            })
            onEdit()
        } catch (err) {
            enqueueSnackbar("Error ao editar assunto!", { variant: "error" })
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            maxWidth="sm"
            fullWidth={true}
        >
            <FormProvider {...methods}>
                <DialogTitle id="scroll-dialog-title">Editar Assuntos</DialogTitle>

                {data && dataSubjects ? (
                    <Select
                        name="subject_id"
                        label="Assunto*"
                        options={dataSubjects.fetchSubjects.data.map((subject) => ({
                            label: subject.name,
                            value: subject.id,
                        }))}

                    />
                ) : (
                    <CircularProgress size={20} />
                )}

                {
                    data.complaint?.zipcode &&
                    dataProtocols &&
                    dataSubjects &&
                    dataProtocols.fetchProtocols.total > 0 && (
                        <DescriptionAlert
                            protocols={dataProtocols.fetchProtocols.data}
                            numberProtocols={dataProtocols.fetchProtocols.total}
                            info={data.complaint.zipcode}
                            zipcode={true}
                            subjectName={
                                dataSubjects.fetchSubjects.data.find(
                                    (item) => item.id === subjectId
                                )?.name || null
                            }
                        />
                    )
                }

                <DialogActions>
                    <LoadingButton
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                        loading={loadingMutation}
                    >
                        Editar
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    )

}
