export const maskMoney = (text: any) => {
	if (text === null) return "";

	if (typeof text === "number") text = text.toFixed(2).toString();

	let sig = "";

	if (text < 0) sig = "-";

	text = text.replace(/[\D]+/g, "").replace(/\b0+/g, "").padStart(3, "0");
	const unformatted = text.replace(/([0-9]{2})$/g, ".$1");
	const number = unformatted.split(".");
	number[0] = number[0].split(/(?=(?:...)*$)/).join(".");

	return `R$${sig}${number.join(",")}`;
};

export const unMaskMoney = (text: string) => {
	return text
		? parseFloat(text.replace("R$", "").replace(".", "").replace(",", "."))
		: 0;
};

export const maskCEP = (text: string) => {
	if (!text) return "";
	text = text.replace(/\D/g, "");
	text = text.replace(/^(\d{5})(\d{3})/, "$1-$2");
	return text;
};

export const maskPhone = (text: string) => {
	text = text.replace(/\D/g, "");
	text = text.replace(/^(\d\d)(\d)/g, "($1) $2");
	text = text.replace(/(\d{5})(\d)/, "$1-$2");

	return text;
};

export const maskCNPJ = (text: string) => {
	text = text.replace(/\D/g, "");
	text = text.replace(/^(\d{2})(\d)/, "$1.$2");
	text = text.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
	text = text.replace(/\.(\d{3})(\d)/, ".$1/$2");
	text = text.replace(/(\d{4})(\d)/, "$1-$2");
	return text;
};

export const maskCPF = (text: string) => {
	text = text.replace(/\D/g, "");
	text = text.replace(/(\d{3})(\d)/, "$1.$2");
	text = text.replace(/(\d{3})(\d)/, "$1.$2");
	text = text.replace(/(\d{3})(\d{1,2})/, "$1-$2");
	return text;
};

export const maskNumber = (text: any) => {
	if (text === null) return "";

	if (typeof text === "number") text = text.toFixed(2).toString();

	let sig = "";

	if (text < 0) sig = "-";

	text = text.replace(/[\D]+/g, "").replace(/\b0+/g, "").padStart(3, "0");
	const unformatted = text.replace(/([0-9]{2})$/g, ".$1");
	const number = unformatted.split(".");
	number[0] = number[0].split(/(?=(?:...)*$)/).join(".");

	return sig + number.join(",");
};

export const unMaskNumber = (text: string) => {
	return text ? parseFloat(text.replace(".", "").replace(",", ".")) : 0;
};

export const validateNumberInput = (value: string) => {
	const regex = /^[0-9]*$/;
	return regex.test(value);
};

