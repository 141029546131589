import { Grid, TextField, FormHelperText, FormControl } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import InputMask from "react-input-mask";

interface CEPInputProps {
	name?: string;
	label?: string;
	placeholder?: string;
	required?: boolean;
	index?: number;
	readonly?: boolean;
	groupTitle?: string;
}

function FieldCEPField(props: CEPInputProps) {
	const { control } = useFormContext();
	return (
		<Grid item xs={12}>
			<Controller
				rules={{
					required: props.required ? "Campo obrigatório" : false,
				}}
				name={`groups.${props.index}.fields.${props.name}`}
				control={control}
				render={(inputProps) => (
					<FormControl fullWidth sx={{ mb: 6 }}>
						<InputMask
							mask="99999-999"
							value={inputProps.field.value}
							disabled={false}
							onChange={(e): void => inputProps.field.onChange(e.target.value)}
							width="100%"
							readOnly={props.readonly}
						>
							<TextField
								disabled={false}
								name={inputProps.field.name}
								type="text"
								label={`CEP${props.required ? "*" : ""}`}
								placeholder={`CEP${props.required ? "*" : ""}`}
								error={inputProps.fieldState.error && true}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>
						</InputMask>
						{inputProps.fieldState.error && (
							<FormHelperText sx={{ color: "error.main" }}>
								{inputProps.fieldState.error.message + ""}
							</FormHelperText>
						)}
					</FormControl>
				)}
			/>
		</Grid>
	);
}

export default FieldCEPField;
