import { gql } from "@apollo/client";

export const COUNT_PROTOCOL_SITUATIONS = gql`
	query (
		$start: String
		$finish: String
		$situations: [String]
		$subject_ids: [Int]
		$responsible_ids: [Int]
		$group_ids: [Int]
		$status_ids: [Int]
		$street: String
		$number: String
		$district_ids: [Int]
		$upfmls: Boolean
		$late_days: String
		$keyword: String
		$tasks: [String]
		$lote: String
		$quadra: String
		$cadastral_index: String
		$offenders_name: String
	) {
		countProtocolSituations(
			start: $start
			finish: $finish
			situations: $situations
			subject_ids: $subject_ids
			responsible_ids: $responsible_ids
			group_ids: $group_ids
			status_ids: $status_ids
			street: $street
			number: $number
			district_ids: $district_ids
			upfmls: $upfmls
			late_days: $late_days
			keyword: $keyword
			tasks: $tasks
			lote: $lote
			quadra: $quadra
			cadastral_index: $cadastral_index
			offenders_name: $offenders_name
		) {
			total
			in_progress
			finished
			canceled
			suspended
			lated
			groups {
				name
				in_progress
				finished
				canceled
				suspended
			}
			status {
				name
				in_progress
				finished
				canceled
				suspended
			}
		}
	}
`;
