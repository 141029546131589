import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
	Box,
	Container,
	TablePagination,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	TableBody,
	Table,
	TableSortLabel,
	TextField,
} from "@mui/material";
import { Item } from "components/Paper";
import { Header } from "components/Header";
import { EmptyMessage } from "components/EmptyMessage";
import noUser from "assets/img/noUser.png";
import { FetchProtocols } from "types/graphql/queries/fetchProtocols";
import { FETCH_PROTOCOLS } from "graphql/queries/fetchProtocols";
import { format } from "date-fns";
import useDebounce from "hooks/useDebounce";
import LocalProvider from "components/LocalProvider";

export function ReportDescontinuedProtocols() {
	const [currentPage, setCurrentPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [orderBy, setOrderBy] = useState("protocols.updated_at");
	const [direction, setDirection] = useState<"asc" | "desc">("asc");
	const [keyword, setKeyword] = useState("");

	const debouncedValue = useDebounce(keyword, 1000);

	const { data, loading, refetch } = useQuery<FetchProtocols>(FETCH_PROTOCOLS, {
		variables: {
			limit: rowsPerPage,
			page: currentPage + 1,
			situations: ["Cancelado"],
			order_by: orderBy,
			direction: direction,
			keyword: debouncedValue,
		}
	});

	function handleSort(name) {
		const isAsc = orderBy === name && direction === "asc"
		setDirection(isAsc ? "desc" : "asc")
		setOrderBy(name)
	}

	return (
		<Box
			width="100%"
			display="flex"
			alignSelf="stretch"
			flexDirection="column"
			sx={{
				backgroundColor: "#f3f3f3",
			}}
			paddingBottom="1rem"
		>
			<Header title="Relatório de protocolos descontinuados" isReturn />

			<Container maxWidth="lg" sx={{ marginTop: "1rem" }}>
				<Item
					sx={{
						paddingX: 0,
						paddingY: "1px",
					}}
				>
					<Box sx={{ padding: 2 }}>
						<TextField
							autoFocus
							fullWidth
							variant="standard"
							name="keyword"
							type="search"
							label="Pesquisar..."
							value={keyword}
							onChange={(event) => {
								setKeyword(event.target.value);
							}}
						/>
					</Box>

					<TableContainer style={{ marginTop: "10px" }}>
						{data?.fetchProtocols?.total === 0 && (
							<>
								<EmptyMessage message="Nenhum ponto para listar" />
								<Box
									marginTop={2}
									sx={{
										backgroundImage: `url(${noUser})`,
										backgroundRepeat: "no-repeat",
										backgroundPosition: "center",
										backgroundSize: "contain",
										height: "200px",
									}}
								/>
							</>
						)}


						{data?.fetchProtocols?.total !== 0 && (
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Protocolo</TableCell>
										<TableCell>Assunto</TableCell>
										<TableCell>Fiscal</TableCell>
										<TableCell>
											<TableSortLabel
												active={orderBy === "protocols.updated_at"}
												direction={orderBy === "protocols.updated_at" ? direction : "asc"}
												onClick={() => handleSort("protocols.updated_at")}
											>
												Data de descontinuação
											</TableSortLabel>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data?.fetchProtocols?.data.map((row) => (
										<>
											<TableRow key={row.code}>
												<TableCell>{row.code}</TableCell>
												<TableCell>{row.subject?.name || "-"}</TableCell>
												<TableCell>{row.responsible?.name || "-"}</TableCell>
												<TableCell>{format(new Date(row.updated_at), "dd/MM/yyyy HH:mm")}</TableCell>
											</TableRow>
										</>
									))}
								</TableBody>
							</Table>
						)}
					</TableContainer>

					{data?.fetchProtocols?.total !== 0 && (
						<LocalProvider locale="ptBR">
							<TablePagination
								showLastButton
								showFirstButton
								rowsPerPageOptions={[5, 15, 30, 60, 100]}
								component="div"
								count={data?.fetchProtocols?.total}
								rowsPerPage={rowsPerPage}
								page={currentPage}
								onPageChange={(event, newPage) => {
									setCurrentPage(newPage);
								}}
								onRowsPerPageChange={(event) => {
									setRowsPerPage(parseInt(event.target.value));
									setCurrentPage(0);
								}}
							/>
						</LocalProvider>
					)}
				</Item>
			</Container>

		</Box>
	);
}
