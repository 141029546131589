/* eslint-disable no-mixed-spaces-and-tabs */
import {
	Box,
	CircularProgress,
	FormControlLabel,
} from "@mui/material";
import { ModalLayout } from "components/ModalLayout";
import { PRINT_PROTOCOL } from "graphql/queries/printProtocol";
import { PRINT_TASK } from "graphql/queries/printTask";
import { useLazyQuery, useQuery } from "@apollo/client";
import { FetchDetailCompany } from "types/graphql/queries/fetchDetailCompany";
import { FETCH_DETAIL_COMPANY } from "graphql/queries/fetchDetailCompany";
import { DetailProtocol, Task } from "types/graphql/queries/detailProtocol";
import { useState } from "react";
import { Checkbox } from "@mui/material"


interface ModalDetailSwitchComplaintProps {
	isOpen: boolean;
	onClose: () => void;
    code: string | number;
    type?: "protocol" | "task";
	task?: Task;
	protocol?: DetailProtocol["detailProtocol"];
}
export default function ModalDetailSwitchComplaint({
	isOpen,
	onClose,
    code,
    type = "protocol",
	task,
	protocol,
}: ModalDetailSwitchComplaintProps) {
    const [switchComplaint, setSwitchComplaint] = useState(false)

		const [printProtocol, { loading }] = useLazyQuery(PRINT_PROTOCOL);
		const [printTask, { loading: loadingTasks }] = useLazyQuery(PRINT_TASK);

		const handlePrint = () => {
			if (type === "protocol") {
				printProtocol({
					variables: { code: code, is_complaint: switchComplaint },
				}).then(({ data }) => {
					const print = window.open("about:blank");
					print.document.write(data.printProtocol);
                    onClose()
				});
			} else if (type === "task") {
				printTask({
					variables: { id: code },
				}).then(({ data }) => {
					const print = window.open("about:blank");
					print.document.write(data.printTask);
                    onClose()
				});
			}
		};


    return (
			<ModalLayout
				open={isOpen}
				handleClose={onClose}
				title="Detalhes da impressão"
				handleSubmit={handlePrint}
				buttonTextClose="voltar"
				buttonText="imprimir"
				isDisabled={loading || loadingTasks}
			>
				{!loading ? (
					<FormControlLabel
						control={
							<Checkbox
								checked={switchComplaint}
								onChange={() => setSwitchComplaint((state) => !state)}
							/>
						}
						label="Imprimir com dados da denúncia"
					/>
				) : (
					<Box sx={{
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignContent: "center"
					}}>
						<CircularProgress />
					</Box>
				)}
			</ModalLayout>
		);
}