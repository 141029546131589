import {
	Link,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	TableSortLabel,
} from "@mui/material";
import { EmptyMessage } from "components/EmptyMessage";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

type ListComplaintsProps = {
	complaints: any;
	setOrderBy: React.Dispatch<React.SetStateAction<string>>;
	setDirection: React.Dispatch<React.SetStateAction<"desc" | "asc">>;
	orderBy: string;
	direction: "desc" | "asc";
};

export function ListComplaints({
	complaints,
	orderBy,
	direction,
	setOrderBy,
	setDirection,
}: ListComplaintsProps) {
	const navigate = useNavigate();
	function handleSort(name) {
		const isAsc = orderBy === name && direction === "asc";
		setDirection(isAsc ? "desc" : "asc");
		setOrderBy(name);
	}

	const handleNavigate = (data) => {
		navigate(
			{
				pathname: "/protocolo/" + data.protocol.code,
				search: "from=denuncias",
			},
			{ replace: true }
		);
	};

	return (
		<TableContainer style={{ marginTop: "10px" }}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							<TableSortLabel
								active={orderBy === "complaints.created_at"}
								direction={
									orderBy === "complaints.created_at" ? direction : "desc"
								}
								onClick={() => handleSort("complaints.created_at")}
							>
								Protocolo
							</TableSortLabel>
						</TableCell>
						<TableCell>
							<TableSortLabel
								active={orderBy === "subjects.name"}
								direction={orderBy === "subjects.name" ? direction : "asc"}
								onClick={() => handleSort("subjects.name")}
							>
								Assunto
							</TableSortLabel>
						</TableCell>
						<TableCell>
							<TableSortLabel
								active={orderBy === "responsibles.name"}
								direction={orderBy === "responsibles.name" ? direction : "asc"}
								onClick={() => handleSort("responsibles.name")}
							>
								Fiscal
							</TableSortLabel>
						</TableCell>
						<TableCell>
							<TableSortLabel
								active={orderBy === "protocols.situation"}
								direction={
									orderBy === "protocols.situation" ? direction : "asc"
								}
								onClick={() => handleSort("protocols.situation")}
							>
								Status
							</TableSortLabel>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{complaints.length > 0 ? (
						complaints.map((complaint) => (
							<TableRow key={complaint.id}>
								<TableCell>
									<Link
										onClick={() => handleNavigate(complaint)}
										underline="hover"
										sx={{
											fontWeight: 600,
											display: "block",
											cursor: "pointer",
										}}
									>
										{complaint.protocol.code}
									</Link>
									<Typography variant="caption">
										{format(new Date(complaint.created_at), "dd/MM/yyyy HH:mm")}
									</Typography>
								</TableCell>
								<TableCell>
									{complaint.protocol.subject
										? complaint.protocol.subject.name
										: "-"}
								</TableCell>
								<TableCell>
									{complaint?.protocol.responsible_user?.name}
								</TableCell>
								<TableCell>{complaint.protocol.situation || "-"}</TableCell>
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell colSpan={4}>
								<EmptyMessage message="Nenhum protocolo para avaliar" />
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
