import { ModalLayout } from "components/ModalLayout"
import { ModalLayoutProps } from "types/ModalLayout";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { CREATE_ATTACHMENT } from "graphql/mutations/createAttachment";
import Input from "components/Input";
import { Stack } from "@mui/material";
import { useState } from "react";


type ModalDetailDescriptionProps = ModalLayoutProps & {
	refetch: () => void;
	task_id: number;
	files: Array<{
		name: string;
		size: number;
		type: string;
		lastModified: number;
	}>;
};

export default function ModalDetailDescription({
	open,
	handleClose,
    refetch,
    task_id,
    files
}: ModalDetailDescriptionProps) {
    const { enqueueSnackbar } = useSnackbar();
	const [createAttachment] = useMutation(CREATE_ATTACHMENT);
    const [description, setDescription] = useState("");

    const onSubmit = async (withDescription: boolean) => {
        try {
			await createAttachment({
				variables: {
					task_id,
					files,
                    description: withDescription ? description: ""
				},
			});
			enqueueSnackbar("Arquivo enviado com sucesso", {
				variant: "success",
			});
			refetch();
			handleClose();
		} catch (err) {
			enqueueSnackbar(err.message, {
				variant: "error",
			});
		} 
    }

	return (
		<ModalLayout
			open={open}
			handleClose={() => onSubmit(false)}
			title="Descrição"
			buttonTextClose="Sem Descrição"
            handleSubmit={() => onSubmit(true)}
            
		>
			<Stack spacing={2} marginTop={2}>
				<Input
					name="justification"
					placeholder="Escreva uma descrição"
					multiline
					rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
				/>
			</Stack>
		</ModalLayout>
	);
}