import { Grid, Typography, Box } from "@mui/material";

interface FieldOffenderDataProps {
	values?: Array<{
		[key: string]:
			| string
			| number
			| boolean
			| Array<string | number | boolean>
			| any;
	}>;
}

function FieldOffenderData({ values }: FieldOffenderDataProps) {
	return (
		<>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">CPF/CNPJ</Typography>
					<Typography variant="subtitle2">
						{values && values.length > 0 && values[0].cpf 
							? values[0].cpf
							: "-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6} alignSelf="self-end">
				<Box>
					<Typography variant="caption">Nome/Razão social</Typography>
					<Typography variant="subtitle2">
						{values && values.length > 0
							? values[0].name && values[0].name
							: "-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">Nº da identidade</Typography>
					<Typography variant="subtitle2">
						{values && values.length > 0 && values[0].document
							? values[0].document
							: "-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">E-mail</Typography>
					<Typography variant="subtitle2">
						{values && values.length > 0 && values[0].email
							? values[0].email
							: "-"}
					</Typography>
				</Box>
			</Grid>

			<Grid item xs={12} md={6}>
				<Typography variant="h6" fontSize="1rem" marginBottom={1}>
					Localização e detalhes
				</Typography>
				<Box>
					<Typography variant="caption">CEP</Typography>
					<Typography variant="subtitle2">
						{values && values.length > 0 && values[0].address.zipcode
							? values[0].address.zipcode
							: "-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6} alignSelf="self-end">
				<Box>
					<Typography variant="caption">Rua</Typography>
					<Typography variant="subtitle2">
						{values && values.length > 0 && values[0].address.street
							? values[0].address.street
							: "-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">Número</Typography>
					<Typography variant="subtitle2">
						{values && values.length > 0 && values[0].address.number
							? values[0].address.number
							: "-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">Bairro</Typography>
					<Typography variant="subtitle2">
						{values && values.length > 0 && values[0].address.neighborhood
							? values[0].address.neighborhood
							: "-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">Cidade</Typography>
					<Typography variant="subtitle2">
						{values && values.length > 0 && values[0].address.city
							? values[0].address.city
							: "-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">UF</Typography>
					<Typography variant="subtitle2">
						{values && values.length > 0 && values[0].address.uf
							? values[0].address.uf
							: "-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} marginBottom={2}>
				<Box>
					<Typography variant="caption">Complemento</Typography>
					<Typography variant="subtitle2">
						{values && values.length > 0 && values[0].address.complement
							? values[0].address.complement
							: "-"}
					</Typography>
				</Box>
			</Grid>
		</>
	);
}

export default FieldOffenderData;
