import { gql } from "@apollo/client";

export const FETCH_RESTRICTION = gql`
	query {
		fetchRestrictions {
			id
			company_id
			code
			name
			created_at
		}
	}
`;
