import {
	Stack,
	Box,
	Typography,
	Button,
	List,
	ListItem,
	Link,
	Divider,
} from "@mui/material";
import { Item } from "components/Paper";
import { Chips } from "components/Chips";
import { getTheme } from "utils/protocolStatus";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import { ModalDetailFlow } from "./Modal/ModalDetailFlow";
import { Fragment, useState } from "react";
import { PrintButton } from "./PrintButton";
import { ModalJustificationProtocol } from "./Modal/ModalJustificationProtocol";
import { ModalDesignateResponsible } from "./Modal/ModalDesignateResponsible";
import { SwitchSituation } from "./SwitchSituation";
import { useSnackbar } from "notistack";
import UndoDialog from "pages/ProtocolDetail/SwitchSituation/UndoDialog";
import ModalUpdateVersion from "./ModalUpdateVersion";
import { DetailProtocol } from "types/graphql/queries/detailProtocol";
import { useVerifyNoHasRestriction } from "utils/useVerifyNoHasRestriction";
import ModalDetailSwitchComplaint from "./Modal/ModalDetailSwitchComplaint";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from "components/IconButton";
import { ShowFiles } from "components/ShowFiles/ShowFiles";
import { format } from "date-fns";
import ModalFilterProtocol from "./Modal/ModalFilterProtocol";
import { useQuery } from "@apollo/client";
import { FETCH_RELATED_PROTOCOLS } from "graphql/queries/fetchRelatedProtocols";
import { FetchRelatedProtocols } from "types/graphql/queries/fetchRelatedProtocols";
import { CircularProgress } from "components/CircularProgress";
import { ModalEditAddress } from "./Modal/ModalEditAddress";
import { maskCEP } from "utils/maskOutputs";

interface InfoResumeProps {
	protocol: DetailProtocol["detailProtocol"];
	refetch: () => void;
}

export function InfoResume({ protocol, refetch }: InfoResumeProps) {
	const [isOpenModalDetailFlow, setIsOpenModalDetailFlow] = useState(false);
	const [isOpenModalDetailSwitchComplaint, setIsOpenModalDetailSwitchComplaint] = useState(false);
	const [isOpenModalJustificationProtocol, setIsOpenModalJustificationProtocol] = useState(false);
	const [isOpenModalDesignateResponsible, setIsOpenModalDesignateResponsible] = useState(false);
	const [type, setType] = useState("");
	const [isOpenModalUndo, setIsOpenModalUndo] = useState(false);
	const [isOpenModalUpdateVersion, setIsOpenModalUpdateVersion] = useState(false);
	const [taskToUndo, setTaskToUndo] = useState(null);
	const [updateVersion, setUpdateVersion] = useState(null);
	const [isOpenModalEditAddress, setIsOpenModalEditAddress] = useState(false);
	const [isOpenModalJustification, setIsOpenModalJustification] = useState(false);

	const [isOpenModalFilterProtocol, setIsOpenModalFilterProtocol] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const onRequestCloseModalFilterProtocol = () => {
		setIsOpenModalFilterProtocol(false);
	};

	const handleOpenModalFIlterProtocol = () => {
		setIsOpenModalFilterProtocol(true);
	};

	const handleOpenModalDetailFlow = (type: string) => {
		setType(type);
		setIsOpenModalJustificationProtocol(true);
	};

	const handleOpenModalUndoTask = () => {
		setIsOpenModalUndo(true);
	};

	const noHasRestrictionUpdateFlow = useVerifyNoHasRestriction(
		"PROTOCOLO_ATUALIZAR_FLUXO"
	);
	const noHasRestrictionUpdateDesignateResponsible = useVerifyNoHasRestriction(
		"DESIGNAR_NOVO_FISCAL"
	);
	const noHasRestrictionEditAddress = useVerifyNoHasRestriction("PROTOCOLO_EDITAR_ENDEREÇO");

	const {
		data: dataProtocols,
		loading: loadingRelatedProtocols,
		refetch: refetchProtocols,
	} = useQuery<FetchRelatedProtocols>(FETCH_RELATED_PROTOCOLS, {
		fetchPolicy: "network-only",
		variables: {
			code: protocol.code,
		},
	});

	const dataListRelatedProtocols = dataProtocols?.fetchRelatedProtocols;

	return (
		<>
			<Box
				width="100%"
				display="flex"
				alignSelf="stretch"
				flexDirection="column"
				sx={{
					backgroundColor: "#f3f3f3",
				}}
			>
				<Item
					sx={{
						height: "100%",
						color: "rgba(0, 0, 0, 0.87)",
						lineHeight: "150%",
					}}
				>
					<Box display="flex" justifyContent="space-between">
						<Stack>
							<Typography variant="caption">N° Protocolo</Typography>
							<Typography variant="h6" fontWeight={500}>
								{protocol.code}
							</Typography>
							<Typography variant="caption">
								Criado em {protocol.created_at}
							</Typography>
						</Stack>
						<Stack direction="row" sx={{ alignItems: "flex-start" }}>
							{protocol.complaint ? (
								<IconButton
									aria-label="print"
									onClick={() => setIsOpenModalDetailSwitchComplaint((state) => !state)}
								>
									<PrintIcon />
								</IconButton>
							) : (
								<PrintButton code={protocol.code} protocol={protocol} />
							)}

							<SwitchSituation
								onOpenModalSwitchSituation={handleOpenModalDetailFlow}
								onOpenModalUndoTask={handleOpenModalUndoTask}
								situation={protocol.situation}
								tasks={
									protocol.tasks.filter((item) => {
										return item.type !== "Desfeita" && item.undo_at === null;
									}).length
								}
							/>
						</Stack>
					</Box>

					<Box mt={2}>
						<Box display="flex" flexDirection={"row"} justifyContent="space-between">
							<Typography variant="caption">Endereço</Typography>
							{noHasRestrictionEditAddress && (
								<IconButton
									aria-label="edit"
									onClick={() => setIsOpenModalEditAddress(true)}
									size="small"
								>
									<EditIcon />
								</IconButton>
							)}
						</Box>
						<Typography variant="body2">
							{protocol.street ? protocol.street : null}{" "}
							{protocol.number
								? (protocol.street ? ", " : "") + "nº" + protocol.number
								: null}{" "}
							{protocol.district ? protocol.district.name : null}
							{protocol.district?.city
								? " - " +
								protocol.district.city.name +
								"/" +
								protocol.district.city.state
								: null}
							{protocol.zipcode && " CEP: " + maskCEP(protocol.zipcode)}
						</Typography>
					</Box>

					{protocol &&
						protocol.address_extras &&
						JSON.parse(protocol.address_extras)?.justifications &&
						JSON.parse(protocol.address_extras).justifications.length > 0 && (
							<Box mt={2} display="flex" justifyContent="space-between">
								<Stack>
									<Link component="button" variant="caption" onClick={
										() => setIsOpenModalJustification(true)
									}>
										Histórico de atualizações do endereço
									</Link>
								</Stack>
							</Box>
						)}

					<Box mt={2} display="flex" justifyContent="space-between">
						<Stack>
							<Typography variant="caption">Status</Typography>
							<Typography variant="body2">{protocol.status || "-"}</Typography>
						</Stack>
						<Stack sx={{ justifyContent: "flex-end" }}>
							<Chips
								label={protocol.situation}
								theme={getTheme(`${protocol.situation}`)}
							/>
						</Stack>
					</Box>

					{protocol.situation === "Suspenso" && (
						<Box mt={2} display="flex" justifyContent="space-between">
							<Stack>
								<Typography variant="caption">
									Responsável pela suspensão
								</Typography>
								<Typography variant="body2">
									{protocol.suspended_by.name || "-"}
								</Typography>
							</Stack>
							<Stack>
								<Typography variant="caption">Data da suspensão</Typography>
								<Typography variant="body2">
									{protocol.suspended_by.suspended_at || "-"}
								</Typography>
							</Stack>
						</Box>
					)}

					{protocol.situation === "Cancelado" && (
						<Box mt={2} display="flex" justifyContent="space-between">
							<Stack>
								<Typography variant="caption">
									Responsável pelo cancelamento
								</Typography>
								<Typography variant="body2">
									{protocol.canceled_by.name || "-"}
								</Typography>
							</Stack>
							<Stack>
								<Typography variant="caption">Data do cancelamento</Typography>
								<Typography variant="body2">
									{protocol.canceled_by.canceled_at || "-"}
								</Typography>
							</Stack>
						</Box>
					)}

					{protocol.justification && (
						<Box mt={2} display="flex" justifyContent="space-between">
							<Stack>
								<Typography variant="caption">Justificativa</Typography>
								<Typography variant="body2">
									{protocol.justification}
									{protocol.situation === "Suspenso" && (
										<ShowFiles
											array_Files={
												protocol.suspended_by.justification_attachments
											}
										/>
									)}
									{protocol.situation === "Cancelado" && (
										<ShowFiles
											array_Files={
												protocol.canceled_by.justification_attachments
											}
										/>
									)}
								</Typography>
							</Stack>
						</Box>
					)}

					<Box mt={2}>
						<Stack>
							<Typography variant="caption">Última atualização</Typography>
							<Typography variant="body2">
								{protocol.updated_at || "-"}
							</Typography>
						</Stack>
					</Box>

					<Box mt={2}>
						<Stack>
							<Typography variant="caption">Responsável</Typography>
							<Typography variant="body2">
								{protocol.responsible?.name || "-"}
							</Typography>
						</Stack>
						{noHasRestrictionUpdateDesignateResponsible && (
							<Button
								variant="outlined"
								size="small"
								startIcon={<CachedRoundedIcon sx={{ height: "20px" }} />}
								onClick={() => setIsOpenModalDesignateResponsible(true)}
							>
								{protocol.responsible ? "ALTERAR" : "designar responsável"}
							</Button>
						)}
					</Box>

					<Box mt={2}>
						<Stack>
							<Typography variant="caption">Fluxo associado</Typography>
							<Typography variant="body2">
								{(protocol.flow && protocol.flow.name) || "-"} - Versão:{" "}
								{(protocol.flow && protocol.flow.used_version) || "-"}
							</Typography>
						</Stack>

						{protocol.flow && (
							<Box sx={{ display: "flex", justifyContent: "space-between" }}>
								<Button
									variant="outlined"
									size="small"
									startIcon={<VisibilityIcon sx={{ height: "20px" }} />}
									onClick={() => setIsOpenModalDetailFlow(true)}
								>
									Visualizar
								</Button>
								{protocol.outdated_flow === true &&
									noHasRestrictionUpdateFlow && (
										<Button
											variant="outlined"
											size="small"
											startIcon={<AddIcon sx={{ height: "20px" }} />}
											onClick={() => setIsOpenModalUpdateVersion(true)}
										>
											Atualizar versão
										</Button>
									)}
							</Box>
						)}
					</Box>
				</Item>
				<Item
					sx={{
						color: "rgba(0, 0, 0, 0.87)",
						lineHeight: "150%",
						marginTop: "16px",
						overflowY: "hidden",
						maxHeight: dataProtocols ? "600px" : "auto",
					}}
				>
					<Box>
						<Typography variant="h6" fontWeight={500}>
							Relacionados
						</Typography>
					</Box>
					<Box mt={2} mb={2}>
						<Button
							variant="outlined"
							size="small"
							startIcon={<AddIcon sx={{ height: "20px" }} />}
							onClick={handleOpenModalFIlterProtocol}
						>
							Relacionar Protocolos
						</Button>
					</Box>
					{loadingRelatedProtocols && (
						<CircularProgress sx={{ marginBottom: 2 }} />
					)}
					{!loadingRelatedProtocols && dataListRelatedProtocols?.length > 0 && (
						<Box
							sx={{
								overflowY: !dataListRelatedProtocols[1] ? "none" : "scroll",
								maxHeight: "400px",
								padding: "0px 10px",
							}}
						>
							<List>
								{dataListRelatedProtocols?.map((relatedProtocol, index) => (
									<Fragment key={index}>
										<ListItem sx={{ padding: "16px 0px" }}>
											<Stack
												direction="row"
												justifyContent="space-between"
												flexWrap="wrap"
												width="100%"
											>
												<Box
													display="flex"
													flexDirection="column"
													alignItems="spacebetween"
													width="50%"
												>
													<Box display="flex" flexDirection="column">
														<Link
															variant="subtitle2"
															href={`/protocolo/${relatedProtocol.code}`}
														>
															{relatedProtocol.code}
														</Link>
														<Typography variant="caption">
															{format(
																new Date(relatedProtocol.created_at),
																"dd/MM/yyyy HH:mm"
															)}
														</Typography>
													</Box>
													<Box marginTop={2}>
														<Typography variant="caption">
															Responsável
														</Typography>
														<Typography
															variant="subtitle2"
															sx={{ width: "150px" }}
														>
															{relatedProtocol.responsible
																? relatedProtocol.responsible.name
																: " - "}
														</Typography>
													</Box>
												</Box>
												<Box
													display="flex"
													flexDirection="column"
													alignItems="space-between"
													width="50%"
												>
													<Box display="flex" flexDirection="column">
														<Typography variant="caption">Assunto</Typography>
														<Typography variant="subtitle2">
															{relatedProtocol.subject
																? relatedProtocol.subject.name
																: " - "}
														</Typography>
													</Box>
													<Box marginTop={2}>
														<Typography variant="caption">Endereço</Typography>
														<Typography variant="subtitle2">
															{relatedProtocol.address.street
																? relatedProtocol.address.street
																: null}{" "}
															{relatedProtocol.address.number
																? (relatedProtocol.address.street ? ", " : "") +
																"nº" +
																relatedProtocol.address.number
																: null}{" "}
															{relatedProtocol.address.district
																? relatedProtocol.address.district.name
																: null}
															{protocol.district?.city
																? " - " +
																protocol.district.city.name +
																"/" +
																protocol.district.city.state
																: null}
														</Typography>
													</Box>

												</Box>
											</Stack>

										</ListItem>
										{index + 1 < dataListRelatedProtocols.length && (
											<Divider
												variant="inset"
												sx={{
													"&.MuiDivider-inset": {
														marginLeft: "0px",
														marginRight: "0px",
													},
												}}
											/>
										)}
									</Fragment>
								))}
							</List>
						</Box>
					)}
				</Item>
				{isOpenModalFilterProtocol && (
					<ModalFilterProtocol
						isOpen={isOpenModalFilterProtocol}
						onClose={onRequestCloseModalFilterProtocol}
						protocols={protocol}
						refetch={refetchProtocols}
						relatedProtocols={dataListRelatedProtocols}
						protocol_code={protocol.code}
					/>
				)}
			</Box>
			{isOpenModalDetailSwitchComplaint && (
				<ModalDetailSwitchComplaint
					isOpen={isOpenModalDetailSwitchComplaint}
					onClose={() => setIsOpenModalDetailSwitchComplaint((state) => !state)}
					code={protocol.code}
				/>
			)}
			{(protocol.flow && isOpenModalDetailFlow) && (
				<ModalDetailFlow
					open={isOpenModalDetailFlow}
					handleClose={() => setIsOpenModalDetailFlow(false)}
					flow_id={protocol.flow.id}
					title={""}
					description={`${protocol.flow.name} - Versão: ${protocol.flow.used_version}`}
				/>
			)}
			{isOpenModalJustificationProtocol && (
				<ModalJustificationProtocol
					open={isOpenModalJustificationProtocol}
					handleClose={() => setIsOpenModalJustificationProtocol(false)}
					type={type}
					code={protocol.code}
					refetch={refetch}
					situation={protocol.situation}
				/>
			)}
			{noHasRestrictionUpdateDesignateResponsible &&
				isOpenModalDesignateResponsible && (
					<ModalDesignateResponsible
						open={isOpenModalDesignateResponsible}
						handleClose={() => setIsOpenModalDesignateResponsible(false)}
						refetch={refetch}
						protocolCode={protocol.code}
					/>
				)}
			{isOpenModalUndo && (
				<UndoDialog
					open={isOpenModalUndo}
					handleClose={() => setIsOpenModalUndo(null)}
					data={taskToUndo}
					refetch={refetch}
					onDelete={() => {
						enqueueSnackbar("tarefa desfeita com sucesso!", {
							variant: "success",
						});
						setIsOpenModalUndo(false);
					}}
				/>
			)}
			{noHasRestrictionUpdateFlow && isOpenModalUpdateVersion && (
				<ModalUpdateVersion
					open={isOpenModalUpdateVersion}
					handleClose={() => setIsOpenModalUpdateVersion(false)}
					data={updateVersion}
					onEdit={() => {
						enqueueSnackbar("Versão do fluxo atualizado com sucesso!", {
							variant: "success",
						});
						refetch();
						setIsOpenModalUpdateVersion(false);
					}}
				/>
			)}
			{isOpenModalEditAddress && (
				<ModalEditAddress
					isOpen={isOpenModalEditAddress}
					onClose={() => setIsOpenModalEditAddress(false)}
					code={protocol.code}
					protocol={protocol}
					refetch={refetch}
				/>
			)}
			{isOpenModalJustification && (
				<ModalJustificationProtocol
					open={isOpenModalJustification}
					handleClose={() => setIsOpenModalJustification(false)}
					type={"justification"}
					code={protocol.code}
					refetch={refetch}
					situation={protocol.situation}
					data={JSON.parse(protocol.address_extras)}
				/>
			)}
		</>
	);
}
