import { Stack, Box, Link, Typography } from "@mui/material";
import Button from "components/Button";
import IconButton from "components/IconButton";
import { LinearProgressWithLabel } from "components/LinearProgress";
import { format } from "date-fns";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useState, useRef } from "react";
import { Document, Recipient } from "types/graphql/queries/fetchDocuments";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { DELETE_DOCUMENT } from "graphql/mutations/deleteDocument";
import { ModalSignContract } from "components/DocumentManager/ModalSignContract";
import { ModalConfirmationDelection } from "components/Modal/ModalConfirmationDelection";
import SyncIcon from "@mui/icons-material/Sync";
import AutoDeleteOutlinedIcon from "@mui/icons-material/AutoDeleteOutlined";
import { RESTORE_DOCUMENT } from "graphql/mutations/restoreDocument";
import { CircularProgress } from "components/CircularProgress";

interface DocumentoOfListProps {
	refetch: () => void;
	refetchCounter?: () => void;
	document: Document;
	findRecipient: Recipient;
	senderHasNotSigned: boolean;
	allRecipientsHasSigned: boolean;
	isSender: boolean;
	someRecipientsHasNotSigned: boolean;
	recipients: string;
	percentageRecipientsHasSigned: number;
	numberOfRecipientsHasSigned: number;
	documentToSign?: Document | null;
	setDocumentToSign?: (document: Document | null) => void;
	isDump?: boolean;
}

export function DocumentOfList({
	refetch,
	refetchCounter,
	document,
	findRecipient,
	senderHasNotSigned,
	allRecipientsHasSigned,
	isSender,
	someRecipientsHasNotSigned,
	recipients,
	percentageRecipientsHasSigned,
	numberOfRecipientsHasSigned,
	documentToSign,
	setDocumentToSign,
	isDump,
}: DocumentoOfListProps) {
	const [isOpenModalConfirmationDelete, setIsOpenModalConfirmationDelete] =
		useState(false);
	const [isOpenModalSignDocument, setIsOpenModalSignDocument] = useState(false);

	const [restoreDocument, { loading: loadingRestore }] =
		useMutation(RESTORE_DOCUMENT);

	const [deleteDocument, { loading: loadignDelete }] =
		useMutation(DELETE_DOCUMENT);

	const idDocument = useRef(null);

	const { enqueueSnackbar } = useSnackbar();

	const handleOpenModalSignDocument = (document: Document) => {
		setDocumentToSign(document);
		setIsOpenModalSignDocument(true);
	};

	const handleDeleteDocument = async () => {
		try {
			await deleteDocument({
				variables: {
					id: idDocument.current,
				},
			});
			enqueueSnackbar("Documento excluído com sucesso", {
				variant: "success",
			});
			setIsOpenModalConfirmationDelete(false);
			refetch();
			refetchCounter && refetchCounter();
		} catch (error) {
			enqueueSnackbar(error.message, {
				variant: "error",
			});
		}
	};

	const handleRestoreDocument = async (id: number) => {
		try {
			await restoreDocument({
				variables: {
					id: id,
				},
			});
			enqueueSnackbar("Documento restaurado com sucesso", {
				variant: "success",
			});
			refetch();
		} catch (error) {
			enqueueSnackbar(error.message, {
				variant: "error",
			});
		}
	};

	const handleCloseModalSignDocument = () => {
		setDocumentToSign(null);
		setIsOpenModalSignDocument(false);
	};

	const handleOpenModalConfirmationDelete = (id: number) => {
		setIsOpenModalConfirmationDelete(true);
		idDocument.current = id;
	};

	return (
		<>
			<Stack
				direction="row"
				mt="10px"
				alignItems={"center"}
				sx={{
					backgroundColor: "#fff",
				}}
				spacing={2}
				padding="10px"
			>
				<Box flex={2} display="flex" flexDirection="column">
					<Link
						fontWeight={700}
						href={isDump ? document.url : undefined}
						target="_blank"
						rel="noreferrer"
						sx={{
							cursor: "pointer",
						}}
						onClick={() =>
							!isDump ? handleOpenModalSignDocument(document) : undefined
						}
					>
						{document.name}
					</Link>
					<Typography variant="caption">Destinatários: {recipients}</Typography>
				</Box>
				{!isDump && (
					<Box flex={1}>
						{findRecipient && senderHasNotSigned && (
							<>
								<Typography variant="subtitle2">Ação necessária</Typography>
								<Box>
									<Button
										variant="contained"
										onClick={() => handleOpenModalSignDocument(document)}
									>
										Assinar
									</Button>
								</Box>
							</>
						)}
						{allRecipientsHasSigned && (
							<Stack direction={"row"} spacing={1} alignItems="center">
								<CheckCircleIcon color="success" />
								<Typography variant="subtitle2">Assinado</Typography>
							</Stack>
						)}
						{someRecipientsHasNotSigned && !senderHasNotSigned && (
							<Stack spacing={1} alignItems="center">
								<Typography variant="subtitle2">Aguardando outros</Typography>
								<Box sx={{ width: "100%" }}>
									<LinearProgressWithLabel
										value={percentageRecipientsHasSigned}
										showOnlyValue
									/>
								</Box>
								<Typography variant="caption" width="100%" textAlign={"left"}>
									{numberOfRecipientsHasSigned} / {document.recipients.length}
								</Typography>
							</Stack>
						)}
					</Box>
				)}
				{isDump && (
					<Box flex={1}>
						<Stack direction={"row"} spacing={1} alignItems="center">
							<AutoDeleteOutlinedIcon
								sx={{
									color: "#FFA621",
								}}
							/>
							<Typography variant="subtitle2">
								{30 - document.expire_days} dias para ser excluido
							</Typography>
						</Stack>
					</Box>
				)}
				{!isDump && (
					<Box flex={1}>
						<Typography variant="caption">
							{format(new Date(document.updated_at), "dd/MM/yyyy HH:mm:ss")}
						</Typography>
					</Box>
				)}
				{isSender && !isDump && (
					<Box flex={0.2}>
						<IconButton
							onClick={() => handleOpenModalConfirmationDelete(document.id)}
						>
							<DeleteOutlineIcon />
						</IconButton>
					</Box>
				)}
				{isSender && isDump && (
					<Box flex={0.2}>
						{loadingRestore ? (
							<CircularProgress />
						) : (
							<IconButton onClick={() => handleRestoreDocument(document.id)}>
								<SyncIcon />
							</IconButton>
						)}
					</Box>
				)}
			</Stack>
			<ModalConfirmationDelection
				open={isOpenModalConfirmationDelete}
				handleClose={() => setIsOpenModalConfirmationDelete(false)}
				message="O documento será excluído e enviado para a lixeira, podendo ser restaurado em 30 dias. Deseja continuar?"
				handleSubmit={handleDeleteDocument}
				buttonSubmitText="Confirmar"
				centerButtons
				isLoading={loadignDelete}
			/>
			{documentToSign && (
				<ModalSignContract
					open={isOpenModalSignDocument}
					handleClose={() => handleCloseModalSignDocument()}
					document_id={documentToSign.id}
					refetch={() => {
						refetch();
						refetchCounter && refetchCounter();
					}}
				/>
			)}
		</>
	);
}
