import { ModalLayout } from "components/ModalLayout";
import { FetchPerfis } from "types/graphql/queries/fetchPerfis";
import { ModalLayoutProps } from "types/ModalLayout";
import { FormProvider, useForm, Controller } from "react-hook-form";
import Input from "components/Input";
import { Box, Stack, Typography } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { FETCH_RESTRICTION } from "graphql/queries/fetchRestriction";
import {
	FetchRestrictions,
	Restriction,
} from "types/graphql/queries/fetchRestrictions";
import { CircularProgress } from "components/CircularProgress";
import { alpha, styled } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import { useEffect, useMemo } from "react";
import { FETCH_PERFIS } from "graphql/queries/fetchPerfis";
import { CREATE_PERFIL } from "graphql/mutations/createPerfil";
import { Select } from "components/Select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const GreenSwitch = styled(Switch)(({ theme }) => ({
	"& .MuiSwitch-switchBase.Mui-checked": {
		color: green[600],
		"&:hover": {
			backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
		},
	},
	"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
		backgroundColor: green[600],
	},
}));

const schema = yup.object().shape({
	name: yup.string().required("Nome é obrigatório"),
});

type ModalCreatePerfilProps = ModalLayoutProps & {
	refetch: () => void;
};

export function ModalCreatePerfil({
	open,
	handleClose,
	refetch,
}: ModalCreatePerfilProps) {
	const { enqueueSnackbar } = useSnackbar();

	const { data, loading } = useQuery<FetchRestrictions>(FETCH_RESTRICTION);
	const { data: dataPerfis, loading: loadingPerfis } = useQuery<FetchPerfis>(
		FETCH_PERFIS,
		{
			variables: {
				limit: 1000,
				order_by: "name",
				direction: "asc",
			},
		}
	);

	const [createPerfil, { loading: loadingCreatePerfil }] =
		useMutation(CREATE_PERFIL);
	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			name: "",
			perfil_id: "",
			restrictions: [],
			groupByRestrictionsTitle: [],
		},
	});
	const { handleSubmit, control, setValue, getValues, reset } = methods;

	const onSubmit = async (data) => {
		try {
			const dataFormated = {
				perfil_id: data.perfil_id,
				name: data.name,
				restriction_ids: Object.entries(data.restrictions)
					.filter(([key, value]) => {
						return value === true;
					})
					.map(([key, value]) => {
						return parseInt(key);
					}),
			};
			await createPerfil({
				variables: dataFormated,
			});
			enqueueSnackbar("Perfil criado com sucesso", {
				variant: "success",
			});
			handleClose();
			refetch();
			reset({
				name: "",
				perfil_id: "",
				restrictions: [],
				groupByRestrictionsTitle: [],
			});
		} catch (err) {
			enqueueSnackbar(err.message, {
				variant: "error",
			});
		}
	};

	const groupByRestrictions = useMemo(() => {
		if (data) {
			return data.fetchRestrictions.reduce((acc, item) => {
				const key = item.code.split("_")[0];
				if (!acc[key]) {
					acc[key] = [];
				}
				acc[key].push(item);
				return acc;
			}, {});
		}
	}, [data]);

	const groupByRestrictionsTitle =
		groupByRestrictions &&
		Object.entries(groupByRestrictions).map(([key, value]) => {
			return {
				title: key.slice(0, 1).toUpperCase() + key.slice(1).toLowerCase(),
				restrictions: value as FetchRestrictions["fetchRestrictions"],
			};
		});

	useEffect(() => {
		const restrictionValues = getValues("restrictions");
		if (groupByRestrictionsTitle && restrictionValues) {
			groupByRestrictionsTitle.forEach((item, index) => {
				const idRestrictions = item.restrictions.map((item) => item.id);

				const allValuesChecked = idRestrictions.every(
					(id) => restrictionValues[id] === true
				);
				if (allValuesChecked) {
					setValue(`groupByRestrictionsTitle.${index}`, true);
				}
			});
		}
	}, [groupByRestrictionsTitle]);

	const handleCheckedAllValuesFromGroup = (value: boolean, index: number) => {
		const findIndexGroup = groupByRestrictionsTitle[index];

		findIndexGroup.restrictions.forEach((restriction) => {
			setValue(`restrictions.${restriction.id}`, value);
		});
		setValue(`groupByRestrictionsTitle.${index}`, value);
	};

	const isLoading = loading || loadingPerfis;

	return (
		<>
			<FormProvider {...methods}>
				<ModalLayout
					open={open}
					handleClose={handleClose}
					title="Criar perfil"
					handleSubmit={handleSubmit(onSubmit)}
					buttonTextClose="Cancelar"
					buttonText="Criar perfil"
					isLoading={loadingCreatePerfil}
				>
					<Stack marginTop={2} spacing={2}>
						<Input name="name" placeholder="Nome*" />
						{dataPerfis && dataPerfis?.fetchPerfis.length > 0 && (
							<Select
								name="perfil_id"
								marginSelect={0}
								label={"Perfil pai"}
								options={dataPerfis.fetchPerfis.map((item) => ({
									label: item.name,
									value: item.id,
								}))}
							/>
						)}

						{isLoading && <CircularProgress />}
						<Stack spacing={2} marginTop={2}>
							<Typography variant="h6">Restrições</Typography>
							{groupByRestrictionsTitle &&
								groupByRestrictionsTitle.map((item, indexGroup) => (
									<Stack spacing={1} key={item.title}>
										<Box
											display="flex"
											justifyContent="space-between"
											alignItems="center"
										>
											<Controller
												control={control}
												name={`groupByRestrictionsTitle.${indexGroup}`}
												shouldUnregister={true}
												render={(inputProps) => (
													<>
														<Typography variant="h6" fontWeight={400}>
															{item.title}
														</Typography>
														<GreenSwitch
															checked={inputProps.field.value}
															value={inputProps.field.value}
															onChange={(event) => {
																inputProps.field.onChange(event.target.checked);
																handleCheckedAllValuesFromGroup(
																	event.target.checked,
																	indexGroup
																);
															}}
														/>
													</>
												)}
											/>
										</Box>
										{item.restrictions.map((restriction: Restriction) => (
											<Controller
												control={control}
												name={`restrictions.${restriction.id}`}
												shouldUnregister={true}
												key={restriction.id}
												render={(inputProps) => (
													<Box
														key={restriction.id}
														display="flex"
														alignItems="center"
														justifyContent="space-between"
														borderBottom="1px solid #E5E5E5"
													>
														<Typography variant="subtitle1" fontWeight={400}>
															{restriction.name}
														</Typography>
														<GreenSwitch
															onChange={(event) =>
																inputProps.field.onChange(event.target.checked)
															}
															value={inputProps.field.value}
															checked={inputProps.field.value}
															size="small"
														/>
													</Box>
												)}
											/>
										))}
									</Stack>
								))}
						</Stack>
					</Stack>
				</ModalLayout>
			</FormProvider>
		</>
	);
}
