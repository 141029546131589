import { gql } from "@apollo/client";

export const SUSPEND_PROTOCOL = gql`
	mutation (
		$code: String!
		$justification: String!
		$files: [SuspendProtocolFile]
		$filesBase64: [String]
		) {
		suspendProtocol(
			code: $code
			justification: $justification 
			files: $files
			filesBase64: $filesBase64
		)
	}
`;
