import {
	Grid,
	Typography,
	FormControl,
	InputLabel,
	OutlinedInput,
} from "@mui/material";
import { useFormContext } from "react-hook-form";

interface FieldReceiverProps {
	name: string;
	index?: number;
	readonly?: boolean;
}

function FieldReceiver({ name, index, readonly }: FieldReceiverProps) {
	const { register } = useFormContext();
	return (
		<>
			<Grid item xs={12}>
				<Typography variant="h6" fontSize="1rem" marginBottom={1} marginTop={2}>
					Recebedor
				</Typography>
				<FormControl variant="outlined" fullWidth>
					<InputLabel htmlFor="name">Nome completo</InputLabel>
					<OutlinedInput
						readOnly={readonly}
						id="name"
						type={"text"}
						label="Nome completo"
						fullWidth
						{...register(`groups.${index}.fields.receiver.name`)}
					/>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<FormControl variant="outlined" fullWidth>
					<InputLabel htmlFor="document">Identidade ou CPF</InputLabel>
					<OutlinedInput
						readOnly={readonly}
						id="document"
						type={"text"}
						label="Identidade ou CPF"
						fullWidth
						{...register(`groups.${index}.fields.receiver.document`)}
					/>
				</FormControl>
			</Grid>
		</>
	);
}

export default FieldReceiver;
