import { CSVDownload } from "react-csv";

interface CSVDownloaderProps {
	rowsCsv: string[][];
	headersCsv: string[];
}

export function CSVDownloader({ rowsCsv, headersCsv }: CSVDownloaderProps) {
	return (
		<CSVDownload
			data={rowsCsv}
			headers={headersCsv}
			target="_blank"
			separator=";"
		/>
	);
}
