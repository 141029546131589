import { Header } from "components/Header";
import { useCallback, useState } from "react";
import { Box, Container, Grid, Stack } from "@mui/material";
import ComponentsDynamicForm from "../../components/DynamicForm/ComponentsDynamicForm";
import { Title } from "components/Title";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { Item } from "components/Paper";
import GroupsDynamicForm from "../../components/DynamicForm/GroupsDynamicForm";
import { SaveFabButton } from "components/SaveFabButton";
import { VisualizationFabButton } from "components/VisualizationFabButton";
import Button from "components/Button";
import { v4 as uuidv4 } from "uuid";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import componentsDinamycForm from "utils/fieldsDynamicForm";
import { useMutation } from "@apollo/client";
import { CREATE_DYNAMIC_FORM } from "graphql/mutations/createDynamicForm";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputFormName from "./InputFormName";
import { useNavigate } from "react-router-dom";
import { ModalVisualization } from "components/DynamicForm/Modal/VisualizationForm";
import { removeSpecialCharacters } from "utils/removeSpecialCharacters";

export type Component = {
	id: string;
	name: string;
	type: string;
	isEditable?: boolean;
	isRequired?: boolean;
	isOption?: boolean;
	component: React.ComponentType<any>;
	options?: Array<{
		id: string;
		label: string;
		value: string;
	}>;
};

type DataFormated = {
	name: string;
	groups: Array<{
		title: string;
		inputs: Array<{
			name: string;
			type: string;
			label: string;
			valor: number;
			required: boolean;
			placeholder: string;
			options: Array<{
				id: string;
				name: string;
			}>;
		}>;
	}>;
};

const schema = yup.object().shape({
	formName: yup.string().required("Nome do formulário é obrigatório"),
});

export function DynamicForm() {
	const [isOpenModalVisualization, setIsOpenModalVisualization] =
		useState(false);
	const [createDynamicForm, { loading }] = useMutation(CREATE_DYNAMIC_FORM);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const methods = useForm({
		defaultValues: {
			groups: [
				{
					id: uuidv4(),
					name: "Grupo 1",
					components: [] as any,
				},
			],
			formName: "",
		},
		resolver: yupResolver(schema),
	});

	const { handleSubmit, control, setValue, getValues } = methods;
	const { fields, append, remove, update } = useFieldArray({
		control,
		name: "groups",
	});

	const formatedNameComponent = (name: string) => {
		const nameSplited = name.split(" ");
		if (nameSplited.length > 1) {
			return (
				removeSpecialCharacters(nameSplited[0]).toLowerCase() +
				"." +
				removeSpecialCharacters(nameSplited[1]).toLowerCase()
			);
		} else {
			return name.replace(/\s/g, ".").toLowerCase();
		}
	};

	async function onSubmit(data: any) {
		const dataFormated: DataFormated = {
			name: data.formName,
			groups: data.groups.map((group) => ({
				title: group.name,
				inputs: group.components.map((component, index: number) => ({
					name: formatedNameComponent(component.name) + index,
					type: component.type,
					label: component.name,
					placeholder: component.name,
					required: component.isRequired,
					options:
						(component.options &&
							component.options.map((option) => ({
								id: option.label ?? option.id,
								name: option.label,
							}))) ||
						null,
				})),
			})),
		};
		try {
			await createDynamicForm({
				variables: dataFormated,
			});
			enqueueSnackbar("Formulário registrado com sucesso", {
				variant: "success",
			});
			navigate(-1);
		} catch (err) {
			enqueueSnackbar(err.message, {
				variant: "error",
			});
		}
	}

	function handleAddGroup() {
		append({
			id: uuidv4(),
			name: `Grupo ${fields.length + 1}`,
			components: [],
		});
	}

	const handleRemoveGroup = useCallback((index: number) => {
		remove(index);
	}, []);

	function onRequestOpenModalVisualization() {
		setIsOpenModalVisualization(true);
	}

	function onRequestCloseModalVisualization() {
		setIsOpenModalVisualization(false);
	}

	const onDragEnd = (result: DropResult) => {
		const { destination, source } = result;

		const findIndexGroup = fields.findIndex((field) => {
			return field.id === destination.droppableId;
		});

		const findComponent = componentsDinamycForm.find((component) => {
			return component.label === source.droppableId;
		});

		// If user tries to drop in an unknown destination
		if (!destination) return;

		// if the user drags and drops back in the same position
		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		) {
			return;
		}
		const values = getValues("groups");

		const concatComponents = fields.map((field) => {
			if (field.id === destination.droppableId) {
				return {
					...field,
					name: values[findIndexGroup].name,
					components: [
						...field.components,
						{
							id: uuidv4(),
							name: findComponent?.label,
							type: findComponent?.type,
							component: findComponent?.component,
							isEditable: findComponent?.isEditable,
							isRequired: findComponent?.isRequired,
							isOption: findComponent?.isOption,
							options: findComponent?.options,
						},
					],
				};
			}
			return field;
		});

		setValue("groups", concatComponents);
	};

	const handleRemoveComponent = useCallback(
		(id: string) => {
			const values = getValues("groups");

			const newComponents = values.map((value) => {
				return {
					...value,
					components: value.components.filter((component) => {
						return component.id !== id;
					}),
				};
			});
			setValue("groups", newComponents);
		},
		[fields]
	);

	const handleEditComponent = useCallback(
		(
			indexGroup: number,
			indexComponent: number,
			componentEdited: Component
		) => {
			const findIndexField = fields.findIndex(
				(field) => field.id === fields[indexGroup].id
			);
			const findIndexComponent = fields[indexGroup].components.findIndex(
				(component) =>
					component.id === fields[indexGroup].components[indexComponent].id
			);
			const values = getValues("groups");

			update(findIndexField, {
				...fields[findIndexField],
				name: values[findIndexField].name,
				components: fields[indexGroup].components.map((component, index) => {
					if (index === findIndexComponent) {
						return componentEdited;
					}
					return component;
				}),
			});
		},
		[fields]
	);

	return (
		<FormProvider {...methods}>
			<Box
				width="100%"
				display="flex"
				alignSelf="stretch"
				flexDirection="column"
				sx={{
					backgroundColor: "#f3f3f3",
				}}
			>
				<Header title="Formulário" isReturn />

				<Container
					sx={{ display: "flex", flexDirection: "column" }}
					maxWidth="lg"
				>
					<Box sx={{ flexGrow: 1 }} marginY="1rem">
						<DragDropContext onDragEnd={onDragEnd}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={4} rowGap={2}>
									<Item>
										<Stack spacing={4}>
											<Box>
												<Title
													title="Dados do formulário"
													marginBottom={2}
													fontSize="1rem"
												/>
												<InputFormName />
											</Box>
											<ComponentsDynamicForm />
										</Stack>
									</Item>
								</Grid>

								<Grid item xs={12} md={8}>
									<Stack spacing={2}>
										{fields.map((field, index) => (
											<Item key={field.id}>
												<GroupsDynamicForm
													index={index}
													handleRemoveGroup={(index) =>
														handleRemoveGroup(index)
													}
													group={field}
													handleRemoveComponent={(id) =>
														handleRemoveComponent(id)
													}
													handleEditComponent={(
														indexGroup,
														indexComponent,
														component
													) =>
														handleEditComponent(
															indexGroup,
															indexComponent,
															component
														)
													}
												/>
											</Item>
										))}
										<Box alignSelf="flex-end">
											<Button
												variant="contained"
												onClick={() => handleAddGroup()}
												type="button"
											>
												Adicionar grupo
											</Button>
										</Box>
									</Stack>
								</Grid>
							</Grid>
						</DragDropContext>
					</Box>
					<VisualizationFabButton
						onClick={() => onRequestOpenModalVisualization()}
					/>
					<SaveFabButton onClick={handleSubmit(onSubmit)} isLoading={loading} />
				</Container>
			</Box>
			<ModalVisualization
				open={isOpenModalVisualization}
				handleClose={() => onRequestCloseModalVisualization()}
				buttonText="Fechar"
			/>
		</FormProvider>
	);
}
