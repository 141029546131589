import { gql } from "@apollo/client";

export const DETAIL_PUBLIC_DOCUMENT = gql`
	query ($document_id: Int!) {
		detailPublicDocument(document_id: $document_id) {
			id
			name
			sender {
				id
				name
				email
				params {
					matricula
				}
			}
			recipients {
				id
				name
				email
				params {
					matricula
				}
			}
			created_at
			updated_at
		}
	}
`;
