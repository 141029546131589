import { useMutation } from "@apollo/client";
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
} from "@mui/material";
import { UPDATE_PROTOCOL_FLOW } from "graphql/mutations/updateProtocolFlow";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

type ModalUpdateVersionProps = {
	open: boolean;
	handleClose: () => void;
	data: any;
	onEdit: () => void;
};

export default function ModalUpdateVersion({
	open,
	handleClose,
	data,
	onEdit,
}: ModalUpdateVersionProps) {
	const { enqueueSnackbar } = useSnackbar();

	const { code } = useParams<{ code: string }>();

	// Setting mutations
	const [UpdateProtocolFlow, { loading }] = useMutation(UPDATE_PROTOCOL_FLOW);

	const onSubmit = (async) => {
		try {
			UpdateProtocolFlow({
				variables: {
					code: code,
				},
			});
			onEdit();
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="paper"
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogTitle id="scroll-dialog-title">
					Atualizar versão do fluxo
				</DialogTitle>

				<DialogContent>
					<p>Deseja realmente atualizar a versão do fluxo?</p>
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancelar
					</Button>
					<Button
						onClick={onSubmit}
						variant="contained"
						color="primary"
						disabled={loading}
					>
						{loading && (
							<CircularProgress size={14} style={{ marginRight: 12 }} />
						)}
						Atualizar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
