import * as React from "react";
import { LinearProgressProps, LinearProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

type LinearProgressWithLabelProps = LinearProgressProps & {
	value: number;
	total?: number;
	current?: number;
	showOnlyValue?: boolean;
};

export function LinearProgressWithLabel(props: LinearProgressWithLabelProps) {
	return (
		<Box sx={{ display: "flex", alignItems: "center" }}>
			<Box sx={{ width: "100%", mr: 1 }}>
				{!props.showOnlyValue && (
					<Typography variant="body2" color="text.secondary">{`${Math.round(
						props.current
					)} de ${props.total}`}</Typography>
				)}
				<LinearProgress variant="determinate" {...props} />
			</Box>
		</Box>
	);
}
