import { Box, Link, Grid, Stack, Typography } from "@mui/material";
import { Item } from "components/Paper";
import { useAuthContext } from "context/AuthProvider";
import { useVerifyNoHasRestriction } from "utils/useVerifyNoHasRestriction";

export default function SettingsPlus() {
	const { state } = useAuthContext();

	const noHasRestricitonFlow = useVerifyNoHasRestriction("FLUXO");
	const noHasRestricitonUser = useVerifyNoHasRestriction("USUARIO");
	const noHasRestricitonStatus = useVerifyNoHasRestriction("STATUS");
	const noHasRestricitonProfile = useVerifyNoHasRestriction("PERFIL");
	const noHasRestricitonRegulatoryActs =
		useVerifyNoHasRestriction("ATO_REGULATORIO");
	const noHasRestricitonTaxGroups = useVerifyNoHasRestriction("GRUPO_FISCAIS");
	const noHasRestricitonOccurrenceTypes =
		useVerifyNoHasRestriction("TIPO_OCORRENCIA");

	return (
		<Item
			sx={{
				paddingX: 0,
				maxHeight: "450px",
				marginBottom: "20px",
				position: "relative",
				background: "transparent",
				boxShadow: "none",
			}}
		>
			<Box
				style={{ marginTop: "25px", marginLeft: "60px" }}
				display="flex"
				flexDirection="column"
				alignItems="center"
			>
				<Grid container spacing={4} wrap="wrap">
					{noHasRestricitonTaxGroups && (
						<Grid item xs={12} sm={4}>
							<Stack>
								<Typography sx={{ fontSize: 13 }}>Grupo de fiscais</Typography>
								<Link
									sx={{ fontSize: 15 }}
									href="configuracoes/grupo-de-fiscais"
									underline="hover"
								>
									Configurar grupo de fiscais
								</Link>
							</Stack>
						</Grid>
					)}
					{noHasRestricitonProfile && (
						<Grid item xs={12} sm={4}>
							<Stack>
								<Typography sx={{ fontSize: 13 }}>Perfil</Typography>
								<Link
									sx={{ fontSize: 15 }}
									href="configuracoes/perfil"
									underline="hover"
								>
									Configurar perfis
								</Link>
							</Stack>
						</Grid>
					)}
					{noHasRestricitonUser && (
						<Grid item xs={12} sm={4}>
							<Stack>
								<Typography sx={{ fontSize: 13 }}>Usuários</Typography>
								<Link
									sx={{ fontSize: 15 }}
									href="configuracoes/usuarios"
									underline="hover"
								>
									Configurar usuários
								</Link>
							</Stack>
						</Grid>
					)}
					{noHasRestricitonStatus && (
						<Grid item xs={12} sm={4}>
							<Stack>
								<Typography sx={{ fontSize: 13 }}>
									Status das tarefas
								</Typography>
								<Link
									sx={{ fontSize: 15 }}
									href="configuracoes/status-da-tarefa"
									underline="hover"
								>
									Configurar status
								</Link>
							</Stack>
						</Grid>
					)}
					<Grid item xs={12} sm={4}>
						<Stack>
							<Typography sx={{ fontSize: 12 }}>
								Formulários dinâmicos
							</Typography>
							<Link href="/configuracoes/formulario" underline="hover">
								Configurar formulários
							</Link>
						</Stack>
					</Grid>
					{noHasRestricitonOccurrenceTypes && (
						<Grid item xs={12} sm={4}>
							<Stack>
								<Typography sx={{ fontSize: 13 }}>
									Tipo de ocorrência
								</Typography>
								<Link
									sx={{ fontSize: 15 }}
									href="configuracoes/tipo-de-ocorrencia"
									underline="hover"
								>
									Configurar tipo de ocorrência
								</Link>
							</Stack>
						</Grid>
					)}
					{noHasRestricitonFlow && (
						<Grid item xs={12} sm={4}>
							<Stack>
								<Typography sx={{ fontSize: 13 }}>Fluxos</Typography>
								<Link
									sx={{ fontSize: 15 }}
									href="configuracoes/fluxos"
									underline="hover"
								>
									Configurar fluxos
								</Link>
							</Stack>
						</Grid>
					)}
					{noHasRestricitonRegulatoryActs && (
						<Grid item xs={12} sm={4}>
							<Stack>
								<Typography sx={{ fontSize: 13 }}>Atos regulatórios</Typography>
								<Link
									sx={{ fontSize: 15 }}
									href="configuracoes/atos-regulatorios"
									underline="hover"
								>
									Configurar atos regulatórios
								</Link>
							</Stack>
						</Grid>
					)}
					<Grid item xs={12} sm={4}>
						<Stack>
							<Typography sx={{ fontSize: 13 }}>Assuntos</Typography>
							<Link
								sx={{ fontSize: 15 }}
								href="configuracoes/assuntos"
								underline="hover"
							>
								Configurar assuntos
							</Link>
						</Stack>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Stack>
							<Typography sx={{ fontSize: 13 }}>Dispositivos Mobile</Typography>
							<Link
								sx={{ fontSize: 15 }}
								href="configuracoes/gerenciar-dispositivos"
								underline="hover"
							>
								Gerenciar acessos aplicativo
							</Link>
						</Stack>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Stack>
							<Typography sx={{ fontSize: 13 }}>Feriados</Typography>
							<Link
								sx={{ fontSize: 15 }}
								href="configuracoes/feriados"
								underline="hover"
							>
								Configurar feriados
							</Link>
						</Stack>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Stack>
							<Typography sx={{ fontSize: 13 }}>Bairros</Typography>
							<Link
								sx={{ fontSize: 15 }}
								href="configuracoes/bairros"
								underline="hover"
							>
								Configurar bairros
							</Link>
						</Stack>
					</Grid>
				</Grid>
			</Box>
		</Item>
	);
}
