import { Box, Stack, Typography, Link, Button } from "@mui/material";
import { ModalLayout } from "components/ModalLayout";
import { ModalLayoutProps } from "types/ModalLayout";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { PrintButton } from "../PrintButton";
import { Task } from "types/graphql/queries/detailProtocol";
import { useState } from "react";
import ModalDetailDescription from "./ModalDetailDescription";
import { useForm, FormProvider } from "react-hook-form";

type ModalDetailAssignmentProps = ModalLayoutProps & {
	refetch: () => void;
	task: Task;
};

export function ModalDetailAssignment({
	open,
	handleClose,
	task,
	refetch,
}: ModalDetailAssignmentProps) {
	const methods = useForm();
	const [files, setFiles] = useState([]);
	const [isOpenModalDescription, setIsOpenModalDescription] = useState(false);

	const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const dataFiles = Array.from(e.target.files);
		setFiles(dataFiles);
		setIsOpenModalDescription((state) => !state);
	};

	const handleCloseModal = () => {
		setIsOpenModalDescription((state) => !state);
		handleClose();
	};

	return (
		<FormProvider {...methods}>
			<ModalLayout
				open={open}
				handleClose={handleClose}
				isNotShowButtonSubmit
				variantButtonClose="contained"
				title="Detalhes"
				isShowAdditionalButton
				componentAdditionalButton={
					<PrintButton type="task" code={task.id} task={task} />
				}
				additionalButtonIsComponent
			>
				<Stack spacing={2} flex={1}>
					<Box
						display="flex"
						justifyContent="space-between"
						columnGap={2}
						flexWrap="wrap"
					>
						<Box display="flex" flex={1} columnGap={10} flexWrap="wrap">
							<Box>
								<Typography variant="caption">Tipo</Typography>
								<Typography variant="subtitle2">{task.type}</Typography>
							</Box>
							<Box>
								<Typography variant="caption">Nome da tarefa</Typography>
								<Typography variant="subtitle2">{task.name}</Typography>
							</Box>
							<Box flex={1}>
								<Typography variant="caption">
									Designado para o perfil
								</Typography>
								<Typography variant="subtitle2">
									{task.perfil ? task.perfil.name : " - "}
								</Typography>
							</Box>
						</Box>
						<Box>
							<Typography variant="caption">Prazo</Typography>
							<Typography variant="subtitle2" textAlign="center">
								{task.expire_at || "-"}
							</Typography>
						</Box>
					</Box>
					<Box>
						<Typography variant="caption">Descrição</Typography>
						<Typography variant="subtitle2">{task.answer || "-"}</Typography>
					</Box>
					<Stack spacing={3} flex={1} marginTop={2}>
						<Box>
							{task.detail.attachments.map((item) => {
								const isImageFile =
									item.name.split(".").pop().toLowerCase() === "jpg" ||
									item.name.split(".").pop().toLowerCase() === "png" ||
									item.name.split(".").pop().toLowerCase() === "jpeg";
								return (
									<Box
										key={item.id}
										display="flex"
										columnGap={2}
									>
										<Box>
											<AttachFileIcon />
										</Box>
										<Box>
											{isImageFile ? (
												<Box display="flex" flexDirection={"column"}>
													<img
														src={item.url}
														style={{
															maxHeight: "200px",
															maxWidth: "200px",
														}}
													/>
													<Link
														variant="h6"
														fontSize="1rem"
														href={item.url}
														target="_blank"
														rel="norefferer"
													>
														{item.name}
													</Link>
												</Box>
											) : (
												<Link
													variant="h6"
													fontSize="1rem"
													href={item.url}
													target="_blank"
													rel="norefferer"
												>
													{item.name}
												</Link>
											)}
											<Box>
												<Typography variant="caption">
													{`criado em ${item.created_at}`}
												</Typography>
											</Box>
											{item.description !== "" && (
												<Box mt={1}>
													<Typography variant="body2" fontSize=".8rem">
														{item.description}
													</Typography>
												</Box>
											)}
										</Box>
									</Box>
								);
							})}
						</Box>
						<Box>
							<Button
								color="primary"
								aria-label="upload picture"
								component="label"
								endIcon={
									<UploadFileIcon
										sx={{ color: "rgba(0, 0, 0, 0.54)", height: "20px" }}
									/>
								}
								variant="outlined"
							>
								ANEXAR ARQUIVOS
								<input
									hidden
									accept="image/*, application/pdf"
									type="file"
									multiple
									name="Logo"
									id="logo"
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										handleUploadFile(e)
									}
								/>
							</Button>
						</Box>
					</Stack>
				</Stack>
			</ModalLayout>
			{isOpenModalDescription && (
				<ModalDetailDescription
					open={isOpenModalDescription}
					handleClose={handleCloseModal}
					files={files}
					refetch={refetch}
					task_id={task.id}
				/>
			)}
		</FormProvider>
	);
}
