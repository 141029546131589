import { useState } from "react";
import { Backdrop } from "@mui/material";
import { Stack } from "@mui/material";
import { Box, Typography, Button } from "@mui/material";
import { useQuery } from "@apollo/client";
import { FETCH_DETAIL_COMPANY } from "graphql/queries/fetchDetailCompany";
import { CircularProgress } from "components/CircularProgress";
import { FetchDetailCompany } from "types/graphql/queries/fetchDetailCompany";
import { ModalReport } from "./ModalReport";
import { ModalFollowUp } from "./ModalFollowUp";

export function WebReport() {
	const subdomain = window.location.hostname.split(".")[0];
	const { data, loading: loadingDetailCompany } = useQuery<FetchDetailCompany>(
		FETCH_DETAIL_COMPANY,
		{
			variables: {
				domain:
					subdomain === "localhost"
						? process.env.REACT_DEFAULT_DOMAIN || "lagoasanta"
						: subdomain,
			},
		}
	);

	const [isOpenModalReport, setIsOpenModalReport] = useState(false);
	const [isOpenModalFollowUp, setIsOpenModalFollowUp] = useState(false);

	const handleOpenModalReport = () => {
		setIsOpenModalReport(true);
	};

	const handleCloseModalReport = () => {
		setIsOpenModalReport(false);
	};

	const handleOpenModalFollowUp = () => {
		setIsOpenModalFollowUp(true);
	};

	const handleCloseModalFollowUp = () => {
		setIsOpenModalFollowUp(false);
	};

	return (
		<>
			{loadingDetailCompany && (
				<Backdrop
					sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={true}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			)}
			{data && (
				<>
					<Box display="flex" width="100%">
						<Box display={["none", "flex"]} minHeight="100vh" flex={1}>
							<Box
								sx={{
									backgroundImage: `url(${data.detailCompany.cover})`,
									backgroundSize: "cover",
									backgroundPosition: "center",

									width: "100%",
								}}
							/>
						</Box>
						<Stack
							sx={{
								display: "flex",
								flexDirection: "column",
								flex: "1",
								alignItems: "center",
								textAlign: "center",
							}}
						>
							<Stack
								padding="1rem"
								flex={1}
								spacing={2}
								justifyContent="center"
							>
								<Box
									textAlign="center"
									sx={{
										backgroundImage: `url(${data.detailCompany.logo})`,
										backgroundSize: "contain",
										backgroundPosition: "center",
										backgroundRepeat: "no-repeat",
										width: "300px",
									}}
									height="100px"
								/>
							</Stack>
							<Stack
								padding="1rem"
								sx={{
									maxWidth: "600px",
									alignItems: "center",
								}}
							>
								<Typography
									fontWeight={700}
									fontSize="1.8rem"
									marginBottom={"40px"}
								>
									WebDenúncia
								</Typography>
								<Stack
									flex={1}
									spacing={4}
									sx={{ color: "#000", letterSpacing: "0.15px" }}
								>
									<Typography variant="body1" fontSize="1.3rem">
										O web Denúncia é um serviço via internet à disposição da
										população de Lagoa Santa. Não é necessário identificar-se
										para fazer a denúncia e o sigilo das informações será
										preservada.
									</Typography>
									<Typography variant="body1" fontSize="1.3rem">
										As providências tomadas pela Prefeitura poderão ser
										acompanhadas através de uma senha.
									</Typography>
									<Typography variant="body1" fontSize="1.3rem">
										Escolha uma opção abaixo:
									</Typography>
								</Stack>

								<Button
									onClick={handleOpenModalReport}
									sx={{
										marginTop: "50px",
										width: "100%",
										maxWidth: "300px",
										height: "55px",
										fontSize: "1.1rem",
									}}
									variant="contained"
								>
									DENUNCIAR
								</Button>

								<Button
									onClick={handleOpenModalFollowUp}
									sx={{
										marginTop: "20px",
										width: "100%",
										maxWidth: "300px",
										height: "55px",
										fontSize: "1.1rem",
										border: "none",
									}}
									variant="outlined"
									color="primary"
								>
									ACOMPANHAR DENUNCIAS
								</Button>
							</Stack>
						</Stack>
					</Box>

					<ModalReport
						open={isOpenModalReport}
						handleClose={() => handleCloseModalReport()}
					/>

					<ModalFollowUp
						open={isOpenModalFollowUp}
						handleClose={() => handleCloseModalFollowUp()}
					/>
				</>
			)}
		</>
	);
}
