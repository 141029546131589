import { Item } from "components/Paper";
import { Title } from "components/Title";
import { Box, Stack, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { TOTAL_UPF } from "graphql/queries/totalUpf";
import { maskNumber } from "utils/maskOutputs";

export function CardUPFMLS() {
	const { data } = useQuery(TOTAL_UPF);
	const formatterNumber = new Intl.NumberFormat("pt-BR", {
		style: "decimal",
	});
	return (
		<Item
			sx={{
				marginBottom: 2,
			}}
		>
			<Box
				height="100%"
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
			>
				<Box>
					<Box
						display="flex"
						alignItems="center"
						justifyContent="space-between"
						marginBottom={2}
					>
						<Title title="Total de UPF" />
					</Box>
					{data && (
						<Stack direction="row" flex={1} justifyContent="space-between">
							<Box>
								<Typography variant="caption">UPFMLS</Typography>
								<Typography variant="h5">
									{maskNumber(data.totalUpf ? data.totalUpf.amount : 0)}
								</Typography>
							</Box>
							<Box>
								<Typography variant="caption">Protocolos</Typography>
								<Typography variant="h5">
									{data.totalUpf
										? formatterNumber.format(data.totalUpf.total)
										: 0}
								</Typography>
							</Box>
						</Stack>
					)}
				</Box>
			</Box>
		</Item>
	);
}
