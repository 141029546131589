import { Item } from "components/Paper";
import { Title } from "components/Title";
import { Box, Typography, Link } from "@mui/material";
import Button from "components/Button";
import { EmptyMessage } from "components/EmptyMessage";
import noData from "assets/img/no_data.png";
import { useQuery } from "@apollo/client";
import { FETCH_LATE_PROTOCOLS } from "graphql/queries/fetchLateProtocols";
import { FetchLateProtocols } from "types/graphql/queries/fetchLateProtocols";
import { CircularProgress } from "components/CircularProgress";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { COUNT_PROTOCOL_SITUATIONS } from "graphql/queries/countProtocolsSituation";
import { CountProtocolSituations } from "types/graphql/queries/countProtocolSituations";

export function CardLateProtocols() {
	const { data, loading } = useQuery<FetchLateProtocols>(FETCH_LATE_PROTOCOLS, {
		variables: {
			limit: 5,
		},
	});

	const { data: dataCount, loading: loadingCount } =
		useQuery<CountProtocolSituations>(COUNT_PROTOCOL_SITUATIONS);

	const navigate = useNavigate();

	const isLoading = loading || loadingCount;

	return (
		<Item
			sx={{
				overflowY: "auto",
				maxHeight: "400px",
				"@media print": {
					display: "none",
				},
			}}
		>
			<Box
				minHeight={"355px"}
				height="100%"
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
			>
				{isLoading ? (
					<CircularProgress />
				) : (
					<Box>
						<Title
							title={`Atrasados (${data?.fetchLateProtocols.total})`}
							sx={{
								marginBottom: "1rem",
							}}
						/>
						{data && data.fetchLateProtocols.data.length > 0 ? (
							<>
								{data.fetchLateProtocols.data.map((item) => (
									<Box
										key={item.id}
										display="flex"
										justifyContent="space-between"
										borderBottom="1px solid #ccc"
										paddingBottom="0.5rem"
										columnGap="1rem"
										marginTop={2}
									>
										<Box sx={{ maxWidth: "180px" }}>
											<Box>
												<Typography variant="caption">Protocolo</Typography>
												<Link
													onClick={() => navigate(`/protocolo/${item.code}`)}
													sx={{
														cursor: "pointer",
													}}
												>
													<Typography variant="subtitle2">
														{item.code}
													</Typography>
												</Link>
												<Typography variant="caption">
													{item.subject?.name || "-"}
												</Typography>
											</Box>
											<Box marginTop={2}>
												<Typography variant="caption">Usuário</Typography>
												<Typography variant="subtitle2">
													{item.responsible ? item.responsible.name : " - "}
												</Typography>
											</Box>
										</Box>
										<Box>
											<Typography variant="caption">Data expiração</Typography>
											<Typography variant="subtitle2">
												{item.expire_at
													? format(new Date(item.expire_at), "dd/MM/yyyy")
													: " - "}
											</Typography>
											<Typography
												variant="caption"
												color="aliceblue"
												textAlign="center"
												borderRadius={2}
												padding="2px 8px"
												sx={{
													backgroundColor: "#d32f2f",
												}}
												fontSize="0.75rem"
											>
												{item.info ? item.info : " - "}
											</Typography>
										</Box>
									</Box>
								))}
							</>
						) : (
							<Box>
								<EmptyMessage message="Não foi encontrado protocolo atrasado 😊" />
								<Box
									marginTop={2}
									sx={{
										backgroundImage: `url(${noData})`,
										backgroundRepeat: "no-repeat",
										backgroundPosition: "center",
										backgroundSize: "contain",
										height: "200px",
									}}
								/>
							</Box>
						)}
					</Box>
				)}
				{data && (
					<Button
						variant="text"
						fullWidth
						sx={{
							marginTop: 2,
						}}
						onClick={() =>
							navigate(
								"/protocolos?startDate=null&finishDate=null&upfmls=null&lateDays=delayed&street=&number=&situation=%5B%22%22%2C%22%22%2C%22%22%2C%22%22%2C%22%22%2C%22%22%5D"
							)
						}
					>
						Ver todos
					</Button>
				)}
			</Box>
		</Item>
	);
}
