import React from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	InputAdornment,
	Stack,
} from "@mui/material";
import { Chips } from "components/Chips";
import { useState, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchInput from "../SeachInput";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { CircularProgress } from "components/CircularProgress";
import { EmptyMessage } from "components/EmptyMessage";

interface SelectFilterNotUseQueryProps {
	onChange: (values: Array<any>) => void;
	fullWidth: boolean;
	title: string;
	valuesSelecteds: Array<{
		id: number | string;
		name: string;
	}>;
	error?: boolean;
	selectValues: Array<{
		id: number | string;
		name: string;
	}>;
	loading: boolean;
}

export default function SelectFilterNotUseQuery({
	onChange,
	title,
	fullWidth,
	valuesSelecteds,
	selectValues,
	loading,
	error,
}: SelectFilterNotUseQueryProps) {
	const [newValue, setNewValue] = useState(valuesSelecteds);
	const [showDialog, setShowDialog] = useState(false);
	useEffect(() => {
		setNewValue(valuesSelecteds);
	}, [valuesSelecteds]);

	const values = selectValues.map((element) => ({
		id: element.id,
		name: element.name,
	})) 

	

	const [searchTerm, setSearchTerm] = useState(null);

	const handleChangeCheckbox = (
		e,
		situation: { id: number | string; name: string }
	) => {
		const value = e.target.value;
		const index = newValue.findIndex((i) => i.name === value);

		if (index >= 0 && e.target.checked === false) deleteItem(situation.id);
		else {
			setNewValue((prevState) => [...prevState, situation]);
		}
	};

	const deleteItem = (id: number | string) => {
		setNewValue((prevState) => prevState.filter((prev) => prev.id !== id));
	};

	const handleSelect = () => {
		onChange([...newValue, { title: title }]);
		setShowDialog(false);
	};

	const handleClearFilter = () => {
		setNewValue([]);
		onChange([{ title: title }]);
		setSearchTerm(null);
		setShowDialog(false);
	};

	const filteredValues =
		values &&
		values.filter((value) => {
			if (searchTerm === null) return value;
			else if (value.name.toLowerCase().includes(searchTerm.toLowerCase())) {
				return value;
			}
		});

	return (
		<>
			<TextField
				error={error}
				InputProps={{
					readOnly: true,
					endAdornment: (
						<InputAdornment position="end">
							<ArrowDropDownIcon
								style={{ color: "#787878", marginRight: -6 }}
							/>
						</InputAdornment>
					),
				}}
				InputLabelProps={{
					shrink: newValue.length > 0 ? true : false,
				}}
				onClick={() => {
					setShowDialog(true);
				}}
				variant="outlined"
				fullWidth={fullWidth}
				value={newValue.map((element) => element.name).join(", ")}
				label={title}
			/>

			<Dialog
				open={showDialog}
				onClose={() => setShowDialog(false)}
				maxWidth="md"
			>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<SearchInput
						typeSearch="inDialog"
						onChange={(value) => {
							setSearchTerm(value);
						}}
					/>

					<Stack
						spacing={2}
						flex={1}
						direction="row"
						marginTop={2}
						flexWrap="wrap"
						rowGap={2}
					>
						{newValue.map((element) => {
							return (
								<Chips
									key={element.id}
									label={element.name}
									handleDelete={() => deleteItem(element.id)}
									isDelete
									color="primary"
								/>
							);
						})}
					</Stack>

					<div
						style={{
							marginTop: 20,
							height: 300,
							overflow: "auto",
							display: "flex",
							flexDirection: "column",
						}}
					>
						{loading ? (
							<CircularProgress />
						) : (
							<>
								{selectValues && (
									<FormGroup>
										<Stack flex={1}>
											{filteredValues && filteredValues.length > 0 ? (
												filteredValues.map((situation) => (
													<FormControlLabel
														key={situation.id}
														control={
															<Checkbox
																checked={newValue.some(
																	(i) => i.id === situation.id
																)}
																value={situation.name}
																onChange={(e) => {
																	handleChangeCheckbox(e, situation);
																}}
															/>
														}
														label={situation.name}
													/>
												))
											) : (
												<EmptyMessage message="Nenhum item encontrado" />
											)}
										</Stack>
									</FormGroup>
								)}
							</>
						)}
					</div>
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClearFilter} color="primary">
						Limpar
					</Button>
					<Button
						onClick={handleSelect}
						variant="contained"
						color="primary"
						disabled={loading}
					>
						Selecionar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

