import { gql } from "@apollo/client";

export const CHANGE_PROTOCOL_SUBJECT = gql`
    mutation(
        $code: String!
        $subject_id: Int!
    ){
        changeProtocolSubject(
            code: $code
            subject_id: $subject_id
        )
    }
`