import { Grid, Typography } from "@mui/material";
import Input from "components/Input";

interface AssignmentProps {
	required: boolean;
}

export function Assignment({ required }: AssignmentProps) {
	return (
		<>
			<Grid item xs={12}>
				<Typography variant="subtitle1" textTransform="uppercase">
					descreva abaixo um parecer para concluir essa tarefa
				</Typography>
				<Input
					name="description"
					placeholder="Parecer"
					multiline
					rows={3}
					isRequired={required}
				/>
			</Grid>
		</>
	);
}
