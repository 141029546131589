import { useMutation } from "@apollo/client";
import { DELETE_DISTRICT } from "graphql/mutations/deleteDistrict";
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
} from "@mui/material";

type DeleteDialogProps = {
	open: boolean;
	handleClose: () => void;
	onDelete: () => void;
	data: any;
};

export default function DeleteDialog({
	open,
	handleClose,
	onDelete,
	data,
}: DeleteDialogProps) {
	// Setting mutation
	const [deleteDistrict, { loading: loadingDelete }] =
		useMutation(DELETE_DISTRICT);

	// Chamando a mutation de remoção
	const handleDelete = () => {
		deleteDistrict({
			variables: {
				id: data.id,
			},
		}).then(() => {
			onDelete();
		});
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="paper"
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogTitle id="scroll-dialog-title">Excluir bairro</DialogTitle>

				<DialogContent>
					<p>Deseja realmente excluir o bairro?</p>
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancelar
					</Button>
					<Button
						onClick={handleDelete}
						variant="contained"
						color="primary"
						disabled={loadingDelete}
					>
						{loadingDelete && (
							<CircularProgress size={14} style={{ marginRight: 12 }} />
						)}
						Excluir
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
