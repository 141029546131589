/* eslint-disable prefer-const */
/* eslint-disable indent */
import { useQuery } from "@apollo/client";
import { Box, Fab, Container, Stack, Typography } from "@mui/material";
import { Item } from "components/Paper";
import { Header } from "components/Header";
import AddIcon from "@mui/icons-material/Add";
import { FETCH_PERFIS } from "graphql/queries/fetchPerfis";
import { CircularProgress } from "components/CircularProgress";
import { COUNT_PERFIS } from "graphql/queries/countPerfis";
import { FetchPerfis, Profile } from "types/graphql/queries/fetchPerfis";
import { CountPerfis } from "types/graphql/queries/countPerfis";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "components/IconButton";
import { useState, useEffect, useCallback, useRef } from "react";
import { ModalEditRestrictions } from "./Modal";
import imgProfile from "assets/img/perfis.svg";
import { ModalCreatePerfil } from "./Modal/createPerfil";

export function Perfis() {
	const [groups, setGroups] = useState<
		{ perfil_id: string | number; value: FetchPerfis["fetchPerfis"] }[]
	>([]);
	const [compareGroups, setCompareGroups] = useState<
		{ perfil_id: string | number; value: FetchPerfis["fetchPerfis"] }[]
	>([]);
	const [idValueClicked, setIdValueClicked] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenModalCreatePerfil, setIsOpenModalCreatePerfil] = useState(false);
	const { data, loading, refetch } = useQuery<FetchPerfis>(FETCH_PERFIS);
	const [perfil, setPerfil] = useState<Profile>(null);
	const { data: dataCount } = useQuery<CountPerfis>(COUNT_PERFIS);

	const stackRef = useRef(null);

	const groupsBy = (groups: FetchPerfis["fetchPerfis"]) => {
		const groupsBy = groups.reduce((acc, group) => {
			const key = group.perfil_id;
			if (!acc[key]) {
				acc[key] = [];
			}
			acc[key].push(group);
			return acc;
		}, {});
		const groupedGroups = Object.keys(groupsBy).map((key) => ({
			perfil_id: key,
			value: groupsBy[key],
		}));

		setCompareGroups(groupedGroups);
		return groupedGroups.filter((item) => item.perfil_id === "null");
	};

	useEffect(() => {
		if (data) {
			const response = groupsBy(data.fetchPerfis);
			setGroups(response);
		}
	}, [data]);

	const handleAddNewColumn = (group: Profile, indexGroup: number) => {
		const hasContainsGroupId = groups.some(
			(item) => item.perfil_id === group.id.toString()
		);
		if (hasContainsGroupId) {
			return;
		}
		const filterSamePerfilId = compareGroups.filter(
			(item) => Number(item.perfil_id) === group.id
		);
		if (filterSamePerfilId.length === 0) {
			return;
		}

		const mappedPreviousIndexes = groups
			.map((item, i) => {
				if (i <= indexGroup) {
					return item;
				}
			})
			.filter((item) => item);

		setIdValueClicked(group.id);
		setGroups([...mappedPreviousIndexes, filterSamePerfilId[0]]);
		setTimeout(() => {
			stackRef.current.scrollLeft = stackRef.current.scrollWidth;
		}, 1);
	};

	const handleRemoveColumn = (index: number, id: number) => {
		const mappedPreviousIndexes = groups
			.map((item, i) => {
				if (i <= index) {
					return item;
				}
			})
			.filter((item) => item);
		setIdValueClicked(id);
		setGroups(mappedPreviousIndexes);
	};

	const handleOpenEditRestrictions = useCallback((group: Profile) => {
		setPerfil(group);
		setIsOpen(true);
	}, []);

	const handleCloseEditRestriction = () => {
		setIsOpen(false);
		setPerfil(null);
	};

	return (
		<>
			<Box
				width="100%"
				display="flex"
				alignSelf="stretch"
				flexDirection="column"
				sx={{
					backgroundColor: "#f3f3f3",
				}}
				paddingBottom="1rem"
			>
				<Header title="Perfil" isReturn />

				{loading && <CircularProgress sx={{ marginTop: 2 }} />}
				<Container maxWidth="md" sx={{ marginTop: "1rem" }}>
					{dataCount && (
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
						>
							<Box display="flex" alignItems="center">
								<Box
									sx={{
										backgroundImage: `url(${imgProfile})`,
										backgroundSize: "contain",
										backgroundPosition: "center",
										backgroundRepeat: "no-repeat",
										width: 150,
										height: 150,
										marginRight: 1,
									}}
								/>
								<Box>
									<Typography variant="caption">Qtd. perfis</Typography>
									<Typography variant="h6" fontSize={30}>
										{dataCount.countPerfis.qtd_perfis}
									</Typography>
								</Box>
							</Box>
							<Box color="blue">
								<Typography variant="caption">Qtd. usuários</Typography>
								<Typography variant="h6" fontSize={30}>
									{dataCount.countPerfis.qtd_users}
								</Typography>
							</Box>
							<Box color="red">
								<Typography variant="caption">Qtd. restrições</Typography>
								<Typography variant="h6" fontSize={30}>
									{dataCount.countPerfis.qtd_restrictions}
								</Typography>
							</Box>
						</Stack>
					)}
					{data && (
						<Item
							sx={{
								marginTop: "1rem",
								paddingY: 0,
								paddingX: 0,
								minHeight: "510px",
							}}
						>
							<Stack
								flex={1}
								flexWrap="nowrap"
								ref={stackRef}
								sx={{
									overflow: "auto",
								}}
								direction="row"
							>
								{groups.map((group, indexGroup) => (
									<Stack
										key={group.perfil_id}
										flex={1}
										borderRight="1px solid #ccc"
									>
										{group.value.map((item) => {
											const filterSamePerfilId = compareGroups.filter(
												(value) => Number(value.perfil_id) === item.id
											);
											return (
												<Stack
													key={item.id}
													padding={2}
													sx={{
														width: "100%",
														minWidth: "max-content",
														backgroundColor:
															idValueClicked === item.id ? "#EEE8AA" : "#fff",
													}}
													display="flex"
													alignItems="center"
													spacing={2}
													direction="row"
													borderBottom="1px solid #ccc"
													justifyContent="space-between"
												>
													<Box
														onClick={() =>
															handleRemoveColumn(indexGroup, item.id)
														}
														sx={{
															cursor: "pointer",
														}}
														flex={1}
													>
														<Typography variant="subtitle1" fontWeight={500}>
															{item.name}
														</Typography>
														<Typography variant="caption">
															{`${
																item.restrictions.length > 0
																	? item.restrictions.length + " restrições"
																	: "Sem restrições"
															}`}
														</Typography>
													</Box>
													<Box>
														<IconButton
															onClick={() => handleOpenEditRestrictions(item)}
														>
															<EditIcon
																sx={{
																	fontSize: 16,
																}}
															/>
														</IconButton>
														{filterSamePerfilId.length > 0 && (
															<IconButton
																onClick={() =>
																	handleAddNewColumn(item, indexGroup)
																}
															>
																<ArrowForwardIosIcon
																	sx={{
																		fontSize: 16,
																	}}
																/>
															</IconButton>
														)}
													</Box>
												</Stack>
											);
										})}
									</Stack>
								))}
							</Stack>
						</Item>
					)}
				</Container>
			</Box>
			<Fab
				color="primary"
				style={{ position: "fixed", bottom: 48, right: 48 }}
				onClick={() => setIsOpenModalCreatePerfil(true)}
			>
				<AddIcon />
			</Fab>
			{perfil && (
				<ModalEditRestrictions
					open={isOpen}
					handleClose={() => handleCloseEditRestriction()}
					perfil={perfil}
					refetch={refetch}
					compareGroups={compareGroups}
				/>
			)}
			<ModalCreatePerfil
				open={isOpenModalCreatePerfil}
				handleClose={() => setIsOpenModalCreatePerfil(false)}
				refetch={refetch}
			/>
		</>
	);
}
