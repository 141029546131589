import { useMutation, useQuery } from "@apollo/client";
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Stack,
} from "@mui/material";
import { UPDATE_SUBJECT } from "graphql/mutations/updateSubject";
import { useForm, FormProvider } from "react-hook-form";
import { useSnackbar } from "notistack";
import Input from "components/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Select } from "components/Select";
import { FETCH_FLOWS } from "graphql/queries/fetchFlows";
import { FETCH_GROUPS } from "graphql/queries/fetchGroups";
import { FetchFlows } from "types/graphql/queries/fetchFlows";
import { FetchGroups } from "types/graphql/queries/fetchGroups";
import SelectFilterModal from "components/selectFilterModal";
import { FetchRegulatoryActs } from "types/graphql/queries/fetchRegulatoryActs";
import { FETCH_REGULATORY_ACTS } from "graphql/queries/fetchRegulatoryActs";
import { GreenSwitch } from "components/GreenSwitch";

type EditDialogProps = {
	open: boolean;
	handleClose: () => void;
	data: any;
	onEdit: () => void;
};

const schema = yup.object().shape({
	name: yup.string().required("Campo obrigatório"),
	status: yup.string().required("Campo obrigatório"),
	type: yup.string().required("Campo obrigatório"),
	flow_id: yup.number().required("Campo obrigatório"),
	group_ids: yup.array(yup.number()).min(1, "Campo obrigatório"),
	can_create_service_order: yup.string().required("Campo obrigatório"),
});

export default function EditDialog({
	open,
	handleClose,
	data,
	onEdit,
}: EditDialogProps) {
	const methods = useForm({
		defaultValues: {
			name: data.name,
			type: data.type,
			flow_id: data.flow.id,
			group_ids: data.groups.map((item) => item.id),
			regulatory_act_ids: data.regulatory_acts.map((item) => item.id),
			status: data.status,
			can_create_service_order: data.can_create_service_order ? "Sim" : "Não",
		},
		resolver: yupResolver(schema),
	});
	const { enqueueSnackbar } = useSnackbar();

	const { data: dataGroup } = useQuery<FetchGroups>(FETCH_GROUPS, {
		variables: {
			limit: 1000,
		},
	});

	const { data: dataFlow } = useQuery<FetchFlows>(FETCH_FLOWS, {
		variables: {
			limit: 1000,
			order_by: "name",
			direction: "asc",
			status: "Ativo"
		},
	});

	const { data: dataActs } = useQuery<FetchRegulatoryActs>(
		FETCH_REGULATORY_ACTS,
		{
			variables: {
				limit: 1000,
			},
		}
	);

	// Setting mutations
	const [updateSubject, { loading }] = useMutation(UPDATE_SUBJECT);

	const {
		reset,
		handleSubmit,
		setValue,
		watch,
		formState: { errors },
	} = methods;

	const groupIds = watch("group_ids");
	const regulatoryActs = watch("regulatory_act_ids");
	const onSubmit = async (values: any) => {
		try {
			await updateSubject({
				variables: {
					id: data.id,
					name: values.name,
					type: values.type,
					flow_id: values.flow_id,
					group_ids: values.group_ids,
					regulatory_act_ids: values.regulatory_act_ids,
					status: values.status,
					can_create_service_order:
						values.can_create_service_order === "Sim" ? true : false,
				},
			});
			onEdit();
			reset();
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
	};
	const status = [
		{
			label: "Ativo",
			title: "Ativo",
			value: "Ativo",
		},
		{
			label: "Inativo",
			title: "Inativo",
			value: "Inativo",
		},
	];

	const type = [
		{
			label: "Administrativo",
			title: "Administrativo",
			value: "Administrativo",
		},
		{
			label: "Fiscalizatório",
			title: "Fiscalizatório",
			value: "Fiscalizatório",
		},
	];

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="paper"
				maxWidth="sm"
				fullWidth={true}
			>
				<FormProvider {...methods}>
					<DialogTitle id="scroll-dialog-title">Editar Assuntos</DialogTitle>

					<DialogContent>
						<Stack spacing={2} marginTop={2}>
							<Input name="name" autoFocus placeholder="Nome*" type="text" />
							<Select
								label="Selecione um status*"
								name="status"
								options={status}
							/>
							<Select label="Selecione um tipo*" name="type" options={type} />
							{data && dataGroup && (
								<SelectFilterModal
									error={errors.group_ids && true}
									title="Selecione um grupo fiscal*"
									fullWidth
									onChange={(value) => {
										const idGroups = value
											.filter((item) => !item.title)
											.map((item) => item.id);
										setValue("group_ids", idGroups);
									}}
									queryName={FETCH_GROUPS}
									order_by="name"
									dataName="fetchGroups"
									valuesSelecteds={dataGroup.fetchGroups.data.filter((item) => {
										return groupIds.includes(item.id);
									})}
								/>
							)}
							{data && dataActs && (
								<SelectFilterModal
									title="Selecione os Atos Regulatórios"
									fullWidth
									onChange={(value) => {
										const idActs = value
											.filter((item) => !item.title)
											.map((item) => item.id);
										setValue("regulatory_act_ids", idActs);
									}}
									queryName={FETCH_REGULATORY_ACTS}
									order_by="name"
									dataName="fetchRegulatoryActs"
									valuesSelecteds={dataActs.fetchRegulatoryActs.data.filter(
										(group) => {
											return regulatoryActs.includes(group.id);
										}
									)}
								/>
							)}
							{data && (
								<Select
									label="Selecione um fluxo*"
									name="flow_id"
									options={dataFlow.fetchFlows.data.map((flow) => ({
										title: flow.name,
										value: flow.id,
									}))}
								/>
							)}
							{data && (
								<Select
									label="Pode criar ordem de serviço?*"
									name="can_create_service_order"
									options={[
										{
											title: "Sim",
											value: "Sim",
										},
										{
											title: "Não",
											value: "Não",
										},
									]}
								/>
							)}
						</Stack>
					</DialogContent>

					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Cancelar
						</Button>
						<Button
							onClick={handleSubmit(onSubmit)}
							variant="contained"
							color="primary"
							disabled={loading}
						>
							{loading && (
								<CircularProgress size={14} style={{ marginRight: 12 }} />
							)}
							Editar
						</Button>
					</DialogActions>
				</FormProvider>
			</Dialog>
		</>
	);
}
