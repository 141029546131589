import { Stack, TextField } from "@mui/material";
import { useQuery } from "@apollo/client";
import { CircularProgress } from "components/CircularProgress";
import { Select } from "components/Select";
import { FETCH_SUBJECTS } from "graphql/queries/fetchSubjects";
import { FetchSubjects } from "types/graphql/queries/fetchSubjects";
import { FETCH_USERS } from "graphql/queries/fetchUsers";
import { FetchUsers } from "types/graphql/queries/fetchUsers";
import { Controller, useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs//locale/pt-br";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export function StepSubject() {
	const { control } = useFormContext();
	const { data, loading } = useQuery<FetchSubjects>(FETCH_SUBJECTS, {
		variables: {
			limit: 1000,
			can_create_service_order: true,
		},
	});

	const { data: dataUser } = useQuery<FetchUsers>(FETCH_USERS, {
		variables: {
			limit: 1000,
			status: "Ativo",
			order_by: "users.name",
			direction: "asc",
		},
	});

	const [selectResponsible, setSelectResponsible] = useState([]);

	const { setValue, watch } = useFormContext();
	const subjectForm = watch("subject_id");

	const types = [
		{
			label: "Fiscalizatório",
			title: "Fiscalizatório",
			value: "Fiscalizatório",
		},
	];

	useEffect(() => {
		if (data && subjectForm) {
			setValue(
				"dynamicForm",
				data.fetchSubjects.data.find((subject) => subject.id === subjectForm)
					.dynamic_form
			);
			setSelectResponsible(
				data.fetchSubjects.data.find((subject) => subject.id === subjectForm)
					.groups
			);
		}
	}, [data, subjectForm]);

	return (
		<>
			{loading ? (
				<CircularProgress />
			) : (
				<>
					{data && (
						<Stack spacing={2}>
							<Select
								name="subject_id"
								label="Assunto*"
								options={data.fetchSubjects.data.map((subject) => ({
									label: subject.name,
									value: subject.id,
								}))}
								fullWidth
								marginSelect={0}
							/>
							{selectResponsible.length > 0 && (
								<>
									<Select
										name="type"
										label="Tipo de OS*"
										options={types}
										fullWidth
										marginSelect={0}
									/>

									<Select
										name="responsible_user_id"
										label="Fiscal*"
										options={dataUser.fetchUsers.data.map((group) => ({
											label: group.name,
											value: group.id,
										}))}
										fullWidth
									/>

									<Controller
										control={control}
										name="date"
										defaultValue={null}
										render={(inputProps) => (
											<LocalizationProvider
												dateAdapter={AdapterDayjs}
												adapterLocale="pt-br"
											>
												<DatePicker
													disablePast
													inputFormat="DD/MM/YYYY"
													label="Data para resolução*"
													value={inputProps.field.value}
													onChange={(newValue) => {
														inputProps.field.onChange(newValue);
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															sx={{ width: "100%" }}
															name={inputProps.field.name}
															error={!!inputProps.fieldState.error}
														/>
													)}
												/>
											</LocalizationProvider>
										)}
									/>
								</>
							)}
						</Stack>
					)}
				</>
			)}
		</>
	);
}
