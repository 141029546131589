import { IconButton, IconButtonProps } from "@mui/material";
import React, { forwardRef, ForwardRefRenderFunction } from "react";

const iconButton: ForwardRefRenderFunction<
	HTMLButtonElement,
	IconButtonProps
> = ({ onClick, children, type = "button", color, disabled, ...rest }, ref) => {
	return (
		<IconButton
			onClick={onClick}
			ref={ref}
			type={type}
			color={color}
			disabled={disabled}
			{...rest}
		>
			{children}
		</IconButton>
	);
};

export default forwardRef(iconButton);
