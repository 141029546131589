import { gql } from "@apollo/client";

export const FETCH_POINTS_BY_MONTH = gql`
	query (
		$keyword: String
		$order_by: String
		$direction: String
		$year: Int!
		$month: Int!
		$user_id: Int
		$responsibles: [Int]
	) {
		fetchPointsByMonth(
			keyword: $keyword
			order_by: $order_by
			direction: $direction
			year: $year
			month: $month
			user_id: $user_id
			responsibles: $responsibles
		) {
			year_month
			accumulated
			user {
				id
				name
				params
			}
		}
	}
`;
