import { gql } from "@apollo/client";

export const FETCH_DOCUMENTS = gql`
	query (
		$sender_id: Int
		$deleted: Boolean
		$concluded: Boolean
		$necessary_action: Boolean
		$waiting_others: Boolean
		$keyword: String
		$order_by: String
		$direction: String
		$page: Int
		$limit: Int
	) {
		fetchDocuments(
			sender_id: $sender_id
			deleted: $deleted
			concluded: $concluded
			necessary_action: $necessary_action
			waiting_others: $waiting_others
			keyword: $keyword
			order_by: $order_by
			direction: $direction
			page: $page
			limit: $limit
		) {
			total
			current_page
			per_page
			last_page
			data {
				id
				name
				url
				sender {
					id
					name
					email
					perfil_id
				}
				recipients {
					id
					user {
						id
						name
						email
						perfil_id
					}
					is_signed
					sign_code
					signed_at
				}
				total_signed
				expire_days
				deleted_at
				updated_at
				created_at
			}
		}
	}
`;
