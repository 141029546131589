import { gql } from "@apollo/client";

export const UPDATE_OCCURRENCE_TYPE = gql`
	mutation updateOccurrenceType(
		$id: Int!
		$dynamic_form_id: Int!
		$name: String!
		$description: String
		$intimationText: String
		$status: String
		$autofill_regulatory_acts: Boolean
	) {
		updateOccurrenceType(
			id: $id
			dynamic_form_id: $dynamic_form_id
			name: $name
			description: $description
			intimationText: $intimationText
			status: $status
			autofill_regulatory_acts: $autofill_regulatory_acts
		)
	}
`;
