import {
	styled,
	SpeedDial,
	SpeedDialProps as SpeedDialPropsMaterial,
} from "@mui/material";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
	position: "fixed",
	"&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	"&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
		top: theme.spacing(2),
		left: theme.spacing(2),
	},
}));

type SpeedDialProps = SpeedDialPropsMaterial & {
	actions: Array<{
		icon: JSX.Element;
		name: string;
		onClick: () => void;
	}>;
};

export default function SpeedDialComponent({
	actions,
	...rest
}: SpeedDialProps) {
	return (
		<StyledSpeedDial {...rest} icon={<MoreVertIcon />} direction={"up"}>
			{actions.map((action) => (
				<SpeedDialAction
					key={action.name}
					icon={action.icon}
					tooltipTitle={action.name}
					onClick={action.onClick}
				/>
			))}
		</StyledSpeedDial>
	);
}
