import { gql } from "@apollo/client";

export const FETCH_DETAIL_DYNAMIC_FORM = gql`
	query ($dynamic_form_id: Int!) {
		detailDynamicForm(dynamic_form_id: $dynamic_form_id) {
			id
			name
			code
			version
			groups {
				title
				inputs {
					name
					size
					type
					label
					valor
					required
					placeholder
					nomeComponente
					tipoComponenteDinamico
					options {
						id
						name
					}
				}
			}
		}
	}
`;
