/* eslint-disable no-mixed-spaces-and-tabs */
import { Fragment } from "react";
import { Box, List, ListItem, Stack, Typography, Divider } from "@mui/material";
import Chip from "@mui/material/Chip";
import { EmptyMessage } from "components/EmptyMessage";
import noData from "assets/img/no-protocols.svg";
import { FetchProtocols } from "types/graphql/queries/fetchProtocols";
import { format } from "date-fns";
import { getTheme } from "utils/protocolStatus";
import invertColor from "utils/invertColor";
import HoverLink from "components/HoverLink";
import { useAuthContext } from "context/AuthProvider";
import { useVerifyNoHasRestriction } from "utils/useVerifyNoHasRestriction";

type ListProtocolsProps = FetchProtocols;

export function ListProtocols({ ...data }: ListProtocolsProps) {
	const { state } = useAuthContext();
	const currentUserId = state.userInfo.user.id;

	const noHasRestrictionDetail = useVerifyNoHasRestriction("PROTOCOLO_DETALHAR");

	return (
		<List>
			{data && data.fetchProtocols.data.length === 0 && (
				<>
					<EmptyMessage message="Nenhum protocolo encontrado 😥" />
					<Box
						marginTop={2}
						sx={{
							backgroundImage: `url(${noData})`,
							backgroundRepeat: "no-repeat",
							backgroundPosition: "center",
							backgroundSize: "contain",
							height: "200px",
						}}
					/>
				</>
			)}
			{data &&
				data.fetchProtocols.data.map((item, index) => (
					<Fragment key={item.id}>
						<ListItem
							disablePadding
							sx={{
								flexDirection: "column",
							}}
						>
							<Box
								p={2}
								display="flex"
								justifyContent="space-between"
								width="100%"
								flexWrap={["wrap", "nowrap", "nowrap"]}
							>
								<Stack spacing={2} flex={1} maxWidth="300px">
									<Box>
										{noHasRestrictionDetail || item.task_user_ids.includes(currentUserId) ? (
											<HoverLink href={`/protocolo/${item.code}?from=protocolos`}>
												{item.code}
											</HoverLink>
										) : (
											<Typography variant="body1">{item.code}</Typography>
										)}
										<Typography variant="caption">
											Criado em{" "}
											{format(new Date(item.created_at), "dd/MM/yyyy HH:mm")}
										</Typography>
									</Box>
									<Box>
										<Typography variant="caption">Assunto</Typography>
										<Typography variant="subtitle2">
											{item.subject?.name || "-"}
										</Typography>
									</Box>
									<Box sx={{ width: "200%" }}>
										<Typography variant="caption">Endereço</Typography>
										<Typography variant="subtitle2">
											{item.street ? item.street : "-"}{" "}
											{item.number
												? (item.street ? ", " : "") + "nº" + item.number
												: null}{" "}
											{item.district ? item.district.name : null}
											{item.district?.city
												? " - " +
												item.district.city.name +
												"/" +
												item.district.city.state
												: null}
										</Typography>
									</Box>
								</Stack>
								<Stack spacing={2} flex={1}>
									<Box>
										<Typography variant="caption">Responsável</Typography>
										<Typography variant="subtitle2">
											{item.responsible?.name || "-"}
										</Typography>
									</Box>
									<Box>
										<Typography variant="caption">Status atual</Typography>
										<div>
											{item.status ? (
												<Chip
													label={item.status.name}
													style={{
														backgroundColor: item.status.theme + "1c",
														color: item.status.theme,
													}}
												/>
											) : (
												"-"
											)}
										</div>
									</Box>
								</Stack>
								<Stack spacing={2} justifyContent="space-between">
									<Box>
										<Typography variant="caption">Situação</Typography>
										<div>
											<Chip
												label={item.situation}
												style={{
													backgroundColor: getTheme(item.situation),
													color: invertColor(getTheme(item.situation)),
												}}
											/>
										</div>
									</Box>
									<Box>
										<Typography variant="caption">Atualizado em</Typography>
										<Typography variant="subtitle2">
											{format(new Date(item.updated_at), "dd/MM/yyyy HH:mm")}
										</Typography>
									</Box>
									<Box>
										<Typography variant="caption">Data expiração</Typography>
										<Typography variant="subtitle2">
											{item.expire_at
												? format(new Date(item.expire_at), "dd/MM/yyyy HH:mm")
												: " - "}
										</Typography>
									</Box>
								</Stack>
							</Box>
						</ListItem>
						{index + 1 < data.fetchProtocols.data.length && <Divider />}
					</Fragment>
				))}
		</List>
	);
}
