import { ModalLayout } from "components/ModalLayout";
import { ModalLayoutProps } from "types/ModalLayout";
import { useFormContext } from "react-hook-form";
import { Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { Component } from "pages/DynamicForm";

type ModalVisualizationProps = ModalLayoutProps;

export function ModalVisualization({
	open,
	handleClose,
	buttonText,
}: ModalVisualizationProps) {
	const { getValues } = useFormContext();
	const { groups } = getValues();
	return (
		<ModalLayout
			title="Pré visualização"
			open={open}
			handleClose={handleClose}
			buttonText={buttonText}
			isNotShowButtonSubmit={true}
			variantButtonClose="contained"
		>
			<Grid container spacing={2} marginTop={0}>
				{groups.map((group: any, indexGroup) => (
					<Fragment key={group.id}>
						<Grid item xs={12} marginTop={indexGroup > 0 && 2}>
							<Typography
								variant="h6"
								component="h2"
								borderTop={indexGroup > 0 && "1px solid #ccc"}
								paddingTop={indexGroup > 0 && 2}
							>
								{group.name}
							</Typography>
						</Grid>
						{group.components.map((component: Component) => (
							<Fragment key={component.id}>
								{component && component.component && (
									<component.component
										label={component.name}
										name={component.name}
										placeholder={component.name}
										options={component.options}
										fullWidth
										index={indexGroup}
									/>
								)}
							</Fragment>
						))}
					</Fragment>
				))}
			</Grid>
		</ModalLayout>
	);
}
