import { List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { ModalLayout } from "components/ModalLayout";
import { ModalLayoutProps } from "types/ModalLayout";
import Input from "components/Input";
import { useMutation } from "@apollo/client";
import { SUSPEND_PROTOCOL } from "graphql/mutations/suspendProtocol";
import { CANCEL_PROTOCOL } from "graphql/mutations/cancelProtocol";
import { useSnackbar } from "notistack";
import { REOPEN_PROTOCOL } from "graphql/mutations/reopenProtocol";
import { StepFileBase } from "components/StepFileBase/BasicStepFile";

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { maskCEP } from "utils/maskOutputs";

type ModalJustificationprotocolProps = ModalLayoutProps & {
	situation: string;
	code: string;
	type: string;
	refetch: () => void;
	data?: any;
};

export function ModalJustificationProtocol({
	open,
	handleClose,
	code,
	type,
	refetch,
	situation,
	data,
}: ModalJustificationprotocolProps) {
	const { enqueueSnackbar } = useSnackbar();
	const [suspendProtocol, { loading: loadingSuspend }] =
		useMutation(SUSPEND_PROTOCOL);
	const [cancelProtocol, { loading: loadingCancel }] =
		useMutation(CANCEL_PROTOCOL);
	const [reopenProtocol, { loading: loadingReopen }] =
		useMutation(REOPEN_PROTOCOL);
	const methods = useForm();

	const { handleSubmit, reset, watch } = methods;

	const files = watch("files", []);

	const onSubmit = async (data: any) => {
		if (situation === "Concluido") {
			enqueueSnackbar(
				"Não é possível suspender ou cancelar um protocolo concluído",
				{
					variant: "error",
				}
			);
			return;
		} else if (situation === "Suspenso" && type === "suspend") {
			enqueueSnackbar("Não é possível suspender um protocolo já suspenso", {
				variant: "error",
			});
			return;
		} else if (situation === "Cancelado" && type !== "reopen") {
			enqueueSnackbar(
				"Não é possível suspender ou cancelar um protocolo cancelado",
				{
					variant: "error",
				}
			);
			return;
		}
		if (type === "suspend") {
			try {
				await suspendProtocol({
					variables: {
						code,
						justification: data.justification,
						files: files ? files : [],
					},
				});
				enqueueSnackbar("Protocolo suspenso com sucesso", {
					variant: "success",
				});
				refetch();
				handleClose();
				reset({
					justification: "",
					files: []
				});
			} catch (err) {
				enqueueSnackbar(err.message, {
					variant: "error",
				});
			}
		} else if (type === "cancel") {
			try {
				await cancelProtocol({
					variables: {
						code,
						justification: data.justification,
						files: files ? files : [],
					},
				});
				enqueueSnackbar("Protocolo cancelado com sucesso", {
					variant: "success",
				});
				refetch();
				handleClose();
				reset({
					justification: "",
					files: []
				});
			} catch (err) {
				enqueueSnackbar("Ocorreu um erro ao cancelar o protocolo", {
					variant: "error",
				});
			}
		} else if (type === "reopen") {
			try {
				await reopenProtocol({
					variables: {
						code,
						justification: data.justification,
					},
				});
				enqueueSnackbar("Protocolo reaberto com sucesso", {
					variant: "success",
				});
				refetch();
				handleClose();
				reset({
					justification: "",
				});
			} catch (err) {
				enqueueSnackbar("Ocorreu um erro ao reabrir o protocolo", {
					variant: "error",
				});
			}
		}
	};

	return (
		<FormProvider {...methods}>
			<ModalLayout
				open={open}
				handleClose={handleClose}
				title="Justificativa"
				handleSubmit={handleSubmit(onSubmit)}
				isNotShowButtonSubmit={data && data.justifications ? true : false}
				isLoading={loadingSuspend || loadingCancel || loadingReopen}
			>
				<Stack spacing={2} marginTop={2}>
					{data && data.justifications ? (
						<List>
							{data.justifications.slice().reverse().map((justification: any, index: number) => (
								<ListItem key={index} disablePadding>
									<ListItemText
										primary={justification.update_justification}
										secondary={
											<>
												<Typography variant="body2">
													{format(new Date(justification.updated_at), 'dd/MM/yyyy HH:mm', { locale: ptBR })}
												</Typography>
												<Typography variant="body2">
													{'Endereço alterado: '}
													<span style={{ fontWeight: 'bold' }}>
														{justification.street
															? justification.street
															: null}{" "}
														{justification.number
															? (justification.street ? ", " : "") +
															"nº" +
															justification.number
															: null}{" "}
														{justification.district
															? justification.district.name
															: null}
														{justification.city
															? " - " +
															justification.city.name +
															"/" +
															justification.city.state
															: null}
														{justification.old_zipcode
															? (", " + maskCEP(justification.old_zipcode))
															: null}

													</span>
												</Typography>
												<Typography variant="body2">
													{'Atualizado por '}
													<span style={{ fontWeight: 'bold' }}>
														{justification.user_name}
													</span>
												</Typography>
											</>
										}
									/>
								</ListItem>
							))}
						</List>
					) : (
						<Input
							name="justification"
							placeholder="Escreva a sua justificativa"
							multiline
							rows={4}
							isRequired={true}
						/>
					)}
				</Stack>
				{(type === "suspend" || type === "cancel") && (
					<StepFileBase />
				)}
			</ModalLayout>
		</FormProvider>
	);
}
