import { Box, List, ListItem, Typography, Stack, Avatar } from "@mui/material";
import { NavLink, Route, Routes } from "react-router-dom";
import {
	sidebar,
	fiscalizationRoutes,
	documentManagerRoutes,
} from "routes/sidebar";
import { useAuthContext } from "context/AuthProvider";
import { useQuery } from "@apollo/client";
import { FetchDetailCompany } from "types/graphql/queries/fetchDetailCompany";
import { FETCH_DETAIL_COMPANY } from "graphql/queries/fetchDetailCompany";
import { CircularProgress } from "components/CircularProgress";
import { useVerifyNoHasRestriction } from "utils/useVerifyNoHasRestriction";

const subdomain = window.location.hostname.split(".")[0];

const activeStyle = {
	textDecoration: "none",
	color: "#3485f7",
	width: "100%",
};

const isNotActiveStyle = {
	textDecoration: "none",
	color: "gray",
	width: "100%",
};

export function SidebarContent() {
	const { data, loading: loadingDetailCompany } = useQuery<FetchDetailCompany>(
		FETCH_DETAIL_COMPANY,
		{
			variables: {
				domain:
					subdomain === "localhost"
						? process.env.REACT_DEFAULT_DOMAIN || "lagoasanta"
						: subdomain,
			},
		}
	);
	const { state } = useAuthContext();

	const noHasRestrictionComplaint =
		useVerifyNoHasRestriction("DENUNCIA_AVALIAR");

	const hasModuleCode = data?.detailCompany.modules.find(
		(item) => item.code === "GED"
	);

	return (
		<Box height="100%" padding={2} minWidth="20vw" position="relative">
			<Stack
				direction="column"
				justifyContent="space-between"
				spacing={2}
				height="100%"
			>
				<Box>
					{loadingDetailCompany ? (
						<CircularProgress />
					) : (
						<>
							<Box
								position="absolute"
								top={0}
								left={0}
								width="100%"
								height="150px"
								sx={{
									backgroundImage: `url(${data && data.detailCompany.cover})`,
									backgroundRepeat: "no-repeat",
									backgroundSize: "cover",
									backgroundPosition: "center",
									filter: "brightness(0.2)",
								}}
							/>
							<Box
								marginTop="-16px"
								display="flex"
								columnGap={2}
								position="relative"
								zIndex={1}
								height="150px"
								alignItems="center"
							>
								<Avatar
									sx={{
										width: 70,
										height: 70,
									}}
								/>
								<Box>
									<Typography variant="body2" color="white">
										{state.userInfo.user.perfil || "Admin"}
									</Typography>
									<Typography variant="h6" color="white" maxWidth="200px">
										{state.userInfo.user.name}
									</Typography>
								</Box>
							</Box>
						</>
					)}
					<List sx={{ flex: 1 }}>
						{sidebar.map((route) => {
							if (
								!state.userInfo.user.is_point_accumulator &&
								route.name === "Pontuação"
							)
								return;

							return (
								<ListItem
									key={route.path}
									sx={{
										paddingX: 0,
									}}
								>
									<NavLink
										to={route.path}
										style={({ isActive }) =>
											isActive ? activeStyle : isNotActiveStyle
										}
									>
										<Routes>
											<Route path={route.path} />
										</Routes>
										<Box display="flex" flexDirection="column" rowGap={1}>
											<Box display="flex" alignItems="center" columnGap={4}>
												<route.icon />
												<Typography variant="body2" flex={1}>
													{route.name}
												</Typography>
											</Box>
										</Box>
									</NavLink>
								</ListItem>
							);
						})}
					</List>
					{noHasRestrictionComplaint && (
						<List sx={{ flex: 1, marginTop: 2 }}>
							<>
								<Typography variant="body2" color="gray" fontWeight={500}>
									Fiscalização
								</Typography>
								{fiscalizationRoutes.map((route) => {
									return (
										<ListItem
											key={route.name}
											sx={{
												paddingX: 0,
											}}
										>
											<NavLink
												to={route.path}
												style={({ isActive }) =>
													isActive ? activeStyle : isNotActiveStyle
												}
											>
												<Routes>
													<Route path={route.path} />
												</Routes>
												<Box display="flex" flexDirection="column" rowGap={1}>
													<Box display="flex" alignItems="center" columnGap={4}>
														<route.icon />
														<Typography variant="body2" flex={1}>
															{route.name}
														</Typography>
													</Box>
												</Box>
											</NavLink>
										</ListItem>
									);
								})}
							</>
						</List>
					)}
					{hasModuleCode && (
					<List sx={{ flex: 1, marginTop: 2 }}>
						<>
							<Box display="flex" alignItems="flex-end">
								<Typography variant="body2" color="gray" fontWeight={500}>
									Gerenciador de documentos
								</Typography>
							</Box>
							{documentManagerRoutes.map((route) => {
								return (
									<ListItem
										key={route.name}
										sx={{
											paddingX: 0,
										}}
									>
										<NavLink
											to={route.path}
											style={({ isActive }) =>
												isActive ? activeStyle : isNotActiveStyle
											}
										>
											<Routes>
												<Route path={route.path} />
											</Routes>
											<Box display="flex" flexDirection="column" rowGap={1}>
												<Box display="flex" alignItems="center" columnGap={4}>
													<route.icon />
													<Typography variant="body2" flex={1}>
														{route.name}
													</Typography>
												</Box>
											</Box>
										</NavLink>
									</ListItem>
								);
							})}
						</>
					</List>
					)}
				</Box>
			</Stack>
		</Box>
	);
}
