/* eslint-disable no-mixed-spaces-and-tabs */
import {
	Box,
	Link,
	List,
	ListItem,
	Stack,
	Typography,
	Divider,
} from "@mui/material";
import { ModalLayout } from "components/ModalLayout";
import { format } from "date-fns";
import { DataProtocol } from "types/graphql/queries/fetchProtocols";

interface ModalDetailProtocolsProps {
	isOpen: boolean;
	onClose: () => void;
	protocols: DataProtocol[];
}
export function ModalDetailProtocols({
	isOpen,
	onClose,
	protocols,
}: ModalDetailProtocolsProps) {
	return (
		<ModalLayout
			open={isOpen}
			handleClose={onClose}
			title="Detalhes"
			isNotShowButtonSubmit
		>
			<List>
				{protocols.map((protocol, index) => (
					<>
						<ListItem key={protocol.id}>
							<Stack
								direction="row"
								flex={1}
								spacing={[0, 0, 2]}
								flexWrap="wrap"
							>
								<Box
									display="flex"
									flexDirection="column"
									alignItems="space-between"
									maxWidth="200px"
								>
									<Box display="flex" flexDirection="column">
										<Link
											variant="subtitle2"
											href={`/protocolo/${protocol.code}`}
										>
											{protocol.code}
										</Link>
										<Typography variant="caption">
											{format(
												new Date(protocol.created_at),
												"dd/MM/yyyy HH:mm"
											)}
										</Typography>
									</Box>
									<Box marginTop={2}>
										<Typography variant="caption">Responsável</Typography>
										<Typography variant="subtitle2">
											{protocol.responsible ? protocol.responsible.name : " - "}
										</Typography>
									</Box>
								</Box>
								<Box
									display="flex"
									flexDirection="column"
									alignItems="space-between"
									flex={1}
								>
									<Box display="flex" flexDirection="column">
										<Typography variant="caption">Assunto</Typography>
										<Typography variant="subtitle2">
											{protocol.subject ? protocol.subject.name : " - "}
										</Typography>
									</Box>
									<Box marginTop={2}>
										<Typography variant="caption">Endereço</Typography>
										<Typography variant="subtitle2">
											{protocol.street ? protocol.street : null}{" "}
											{protocol.number
												? (protocol.street ? ", " : "") + "nº" + protocol.number
												: null}{" "}
											{protocol.district ? protocol.district.name : null}
											{protocol.district?.city
												? " - " +
												  protocol.district.city.name +
												  "/" +
												  protocol.district.city.state
												: null}
										</Typography>
									</Box>
								</Box>
							</Stack>
						</ListItem>
						{index + 1 < protocols.length && (
							<Divider
								variant="inset"
								sx={{
									"&.MuiDivider-inset": {
										marginLeft: "-24px",
										marginRight: "-24px",
									},
								}}
							/>
						)}
					</>
				))}
			</List>
		</ModalLayout>
	);
}
