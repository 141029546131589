/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Item } from "components/Paper";
import { Title } from "components/Title";
import { Box } from "@mui/material";
import { EmptyMessage } from "components/EmptyMessage";
import noData from "assets/img/no_data.png";
import { useQuery } from "@apollo/client";
import { FETCH_CONNECTED_DEVICES } from "graphql/queries/fetchConnectedDevices";
import { FetchConnectedDevices } from "types/graphql/queries/fetchConnectedDevices";
import { CircularProgress } from "components/CircularProgress";
import { Wrapper } from "@googlemaps/react-wrapper";
import React from "react";
import Map from "./Map";
import Marker from "./Marker";

export function CardDevicesLocation() {
	const { data, loading } = useQuery<FetchConnectedDevices>(
		FETCH_CONNECTED_DEVICES
	);
	return (
		<Item
			sx={{
				height: "100%",
				overflowY: "auto",
			}}
		>
			<Box
				height="100%"
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
			>
				<Box>
					<Title
						title="Dispositivos móveis"
						sx={{
							marginBottom: "1rem",
						}}
					/>
					{loading && <CircularProgress />}
					{data && data.fetchConnectedDevices?.length > 0 ? (
						<Wrapper apiKey={"AIzaSyArB6wBU94QZ-2t8brj2Lt9CL1w8tr23lo"}>
							<Map
								center={{ lat: -19.627369478283864, lng: -43.89424334862232 }}
								zoom={14}
								style={{ height: 600 }}
							>
								{data.fetchConnectedDevices.map((device) => (
									<Marker
										title={device.name + " - " + device.created_at}
										clickable
										position={{
											lat: device.lat,
											lng: device.lng,
										}}
										key={device.id}
									/>
								))}
							</Map>
						</Wrapper>
					) : (
						<Box>
							<EmptyMessage message="Não há dispositivos conectados para exibir" />
							<Box
								marginTop={2}
								sx={{
									backgroundImage: `url(${noData})`,
									backgroundRepeat: "no-repeat",
									backgroundPosition: "center",
									backgroundSize: "contain",
									height: "200px",
								}}
							/>
						</Box>
					)}
				</Box>
			</Box>
		</Item>
	);
}
