import { useMutation } from "@apollo/client";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";
import { DataFlow } from "types/graphql/queries/fetchFlows";
import { DELETE_FLOW } from "graphql/mutations/deleteFlow";
import { useSnackbar } from "notistack";

type DeleteDialogProps = {
	open: boolean;
	handleClose: () => void;
	onDelete: () => void;
	data: DataFlow;
}

export default function DeleteDialog({ open, handleClose, onDelete, data }: DeleteDialogProps) {

	const { enqueueSnackbar } = useSnackbar();

	// Setting mutation
	const [deleteFlow, { loading: loadingDelete }] = useMutation(
		DELETE_FLOW
	);

	// Chamando a mutation de remoção
	const handleDelete = () => {
		deleteFlow({
			variables: {
				id: data.id,
			},
		}).then(() => {
			onDelete();
		}).catch(
			(error) => {
				enqueueSnackbar(error.message, {
					variant: "error",
				});
			}
		);
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="paper"
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogTitle id="scroll-dialog-title">Excluir fluxo</DialogTitle>

				<DialogContent>

					<p>Deseja realmente excluir o fluxo?</p>

				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancelar
					</Button>
					<Button onClick={handleDelete} variant="contained" color="primary" disabled={loadingDelete}>
						{loadingDelete && <CircularProgress size={14} style={{ marginRight: 12 }} />}
						Excluir
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}
