import { useState } from "react";
import { useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import {
	Box,
	Container,
	Fab,
	IconButton,
	InputAdornment,
	MenuItem,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	TextField,
	useMediaQuery,
	Menu,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from '@mui/icons-material/FilterList';
import RestoreIcon from '@mui/icons-material/Restore';

import { CircularProgress } from "components/CircularProgress";
import PaginationStorage from "components/PaginationStorage";
import { EmptyMessage } from "components/EmptyMessage";
import { Header } from "components/Header";
import { Item } from "components/Paper";

import EditPassword from "pages/Settings/InfoResume/EditPassword";
import CreateUserDialog from "./CreateUserDialog";
import RestoreDialog from "./RestoreDialog";
import DeleteDialog from "./DeleteDialog";
import EditDialog from "./EditDialog";

import useDebounce from "hooks/useDebounce";

import { DataUser, FetchUsers } from "types/graphql/queries/fetchUsers";
import { FETCH_USERS } from "graphql/queries/fetchUsers";

import noUser from "assets/img/noUser.png";

export function Users() {
	const { enqueueSnackbar } = useSnackbar();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [currentPage, setCurrentPage] = useState(0);
	const [direction, setDirection] = useState<"asc" | "desc">("asc");
	const [isOpenModalUpdatePassword, setIsOpenModalUpdatePassword] = useState(false);
	const [keyword, setKeyword] = useState("");
	const [orderBy, setOrderBy] = useState("users.name");
	const [openEdit, setOpenEdit] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [showCreate, setShowCreate] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [showPagination, setShowPagination] = useState(false);
	const [showRestored, setShowRestored] = useState(false);
	const [status, setStatus] = useState<"Ativo" | "Inativo" | null>(null);
	const [userId, setUserId] = useState(null);
	const [userRestored, setUserRestored] = useState(null);
	const [userToDeleted, setUserToDelete] = useState<DataUser>(null);
	const [userToEdit, setUserToEdit] = useState<DataUser>(null);

	const debouncedValue = useDebounce(keyword, 1000);

	const { data, loading, refetch } = useQuery<FetchUsers>(FETCH_USERS, {
		variables: {
			limit: rowsPerPage,
			status: status,
			page: currentPage + 1,
			order_by: orderBy,
			direction: direction,
			keyword: debouncedValue,
		},
	});

	function onRequestOpenModalUpdatePassword(userId: number) {
		setUserId(userId);
		setIsOpenModalUpdatePassword(true);
	}

	function onRequestCloseModalUpdatePassword() {
		setIsOpenModalUpdatePassword(false);
	}

	function handleDeleteUser(user) {
		setUserToDelete(user);
		setShowDelete(true);
	}

	function handleRestore(user) {
		setUserRestored(user);
		setShowRestored(true);
	}

	function handleOpenModalEdit(user) {
		setUserToEdit(user);
		setOpenEdit(true);
	}

	function handleSort(name) {
		const isAsc = orderBy === name && direction === "asc";
		setDirection(isAsc ? "desc" : "asc");
		setOrderBy(name);
	}

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const media500 = useMediaQuery("(max-width:500px)");

	return (
		<Box
			width="100%"
			display="flex"
			alignSelf="stretch"
			flexDirection="column"
			sx={{
				backgroundColor: "#f3f3f3",
			}}
			paddingBottom="1rem"
		>
			<Header title="Usuários" isReturn />

			{loading ? (
				<CircularProgress />
			) : (
				<Container maxWidth="lg" sx={{ marginTop: "1rem" }}>
					<div
						style={{ position: "relative", marginBottom: "60px" }}
						onMouseEnter={() => setShowPagination(true)}
						onMouseLeave={() => setShowPagination(false)}
					>
						<Item
							sx={{
								paddingX: 0,
								paddingY: "1px",
							}}
						>
							<Box sx={{ padding: 2 }}>
								<TextField
									fullWidth
									variant="standard"
									name="keyword"
									type="search"
									label="Pesquisar..."
									value={keyword}
									onChange={(event) => {
										setKeyword(event.target.value);
									}}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-controls="simple-menu"
													aria-haspopup="true"
													onClick={handleClick}
												>
													<FilterListIcon />
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<Menu
									id="simple-menu"
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={handleClose}
								>
									<MenuItem
										onClick={() => { handleClose(); setStatus(null); }}
										value={null}
										style={{ backgroundColor: status === null ? '#F0F0F0' : 'transparent' }}
									>
										Todos
									</MenuItem>
									<MenuItem
										onClick={() => { handleClose(); setStatus("Ativo"); }}
										value="Ativo"
										style={{ backgroundColor: status === "Ativo" ? '#F0F0F0' : 'transparent' }}
									>
										Ativo
									</MenuItem>
									<MenuItem
										onClick={() => { handleClose(); setStatus("Inativo"); }}
										value="Inativo"
										style={{ backgroundColor: status === "Inativo" ? '#F0F0F0' : 'transparent' }}
									>
										Inativo
									</MenuItem>
								</Menu>
							</Box>
							<TableContainer style={{ marginTop: "10px" }}>
								{data?.fetchUsers?.total === 0 && (
									<>
										<EmptyMessage message="Nenhum usuário encontrado 😥" />
										<Box
											marginTop={2}
											sx={{
												backgroundImage: `url(${noUser})`,
												backgroundRepeat: "no-repeat",
												backgroundPosition: "center",
												backgroundSize: "contain",
												height: "200px",
											}}
										/>
									</>
								)}
								{data?.fetchUsers?.total !== 0 && (
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>
													<TableSortLabel
														active={orderBy === "users.name"}
														direction={
															orderBy === "users.name" ? direction : "asc"
														}
														onClick={() => handleSort("users.name")}
													>
														Nome
													</TableSortLabel>
												</TableCell>
												<TableCell>
													<TableSortLabel
														active={orderBy === "users.email"}
														direction={
															orderBy === "users.email" ? direction : "asc"
														}
														onClick={() => handleSort("users.email")}
													>
														Email
													</TableSortLabel>
												</TableCell>
												<TableCell>
													<TableSortLabel
														active={orderBy === "users.status"}
														direction={
															orderBy === "users.status" ? direction : "asc"
														}
														onClick={() => handleSort("users.status")}
													>
														Status
													</TableSortLabel>
												</TableCell>
												<TableCell>
													<TableSortLabel
														active={orderBy === "perfils.name"}
														direction={
															orderBy === "perfils.name" ? direction : "asc"
														}
														onClick={() => handleSort("perfils.name")}
													>
														Perfil
													</TableSortLabel>
												</TableCell>
												<TableCell sx={{ padding: 0 }}>&nbsp;</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{data?.fetchUsers?.data.map((row) => (
												<TableRow key={row.id}>
													<TableCell>{row.name}</TableCell>
													<TableCell>{row.email}</TableCell>
													<TableCell>{row.status}</TableCell>
													<TableCell>
														{row.perfil?.name ? row.perfil?.name : "-"}
													</TableCell>
													<TableCell>
														<Stack
															spacing={2}
															direction="row"
															flex={1}
															justifyContent="flex-end"
														>
															{/* <IconButton
														onClick={() =>
															onRequestOpenModalUpdatePassword(row.id)
														}
													>
														<LockIcon />
													</IconButton> */}
															<IconButton
																aria-label="edit"
																onClick={() => handleOpenModalEdit(row)}
															>
																<EditIcon />
															</IconButton>
															{row.deleted_at ? (
																<IconButton
																	aria-label="restore"
																	onClick={() => handleRestore(row)}
																>
																	<RestoreIcon />
																</IconButton>
															) : (
																<IconButton
																	aria-label="delete"
																	onClick={() => handleDeleteUser(row)}
																>
																	<DeleteIcon />
																</IconButton>

															)}
														</Stack>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								)}
							</TableContainer>

							<EditPassword
								open={isOpenModalUpdatePassword}
								handleClose={() => onRequestCloseModalUpdatePassword()}
								onEdit={() => {
									enqueueSnackbar("Senha editada com sucesso!", { variant: "success" });
									onRequestCloseModalUpdatePassword();
								}}
								userId={userId}
							/>

							<EditPassword
								open={isOpenModalUpdatePassword}
								handleClose={() => onRequestCloseModalUpdatePassword()}
								onEdit={() => {
									enqueueSnackbar("Senha editada com sucesso!", { variant: "success" });
									onRequestCloseModalUpdatePassword();
								}}
								userId={userId}
							/>

							{userToEdit && (
								<EditDialog
									open={openEdit}
									handleClose={() => {
										setOpenEdit(false);
										setUserToEdit(null);
									}}
									data={userToEdit}
									onEdit={() => {
										enqueueSnackbar("Usuário editado com sucesso!", { variant: "success" });
										refetch();
										setOpenEdit(false);
										setUserToEdit(null);
									}}
								/>
							)}

							<DeleteDialog
								open={showDelete}
								handleClose={() => setShowDelete(null)}
								data={userToDeleted}
								onDelete={() => {
									enqueueSnackbar("Usuário excluído com sucesso!", { variant: "success" });
									refetch();
									setShowDelete(false);
								}}
							/>

							<RestoreDialog
								open={showRestored}
								handleClose={() => setShowRestored(false)}
								onRestore={() => {
									enqueueSnackbar("Usuário restaurado com sucesso!", {
										variant: "success",
									});
									refetch();
									setShowRestored(false);
								}}
								data={userRestored}
							/>

							<CreateUserDialog
								open={showCreate}
								handleClose={() => setShowCreate(false)}
								onCreate={() => {
									enqueueSnackbar("Cadastro realizado com sucesso!", {
										variant: "success",
									});
									refetch();
									setShowCreate(false);
								}}
							/>

							<Fab
								color="primary"
								onClick={() => setShowCreate(true)}
								style={{ position: "fixed", bottom: 48, right: 48 }}
							>
								<AddIcon />
							</Fab>
							{data?.fetchUsers?.total !== 0 &&
								(media500 ? (
									<TablePagination
										sx={{
											background: "#fff",
										}}
										component="div"
										labelRowsPerPage="Linhas por página:"
										rowsPerPageOptions={[5, 15, 30, 60, 100]}
										count={data?.fetchUsers?.total}
										page={currentPage}
										onPageChange={(event, newPage) => {
											setCurrentPage(newPage);
										}}
										rowsPerPage={rowsPerPage}
										onRowsPerPageChange={(event) => {
											setRowsPerPage(parseInt(event.target.value));
											setCurrentPage(0);
										}}
									/>
								) : (
									<Box
										sx={{
											position: "fixed",
											bottom: "30px",
											left: "50%",
											transform: "translateX(-50%)",
											padding: "0px",
											backgroundColor: "rgba(25, 118, 210, 0.98)",
											boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
											opacity: showPagination ? 0.95 : 0,
											zIndex: 2,
											borderRadius: "10px",
											transition: "opacity 0.3s ease-in-out",
										}}
									>
										<PaginationStorage
											count={data?.fetchUsers?.total}
											rowsPerPage={rowsPerPage}
											page={currentPage}
											onPageChange={(event, newPage) => {
												setCurrentPage(newPage);
											}}
											onRowsPerPageChange={(event) => {
												setRowsPerPage(parseInt(event.target.value));
												setCurrentPage(0);
											}}
										/>
									</Box>
								))}
						</Item>
					</div>
				</Container>
			)}
		</Box>
	);
}
