import { useQuery } from "@apollo/client";
import { ModalLayout } from "components/ModalLayout";
import { SEARCH_CUSTOM_DATA } from "graphql/queries/searchCustomData";
import { ModalLayoutProps } from "types/ModalLayout";
import GenericInput from "components/Input/GenericInput";
import { useState } from "react";
import IconButton from "components/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {
	Stack,
	Box,
	FormControl,
	RadioGroup,
	Radio,
	Grid,
} from "@mui/material";
import { SearchCustomData } from "types/graphql/queries/searchCustomData";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "components/CircularProgress";
import { Pagination } from "components/Pagination";
import { EmptyMessage } from "components/EmptyMessage";
import useDebounce from "hooks/useDebounce";

type ModalVisualizationProps = ModalLayoutProps & {
	handleSelect: (value: string) => void;
	defaultKeyword?: string;
};

export function ModalSearchContributor({
	open,
	handleClose,
	handleSelect,
	defaultKeyword = "",
}: ModalVisualizationProps) {
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState(defaultKeyword);

	const [selected, setSelected] = useState<string | null>(null);

	const debouncedValue = useDebounce(search, 500);

	const { data: searchData, loading: loadingSearch } =
		useQuery<SearchCustomData>(SEARCH_CUSTOM_DATA, {
			variables: {
				type: "Contribuinte",
				keyword: debouncedValue,
				page: page,
			},
		});

	function handleSelectRealty() {
		handleSelect(selected);
		handleClose();
	}

	return (
		<ModalLayout
			title="Buscar pessoa"
			open={open}
			handleClose={handleClose}
			buttonText="Selecionar"
			handleSubmit={() => handleSelectRealty()}
		>
			<Box marginTop={2}>
				<GenericInput
					endAdornment={
						<IconButton>
							<SearchIcon />
						</IconButton>
					}
					placeholder="Pesquisar"
					value={search}
					onChange={(e) => {
						setPage(1);
						setSearch(e.target.value);
					}}
				/>
				<Grid container spacing={2} marginTop={2}>
					{loadingSearch && (
						<Box flex={1} alignItems="center" justifyContent="center">
							<CircularProgress />
						</Box>
					)}
					{searchData && searchData.searchCustomData.data.length === 0 && (
						<EmptyMessage message="Nenhum Contribuinte encontrado" />
					)}
					{searchData && (
						<FormControl>
							<RadioGroup row aria-labelledby={"realty"} name={"realty"}>
								{searchData.searchCustomData.data.map((item) => {
									const parsedValue = JSON.parse(item.value);
									return (
										<Grid
											key={item.id}
											display="flex"
											flex={1}
											item
											xs={12}
											marginTop={3}
											alignItems="center"
										>
											<Radio
												onChange={() => setSelected(item.value)}
												value={selected}
												checked={selected === item.value}
												sx={{
													height: 40,
												}}
											/>
											<Stack
												marginRight={2}
												flex={1}
												spacing={3}
												direction="row"
												justifyContent="space-between"
											>
												<Box width={200}>
													<Box>
														<Typography variant="caption">
															Nome:{" "}
															<Typography variant="subtitle2">
																{parsedValue.NOME}
															</Typography>
														</Typography>
													</Box>
													<Box>
														<Typography variant="caption">
															CGCCPF:
															<Typography variant="subtitle2">
																{" "}
																{parsedValue.CGCCPF ?? "-"}
															</Typography>
														</Typography>
													</Box>
												</Box>
												<Box display="flex" columnGap={2} flex={1}>
													<Box>
														<Typography variant="caption">
															Logradouro:{" "}
															<Typography variant="subtitle2">
																{parsedValue.LOGRADOURO}
															</Typography>
														</Typography>
													</Box>
													<Box>
														<Typography variant="caption">
															Bairro:{" "}
															<Typography variant="subtitle2">
																{parsedValue.BAIRRO}
															</Typography>
														</Typography>
													</Box>
													<Box>
														<Typography variant="caption">
															Cep:{" "}
															<Typography variant="subtitle2">
																{parsedValue.CEP}
															</Typography>
														</Typography>
													</Box>
												</Box>
											</Stack>
										</Grid>
									);
								})}
							</RadioGroup>
						</FormControl>
					)}
					<Grid item xs={12}>
						{searchData && searchData.searchCustomData.total > 15 && (
							<Pagination
								count={searchData.searchCustomData.last_page}
								onChange={(event, value) => {
									setPage(value);
								}}
								page={page}
							/>
						)}
					</Grid>
				</Grid>
			</Box>
		</ModalLayout>
	);
}
