/* eslint-disable prefer-const */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import { ModalLayout } from "components/ModalLayout";
import { ModalLayoutProps } from "types/ModalLayout";
import { FormProvider, useForm } from "react-hook-form";
import useStep from "hooks/useStep";
import { Steps } from "components/Steps";
import { StepSubject } from "./StepSubject";
import { StepRegulatoryActs } from "./StepRegulatoryActs";
import { StepDynamicForm } from "./StepDynamicForm";
import { useMutation } from "@apollo/client";
import { CREATE_PROTOCOL } from "graphql/mutations/createProtocol";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { convertToHtml } from "utils/convertTextToHtml";
import StepLocation from "./StepLocation";
import { StepFiles } from "./StepFiles";

type ModalCreateProtocolProps = ModalLayoutProps & {
	refetch: () => void;
};

export type formData = {
	subject: string | number;
	regulatoryActs: Array<{
		checked?: boolean;
		id: number;
		name: string;
	}>;
	dynamicForm?: {
		code: string;
		groups?: Array<{
			title: string;
			inputs: Array<{
				label: string;
				name: string;
				options?: Array<{
					id: number;
					name: string;
				}>;
				placeholder: string;
				required: boolean;
				type: string;
			}>;
		}>;
		id: number;
		name: string;
		version: number;
	};
};

export function ModalCreateProtocol({
	open,
	handleClose,
	refetch,
}: ModalCreateProtocolProps) {
	const navigate = useNavigate();
	const [createProtocol, { loading }] = useMutation(CREATE_PROTOCOL);
	const { enqueueSnackbar } = useSnackbar();
	const methods = useForm({
		defaultValues: {
			subject: null as formData["subject"],
			regulatoryActs: [] as formData["regulatoryActs"],
			dynamicForm: null as formData["dynamicForm"],
			location: {},
			files: [],
		},
	});
	const { handleSubmit, watch } = methods;

	const onSubmit = async (data: any) => {
		let findInputTextArea = [];

		data.dynamicForm
			? data.dynamicForm.groups.forEach((group, index) => {
				const findInput = group.inputs.find(
					(input) => input.type === "textarea"
				);
				if (findInput) {
					findInputTextArea.push(index);
				}
			})
			: null;

		const nameInputTextArea =
			data.dynamicForm && findInputTextArea.length > 0
				? findInputTextArea.map(
					(value) =>
						data.dynamicForm.groups[value].inputs.find(
							(input) => input.type === "textarea"
						)?.name
				)
				: null;

		const dataForm = {
			subject_id: data.subject,
			group_id: data.group ? data.group : null,
			regulatory_acts: data.regulatoryActs
				? data.regulatoryActs.filter((act) => act.checked).map((act) => act.id)
				: null,
			dynamic_form_response: data.groups
				? JSON.stringify(
					data.groups.map((group, index) => {
						const findIndexOfInputTextArea = findInputTextArea.indexOf(index);
						if (
							findIndexOfInputTextArea >= 0 &&
							findInputTextArea.length > 0 &&
							nameInputTextArea
						) {
							return {
								...group.fields,
								[nameInputTextArea[findIndexOfInputTextArea]]: convertToHtml(
									group.fields[nameInputTextArea[findIndexOfInputTextArea]]
								),
							};
						}
						return group.fields;
					})
				)
				: null,
			dynamic_form_version: data.dynamic_form_version || null,
			files: data.files,
		};

		try {
			const { data } = await createProtocol({
				variables: dataForm,
			});
			refetch();
			enqueueSnackbar("Protocolo cadastrado com sucesso", {
				variant: "success",
			});
			navigate(`/protocolo/${data.createProtocol}`);
		} catch (err) {
			enqueueSnackbar(err.message, {
				variant: "error",
			});
		}
	};

	const subject = watch("subject");
	const regulatoryActs = watch("regulatoryActs");

	const steps =
		regulatoryActs.length > 0 && subject
			? [
				{
					label: "Selecione os assuntos",
					component: StepSubject,
				},
				{
					label: "Selecione os atos regulatórios",
					component: StepRegulatoryActs,
				},
				{
					label: "Localização",
					component: StepLocation,
				},
				{
					label: "Formulário",
					component: StepDynamicForm,
				},
				{
					label: "Anexos",
					component: StepFiles,
				},
			]
			: [
				{
					label: "Selecione o assunto",
					component: StepSubject,
				},
				{
					label: "Localização",
					component: StepLocation,
				},
				{
					label: "Formulário",
					component: StepDynamicForm,
				},
				{
					label: "Anexos",
					component: StepFiles,
				},
			];

	const [currentStep, helpers] = useStep(steps.length);
	const { goToNextStep, goToPrevStep } = helpers;

	const lastStep = currentStep === steps.length - 1;
	return (
		<FormProvider {...methods}>
			<ModalLayout
				open={open}
				handleClose={handleClose}
				handleSubmit={handleSubmit(onSubmit)}
				title="Criar Protocolo"
				isLoading={loading}
				isDisabled={!lastStep}
				modalConfirm={true}
			>
				<Steps
					steps={steps}
					currentStep={currentStep}
					goToNextStep={goToNextStep}
					goToPrevStep={goToPrevStep}
					isCreateProtocol
				/>
			</ModalLayout>
		</FormProvider>
	);
}
