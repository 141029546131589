import { Chip, ChipProps as ChipsPropsMaterial } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "components/IconButton";

type ChipProps = ChipsPropsMaterial & {
	theme?: string;
	textColor?: string;
	isDelete?: boolean;
	isEditable?: boolean;
	handleDelete?: () => void;
	handleEdit?: () => void;
};

export function Chips({
	label,
	color,
	theme,
	textColor,
	isDelete,
	isEditable,
	handleDelete,
	handleEdit,
	...rest
}: ChipProps) {
	return (
		<Chip
			size="small"
			icon={
				isEditable ? (
					<IconButton onClick={handleEdit}>
						<EditIcon />
					</IconButton>
				) : undefined
			}
			label={label}
			color={color}
			{...rest}
			sx={{
				backgroundColor: theme,
				color: textColor ?? "primary.contrastText",
				"& .MuiChip-icon": {
					marginRight: 1,
				},
				"& .MuiChip-label": {
					order: isEditable ? -1 : 0,
				},
			}}
			onDelete={isDelete ? () => handleDelete() : undefined}
			component={isEditable ? "button" : "div"}
		/>
	);
}
