import { Box, Typography, Stack, Paper } from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Fragment } from "react";
import { titles } from "../../consts";

export function TitleAndValues() {
	return (
		<>
			<Box mt="20px" mb="10px">
				<Typography fontSize="18px" fontWeight={400} lineHeight={"24px"}>
					Títulos e Valores
				</Typography>
			</Box>
			<Stack direction="row" spacing={1}>
				{titles.map((title, index) => (
					<Droppable
						key={title.id}
						droppableId={`${title.id.toString()}`}
						type="title"
					>
						{(droppableProvided) => (
							<Paper
								sx={{
									backgroundColor: "#F4F4f4",
								}}
								ref={droppableProvided.innerRef}
								{...droppableProvided.droppableProps}
							>
								<Draggable draggableId={`${title.id.toString()}`} index={index}>
									{(draggableProvided) => (
										<Stack
											sx={{
												p: "12px",
											}}
											ref={draggableProvided.innerRef}
											{...draggableProvided.draggableProps}
											{...draggableProvided.dragHandleProps}
											flexDirection={
												title.reverse ? "column-reverse" : "column"
											}
										>
											<>
												<Typography
													textAlign={title.textAlign}
													fontSize="32px"
													fontWeight={700}
													lineHeight={"38px"}
													width={"100%"}
												>
													000
												</Typography>
												<Typography
													fontSize="16px"
													lineHeight={"24px"}
													letterSpacing={"0.5px"}
													textAlign={title.textAlign}
													width={"100%"}
												>
													Título
												</Typography>
											</>
										</Stack>
									)}
								</Draggable>
							</Paper>
						)}
					</Droppable>
				))}
			</Stack>
		</>
	);
}
