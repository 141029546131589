import { gql } from "graphql-tag";

export const PRINT_PROTOCOL = gql`
  query (
    $code: String!
    $with_images: Boolean
    $is_complaint: Boolean
    ) {
    printProtocol(
      code: $code
      with_images: $with_images
      is_complaint: $is_complaint
      )
  }
`;