/* eslint-disable no-mixed-spaces-and-tabs */
import {
	Box,
	Link,
	List,
	ListItem,
	Stack,
	Typography,
	Divider,
	CircularProgress,
} from "@mui/material";
import GenericInput from "components/Input/GenericInput";
import { ModalLayout } from "components/ModalLayout";
import SearchIcon from "@mui/icons-material/Search";

import { FetchProtocols } from "types/graphql/queries/fetchProtocols";
import { FETCH_PROTOCOLS } from "graphql/queries/fetchProtocols";
import { useQuery, useMutation } from "@apollo/client";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Fragment, useEffect, useState } from "react";
import { format } from "date-fns";
import useDebounce from "hooks/useDebounce";
import { ADD_RELATED_PROTOCOL } from "graphql/mutations/addRelatedProtocol";
import { useSnackbar } from "notistack";
import { DetailProtocol } from "types/graphql/queries/detailProtocol";
import { DataRelatedProtocol } from "types/graphql/queries/fetchRelatedProtocols";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";

interface ModalFilterProtocolsProps {
	isOpen: boolean;
	onClose: () => void;
	protocols: DetailProtocol["detailProtocol"];
	refetch: () => void;
	relatedProtocols: Array<DataRelatedProtocol>;
	protocol_code: string;
}
export default function ModalFilterProtocols({
	isOpen,
	onClose,
	protocols,
	refetch,
	relatedProtocols,
	protocol_code
}: ModalFilterProtocolsProps) {
	const [selectedItems, setSelectedItems] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [addRelatedProtocol, { loading }] = useMutation(ADD_RELATED_PROTOCOL);
	const { enqueueSnackbar } = useSnackbar();

	const schema = yup.object().shape({
		subject_id: yup.number().required("Campo obrigatório"),
	});

	const methods = useForm({
		defaultValues: {
			code: protocols.code,
			subject_id: protocols.subject.id,
		},
		resolver: yupResolver(schema),
	});

	const { formState: { errors } } = methods;

	const { data: dataProtocols, loading: loadingDataProtocols } = useQuery<FetchProtocols>(FETCH_PROTOCOLS, {
			fetchPolicy: "network-only",
			variables: {
				subject_ids: [protocols.subject.id],
				street: protocols.street,
			},
		});

	const data = dataProtocols?.fetchProtocols.data;

	useEffect(() => {
		if (relatedProtocols.length > 0 && data) {

			const newItemsSelected = relatedProtocols.filter((protocolRelated) => {
				return data?.some(
					(protocolData) => protocolData.id === protocolRelated.id
				);
			});

			setSelectedItems([...selectedItems, ...newItemsSelected]);
		}
	}, [relatedProtocols, data]);

	const toggleItem = (item) => {
		if (selectedItems.some((selected) => selected.id === item.id)) {
			setSelectedItems(
				selectedItems.filter((selected) => selected.id !== item.id)
			);
		} else {
			setSelectedItems([...selectedItems, item]);
		}
	};

    const dataDebounce = useDebounce(searchTerm, 1000);

	const filteredData = data?.filter(
		(protocol) =>
			protocol.responsible.name.toLowerCase().includes(dataDebounce.toLowerCase()) || 
            protocol.code.includes(dataDebounce)
	);

	const methodsMutation = useForm({
		defaultValues: {
			protocol_code: "",
			related_protocol_codes: "",
		},
		resolver: yupResolver(schema),
	});

	const { reset, handleSubmit } = methodsMutation;

	const onSubmit = async () => {
		const arrayToSubmitRelatedProtocols = [];
		selectedItems.map((items) => arrayToSubmitRelatedProtocols.push(items.code));

		try {
			await addRelatedProtocol({
				variables: {
					protocol_code: protocols.code,
					related_protocol_codes: arrayToSubmitRelatedProtocols
				}
			})
			reset({
				protocol_code: "",
				related_protocol_codes: ""
			});
			refetch();
			onClose();
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
	};


	return (
		<ModalLayout
			open={isOpen}
			handleClose={onClose}
			handleSubmit={onSubmit}
			title="Relacionar Protocolos"
			isDisabled={data?.length <= 1}
			buttonTextClose="Cancelar"
			buttonText={
				selectedItems[0] ? `Relacionar (${selectedItems.length})` : "Relacionar"
			}
		>
			<Box mt={2}>
				<GenericInput
					placeholder="Pesquisar"
					endAdornment={<SearchIcon color="disabled" />}
					onChange={(e) => setSearchTerm(e.target.value)}
					value={searchTerm}
				/>
			</Box>
			<Divider
				variant="inset"
				sx={{
					"&.MuiDivider-inset": {
						margin: "16px 0px 16px",
					},
				}}
			/>
			<Box
				mt={2}
				sx={{
					minHeight: "10vh",
					maxHeight: "60vh",
					overflowY: data?.length > 1 && "scroll",
					display: "flex",
					width: "100%",
					justifyContent: "center",
					alignItems: data?.length <= 1 && "center",
				}}
			>
				<List
					sx={{
						WebkitUserSelect: "none",
						userSelect: "none",
						height: "100%"
					}}
				>
					{loadingDataProtocols ? (
						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<CircularProgress /> 
						</Box>
					) : !filteredData[1] ? (
						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								gap: "5px",
							}}
						>
							<ReportGmailerrorredIcon sx={{ color: "#ccc" }} />
							<span>
								Não existem protocolos para serem relacionados no momento
							</span>
						</Box>
					) : (
						filteredData?.map((protocol, index) => {
							if (protocol.code !== protocol_code) {
								return (
									<Fragment key={index}>
										<ListItem
											onClick={() => toggleItem(protocol)}
											sx={{
												backgroundColor: selectedItems.some(
													(selected) => selected.id === protocol.id
												)
													? "#FBFBF0"
													: "transparent",
											}}
										>
											<Stack
												direction="row"
												justifyContent="space-between"
												flexWrap="wrap"
												width="100%"
												sx={{ cursor: "pointer" }}
											>
												<Box
													display="flex"
													flexDirection="column"
													alignItems="spacebetween"
													width="50%"
												>
													<Box display="flex" flexDirection="column">
														<Link
															variant="subtitle2"
															href={`/protocolo/${protocol.code}`}
														>
															{protocol.code}
														</Link>
														<Typography variant="caption">
															{format(
																new Date(protocol.created_at),
																"dd/MM/yyyy HH:mm"
															)}
														</Typography>
													</Box>
													<Box marginTop={2}>
														<Typography variant="caption">
															Responsável
														</Typography>
														<Typography variant="subtitle2">
															{protocol.responsible
																? protocol.responsible.name
																: " - "}
														</Typography>
													</Box>
												</Box>
												<Box
													display="flex"
													flexDirection="column"
													alignItems="space-between"
													width="50%"
												>
													<Box display="flex" flexDirection="column">
														<Typography variant="caption">Assunto</Typography>
														<Typography variant="subtitle2">
															{protocol.subject ? protocol.subject.name : " - "}
														</Typography>
													</Box>
													<Box marginTop={2}>
														<Typography variant="caption">Endereço</Typography>
														<Typography variant="subtitle2">
															{protocol.street ? protocol.street : null}{" "}
															{protocol.number
																? (protocol.street ? ", " : "") +
																  "nº" +
																  protocol.number
																: null}{" "}
															{protocol.district
																? protocol.district.name
																: null}
															{protocol.district?.city
																? " - " +
																  protocol.district.city.name +
																  "/" +
																  protocol.district.city.state
																: null}
														</Typography>
													</Box>
												</Box>
											</Stack>
										</ListItem>
										{index + 1 < data.length && (
											<Divider
												variant="inset"
												sx={{
													"&.MuiDivider-inset": {
														marginLeft: "0px",
														marginRight: "0px",
													},
												}}
											/>
										)}
									</Fragment>
								);
							}
						})
					)}
				</List>
			</Box>
		</ModalLayout>
	);
}
