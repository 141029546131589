import { gql } from "@apollo/client";

export const CREATE_OCCURRENCE_TYPE = gql`
	mutation createOccurrenceType(
		$name: String!
		$dynamic_form_id: Int!
		$description: String
		$intimationText: String
		$autofill_regulatory_acts: Boolean
	) {
		createOccurrenceType(
			name: $name
			dynamic_form_id: $dynamic_form_id
			description: $description
			intimationText: $intimationText
			autofill_regulatory_acts: $autofill_regulatory_acts
		)
	}
`;
