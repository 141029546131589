import { DatePicker } from "@mui/x-date-pickers";
import "dayjs//locale/pt-br";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField, Grid, FormHelperText, Box } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

interface FieldDataProps {
	name: string;
	label?: string;
	required?: boolean;
	index?: number;
	readonly?: boolean;
}

function FieldDate({ label, name, required, readonly, index }: FieldDataProps) {
	const { control } = useFormContext();

	return (
		<Grid item xs={12}>
			<Controller
				rules={{
					required: required ? "Campo obrigatório" : false,
				}}
				defaultValue={new Date()}
				name={`groups.${index}.fields.${name}`}
				control={control}
				render={(inputProps) => (
					<Box>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale="pt-br"
						>
							<DatePicker
								readOnly={readonly}
								label={label}
								openTo="year"
								views={["year", "month", "day"]}
								value={inputProps.field.value}
								onChange={(newValue) => inputProps.field.onChange(newValue)}
								renderInput={(params) => <TextField {...params} fullWidth />}
							/>
						</LocalizationProvider>

						{inputProps.fieldState.error && (
							<FormHelperText sx={{ color: "error.main" }}>
								{inputProps.fieldState.error.message + ""}
							</FormHelperText>
						)}
					</Box>
				)}
			/>
		</Grid>
	);
}

export default FieldDate;
