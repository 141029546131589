import { FormControl, FormHelperText, Grid, TextField } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { ModalSearchContributor } from "../Modal/ModalSearchContributor";
import { useState } from "react";
import IconButton from "components/IconButton";
import { maskCNPJ, maskCPF } from "utils/maskOutputs";
import SearchIcon from "@mui/icons-material/Search";

interface FieldOffenderDataProps {
	name: string;
	index?: number;
	readonly?: boolean;
}

function FieldApplicantData({ name, index }: FieldOffenderDataProps) {
	const [isOpenModalSearch, setIsOpenModalSearch] = useState(false);
	const { control, setValue } = useFormContext();

	function handleSelectContributor(value: string) {
		const parsedValue = JSON.parse(value);
		if (parsedValue) {
			const {
				BAIRRO,
				CEP,
				CGCCPF,
				LOGRADOURO,
				NOME,
				NUM_RESID,
				EMAIL,
				COMPLEMENTO,
				NOMEBAIRRO,
				TIPOLOGRAD,
			} = parsedValue;
			setValue(`groups.${index}.fields.owner.document`, CGCCPF ?? "");
			setValue(`groups.${index}.fields.owner.name`, NOME ?? "");
			setValue(`groups.${index}.fields.owner.identity`, CGCCPF ?? "");
			setValue(`groups.${index}.fields.owner.email`, EMAIL ?? "");
			setValue(`groups.${index}.fields.owner.address.zipcode`, CEP ?? "");
			setValue(
				`groups.${index}.fields.owner.address.street`,
				LOGRADOURO ? `${TIPOLOGRAD ?? " "} ${LOGRADOURO}` : ""
			);
			setValue(`groups.${index}.fields.owner.address.number`, NUM_RESID ?? "");
			setValue(
				`groups.${index}.fields.owner.address.neighborhood`,
				BAIRRO ?? NOMEBAIRRO ?? ""
			);
			setValue(
				`groups.${index}.fields.owner.address.complement`,
				COMPLEMENTO ?? ""
			);
		}
	}
	return (
		<>
			<Grid item xs={12}>
				<FormControl variant="outlined" fullWidth>
					<Controller
						name={`groups.${index}.fields.owner.proccessNumber`}
						control={control}
						render={(inputProps) => (
							<FormControl variant="outlined" fullWidth>
								<TextField
									id={name}
									type={"text"}
									label="Nº do Processo"
									fullWidth
									value={inputProps.field.value ?? ""}
									onChange={(e) => {
										inputProps.field.onChange(e.target.value);
									}}
									InputLabelProps={{
										shrink: inputProps.field.value ? true : false,
									}}
								/>
							</FormControl>
						)}
					/>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<Controller
					name={`groups.${index}.fields.owner.name`}
					control={control}
					render={(inputProps) => (
						<FormControl
							variant="outlined"
							fullWidth
							error={inputProps.fieldState.error && true}
						>
							<TextField
								id={name}
								type={"text"}
								label="Nome/Razão Social*"
								fullWidth
								value={inputProps.field.value ?? ""}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								error={inputProps.fieldState.error && true}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>
							{inputProps.fieldState.error && (
								<FormHelperText error={inputProps.fieldState.error && true}>
									{inputProps.fieldState.error.message + ""}
								</FormHelperText>
							)}
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Controller
					control={control}
					name={`groups.${index}.fields.owner.document`}
					rules={{
						validate: (value) => {
							const valueRegex = value.replace(/\D/g, "");
							if (valueRegex.length < 11) {
								return "CPF inválido";
							}
							if (valueRegex.length > 11 && valueRegex.length < 14) {
								return "CNPJ inválido";
							}
							if (valueRegex.length > 14) {
								return "CNPJ ou CPF inválido";
							}
						},
					}}
					render={(inputProps) => (
						<>
							<FormControl
								fullWidth
								error={inputProps.fieldState.error && true}
							>
								<TextField
									variant="outlined"
									type="text"
									InputLabelProps={{
										shrink: inputProps.field.value ? true : false,
									}}
									InputProps={{
										endAdornment: (
											<IconButton onClick={() => setIsOpenModalSearch(true)}>
												<SearchIcon />
											</IconButton>
										),
									}}
									label="CPF/CNPJ*"
									fullWidth
									value={inputProps.field.value ?? ""}
									onChange={(e) => {
										const { value } = e.target;
										const valueReplace = value.replace(/\D/g, "");
										inputProps.field.onChange(valueReplace);
									}}
									error={inputProps.fieldState.error && true}
									onBlur={(e) => {
										const { value } = e.target;
										const valueReplace = value.replace(/\D/g, "");
										if (valueReplace.length >= 14) {
											const text = valueReplace;
											const cnpj = maskCNPJ(text);

											e.target.value = cnpj;
											inputProps.field.onChange(e.target.value);
											return;
										}
										if (valueReplace.length >= 11) {
											const text = valueReplace;
											const cpf = maskCPF(text);

											e.target.value = cpf;
											inputProps.field.onChange(e.target.value);
											return;
										}
									}}
								/>
								{inputProps.fieldState.error && (
									<FormHelperText error={inputProps.fieldState.error && true}>
										{inputProps.fieldState.error.message + ""}
									</FormHelperText>
								)}
							</FormControl>
							{isOpenModalSearch && (
								<ModalSearchContributor
									open={isOpenModalSearch}
									handleClose={() => setIsOpenModalSearch(false)}
									handleSelect={(values) => handleSelectContributor(values)}
									defaultKeyword={inputProps.field.value}
								/>
							)}
						</>
					)}
				/>
			</Grid>

			<Grid item xs={12}>
				<FormControl variant="outlined" fullWidth>
					<Controller
						name={`groups.${index}.fields.owner.address.zipcode`}
						control={control}
						render={(inputProps) => (
							<FormControl variant="outlined" fullWidth>
								<TextField
									id={name}
									type={"text"}
									label="CEP"
									fullWidth
									value={inputProps.field.value ?? ""}
									onChange={(e) => {
										inputProps.field.onChange(e.target.value);
									}}
									InputLabelProps={{
										shrink: inputProps.field.value ? true : false,
									}}
								/>
							</FormControl>
						)}
					/>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<FormControl variant="outlined" fullWidth>
					<Controller
						name={`groups.${index}.fields.owner.address.street`}
						control={control}
						render={(inputProps) => (
							<FormControl variant="outlined" fullWidth>
								<TextField
									id={name}
									type={"text"}
									label="Rua"
									fullWidth
									value={inputProps.field.value ?? ""}
									onChange={(e) => {
										inputProps.field.onChange(e.target.value);
									}}
									InputLabelProps={{
										shrink: inputProps.field.value ? true : false,
									}}
								/>
							</FormControl>
						)}
					/>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<Controller
					name={`groups.${index}.fields.owner.address.number`}
					control={control}
					rules={{
						pattern: {
							value: /^[0-9]+$/,
							message: "Digite apenas números",
						},
					}}
					render={(inputProps) => (
						<FormControl
							variant="outlined"
							fullWidth
							error={inputProps.fieldState.error && true}
						>
							<TextField
								id={name}
								type={"text"}
								label="Número"
								fullWidth
								value={inputProps.field.value ?? ""}
								error={inputProps.fieldState.error && true}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>
							{inputProps.fieldState.error && (
								<FormHelperText error={inputProps.fieldState.error && true}>
									{inputProps.fieldState.error.message + ""}
								</FormHelperText>
							)}
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormControl variant="outlined" fullWidth>
					<Controller
						name={`groups.${index}.fields.owner.address.neighborhood`}
						control={control}
						render={(inputProps) => (
							<FormControl variant="outlined" fullWidth>
								<TextField
									id={name}
									type={"text"}
									label="Bairro"
									fullWidth
									value={inputProps.field.value ?? ""}
									onChange={(e) => {
										inputProps.field.onChange(e.target.value);
									}}
									InputLabelProps={{
										shrink: inputProps.field.value ? true : false,
									}}
								/>
							</FormControl>
						)}
					/>
				</FormControl>
			</Grid>
			<Grid item xs={12} marginBottom={2}>
				<FormControl variant="outlined" fullWidth>
					<Controller
						name={`groups.${index}.fields.owner.address.complement`}
						control={control}
						render={(inputProps) => (
							<FormControl variant="outlined" fullWidth>
								<TextField
									id={name}
									type={"text"}
									label="Complemento"
									fullWidth
									value={inputProps.field.value ?? ""}
									onChange={(e) => {
										inputProps.field.onChange(e.target.value);
									}}
									InputLabelProps={{
										shrink: inputProps.field.value ? true : false,
									}}
								/>
							</FormControl>
						)}
					/>
				</FormControl>
			</Grid>
		</>
	);
}

export default FieldApplicantData;
