import { Checkbox } from "components/Checkbox";
import { Grid, Typography } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

interface FieldCheckBoxProps {
	label: string;
	name: string;
	index?: number;
	options?: Array<{
		label?: string;
		value: string | number;
	}>;
	readonly?: boolean;
}

function FieldCheckBox({
	options,
	label,
	name,
	index,
	readonly,
}: FieldCheckBoxProps) {
	const { control } = useFormContext();
	return (
		<Grid item xs={12}>
			<Typography variant="h6" marginBottom={1}>
				{label}
			</Typography>
			{options.map((option, indexOption) => (
				<Controller
					key={option.value}
					name={`groups.${index}.fields.${name}.${indexOption}`}
					control={control}
					render={(inputProps) => (
						<Checkbox
							readOnly={readonly}
							label={option.label}
							onChange={(e) => inputProps.field.onChange(e.target.value)}
							value={option.value}
						/>
					)}
				/>
			))}
		</Grid>
	);
}

export default FieldCheckBox;
