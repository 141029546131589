import { Animation } from "@devexpress/dx-react-chart";
import {
	Chart,
	PieSeries,
	Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { Grid, Typography } from "@mui/material";

interface VariantDoughnutProps {
	chartData: Array<{
		key: string;
		value: number;
	}>;
	title: string;
	label: string;
	medias?: { [key: string]: boolean };
}

const Root = (props: Legend.RootProps) => <Grid container {...props} />;
const Label = (props: Legend.LabelProps) => (
	<Legend.Label sx={{ pt: 1, whiteSpace: "nowrap" }} {...props} />
);
const Item = (props: Legend.ItemProps) => (
	<Grid item xs>
		<Legend.Item {...props} sx={{ p: 0 }} />
	</Grid>
);

export function VariantDoughnut({
	chartData,
	title,
	label = "",
	medias
}: VariantDoughnutProps) {
	return (
		<>
			<Typography variant="h6">{title}</Typography>
			<Typography variant="caption">{label}</Typography>
			<Chart data={chartData} height={300}>
				<PieSeries valueField="value" argumentField="key" innerRadius={0.6} />
				<Legend
					position="bottom"
					rootComponent={Root}
					itemComponent={Item}
					labelComponent={Label}
				/>
				<Animation />
			</Chart>
		</>
	);
}
