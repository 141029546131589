import { gql } from "@apollo/client";

export const UPDATE_SUBJECT = gql`
	mutation updateSubject(
		$id: Int!
		$name: String!
		$status: String!
		$type: String!
		$flow_id: Int!
		$group_ids: [Int]!
		$regulatory_act_ids: [Int]
		$can_create_service_order: Boolean!
	) {
		updateSubject(
			id: $id
			name: $name
			status: $status
			type: $type
			flow_id: $flow_id
			group_ids: $group_ids
			regulatory_act_ids: $regulatory_act_ids
			can_create_service_order: $can_create_service_order
		)
	}
`;
