/* eslint-disable no-mixed-spaces-and-tabs */
import {
	Dialog,
	DialogActions,
	DialogTitle,
	Button,
	Stack,
	Typography,
	Grid,
	Box,
	Link,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { useState } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Conditional } from "./ComponentsResolveTask/Conditional";
import { Assignment } from "./ComponentsResolveTask/Assignment";
import { Occurrence } from "./ComponentsResolveTask/Occurrence";
import React from "react";
import { useSnackbar } from "notistack";
import IconButton from "components/IconButton";
import { useMutation } from "@apollo/client";
import { COMPLETE_TASK } from "graphql/mutations/completeTask";
import LoadingButton from "components/LoadingButton";
import { convertToHtml } from "utils/convertTextToHtml";
import { DetailProtocol, Task } from "types/graphql/queries/detailProtocol";

type ModalTaskResolveProps = {
	open: boolean;
	handleClose: () => void;
	tasks: Task;
	refetch: () => void;
	subject?: DetailProtocol["detailProtocol"]["subject"];
	formResponseOccurrence?: DetailProtocol["detailProtocol"]["tasks"][0]["detail"]["form_response"];
	auto_finish_on_complaint_refused?: boolean;
};

export function ModalTaskResolve({
	open,
	handleClose,
	tasks,
	refetch,
	subject,
	formResponseOccurrence,
	auto_finish_on_complaint_refused,
}: ModalTaskResolveProps) {
	const [completeTask, { loading }] = useMutation(COMPLETE_TASK);
	const { enqueueSnackbar } = useSnackbar();
	const methods = useForm();
	const [filesAttached, setFilesAttached] = useState(false);

	const renderComponentType = {
		Condicional: (
			<Conditional
				options={tasks.options}
				auto_finish_on_complaint_refused={auto_finish_on_complaint_refused}
			/>
		),
		Tarefa: <Assignment required={tasks.required_description} />,
		Ocorrência: (
			<Occurrence
				formResponse={formResponseOccurrence}
				form={tasks.detail.form_groups}
				subject={subject}
			/>
		),
	};


	const { handleSubmit, setValue, watch } = methods;

	const files = watch("files", []);

	const handleAddFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFilesAttached(true);
		const newFiles = e.target.files;
		const filesArray = Array.from(newFiles);
		if (
			files &&
			filesArray.filter((fileArray) =>
				files.find((file) => file.name === fileArray.name)
			).length > 0
		) {
			enqueueSnackbar("Arquivo já adicionado", { variant: "error" });
			return;
		}
		const concatedFiles = files ? [...files, ...filesArray] : filesArray;

		setValue("files", concatedFiles);
	};

	const handleDeleteFile = (file: File) => {
		const newFiles = files.filter((f) => f.name !== file.name);

		if (newFiles.length === 0) {
			setFilesAttached(false);
		}

		setValue("files", newFiles);
	};

	const filePreview = (file: File) => {
		const fileURL = URL.createObjectURL(file);
		return (
			<Box>
				<Link
					variant="h6"
					fontSize="1rem"
					target="_blank"
					rel="norefferer"
					href={fileURL}
				>
					{file.name}
				</Link>
			</Box>
		);
	};

	const onSubmit = async (data: any) => {
		const formGroups = tasks.detail.form_groups
			? JSON.parse(tasks.detail.form_groups)
			: null;

		const dataNotFiles = Object.keys(data).filter((key) => key !== "files");

		const dataFromKeys = dataNotFiles.reduce((acc, key) => {
			acc[key] = data[key];
			return acc;
		}, {} as any);

		const findInputTextArea = formGroups
			? formGroups.findIndex((group) => {
				return group?.inputs.find((input) => input.type === "textarea");
			})
			: null;

		const nameInputTextArea = formGroups
			? formGroups[findInputTextArea]?.inputs.find(
				(input) => input.type === "textarea"
			)?.name
			: null;

		let answer = null;
		if (tasks.type === "Condicional") {
			answer = data.option;
		} else if (tasks.type === "Tarefa") {
			answer = data.description;
		}

		const formData = {
			id: tasks.id,
			dynamic_form_response:
				tasks.type === "Condicional" || tasks.type === "Tarefa"
					? null
					: dataFromKeys.groups
						? JSON.stringify(
							dataFromKeys.groups.map((group, index) => {
								if (
									findInputTextArea >= 0 &&
									index === findInputTextArea &&
									nameInputTextArea
								) {
									return {
										...group.fields,
										[nameInputTextArea]: convertToHtml(
											group.fields[nameInputTextArea]
										),
									};
								}
								return group.fields;
							})
						)
						: null,
			answer: answer,
			description:
				data.description && tasks.type === "Condicional"
					? data.description
					: null,
			files: data.files ? data.files : [],
			regulatory_act_ids: data.regulatoryActs
				? data.regulatoryActs
					.filter((act) => act.checked)
					.map((act) => act.checked.id)
				: null,
		};

		try {
			if (tasks.concluded_at === null && tasks.advance_next_task === true) {
				const formDataWithOldTask = {
					...formData,
					old_task: true,
				};
				await completeTask({
					variables: formDataWithOldTask,
				});
			} else {
				await completeTask({
					variables: formData,
				});
			}
			enqueueSnackbar("Tarefa completada com sucesso", { variant: "success" });
			refetch();
			handleClose();
		} catch (err) {
			enqueueSnackbar(err.message, {
				variant: "error",
			});
		}
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="paper"
				maxWidth="md"
				fullWidth={true}
				sx={{
					lineHeight: "150%",
				}}
			>
				<FormProvider {...methods}>
					<DialogTitle id="scroll-dialog-title">Resolver tarefa</DialogTitle>

					<Stack
						sx={{
							height: "100%",
							color: "rgba(0, 0, 0, 0.87)",
							lineHeight: "150%",
							marginX: "25px",
							marginBottom: "20px",
						}}
					>
						<Stack
							sx={{
								display: "flex",
								flexDirection: "row",
								marginBottom: "15px",
							}}
						>
							<Stack sx={{ marginRight: 12 }}>
								<Typography variant="caption">Tipo</Typography>
								<Typography variant="subtitle2">{tasks.type}</Typography>
							</Stack>
							<Stack>
								<Typography variant="caption">Nome da tarefa</Typography>
								<Typography variant="subtitle2">{tasks.name}</Typography>
							</Stack>
							<Stack sx={{ marginLeft: "400px" }}>
								<Typography variant="caption">Prazo</Typography>
								<Typography variant="subtitle2">
									Até {tasks.expire_at}
								</Typography>
							</Stack>
						</Stack>

						<Grid container spacing={1}>
							{renderComponentType[tasks.type]}

							<Grid item xs={12}>
								{tasks.required_attachment === true ? (
									<Typography variant="caption">Anexos *</Typography>
								) : (
									<Typography variant="caption">Anexos</Typography>
								)}
								<Stack spacing={2} marginTop={2}>
									{files && files.length > 0 ? (
										files.map((file: File) => (
											<Box
												display="flex"
												columnGap={2}
												alignItems="center"
												key={file.name}
											>
												<Box>
													<AttachFileIcon />
												</Box>
												{filePreview(file)}
												<Box>
													<IconButton onClick={() => handleDeleteFile(file)}>
														<DeleteOutlineIcon color="error" />
													</IconButton>
												</Box>
											</Box>
										))
									) : (
										<Typography variant="subtitle2">
											Nenhum arquivo anexado
										</Typography>
									)}
								</Stack>
							</Grid>
						</Grid>

						<DialogActions
							sx={{ marginTop: "30px", justifyContent: "start", columnGap: 2 }}
						>
							{tasks.required_attachment === true ? (
								<LoadingButton
									disabled={filesAttached === false}
									variant="contained"
									onClick={handleSubmit(onSubmit)}
									loading={loading}
								>
									CONCLUIR TAREFA
								</LoadingButton>
							) : (
								<LoadingButton
									variant="contained"
									onClick={handleSubmit(onSubmit)}
									loading={loading}
								>
									CONCLUIR TAREFA
								</LoadingButton>
							)}
							<Button
								color="primary"
								aria-label="upload picture"
								component="label"
								endIcon={
									<UploadFileIcon
										sx={{ color: "rgba(0, 0, 0, 0.54)", height: "20px" }}
									/>
								}
								variant="outlined"
							>
								ANEXAR ARQUIVOS
								<input
									hidden
									accept="image/*, application/pdf"
									type="file"
									name="Logo"
									id="logo"
									multiple
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										handleAddFiles(e)
									}
								/>
							</Button>
						</DialogActions>
					</Stack>
				</FormProvider>
			</Dialog>
		</>
	);
}
