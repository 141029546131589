import { gql } from "@apollo/client";

export const CREATE_USER = gql`
	mutation createUser(
		$perfil_id: Int
		$group_ids: [Int]
		$name: String!
		$email: String!
		$password: String!
		$is_point_accumulator: Boolean!
		$is_mobile_user: Boolean!
	) {
		createUser(
			perfil_id: $perfil_id
			group_ids: $group_ids
			name: $name
			email: $email
			password: $password
			is_point_accumulator: $is_point_accumulator
			is_mobile_user: $is_mobile_user
		)
	}
`;
