import { Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ModalLayout } from "components/ModalLayout";
import { ModalLayoutProps } from "types/ModalLayout";
import { useState } from "react";
import { format } from "date-fns";
import SelectFilterModal from "components/selectFilterModal";
import { FETCH_USERS } from "graphql/queries/fetchUsers";
import SearchIcon from "@mui/icons-material/Search";
import GenericInput from "components/Input/GenericInput";

type ModalFilterProps = ModalLayoutProps & {
	handleFilter: (values) => void;
	keyword: string;
};

export function ModalFilter({
	open,
	handleClose,
	handleFilter,
	keyword,
}: ModalFilterProps) {
	const arrResponsible = [
		{
			id: 0,
			title: "Responsável",
			options: [],
		},
	];
	const [search, setSearch] = useState(keyword);
	const [year, setYear] = useState<Date | null>(null);
	const [month, setMonth] = useState<Date | null>(null);
	const [situation, setSituation] = useState(arrResponsible);

	const handleClear = () => {
		setYear(null);
		setMonth(null);
		setSituation(arrResponsible);
		setSearch("");
	};

	const handleAddedSituation = (values) => {
		const title = values.find((item) => item.title).title;
		const index = situation.findIndex((item) => item.title === title);
		const newSituations = [...situation];
		newSituations[index].options = values.filter((item) => !item.title);
		setSituation(newSituations);
	};


	const onRequestFilter = () => {
		const filter = {
			year: year ? format(new Date(year), "yyyy") : null,
			month: month ? format(new Date(month), "MM") : null,
			responsibles: situation[0].options,
			keyword: search || "",
		};

		handleFilter(filter);
	};

	return (
		<ModalLayout
			open={open}
			handleClose={handleClose}
			additionalFuntion={handleClear}
			title="Filtrar pontuação"
			buttonTextClose="Limpar filtros"
			buttonText="Filtrar"
			handleSubmit={onRequestFilter}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} marginTop={2}>
					<GenericInput
						placeholder="Pesquisar"
						endAdornment={<SearchIcon color="disabled" />}
						onChange={(e) => setSearch(e.target.value)}
						value={search}
						fullWidth
					/>
				</Grid>

				<Grid item xs={12} sm={6}>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale="pt-br"
					>
						<DatePicker
							disableFuture
							views={["year"]}
							inputFormat="YYYY"
							label="Ano"
							value={year}
							onChange={(newValue) => {
								setYear(newValue);
							}}
							renderInput={(params) => (
								<TextField {...params} sx={{ width: "100%" }} />
							)}
						/>
					</LocalizationProvider>
				</Grid>

				<Grid item xs={12} sm={6}>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale="pt-br"
					>
						<DatePicker
							disableFuture
							views={["year", "month"]}
							inputFormat="MMMM"
							label="Mês"
							value={month}
							onChange={(newValue) => {
								setMonth(newValue);
							}}
							renderInput={(params) => (
								<TextField {...params} sx={{ width: "100%" }} />
							)}
						/>
					</LocalizationProvider>
				</Grid>

				<Grid item xs={12}>
					<SelectFilterModal
						title="Responsável"
						fullWidth
						onChange={(values) => {
							handleAddedSituation(values);
						}}
						queryName={FETCH_USERS}
						order_by="users.name"
						dataName="fetchUsers"
						valuesSelecteds={situation[0].options.map((item) => ({
							id: item.id,
							name: item.name,
						}))}
					/>
				</Grid>
			</Grid>
		</ModalLayout>
	);
}
