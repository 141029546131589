import { useMutation } from "@apollo/client";
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Stack,
} from "@mui/material";
import { CREATE_REGULATORY_ACT } from "graphql/mutations/createRegulatoryAct";
import { useForm, FormProvider } from "react-hook-form";
import { useSnackbar } from "notistack";
import Input from "components/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

type CreateRegulatoryActDialogProps = {
	open: boolean;
	handleClose: () => void;
	onCreate: () => void;
};

const schema = yup.object().shape({
	name: yup.string().required("Campo obrigatório"),
	description: yup.string(),
});

export default function CreateRegulatoryActsDialog({
	open,
	handleClose,
	onCreate,
}: CreateRegulatoryActDialogProps) {
	const methods = useForm({
		defaultValues: {
			name: "",
			description: "",
		},
		resolver: yupResolver(schema),
	});
	const { enqueueSnackbar } = useSnackbar();

	// Setting mutation
	const [createAct, { loading }] = useMutation(CREATE_REGULATORY_ACT);

	const { reset, handleSubmit } = methods;

	const onSubmit = async (values: any) => {
		try {
			await createAct({
				variables: {
					name: values.name,
					description: values.description,
				},
			});
			onCreate();
			reset({
				name: "",
				description: "",
			});
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="paper"
				maxWidth="sm"
				fullWidth={true}
			>
				<FormProvider {...methods}>
					<DialogTitle id="scroll-dialog-title">
						Criar Atos regulatórios
					</DialogTitle>

					<DialogContent>
						<Stack spacing={2} marginTop={2}>
							<Input name="name" autoFocus placeholder="Nome*" type="text" />
							<Input
								name="description"
								multiline
								rows={3}
								placeholder="descrição"
								type="text"
							/>
						</Stack>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Cancelar
						</Button>
						<Button
							onClick={handleSubmit(onSubmit)}
							variant="contained"
							color="primary"
							disabled={loading}
						>
							{loading && (
								<CircularProgress size={14} style={{ marginRight: 12 }} />
							)}
							Criar
						</Button>
					</DialogActions>
				</FormProvider>
			</Dialog>
		</>
	);
}
