import { gql } from "@apollo/client";

export const FETCH_SUBJECTS = gql`
	query (
		$keyword: String
		$order_by: String
		$direction: String
		$page: Int
		$limit: Int
		$can_create_service_order: Boolean
		$is_official_protocol: Boolean
		$status: String
	) {
		fetchSubjects(
			keyword: $keyword
			order_by: $order_by
			direction: $direction
			page: $page
			limit: $limit
			can_create_service_order: $can_create_service_order
			is_official_protocol: $is_official_protocol
			status: $status
		) {
			total
			current_page
			per_page
			last_page
			data {
				id
				code
				name
				status
				can_create_service_order
				autofill_regulatory_acts
				type
				regulatory_acts {
					id
					name
					description
				}
				dynamic_form {
					id
					name
					code
					version
					groups {
						title
						inputs {
							name
							type
							label
							required
							placeholder
							options {
								id
								name
							}
						}
					}
				}
				flow {
					id
					name
					first_occurrence_required_attachment
					first_occurrence_required_description
				}
				groups {
					id
					name
				}

				created_at
			}
		}
	}
`;
