import {
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	Radio,
	RadioGroup,
	Typography,
	Stack,
} from "@mui/material";
import Input from "components/Input";
import { Controller, useFormContext } from "react-hook-form";
import { DetailProtocolTaskOptionsResponse } from "types/graphql/queries/detailProtocol";


interface ConditionalProps {
	options: Array<DetailProtocolTaskOptionsResponse>;
	auto_finish_on_complaint_refused?: boolean;
}

export function Conditional({
	options,
	auto_finish_on_complaint_refused,
}: ConditionalProps) {
	const { control, watch } = useFormContext();
	const option = watch("option");

	const isRefuseComplaint = option === "Recusar ocorrência";
	return (
		<>
			<Grid item xs={12}>
				<Typography variant="subtitle1" textTransform="uppercase">
					escolha uma das opções abaixo para concluir essa tarefa
				</Typography>
			</Grid>
			<Grid item xs={12} marginTop={2}>
				<FormControl>
					<RadioGroup row>
						{options.map((item, index) => (
							<Controller
								key={index}
								name={"option"}
								control={control}
								rules={{ required: "Campo obrigatório" }}
								shouldUnregister={true}
								render={(inputProps) => (
									<Stack>
										<FormControlLabel
											value={item.label}
											control={
												<Radio
													value={item.label}
													onChange={() => inputProps.field.onChange(item.label)}
												/>
											}
											label={item.label}
										/>
										{inputProps.fieldState.error && (
											<FormHelperText
												error={inputProps.fieldState.error && true}
											>
												{inputProps.fieldState.error.message + ""}
											</FormHelperText>
										)}
									</Stack>
								)}
							/>
						))}
					</RadioGroup>
				</FormControl>
			</Grid>
			{auto_finish_on_complaint_refused && isRefuseComplaint && (
				<Grid item xs={12} marginTop={2}>
					<Typography variant="subtitle1" textTransform="uppercase">
						descreva abaixo um parecer para concluir essa tarefa
					</Typography>
					<Input
						name="description"
						placeholder="Parecer"
						multiline
						rows={3}
						isRequired={true}
						shouldUnregister={true}
					/>
				</Grid>
			)}
		</>
	);
}
