import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
	Box,
	Fab,
	Container,
	TablePagination,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	TableBody,
	Table,
	IconButton,
	CircularProgress,
	Stack,
	Typography,
	Tooltip,
	Switch,
	Link,
} from "@mui/material";
import { Item } from "components/Paper";
import { Header } from "components/Header";
import { EmptyMessage } from "components/EmptyMessage";
import noUser from "assets/img/noUser.png";
import { useSnackbar } from "notistack";
import { FETCH_MOBILE_USERS } from "graphql/queries/fetchMobileUsers";
import { fetchMobileUsers } from "types/graphql/queries/fetchMobileUsers";
import { format } from "date-fns";
import { UPDATE_MOBILE_USER } from "graphql/mutations/updateMobileUser";
export function UserMobiles() {
	const { enqueueSnackbar } = useSnackbar();
	const [currentPage, setCurrentPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);

	const { data, loading, refetch } = useQuery<fetchMobileUsers>(
		FETCH_MOBILE_USERS,
		{
			variables: {
				limit: rowsPerPage,
				page: currentPage + 1,
			},
		}
	);

	const [updateMobileUser, { loading: loadingUpdate }] =
		useMutation(UPDATE_MOBILE_USER);

	const handleUpdate = (event, id) => {
		updateMobileUser({
			variables: {
				id: id,
				is_allowed: event.target.checked,
			},
		}).then(() => {
			refetch();
			enqueueSnackbar("Acesso atualizado com sucesso!", {
				variant: "success",
			});
		});
	};

	if (loading) return <CircularProgress style={{ margin: 12 }} />;

	return (
		<Box
			width="100%"
			display="flex"
			alignSelf="stretch"
			flexDirection="column"
			sx={{
				backgroundColor: "#f3f3f3",
			}}
			paddingBottom="1rem"
		>
			<Header title="Acessos do aplicativo" isReturn />

			<Container maxWidth="lg" sx={{ marginTop: "1rem" }}>
				<Stack spacing={2} marginBottom={4}>
					<Box>
						<Typography variant="h6">Como usar o aplicativo?</Typography>
						<Typography variant="subtitle1">
							Através de um smartphone Android, abra a Google Play e pesquise
							por &ldquo;Checar Lagoa Santa&rdquo; ou então acesse o link
							abaixo:
						</Typography>
						<Link
							href="https://play.google.com/store/apps/details?id=com.checar.lagoasanta"
							target="_blank"
							rel="noreferrer"
							sx={{
								wordBreak: "break-all",
							}}
						>
							https://play.google.com/store/apps/details?id=com.checar.lagoasanta
						</Link>
					</Box>
					<Box>
						<Typography variant="h6">
							Como usar a impressora bluetooth?
						</Typography>
						<Typography variant="subtitle1">
							Baixe o arquivo &ldquo;Label Printer.apk&rdquo; no link abaixo e
							faça a instalação em seu smartphone Android.
						</Typography>
						<Link
							download
							href="../../../LabelPrinter.apk"
							target="_blank"
							rel="noreferrer"
							sx={{
								wordBreak: "break-all",
							}}
						>
							https://lagoasanta.checar.app/LabelPrinter.apk
						</Link>
					</Box>
				</Stack>
				<Item
					sx={{
						paddingX: 0,
						paddingY: "1px",
					}}
				>
					<TableContainer style={{ marginTop: "10px" }}>
						{data?.fetchMobileUsers?.total === 0 && (
							<>
								<EmptyMessage message="Nenhum acesso de usuário encontrado 😥" />
								<Box
									marginTop={2}
									sx={{
										backgroundImage: `url(${noUser})`,
										backgroundRepeat: "no-repeat",
										backgroundPosition: "center",
										backgroundSize: "contain",
										height: "200px",
									}}
								/>
							</>
						)}
						{data?.fetchMobileUsers?.total !== 0 && (
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Usuário</TableCell>
										<TableCell>Código</TableCell>
										<TableCell>Solicitado em</TableCell>
										<TableCell>Permitir acesso</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data &&
										data?.fetchMobileUsers?.data.map((row) => (
											<>
												<TableRow key={row.id}>
													<TableCell>{row.name}</TableCell>
													<TableCell>{row.code}</TableCell>
													<TableCell>
														{format(
															new Date(row.last_attempt),
															"dd/MM/yyyy HH:mm"
														)}
													</TableCell>
													<TableCell>
														<Switch
															name="ismobile"
															defaultChecked={row.is_allowed}
															onChange={(event) => handleUpdate(event, row.id)}
														/>
													</TableCell>
												</TableRow>
											</>
										))}
								</TableBody>
							</Table>
						)}
					</TableContainer>

					{data?.fetchMobileUsers?.total !== 0 && (
						<TablePagination
							rowsPerPageOptions={[5, 15, 30, 60, 100]}
							component="div"
							count={data?.fetchMobileUsers?.total}
							rowsPerPage={rowsPerPage}
							page={currentPage}
							onPageChange={(event, newPage) => {
								setCurrentPage(newPage);
							}}
							onRowsPerPageChange={(event) => {
								setRowsPerPage(parseInt(event.target.value));
								setCurrentPage(0);
							}}
						/>
					)}
				</Item>
			</Container>
		</Box>
	);
}
