import { Background, BackgroundProps, BackgroundVariant } from "reactflow";
import "reactflow/dist/style.css";
import { memo } from "react";

type BackgroundFlowProps = BackgroundProps & {
	variants?: "dots" | "lines" | "cross";
};
function BackgroundFlow({ variants = "lines", ...rest }: BackgroundFlowProps) {
	const optionsBackgroundVariant = {
		cross: BackgroundVariant.Cross,
		lines: BackgroundVariant.Lines,
		dots: BackgroundVariant.Dots,
	};
	return <Background {...rest} variant={optionsBackgroundVariant[variants]} />;
}

export default memo(BackgroundFlow);
