import { VariantBar } from "./Form/SinteticReport/components/DynamicGraphics/VariantBar";
import { VariantDoughnut } from "./Form/SinteticReport/components/DynamicGraphics/VariantDoughnut";
import { VariantPie } from "./Form/SinteticReport/components/DynamicGraphics/VariantPie";
import { VariantSimpleLine } from "./Form/SinteticReport/components/DynamicGraphics/VariantSimpleLine";
import { VariantSsidChart } from "./Form/SinteticReport/components/DynamicGraphics/VariantSsidChart";
import { VariantStackedBar } from "./Form/SinteticReport/components/DynamicGraphics/VariantStackedBar";
import { VariantTaskChart } from "./Form/SinteticReport/components/DynamicGraphics/VariantTaskChart";

export const graphicsComponents = {
	bar: VariantBar,
	simpleLine: VariantSimpleLine,
	stackedBar: VariantStackedBar,
	doughnut: VariantDoughnut,
	pie: VariantPie,
	ssidChart: VariantSsidChart,
	taskChart: VariantTaskChart
};
