import { Box, Link } from "@mui/material";

export const filePreview = (file: File) => {
	const fileURL = URL.createObjectURL(file);
	return (
		<Box>
			<Link
				variant="h6"
				fontSize="12px"
				target="_blank"
				rel="norefferer"
				href={fileURL}
			>
				{file.name}
			</Link>
		</Box>
	);
};
