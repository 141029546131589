import { useMutation, useQuery } from "@apollo/client";
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Stack,
} from "@mui/material";
import { CREATE_SUBJECT } from "graphql/mutations/createSubject";
import { useForm, FormProvider } from "react-hook-form";
import { useSnackbar } from "notistack";
import Input from "components/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Select } from "components/Select";
import { FetchGroups } from "types/graphql/queries/fetchGroups";
import { FETCH_GROUPS } from "graphql/queries/fetchGroups";
import { FetchFlows } from "types/graphql/queries/fetchFlows";
import { FETCH_FLOWS } from "graphql/queries/fetchFlows";
import SelectFilterModal from "components/selectFilterModal";
import { FetchRegulatoryActs } from "types/graphql/queries/fetchRegulatoryActs";
import { FETCH_REGULATORY_ACTS } from "graphql/queries/fetchRegulatoryActs";
import { GreenSwitch } from "components/GreenSwitch";

type CreateSubjectDialogProps = {
	open: boolean;
	handleClose: () => void;
	onCreate: () => void;
};

const schema = yup.object().shape({
	name: yup.string().required("Campo obrigatório"),
	type: yup.string().required("Campo obrigatório"),
	flow_id: yup.number().required("Campo obrigatório"),
	group_ids: yup.array(yup.number()).min(1, "Campo obrigatório"),
	can_create_service_order: yup.string().required("Campo obrigatório"),
});

export default function CreateSubjectDialog({
	open,
	handleClose,
	onCreate,
}: CreateSubjectDialogProps) {
	const methods = useForm({
		defaultValues: {
			name: "",
			type: "",
			flow_id: "",
			group_ids: [],
			regulatory_act_ids: [],
			can_create_service_order: "",
		},
		resolver: yupResolver(schema),
	});
	const { enqueueSnackbar } = useSnackbar();

	const { data } = useQuery<FetchGroups>(FETCH_GROUPS, {
		variables: {
			limit: 1000,
		},
	});

	const { data: dataFlow } = useQuery<FetchFlows>(FETCH_FLOWS, {
		variables: {
			limit: 1000,
			order_by: "name",
			direction: "asc",
			status: "Ativo",
		},
	});

	const { data: dataActs } = useQuery<FetchRegulatoryActs>(
		FETCH_REGULATORY_ACTS,
		{
			variables: {
				limit: 1000,
			},
		}
	);

	// Setting mutation
	const [createSubject, { loading }] = useMutation(CREATE_SUBJECT);

	const {
		reset,
		handleSubmit,
		setValue,
		watch,
		formState: { errors },
	} = methods;
	const groupIds = watch("group_ids");
	const regulatoryActs = watch("regulatory_act_ids");
	const onSubmit = async (values: any) => {
		try {
			await createSubject({
				variables: {
					name: values.name,
					type: values.type,
					flow_id: values.flow_id,
					group_ids: values.group_ids,
					regulatory_act_ids: values.regulatory_act_ids,
					can_create_service_order:
						values.can_create_service_order === "Sim" ? true : false,
				},
			});
			onCreate();
			reset({
				name: "",
				type: "",
				flow_id: "",
				group_ids: [],
				regulatory_act_ids: [],
			});
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
	};
	const type = [
		{
			label: "Administrativo",
			title: "Administrativo",
			value: "Administrativo",
		},
		{
			label: "Fiscalizatório",
			title: "Fiscalizatório",
			value: "Fiscalizatório",
		},
	];

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="paper"
				maxWidth="sm"
				fullWidth={true}
			>
				<FormProvider {...methods}>
					<DialogTitle id="scroll-dialog-title">Criar Assuntos</DialogTitle>

					<DialogContent>
						<Stack spacing={2} marginTop={2}>
							<Input name="name" autoFocus placeholder="Nome*" type="text" />
							<Select label="Selecione um tipo*" name="type" options={type} />
							{data && (
								<SelectFilterModal
									error={errors.group_ids && true}
									title="Selecione um grupo fiscal*"
									fullWidth
									onChange={(value) => {
										const idGroups = value
											.filter((item) => !item.title)
											.map((item) => item.id);
										setValue("group_ids", idGroups);
									}}
									queryName={FETCH_GROUPS}
									order_by="name"
									dataName="fetchGroups"
									valuesSelecteds={data.fetchGroups.data.filter((group) => {
										return groupIds.includes(group.id);
									})}
								/>
							)}
							{dataActs && (
								<SelectFilterModal
									title="Selecione os Atos Regulatórios"
									fullWidth
									onChange={(value) => {
										const idActs = value
											.filter((item) => !item.title)
											.map((item) => item.id);
										setValue("regulatory_act_ids", idActs);
									}}
									queryName={FETCH_REGULATORY_ACTS}
									order_by="name"
									dataName="fetchRegulatoryActs"
									valuesSelecteds={dataActs.fetchRegulatoryActs.data.filter(
										(group) => {
											return regulatoryActs.includes(group.id);
										}
									)}
								/>
							)}
							{dataFlow && (
								<Select
									label="Selecione um fluxo*"
									name="flow_id"
									options={dataFlow.fetchFlows.data.map((flow) => ({
										title: flow.name,
										value: flow.id,
									}))}
								/>
							)}
							{dataFlow && (
								<Select
									label="Pode criar ordem de serviço?*"
									name="can_create_service_order"
									options={[
										{
											title: "Sim",
											value: "Sim",
										},
										{
											title: "Não",
											value: "Não",
										},
									]}
								/>
							)}
						</Stack>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Cancelar
						</Button>
						<Button
							onClick={handleSubmit(onSubmit)}
							variant="contained"
							color="primary"
							disabled={loading}
						>
							{loading && (
								<CircularProgress size={14} style={{ marginRight: 12 }} />
							)}
							Criar
						</Button>
					</DialogActions>
				</FormProvider>
			</Dialog>
		</>
	);
}
