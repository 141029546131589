import { Box, Stack, Button, FormHelperText } from "@mui/material";
import Input from "components/Input";
import {
	useFormContext,
	FormProvider,
	useForm,
	Controller,
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { filePreview } from "utils/filePreview";

interface FileUploadProps {
	goToNextStep: () => void;
}

type FormData = {
	document: File;
	documentName: string;
};

const schema = yup.object().shape({
	document: yup.mixed().required("O documento é obrigatório."),
	documentName: yup.string().required("O nome do documento é obrigatório."),
});

export function FileUpload({ goToNextStep }: FileUploadProps) {
	const { setValue: setValueContext, watch: watchContext } = useFormContext();
	const allValuesForm = watchContext();
	const methods = useForm({
		defaultValues: {
			document: allValuesForm.file,
			documentName: allValuesForm.name,
		},
		resolver: yupResolver(schema),
	});
	const {
		handleSubmit,
		formState: { errors },
		control,
		watch,
	} = methods;

	const document = watch("document");
	const onSubmit = (data: FormData) => {
		setValueContext("file", data.document);
		setValueContext("name", data.documentName);
		goToNextStep();
	};
	const disabled = (errors.document && true) || (errors.documentName && true);
	return (
		<FormProvider {...methods}>
			<Stack spacing={4} mt={4}>
				<Stack spacing={2}>
					<Box>
						<Controller
							name="document"
							control={control}
							render={(inputProps) => (
								<Button
									color="primary"
									aria-label="upload document"
									component="label"
									variant="contained"
									sx={{
										maxWidth: "100px",
									}}
								>
									UPLOAD
									<input
										hidden
										accept="application/pdf"
										type="file"
										name="Document"
										id="document"
										onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											inputProps.field.onChange(e.target.files?.[0])
										}
									/>
								</Button>
							)}
						/>
						{document && filePreview(document)}
					</Box>
					<Box>
						<Input name="documentName" placeholder="Nome do documento" />
						<FormHelperText>Dê um nome ao seu documento.</FormHelperText>
					</Box>
				</Stack>
				<Button
					variant="contained"
					sx={{
						maxWidth: "100px",
					}}
					onClick={handleSubmit(onSubmit)}
					disabled={disabled}
				>
					Próximo
				</Button>
			</Stack>
		</FormProvider>
	);
}
