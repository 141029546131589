import { Box } from "@mui/material";
import Button from "components/Button";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";
import { ModalExportCSV } from "../Modal";

interface ExportCSVProps {
	order_by: string;
	direction: string;
	deboucedValue: string;
	year: number;
	month: number;
	responsibles: any;
}

export function ExportCSV({
	order_by,
	direction,
	deboucedValue,
	year,
	month,
	responsibles,
}: ExportCSVProps) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<Box
				display="flex"
				justifyContent="flex-end"
				alignItems="center"
				marginTop={2}
			>
				<Button
					color="inherit"
					startIcon={<DownloadIcon />}
					onClick={() => setIsOpen(true)}
				>
					Exportar para Excel
				</Button>
			</Box>
			{isOpen && (
				<ModalExportCSV
					open={isOpen}
					handleClose={() => setIsOpen(false)}
					direction={direction}
					orderBy={order_by}
					debouncedValue={deboucedValue}
					year={year}
					month={month}
					responsibles={responsibles}
				/>
			)}
		</>
	);
}
