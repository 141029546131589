import {
	Box,
	Button,
	FormHelperText,
	Grid,
	Link,
	Stack,
	Typography,
} from "@mui/material";
import IconButton from "components/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useFormContext } from "react-hook-form";
import { useSnackbar } from "notistack";



export function StepFileBase() {
	const {
		watch,
		setValue,
		clearErrors,
		setError,
		formState: { errors },
	} = useFormContext();
	const { enqueueSnackbar } = useSnackbar();

	const files = watch("files");
	const handleAddFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newFiles = e.target.files;
		const filesArray = Array.from(newFiles);
		if (
			files &&
			filesArray.filter((fileArray) =>
				files.find((file) => file.name === fileArray.name)
			).length > 0
		) {
			enqueueSnackbar("Arquivo já adicionado", { variant: "error" });
			return;
		}
		const concatedFiles = files ? [...files, ...filesArray] : filesArray;

		setValue("files", concatedFiles);
		clearErrors("files");
	};

	const handleDeleteFile = (file: File) => {
		const newFiles = files.filter((f) => f.name !== file.name);

		setValue("files", newFiles);

		if (newFiles.length === 0) {
			setError("files", {
				type: "required",
				message: "O campo é obrigatório",
			});
		}
	};

	const filePreview = (file: File) => {
		const fileURL = URL.createObjectURL(file);
		return (
			<Box>
				<Link
					variant="h6"
					fontSize="1rem"
					target="_blank"
					rel="norefferer"
					href={fileURL}
				>
					{file.name}
				</Link>
			</Box>
		);
	};

	return (
		<>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					{errors.files && (
						<Box>
							<FormHelperText error={errors.files && true}>
								{errors.files.message + "*"}
							</FormHelperText>
						</Box>
					)}
					<Stack  marginTop={2}>
						{files && files.length > 0 ? (
							files.map((file: File) => (
								<Box
									display="flex"
									columnGap={2}
									alignItems="center"
									key={file.name}
								>
									<Box>
										<AttachFileIcon />
									</Box>
									{filePreview(file)}
									<Box>
										<IconButton onClick={() => handleDeleteFile(file)}>
											<DeleteOutlineIcon color="error" />
										</IconButton>
									</Box>
								</Box>
							))
						) : (
							<Typography variant="subtitle2" sx={{
								padding:2
							}}>
								Nenhum arquivo anexado
							</Typography>
						)}
					</Stack>
					<Button
						sx={{marginTop: "1rem"}}
						color="primary"
						aria-label="upload picture"
						component="label"
						endIcon={
							<UploadFileIcon
								sx={{ color: "rgba(0, 0, 0, 0.54)", height: "20px" }}
							/>
						}
						variant="outlined"
					>
						ANEXAR ARQUIVOS
						<input
							hidden
							accept="image/*, application/pdf"
							type="file"
							name="Logo"
							id="logo"
							multiple
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								handleAddFiles(e)
							}
						/>
					</Button>
				</Grid>
			</Grid>
			
		</>
	);
}
