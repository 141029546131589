import { gql } from "@apollo/client";

export const COUNT_TASKS = gql`
	query (
		$start: String
		$finish: String
		$subject_ids: [Int]
		$street: String
		$number: String
		$district_ids: [Int]
		$late_days: String
		$lote: String
		$quadra: String
		$cadastral_index: String
		$offenders_name: String
		$situations: [String]
		$status_ids: [Int]
		$upfmls: Boolean
		$tasks: [String]
		$keyword: String
	) {
		countTasks(
			start: $start
			finish: $finish
			subject_ids: $subject_ids
			street: $street
			number: $number
			district_ids: $district_ids
			late_days: $late_days
			lote: $lote
			quadra: $quadra
			cadastral_index: $cadastral_index
			offenders_name: $offenders_name
			situations: $situations
			status_ids: $status_ids
			upfmls: $upfmls
			tasks: $tasks
			keyword: $keyword
		) {
			total
			today
			future
			late
			concluded
			canceled
			suspended
		}
	}
`;
