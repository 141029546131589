import { gql } from "@apollo/client";

export const FETCH_MOBILE_USERS = gql`
	query ($page: Int, $limit: Int) {
		fetchMobileUsers(page: $page, limit: $limit) {
			total
			current_page
			per_page
			last_page
			data {
				id
				user_id
				company_id
				name
				code
				is_allowed
				last_attempt
				created_at
			}
		}
	}
`;
