import { Item } from "components/Paper";
import { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { useQuery } from "@apollo/client";
import { FETCH_DYNAMIC_REPORTS } from "graphql/queries/fetchDynamicReports";
import { CircularProgress } from "components/CircularProgress";
import { FetchDynamicReports } from "types/graphql/queries/fetchDynamicReport";
import { AnalyticalReports } from "./List/AnalyticalReports";
import { SinteticReports } from "./List/SinteticReports";
import { AddFabButton } from "components/AddFabButton";
import { ModalCreateReport } from "./ModalCreateReport";

function a11yProps(index: number) {
	return {
		id: `relatorio-tab-${index}`,
		"aria-controls": `relatorio-tabpanel-${index}`,
	};
}

export function CustomReports() {
	const [isOpenModalCreateReport, setIsOpenModalCreateReport] = useState(false);
	const [orderBy, setOrderBy] = useState("created_at");
	const [direction, setDirection] = useState<"asc" | "desc">("asc");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [tab, setTab] = useState(0);
	const { data, loading, refetch } = useQuery<FetchDynamicReports>(
		FETCH_DYNAMIC_REPORTS,
		{
			fetchPolicy: "network-only",
			nextFetchPolicy: "cache-only",
			variables: {
				keyword: "",
				type: tab === 0 ? "Analítico" : "Sintético",
				data_source: "Protocolos",
				order_by: orderBy,
				order_direction: direction,
				page: page + 1,
				limit: rowsPerPage,
			},
		}
	);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	};

	const handleSort = (value: string) => {
		if (orderBy === value) {
			setDirection((prev) => (prev === "asc" ? "desc" : "asc"));
		} else {
			setOrderBy(value);
			setDirection("asc");
		}
	};

	return (
		<>
			<Item
				sx={{
					marginBottom: 2,
				}}
			>
				<Tabs value={tab} onChange={handleChange} aria-label="Relatórios">
						<Tab label="Relatórios analíticos" {...a11yProps(0)} />
						<Tab label="Relatórios sintéticos" {...a11yProps(1)} />
				</Tabs>
				{loading ? (
					<CircularProgress />
				) : (
					data && (
						<>
							{tab === 0 && (
								<AnalyticalReports
									dynamicReports={data.fetchDynamicReports}
									currentPage={page}
									handleSwitchPage={(value) => setPage(value)}
									rowsPerPage={rowsPerPage}
									handleSwitchPerPage={(value) => setRowsPerPage(value)}
									orderBy={orderBy}
									direction={direction}
									handleSort={(value) => handleSort(value)}
									refetch={refetch}
								/>
							)}
							{tab === 1 && (
								<SinteticReports
									dynamicReports={data.fetchDynamicReports}
									currentPage={page}
									handleSwitchPage={(value) => setPage(value)}
									rowsPerPage={rowsPerPage}
									handleSwitchPerPage={(value) => setRowsPerPage(value)}
									orderBy={orderBy}
									direction={direction}
									handleSort={(value) => handleSort(value)}
									refetch={refetch}
								/>
							)}
						</>
					)
				)}
			</Item>
			<AddFabButton onClick={() => setIsOpenModalCreateReport(true)} />
			{isOpenModalCreateReport && (
				<ModalCreateReport
					open={isOpenModalCreateReport}
					handleClose={() => setIsOpenModalCreateReport(false)}
				/>
			)}
		</>
	);
}
