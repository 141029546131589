import { gql } from "@apollo/client";

export const CREATE_PERFIL = gql`
	mutation ($perfil_id: Int, $name: String!, $restriction_ids: [Int]) {
		createPerfil(
			perfil_id: $perfil_id
			name: $name
			restriction_ids: $restriction_ids
		)
	}
`;
