import { gql } from "@apollo/client";

export const CREATE_PROTOCOL = gql`
	mutation (
		$subject_id: Int!
		$group_id: Int
		$regulatory_acts: [Int]
		$dynamic_form_response: String
		$files: [CreateProtocolFilesResponse]
		$filesBase64: [String]
		$dynamic_form_version: Int
	) {
		createProtocol(
			subject_id: $subject_id
			group_id: $group_id
			regulatory_acts: $regulatory_acts
			dynamic_form_response: $dynamic_form_response
			dynamic_form_version: $dynamic_form_version
			files: $files
			filesBase64: $filesBase64
		)
	}
`;
