import { Box, Stack } from "@mui/material";
import { useQuery } from "@apollo/client";
import { Select } from "components/Select";
import { FETCH_SUBJECTS } from "graphql/queries/fetchSubjects";
import { FetchSubjects } from "types/graphql/queries/fetchSubjects";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import Button from "components/Button";
import { RadioFilter } from "components/RadioFilterModal";

interface StepSubjectProps {
	goToNextStep: () => void;
}

export function StepSubject({ goToNextStep }: StepSubjectProps) {
	const { data } = useQuery<FetchSubjects>(FETCH_SUBJECTS, {
		variables: {
			order_by: "subjects.name",
			direction: "asc",
			limit: 1000,
			is_official_protocol: true,
		},
	});
	const [selectGroups, setSelectGroups] = useState([]);

	const { setValue, watch, setError, clearErrors } = useFormContext();
	const subjectForm = watch("subject");

	useEffect(() => {
		if (data && subjectForm) {
			setValue(
				"dynamic_form_version",
				data.fetchSubjects.data.find((subject) => subject.id === subjectForm)
					?.dynamic_form?.version || null
			);
			setValue(
				"regulatoryActs",
				data.fetchSubjects.data.find((subject) => subject.id === subjectForm)
					?.regulatory_acts || null
			);
			setValue(
				"dynamicForm",
				data.fetchSubjects.data.find((subject) => subject.id === subjectForm)
					?.dynamic_form || null
			);
			setValue(
				"autofill_regulatory_acts",
				data.fetchSubjects.data.find((subject) => subject.id === subjectForm)
					?.autofill_regulatory_acts || null
			);
			setSelectGroups(
				data.fetchSubjects.data.find((subject) => subject.id === subjectForm)
					?.groups || []
			);
			if (
				data.fetchSubjects.data.find((subject) => subject.id === subjectForm)
					.flow.first_occurrence_required_attachment === true
			) {
				setError("files", {
					type: "required",
					message: "O campo é obrigatório",
				});
			} else {
				clearErrors("files");
			}
		}
	}, [data, subjectForm]);

	const defaultValue = data?.fetchSubjects?.data.find(
		(subject) => subject.id === subjectForm
	);
	return (
		<>
			<Stack spacing={2}>
				<RadioFilter
					title="Selecionar assunto"
					placeholder="Assunto*"
					queryName={FETCH_SUBJECTS}
					dataName="fetchSubjects"
					handleSelect={(value) => setValue("subject", value)}
					officialProtocol={{
						order_by: "subjects.name",
						direction: "asc",
						is_official_protocol: true,
					}}
					defaultValue={
						defaultValue
							? { name: defaultValue.name, id: defaultValue.id }
							: null
					}
				/>
				{selectGroups.length > 0 && (
					<Select
						isShowEmptyOption
						name="group"
						label="Grupos"
						options={selectGroups.map((group) => ({
							label: group.name,
							value: group.id,
						}))}
						fullWidth
					/>
				)}
			</Stack>
			<Box sx={{ mb: 2 }}>
				<Box>
					<Button
						variant="contained"
						onClick={goToNextStep}
						disabled={!subjectForm}
						sx={{ mt: 1, mr: 1 }}
					>
						Próximo
					</Button>
				</Box>
			</Box>
		</>
	);
}
