import React, { createContext, useContext, useCallback } from "react";
import { Edge, MarkerType, Node, useEdgesState, useNodesState } from "reactflow";
import { v4 as uuidv4 } from "uuid";

const FlowContext = createContext({});

export function FlowProvider({ children, isFromCreate }: { children: React.ReactNode, isFromCreate: boolean }) {

	const initialNodes: Node[] = [
		{
			id: uuidv4(),
			type: "START",
			data: {
				label: "Início",
			},
			position: { x: 100, y: 100 },
		},
		{
			id: uuidv4(),
			type: "OCCURRENCE",
			data: {
				label: "Ocorrência",
			},
			position: { x: 100, y: 200 },
		},
	];

	const initialEdges: Edge[] = [
		{
			id: uuidv4(),
			source: initialNodes[0].id,
			target: initialNodes[1].id,
			type: "floating",
			style: { strokeWidth: 3, stroke: "gray" },
			markerEnd: {
				type: MarkerType.ArrowClosed,
				color: "gray",
			},
		},
	];

	const [nodes, setNodes, onNodesChange] = useNodesState(isFromCreate ? initialNodes : []);
	const [edges, setEdges, onEdgesChange] = useEdgesState(isFromCreate ? initialEdges : []);

	const contextValue = {
		nodes,
		edges,
		setNodes,
		setEdges,
		onNodesChange,
		onEdgesChange
	};

	return (
		<FlowContext.Provider value={contextValue}>
			{children}
		</FlowContext.Provider>
	);
}

export const useFlowContext = () => useContext(FlowContext);
