import { Box, Grid, Stack, TextField } from "@mui/material";
import { ModalLayout } from "components/ModalLayout";
import { ModalLayoutProps } from "types/ModalLayout";
import { type } from "../../consts";
import { FormProvider, useForm } from "react-hook-form";
import Input from "components/Input";
import SelectFilterNotUseQuery from "components/selectFilterModal/SelectFilterNotUseQuery";
import { Select } from "components/Select";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import { format, parse } from "date-fns";
import { dateTypes, day, month, year } from "./consts";
import { useQuery } from "@apollo/client";
import { FetchTasksLabelList } from "types/graphql/queries/fetchTasksLabelList";
import { FETCH_TASKS_LABEL_LIST } from "graphql/queries/fetchTasksLabelList";

type ModalEditGraphicProps = ModalLayoutProps & {
	handleEditGraphic: (data: {
		id: string;
		title: string;
		label: string;
		dataTypes: string[];
		dateType?: string;
		month?: number;
		year?: number;
		day?: number;
		startDate?: string;
		finishDate?: string;
		subject_id?: number;
	}) => void;
	graphic: {
		variant: string;
		id: string;
		title: string;
		label: string;
		dataTypes: string[];
		dateType?: string;
		year?: number;
		month?: number;
		day?: number | string;
		startDate?: string | null;
		finishDate?: string | null;
		subject_id?: number;
	};
};

export function ModalEditGraphic({
	open,
	handleClose,
	handleEditGraphic,
	graphic,
}: ModalEditGraphicProps) {
	const [startDate, setStartDate] = useState<Date | null>(
		graphic.startDate
			? parse(graphic.startDate, "dd/MM/yyyy", new Date())
			: null
	);
	const [finishDate, setFinishDate] = useState<Date | null>(
		graphic.finishDate
			? parse(graphic.finishDate, "dd/MM/yyyy", new Date())
			: null
	);
	const methods = useForm({
		defaultValues: {
			id: graphic.id,
			dataTypes: graphic.dataTypes,
			title: graphic.title,
			label: graphic.label,
			dateType: graphic.dateType || "",
			year: graphic.dateType === "year" ? graphic.year : "",
			month: graphic.dateType === "month" ? graphic.month : "",
			day:
				graphic.dateType === "day"
					? graphic.day === 2
						? "custom"
						: graphic.day
					: "",
			subject_id: graphic.subject_id
		},
	});

	const { watch, handleSubmit, setValue } = methods;

	const dataTypesSelected = watch("dataTypes");
	const dateTypeSelected = watch("dateType");
	const perDayOptionSelected = watch("day");

	const onSubmit = (data: {
		id: string;
		title: string;
		label: string;
		dataTypes: string[];
		dateType: string;
	}) => {
		const dataFormatted: {
			id: string;
			title: string;
			label: string;
			dataTypes: string[];
			dateType: string;
			startDate?: string;
			finishDate?: string;
		} = {
			...data,
			startDate: startDate ? format(new Date(startDate), "dd/MM/yyyy") : null,
			finishDate: finishDate
				? format(new Date(finishDate), "dd/MM/yyyy")
				: null,
		};

		handleEditGraphic(dataFormatted);
	};

	const handleAddDataType = (values: Array<{ id: string; name: string }>) => {
		const dataFormatted = values
			.filter((item) => item.name !== undefined)
			.map((item) => item.name);
		setValue("dataTypes", dataFormatted);
	};

	const graphicToEditHasSeries =
		graphic.variant === "simpleLine" ||
		graphic.variant === "stackedBar" ||
		graphic.variant === "taskChart";

	const { data: dataTasks, loading: loadingTasks } = useQuery<FetchTasksLabelList>(
		FETCH_TASKS_LABEL_LIST
	);

	return (
		<FormProvider {...methods}>
			<ModalLayout
				open={open}
				handleClose={handleClose}
				title="Editar gráfico"
				buttonText="Editar"
				handleSubmit={handleSubmit(onSubmit)}
				isDisabled={dataTypesSelected.length === 0}
			>
				<Stack spacing={2} mt={2}>
					<Stack direction="row" spacing={2}>
						<SelectFilterNotUseQuery
							loading={false}
							fullWidth
							title="Selecione um item dos dados"
							selectValues={
								graphic.variant === "taskChart" && !loadingTasks
									? dataTasks?.fetchTasksLabelList?.labels.map((label) => ({
											id: label,
											name: label,
									}))
									: type.filter((type) => type.label !== "Por Tarefa")
										.map((item) => (
											{
												id: item.value,
												name: item.label,
											}
										))
							}
							valuesSelecteds={dataTypesSelected.map((item) => ({
								id: item,
								name: item,
							}))}
							onChange={(value) => handleAddDataType(value)}
						/>
					</Stack>
					{graphicToEditHasSeries && (
						<>
							<Box>
								<Select
									name="dateType"
									label={"Intervalo entre"}
									options={dateTypes}
									fullWidth
									marginSelect={0}
								/>
							</Box>
							{dateTypeSelected === "year" && (
								<Box>
									<Select
										name="year"
										label={"Intervalo por ano"}
										options={year}
										fullWidth
										marginSelect={0}
										shouldUnregister={true}
									/>
								</Box>
							)}
							{dateTypeSelected === "month" && (
								<Box>
									<Select
										name="month"
										label={"Intervalo por mês"}
										options={month}
										fullWidth
										marginSelect={0}
										shouldUnregister={true}
									/>
								</Box>
							)}
							{dateTypeSelected === "day" && (
								<Box>
									<Select
										name="day"
										label={"Intervalo por dia"}
										options={day}
										fullWidth
										marginSelect={0}
										shouldUnregister={true}
									/>
								</Box>
							)}
							{perDayOptionSelected === "custom" && (
								<Grid container mt={2}>
									<Grid item xs pr={2}>
										<LocalizationProvider
											dateAdapter={AdapterDayjs}
											adapterLocale="pt-br"
										>
											<DatePicker
												disableFuture
												inputFormat="DD/MM/YYYY"
												label="Início"
												value={startDate}
												onChange={(newValue) => {
													setStartDate(newValue);
												}}
												renderInput={(params) => (
													<TextField {...params} sx={{ width: "100%" }} />
												)}
											/>
										</LocalizationProvider>
									</Grid>
									<Grid item xs>
										<LocalizationProvider
											dateAdapter={AdapterDayjs}
											adapterLocale="pt-br"
										>
											<DatePicker
												disableFuture
												inputFormat="DD/MM/YYYY"
												label="Fim"
												value={finishDate}
												onChange={(newValue) => {
													setFinishDate(newValue);
												}}
												renderInput={(params) => (
													<TextField {...params} sx={{ width: "100%" }} />
												)}
											/>
										</LocalizationProvider>
									</Grid>
								</Grid>
							)}
						</>
					)}
					<Box>
						<Input name="title" placeholder="Título" fullWidth />
					</Box>
					<Box>
						<Input name="label" placeholder="Label" fullWidth />
					</Box>
				</Stack>
			</ModalLayout>
		</FormProvider>
	);
}
