import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
	Box,
	Container,
	TablePagination,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	TableBody,
	Table,
	TableSortLabel,
	TextField,
} from "@mui/material";
import { Item } from "components/Paper";
import { Header } from "components/Header";
import { EmptyMessage } from "components/EmptyMessage";
import noUser from "assets/img/noUser.png";
import { FetchPoints } from "types/graphql/queries/fetchPoints";
import { FETCH_POINTS } from "graphql/queries/fetchPoints";
import useDebounce from "hooks/useDebounce";
import LocalProvider from "components/LocalProvider";
import { ExportCSV } from "./ExportCSV";
import { Filter } from "./Filters";

export function ReportPoints() {
	const [currentPage, setCurrentPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [orderBy, setOrderBy] = useState("users.name");
	const [direction, setDirection] = useState<"asc" | "desc">("asc");
	const [keyword, setKeyword] = useState("");
	const [description, setDescription] = useState("");
	const [startDate, setStartDate] = useState(null);
	const [startFinish, setFinishDate] = useState(null);
	const [responsibles, setResponsibles] = useState(null);

	const debouncedValue = useDebounce(keyword, 1000);

	const { data } = useQuery<FetchPoints>(FETCH_POINTS, {
		variables: {
			limit: rowsPerPage,
			page: currentPage + 1,
			order_by: orderBy,
			direction: direction,
			keyword: keyword,
			start: startDate,
			finish: startFinish,
			description: description,
			responsibles:
				responsibles && responsibles.length > 0
					? responsibles.map((item) => item.id)
					: null,
		},
	});

	function handleSort(name) {
		const isAsc = orderBy === name && direction === "asc";
		setDirection(isAsc ? "desc" : "asc");
		setOrderBy(name);
	}

	function handleFilter(values) {
		setStartDate(values.startDate);
		setFinishDate(values.finishDate);
		setDescription(values.description);
		setResponsibles(values.responsibles);
		setKeyword(values.keyword);
	}

	return (
		<Box
			width="100%"
			display="flex"
			alignSelf="stretch"
			flexDirection="column"
			sx={{
				backgroundColor: "#f3f3f3",
			}}
			paddingBottom="1rem"
		>
			<Header title="Relatório de pontuação" isReturn />

			<Container maxWidth="lg" sx={{ marginTop: "1rem" }}>
				<Item
					sx={{
						paddingX: 0,
						paddingY: "1px",
					}}
				>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						paddingX="1rem"
					>
						<Filter
							handleFilter={(values) => handleFilter(values)}
							keyword={keyword}
						/>
						<ExportCSV
							direction={direction}
							order_by={orderBy}
							deboucedValue={debouncedValue}
							description={description}
							responsibles={responsibles}
							startDate={startDate}
							finishDate={startFinish}
						/>
					</Box>
					<TableContainer style={{ marginTop: "10px" }}>
						{data?.fetchPoints?.total === 0 && (
							<>
								<EmptyMessage message="Nenhum ponto para listar" />
								<Box
									marginTop={2}
									sx={{
										backgroundImage: `url(${noUser})`,
										backgroundRepeat: "no-repeat",
										backgroundPosition: "center",
										backgroundSize: "contain",
										height: "200px",
									}}
								/>
							</>
						)}

						{data?.fetchPoints?.total !== 0 && (
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>
											<TableSortLabel
												active={orderBy === "protocols.code"}
												direction={
													orderBy === "protocols.code" ? direction : "asc"
												}
												onClick={() => handleSort("protocols.code")}
											>
												Protocolo
											</TableSortLabel>
										</TableCell>
										<TableCell>
											<TableSortLabel
												active={orderBy === "users.name"}
												direction={orderBy === "users.name" ? direction : "asc"}
												onClick={() => handleSort("users.name")}
											>
												Fiscal
											</TableSortLabel>
										</TableCell>
										<TableCell>
											<TableSortLabel
												active={orderBy === "tasks.created_at"}
												direction={
													orderBy === "tasks.created_at" ? direction : "asc"
												}
												onClick={() => handleSort("tasks.created_at")}
											>
												Data/Hora
											</TableSortLabel>
										</TableCell>
										<TableCell>
											<TableSortLabel
												active={orderBy === "tasks.points"}
												direction={
													orderBy === "tasks.points" ? direction : "asc"
												}
												onClick={() => handleSort("tasks.points")}
											>
												Pontuação
											</TableSortLabel>
										</TableCell>
										<TableCell>Descrição</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data?.fetchPoints?.data.map((row) => (
										<>
											<TableRow key={row.protocol}>
												<TableCell>{row.protocol}</TableCell>
												<TableCell>{row.user}</TableCell>
												<TableCell>{row.created_at}</TableCell>
												<TableCell>{row.points}</TableCell>
												<TableCell>{row.description}</TableCell>
											</TableRow>
										</>
									))}
								</TableBody>
							</Table>
						)}
					</TableContainer>
					{data?.fetchPoints?.total !== 0 && (
						<LocalProvider locale="ptBR">
							<TablePagination
								showLastButton
								showFirstButton
								rowsPerPageOptions={[5, 15, 30, 60, 100]}
								component="div"
								count={data?.fetchPoints?.total}
								rowsPerPage={rowsPerPage}
								page={currentPage}
								onPageChange={(event, newPage) => {
									setCurrentPage(newPage);
								}}
								onRowsPerPageChange={(event) => {
									setRowsPerPage(parseInt(event.target.value));
									setCurrentPage(0);
								}}
							/>
						</LocalProvider>
					)}
				</Item>
			</Container>
		</Box>
	);
}
