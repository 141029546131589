import { Backdrop } from "@mui/material";
import { Stack } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { FETCH_DETAIL_COMPANY } from "graphql/queries/fetchDetailCompany";
import { CircularProgress } from "components/CircularProgress";
import { FetchDetailCompany } from "types/graphql/queries/fetchDetailCompany";
import { DetailPublicProtocol } from "types/graphql/queries/detailPublicProtocol";
import { DETAIL_PUBLIC_PROTOCOL } from "graphql/queries/detailPublicProtocol";
import { useParams, useNavigate } from "react-router-dom";

export function ValidateProtocol() {
	const subdomain = window.location.hostname.split(".")[0];
	const { data, loading: loadingDetailCompany } = useQuery<FetchDetailCompany>(
		FETCH_DETAIL_COMPANY,
		{
			variables: {
				domain:
					subdomain === "localhost"
						? process.env.REACT_DEFAULT_DOMAIN || "lagoasanta"
						: subdomain,
			},
		}
	);

	const navigate = useNavigate();
	const params = useParams();
	const protocolo = params.protocolo;

	const { data: dataProtocol, loading } = useQuery<DetailPublicProtocol>(
		DETAIL_PUBLIC_PROTOCOL,
		{
			variables: {
				code: String(protocolo),
			},
		}
	);

	return (
		<>
			{loadingDetailCompany && (
				<Backdrop
					sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={true}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			)}
			{data && (
				<>
					<Box display={["none", "none", "block"]} height="100vh" flex={1}>
						<Box
							sx={{
								backgroundImage: `url(${data.detailCompany.cover})`,
								backgroundSize: "cover",
								backgroundPosition: "center",
								width: "100%",
								height: "100%",
							}}
						/>
					</Box>

					<Stack
						sx={{
							display: "flex",
							flexDirection: "column",
							flex: "1",
							alignItems: "center",
							textAlign: "center",
						}}
					>
						<Stack padding="1rem" flex={1} spacing={2}>
							<Box
								textAlign="center"
								sx={{
									backgroundImage: `url(${data.detailCompany.logo})`,
									backgroundSize: "contain",
									backgroundPosition: "center",
									backgroundRepeat: "no-repeat",
									width: ["250px", "350px"],
								}}
								height="100px"
							/>
						</Stack>
						<Stack
							padding="1rem"
							sx={{ marginTop: "10px", alignItems: "center" }}
							flex={1}
							spacing={2}
							width="100%"
						>
							<Typography fontWeight={700} fontSize="1.6rem">
								Validação de protocolo
							</Typography>
						</Stack>

						<Stack width="100%" paddingX={4}>
							<Box
								sx={{
									marginY: "3rem",
									textAlign: "start",
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
								}}
							>
								{dataProtocol && (
									<Typography
										variant="subtitle2"
										fontWeight={400}
										fontSize="1.4rem"
									>
										<p>
											O protocolo de n° {dataProtocol.detailPublicProtocol.code}{" "}
											é VÁLIDO e foi cadastrado no dia{" "}
											{dataProtocol.detailPublicProtocol.created_at}.
										</p>
									</Typography>
								)}

								{!dataProtocol && (
									<Typography
										variant="subtitle2"
										fontWeight={400}
										fontSize="1.4rem"
									>
										O protocolo de n° {protocolo} é INVÁLIDO.
									</Typography>
								)}

								{dataProtocol && (
									<Typography
										variant="subtitle2"
										fontWeight={400}
										fontSize="1.1rem"
										style={{ marginTop: "50px" }}
									>
										<p style={{ margin: 0, fontWeight: "700" }}>
											Fiscal:{" "}
											<span style={{ fontWeight: "400" }}>
												{
													dataProtocol.detailPublicProtocol.responsible_user
														.name
												}
											</span>
										</p>
										<p style={{ margin: 0, fontWeight: "700" }}>
											Matrícula:{" "}
											<span style={{ fontWeight: "400" }}>
												{dataProtocol.detailPublicProtocol.responsible_user
													.params
													? dataProtocol.detailPublicProtocol.responsible_user
															.params.matricula
													: " - "}
											</span>
										</p>
									</Typography>
								)}
							</Box>
						</Stack>
					</Stack>
				</>
			)}
		</>
	);
}
