import {
	Box,
	TableRow,
	TableHead,
	TableContainer,
	TablePagination,
	TableCell,
	TableBody,
	Table,
	TableSortLabel,
	Stack,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { Title } from "components/Title";
import { Item } from "components/Paper";
import { EmptyMessage } from "components/EmptyMessage";
import noTaskToday from "assets/img/noTaskToday.png";
import LocalProvider from "components/LocalProvider";
import { format } from "date-fns";
import { Chips } from "components/Chips";
import { FetchTasksToday } from "types/graphql/queries/fetchTasksToday";
import HoverLink from "components/HoverLink";
import { getTheme } from "utils/protocolStatus";
import invertColor from "utils/invertColor";
import PaginationStorage from "components/PaginationStorage";
import { useState } from "react";

type ListTodayProps = {
	fetchTasksToday: FetchTasksToday["fetchTasksToday"]["data"];
	currentPageToday: number;
	setCurrentPageToday: React.Dispatch<React.SetStateAction<number>>;
	rowsPerPageToday: number;
	setRowsPerPageToday: React.Dispatch<React.SetStateAction<number>>;
	setOrderByToday: React.Dispatch<React.SetStateAction<string>>;
	setDirectionToday: React.Dispatch<React.SetStateAction<"desc" | "asc">>;
	orderByToday: string;
	directionToday: "desc" | "asc";
	total: number;
};

export default function ListToday({
	currentPageToday,
	setCurrentPageToday,
	rowsPerPageToday,
	setRowsPerPageToday,
	fetchTasksToday,
	total,
	orderByToday,
	setOrderByToday,
	directionToday,
	setDirectionToday,
}: ListTodayProps) {
	const [showPagination, setShowPagination] = useState(false);

	const media889 = useMediaQuery("(max-width:889px)");
	const media500 = useMediaQuery("(max-width:500px)");

	const handleSort = (name) => {
		const isAsc = orderByToday === name && directionToday === "asc";
		setDirectionToday(isAsc ? "desc" : "asc");
		setOrderByToday(name);
	};

	return (
		<div
			style={{ position: "relative", marginBottom: "60px" }}
			onMouseEnter={() => setShowPagination(true)}
			onMouseLeave={() => setShowPagination(false)}
		>
			<Item
				sx={{
					paddingX: 0,
					position: "relative",
					marginBottom: "20px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						paddingX: "16px",
					}}
				>
					<Title title="Para hoje" />
				</Box>
				<TableContainer style={{ marginTop: "20px" }}>
					{fetchTasksToday.length === 0 && (
						<>
							<EmptyMessage message="Nenhuma tarefa cadastrada para hoje" />
							<Box
								marginTop={2}
								sx={{
									backgroundImage: `url(${noTaskToday})`,
									backgroundRepeat: "no-repeat",
									backgroundPosition: "center",
									backgroundSize: "contain",
									height: "100px",
								}}
							/>
						</>
					)}

					{fetchTasksToday.length !== 0 && (
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<TableSortLabel
											active={orderByToday === "tasks.expire_at"}
											direction={
												orderByToday === "tasks.expire_at" ? directionToday : "desc"
											}
											onClick={() => handleSort("tasks.expire_at")}
										>
											Nº Protocolo
										</TableSortLabel>
									</TableCell>
									<TableCell>Tarefa atual</TableCell>
									<TableCell>Assunto</TableCell>
									<TableCell>Endereço</TableCell>
									<TableCell>Situação do protocolo</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{fetchTasksToday.map((row) => (
									<TableRow key={row.id}>
										<TableCell>
											<HoverLink
												href={`/protocolo/${row.protocol.code}?from=task`}
											>
												{row.protocol.code}
											</HoverLink>
											<Typography
												variant="caption"
												sx={{ display: "flex", flexDirection: "column" }}
											>
												<Stack
													spacing={2}
													direction="row"
													flex={1}
													alignItems="center"
												>
													Prazo resolução:{" "}
													{!row.expire_at && <Typography>-</Typography>}
													{row.expire_at &&
														format(new Date(row.expire_at), "dd/MM/yyyy")}
												</Stack>
												{row.info && (
													<Chips label={row.info} theme={row.theme} />
												)}
											</Typography>
										</TableCell>
										<TableCell>
											<b>{row.name + "\n"}</b>
											<br />
											<Typography variant="caption">
												Responsável da tarefa:{" "}
												{row.user ? row.user.name : row.perfil.name}
											</Typography>
										</TableCell>
										<TableCell>
											{row.protocol?.subject
												? row.protocol?.subject.name
												: "-"}
										</TableCell>
										<TableCell>
											<Typography variant="subtitle2" fontWeight={400}>
												{row.street ? row.street : "-"}{" "}
												{row.number
													? (row.street ? ", " : "") + "nº" + row.number
													: null}{" "}
												{row.district ? row.district : null}
											</Typography>
										</TableCell>
										<TableCell>
											<Chips
												label={row.protocol.situation}
												style={{
													backgroundColor: getTheme(row.protocol.situation),
													color: invertColor(
														getTheme(row.protocol.situation)
													),
												}}
											/>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					)}
				</TableContainer>
				{fetchTasksToday.length !== 0 &&
					(media500 ? (
						<TablePagination
							sx={{
								background: "#fff",
							}}
							component="div"
							labelRowsPerPage="Linhas por página:"
							rowsPerPageOptions={[5, 15, 30, 60, 100]}
							count={total}
							page={currentPageToday}
							onPageChange={(event, newPage) => {
								setCurrentPageToday(newPage);
							}}
							rowsPerPage={rowsPerPageToday}
							onRowsPerPageChange={(event) => {
								setRowsPerPageToday(parseInt(event.target.value));
								setCurrentPageToday(0);
							}}
						/>
					) : (
						<Box
							sx={{
								position: "fixed",
								bottom: "30px",
								left: media889 ? "50%" : "60%",
								transform: "translateX(-50%)",
								padding: "0px",
								backgroundColor: "rgba(25, 118, 210, 0.98)",
								boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
								opacity: showPagination ? 0.95 : 0,
								zIndex: 2,
								borderRadius: "10px",
								transition: "opacity 0.3s ease-in-out",
							}}
						>
							<PaginationStorage
								count={total}
								rowsPerPage={rowsPerPageToday}
								page={currentPageToday}
								onPageChange={(event, newPage) => {
									setCurrentPageToday(newPage);
								}}
								onRowsPerPageChange={(event) => {
									setRowsPerPageToday(parseInt(event.target.value));
									setCurrentPageToday(0);
								}}
							/>
						</Box>
					))}
			</Item>
		</div>
	);
}
