import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { Item } from "components/Paper";
import React, { SetStateAction, memo } from "react";
import Button from "components/Button";
import { Version } from "types/graphql/queries/detailFlow";

interface Props {
	versionHistory?: {
		version: number;
		open: boolean;
	};
	versions: Array<Version>;
	setVersionHistory?: React.Dispatch<
		SetStateAction<{
			version: number;
			open: boolean;
		}>
	>;
	currentVersion: number;
}

function HistoryPanel({
	versionHistory,
	versions,
	currentVersion,
	setVersionHistory,
}: Props) {
	return (
		<Item
			sx={{
				maxWidth: "300px",
				maxHeight: "600px",
				position: "relative",
				left: "10px",
				top: "10px",
				zIndex: 999,
				overflow: "auto",
				backgroundColor: "#e6e3e3",
			}}
		>
			<Stack spacing={2} paddingTop={2} paddingX={1}>
				<Button
					variant="contained"
					onClick={() => {
						setVersionHistory({
							version: currentVersion,
							open: false,
						});
					}}
					sx={{
						mb: 2,
					}}
				>
					Sair do historico
				</Button>
				Versões
				<Grid container>
					{versions.map((item) => (
						<Grid
							item
							key={item.version}
							xs={12}
							sx={{
								mb: 2,
							}}
						>
							<Card
								variant="outlined"
								sx={{
									border:
										item.version === versionHistory.version
											? "2px solid #00ff1e"
											: "none",
								}}
							>
								<Button
									sx={{
										p: 2,
										width: "100%",
										height: "100%",
										justifyContent: "space-between",
										alignItems: "center",
										gap: 2,
									}}
									color="inherit"
									onClick={() => {
										setVersionHistory({
											version: item.version,
											open: true,
										});
									}}
								>
									<Box display={"flex"} alignItems={"flex-end"}>
										<Typography
											variant="body1"
											fontWeight={700}
											textTransform={"lowercase"}
											fontSize={22}
										>
											v{item.version}
										</Typography>
									</Box>
									<Box textAlign={"left"} flex={1}>
										<Box mb={0.1}>
											<Typography variant="caption" mr={1} textAlign={"left"}>
												Atualizado em:
											</Typography>
											<Typography variant="subtitle2" textAlign={"left"}>
												{item.updated_at}
											</Typography>
										</Box>
										{item.user && (
											<Box>
												<Typography variant="caption" mr={1} textAlign={"left"}>
													Atualizado por:
												</Typography>
												<Typography variant="subtitle2" textAlign={"left"}>
													{item.user}
												</Typography>
											</Box>
										)}
									</Box>
								</Button>
							</Card>
						</Grid>
					))}
				</Grid>
			</Stack>
		</Item>
	);
}

export default memo(HistoryPanel);
