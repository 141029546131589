/* eslint-disable no-mixed-spaces-and-tabs */
import { useQuery } from "@apollo/client";
import { Stack, Box, Typography } from "@mui/material";
import { CircularProgress } from "components/CircularProgress";
import { WrapperFilterInfo } from "components/Filter/WrapperFilterInfo";
import { COUNT_PROTOCOL_SITUATIONS } from "graphql/queries/countProtocolsSituation";
import { CountProtocolSituations } from "types/graphql/queries/countProtocolSituations";

export function Counter(props: any) {
	const filters = props.filters;
	const { data, loading } = useQuery<CountProtocolSituations>(
		COUNT_PROTOCOL_SITUATIONS,
		{
			fetchPolicy: "network-only",
			variables: {
				keyword: props.keyword,
				start: props.startDate,
				finish: props.finishDate,
				situations: filters
					? (filters[0].options.length > 0 &&
							filters[0].options.map((item) => item.name)) ||
					  null
					: null,
				subject_ids: filters
					? (filters[1].options.length > 0 &&
							filters[1].options.map((item) => item.id)) ||
					  null
					: null,
				group_ids: filters
					? (filters[2].options.length > 0 &&
							filters[2].options.map((item) => item.id)) ||
					  null
					: null,
				status_ids: filters
					? (filters[3].options.length > 0 &&
							filters[3].options.map((item) => item.id)) ||
					  null
					: null,
				responsible_ids: filters
					? (filters[4].options.length > 0 &&
							filters[4].options.map((item) => item.id)) ||
					  null
					: null,
				district_ids: filters
					? (filters[5].options.length > 0 &&
							filters[5].options.map((item) => item.id)) ||
					  null
					: null,
				tasks: filters
					? (filters[6].options.length > 0 &&
							filters[6].options.map((item) => item.name)) ||
					  null
					: null,
				late_days: props.lateDays,
				upfmls: props.upfmls,
				street: props.street,
				number: props.number,
				lote: props.lote,
				quadra: props.quadra,
				cadastral_index: props?.cadastralIndex || "",
				offenders_name: props?.offendersName || "",
			},
		}
	);
	const isLoading = loading || props.isLoading;

	return (
		<>
			{isLoading ? (
				<CircularProgress />
			) : (
				<Stack justifyContent="space-between">
					<Box
						display="flex"
						alignItems="center"
						justifyContent="space-between"
					>
						<Box>
							<Typography variant="caption">Total</Typography>
							<Typography variant="h5">
								{data && data.countProtocolSituations.total}
							</Typography>
						</Box>
						<Box>
							<Typography variant="caption" color="red">
								Atrasados
							</Typography>
							<Typography variant="h5" color="red">
								{data && data.countProtocolSituations.lated}
							</Typography>
						</Box>
					</Box>
					<Stack mt={2} spacing={1}>
						<Typography variant="overline" fontWeight="bold">
							Qtd. por situação
						</Typography>
						<WrapperFilterInfo
							title="Concluído"
							value={data && data.countProtocolSituations.finished}
						/>
						<WrapperFilterInfo
							title="Em andamento"
							value={data && data.countProtocolSituations.in_progress}
						/>
						<WrapperFilterInfo
							title="Cancelado"
							value={data && data.countProtocolSituations.canceled}
						/>
						<WrapperFilterInfo
							title="Suspenso"
							value={data && data.countProtocolSituations.suspended}
						/>
					</Stack>
					<Stack mt={2} spacing={1}>
						<Typography variant="overline" fontWeight="bold">
							Qtd. por status atual
						</Typography>
						{data &&
							data.countProtocolSituations.status?.map((item, key) => {
								return (
									<WrapperFilterInfo
										key={key}
										title={item.name}
										value={
											item.in_progress +
											item.finished +
											item.canceled +
											item.suspended
										}
									/>
								);
							})}
					</Stack>
				</Stack>
			)}
		</>
	);
}
