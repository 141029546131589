import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
	Box,
	Fab,
	Container,
	TablePagination,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	TableBody,
	Table,
	IconButton,
	Stack,
	TableSortLabel,
	TextField,
	useMediaQuery,
} from "@mui/material";
import { Item } from "components/Paper";
import { Header } from "components/Header";
import { EmptyMessage } from "components/EmptyMessage";
import noUser from "assets/img/noUser.png";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useSnackbar } from "notistack";
import { FETCH_HOLIDAYS } from "graphql/queries/fetchHolidays";
import { FetchHolidays } from "types/graphql/queries/fetchHolidays";
import AddIcon from "@mui/icons-material/Add";
import DeleteDialog from "./DeleteDialog";
import EditDialog from "./EditDialog";
import CreateHolidayDialog from "./CreateHolidayDialog";
import useDebounce from "hooks/useDebounce";
import { useVerifyNoHasRestriction } from "utils/useVerifyNoHasRestriction";
import LocalProvider from "components/LocalProvider";
import { CircularProgress } from "components/CircularProgress";
import PaginationStorage from "components/PaginationStorage";

export function Holidays() {
	const [showDelete, setShowDelete] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [subjectToDeleted, setSubjectToDelete] = useState(null);
	const [showCreate, setShowCreate] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [subjectSelected, setSubjectSelected] = useState(null);
	const [orderBy, setOrderBy] = useState("name");
	const [direction, setDirection] = useState<"asc" | "desc">("asc");
	const [keyword, setKeyword] = useState("");
	const [showPagination, setShowPagination] = useState(false);

	const debouncedValue = useDebounce(keyword, 1000);

	const { data, refetch, loading } = useQuery<FetchHolidays>(FETCH_HOLIDAYS, {
		variables: {
			limit: rowsPerPage,
			page: currentPage + 1,
			order_by: orderBy,
			direction: direction,
			keyword: debouncedValue,
		},
	});

	function handleDeleteHolidays(item) {
		setSubjectToDelete(item);
		setShowDelete(true);
	}

	const handleOpenEditModal = (Subject) => {
		setSubjectSelected(Subject);
		setOpenEdit(true);
	};

	const handleCloseEditModal = () => {
		setOpenEdit(false);
		setSubjectSelected(null);
	};

	function handleSort(name) {
		const isAsc = orderBy === name && direction === "asc";
		setDirection(isAsc ? "desc" : "asc");
		setOrderBy(name);
	}

	function getMonthName(monthNumber) {
		const date = new Date();
		date.setMonth(monthNumber - 1);
		return date.toLocaleString([], { month: "long" });
	}

	const media500 = useMediaQuery("(max-width:500px)");
	return (
		<Box
			width="100%"
			display="flex"
			alignSelf="stretch"
			flexDirection="column"
			sx={{
				backgroundColor: "#f3f3f3",
			}}
			paddingBottom="1rem"
		>
			<Header title="Feriados" isReturn />

			{loading ? (
				<CircularProgress />
			) : (
				<Container maxWidth="lg" sx={{ marginTop: "1rem" }}>
					<div
						style={{ position: "relative", marginBottom: "60px" }}
						onMouseEnter={() => setShowPagination(true)}
						onMouseLeave={() => setShowPagination(false)}
					>
						<Item
							sx={{
								paddingX: 0,
								paddingY: "1px",
							}}
						>
							<Box sx={{ padding: 2 }}>
								<TextField
									fullWidth
									variant="standard"
									name="keyword"
									type="search"
									label="Pesquisar..."
									value={keyword}
									onChange={(event) => {
										setKeyword(event.target.value);
									}}
								/>
							</Box>

							<TableContainer style={{ marginTop: "10px" }}>
								{data?.fetchHolidays?.total === 0 && (
									<>
										<EmptyMessage message="Nenhum feriado encontrado 😥" />
										<Box
											marginTop={2}
											sx={{
												backgroundImage: `url(${noUser})`,
												backgroundRepeat: "no-repeat",
												backgroundPosition: "center",
												backgroundSize: "contain",
												height: "200px",
											}}
										/>
									</>
								)}
								{data?.fetchHolidays?.total !== 0 && (
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>
													<TableSortLabel
														active={orderBy === "name"}
														direction={orderBy === "name" ? direction : "asc"}
														onClick={() => handleSort("name")}
													>
														Nome
													</TableSortLabel>
												</TableCell>
												<TableCell>
													<TableSortLabel
														active={orderBy === "day"}
														direction={orderBy === "day" ? direction : "asc"}
														onClick={() => handleSort("day")}
													>
														Dia
													</TableSortLabel>
												</TableCell>
												<TableCell>
													<TableSortLabel
														active={orderBy === "month"}
														direction={orderBy === "month" ? direction : "asc"}
														onClick={() => handleSort("month")}
													>
														Mês
													</TableSortLabel>
												</TableCell>
												<TableCell>
													<TableSortLabel
														active={orderBy === "year"}
														direction={orderBy === "year" ? direction : "asc"}
														onClick={() => handleSort("year")}
													>
														Ano
													</TableSortLabel>
												</TableCell>
												<TableCell>&nbsp;</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{data?.fetchHolidays?.data.map((row) => (
												<TableRow key={row.id}>
													<TableCell>{row.name}</TableCell>
													<TableCell>{row.day}</TableCell>
													<TableCell>{getMonthName(row.month)}</TableCell>
													<TableCell>{row.year}</TableCell>
													<TableCell>
														<Stack
															spacing={1}
															direction="row"
															flex={1}
															justifyContent="flex-end"
														>
															<IconButton
																style={{ padding: 0, marginRight: 24 }}
																aria-label="edit"
																onClick={() => handleOpenEditModal(row)}
															>
																<EditIcon />
															</IconButton>
															<IconButton
																style={{ padding: 0 }}
																aria-label="delete"
																onClick={() => handleDeleteHolidays(row)}
															>
																<DeleteIcon />
															</IconButton>
														</Stack>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								)}
							</TableContainer>

							<DeleteDialog
								open={showDelete}
								handleClose={() => setShowDelete(null)}
								data={subjectToDeleted}
								onDelete={() => {
									enqueueSnackbar("Feriado excluído com sucesso!", {
										variant: "success",
									});
									refetch();
									setShowDelete(false);
								}}
							/>

							{subjectSelected && (
								<EditDialog
									open={openEdit}
									handleClose={() => handleCloseEditModal()}
									data={subjectSelected}
									onEdit={() => {
										enqueueSnackbar("Feriado editado com sucesso!", {
											variant: "success",
										});
										refetch();
										setOpenEdit(false);
										setSubjectSelected(null);
									}}
								/>
							)}

							<CreateHolidayDialog
								open={showCreate}
								handleClose={() => setShowCreate(false)}
								onCreate={() => {
									enqueueSnackbar("Feriado criado com sucesso!", {
										variant: "success",
									});
									setShowCreate(false);
									refetch();
								}}
							/>

							<Fab
								color="primary"
								onClick={() => setShowCreate(true)}
								style={{ position: "fixed", bottom: 48, right: 48 }}
							>
								<AddIcon />
							</Fab>
							{data?.fetchHolidays?.total !== 0 &&
								(media500 ? (
									<TablePagination
										sx={{
											background: "#fff",
										}}
										component="div"
										labelRowsPerPage="Linhas por página:"
										rowsPerPageOptions={[5, 15, 30, 60, 100]}
										count={data?.fetchHolidays?.total}
										page={currentPage}
										onPageChange={(event, newPage) => {
											setCurrentPage(newPage);
										}}
										rowsPerPage={rowsPerPage}
										onRowsPerPageChange={(event) => {
											setRowsPerPage(parseInt(event.target.value));
											setCurrentPage(0);
										}}
									/>
								) : (
									<Box
										sx={{
											position: "fixed",
											bottom: "30px",
											left: "50%",
											transform: "translateX(-50%)",
											padding: "0px",
											backgroundColor: "rgba(25, 118, 210, 0.98)",
											boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
											opacity: showPagination ? 0.95 : 0,
											zIndex: 2,
											borderRadius: "10px",
											transition: "opacity 0.3s ease-in-out",
										}}
									>
										<PaginationStorage
											count={data?.fetchHolidays?.total}
											rowsPerPage={rowsPerPage}
											page={currentPage}
											onPageChange={(event, newPage) => {
												setCurrentPage(newPage);
											}}
											onRowsPerPageChange={(event) => {
												setRowsPerPage(parseInt(event.target.value));
												setCurrentPage(0);
											}}
										/>
									</Box>
								))}
						</Item>
					</div>
				</Container>
			)}
		</Box>
	);
}
