import { gql } from "@apollo/client";

export const CREATE_DOCUMENT = gql`
	mutation (
		$name: String!
		$file: CreateDocumentFileResponse
		$fileBase64: String
		$recipients: [Int]!
	) {
		createDocument(
			name: $name
			file: $file
			fileBase64: $fileBase64
			recipients: $recipients
		)
	}
`;
