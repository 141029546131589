import { gql } from "@apollo/client";

export const CREATE_DYNAMIC_REPORT = gql`
	mutation (
		$type: String!
		$data_source: String!
		$title: String!
		$group_by: String
		$order_by: String
		$order_direction: String
		$visualization_rule: String!
		$columns: [String]
		$spec: String
		$profile_ids: [Int]
		$subject_id: Int
	) {
		createDynamicReport(
			type: $type
			data_source: $data_source
			title: $title
			group_by: $group_by
			order_by: $order_by
			order_direction: $order_direction
			visualization_rule: $visualization_rule
			columns: $columns
			spec: $spec
			profile_ids: $profile_ids
			subject_id: $subject_id
		)
	}
`;
