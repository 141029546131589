import { useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { DetailProtocol } from "types/graphql/queries/detailProtocol";
import { Backdrop, Button, useMediaQuery } from "@mui/material";

type ItemData = {
	url: string;
	name: string;
};

type ImgListProps = {
	itemsData: Array<ItemData>;
};

type BackdropImgProps = {
	open: {
		state: boolean;
		url: string;
	};
	handleClose: () => void;
};

const ImgList = ({ itemsData }: ImgListProps) => {
	const isSmallScreen = useMediaQuery("(max-width: 600px)");
	const [open, setOpen] = useState({
		state: false,
		url: null,
	});

	const handleClose = () => {
		setOpen({ state: false, url: null });
	};
	const handleToggle = (itemUrl: string) => {
		setOpen({ state: !open.state, url: itemUrl });
	};

	const data: Array<ItemData> = itemsData.filter((file) => {
		const split = file.name.split(".")[1];
		if (split === "jpg" || split === "png" || split === "jpeg" || split === "gif") {
			return file;
		}
	});

	return (
		<>
			<ImageList sx={{ width: "100%" }} gap={10} cols={isSmallScreen ? 1 : 3}>
				{data.map((item) => (
					<ImageListItem
						key={item.url}
						style={{ cursor: "pointer" }}
						onClick={() => handleToggle(item.url)}
					>
						<img
							style={{
								minWidth: 180,
								minHeight: 180,
								boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
							}}
							src={`${item.url}?w=180&h=180&fit=crop&auto=format`}
							srcSet={`${item.url}?w=180&h=180&fit=crop&auto=format&dpr=2 2x`}
							alt={item.name}
							loading="lazy"
						/>
					</ImageListItem>
				))}
				{open.state && <BackdropImg {...{ open, handleClose }} />}
			</ImageList>
		</>
	);
};

const BackdropImg = ({ open, handleClose }: BackdropImgProps) => {
	return (
		<Backdrop
			sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={open.state}
			onClick={handleClose}
		>
			<img
				style={{ maxWidth: 600 }}
				src={`${open.url}`}
				srcSet={`${open.url}`}
				loading="lazy"
			/>
			{/* <Button
                style={{
                    position: "absolute",
                    top: 20,
                    right: 20
                }}
                variant="contained"
                color="info"
                onClick={() => {
                    window.open(open.url, '_blank');
                }}
            >
                Abrir em nova guia
            </Button> */}
		</Backdrop>
	);
};

export default ImgList;
