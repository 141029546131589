import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { CircularProgress } from "components/CircularProgress";
import { CSVDownloader } from "components/ExportCSV/CSVDownloader";
import { LinearProgressWithLabel } from "components/LinearProgress";
import { ModalLayout } from "components/ModalLayout";
import { FETCH_POINTS_BY_MONTH } from "graphql/queries/fetchPointsByMonth";
import { FetchPointsByMonth } from "types/graphql/queries/fetchPointsByMonth";
import { ModalLayoutProps } from "types/ModalLayout";
import { closeModalExportCsv } from "utils/closeModalExportCsv";

type ModalExportCSVProps = ModalLayoutProps & {
	orderBy: string;
	direction: string;
	debouncedValue: string;
	year: number;
	month: number;
	responsibles: any;
};

export function ModalExportCSV({
	open,
	handleClose,
	orderBy,
	direction,
	debouncedValue,
	year,
	month,
	responsibles,
}: ModalExportCSVProps) {
	const { data, loading, refetch } = useQuery<FetchPointsByMonth>(
		FETCH_POINTS_BY_MONTH,
		{
			fetchPolicy: "network-only",
			variables: {
				year: year,
				month: month,
				order_by: orderBy,
				direction: direction,
				keyword: debouncedValue,
				responsibles:
					responsibles && responsibles.length > 0
						? responsibles.map((item) => item.id)
						: null,
			},
		}
	);

	const rowsCsv = data?.fetchPointsByMonth?.map((points) => [
		points.user.params ? JSON.parse(points.user.params).matricula : " - ",
		points.user.name,
		points.year_month ? points.year_month.split("-")[1] : " - ",
		points.year_month ? points.year_month.split("-")[0] : " - ",
		points.accumulated,
	]);

	const headersCsv = [
		"Matrícula",
		"Colaborador",
		"Mês",
		"Ano",
		"Total de pontos",
	];

	const percentageDataExport = Math.round(
		(data?.fetchPointsByMonth?.length * 100) / data?.fetchPointsByMonth?.length
	);

		if(percentageDataExport === 100) {
		closeModalExportCsv(
			100,
			handleClose,
		);
	}

	return (
		<ModalLayout
			open={open}
			handleClose={handleClose}
			title="Exportar CSV"
			isNotShowButtonSubmit
			buttonTextClose="Cancelar"
		>
			<>
				{loading ? (
					<CircularProgress />
				) : (
					<>
						{data && (
							<CSVDownloader rowsCsv={rowsCsv} headersCsv={headersCsv} />
						)}
						<Box sx={{ width: "100%" }}>
							<LinearProgressWithLabel
								value={percentageDataExport}
								current={data?.fetchPointsByMonth?.length}
								total={data?.fetchPointsByMonth?.length}
							/>
						</Box>
					</>
				)}
			</>
		</ModalLayout>
	);
}
