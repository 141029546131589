import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
	Box,
	Fab,
	Container,
	TablePagination,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	TableBody,
	Table,
	IconButton,
	Stack,
	TableSortLabel,
	TextField,
	useMediaQuery,
} from "@mui/material";
import { Item } from "components/Paper";
import { Header } from "components/Header";
import { EmptyMessage } from "components/EmptyMessage";
import noUser from "assets/img/noUser.png";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useSnackbar } from "notistack";
import { FETCH_DISTRICTS } from "graphql/queries/fetchDistricts";
import { FetchDistricts } from "types/graphql/queries/fetchDistricts";
import AddIcon from "@mui/icons-material/Add";
import DeleteDialog from "./DeleteDialog";
import EditDialog from "./EditDialog";
import CreateDistrictDialog from "./CreateDistrictDialog";
import useDebounce from "hooks/useDebounce";
import LocalProvider from "components/LocalProvider";
import { CircularProgress } from "components/CircularProgress";
import PaginationStorage from "components/PaginationStorage";

export function Districts() {
	const [showDelete, setShowDelete] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [districtToDeleted, setDistrictToDelete] = useState(null);
	const [showCreate, setShowCreate] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [districtSelected, setDistrictSelected] = useState(null);
	const [orderBy, setOrderBy] = useState("name");
	const [direction, setDirection] = useState<"asc" | "desc">("asc");
	const [keyword, setKeyword] = useState("");
	const [showPagination, setShowPagination] = useState(false);

	const debouncedValue = useDebounce(keyword, 1000);

	const subdomain = window.location.hostname.split(".")[0];

	const { data, refetch, loading } = useQuery<FetchDistricts>(FETCH_DISTRICTS, {
		variables: {
			domain:
				subdomain === "localhost"
					? process.env.REACT_DEFAULT_DOMAIN || "lagoasanta"
					: subdomain,
			limit: rowsPerPage,
			page: currentPage + 1,
			order_by: orderBy,
			direction: direction,
			keyword: debouncedValue,
		},
	});

	function handleDeleteDistricts(item) {
		setDistrictToDelete(item);
		setShowDelete(true);
	}

	const handleOpenEditModal = (district) => {
		setDistrictSelected(district);
		setOpenEdit(true);
	};

	const handleCloseEditModal = () => {
		setOpenEdit(false);
		setDistrictSelected(null);
	};

	function handleSort(name) {
		const isAsc = orderBy === name && direction === "asc";
		setDirection(isAsc ? "desc" : "asc");
		setOrderBy(name);
	}

	const media500 = useMediaQuery("(max-width:500px)");

	return (
		<Box
			width="100%"
			display="flex"
			alignSelf="stretch"
			flexDirection="column"
			sx={{
				backgroundColor: "#f3f3f3",
			}}
			paddingBottom="1rem"
		>
			<Header title="Bairros" isReturn />

			{loading ? (
				<CircularProgress />
			) : (
				<Container maxWidth="lg" sx={{ marginTop: "1rem" }}>
					<div
						style={{ position: "relative", marginBottom: "60px" }}
						onMouseEnter={() => setShowPagination(true)}
						onMouseLeave={() => setShowPagination(false)}
					>
						<Item
							sx={{
								paddingX: 0,
								paddingY: "1px",
							}}
						>
							<Box sx={{ padding: 2 }}>
								<TextField
									fullWidth
									variant="standard"
									name="keyword"
									type="search"
									label="Pesquisar..."
									value={keyword}
									onChange={(event) => {
										setKeyword(event.target.value);
									}}
								/>
							</Box>

							<TableContainer style={{ marginTop: "10px" }}>
								{data?.fetchDistricts?.total === 0 && (
									<>
										<EmptyMessage message="Nenhum feriado encontrado 😥" />
										<Box
											marginTop={2}
											sx={{
												backgroundImage: `url(${noUser})`,
												backgroundRepeat: "no-repeat",
												backgroundPosition: "center",
												backgroundSize: "contain",
												height: "200px",
											}}
										/>
									</>
								)}
								{data?.fetchDistricts?.total !== 0 && (
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>
													<TableSortLabel
														active={orderBy === "name"}
														direction={orderBy === "name" ? direction : "asc"}
														onClick={() => handleSort("name")}
													>
														Nome
													</TableSortLabel>
												</TableCell>
												<TableCell>status</TableCell>
												<TableCell>&nbsp;</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{data?.fetchDistricts?.data.map((row) => (
												<>
													<TableRow key={row.id}>
														<TableCell>{row.name}</TableCell>
														<TableCell>{row.status}</TableCell>
														<TableCell>
															<Stack
																spacing={1}
																direction="row"
																flex={1}
																justifyContent="flex-end"
															>
																<IconButton
																	style={{ padding: 0, marginRight: 24 }}
																	aria-label="edit"
																	onClick={() => handleOpenEditModal(row)}
																>
																	<EditIcon />
																</IconButton>
																<IconButton
																	style={{ padding: 0 }}
																	aria-label="delete"
																	onClick={() => handleDeleteDistricts(row)}
																>
																	<DeleteIcon />
																</IconButton>
															</Stack>
														</TableCell>
													</TableRow>
												</>
											))}
										</TableBody>
									</Table>
								)}
							</TableContainer>

							<DeleteDialog
								open={showDelete}
								handleClose={() => setShowDelete(null)}
								data={districtToDeleted}
								onDelete={() => {
									enqueueSnackbar("Bairro excluído com sucesso!", {
										variant: "success",
									});
									refetch();
									setShowDelete(false);
								}}
							/>

							{districtSelected && (
								<EditDialog
									open={openEdit}
									handleClose={() => handleCloseEditModal()}
									data={districtSelected}
									onEdit={() => {
										enqueueSnackbar("Bairro editado com sucesso!", {
											variant: "success",
										});
										refetch();
										setOpenEdit(false);
										setDistrictSelected(null);
									}}
								/>
							)}

							<CreateDistrictDialog
								data={data}
								open={showCreate}
								handleClose={() => setShowCreate(false)}
								onCreate={() => {
									enqueueSnackbar("Bairro criado com sucesso!", {
										variant: "success",
									});
									setShowCreate(false);
									refetch();
								}}
							/>

							<Fab
								color="primary"
								onClick={() => setShowCreate(true)}
								style={{ position: "fixed", bottom: 48, right: 48 }}
							>
								<AddIcon />
							</Fab>
							{data?.fetchDistricts?.total !== 0 &&
								(media500 ? (
									<TablePagination
										sx={{
											background: "#fff",
										}}
										component="div"
										labelRowsPerPage="Linhas por página:"
										rowsPerPageOptions={[5, 15, 30, 60, 100]}
										count={data?.fetchDistricts?.total}
										page={currentPage}
										onPageChange={(event, newPage) => {
											setCurrentPage(newPage);
										}}
										rowsPerPage={rowsPerPage}
										onRowsPerPageChange={(event) => {
											setRowsPerPage(parseInt(event.target.value));
											setCurrentPage(0);
										}}
									/>
								) : (
									<Box
										sx={{
											position: "fixed",
											bottom: "30px",
											left: "50%",
											transform: "translateX(-50%)",
											padding: "0px",
											backgroundColor: "rgba(25, 118, 210, 0.98)",
											boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
											opacity: showPagination ? 0.95 : 0,
											zIndex: 2,
											borderRadius: "10px",
											transition: "opacity 0.3s ease-in-out",
										}}
									>
										<PaginationStorage
											count={data?.fetchDistricts?.total}
											rowsPerPage={rowsPerPage}
											page={currentPage}
											onPageChange={(event, newPage) => {
												setCurrentPage(newPage);
											}}
											onRowsPerPageChange={(event) => {
												setRowsPerPage(parseInt(event.target.value));
												setCurrentPage(0);
											}}
										/>
									</Box>
								))}
						</Item>
					</div>
				</Container>
			)}
		</Box>
	);
}
