import React, { useMemo } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as locales from "@mui/material/locale";

interface LocalProviderProps {
  locale: "ptBR";
	children: any;
}

const LocalProvider: React.FC<LocalProviderProps> = ({
  children,
  locale,
}) => {
  const theme = useMemo(
    () => createTheme(locales[locale]),
    [locale],
  );

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

export default LocalProvider;
