import { gql } from "@apollo/client";

export const CREATE_SERVICE_ORDER = gql`
	mutation (
		$subject_id: Int!
		$responsible_user_id: Int!
		$type: String!
		$expire_at: String!
		$description: String!
		$zipcode: String
		$street: String!
		$number: String
		$complement: String
		$district_id: Int
	) {
			createServiceOrder(
				subject_id: $subject_id
				responsible_user_id: $responsible_user_id
				type: $type
				expire_at: $expire_at
				description: $description
				zipcode: $zipcode
				street: $street
				number: $number
				complement: $complement
				district_id: $district_id
			)
		}
`;
