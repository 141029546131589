import { gql } from "@apollo/client";

export const DETAIL_PROTOCOL = gql`
	query ($code: String!) {
		detailProtocol(code: $code) {
			code
			situation
			expire_at
			created_at
			updated_at
			street
			number
			complement
			zipcode
			district {
				id
				name
				city {
					id
					name
					state
				}
			}
			address_extras
			justification
			outdated_flow
			created_by {
				id
				name
			}
			suspended_by {
				id
				name
				suspended_at
				justification_attachments {
					id
					name
					url
				}
			}
			canceled_by {
				id
				name
				canceled_at
				justification_attachments {
					id
					name
					url
				}
			}
			responsible {
				id
				name
			}
			subject {
				id
				name
				regulatory_acts {
					id
					name
					description
				}
			}
			flow {
				id
				name
				current_version
				used_version
				auto_finish_on_complaint_refused
			}
			status
			complaint {
				code
				name
				email
				zipcode
				address
				number
				complement
				reference
				district
				description
				attachments {
					id
					name
					url
					created_at
					created_by {
						id
						name
					}
				}
			}
			tasks {
				id
				name
				theme
				type
				answer
				options {
					label
					required_attached
				}
				points
				expire_at
				concluded_at
				created_at
				updated_at
				undo_at
				resolve_task
				user {
					id
					name
					params {
						matricula
					}
				}
				perfil {
					id
					name
				}
				detail {
					form_groups
					form_response
					attachments {
						id
						name
						description
						url
						created_at
						created_by {
							id
							name
						}
					}
				}
				required_attachment
				required_description
				advance_next_task
			}
			params
			attachments {
				id
				name
				url
				created_at
				created_by {
					id
					name
				}
			}
		}
	}
`;
