export const dateTypes = [
	{
		label: "Ano",
		value: "year",
	},
	{
		label: "Mês",
		value: "month",
	},
	{
		label: "Dia",
		value: "day",
	},
];

export const year = [
	{
		label: "Últimos 2 anos",
		value: 2,
	},
	{
		label: "Últimos 5 anos",
		value: 5,
	},
	{
		label: "Últimos 10 anos",
		value: 10,
	},
];

export const month = [
	{
		label: "Últimos 2 meses",
		value: 2,
	},
	{
		label: "Últimos 4 meses",
		value: 4,
	},
	{
		label: "Últimos 6 meses",
		value: 6,
	},
	{
		label: "Últimos 12 meses",
		value: 12,
	},
];

export const day = [
	{
		label: "Últimos 7 dias",
		value: 7,
	},
	{
		label: "Últimos 14 dias",
		value: 14,
	},
	{
		label: "Últimos 30 dias",
		value: 30,
	},
	{
		label: "Personalizado",
		value: "custom",
	},
];
