import { Grid, Typography, Box } from "@mui/material";

interface FieldWitnessProps {
	values?: Array<{
		[key: string]: string | number | boolean | Array<string | number | boolean>;
	}>;
}

function FieldWitness({ values }: FieldWitnessProps) {
	return (
		<>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">Testemunha 1</Typography>
					<Typography variant="subtitle2">
						{(values && values[0].witness1 && values[0].witness1) || "-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6} alignSelf="self-end">
				<Box>
					<Typography variant="caption">Identidade ou CPF</Typography>
					<Typography variant="subtitle2">
						{(values &&
							values[0].documentWitness1 &&
							values[0].documentWitness1) ||
							"-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">Testemunha 2</Typography>
					<Typography variant="subtitle2">
						{(values && values[0].witness2 && values[0].witness2) || "-"}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="caption">Identidade ou CPF 2</Typography>
					<Typography variant="subtitle2">
						{(values &&
							values[0].documentWitness2 &&
							values[0].documentWitness2) ||
							"-"}
					</Typography>
				</Box>
			</Grid>
		</>
	);
}

export default FieldWitness;
