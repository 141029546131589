import { gql } from "@apollo/client";

export const DETAIL_DYNAMIC_REPORT = gql`
	query ($id: Int!, $limit: Int, $page: Int, $year: String) {
		detailDynamicReport(id: $id, limit: $limit, page: $page, year: $year) {
			id
			created_by {
				id
				name
				email
				params {
					matricula
				}
			}
			type
			data_source
			title
			version
			group_by
			order_by
			order_direction
			visualization_rule
			columns
			spec
			profiles {
				id
				name
			}
			history
			created_at
			updated_at
			subject_id
		}
	}
`;
