import { gql } from "@apollo/client";

export const UPDATE_DISTRICT = gql`
	mutation updateDistrict(
		$id: Int!
		$name: String!
		$city_id: Int!
		$latitude: String
		$longitude: String
		$status: String!
	) {
		updateDistrict(
			id: $id
			name: $name
			city_id: $city_id
			latitude: $latitude
			longitude: $longitude
			status: $status
		)
	}
`;
