import { gql } from "@apollo/client";

export const FETCH_GROUPS = gql`
	query (
		$keyword: String
		$order_by: String
		$direction: String
		$page: Int
		$limit: Int
		$subject_id: Int
	) {
		fetchGroups(
			keyword: $keyword
			order_by: $order_by
			direction: $direction
			page: $page
			limit: $limit
			subject_id: $subject_id
		) {
			total
			current_page
			per_page
			last_page
			data {
				id
				company_id
				code
				name
				created_at
				status
				description
				limit_points_day
			}
		}
	}
`;
