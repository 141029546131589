import { Stack } from "@mui/material";
import { WrapperFilterInfo } from "components/Filter/WrapperFilterInfo";
import { countTasks } from "types/graphql/queries/countTasks";

type CountTaskProps = {
	countTasks: countTasks;
};

export default function CountTask({ countTasks }: CountTaskProps) {
	return (
		<>
			<Stack spacing={2} marginTop={2}>
				<WrapperFilterInfo
					title="Total"
					value={countTasks?.countTasks.total}
					color="black"
					fontSize={"1.5rem"}
				/>
				<WrapperFilterInfo
					title="Para hoje"
					value={countTasks?.countTasks.today}
					color="orange"
					fontSize={"1.5rem"}
				/>
				<WrapperFilterInfo
					title="Suspensas"
					value={countTasks?.countTasks.suspended}
					fontSize={"1.5rem"}
				/>
				<WrapperFilterInfo
					title="Canceladas"
					value={countTasks?.countTasks.canceled}
					fontSize={"1.5rem"}
				/>
				<WrapperFilterInfo
					title="Futuras"
					value={countTasks?.countTasks.future}
					color="blue"
					fontSize={"1.5rem"}
				/>
				<WrapperFilterInfo
					title="Atrasadas"
					value={countTasks?.countTasks.late}
					color="red"
					fontSize={"1.5rem"}
				/>
				<WrapperFilterInfo
					title="Concluidas"
					value={countTasks?.countTasks.concluded}
					color="green"
					fontSize={"1.5rem"}
				/>
			</Stack>
		</>
	);
}