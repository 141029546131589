import { Stack, Typography } from "@mui/material";
import { format, parse } from "date-fns";
import { Task } from "types/graphql/queries/detailProtocol";

interface DetailUndoTaskProps {
	task: Task;
}

export function DetailUndoTask({ task }: DetailUndoTaskProps) {
	const parsedDate = task.updated_at
		? parse(task.updated_at, "yyyy-MM-dd HH:mm:ss", new Date())
		: null;
	return (
		<Stack minHeight={100}>
			<Typography variant="subtitle2" fontWeight={500} fontSize="0.9rem">
				{task.name}
			</Typography>
			<Typography variant="caption" fontSize="0.8rem">
				Desfeita em {format(parsedDate, "dd/MM/yyyy HH:mm:ss")}
			</Typography>
			<Typography variant="caption" fontSize="0.8rem">
				Por {task.user?.name}
			</Typography>
			{task.user?.params?.matricula && (
				<Typography variant="caption" fontSize="0.8rem">
					Matrícula {task.user?.params?.matricula}
				</Typography>
			)}
		</Stack>
	);
}
