import { gql } from "@apollo/client";

export const FETCH_OCCURRENCE_TYPES = gql`
	query (
		$keyword: String
		$order_by: String
		$direction: String
		$page: Int
		$limit: Int
	) {
		fetchOccurrenceTypes(
			keyword: $keyword
			order_by: $order_by
			direction: $direction
			page: $page
			limit: $limit
		) {
			total
			current_page
			per_page
			last_page
			data {
				id
				company_id
				dynamic_form {
					code
					id
					name
				}
				code
				name
				description
				intimationText
				status
				created_at
				autofill_regulatory_acts
			}
		}
	}
`;
