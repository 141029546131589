import { Box, Stack, Typography } from "@mui/material";
import Button from "components/Button";
import { useFormContext } from "react-hook-form";
import { FormData, Recipient } from "..";
import LoadingButton from "components/LoadingButton";
import { useMutation } from "@apollo/client";
import { CREATE_DOCUMENT } from "graphql/mutations/createDocument";
import { useSnackbar } from "notistack";
import { filePreview } from "utils/filePreview";

interface ToSendProps {
	goToPrevStep: () => void;
	handleClose?: () => void;
	resetSteps?: () => void;
	refetch?: () => void;
}

export function ToSend({
	goToPrevStep,
	handleClose,
	resetSteps,
	refetch,
}: ToSendProps) {
	const [createDocument, { loading }] = useMutation(CREATE_DOCUMENT);
	const { enqueueSnackbar } = useSnackbar();
	const { watch, handleSubmit, reset } = useFormContext();
	const recipients = watch("recipients");
	const file = watch("file");

	const onSubmit = async (data: FormData) => {
		try {
			await createDocument({
				variables: {
					name: data.name,
					file: data.file,
					recipients: data.recipients.map(
						(recipient: Recipient) => recipient.idUser
					),
				},
			});
			enqueueSnackbar("Documento enviado com sucesso", { variant: "success" });
			reset();
			refetch();
			handleClose();
			resetSteps();
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
	};
	return (
		<Stack spacing={4} mt={4}>
			<Stack spacing={2}>
				{file && (
					<Stack>
						<Typography variant="h6" fontSize="1rem">
							Termo de Contrato
						</Typography>
						{filePreview(file)}
					</Stack>
				)}
				{recipients.map((recipient: Recipient) => (
					<Box key={recipient.id}>
						{recipient.name} &lt;{recipient.email}&gt;
					</Box>
				))}
			</Stack>

			<Stack direction="row" spacing={2}>
				<LoadingButton
					variant="contained"
					sx={{
						maxWidth: "100px",
					}}
					loading={loading}
					onClick={handleSubmit(onSubmit)}
				>
					Enviar
				</LoadingButton>
				<Button
					variant="outlined"
					sx={{
						maxWidth: "100px",
					}}
					onClick={goToPrevStep}
					disabled={loading}
				>
					Voltar
				</Button>
			</Stack>
		</Stack>
	);
}
