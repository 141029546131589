import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import GenericInput from "components/Input/GenericInput";
import { ModalLayout } from "components/ModalLayout";
import SelectFilterModal from "components/selectFilterModal";
import { FETCH_DISTRICTS } from "graphql/queries/fetchDistricts";
import { FETCH_SUBJECTS } from "graphql/queries/fetchSubjects";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { format, parse } from "date-fns";
import SelectFilterNotUseQuery from "components/selectFilterModal/SelectFilterNotUseQuery";
import { FETCH_STATUS } from "graphql/queries/fetchStatus";

interface TasksFilterProps {
	open: boolean;
	handleClose: () => void;
	startDate?: string | null;
	finishDate?: string | null;
	upfmls: boolean | null;
	filters: Array<{
		id: number;
		title: string;
		options: Array<{
			id: number;
			name: string;
		}>;
	}>;
	lateDays: string | null;
	street: string | null;
	number: string | null;
	keyword: string;
	lote: string | null;
	quadra: string | null;
	cadastralIndex: string | null;
	offendersName: string | null;
	tasksLabel: Array<{
		id: number;
		name: string;
	}>;
	handleFilter: (values) => void;
}

const lateDaysOptions = [
	{
		id: 1,
		name: "atrasa hoje",
		value: "today",
	},
	{
		id: 2,
		name: "em até 7 dias",
		value: "7",
	},
	{
		id: 3,
		name: "em até 15 dias",
		value: "15",
	},
	{
		id: 4,
		name: "em até 1 mês",
		value: "1m",
	},
	{
		id: 5,
		name: "em mais de 1 mês",
		value: "+1m",
	},
	{
		id: 6,
		name: "Atrasados",
		value: "delayed",
	},
];

export function TasksFilter({
	handleClose,
	open,
	keyword,
	lateDays,
	filters,
	number,
	street,
	upfmls,
	finishDate,
	startDate,
	lote,
	quadra,
	cadastralIndex,
	offendersName,
	handleFilter,
	tasksLabel,
}: TasksFilterProps) {
	const subdomain = window.location.hostname.split(".")[0];
	const [startDateDialog, setStartDateDialog] = useState<Date | null>(
		startDate ? parse(startDate, "yyyy-MM-dd", new Date()) : null
	);

	const [finishDateDialog, setFinishDateDialog] = useState<Date | null>(
		finishDate ? parse(finishDate, "yyyy-MM-dd", new Date()) : null
	);

	const [search, setSearch] = useState(keyword);
	const [situation, setSituation] = useState(filters);
	const [lateDaysFilter, setLateDaysFilter] = useState(lateDays);
	const [streetDialog, setStreetDialog] = useState(street);
	const [numberDialog, setNumberDialog] = useState(number);
	const [loteDialog, setLoteDialog] = useState(lote);
	const [quadraDialog, setQuadraDialog] = useState(quadra);
	const [cadastralIndexDialog, setCadastralIndexDialog] =
		useState(cadastralIndex);
	const [offendersNameDialog, setOffendersNameDialog] = useState(offendersName);
	const [upfmlsFilter, setUpfmlsFilter] = useState(upfmls);

	const handleAddedSituation = (values) => {
		const title = values.find((item) => item.title).title;
		const index = situation.findIndex((item) => item.title === title);
		const newSituations = [...situation];
		newSituations[index].options = values.filter((item) => !item.title);
		setSituation(newSituations);
	};

	const handleClear = () => {
		setStartDateDialog(null);
		setFinishDateDialog(null);
		setSituation([
			{
				id: 0,
				title: "Situação do protocolo",
				options: [],
			},
			{
				id: 1,
				title: "Assunto",
				options: [],
			},
			{
				id: 2,
				title: "Status do protocolo",
				options: [],
			},
			{
				id: 3,
				title: "Bairro",
				options: [],
			},
			{
				id: 4,
				title: "Tarefa atual",
				options: [],
			},
		]);
		setStreetDialog("");
		setNumberDialog("");
		setLateDaysFilter("");
		setSearch("");
		setLoteDialog("");
		setQuadraDialog("");
		setCadastralIndexDialog("");
		setOffendersNameDialog("");
		setUpfmlsFilter(null);
	};

	const valuesSituation = [
		{
			id: 1,
			name: "Em andamento",
		},
		{
			id: 2,
			name: "Concluido",
		},
		{
			id: 3,
			name: "Cancelado",
		},
		{
			id: 4,
			name: "Suspenso",
		},
	];

	const onRequestFilter = () => {
		const filter = {
			startDate: startDateDialog
				? format(new Date(startDateDialog), "yyyy-MM-dd")
				: null,
			finishDate: finishDateDialog
				? format(new Date(finishDateDialog), "yyyy-MM-dd")
				: null,
			keyword: search,
			upfmls: upfmlsFilter,
			lateDays: lateDaysFilter,
			street: streetDialog,
			number: numberDialog,
			lote: loteDialog,
			quadra: quadraDialog,
			cadastralIndex: cadastralIndexDialog,
			offendersName: offendersNameDialog,
			situation,
		};

		handleFilter(filter);
	};

	const upfmlsOptions = [
		{
			id: 1,
			name: "SIM",
			value: true,
		},
		{
			id: 2,
			name: "NÃO",
			value: false,
		},
	];

	return (
		<ModalLayout
			open={open}
			handleClose={handleClose}
			title="Filtrar tarefas"
			buttonTextClose="Limpar filtros"
			buttonText="Filtrar"
			additionalFuntion={handleClear}
			handleSubmit={onRequestFilter}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} marginTop={2}>
					<GenericInput
						placeholder="Pesquisar"
						endAdornment={<SearchIcon color="disabled" />}
						onChange={(e) => setSearch(e.target.value)}
						value={search}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale="pt-br"
					>
						<DatePicker
							disableFuture
							inputFormat="DD/MM/YYYY"
							label="Início"
							value={startDateDialog}
							onChange={(newValue) => {
								setStartDateDialog(newValue);
							}}
							renderInput={(params) => (
								<TextField {...params} sx={{ width: "100%" }} />
							)}
						/>
					</LocalizationProvider>
				</Grid>

				<Grid item xs={12} sm={6}>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale="pt-br"
					>
						<DatePicker
							disableFuture
							inputFormat="DD/MM/YYYY"
							label="Fim"
							value={finishDateDialog}
							onChange={(newValue) => {
								setFinishDateDialog(newValue);
							}}
							renderInput={(params) => (
								<TextField {...params} sx={{ width: "100%" }} />
							)}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid item xs={12}>
					<FormControl fullWidth>
						<InputLabel htmlFor={"dialog-prazo de resolução"}>
							Prazo de resolução
						</InputLabel>
						<Select
							input={
								<OutlinedInput
									label={"Prazo de resolução"}
									id={"dialog-prazo de resolução"}
								/>
							}
							value={lateDaysFilter}
							onChange={(event) => {
								setLateDaysFilter(event.target.value);
							}}
						>
							<MenuItem value={null}>
								<em>Nenhum</em>
							</MenuItem>
							{lateDaysOptions.map((option) => (
								<MenuItem key={option.id} value={String(option.value)}>
									{option.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<SelectFilterNotUseQuery
						title="Situação do protocolo"
						fullWidth
						onChange={(values) => {
							handleAddedSituation(values);
						}}
						loading={false}
						selectValues={valuesSituation}
						valuesSelecteds={situation[0].options.map((item) => ({
							id: item.id,
							name: item.name,
						}))}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<SelectFilterModal
						title="Assunto"
						fullWidth
						onChange={(values) => {
							handleAddedSituation(values);
						}}
						queryName={FETCH_SUBJECTS}
						order_by="subjects.name"
						dataName="fetchSubjects"
						valuesSelecteds={situation[1].options.map((item) => ({
							id: item.id,
							name: item.name,
						}))}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<SelectFilterModal
						title="Status do protocolo"
						fullWidth
						onChange={(values) => {
							handleAddedSituation(values);
						}}
						queryName={FETCH_STATUS}
						order_by="status.name"
						dataName="fetchStatus"
						valuesSelecteds={situation[2].options.map((item) => ({
							id: item.id,
							name: item.name,
						}))}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth>
						<InputLabel htmlFor={"dialog-GerouUPFMLS"}>Gerou UPFMLS</InputLabel>
						<Select
							input={
								<OutlinedInput
									label={"Gerou UPFMLS"}
									id={"dialog-GerouUPFMLS"}
								/>
							}
							value={upfmlsFilter}
							onChange={(event) => {
								setUpfmlsFilter(
									JSON.parse(String(event.target.value).toLowerCase())
								);
							}}
						>
							<MenuItem value={null}>
								<em>Nenhum</em>
							</MenuItem>
							{upfmlsOptions.map((option) => (
								<MenuItem key={option.id} value={String(option.value)}>
									{option.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} md={6}>
					<TextField
						label="Rua"
						fullWidth
						value={streetDialog}
						onChange={(e) => setStreetDialog(e.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<SelectFilterNotUseQuery
						title="Tarefa atual"
						fullWidth
						onChange={(values) => {
							handleAddedSituation(values);
						}}
						loading={false}
						selectValues={tasksLabel}
						valuesSelecteds={situation[4].options.map((item) => ({
							id: item.id,
							name: item.name,
						}))}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField
						label="Número"
						fullWidth
						value={numberDialog}
						onChange={(e) => setNumberDialog(e.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<SelectFilterModal
						title="Bairro"
						fullWidth
						onChange={(values) => {
							handleAddedSituation(values);
						}}
						queryName={FETCH_DISTRICTS}
						order_by="name"
						moreVariables={{
							domain:
								subdomain === "localhost"
									? process.env.REACT_DEFAULT_DOMAIN || "lagoasanta"
									: subdomain,
						}}
						dataName="fetchDistricts"
						valuesSelecteds={situation[3].options.map((item) => ({
							id: item.id,
							name: item.name,
						}))}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						label="Lote"
						fullWidth
						value={loteDialog}
						onChange={(e) => setLoteDialog(e.target.value)}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						label="Quadra"
						fullWidth
						value={quadraDialog}
						onChange={(e) => setQuadraDialog(e.target.value)}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						label="Inscrição cadastral"
						fullWidth
						value={cadastralIndexDialog}
						onChange={(e) => setCadastralIndexDialog(e.target.value)}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						label="Nome do autuado"
						fullWidth
						value={offendersNameDialog}
						onChange={(e) => setOffendersNameDialog(e.target.value)}
					/>
				</Grid>
			</Grid>
		</ModalLayout>
	);
}
