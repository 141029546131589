import { gql } from "@apollo/client";

export const DETAIL_DOCUMENT = gql`
	query ($id: Int!) {
		detailDocument(id: $id) {
			id
			name
			doc_base64
			events_base64
			sender {
				id
				name
				email
				perfil_id
			}
			recipients {
				id
				user {
					id
					name
					email
					perfil_id
				}
				is_signed
				sign_code
				signed_at
			}
			events {
				id
				sender {
					id
					name
					email
				}
				recipient {
					id
					name
					email
					sign_code
				}
				ip_address
				is_created
				is_first_view
				is_signed
				created_at
			}
			total_signed
			expire_days
			deleted_at
			updated_at
			created_at
		}
	}
`;
