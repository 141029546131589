import { gql } from "@apollo/client";

export const FETCH_COMPLAINTS_TO_EVALUTE = gql`
	query (
		$keyword: String
		$order_by: String
		$direction: String
		$page: Int
		$limit: Int
	) {
		fetchComplaintsToEvaluate(
			keyword: $keyword
			order_by: $order_by
			direction: $direction
			page: $page
			limit: $limit
		) {
			total
			current_page
			per_page
			last_page
			data {
				id
				company_id
				protocol {
					id
					code
				}
				code
				name
				email
				zipcode
				address
				number
				complement
				reference
				district_id
				created_at
				updated_at
			}
		}
	}
`;
