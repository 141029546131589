import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { useFormContext } from "react-hook-form";

interface CheckboxButtonProps {
	checks: Array<{
		name: string;
		label: string;
	}>;
}

export function CheckboxButtonGroup({ checks }: CheckboxButtonProps) {
	const { register, watch } = useFormContext();
	const watchAllFields = watch();

	return (
		<FormGroup>
			{checks.map((item) => (
				<FormControlLabel
					control={<Checkbox defaultChecked={watchAllFields[item.name]} />}
					label={item.label}
					key={item.label}
					{...register(item.name)}
				/>
			))}
		</FormGroup>
	);
}
