import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export function Missing() {
	return (
		<article style={{ padding: "100px" }}>
			<Typography variant="h1">Oops!</Typography>
			<Typography variant="body1">
				Página não encontrada Ou você não pode acessá-la
			</Typography>
			<div
				style={{
					flexGrow: 1,
				}}
			>
				<Link to="/">Ir para HomePage</Link>
			</div>
		</article>
	);
}
