import {
	Grid,
	Typography,
	Stack,
	Box,
	Divider,
	FormHelperText,
	Autocomplete,
	TextField,
	OutlinedInput,
	FormControl,
	CircularProgress,
	useMediaQuery,
} from "@mui/material";
import { Select } from "components/Select";
import { RadioButton } from "components/RadioButton";
import Input from "components/Input";
import { TitleAndValues } from "./components/TitleAndValues";
import { DynamicGraphics } from "./components/DynamicGraphics";
import { ModalSharePerfis } from "pages/Reports/CustomReports/ModalSharePerfis";
import { useState, Fragment, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import {
	dataSource,
	defaultData,
	defaultSeries,
	dynamicGraphics,
	titles,
	visualizationRules,
} from "./consts";
import { useSnackbar } from "notistack";
import { v4 as uuid } from "uuid";
import { Graphic, Title } from "../../ModalCreateReport";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "components/IconButton";
import { ModalAddTypeOfData } from "./Modal/ModalAddTypeOfData";
import { ModalEditGraphic } from "./Modal/ModalEditGraphic";
import { removeSpecialCharacters } from "utils/removeSpecialCharacters";
import { getLastDays, getLastMonths, getLastYears } from "utils/getLastDates";
import { cloneDeep } from "lodash";
import React from "react";
import { graphicsComponents } from "pages/Reports/CustomReports/consts";
import { useQuery } from "@apollo/client";
import { FetchSubjects } from "types/graphql/queries/fetchSubjects";
import { FETCH_SUBJECTS } from "graphql/queries/fetchSubjects";

type OptionsSubjectSelect = { label: string; value: number; }


const months = [
	"Janeiro",
	"Fevereiro",
	"Março",
	"Abril",
	"Maio",
	"Junho",
	"Julho",
	"Agosto",
	"Setembro",
	"Outubro",
	"Novembro",
	"Dezembro",
];
export function FormCreateSinteticReport() {
	const media768 = useMediaQuery("(max-width:768px)");
	const media600 = useMediaQuery("(max-width:600px)");
	const media425 = useMediaQuery("(max-width:425px)");
	const {
		watch,
		setValue,
		formState: { errors },
	} = useFormContext();
	const { enqueueSnackbar } = useSnackbar();

	const [valueSubjects, setValueSubjects] = useState<OptionsSubjectSelect>()
	const [isOpenModalSharePerfis, setIsOpenModalSharePerfis] = useState(false);
	const [isOpenModalAddTypeOfData, setIsOpenModalAddTypeOfData] =
		useState(false);
	const [isOpenModalEditGraphic, setIsOpenModalEditGraphic] = useState(false);
	const [titleToEdit, setTitleToEdit] = useState<Title | null>(null);
	const [graphicToEdit, setGraphicToEdit] = useState<Graphic | null>(null);

	const dataTypeSelected: string = watch("type");
	const dataSubject_idSelected: number = watch("subject_id");
	const titlesSelecteds: Title[] = watch("titles");
	const graphicsSelecteds: Graphic[] = watch("graphics");
	const profileIds: Array<{ id: number; name: string }> = watch("profile_ids");
	const visualizationSelected: string = watch("visualization_rule");

	const { data: dataSubjects, loading: loadingSubjects } = useQuery<FetchSubjects>(FETCH_SUBJECTS, {
		fetchPolicy: "network-only",
		variables: {
			order_by: "subjects.name",
			direction: "asc",
			limit: 1000,
			status: "Ativo"
		},
	});

	const subjects = dataSubjects?.fetchSubjects?.data;

	const optionsSubject: OptionsSubjectSelect[] = subjects?.map((option) => ({
		label: option.name,
		value: option.id,
	}));

	useEffect(() => {
		const subjectFind = subjects?.find(
			(subject) => subject.id === dataSubject_idSelected
		);
		if (subjectFind) {
			setValueSubjects({label: subjectFind.name, value: subjectFind.id})
		}
	}, [dataSubjects])

	const handleOpenModalSharePerfis = () => {
		setIsOpenModalSharePerfis(true);
	};

	const handleAddPerfis = (perfis: Array<{ id: number; name: string }>) => {
		setValue("profile_ids", perfis);
		setIsOpenModalSharePerfis(false);
	};

	const handleOpenModalAddTypeOfData = (title: Title) => {
		setTitleToEdit(title);
		setIsOpenModalAddTypeOfData(true);
	};

	const handleCloseModalAddTypeOfData = () => {
		setIsOpenModalAddTypeOfData(false);
		setTitleToEdit(null);
	};

	const handleOpenModalEditGraphic = (graphic: Graphic) => {
		setGraphicToEdit(graphic);
		setIsOpenModalEditGraphic(true);
	};

	const handleCloseModalEditGraphic = () => {
		setIsOpenModalEditGraphic(false);
		setGraphicToEdit(null);
	};

	const handleDeleteGraphic = (id: string) => {
		const prevGraphics = cloneDeep(graphicsSelecteds);

		const newGraphics = prevGraphics.filter((prev) => {
			return prev.id !== id;
		});

		setValue("graphics", newGraphics);
	};

	const handleDeleteTitle = (id: string) => {
		const prevTitles = cloneDeep(titlesSelecteds);

		const newTitles = prevTitles.filter((prev) => {
			return prev.id !== id;
		});

		setValue("titles", newTitles);
	};

	const handleAddTypeOfData = (type: string, customized: boolean, titles: string[], subtitle: string) => {
		const prevTitles = [...titlesSelecteds];
		const titleSelected = prevTitles.findIndex(
			(title) => title.id === titleToEdit?.id
		);

		const hasTitle = prevTitles.find((title) => title.title === type && title.title !== "Por Tarefa");

		if (hasTitle) {
			enqueueSnackbar("Já existe um título com esse tipo de dados.", {
				variant: "warning",
			});
			return;
		}

		if (titleSelected >= 0) {
			prevTitles[titleSelected] = {
				...prevTitles[titleSelected],
				title: type,
				titles: titles,
				customized: customized,
				subtitle: subtitle
			};
			setTitleToEdit(null);
			setIsOpenModalAddTypeOfData(false);
			setValue("titles", [...prevTitles]);
		}
	};

	const handleEditGraphic = (graphic: {
		id: string;
		title: string;
		label: string;
		dataTypes: any;
		dateType?: string;
		month?: number;
		year?: number;
		day?: number | string;
		startDate?: string;
		finishDate?: string;
	}) => {
		const prevGraphics = cloneDeep(graphicsSelecteds);
		const graphicSelectedIndex = prevGraphics.findIndex(
			(title) => title.id === graphicToEdit?.id
		);
		if (graphicSelectedIndex >= 0) {
			let newGraphic = null;
			if (
				(prevGraphics[graphicSelectedIndex].variant === "simpleLine" ||
					prevGraphics[graphicSelectedIndex].variant === "stackedBar" ||
					prevGraphics[graphicSelectedIndex].variant === "taskChart") &&
				prevGraphics[graphicSelectedIndex].variant !== "ssidChart"
			) {
				newGraphic = {
					...prevGraphics[graphicSelectedIndex],
					title: graphic.title ? graphic.title : "Título",
					titles: graphic.dataTypes ? graphic.dataTypes : ["Concluídos"],
					label: graphic.label,
					date: {
						type: graphic.dateType,
						range:
							graphic[graphic.dateType] === "custom"
								? [graphic.startDate, graphic.finishDate]
								: graphic[graphic.dateType],
					},
					data: Array.from({
						length:
							graphic[graphic.dateType] === "custom"
								? 2
								: graphic[graphic.dateType],
					}).map((item, index) => {
						if (graphic.dateType === "month") {
							return {
								[graphic.dateType]: getLastMonths(graphic[graphic.dateType])[
									index
								],
								...graphic.dataTypes.reduce((acc, item) => {
									acc[item] = Math.floor(Math.random() * 100);
									return acc;
								}, {}),
							};
						}
						if (graphic.dateType === "year") {
							return {
								[graphic.dateType]: getLastYears(graphic[graphic.dateType])[
									index
								].toString(),
								...graphic.dataTypes.reduce((acc, item) => {
									acc[item] = Math.floor(Math.random() * 100);
									return acc;
								}, {}),
							};
						}
						if (
							graphic.dateType === "day" &&
							graphic[graphic.dateType] !== "custom"
						) {
							return {
								[graphic.dateType]: getLastDays(
									graphic[graphic.dateType] as number
								)[index].toString(),
								...graphic.dataTypes.reduce((acc, item) => {
									acc[item] = Math.floor(Math.random() * 100);
									return acc;
								}, {}),
							};
						}
						if (graphic[graphic.dateType] === "custom") {
							const dates = [graphic.startDate, graphic.finishDate];
							return {
								[graphic.dateType]: dates[index],
								...graphic.dataTypes.reduce((acc, item) => {
									acc[item] = Math.floor(Math.random() * 100);
									return acc;
								}, {}),
							};
						}
					}),
					series: graphic.dataTypes.map((item) => ({
						argumentField: graphic.dateType,
						valueField: item,
						name: item,
					})),
				};
			} else if (prevGraphics[graphicSelectedIndex].variant !== "ssidChart") {
				newGraphic = {
					...prevGraphics[graphicSelectedIndex],
					title: graphic.title ? graphic.title : "Titulo",
					label: graphic.label,
					titles: graphic.dataTypes ? graphic.dataTypes : ["Concluídos"],
					date: null,
					data: graphic.dataTypes.map((item) => ({
						key: item,
						value: Math.floor(Math.random() * 100),
					})),
					series: [],
				};
			} else {
				const listaDeTitlesSelecteds = titlesSelecteds.map((obj) => ({
					key: obj.customized ? obj.subtitle : obj.title,
					value: obj.data,
				}));

				const newObjMonthTitles = listaDeTitlesSelecteds.reduce(
					(resultado, objeto) => {
						resultado[objeto.key] = objeto.value;
						return resultado;
					},
					{}
				);
				newGraphic = {
					...prevGraphics[graphicSelectedIndex],
					title: graphic.title ? graphic.title : "Titulo",
					label: graphic.label,
					titles: titlesSelecteds.map((obj) => obj.title),
					date: null,
					data: months.map((month) => ({
						month: month,
						...newObjMonthTitles,
					})),
					series: [],
				};
			}
			setGraphicToEdit(null);
			setIsOpenModalEditGraphic(false);
			return setValue("graphics", [
				...prevGraphics.slice(0, graphicSelectedIndex),
				newGraphic,
				...prevGraphics.slice(graphicSelectedIndex + 1),
			]);
		}
	};

	useEffect(() => {
		const searchSsidChart = graphicsSelecteds.find(
			(graph) => graph.variant === "ssidChart"
			);
		if (searchSsidChart) setGraphicToEdit(searchSsidChart);
	}, [titlesSelecteds])


	useEffect(() => {
		if (graphicToEdit?.variant === "ssidChart") {
			const { id, data, title, label, type } = graphicToEdit;
			handleEditGraphic({
				id,
				title,
				label,
				dateType: type,
				dataTypes: data,
			});
		}
	}, [graphicToEdit]);
	

	const onDragEnd = (result: DropResult) => {
		const { draggableId, type } = result;
		const prevTitles = [...titlesSelecteds];
		const titleSelected = titles.find(
			(title) => title.id === parseInt(draggableId)
		);
		if (type === "title") {
			const hasTitle = prevTitles.find(
				(title) => title.title === dataTypeSelected
			);
			if (hasTitle) {
				enqueueSnackbar("Já existe um título com esse tipo de dados.", {
					variant: "warning",
				});
				return;
			}
			const dataFormatted = {
				id: uuid(),
				type: "dataDisplay",
				title: "",
				data: Math.floor(Math.random() * 1000),
				customized: false,
				titles: [],
				subtitle: "",
				style: {
					textAlign: titleSelected.textAlign,
					flexDirection: titleSelected.reverse ? "column-reverse" : "column",
				},
			} satisfies Title;

			setTitleToEdit(dataFormatted);
			setIsOpenModalAddTypeOfData((state) => !state)
			return setValue("titles", [...prevTitles, dataFormatted]);
		}
		if (type === "graphic") {
			let dataFormatted;
			const prevGraphics = [...graphicsSelecteds];
			const graphicSelected = dynamicGraphics.find(
				(title) => title.variant === draggableId
			);
			if (
				graphicSelected.variant !== "simpleLine" &&
				graphicSelected.variant !== "stackedBar" &&
				graphicSelected.variant !== "ssidChart" &&
				graphicSelected.variant !== "taskChart"
			) {
				dataFormatted = {
					id: uuid(),
					type: "graphic",
					variant: graphicSelected.originalVariant ?? graphicSelected.variant,
					rotated: graphicSelected.rotated || false,
					title: "Título",
					titles: ["Concluídos"],
					label: "",
					series: defaultSeries[graphicSelected.variant],
					data: defaultData[graphicSelected.variant],
				};
			} else if (graphicSelected.variant !== "ssidChart") {
				dataFormatted = {
					id: uuid(),
					type: "graphic",
					variant: graphicSelected.originalVariant ?? graphicSelected.variant,
					rotated: graphicSelected.rotated || false,
					title: "Título",
					titles: graphicSelected.variant === "taskChart" ? [] : ["Concluídos"],
					label: "",
					date: { type: "year", range: 2 },
					series: defaultSeries[graphicSelected.variant],
					data: defaultData[graphicSelected.variant],
				};
			} else {
				const listaDeTitlesSelecteds = titlesSelecteds.map((obj) => ({
					key: obj.customized ? obj.subtitle : obj.title,
					value: obj.data,
				}));

				const newObjMonthTitles = listaDeTitlesSelecteds.reduce(
					(resultado, objeto) => {
						resultado[objeto.key] = objeto.value;
						return resultado;
					},{}
				);
				dataFormatted = {
					id: uuid(),
					type: "graphic",
					variant: graphicSelected.originalVariant ?? graphicSelected.variant,
					rotated: graphicSelected.rotated || false,
					title: "Título",
					titles: titlesSelecteds.map((obj) => obj.title),
					label: "",
					series: defaultSeries[graphicSelected.variant],
					data: months.map((month) => ({
						month: month,
						...newObjMonthTitles,
					})),
				};
			}
	
	
			if (graphicSelected.variant !== "ssidChart")  {
				setIsOpenModalEditGraphic((state) => !state);
				setGraphicToEdit(dataFormatted);
			}
			return setValue("graphics", [...prevGraphics, dataFormatted]);
		}
	};
	return (
		<>
			<Grid container height={"100%"}>
				<DragDropContext onDragEnd={onDragEnd}>
					<Grid item xs={5} pr={4}>
						<Box mb="20px">
							<Typography color="#2660F6" fontWeight={400} fontSize="24px">
								Criar Relatório
							</Typography>
						</Box>
						<Stack spacing={2}>
							<Box>
								<Autocomplete
									options={
										loadingSubjects
											? [{ label: "carregando", value: 0 }]
											: optionsSubject
									}
									disableClearable
									value={
										loadingSubjects
											? { label: "carregando", value: 0 }
											: valueSubjects
									}
									defaultValue={
										loadingSubjects
											? { label: "carregando", value: 0 }
											: valueSubjects
									}
									disabled={loadingSubjects}
									fullWidth
									autoHighlight
									renderInput={(params) => (
										<Box sx={{ position: "relative", width: "100%" }}>
											<TextField {...params} label="Selecione um assunto" />
											{loadingSubjects && (
												<CircularProgress
													size={20}
													sx={{
														position: "absolute",
														top: "30%",
														right: "10%",
													}}
												/>
											)}
										</Box>
									)}
									getOptionLabel={(option) => option.label}
									onChange={(event, newValue) => {
										setValueSubjects({
											label: newValue.label,
											value: newValue.value,
										});
										setValue("title", newValue.label);
										setValue("subject_id", newValue.value);
									}}
								/>
							</Box>
						</Stack>
						<Box mt="12px">
							<Stack mb="10px">
								<Typography fontWeight={400} fontSize="24px">
									Componentes
								</Typography>
								<Typography fontSize="16px" color="#292929" fontWeight={400}>
									Selecione o componente abaixo e arraste para a área de
									montagem do relatório.
								</Typography>
							</Stack>

							<TitleAndValues />
							<DynamicGraphics />
						</Box>
						<Box mt="44px">
							<Input
								name="author"
								placeholder="Autor"
								fullWidth
								disabled
								readOnly
							/>
						</Box>
						<Box mt="6px">
							<RadioButton
								name="visualization_rule"
								label=""
								radios={visualizationRules}
								row={false}
								onClick={(e) => {
									if (e.currentTarget.id === "Perfis") {
										handleOpenModalSharePerfis();
									}
								}}
							/>
							{errors.visualization_rule && (
								<FormHelperText error>
									{errors.visualization_rule.message + ""}
								</FormHelperText>
							)}
							{profileIds.length > 0 && visualizationSelected === "Perfis" && (
								<Box>
									<Typography variant="caption">
										Perfis Selecionados:
									</Typography>
									<Typography>
										{profileIds.map((item, index) => {
											const isLastIndex = index < profileIds.length - 1;
											return (
												<Fragment key={item.id}>
													{item.name} {isLastIndex && ", "}
												</Fragment>
											);
										})}
									</Typography>
								</Box>
							)}
						</Box>
					</Grid>
					<Divider orientation="vertical" flexItem />
					<Grid item xs pl={4}>
						<Stack height="100%">
							<Box mb="20px">
								<Typography fontWeight={400} fontSize="24px">
									Montar Relatório
								</Typography>
							</Box>
							<Box mb="20px">
								<Input name="title" placeholder="Título" fullWidth />
							</Box>
							<Droppable droppableId="droppable">
								{(droppableProvided, droppableSnapshot) => (
									<Box
										flex={1}
										border={`3px dashed ${
											droppableSnapshot.isDraggingOver ? "#000" : "#c8c8c8"
										}`}
										height="max-content"
										bgcolor={
											titlesSelecteds.length > 0 || graphicsSelecteds.length > 0
												? "#fff"
												: "#EAEAEA"
										}
										display="flex"
										flexDirection={"column"}
										borderRadius="10px"
										ref={droppableProvided.innerRef}
										{...droppableProvided.droppableProps}
										p={2}
										position="relative"
									>
										<Grid container spacing={2}>
											{titlesSelecteds.map((title) => {
												return (
													<Grid item key={title.id}>
														<Stack
															direction={"row"}
															alignItems={"center"}
															justifyContent="center"
														>
															<Stack
																flexDirection={title.style.flexDirection}
																textAlign={title.style.textAlign}
															>
																<Typography
																	textAlign={title.style.textAlign}
																	fontSize="32px"
																	fontWeight={700}
																	lineHeight={"38px"}
																	width={"100%"}
																>
																	{title.data || 0}
																</Typography>
																<Typography
																	fontSize="16px"
																	lineHeight={"24px"}
																	letterSpacing={"0.5px"}
																	textAlign={title.style.textAlign}
																	width={"100%"}
																>
																	{title.customized ? title.subtitle : title.title || "Título"}
																</Typography>
															</Stack>
															<Stack spacing={0.5}>
																<IconButton
																	onClick={() =>
																		handleOpenModalAddTypeOfData(title)
																	}
																>
																	<EditIcon />
																</IconButton>
																<IconButton
																	onClick={() => handleDeleteTitle(title.id)}
																>
																	<DeleteIcon color="error" />
																</IconButton>
															</Stack>
														</Stack>
													</Grid>
												);
											})}
										</Grid>
										<Box
											position={"absolute"}
											sx={{
												top: "50%",
												left: "50%",
												transform: "translate(-50%, -50%)",
											}}
											width="max-content"
										>
											{graphicsSelecteds.length === 0 && (
												<Typography fontWeight={400} fontSize="20px">
													Arraste os componentes ao lado para essa área
												</Typography>
											)}
										</Box>
										<Grid
											container
											spacing={2}
											flex={1}
											alignItems="center"
											mt={4}
										>
											{graphicsSelecteds.map((graphic) => {
												return (
													<Grid
														item
														xs={graphic.variant === "ssidChart" ? 12 : 6}
														key={graphic.id}
														position={"relative"}
														py={4}
													>
														{graphicsComponents[graphic.variant]({
															chartData: graphic.data,
															series: graphic.series,
															title: graphic.title,
															rotate: graphic.rotated,
															label: graphic.label,
															medias: {
																media768,
																media600,
																media425
															}
														})}

														<Stack
															direction="row"
															justifyContent="flex-end"
															alignItems="center"
															sx={{
																position: "absolute",
																top: "-24px",
																right: "0",
															}}
														>
															{graphic.variant !== "ssidChart" && (
																<IconButton
																	onClick={() =>
																		handleOpenModalEditGraphic(graphic)
																	}
																>
																	<EditIcon />
																</IconButton>
															)}
															<IconButton
																onClick={() => handleDeleteGraphic(graphic.id)}
															>
																<DeleteIcon color="error" />
															</IconButton>
														</Stack>
													</Grid>
												);
											})}
										</Grid>
									</Box>
								)}
							</Droppable>
						</Stack>
					</Grid>
				</DragDropContext>
			</Grid>
			{isOpenModalSharePerfis && (
				<ModalSharePerfis
					open={isOpenModalSharePerfis}
					handleClose={() => setIsOpenModalSharePerfis(false)}
					handleAddPerfis={(perfis) => handleAddPerfis(perfis)}
				/>
			)}
			{isOpenModalAddTypeOfData && (
				<ModalAddTypeOfData
					open={isOpenModalAddTypeOfData}
					handleClose={handleCloseModalAddTypeOfData}
					handleAddTypeOfData={({ type, customized, titles, subtitle }) =>
						handleAddTypeOfData(type, customized, titles, subtitle)
					}
					title={titleToEdit.title}
					customized={titleToEdit.customized}
					titles={titleToEdit.titles}
					subtitle={titleToEdit.subtitle}
				/>
			)}
			{isOpenModalEditGraphic && (
				<ModalEditGraphic
					handleEditGraphic={handleEditGraphic}
					open={isOpenModalEditGraphic}
					handleClose={() => handleCloseModalEditGraphic()}
					graphic={{
						id: graphicToEdit.id,
						label: graphicToEdit.label,
						title: graphicToEdit.title,
						dataTypes:
							graphicToEdit.variant === "simpleLine" ||
							graphicToEdit.variant === "stackedBar" ||
							graphicToEdit.variant === "taskChart"
								? graphicToEdit.series.map((item) => item.name)
								: graphicToEdit.data.map((item) => item.key),
						variant: graphicToEdit.variant,
						dateType: graphicToEdit.series[0]?.argumentField || "",
						day: graphicToEdit.data.length,
						month: graphicToEdit.data.length,
						year: graphicToEdit.data.length,
						startDate:
							graphicToEdit.series[0]?.argumentField === "day" &&
							graphicToEdit.data.length === 2
								? graphicToEdit.data[0].day
								: null,
						finishDate:
							graphicToEdit.series[0]?.argumentField === "day" &&
							graphicToEdit.data.length === 2
								? graphicToEdit.data[1].day
								: null,
					}}
				/>
			)}
		</>
	);
}
