import { Grid, Stack, Typography } from "@mui/material";
import Button from "components/Button";
import { ModalLayout } from "components/ModalLayout";
import { ModalLayoutProps } from "types/ModalLayout";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AddchartIcon from "@mui/icons-material/Addchart";
import { useState } from "react";
import { FormCreateAnalyticalReport } from "../Form/AnalyticalReport";
import { FormCreateSinteticReport } from "../Form/SinteticReport";
import { useForm, FormProvider } from "react-hook-form";
import { ModalConfirmationDelection } from "components/Modal/ModalConfirmationDelection";
import { useAuthContext } from "context/AuthProvider";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@apollo/client";
import { CREATE_DYNAMIC_REPORT } from "graphql/mutations/createDynamicReport";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

type ModalCreateReportProps = ModalLayoutProps;

export type Title = {
	id: string;
	type: "dataDisplay";
	title: string;
	titles: string[];
	data: number;
	subtitle: string;
	customized: boolean;
	style: {
		textAlign: "center" | "left" | "right";
		flexDirection: "column" | "column-reverse" | "row" | "row-reverse";
	};
};

export type Graphic = {
	id: string;
	type: "graphic";
	title: string;
	titles: string[];
	label: string;
	variant: string;
	rotated: boolean;
	data: Array<{
		[key: string]: string;
	}>;
	series: Array<{
		argumentField: string;
		valueField: string;
		name: string;
	}>;
};

type FormData = {
	data_source: string;
	columns_name: string[];
	title: string;
	group_by: string;
	order_by: string;
	order_direction: string;
	visualization_rule: string;
	spec: Array<string>;
	profile_ids: Array<{
		id: number;
		name: string;
	}>;
	titles: Array<Title>;
	graphics: Array<Graphic>;
	date: {
		type: string;
		range: string;
	} | null;
	subject_id: number;
};

const schema = yup.object().shape({
	author: yup.string(),
	data_source: yup.string().required("Campo obrigatório"),
	columns_name: yup.array().of(yup.string()).required("Campo obrigatório"),
	title: yup.string().required("Campo obrigatório"),
	group_by: yup.string(),
	order_by: yup.string(),
	order_direction: yup.string().nullable(),
	visualization_rule: yup.string().required("Campo obrigatório").nullable(),
	spec: yup.array().of(yup.string()),
	profile_ids: yup.array().of(
		yup.object().shape({
			id: yup.number(),
			name: yup.string(),
		})
	),
	titles: yup.array().of(
		yup.object().shape({
			id: yup.string(),
			type: yup.string(),
			title: yup.string(),
			titles: yup.array().of(yup.string()),
			subtitle: yup.string(),
			customized: yup.boolean(),
			data: yup.number(),
			style: yup.object().shape({
				textAlign: yup.string(),
				flexDirection: yup.string(),
			}),
		})
	),
});

export function ModalCreateReport({
	open,
	handleClose,
}: ModalCreateReportProps) {
	const [createDynamicReport, { loading }] = useMutation(CREATE_DYNAMIC_REPORT);
	const [reportType, setReportType] = useState<
		"Analítico" | "Sintético" | null
	>(null);
	const { state } = useAuthContext();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			author: state.userInfo.user.name,
			data_source: "Protocolos",
			columns_name: ["userName", "responsibleName"],
			title: "",
			group_by: "",
			order_by: "",
			order_direction: null,
			visualization_rule: "",
			spec: [],
			profile_ids: [],
			titles: [],
			graphics: [],
			date: null,
			subject_id: null
		},
	});

	const {
		handleSubmit,
		formState: { errors },
	} = methods;

	const [isShowForm, setIsShowForm] = useState<boolean>(false);
	const [isShowConfirmation, setIsShowConfirmation] = useState<boolean>(false);

	const onSubmit = async (data: FormData) => {
		let dataFormatted = null;
		if (reportType === "Analítico") {
			dataFormatted = {
				type: reportType,
				data_source: data.data_source,
				title: data.title,
				group_by:
					data.data_source === "Protocolos" && data.group_by === "user"
						? "responsibleUser"
						: data.group_by,
				order_by: data.order_by,
				order_direction: data.order_direction,
				visualization_rule: data.visualization_rule,
				columns:
					data.data_source === "Protocolos"
						? data.columns_name.filter((item) => item !== "userName")
						: data.columns_name.filter((item) => item !== "responsibleName"),
				spec: JSON.stringify(data.spec),
				profile_ids:
					data.visualization_rule === "Perfis"
						? data.profile_ids.map((item) => item.id)
						: [],
			};
		}

		if (reportType === "Sintético") {
			const specSynthetic = [
				{
					type: "wrapper",
					col: 12,
					style: {},
					items: data.titles,
				},
				{
					type: "wrapper",
					col: 12,
					style: {},
					items: data.graphics.map((item) => ({
						...item,
						data: item.data,
					})),
				},
			];
			dataFormatted = {
				type: reportType,
				data_source: data.data_source,
				title: data.title,
				visualization_rule: data.visualization_rule,
				profile_ids:
					data.visualization_rule === "Perfis"
						? data.profile_ids.map((item) => item.id)
						: [],
				spec: JSON.stringify([...specSynthetic]),
				subject_id: data.subject_id
			};
		}

		try {
			const { data: response } = await createDynamicReport({
				variables: dataFormatted,
			});
			if (reportType === "Analítico") {
				navigate(`relatorio-analitico/${response.createDynamicReport}`);
			}
			if (reportType === "Sintético") {
				navigate(`relatorio-sintetico/${response.createDynamicReport}`);
			}
		} catch (err) {
			enqueueSnackbar(err.message, {
				variant: "error",
			});
		}
	};
	return (
		<>
			<FormProvider {...methods}>
				<ModalLayout
					open={open}
					handleClose={handleClose}
					isNotShowButtonClose={isShowForm ? false : true}
					isNotShowButtonSubmit={isShowForm ? false : true}
					maxWidth={isShowForm ? "lg" : "sm"}
					buttonTextClose="Cancelar"
					additionalFuntion={() => setIsShowConfirmation(true)}
					handleSubmit={handleSubmit(onSubmit)}
					isDisabled={Object.keys(errors).length > 0}
					isLoading={loading}
				>
					{!isShowForm && (
						<>
							<Stack mb="48px">
								<Typography color="#2660F6" fontWeight={700} fontSize="28px">
									Criar Relatório
								</Typography>
								<Typography fontWeight={400}>
									Escolha o tipo de relatório que você deseja criar:
								</Typography>
							</Stack>
							<Grid container spacing={8} px={6}>
								<Grid item xs={12} md={6}>
									<Button
										id="Analitico"
										variant="contained"
										color="inherit"
										sx={{
											backgroundColor: "#fff",
											minHeight: 200,
											color: reportType === "Analítico" ? "#1976d2" : "inherit",
											"&:hover": {
												color: "#1976d2",
											},
											border:
												reportType === "Analítico"
													? "2px solid #1976d2"
													: "none",
										}}
										onClick={() => setReportType("Analítico")}
									>
										<Stack alignItems={"center"}>
											<FormatListBulletedIcon fontSize="large" />
											<Typography>Analítico</Typography>
											<Typography variant="caption" textTransform={"none"}>
												Permite gerar listagem de dados de acordo com filtros e
												grupos pré-estabelecidos.
											</Typography>
										</Stack>
									</Button>
								</Grid>
								<Grid item xs={12} md={6}>
									<Button
										id="Sintetico"
										variant="contained"
										color="inherit"
										sx={{
											backgroundColor: "#fff",
											minHeight: 200,
											color: reportType === "Sintético" ? "#1976d2" : "inherit",
											border:
												reportType === "Sintético"
													? "2px solid #1976d2"
													: "none",
											"&:hover": {
												color: "#1976d2",
											},
										}}
										onClick={() => setReportType("Sintético")}
									>
										<Stack alignItems={"center"}>
											<AddchartIcon fontSize="large" />
											<Typography>Sintético</Typography>
											<Typography variant="caption" textTransform={"none"}>
												Permite selecionar grupos de dados para exibir
												informações de forma sintetizada.
											</Typography>
										</Stack>
									</Button>
								</Grid>
								<Grid item xs={12} display="flex" justifyContent={"center"}>
									<Button
										variant="contained"
										disabled={!reportType}
										onClick={() => setIsShowForm(true)}
									>
										Avançar
									</Button>
								</Grid>
							</Grid>
						</>
					)}
					{isShowForm && (
						<>
							{reportType === "Analítico" ? (
								<FormCreateAnalyticalReport />
							) : (
								<FormCreateSinteticReport />
							)}
						</>
					)}
				</ModalLayout>
			</FormProvider>
			{isShowConfirmation && (
				<ModalConfirmationDelection
					message="O relatório criado será excluido. Deseja continuar?"
					open={isShowConfirmation}
					handleClose={() => setIsShowConfirmation(false)}
					handleSubmit={() => {
						setIsShowConfirmation(false);
						handleClose();
					}}
					buttonSubmitText="Confirmar"
					maxWidth="xs"
				/>
			)}
		</>
	);
}
