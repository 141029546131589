import { gql } from "@apollo/client";

export const FETCH_CONNECTED_DEVICES = gql`
	query {
		fetchConnectedDevices {
			id
			name
			lat
			lng
			created_at
		}
	}
`;
