import { v4 as uuidv4 } from "uuid";
import FieldOptionButton from "components/DynamicForm/TypeOfComponents/FieldOptionButton";
import FieldCEPInput from "components/DynamicForm/TypeOfComponents/FieldCEPInput";
import FieldCheckBox from "components/DynamicForm/TypeOfComponents/FieldCheckBox";
import FieldCpfOrCnpj from "components/DynamicForm/TypeOfComponents/FieldCpfOrCnpj";
import FieldDate from "components/DynamicForm/TypeOfComponents/FieldDate";
import FieldDecimalInput from "components/DynamicForm/TypeOfComponents/FieldDecimalInput";
import FieldEmail from "components/DynamicForm/TypeOfComponents/FieldEmail";
import FieldNumericInput from "components/DynamicForm/TypeOfComponents/FieldNumericInput";
import FieldOffenderData from "components/DynamicForm/TypeOfComponents/FieldOffenderData";
import FieldLocation from "components/DynamicForm/TypeOfComponents/FieldLocation";
import FieldPropertyDetails from "components/DynamicForm/TypeOfComponents/FieldPropertyDetails";
import FieldReceiver from "components/DynamicForm/TypeOfComponents/FieldReceiver";
import FieldSelectBox from "components/DynamicForm/TypeOfComponents/FieldSelectBox";
import FieldText from "components/DynamicForm/TypeOfComponents/FieldText";
import FieldTextEditor from "components/DynamicForm/TypeOfComponents/FieldTextEditor";
import FieldTotalAmountFine from "components/DynamicForm/TypeOfComponents/FieldTotalAmountFine";
import FieldWitness from "components/DynamicForm/TypeOfComponents/FieldWitness";
import FieldApplicantData from "components/DynamicForm/TypeOfComponents/FieldApplicantData";

const componentsDinamycForm = [
	{
		id: uuidv4(),
		label: "Texto",
		component: FieldText,
		type: "text",
		isEditable: true,
		isRequired: false,
	},
	{
		id: uuidv4(),
		label: "Botão de opção",
		component: FieldOptionButton,
		type: "radioButton",
		isEditable: true,
		isRequired: true,
		isOption: true,
		options: [
			{
				id: uuidv4(),
				label: "Opção 1",
				value: "Opção 1",
			},
		],
	},
	{
		id: uuidv4(),
		label: "CEP",
		component: FieldCEPInput,
		type: "cep",
		isEditable: true,
		isRequired: false,
	},
	{
		id: uuidv4(),
		label: "Caixa Suspensa",
		component: FieldSelectBox,
		type: "combo",
		isEditable: true,
		isRequired: false,
		isOption: true,
		options: [
			{
				id: uuidv4(),
				label: "Opção 1",
				value: "Opção 1",
			},
		],
	},
	{
		id: uuidv4(),
		label: "Caixa de seleção",
		component: FieldCheckBox,
		type: "checkbox",
		isEditable: true,
		isRequired: false,
		isOption: true,
		options: [
			{
				id: uuidv4(),
				label: "Opção 1",
				value: "Opção 1",
			},
		],
	},
	{
		id: uuidv4(),
		label: "Campo decimal",
		component: FieldDecimalInput,
		type: "decimal",
		isEditable: true,
		isRequired: false,
	},
	{
		id: uuidv4(),
		label: "Campo numérico",
		component: FieldNumericInput,
		type: "number",
		isEditable: true,
		isRequired: false,
	},
	{
		id: uuidv4(),
		label: "Cpf/Cnpj",
		component: FieldCpfOrCnpj,
		type: "cpf",
		isEditable: true,
		isRequired: false,
	},
	{
		id: uuidv4(),
		label: "Dados do imóvel",
		component: FieldPropertyDetails,
		type: "imovelInput",
		isEditable: false,
		isRequired: false,
	},
	{
		id: uuidv4(),
		label: "Localização e detalhes",
		component: FieldLocation,
		type: "location",
		isEditable: false,
		isRequired: false,
	},
	{
		id: uuidv4(),
		label: "Dados do infrator",
		component: FieldOffenderData,
		type: "owner",
		isEditable: false,
		isRequired: false,
	},
	{
		id: uuidv4(),
		label: "Dados do requerente",
		component: FieldApplicantData,
		type: "applicant",
		isEditable: false,
		isRequired: false,
	},
	{
		id: uuidv4(),
		label: "Data",
		component: FieldDate,
		type: "date",
		isEditable: true,
		isRequired: false,
	},

	{
		id: uuidv4(),
		label: "E-mail",
		component: FieldEmail,
		type: "email",
		isEditable: true,
		isRequired: false,
	},
	{
		id: uuidv4(),
		label: "Recebedor",
		component: FieldReceiver,
		type: "receiver",
		isEditable: false,
		isRequired: false,
	},
	{
		id: uuidv4(),
		label: "Testemunhas",
		component: FieldWitness,
		type: "witness",
		isEditable: false,
		isRequired: false,
	},

	{
		id: uuidv4(),
		label: "Valor total da multa (UPFMLS)",
		component: FieldTotalAmountFine,
		type: "totalAmountFine",
		isEditable: false,
		isRequired: false,
	},
	{
		id: uuidv4(),
		label: "Área de texto",
		component: FieldTextEditor,
		type: "textarea",
		isEditable: true,
		isRequired: false,
	},
];

export default componentsDinamycForm;
