import {
	Box,
	FormControl,
	FormHelperText,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import { Checkbox } from "components/Checkbox";
import IconButton from "components/IconButton";
import { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { ModalSearchRealty } from "../Modal/ModalSearchRealty";
import SearchIcon from "@mui/icons-material/Search";

interface FieldPropertyDetailsProps {
	name: string;
	index?: number;
	readonly?: boolean;
}

function FieldPropertyDetails({
	name,
	index,
	readonly,
}: FieldPropertyDetailsProps) {
	const [isOpenModalSearch, setIsOpenModalSearch] = useState(false);
	const { control, setValue } = useFormContext();

	function handleSelectRealty(realty: string) {
		const parsedRealty = JSON.parse(realty);

		if (parsedRealty) {
			const {
				INDICECADASTRAL,
				LOTEAMLOTE,
				LOTEAMQUADRA,
				LOGRADOURO,
				NUMERO,
				NOMEBAIRRO,
				COMPLEMENTO,
				AREATOTAL,
				CODIGO,
				NOME,
				CGCCPF,
				AREATERRENO,
			} = parsedRealty;
			setValue(
				`groups.${index}.fields.property.municipalRegistration`,
				INDICECADASTRAL
			);
			setValue(`groups.${index}.fields.property.lote`, LOTEAMLOTE);
			setValue(`groups.${index}.fields.property.quadra`, LOTEAMQUADRA);
			setValue(`groups.${index}.fields.property.street`, LOGRADOURO);
			setValue(`groups.${index}.fields.property.number`, NUMERO);
			setValue(`groups.${index}.fields.property.neighborhood`, NOMEBAIRRO);
			setValue(`groups.${index}.fields.property.complement`, COMPLEMENTO);
			setValue(`groups.${index}.fields.property.areatotal`, AREATOTAL);
			setValue(`groups.${index}.fields.property.areaterreno`, AREATERRENO);
			setValue(`groups.${index}.fields.property.codigo`, CODIGO);
			setValue("groups.0.fields.owner.name", NOME ?? "");
			setValue("groups.0.fields.owner.document", CGCCPF ?? "");
		}
	}
	return (
		<>
			<Grid item xs={12}>
				<Typography variant="h6" fontSize="1rem">
					Dados do imóvel
				</Typography>
				<Controller
					name={`groups.${index}.fields.property.municipalRegistration`}
					control={control}
					render={(inputProps) => (
						<FormControl variant="outlined" fullWidth>
							<TextField
								id={name}
								type={"text"}
								label="Indíce cadastral"
								fullWidth
								value={inputProps.field.value}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
								InputProps={{
									endAdornment: (
										<IconButton onClick={() => setIsOpenModalSearch(true)}>
											<SearchIcon />
										</IconButton>
									),
								}}
							/>
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<Controller
					name={`groups.${index}.fields.property.lote`}
					control={control}
					render={(inputProps) => (
						<FormControl variant="outlined" fullWidth>
							<TextField
								id={name}
								type={"text"}
								label="Lote"
								fullWidth
								value={inputProps.field.value}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<Controller
					name={`groups.${index}.fields.property.quadra`}
					control={control}
					render={(inputProps) => (
						<FormControl variant="outlined" fullWidth>
							<TextField
								id={name}
								type={"text"}
								label="Quadra"
								fullWidth
								value={inputProps.field.value}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<Controller
					control={control}
					name={`groups.${index}.fields.property.isSameAddress`}
					render={(inputProps) => (
						<Checkbox
							readOnly={readonly}
							label="Mesmo endereço do infrator/requerente"
							onChange={(e) => inputProps.field.onChange(e.target.checked)}
							checked={inputProps.field.value}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<Controller
					name={`groups.${index}.fields.property.street`}
					control={control}
					render={(inputProps) => (
						<FormControl variant="outlined" fullWidth>
							<TextField
								id={name}
								type={"text"}
								label="Rua"
								fullWidth
								value={inputProps.field.value}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<Controller
					name={`groups.${index}.fields.property.number`}
					control={control}
					rules={{
						pattern: {
							value: /^[0-9]+$/,
							message: "Digite apenas números",
						},
					}}
					render={(inputProps) => (
						<FormControl
							variant="outlined"
							fullWidth
							error={inputProps.fieldState.error && true}
						>
							<TextField
								id={name}
								type={"text"}
								label="Número"
								fullWidth
								value={inputProps.field.value}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>

							{inputProps.fieldState.error && (
								<FormHelperText error={inputProps.fieldState.error && true}>
									{inputProps.fieldState.error.message + ""}
								</FormHelperText>
							)}
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<Controller
					name={`groups.${index}.fields.property.neighborhood`}
					control={control}
					render={(inputProps) => (
						<FormControl variant="outlined" fullWidth>
							<TextField
								id={name}
								type={"text"}
								label="Bairro"
								fullWidth
								value={inputProps.field.value}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<Controller
					name={`groups.${index}.fields.property.complement`}
					control={control}
					render={(inputProps) => (
						<FormControl variant="outlined" fullWidth>
							<TextField
								id={name}
								type={"text"}
								label="Complemento"
								fullWidth
								value={inputProps.field.value}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<Controller
					name={`groups.${index}.fields.property.areatotal`}
					control={control}
					render={(inputProps) => (
						<FormControl variant="outlined" fullWidth>
							<TextField
								id={name}
								type={"text"}
								label="Área total"
								fullWidth
								value={inputProps.field.value}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<Controller
					name={`groups.${index}.fields.property.areaterreno`}
					control={control}
					render={(inputProps) => (
						<FormControl variant="outlined" fullWidth>
							<TextField
								id={name}
								type={"text"}
								label="Área terreno"
								fullWidth
								value={inputProps.field.value}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>
						</FormControl>
					)}
				/>
			</Grid>
			<Grid item xs={12} marginBottom={2}>
				<Controller
					name={`groups.${index}.fields.property.codigo`}
					control={control}
					render={(inputProps) => (
						<FormControl variant="outlined" fullWidth>
							<TextField
								id={name}
								type={"text"}
								label="Ponto de referência"
								fullWidth
								value={inputProps.field.value}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
							/>
						</FormControl>
					)}
				/>
			</Grid>
			<ModalSearchRealty
				open={isOpenModalSearch}
				handleClose={() => setIsOpenModalSearch(false)}
				handleSelect={(value) => handleSelectRealty(value)}
			/>
		</>
	);
}

export default FieldPropertyDetails;
