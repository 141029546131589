import { Box, Grid, Paper, Typography } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import PieChartOutlineIcon from "@mui/icons-material/PieChartOutline";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { dynamicGraphics } from "../../consts";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import LegendToggleIcon from '@mui/icons-material/LegendToggle';

const icons = {
	bar: <BarChartIcon />,
	barRotated: (
		<BarChartIcon
			sx={{
				transform: "rotate(90deg)",
			}}
		/>
	),
	simpleLine: <StackedLineChartIcon />,
	stackedBar: <StackedBarChartIcon />,
	stackedBarRotaded: (
		<StackedBarChartIcon
			sx={{
				transform: "rotate(90deg)",
			}}
		/>
	),
	doughnut: <DonutLargeIcon />,
	pie: <PieChartOutlineIcon />,
	ssidChart: <SsidChartIcon />,
	taskChart: <LegendToggleIcon />
};


export function DynamicGraphics() {
	return (
		<Box my="15px">
			<Typography fontSize="18px" fontWeight={400} lineHeight={"24px"}>
				Gráficos Dinâmicos
			</Typography>
			<Grid container spacing={2} mt="15px">
				{dynamicGraphics.map((graphic, index) => {
					return (
						<Droppable
							key={graphic.variant}
							droppableId={graphic.variant}
							type="graphic"
						>
							{(droppableProvided) => (
								<Grid
									item
									xs={2}
									ref={droppableProvided.innerRef}
									{...droppableProvided.droppableProps}
								>
									<Draggable draggableId={`${graphic.variant}`} index={index}>
										{(draggableProvided) => (
											<Paper
												sx={{
													backgroundColor: "#F4F4F4",
												}}
												ref={draggableProvided.innerRef}
												{...draggableProvided.draggableProps}
												{...draggableProvided.dragHandleProps}
											>
												<Box
													sx={{
														p: "11px",
													}}
													textAlign="center"
												>
													<Typography
														fontSize="16px"
														lineHeight={"24px"}
														letterSpacing={"0.5px"}
														textAlign={"center"}
													>
														Título
													</Typography>
													<Typography
														fontSize="10px"
														lineHeight={"16px"}
														letterSpacing={"0.4px"}
														textAlign={"center"}
													>
														Descrição
													</Typography>
													{icons[graphic.variant]}
												</Box>
											</Paper>
										)}
									</Draggable>
								</Grid>
							)}
						</Droppable>
					);
				})}
			</Grid>
		</Box>
	);
}
