/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import { Fragment, useEffect } from "react";
import {
	Box,
	Checkbox,
	FormControlLabel,
	Grid,
	Stack,
	Typography,
} from "@mui/material";
import componentsDinamycForm from "utils/fieldsDynamicForm";
import { DetailProtocol, Task } from "types/graphql/queries/detailProtocol";
import { useFormContext, Controller } from "react-hook-form";
import { ContentState, convertFromHTML, EditorState } from "draft-js";

interface Props {
	form: Task["detail"]["form_groups"];
	subject: DetailProtocol["detailProtocol"]["subject"];
	formResponse: string;
}

export function Occurrence({ form, subject, formResponse }: Props) {
	const { control, setValue, watch } = useFormContext();
	const regulatoryActs = watch("regulatoryActs");
	const dynamicFormGroup = JSON.parse(form);
	const formResponseParsed = JSON.parse(formResponse ?? "[]");
	if (!dynamicFormGroup) return null;

	const formResponseIsArray = formResponseParsed
		? Array.isArray(formResponseParsed)
		: false;

	let regulatoryActDescription = regulatoryActs
		? regulatoryActs.length > 0 &&
		  regulatoryActs.filter((i) => i.checked).map((i) => i.checked.description)
		: [];

	if (regulatoryActDescription.length > 0) {
		regulatoryActDescription = regulatoryActDescription.join("\n\n\n");
	}

	const findInputTextAreaGroup = dynamicFormGroup
		? dynamicFormGroup.findIndex((group) => {
				return group.inputs.find((input) => input.type === "textarea");
		  })
		: null;

	const findIndexTextAreaInput =
		findInputTextAreaGroup >= 0
			? dynamicFormGroup[findInputTextAreaGroup].inputs.findIndex((input) => {
					return input.type === "textarea";
			  })
			: null;

	const findInputTextArea =
		findInputTextAreaGroup >= 0
			? dynamicFormGroup[findInputTextAreaGroup].inputs.find((input) => {
					return input.type === "textarea";
			  })
			: null;

	useEffect(() => {
		if (
			findInputTextAreaGroup !== null &&
			findIndexTextAreaInput !== null &&
			findInputTextArea &&
			regulatoryActDescription.length > 0
		) {
			const blocksFromHTML = convertFromHTML(regulatoryActDescription);
			const state = ContentState.createFromBlockArray(
				blocksFromHTML.contentBlocks,
				blocksFromHTML.entityMap
			);
			setValue(
				`groups.${findInputTextAreaGroup}.fields.${findInputTextArea.name}`,
				EditorState.createWithContent(state)
			);
		}
	}, [regulatoryActDescription]);

	const indexInputTypeLocation = dynamicFormGroup.findIndex((group) => {
		return group.inputs.find((input) => input.type === "location");
	});

	useEffect(() => {
		if (formResponseParsed) {
			dynamicFormGroup.forEach((group, index) => {
				group.inputs.forEach((input) => {
					const keys = input.name.split(".");
					let values = null;
					if (formResponseIsArray) {
						values = formResponseParsed[index];

						if (input.type === "owner" && values) {
							setValue(
								`groups.${index}.fields.owner.document`,
								values?.owner?.document ?? ""
							);
							setValue(
								`groups.${index}.fields.owner.name`,
								values?.owner?.name ?? ""
							);
							setValue(
								`groups.${index}.fields.owner.identity`,
								values?.owner?.document ?? ""
							);
							setValue(
								`groups.${index}.fields.owner.email`,
								values?.owner?.email ?? ""
							);
							setValue(
								`groups.${index}.fields.owner.address.zipcode`,
								values?.owner?.address?.zipcode ?? ""
							);
							setValue(
								`groups.${index}.fields.owner.address.street`,
								values?.owner?.address?.street ?? ""
							);
							setValue(
								`groups.${index}.fields.owner.address.number`,
								values?.owner?.address?.number ?? ""
							);
							setValue(
								`groups.${index}.fields.owner.address.neighborhood`,
								values?.owner?.address?.neighborhood ?? ""
							);
							setValue(
								`groups.${index}.fields.owner.address.city`,
								values?.owner?.address?.city ?? ""
							);
							setValue(
								`groups.${index}.fields.owner.address.uf`,
								values?.owner?.address?.uf ?? ""
							);
							setValue(
								`groups.${index}.fields.owner.address.complement`,
								values?.owner?.address?.complement ?? ""
							);
							if (indexInputTypeLocation >= 0) {
								setValue(
									`groups.${indexInputTypeLocation}.fields.location.municipalRegistration`,
									values?.location?.municipalRegistration ?? ""
								);
								setValue(
									`groups.${indexInputTypeLocation}.fields.location.lote`,
									values?.location?.lote ?? ""
								);
								setValue(
									`groups.${indexInputTypeLocation}.fields.location.quadra`,
									values?.location?.quadra ?? ""
								);
								setValue(
									`groups.${indexInputTypeLocation}.fields.location.street`,
									values?.location?.street ?? ""
								);
								setValue(
									`groups.${indexInputTypeLocation}.fields.location.number`,
									values?.location?.number ?? ""
								);
								setValue(
									`groups.${indexInputTypeLocation}.fields.location.neighborhood`,
									values?.location?.neighborhood ?? ""
								);
								setValue(
									`groups.${indexInputTypeLocation}.fields.location.complement`,
									values?.location?.complement ?? ""
								);
								setValue(
									`groups.${indexInputTypeLocation}.fields.location.areatotal`,
									values?.location?.areatotal ?? ""
								);
								setValue(
									`groups.${indexInputTypeLocation}.fields.location.areaterreno`,
									values?.location?.areaterreno ?? ""
								);
								setValue(
									`groups.${indexInputTypeLocation}.fields.location.codigo`,
									values?.location?.codigo ?? ""
								);
							}
							return;
						}
						if (!values) return null;
						const hasKeysInTheFormResponse = keys.some((key) => {
							return values[key] ?? false;
						});
						if (hasKeysInTheFormResponse) {
							values = keys.reduce((acc, key) => {
								if (!acc[key]) return "";
								return acc[key];
							}, values);
						} else {
							values = "";
						}
					} else {
						const hasKeysInTheFormResponse = keys.some((key) => {
							return formResponseParsed[key] ?? false;
						});

						if (hasKeysInTheFormResponse) {
							values = keys.reduce((acc, key) => {
								if (!acc[key]) return "";
								return acc[key];
							}, formResponseParsed);
						} else {
							values = "";
						}
					}


					if (input.type === "wrapper") return;
					if (input.type === "textarea" && values) {
						return;
					}
					if (input.type === "text") {
						setValue(`groups.${index}.fields.${input.name}`, values ?? "");
						return;
					}
					if (input.type === "cpf") {
						setValue(`groups.${index}.fields.${input.name}`, values ?? "");
						return;
					}
					if (input.type === "email") {
						setValue(`groups.${index}.fields.${input.name}`, values ?? "");
						return;
					}
					if (input.type === "cep") {
						setValue(`groups.${index}.fields.${input.name}`, values ?? "");
						return;
					}
					if (input.type === "number") {
						setValue(`groups.${index}.fields.${input.name}`, values ?? "");
						return;
					}
					if (input.type === "decimal") {
						setValue(`groups.${index}.fields.${input.name}`, values ?? "");
						return;
					}

					if (input.type === "applicant" && formResponseIsArray) {
						const values = formResponseParsed[index];

						if (values && values.owner) {
							setValue(
								`groups.${index}.fields.owner.proccessNumber`,
								values?.owner?.document ?? ""
							);
							setValue(
								`groups.${index}.fields.owner.name`,
								values?.owner?.name ?? ""
							);
							setValue(
								`groups.${index}.fields.owner.identity`,
								values?.owner?.document ?? ""
							);
							setValue(
								`groups.${index}.fields.owner.address.zipcode`,
								values?.owner?.address?.zipcode ?? ""
							);
							setValue(
								`groups.${index}.fields.owner.address.street`,
								values?.owner?.address?.street ?? ""
							);
							setValue(
								`groups.${index}.fields.owner.address.number`,
								values?.owner?.address?.number ?? ""
							);
							setValue(
								`groups.${index}.fields.owner.address.neighborhood`,
								values?.owner?.address?.neighborhood ?? ""
							);
							setValue(
								`groups.${index}.fields.owner.address.complement`,
								values?.owner?.address?.complement ?? ""
							);
						}
					}

					if (input.type === "location") {
						if (formResponseIsArray) {
							const values = formResponseParsed[index];
							const valuesOwner = formResponseParsed.find(obj => 'owner' in obj)?.owner;
							const valuesLocation = formResponseParsed.find(obj => 'location' in obj)?.location;
	
							if (valuesOwner && !values.location) {
								setValue(
									`groups.${index}.fields.location.codigo`,
									valuesOwner?.address?.zipcode ?? ""
								);
								setValue(
									`groups.${index}.fields.location.number`,
									valuesOwner?.address?.number ?? ""
								);
								setValue(
									`groups.${index}.fields.location.complement`,
									valuesOwner?.address?.complement ?? ""
								);
								setValue(
									`groups.${index}.fields.location.street`,
									valuesOwner?.address?.street ?? ""
								);
								setValue(
									`groups.${index}.fields.location.neighborhood`,
									valuesOwner?.address?.neighborhood ?? ""
								);
								setValue(
									`groups.${index}.fields.location.city`,
									valuesOwner?.address?.city ?? ""
								);
								setValue(
									`groups.${index}.fields.location.identity`,
									valuesOwner?.identity ?? ""
								);
								setValue(
									`groups.${index}.fields.location.document`,
									valuesOwner?.document ?? ""
								);
								setValue(
									`groups.${index}.fields.location.uf`,
									valuesOwner?.address?.uf ?? ""
								);
								setValue(
									`groups.${index}.fields.location.email`,
									valuesOwner?.email ?? ""
								);
							}

							if ((values && values.location) || valuesLocation) {
								setValue(
									`groups.${index}.fields.location.municipalRegistration`,
									valuesLocation?.municipalRegistration ?? ""
								);
								setValue(
									`groups.${index}.fields.location.lote`,
									valuesLocation?.lote ?? ""
								);
								setValue(
									`groups.${index}.fields.location.quadra`,
									valuesLocation?.quadra ?? ""
								);
								setValue(
									`groups.${index}.fields.location.street`,
									valuesLocation?.street ?? ""
								);
								setValue(
									`groups.${index}.fields.location.number`,
									valuesLocation?.number ?? ""
								);
								setValue(
									`groups.${index}.fields.location.neighborhood`,
									valuesLocation?.neighborhood ?? ""
								);
								setValue(
									`groups.${index}.fields.location.areatotal`,
									valuesLocation?.areatotal ?? ""
								);
								setValue(
									`groups.${index}.fields.location.areaterreno`,
									valuesLocation?.areaterreno ?? ""
								);
								setValue(
									`groups.${index}.fields.location.codigo`,
									valuesLocation?.codigo ?? ""
								);
							}
						} else {
							setValue(
								`groups.${index}.fields.location.street`,
								values?.street ?? ""
							);
							setValue(
								`groups.${index}.fields.location.municipalRegistration`,
								values?.municipalRegistration ?? ""
							);
							setValue(
								`groups.${index}.fields.location.lote`,
								values?.lote ?? ""
							);
							setValue(
								`groups.${index}.fields.location.quadra`,
								values?.quadra ?? ""
							);

							setValue(
								`groups.${index}.fields.location.number`,
								values?.number ?? ""
							);
							setValue(
								`groups.${index}.fields.location.neighborhood`,
								values?.neighborhood ?? ""
							);
							setValue(
								`groups.${index}.fields.location.complement`,
								values?.complement ?? ""
							);
							setValue(
								`groups.${index}.fields.location.areatotal`,
								values?.areatotal ?? ""
							);
							setValue(
								`groups.${index}.fields.location.areaterreno`,
								values?.areaterreno ?? ""
							);
							setValue(
								`groups.${index}.fields.location.codigo`,
								values?.codigo ?? ""
							);
						}
					}
				});
			});
		}
	}, []);

	return (
		<>
			<Grid item xs={12}>
				<Typography variant="subtitle1" textTransform="uppercase">
					preencha o formulário abaixo para concluir essa tarefa
				</Typography>

				<Box marginTop={2}>
					<Typography variant="body2" fontWeight={600}>
						Selecione os atos regulatórios
					</Typography>

					<Stack spacing={1} flex={1} maxHeight="500px" overflow="auto">
						{subject.regulatory_acts &&
							subject.regulatory_acts.map((regulatoryAct, index) => (
								<Controller
									key={regulatoryAct.id}
									control={control}
									name={`regulatoryActs.${index}.checked`}
									render={({
										field: { onChange, value, ref },
										fieldState: { invalid, isTouched, isDirty, error },
										formState,
										...rest
									}) => (
										<FormControlLabel
											control={<Checkbox {...rest} />}
											label={regulatoryAct.name}
											value={regulatoryAct.id}
											onChange={(event, checked) => {
												if (checked) {
													onChange(regulatoryAct);
												} else {
													onChange(null);
												}
											}}
											checked={value}
											sx={{
												mx: 0,
											}}
										/>
									)}
								/>
							))}
					</Stack>
				</Box>

				<Stack spacing={3} flex={1} marginTop={2}>
					{dynamicFormGroup.map((group, index) => (
						<Fragment key={index}>
							<Typography variant="h6" fontSize="1rem">
								{group.title ?? null}
							</Typography>
							{group.inputs.map((input) => {
								const component = componentsDinamycForm.find(
									(value) => value.type === input.type
								);

								return (
									<Fragment key={input.name}>
										{component && (
											<component.component
												required={input.required}
												label={input.label}
												placeholder={input.label}
												name={input.name}
												options={
													input.options &&
													input.options.map((option) => ({
														label: option.name,
														value: option.id.toString(),
													}))
												}
												index={index}
											/>
										)}
									</Fragment>
								);
							})}
						</Fragment>
					))}
				</Stack>
			</Grid>
		</>
	);
}
