import { gql } from "@apollo/client";

export const DETAIL_PUBLIC_COMPLAINT = gql`
	query ($code: String!) {
		detailPublicComplaint(code: $code) {
			complaint_id
			complaint_code
			complaint_refuse_justification
			name
			email
			zipcode
			address
			number
			reference
			district {
				name
			}
			description
			protocol_id
			protocol_code
			situation
			concluded_at
			tasks {
				id
				name
				answer
				concluded_at
				expire_at
				created_at
				type
			}
			type
			created_at
			attachments {
				id
				name
				url
				created_at
			}
		}
	}
`;
