import {
	Box,
	Container,
	Grid,
	Stack,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { Header } from "components/Header";
import InfoResume from "./InfoResume";
import SettingsPlus from "./SettingsPlus";
import { FETCH_DETAIL_COMPANY } from "graphql/queries/fetchDetailCompany";
import { FetchDetailCompany } from "types/graphql/queries/fetchDetailCompany";

export function Settings() {

	const subdomain = window.location.hostname.split(".")[0];

	const { data: dataCompany } = useQuery<FetchDetailCompany>(FETCH_DETAIL_COMPANY, {
		variables: {
			domain:
				subdomain === "localhost"
					? process.env.REACT_DEFAULT_DOMAIN || "lagoasanta"
					: subdomain,
		},
	});

	return (
		<Box
			width="100%"
			display="flex"
			alignSelf="stretch"
			flexDirection="column"
			sx={{
				backgroundColor: "#f3f3f3",
			}}
			paddingBottom="1rem"
		>
			<Header title="Configurações" />

			<Container maxWidth="lg">
				<Box
					sx={{
						backgroundImage: `url(${dataCompany && dataCompany.detailCompany.logo})`,
						backgroundSize: "contain",
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
					}}
					minHeight="70px"
					marginTop={2}
					marginBottom={3}
				/>

				<Box sx={{ flexGrow: 1 }} marginTop="1rem">
					<Grid container spacing={2}>
						<Grid item xs={12} sm={2}>
							<InfoResume />
						</Grid>

						<Grid item xs={12} sm={10}>
							<Stack>
								<SettingsPlus />
							</Stack>
						</Grid>
					</Grid>
				</Box>
			</Container>

		</Box>
	);
}
