import { useMutation, useQuery } from "@apollo/client";
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Stack,
	Box,
} from "@mui/material";
import { CREATE_USER } from "graphql/mutations/createUser";
import { useForm, FormProvider } from "react-hook-form";
import { useSnackbar } from "notistack";
import Input from "components/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FETCH_GROUPS } from "graphql/queries/fetchGroups";
import SelectFilterModal from "components/selectFilterModal";
import { FETCH_PERFIS } from "graphql/queries/fetchPerfis";
import { FetchGroups } from "types/graphql/queries/fetchGroups";
import { FetchPerfis } from "types/graphql/queries/fetchPerfis";
import { Select } from "components/Select";
import { GreenSwitch } from "components/GreenSwitch";

type CreateUserDialogProps = {
	open: boolean;
	handleClose: () => void;
	onCreate: () => void;
};

const schema = yup.object().shape({
	name: yup.string().required("Campo obrigatório"),
	email: yup.string().required("Campo obrigatório").email("Email inválido"),
	password: yup.string().required("Campo obrigatório"),
	group_ids: yup.array(yup.number()),
	perfil_ids: yup.number(),
	ismobile: yup.boolean(),
	isAccumulatePoints: yup.boolean(),
});

export default function CreateUserDialog({
	open,
	handleClose,
	onCreate,
}: CreateUserDialogProps) {
	const methods = useForm({
		defaultValues: {
			name: "",
			email: "",
			password: "",
			group_ids: [],
			perfil_id: "",
			ismobile: false,
			isAccumulatePoints: false,
		},
		resolver: yupResolver(schema),
	});
	const { enqueueSnackbar } = useSnackbar();

	// Setting mutation
	const [createUser, { loading }] = useMutation(CREATE_USER);
	const { data: dataGroups } = useQuery<FetchGroups>(FETCH_GROUPS, {
		variables: {
			limit: 1000,
		},
	});
	const { data: dataPerfis } = useQuery<FetchPerfis>(FETCH_PERFIS, {
		variables: {
			limit: 1000,
			order_by:"name",
			direction: "asc",
		},
	});

	const { reset, handleSubmit, watch, setValue } = methods;

	const groupIds = watch("group_ids");

	const onSubmit = async (values: any) => {
		try {
			await createUser({
				variables: {
					name: values.name,
					email: values.email,
					password: values.password,
					group_ids: values.group_ids,
					perfil_id: values.perfil_id,
					is_point_accumulator: values.isAccumulatePoints,
					is_mobile_user: values.ismobile,
				},
			});
			onCreate();
			reset({
				name: "",
				email: "",
				password: "",
				group_ids: [],
				perfil_id: "",
			});
		} catch (err) {
			enqueueSnackbar(err.message, { variant: "error" });
		}
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="paper"
				maxWidth="sm"
				fullWidth={true}
			>
				<FormProvider {...methods}>
					<DialogTitle id="scroll-dialog-title">Cadastrar Usuário</DialogTitle>

					<DialogContent>
						<Stack spacing={2} marginTop={2}>
							<Input name="name" autoFocus placeholder="Nome*" type="text" />
							<Input name="email" placeholder="E-mail*" type="email" />
							<Input
								name="password"
								placeholder="Senha*"
								type="password"
								isPassword
							/>
							{dataGroups && (
								<SelectFilterModal
									title="Selecione um grupo fiscal"
									fullWidth
									onChange={(value) => {
										const idGroups = value
											.filter((item) => !item.title)
											.map((item) => item.id);
										setValue("group_ids", idGroups);
									}}
									queryName={FETCH_GROUPS}
									order_by="name"
									dataName="fetchGroups"
									valuesSelecteds={dataGroups.fetchGroups.data.filter(
										(group) => {
											return groupIds.includes(group.id);
										}
									)}
								/>
							)}
							{dataPerfis && (
								<Select
									label="Selecione um perfil"
									name="perfil_id"
									options={dataPerfis.fetchPerfis.map((perfil) => ({
										label: perfil.name,
										value: perfil.id,
									}))}
								/>
							)}
							<Box>
								<GreenSwitch name="ismobile" label="Permitir acesso mobile?" />
								<GreenSwitch
									name="isAccumulatePoints"
									label="Acumula pontuação?"
								/>
							</Box>
						</Stack>
					</DialogContent>

					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Cancelar
						</Button>
						<Button
							onClick={handleSubmit(onSubmit)}
							variant="contained"
							color="primary"
							disabled={loading}
						>
							{loading && (
								<CircularProgress size={14} style={{ marginRight: 12 }} />
							)}
							Cadastrar Usuário
						</Button>
					</DialogActions>
				</FormProvider>
			</Dialog>
		</>
	);
}
