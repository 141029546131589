import {
	FormControl,
	InputLabel,
	Select as SelectMaterial,
	OutlinedInput,
	SelectProps as SelectPropsMaterial,
	MenuItem,
} from "@mui/material";
import { useFormContext } from "react-hook-form";

type SelectProps = SelectPropsMaterial & {
	label: string;
	options: Array<{
		label?: string;
		title?: string;
		value: string | number;
		style?: any;
	}>;
	fullWidth?: boolean;
	isShowEmptyOption?: boolean;
	marginSelect?: any;
	shouldUnregister?: boolean;
};

export function Select({
	label,
	options,
	fullWidth,
	name,
	isShowEmptyOption,
	marginSelect = 1,
	shouldUnregister = false,
	...rest
}: SelectProps) {
	const {
		register,
		watch,
		formState: { errors },
	} = useFormContext();
	const value = watch(name);
	return (
		<FormControl
			sx={{ m: marginSelect, minWidth: 120 }}
			fullWidth={fullWidth}
			error={errors[name] && true}
		>
			<InputLabel htmlFor={`dialog-${label}`} error={errors[name] && true}>
				{label}
			</InputLabel>
			<SelectMaterial
				error={errors[name] && true}
				{...rest}
				input={<OutlinedInput label={label} id={`dialog-${label}`} />}
				{...register(name, {
					shouldUnregister: shouldUnregister,
				})}
				defaultValue={value ?? ""}
			>
				{isShowEmptyOption && (
					<MenuItem value="">
						<em>Nenhum</em>
					</MenuItem>
				)}
				{options.map((option) => (
					<MenuItem
						key={option.value}
						value={option.value}
						style={option.style}
					>
						{option.title ?? option.label}
					</MenuItem>
				))}
			</SelectMaterial>
		</FormControl>
	);
}
